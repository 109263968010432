import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

interface IComponent {
  orderNumber: string;
  packListNumber: string;
  poNumber: string;
  terms: string;
}

const BORDER_RADIUS = 3;
const COL_HEIGHT = 30;

const style = StyleSheet.create({
  componentWrapper: {
    width: '96%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    borderRadius: BORDER_RADIUS,
    border: '1px solid black',
    justifySelf: 'center',
    marginLeft: 24,
  },
  sectionColumn: {
    width: '25%',
    height: COL_HEIGHT,
    flexDirection: 'column',
    overflow: 'hidden',
    borderLeft: '1px solid black',
  },
  firstColumn: {
    borderTopLeftRadius: BORDER_RADIUS,
    borderLeft: 'none',
  },
  lastColumn: {
    borderTopRightRadius: BORDER_RADIUS,
  },
  fobColumn: {
    width: '15%',
  },
  sectionTitle: {
    width: '100%',
    height: COL_HEIGHT / 2,
    borderBottom: '1px solid lightgrey',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    textAlign: 'center',
    fontFamily: 'Red Hat Display-700',
    fontSize: 10,
    margin: 0,
  },
  sectionValue: {
    width: '100%',
    height: (COL_HEIGHT / 2) - 1,
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
    fontSize: 10,
    margin: 0,
  },
});
const ShipmentLogisticsDetail = ({
  orderNumber, packListNumber, poNumber, terms,
}: IComponent) => {
  const salesOrderNumber = orderNumber.padStart(4, '0').padStart(5, '1');
  return (
    <View style={style.componentWrapper}>
      <View style={[style.sectionColumn, style.firstColumn]}>
        <Text style={style.sectionTitle}>P.O. Number</Text>
        <Text style={style.sectionValue}>{poNumber}</Text>
      </View>
      <View style={[style.sectionColumn]}>
        <Text style={style.sectionTitle}>Order Number</Text>
        <Text style={style.sectionValue}>{salesOrderNumber}</Text>
      </View>
      <View style={[style.sectionColumn]}>
        <Text style={style.sectionTitle}>Pack List Number</Text>
        <Text style={style.sectionValue}>{packListNumber}</Text>
      </View>
      <View style={style.sectionColumn}>
        <Text style={style.sectionTitle}>Terms</Text>
        <Text style={style.sectionValue}>{terms}</Text>
      </View>
      <View style={[style.sectionColumn, style.fobColumn, style.lastColumn]}>
        <Text style={style.sectionTitle}>FOB</Text>
        <Text style={style.sectionValue}>Origin</Text>
      </View>
    </View>
  );
};

export default ShipmentLogisticsDetail;
