import React from 'react';
import {
  StyleSheet, View, Text, Image,
} from '@react-pdf/renderer';
import { IBillingInfo, IShippingInfo } from 'shared/types/order';
import { includes } from 'lodash';
import { IShipment } from '../../../../shared/types/dbRecords';

interface IComponent {
  shipment: IShipment;
  isPackSlip: boolean;
}

const style = StyleSheet.create({
  infoWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 24,
    marginBottom: 28,
  },
  infoPackSlipWrapper: {
    flexDirection: 'row-reverse',
  },
  addressInfoColumn: {
    width: 230,
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  shippingInfoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%',
  },
  rightHandInfoColumn: {
    marginLeft: 36,
  },
  addressHeader: {
    width: '100%',
    borderBottom: '2px solid black',
    marginBottom: 6,
  },
  addressBlockTitle: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 10,
  },
  addressBlockText: {
    fontFamily: 'Red Hat Display',
    fontSize: 9,
    paddingLeft: 1,
    lineHeight: 1.35,
  },
  internalUseView: {
    width: 160,
    marginLeft: 36,
    border: '1px solid black',
    padding: 4,
  },
  enteredStamp: {
    width: 90,
    height: 40,
    position: 'absolute',
    top: 24,
    right: 0,
    zIndex: 1000,
  },
});

const BillingShippingInfo = ({ shipment, isPackSlip = false }: IComponent) => {
  const billingInfo = shipment.customer.BillAddr;
  const shippingInfo = shipment.customer.ShipAddr;
  const shippingLocation = `${shippingInfo.City}${shippingInfo.CountrySubDivisionCode ? `, ${shippingInfo.CountrySubDivisionCode}` : ''}${shippingInfo.PostalCode ? `  ${shippingInfo.PostalCode}` : ''}`;
  const billingLocation = `${billingInfo.City}${billingInfo.CountrySubDivisionCode ? `, ${billingInfo.CountrySubDivisionCode}` : ''}${billingInfo.PostalCode ? `  ${billingInfo.PostalCode}` : ''}`;
  const billingCountry = billingInfo.Country?.match(/USA/) || billingInfo.Country === 'United States' ? '' : billingInfo.Country;

  return (
    <View style={[style.infoWrapper, isPackSlip ? style.infoPackSlipWrapper : {}]}>
      <View style={[style.addressInfoColumn, isPackSlip ? style.rightHandInfoColumn : {}]}>
        <View style={style.addressHeader}>
          <Text style={style.addressBlockTitle}>Bill To:</Text>
        </View>
        <Text style={style.addressBlockText}>{shipment.customer.CompanyName}</Text>
        <Text style={style.addressBlockText}>{billingInfo.Line1}</Text>
        <Text style={style.addressBlockText}>{billingInfo.Line2}</Text>
        {!!billingInfo.Line3?.length && (
        <Text style={style.addressBlockText}>{billingInfo.Line3}</Text>
        )}
        {!!billingInfo.Line4?.length && (
          <Text style={style.addressBlockText}>{billingInfo.Line4}</Text>
        )}
        <Text style={style.addressBlockText}>{billingLocation}</Text>
        {!includes(['USA', 'United States'], billingCountry) && (
          <Text style={style.addressBlockText}>{billingCountry}</Text>
        )}
      </View>
      <View style={[style.addressInfoColumn, isPackSlip ? {} : style.rightHandInfoColumn]}>
        <View style={style.addressHeader}>
          <Text style={style.addressBlockTitle}>Ship To:</Text>
        </View>
        <Text style={style.addressBlockText}>{shipment.customer.CompanyName}</Text>
        <Text style={style.addressBlockText}>{shippingInfo.Line1}</Text>
        <Text style={style.addressBlockText}>{shippingInfo.Line2}</Text>
        {!!shippingInfo.Line3?.length && (
          <Text style={style.addressBlockText}>{shippingInfo.Line3}</Text>
        )}
        {!!shippingInfo.Line4?.length && (
          <Text style={style.addressBlockText}>{shippingInfo.Line4}</Text>
        )}
        <Text style={style.addressBlockText}>{shippingLocation}</Text>
        {!includes(['USA', 'United States'], shippingInfo.Country) && (
          <Text style={style.addressBlockText}>{shippingInfo.Country}</Text>
        )}
      </View>
    </View>
  );
};

export default BillingShippingInfo;
