import {
  difference, find, includes, omit, sortBy,
} from 'lodash';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { devLog } from 'shared/util/logging';
import injectMissingParts from 'shared/text/description';
import { resolveFirestorePath, useTestData } from 'shared/util';
import {
  bodyDataAtom, neckDataAtom, newBodyAtom, newNeckAtom,
} from 'shared/state/pricingState';
import { CONSTRUCTION_JASON } from 'shared/scanner';
import { ICustomerRecord } from 'shared/types/dbRecords';
import { IRateOption, IUnitOption } from 'shared/types/pricingTool';
import { IShopOrder } from 'pages/Orders/types';
import { uniqueChildPartNameSegments } from 'shared/text';
import { configToDescription, getPartValue, orderTermsByRank, setPartValue, updateConfig } from 'shared/partParser/util';
import { IConfigEntry, IConfigTerm, ICustomerPart } from 'shared/types/parts';

interface IPrice {
  price: number;
  discount: number;
}

const queryCollectionByCustomerId = async (firestore: firebase.firestore.Firestore, collection: string, customerId: string) => {
  const nextCustomerId = `${customerId}${'\uf8ff'}`;

  const query = firestore.collection(collection)
    .where(firebase.firestore.FieldPath.documentId(), '>=', customerId)
    .where(firebase.firestore.FieldPath.documentId(), '<', nextCustomerId);

  const docs = await query.get();
  return docs;
};

export const queryActiveCollection = async (firestore: firebase.firestore.Firestore, collection: string) => {
  const query = firestore.collection(collection).where('active', '==', true);
  const docs = await query.get();
  return docs;
};

export const definedParts = (list: any) => list.filter((i: any) => !!i);

export const formatPercent = (value: number, precision: number = 2, float: boolean = true) => {
  if (float) return `${(value * 100).toFixed(precision)}%`;
  return `${value.toFixed(precision)}%`;
};

export const formatMargin = (purchaseCost: number, sellPrice: number) => {
  const margin = (sellPrice - purchaseCost) / sellPrice;
  return formatPercent(margin);
};
export const formatPrice = (price: string|number|undefined, decimalPlaces: number = 2): string => {
  if (!price) return '$0.00';
  // guard against some prices being stored as strings
  const _price = typeof (price) === 'number' ? price : parseFloat(price.replace('$', ''));
  const format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
  // if (_price < 0) return `($${(_price * -1).toFixed(2)})`;
  // return `$${_price.toFixed(2)}`;
  return format.format(_price);
};

// export const partType = (part: any): string => {
//   if (_.includes(['neck', 'BN', 'GN'], part.type)) return 'neck';
//   return 'body';
// };

export const bodyTotal = (bodyState: any): IPrice => {
  if (!bodyState) return { price: 0, discount: 0 };
  const archetype = bodyState.archetype;
  // @ts-ignore
  let bodyCost = bodyState.bodyWood ? bodyState.bodyWood.price[archetype.instrument] : 0;
  // let materialCost = bomItems.map((b) => b.totalPrice).reduce((a, b) => a + b, 0);
  if (archetype.instrument === 'S' && bodyCost === 0) bodyCost = bodyState.bodyWood.price.M || 0;
  bodyCost += bodyState.blankModification?.price || 0;
  // @ts-ignore
  const topCost = bodyState.topWood && bodyState.topWood.price ? bodyState.topWood.price[archetype.instrument] : 0;

  const unitOptionsCost = [
    ...bodyState.options.weightReductionOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.constructionOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.accessoryOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.finishingOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.handlingOptions.filter((i: IUnitOption) => i.price >= 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  // Calculate discounts separately so we don't discount our deductions from the price of a part.
  const unitOptionsDiscount = [
    ...bodyState.options.weightReductionOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.constructionOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.accessoryOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.finishingOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.handlingOptions.filter((i: IUnitOption) => i.price < 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  const laborOptionsCost = bodyState.options.laborOptions
    .map((o: IRateOption) => (o.quantity * o.price))
    .reduce((a: number, b: number) => a + b, 0);

  const priceAdjustment = bodyState.priceAdjustment || 0;

  // devLog('data/index', 90, `part total: ${archetype.price + bodyCost + topCost + unitOptionsCost + laborOptionsCost + priceAdjustment}`);
  // devLog('data/index', 90, `archetypeCost: ${archetype.price}`);
  // devLog('data/index', 90, `bodyCost: ${bodyCost}`);
  // devLog('data/index', 90, `topCost: ${topCost}`);
  // // console.log('bodyCost', bodyCost);
  // devLog('data/index', 90, `unitOptionsCost: ${unitOptionsCost}`);
  // devLog('data/index', 90, `laborOptionsCost: ${laborOptionsCost}`);
  // devLog('data/index', 90, `priceAdjustment: ${priceAdjustment}`);

  return {
    price: archetype.price + bodyCost + topCost + unitOptionsCost + laborOptionsCost + priceAdjustment,
    discount: unitOptionsDiscount,
  };
};

export const neckTotal = (neckState: any): IPrice => {
  if (!neckState) return { price: 0, discount: 0 };
  const archetype = neckState.archetype;
  // @ts-ignore
  const neckCost = neckState.neckWood ? neckState.neckWood.price[archetype.instrument] : 0;
  // @ts-ignore
  const fretboardCost = neckState.fretboardWood ? neckState.fretboardWood.price[archetype.instrument] : 0;
  const unitOptionsCost = [
    ...(neckState.options.trussRodOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.frettingOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.inlayOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.dotOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.constructionOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.finishingOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.handlingOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options.accessoryOptions || []).filter((i: IUnitOption) => i.price >= 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  const unitOptionsDiscount = [
    ...(neckState.options.trussRodOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.frettingOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.inlayOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.dotOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.constructionOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.finishingOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.handlingOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options.accessoryOptions || []).filter((i: IUnitOption) => i.price < 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  const laborOptionsCost = neckState.options.laborOptions
    .map((o: IRateOption) => (o.quantity * o.price))
    .reduce((a: number, b: number) => a + b, 0);

  const priceAdjustment = neckState.priceAdjustment || 0;

  // const archetypePrice = includes(SPECIAL_CUSTOMERS, currentCustomer.id) ? archetype.price - ARCHETYPE_DISCOUNT : archetype.price;
  return {
    price: archetype.price + neckCost + fretboardCost + unitOptionsCost + laborOptionsCost + priceAdjustment,
    discount: unitOptionsDiscount,
  };
};

export const discountPrice = (price: { price: number, discount: number, customerDiscount: number, type: string }, divisor: number = 100, noFormat: boolean = false) => {
  if (!price || !price.price) return formatPrice(0);
  if (price.price < 0) return price;
  // const discount = price.type.match(/[G|B]B/i) ? customer.bodyDiscount : customer.neckDiscount : 0;
  const rawDiscount = (price.price * (1 + (price.customerDiscount) / 100));
  if (noFormat) return rawDiscount + (price.discount || 0);
  return formatPrice(Math.round((rawDiscount * divisor) / divisor) + (price.discount || 0));
};

export const groupItems = (collection: any) => {
  const groupedItems = collection.types.map((t: string) => ({
    type: t,
    items: collection.items.filter((i: any) => i.type === t),
  }));

  return groupedItems;
};

export const mapParentPricing = (parent: any, pricingData: any) => {
  if (!parent.pricing) return pricingData;
  const MATERIAL_KEYS = [
    'bodyWood',
    'topWood',
    'fretboardWood',
    'neckWood',
    'options.dotOptions',
    'options.frettingOptions',
    'options.inlayOptions',
  ];
  const OPTIONAL_KEYS = [
    'options.constructionOptions',
    // Add other optional keys here as needed
  ];

  // Start with all pricing data
  const mappedPricing = { ...pricingData };

  // Remove items from OPTIONAL_KEYS that have parentMapped set to false
  OPTIONAL_KEYS.forEach((k: string) => {
    const optionCategory = getPartValue(mappedPricing, k);
    if (optionCategory && optionCategory.items) {
      const filteredItems = optionCategory.items.filter((item: any) => item.parentMapped !== false);
      setPartValue(mappedPricing, k, { ...optionCategory, items: filteredItems });
    }
  });

  // Map regular material keys from child 
  MATERIAL_KEYS.forEach((k: string) => {
    const value = getPartValue(pricingData, k);
    if (value) {
      setPartValue(mappedPricing, k, value);
    }
  });

  return mappedPricing;
};

export const updateBodyPricing = (priceRecord: any, bodyData: any) => {
  try {
    // @ts-ignore
    priceRecord.archetype = find(
      definedParts(bodyData.archetype.items),
      (bA: any) => bA.id === priceRecord.archetype.id,
    ) || priceRecord.archetype;

    // @ts-ignore
    priceRecord.bodyWood = find(
      definedParts(bodyData.bodyWood.items),
      (tW: any) => tW.id === priceRecord.bodyWood.id,
    ) || priceRecord.bodyWood;

    priceRecord.blankModification = find(
      definedParts(bodyData.options.bodyBlankModifications.items),
      (b: any) => b.id === priceRecord.blankModification?.id,
    );

    // @ts-ignore
    priceRecord.topWood = find(
      definedParts(bodyData.topWood.items),
      (tW: any) => tW.id === priceRecord.topWood.id,
    ) || { id: '', label: 'Select a top wood (optional)', price: { L: 0, M: 0, S: 0 } };

    Object.entries(priceRecord.options).forEach((value: [string, unknown]) => {
      const [key, optionSet] = value;
      if (key !== 'laborOptions') {
        // if it's not a labor option, the value/quantity is always the same on both the
        // part record and the database, since it is a flat, binary value. In this case,
        // it's fine to stomp on the priceRecord's options.
        // @ts-ignore
        const mergedUnitOptionRecords = optionSet.map((o: IUnitOption) => find(definedParts(bodyData.options[key].items),
          (u: IUnitOption) => u.id === o.id));
        priceRecord.options[key] = mergedUnitOptionRecords.filter((o: IUnitOption) => o);
      } else {
        // in the case of Labor options, we only want to update the option value, since the quantity
        // may be different for each object.
        // @ts-ignore
        const mergedLaborOptionRecords = optionSet.map((o: IUnitOption) => {
          const dbRecord = find(definedParts(bodyData.options[key].items), (u: IUnitOption) => u.id === o.id);
          if (dbRecord) {
            return {
              ...o,
              price: dbRecord.price,
            };
          }
          return undefined;
        });
        priceRecord.options[key] = mergedLaborOptionRecords.filter((o: IUnitOption) => o);
      }
    });

    // console.log(bodyData);
    // console.log('----------');
    // console.log(priceRecord);
    return priceRecord;
  } catch (e) {
    console.log(e);
    console.log('----------------------------');
    console.log(priceRecord);
    console.log(bodyData);
    return null;
    // throw new Error('fuck dis shit');
  }
};

export const updateNeckPricing = (priceRecord: any, neckData: any) => {
  try {
  // @ts-ignore
    priceRecord.archetype = find(
      definedParts(neckData.archetype.items),
      (bA: any) => bA.id === priceRecord.archetype.id,
    ) || priceRecord.archetype;

    // @ts-ignore
    priceRecord.neckWood = find(
      definedParts(neckData.neckWood.items),
      (bW: any) => bW.id === priceRecord.neckWood.id,
    ) || priceRecord.neckWood;

    // @ts-ignore
    priceRecord.fretboardWood = find(
      definedParts(neckData.fretboardWood.items),
      (tW: any) => tW.id === priceRecord.fretboardWood.id,
    ) || priceRecord.fretboardWood;

    Object.entries(priceRecord.options).forEach((value: [string, unknown]) => {
      const [key, optionSet] = value;
      // @ts-ignore
      const mergedRecords = optionSet.map((o: IUnitOption) => find(definedParts(neckData.options[key].items),
        (u: IUnitOption) => u.id === o.id));
      priceRecord.options[key] = mergedRecords.filter((o: IUnitOption) => o);
    });

    return priceRecord;
  } catch (e) {
    console.log(e);
    console.log('----------------------------');
    console.log(priceRecord);
    console.log(neckData);
    throw new Error('fuck dis shit');
  }
};

export const updatePricing = (partRecord: any, pricingData: any, partCategory: 'body'|'neck'): any => {
  if (!partRecord.pricing) return { ...partRecord, pricing: null };

  const [bodyData, neckData] = pricingData;

  if (!partRecord.pricing) {
    return partRecord;
  }

  let updated = {
    ...partRecord.pricing, discount: 0,
  };

  if (partCategory === 'neck') {
    updated = updateNeckPricing(updated, neckData);
  } else {
    updated = updateBodyPricing(updated, bodyData);
  }

  if (!updated) {
    devLog('shared/data/index', 274, partRecord);
    return;
  }

  return { ...partRecord, pricing: updated };
};

export const sanitizePricingRecord = (pricingRecord: any) => {
  const validKeys = [
    'archetype',
    'bodyWood',
    'options',
    'topWood',
    'weightClasses',
    'fretboardWood',
    'neckWood',
    'priceAdjustment',
    'blankModification',
  ];
  const invalidKeys = difference(Object.keys(pricingRecord), validKeys);
  return omit(pricingRecord, invalidKeys);
};

export const processPricingData = (firestore: any, partCollectionDbString: string, partRecords: any, pricingData: any, childViewType: 'sku' | 'like' = 'sku') => {
  const partPrices = partRecords.map((p: any) => {
    if (!p.pricing) return p;

    const parent = p.parent ? find(partRecords, (_p) => _p.Sku === p.parent) : null;
    const Description = parent ? injectMissingParts(parent.Description, p.Description) : p.Description;
    const partPricing = parent ? mapParentPricing(parent, p.pricing) : p.pricing;
    devLog('shared/data/index', 375, `Pricing ${p.Sku}`);
    const record = updatePricing({ ...p, pricing: partPricing }, pricingData, includes(['GB', 'BB', 'CP'], p.type) ? 'body' : 'neck');
    let rawPrice = record?.price || p.price || 0;
    if (record?.pricing) {
      const totalFunction = includes(Object.keys(record.pricing), 'neckWood') ? neckTotal : bodyTotal;
      rawPrice = totalFunction(record.pricing);
    }

    const customerDiscount = includes(['GB', 'BB'], p.type) ? parseInt((p.customer?.bodyDiscount || 0), 10) : parseInt((p.customer?.neckDiscount || 0), 10);
    const price = Math.round(discountPrice({ ...rawPrice, customerDiscount, type: p.type }, 100, true) as number);

    if (p.price !== price) {
      firestore.collection(partCollectionDbString).doc(p.Sku).update({ price: Math.round(price) });
    }

    return { ...p, price, Description };
  });

  if (childViewType === 'sku') sortBy(partPrices, (p) => p.Sku);
  // @ts-ignore
  const childSkuPricing = partPrices.map((p) => {
    if (!p.parent) return { ...p, isChild: false, sortKey: p.Sku };
    const parentSku = p.parent.Sku;
    const parent = find(partPrices, (price) => price.Sku === parentSku);
    if (parent) {
      try {
        const partDifferences = uniqueChildPartNameSegments(configToDescription(parent.config), [p.Description], false)[0];
        return {
          ...p,
          isChild: true,
          sortKey: `${parent.Sku}.${p.Sku}`,
          uniqueModifiers: partDifferences,
        };
      } catch (e) {
        devLog('shared/data/index', 405, p);
        devLog('shared/data/index', 405, parent);
        return { ...p, isChild: false, sortKey: p.Sku };
      }
    }
    return { ...p, isChild: false, sortKey: p.Sku };
  });
  return sortBy(childSkuPricing, 'sortKey');
};

export const fetchPricing = async (
  firestore: any,
  partCollectionDbString: string,
  partPricingCollectionDbString: string,
  customer: ICustomerRecord|null,
  configTerms: IConfigTerm[],
  pricingData: any = null,
  childViewType: 'sku'|'like' = 'sku',
): Promise<[]> => {
  let query = firestore.collection(partCollectionDbString);
  let pricingDocs: any = null;
  if (customer) {
    query = query.where('customer', '==', customer.DisplayName);
    pricingDocs = await queryCollectionByCustomerId(firestore, partPricingCollectionDbString, customer.DisplayName);
  } else {
    pricingDocs = await firestore.collection(partPricingCollectionDbString).get();
  }
  const partDocs = await query.get();
  const partPricing = pricingDocs.docs.map((d: any) => ({ Sku: d.id, pricing: d.data() }));

  const customerDatabase = resolveFirestorePath('customers');
  const customerDocs = await queryActiveCollection(firestore, customerDatabase);
  const customers = customerDocs.docs.map((d: any) => d.data());
  const partsToUpdate = [] as ICustomerPart[];
  const parts = partDocs.docs.map((d: any) => {
    const partData = d.data() as ICustomerPart;
    const _customer = find(customers, (c: ICustomerRecord) => c.DisplayName === partData.customer) || partData.customer;
    try {
      const pricing = find(partPricing, (p: any) => p.Sku === partData.Sku);
      // if (!pricing) return { ...partData, customer: _customer, pricing: null };
      if (!partData.config) return { ...partData, customer: _customer, pricing: pricing?.pricing || null };
      const oldConfig = partData.config;
      const newConfig = updateConfig(partData.config, configTerms);
      // if (configToDescription(oldConfig) !== configToDescription(newConfig)) {
      if (JSON.stringify(oldConfig) !== JSON.stringify(newConfig)) {
        partsToUpdate.push({ ...partData, config: newConfig });
      }
      const Description = configToDescription(newConfig);
      return { ...partData, Description, customer: _customer, pricing: pricing.pricing || null };
    } catch (e) {
      devLog('shared/data/index', 443, partData);
      return { ...partData, customer: _customer, pricing: null };
    }
  });

  // if we have parts whose configurations have changed, updated them silently in the database
  if (partsToUpdate.length > 0) {
    Promise.all(partsToUpdate.map((p) => firestore.collection(partCollectionDbString).doc(p.Sku).update({ config: p.config })));
  }

  const sortedPricing = processPricingData(firestore, partCollectionDbString, parts, pricingData, childViewType);
  return new Promise((resolve: any) => resolve(sortedPricing));
};

export const priceMultiplier = (value: number): string => {
  if (value < 0) return `${Math.floor((value * 100))}%`;
  if (value > 0) return `${Math.floor((value * 100))}%`;
  return '0%';
};

export const nextCustomerPart = (customer: string, customerParts: any[], partTypePattern: string) => {
  const partTypeMatcher = new RegExp(partTypePattern);
  const sameType = customerParts.filter((p: any) => p.type.match(partTypeMatcher));
  // const nextPart = customerParts
  //   .filter((p: any) => p.type.match(partTypeMatcher))
  const nextPart = sameType.map((p: any) => parseInt(p.Sku.split('_')[1], 10) + 1)
    .sort((a: number, b: number) => b - a);
  if (nextPart[0] >= 1000) return `${customer}_0${nextPart[0]}`;
  if (nextPart[0] >= 100) return `${customer}_00${nextPart[0]}`;
  if (nextPart[0] >= 10) return `${customer}_000${nextPart[0]}`;
  return `${customer}_0000${nextPart[0]}`;
  // }
  // return `${customer}_00000`;
};

export const isScannerStation = (userId: string) => {
  const stationIds = [
    'thoqTZixlrhlYxWy52GHUvNUUr42', // station-release
    'uuikiqzedJPiJ0t1zc9bJn2cDQt2', // station-assembly
    'n973nmDar7Xs7j74bmCJ8IcGlXp2', // station-construction
    CONSTRUCTION_JASON.stationId, // station-construction-jason
    'fUl6oWOQOYSHxDkI9BVYzgi8suj1', // station-dryroom
    '7l8NTrVLWFYReCmFYnbabqNXxTe2', // station-cnc
    'kF8mOZ8sIXXAOVPjFyJty8aFCfl1', // station-finishing
    'gEmUhPcMPHeCYUCA8o0dEXdBoSo1', // station-finishing-hand
    'mAmnJq0iJAUpSCZgKC5MIOEHrTD2', // station-qa-shipping
  ];

  return includes(stationIds, userId);
};

export const recordCsvData = (data: IShopOrder[]) => data.map((d: IShopOrder) => [
  d.customer.id,
  d.salesOrder,
  d.orderDate.toDate().toLocaleDateString(),
  d.type,
  d.partCount,
  d.description.replace(/,/g, ';'),
  `$${d.orderValue}`,
  d.releaseDate.toDate().toLocaleDateString(),
  d.runners?.length ? 'Released' : '',
  d.shipDate.toDate().toLocaleDateString(),
  d.completed ? 'Shipped' : '',
]);

export const PART_STATE_ATOM = {
  body: newBodyAtom,
  neck: newNeckAtom,
};

export const PART_DATA_ATOM = {
  body: bodyDataAtom,
  bodyTest: bodyDataAtom,
  neck: neckDataAtom,
  neckTest: neckDataAtom,
};

export const IS_MOBILE = /iPhone|iPad/i.test(navigator.userAgent);

export const RUNNER_BODY_INITIAL_STEP = 'ARft0FS3O';
export const RUNNER_NECK_INITIAL_STEP = 'bIKQ9ANcC';
export const ROUTER_JOB_TYPES = ['body', 'neck'];

export const BODY_HOLD_STEP = '_ic83Hl5F';
export const NECK_HOLD_STEP = 'JRgTgTROh';
export const HOLD_STEPS = ['On Hold', NECK_HOLD_STEP, BODY_HOLD_STEP];
export const READY_STEPS = [RUNNER_BODY_INITIAL_STEP, RUNNER_NECK_INITIAL_STEP, '-sWUWMT8b', 'ro2EFSo_X'];
export const NECK_READY_STEPS = [RUNNER_NECK_INITIAL_STEP, 'ro2EFSo_X'];
export const FINISHING_STEPS = ['Finishing', 'sOspM8A0Bhx', 'DsGT5gQg-dn', 'aaKxatWdehe', 'ejhdPLv2l', '99ehk9DSkpu'];
export const COMPLETE_STEPS = ['Complete', 'vTRZZbKL9', '73TxOo4eh'];
export const DRY_ROOM_STEP = '7kN0pSmXBw3';
export const BODY_WOOD_TYPES = [
  'SwAsh', 'Alder', 'Mahog', 'Pine', 'Rst. Pine', 'Other',
];
export const SLACK_NOTIFICATION_STEPS = [
  'Q9ANbIKcC',
  // 'aaKxatWdehe',
];

// takes an object array and reduces values in the array by key into a single object.
export const reduceObjectArrayValues = (objectArray: any[]) => objectArray.reduce((a: any, b: any) => {
  Object.keys(b).forEach((key) => {
    if (a[key] === undefined) {
      a[key] = b[key];
    } else {
      a[key] += b[key];
    }
  });
  return a;
}, {});
