import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import shortid from 'shortid';
import {
  find, includes, flattenDeep, flatten,
} from 'lodash';
import {
  customerFilterAtom,
  orderWeeksAtom,
  salesOrdersAtom, scheduleViewStartDateAtom,
} from 'shared/state/routingState';
import {
  dateString, getOrderWeeks, MONTHS, monthDates, workWeeksForMonth, isSameDay, weekStartDate, daysElapsedInWeek,
} from 'shared/data/calendar';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { numberWithCommas } from 'shared/text';
import NotFound from 'shared/components/Utility/NotFound';
import { ISalesOrder, IShopOrder } from 'pages/Orders/types';
import Loader from 'shared/components/Utility/Loader';
import { sortList } from 'shared/util';
import { shade, tint } from 'shared/palette/utils';
import { formatPercent, formatPrice } from 'shared/data';
import theme from 'shared/theme';
import { IBomItem, IOrderItem } from 'shared/types/dbRecords';
import { ICreditMemo } from 'shared/types/qbo';
import styled from 'styled-components';
import {
  salesOrderBodyColumns, salesOrderNeckColumns,
} from './Components/DataColumns';
import {
  RouterTableHeaderWrapper,
  RouterTableHeaderDates,
  RouterTableHeader,
  SalesOrderTable,
  WeekPrintWrapper,
  RouterStatHeaderWrapper,
  RouterTablePartValue,
  RouterTableSubHeader,
  RouterTableHeaderDivider,
  RouterWeekSummaryWrapper,
  RouterMonthProjectedWrapper,
  RouterMonthActualWrapper,
  TableHeaderRow,
  ScheduleWeekHeaderRow,
  RouterWeekHeader,
  RouterTableHeaderSectionWrapper,
  RouterTableHeaderDayCount,
  RouterTableHeaderDatesIcon,
  MonthWrapper,
  RouterTableHeaderVerticalDivider,
  PartTableWrapper,
  RouterTotalContentWrapper,
  TableHorizontalDivider,
  RouterTotalTableHeader,
  RouterTableTotalPartValue, RouterTableMonthEndSubHeader,
} from './styledComponents';
import DownloadWeekOrdersButton from './Components/Buttons/DownloadWeekOrdersButton';
import { CNCDailyTag } from './Components/ScheduleTags';
import { neckOrderCNCLoad } from '../../shared/data/order';
import { userSettingsAtom } from '../../shared/state/siteState';
import {
  UnitsPerDayIndicator, DollarTotalIndicator, MaterailCostTotalIndicator, CNCHoursTotalIndicator,
} from './Components/DailyTotalIndicators';

interface IComponent {
  renderItems: IShopOrder[];
  salesOrders: IShopOrder[];
  ordersLoaded: boolean;
  creditMemos: ICreditMemo[];
  showBodies: boolean;
  showNecks: boolean;
}

const PerDayLabel = styled(RouterTablePartValue)`
    @keyframes blink {
        0% {opacity: 1; scale: 1;}
        30% {opacity: 0.7; scale: 0.98;}
        100% {opacity: 1; scale: 1;}
    }
    animation: ${(props: { weighted: boolean }) => (props.weighted ? 'blink 5s linear infinite' : 'unset')};
`;
export default ({
  showBodies, showNecks, salesOrders = [], renderItems = [], ordersLoaded = false, creditMemos = [],
}: IComponent) => {
  const start = useRecoilValue(scheduleViewStartDateAtom);
  const customerFilter = useRecoilValue(customerFilterAtom);
  const [orderRecords, setOrderRecords] = useState<IShopOrder[]>(renderItems);
  const [_ordersLoaded, setOrdersLoaded] = useState<boolean>(ordersLoaded);
  const [_showBodies, setShowBodies] = useState<boolean>(showBodies);
  const [_showNecks, setShowNecks] = useState<boolean>(showNecks);
  const [_salesOrders, setSalesOrders] = useState<IShopOrder[]>(salesOrders);
  const userSettings = useRecoilValue(userSettingsAtom);

  const BODY_AVERAGE_COST = 200;
  const NECK_AVERAGE_COST = 260;

  const orderWeeks = useRecoilValue(orderWeeksAtom);

  const setRowClass = (record: any) => {
    const hash = window.location.hash.replace('#', '');
    return hash === record.id ? 'sales-order-prev-nav' : 'sales-order-row';
  };

  const partTotal = (partList: IShopOrder) => partList.map((o: any) => o.orderValue)
    .reduce((a: number, b: number) => a + b, 0);

  useEffect(() => {
    if (renderItems.length) {
      setOrderRecords(renderItems.map((o: IShopOrder) => {
        const salesOrder = find(_salesOrders, (so: IShopOrder) => so.id === o.id) as IShopOrder;
        return salesOrder || o;
      }));
    } else {
      setOrderRecords(renderItems);
    }

    const timeout = setTimeout(() => {
      if (!renderItems) return;
      let hash = window.location.hash.replace('#', '');
      if (hash.length === 0) {
        const scrollType = userSettings.string.scrollToLocation;
        const today = (scrollType === 'start') ? flattenDeep(orderWeeks)[0] : weekStartDate(orderWeeks);
        hash = (scrollType === 'start') ? `${today.getTime()}` : `${new Date(today).getTime()}`;
      }
      const el = document.getElementById(hash);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [renderItems]);

  useEffect(() => {
    setSalesOrders(salesOrders);
  }, [salesOrders]);

  useEffect(() => {
    setOrdersLoaded(ordersLoaded);
    if (!ordersLoaded) setOrderRecords([]);
  }, [ordersLoaded]);

  useEffect(() => {
    setShowBodies(showBodies);
    setShowNecks(showNecks);
  }, [showBodies, showNecks]);

  // @ts-ignore
  return (
    <>
      {(renderItems.length === 0 && _ordersLoaded) ? (
        <NotFound title="No records found!" message="Try adjusting your search query, or changing the view type, to see more results." compact={false} />
      ) : (
        <>
          {renderItems.length === 0 ? (
            <FlexColumn style={{ width: '100%', height: '50vw' }}>
              <Loader />
            </FlexColumn>
          ) : (
            <>
              {orderWeeks.map((month: Date[][], _index: number) => {
                const today = new Date();
                const monthIndex = month[0][0].getMonth();
                const firstDayOfMonth = new Date(start.getFullYear(), monthIndex);
                const lastDayOfMonth = new Date(start.getFullYear(), monthIndex + 1, 0, 23, 59, 59, 999);
                const isCurrentMonth = today >= firstDayOfMonth && today <= lastDayOfMonth;
                const isPastMonth = today.getMonth() > firstDayOfMonth.getMonth();

                const monthCredits = creditMemos.filter((c: ICreditMemo) => c.TxnDate.toDate() >= firstDayOfMonth && c.TxnDate.toDate() <= lastDayOfMonth);
                const monthCreditTotal = monthCredits.map((c: ICreditMemo) => c.TotalAmt).reduce((a, b) => a + b, 0);

                const allMonthWeeks = getOrderWeeks(firstDayOfMonth, lastDayOfMonth)[0];
                const monthLength = flattenDeep(allMonthWeeks).length;
                const monthOrders = _salesOrders.filter((o: IShopOrder) => o.shipDate.toDate().getMonth() === monthIndex);
                const monthProjectedValue = monthOrders.map((o: any) => o.orderValue).reduce((a: number, b: number) => a + b, 0);
                const monthShippedValue = monthOrders.filter((o: any) => o.completed).map((o: any) => o.orderValue).reduce((a: number, b: number) => a + b, 0);

                const monthBodies = monthOrders.filter((o: IShopOrder) => o.type.match(/body/i));
                const monthShippedBodies = monthBodies.filter((o: IShopOrder) => o.completed);
                const monthBodyOrderItems = flatten(monthBodies.map((o: { orderItems: IOrderItem[] }) => o.orderItems));
                const monthBodyBomCost = monthBodyOrderItems
                  .map((i: IOrderItem) => {
                    if (!i.bom?.length) return 0;
                    return i.bom.map((b: IBomItem) => b.unitCost * i.quantityOrdered).reduce((a, b) => a + b, 0);
                  })
                  .reduce((a, b) => a + b, 0);

                const monthProjectedBodyCount = monthBodies.map((o: IShopOrder) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                const monthProjectedBodiesPerDay = (monthProjectedBodyCount / monthLength).toFixed(1);
                const monthProjectedBodyValue = monthBodies.map((o: IShopOrder) => o.orderValue).reduce((a: number, b: number) => a + b, 0);
                const monthShippedBodyCount = monthShippedBodies.map((o: IShopOrder) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                const monthShippedBodiesPerDay = (monthShippedBodyCount / monthLength).toFixed(1);
                const monthShippedBodyValue = monthShippedBodies.map((o: IShopOrder) => o.orderValue).reduce((a: number, b: number) => a + b, 0);

                const monthNecks = monthOrders.filter((o: IShopOrder) => o.type.match(/neck/i));
                const monthShippedNecks = monthNecks.filter((o: IShopOrder) => o.completed);
                const monthNeckOrderItems = flatten(monthNecks.map((o: { orderItems: IOrderItem[] }) => o.orderItems));
                const monthNeckBomCost = monthNeckOrderItems
                  .map((i: IOrderItem) => i.bom.map((b: IBomItem) => b.unitCost * i.quantityOrdered).reduce((a, b) => a + b, 0))
                  .reduce((a, b) => a + b, 0);

                const monthProjectedNeckCount = monthNecks.map((o: IShopOrder) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                const monthProjectedNecksPerDay = (monthProjectedNeckCount / monthLength).toFixed(1);
                const monthProjectedNeckValue = monthNecks.map((o: IShopOrder) => o.orderValue).reduce((a: number, b: number) => a + b, 0);
                const monthShippedNeckCount = monthShippedNecks.map((o: IShopOrder) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                const monthShippedNecksPerDay = (monthShippedNeckCount / monthLength).toFixed(1);
                const monthShippedNeckValue = monthShippedNecks.map((o: IShopOrder) => o.orderValue).reduce((a: number, b: number) => a + b, 0);

                const monthColorIndex = _index % theme.palette.schedulePalette.length;

                return (
                  <MonthWrapper key={`sales-order-inner-wrapper-${monthIndex}-${shortid.generate()}`} wrapperColor={theme.palette.schedulePalette[monthColorIndex]}>
                    { month.map((week: Date[], index) => {
                      console.log(week);
                      const monthColor = theme.palette.schedulePalette[monthColorIndex];
                      const wrapperColor = shade(monthColor, _index * 0.05);
                      const monthWeeks = workWeeksForMonth(
                        monthDates()[week[0].getMonth()],
                      );
                      const weekIndex = month.length === 1 ? monthWeeks.length : index + 1;
                      const headerMonth = `${MONTHS[monthIndex]} W${weekIndex}`;
                      const headerDates = `${dateString(week[0], true)}-${dateString(week[week.length - 1], true)}`;
                      const headerDayCount = `(${week.length} days)`;
                      today.setHours(0, 0, 0, 0);
                      const isCurrentWeek = week[0] <= today && (week[week.length - 1] > today || isSameDay(week[week.length - 1], today));
                      const isPastWeek = week[week.length - 1] < today;
                      // get index of current day in the currentWeek -- used to map daily average shipped.
                      const currentDayIndex = isCurrentWeek ? week.map((d) => d.getTime()).indexOf(today.getTime()) : -1;

                      const firstDay = new Date(week[0]);
                      firstDay.setHours(0, 0, 0, 0);
                      const sectionId = firstDay.getTime();
                      // const sectionId = isCurrentWeek ? today.getTime() : firstDay.getTime();
                      const weekOrders = orderRecords.filter((o: any) => {
                        const weekDates = week.map((d) => dateString(d, true));
                        return includes(weekDates, o.dates.shipDate);
                      });

                      const orders = sortList(weekOrders, ['customer.id', 'shipDate', 'completedDate']);

                      const bodies = orders.filter((o: ISalesOrder) => o.type.match(/body/i));
                      const bodyCount = bodies.map((o: ISalesOrder) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                      const finishBodies = bodies.filter((o: ISalesOrder) => o.finishing && !o.description.match(/cnc/i));
                      const bodyFinishCount = finishBodies.map((o: ISalesOrder) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                      const cncBodies = bodies.filter((o: ISalesOrder) => o.description.match(/cnc|nfs/i) || !o.finishing);
                      const bodyCNCCount = cncBodies.map((o: ISalesOrder) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                      const bodyOrderItems = flatten(bodies.map((o: { orderItems: IOrderItem[] }) => o.orderItems)) as IOrderItem[];
                      const bodyBomCost = bodyOrderItems
                        .map((i: IOrderItem) => (i.bom || []).map((b: IBomItem) => b.unitCost * i.quantityOrdered).reduce((a, b) => a + b, 0))
                        .reduce((a, b) => a + b, 0);
                      // part value alone, excluding any discounts or service items
                      const bodiesPartValue = bodyOrderItems
                        .map((i: IOrderItem) => i.unitPrice * i.quantityOrdered)
                        .reduce((a, b) => a + b, 0);

                      const necks = orders.filter((o: ISalesOrder) => o.type.match(/neck/i));
                      const neckCount = necks.map((o: any) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                      const finishNecks = necks.filter((o: ISalesOrder) => o.finishing);
                      const neckFinishCount = finishNecks.map((o: ISalesOrder) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                      const cncNecks = necks.filter((o: ISalesOrder) => o.description.match(/cnc|nfs/i) || !o.finishing);
                      const neckCNCCount = cncNecks.map((o: ISalesOrder) => o.partCount).reduce((a: number, b: number) => a + b, 0);
                      const neckOrderItems = flatten(necks.map((o: { orderItems: IOrderItem[] }) => o.orderItems)) as IOrderItem[];
                      const neckCncLoadTime = necks.map((o: IShopOrder) => neckOrderCNCLoad(o))
                        .reduce((a, b) => ({ active: a.active + b.active, latent: a.latent + b.latent }), { active: 0, latent: 0 });

                      const neckBomCost = neckOrderItems
                        .map((i: IOrderItem) => i.bom.map((b: IBomItem) => b.unitCost * i.quantityOrdered).reduce((a, b) => a + b, 0))
                        .reduce((a, b) => a + b, 0);
                      // part value alone, excluding any discounts or service items
                      const necksPartValue = neckOrderItems
                        .map((i: IOrderItem) => i.unitPrice * i.quantityOrdered)
                        .reduce((a, b) => a + b, 0);

                      const bodyPlannedTotal = partTotal(bodies);
                      const bodyOrderTotal = partTotal(bodies.filter((o: any) => o.completed));

                      const neckPlannedTotal = partTotal(necks);
                      const neckOrderTotal = partTotal(necks.filter((o: any) => o.completed));

                      let bodiesFinishPerDay: string|number = Math.round(bodyFinishCount / week.length);
                      if (userSettings?.boolean?.useWeightedPartsPerDay) bodiesFinishPerDay = Math.round((partTotal(finishBodies) / BODY_AVERAGE_COST) / week.length);
                      let bodiesCNCPerDay: string|number = Math.round(bodyCNCCount / week.length);
                      if (bodiesCNCPerDay === 0) bodiesCNCPerDay = '<1';

                      let necksFinishPerDay = Math.round(neckFinishCount / week.length);
                      if (userSettings?.boolean?.useWeightedPartsPerDay) necksFinishPerDay = Math.round((partTotal(finishNecks) / NECK_AVERAGE_COST) / week.length);
                      const necksCNCPerDay = Math.round(neckCNCCount / week.length);
                      // @ts-ignore
                      return (
                        <>
                          {(!customerFilter.length
                            || ((!!orders.length && !!customerFilter.length)))
                            && (bodies.length + necks.length > 0)
                             && (
                             <>
                               <WeekPrintWrapper key={`${sectionId}-week`} wrapperColor={wrapperColor}>
                                 <ScheduleWeekHeaderRow id={sectionId.toString()} key={shortid.generate()} wrapperColor={wrapperColor} addedWidth={32}>
                                   <RouterTableHeaderSectionWrapper justify="flex-start">
                                     <RouterWeekHeader>{headerMonth}</RouterWeekHeader>
                                   </RouterTableHeaderSectionWrapper>
                                   <RouterTableHeaderSectionWrapper>
                                     <RouterTableHeaderDatesIcon />
                                     <RouterTableHeaderDates>{headerDates}</RouterTableHeaderDates>
                                     <RouterTableHeaderDayCount>{headerDayCount}</RouterTableHeaderDayCount>
                                     <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                     {/* <RouterTableHeaderInvoicedIcon /> */}
                                     <RouterTableHeaderDates>{`$${numberWithCommas((Math.round((bodyPlannedTotal + neckPlannedTotal) / week.length)))}`}</RouterTableHeaderDates>
                                     <RouterTableHeaderDayCount>/ day</RouterTableHeaderDayCount>
                                     {(isCurrentWeek) && (
                                     <>
                                       <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                       <RouterTableHeaderDates>{formatPercent((Math.round(bodyOrderTotal + neckOrderTotal) / Math.round(bodyPlannedTotal + neckPlannedTotal)) * 100, 0, false)}</RouterTableHeaderDates>
                                       <RouterTableHeaderDayCount>invoiced</RouterTableHeaderDayCount>
                                     </>
                                     )}
                                   </RouterTableHeaderSectionWrapper>
                                   <RouterTableHeaderSectionWrapper justify="flex-end">
                                     <DownloadWeekOrdersButton weekStart={week[0]} weekColor={wrapperColor} weekEnd={week[week.length - 1]} type="week" context="ordersPage" />
                                   </RouterTableHeaderSectionWrapper>
                                 </ScheduleWeekHeaderRow>
                                 {(_showBodies && !!bodies.length) && (
                                 <PartTableWrapper addedWidth={32}>
                                   <TableHeaderRow wrapperColor={tint(wrapperColor, 0.2)}>
                                     <RouterTableHeaderWrapper id={sectionId.toString()} key={shortid.generate()}>
                                       <RouterTableHeader wrapperColor={wrapperColor}>Bodies</RouterTableHeader>
                                     </RouterTableHeaderWrapper>
                                     <RouterStatHeaderWrapper key={shortid.generate()}>
                                       <RouterTableSubHeader>{`${isPastWeek ? 'Invoiced' : 'Scheduled'}`}</RouterTableSubHeader>
                                       {/* <DailyTagWrapper> */}
                                       <UnitsPerDayIndicator
                                         partType="bodies"
                                         weighted={userSettings?.boolean?.useWeightedPartsPerDay}
                                         weekTotal={bodyCount}
                                         finishPerDay={bodiesFinishPerDay}
                                         cncPerDay={bodiesCNCPerDay}
                                         color={tint(wrapperColor, 0.2)}
                                       />
                                       {!isPastWeek && (
                                       <>
                                         <DollarTotalIndicator
                                           weekTotal={Math.round(bodyPlannedTotal)}
                                           perDay={Math.round(bodyPlannedTotal / week.length)}
                                           color={tint(wrapperColor, 0.2)}
                                         />
                                       </>
                                       )}
                                       {(isPastWeek || isCurrentWeek) && (
                                       <>
                                         {isCurrentWeek && (
                                           <>
                                             <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                             <RouterTableSubHeader>Invoiced</RouterTableSubHeader>
                                           </>
                                         )}
                                         <DollarTotalIndicator
                                           invoiced
                                           weekTotal={Math.round(bodyOrderTotal)}
                                           perDay={Math.round(bodyOrderTotal / (isPastWeek ? week.length : daysElapsedInWeek(week)))}
                                           color={tint(wrapperColor, 0.2)}
                                         />
                                       </>
                                       )}
                                       <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                       <RouterTableSubHeader>Material Cost</RouterTableSubHeader>
                                       <MaterailCostTotalIndicator
                                         weekTotalValue={Math.round(bodyBomCost)}
                                         materialPercentage={bodyBomCost / (isPastWeek ? bodyOrderTotal : bodyPlannedTotal)}
                                         color={tint(wrapperColor, 0.2)}
                                       />
                                     </RouterStatHeaderWrapper>
                                   </TableHeaderRow>
                                   <SalesOrderTable
                                     key={`${sectionId}-body-week-table`}
                                     rowKey="recordId"
                                     size="small"
                             // @ts-ignore
                                     columns={salesOrderBodyColumns}
                                     dataSource={bodies}
                                     pagination={false}
                                     rowClassName={setRowClass}
                                   />
                                 </PartTableWrapper>
                                 )}
                                 {(_showNecks && !!necks.length) && (
                                 <PartTableWrapper addedWidth={32}>
                                   <TableHeaderRow wrapperColor={tint(wrapperColor, 0.2)}>
                                     <RouterTableHeaderWrapper id={sectionId.toString()} key={shortid.generate()}>
                                       <RouterTableHeader>Necks</RouterTableHeader>
                                     </RouterTableHeaderWrapper>
                                     <RouterStatHeaderWrapper key={shortid.generate()}>
                                       <RouterTableSubHeader>{`${isPastWeek ? 'Invoiced' : 'Scheduled'}`}</RouterTableSubHeader>
                                       {/* <DailyTagWrapper> */}
                                       <UnitsPerDayIndicator
                                         partType="necks"
                                         weighted={userSettings?.boolean?.useWeightedPartsPerDay}
                                         weekTotal={neckCount}
                                         finishPerDay={necksFinishPerDay}
                                         cncPerDay={necksCNCPerDay}
                                         color={tint(wrapperColor, 0.2)}
                                       />
                                       {!isPastWeek && (
                                       <>
                                         <DollarTotalIndicator
                                           weekTotal={Math.round(neckPlannedTotal)}
                                           perDay={Math.round(neckPlannedTotal / week.length)}
                                           color={tint(wrapperColor, 0.2)}
                                         />
                                       </>
                                       )}
                                       {(isPastWeek || isCurrentWeek) && (
                                       <>
                                         {isCurrentWeek && (
                                           <>
                                             <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                             <RouterTableSubHeader>Invoiced</RouterTableSubHeader>
                                           </>
                                         )}
                                         <DollarTotalIndicator
                                           invoiced
                                           weekTotal={Math.round(neckOrderTotal)}
                                           perDay={Math.round(neckOrderTotal / (isPastWeek ? week.length : daysElapsedInWeek(week)))}
                                           color={tint(wrapperColor, 0.2)}
                                         />
                                       </>
                                       )}
                                       <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                       <RouterTableSubHeader>Material Cost</RouterTableSubHeader>
                                       <MaterailCostTotalIndicator
                                         weekTotalValue={Math.round(neckBomCost)}
                                         materialPercentage={neckBomCost / (isPastWeek ? neckOrderTotal : neckPlannedTotal)}
                                         color={tint(wrapperColor, 0.2)}
                                       />
                                       {(!isPastWeek && userSettings?.boolean?.showCNCLoad) && (
                                         <>
                                           <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                           <RouterTableSubHeader>CNC Load</RouterTableSubHeader>
                                           <CNCHoursTotalIndicator
                                             weekTotalHours={neckCncLoadTime.active + neckCncLoadTime.latent}
                                             cncPercentage={(neckCncLoadTime.active + neckCncLoadTime.latent) / (9 * 2 * week.length)}
                                             color={tint(wrapperColor, 0.2)}
                                           />
                                         </>
                                       )}
                                     </RouterStatHeaderWrapper>
                                   </TableHeaderRow>
                                   <SalesOrderTable
                                     key={`${sectionId}-neck-week-table`}
                                     rowKey="recordId"
                                     size="small"
                             // @ts-ignore
                                     columns={salesOrderNeckColumns}
                                     dataSource={necks}
                                     pagination={false}
                                     rowClassName={setRowClass}
                                   />
                                 </PartTableWrapper>
                                 )}
                                 <RouterWeekSummaryWrapper key={shortid.generate()} wrapperColor={wrapperColor}>
                                   <RouterTableHeader>Week Total</RouterTableHeader>
                                   <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                   <RouterTableSubHeader>{isCurrentWeek || week[0] >= today ? 'Planned' : 'Invoiced'}</RouterTableSubHeader>
                                   <DollarTotalIndicator
                                     invoiced={!isCurrentWeek && week[0] < today}
                                     weekTotal={Math.round(bodyPlannedTotal + neckPlannedTotal)}
                                     perDay={Math.round((bodyPlannedTotal + neckPlannedTotal) / week.length)}
                                     color={tint(wrapperColor, 0.35)}
                                   />
                                   {isCurrentWeek && (
                                   <>
                                     <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                     <RouterTableSubHeader>Invoiced</RouterTableSubHeader>
                                     <DollarTotalIndicator
                                       invoiced
                                       weekTotal={Math.round(bodyOrderTotal + neckOrderTotal)}
                                       perDay={Math.round((bodyOrderTotal + neckOrderTotal) / daysElapsedInWeek(week))}
                                       color={tint(wrapperColor, 0.35)}
                                     />
                                   </>
                                   )}
                                 </RouterWeekSummaryWrapper>
                               </WeekPrintWrapper>
                               <TableHorizontalDivider />
                             </>
                             )}
                          <>
                            {(index === month.length - 1) && (
                            <>
                              {!isPastMonth
                               && (
                               <RouterMonthProjectedWrapper key={shortid.generate()} wrapperColor={shade(monthColor, 0.3)}>
                                 <RouterTotalContentWrapper>
                                   <RouterTotalTableHeader>Scheduled</RouterTotalTableHeader>
                                 </RouterTotalContentWrapper>
                                 <RouterTotalContentWrapper>
                                   <RouterTableSubHeader>{`$${numberWithCommas(Math.round(monthProjectedValue))} total`}</RouterTableSubHeader>
                                   <RouterTableHeaderVerticalDivider margin="0 16px" />
                                   <RouterTableSubHeader>{`$${numberWithCommas(Math.round(monthProjectedBodyValue))} bodies`}</RouterTableSubHeader>
                                   <RouterTableHeaderVerticalDivider width="1px" height="16px" margin="0 4px" />
                                   <RouterTableTotalPartValue>{`${numberWithCommas(monthProjectedBodyCount)} total (${Math.round(parseFloat(monthProjectedBodiesPerDay))}/day)`}</RouterTableTotalPartValue>
                                   <RouterTableHeaderVerticalDivider margin="0 16px" />
                                   <RouterTableSubHeader>{`$${numberWithCommas(Math.round(monthProjectedNeckValue))} necks`}</RouterTableSubHeader>
                                   <RouterTableHeaderVerticalDivider width="1px" height="16px" margin="0 4px" />
                                   <RouterTableTotalPartValue>{`${numberWithCommas(monthProjectedNeckCount)} total (${Math.round(parseFloat(monthProjectedNecksPerDay))}/day)`}</RouterTableTotalPartValue>
                                 </RouterTotalContentWrapper>
                               </RouterMonthProjectedWrapper>
                               )}
                              {(isCurrentMonth || isPastMonth) && (
                                <RouterMonthActualWrapper key={shortid.generate()} wrapperColor={shade(monthColor, 0.6)} borderRadius={isCurrentMonth ? '0' : '12px 12px 0 0'}>
                                  <RouterTotalContentWrapper>
                                    <RouterTotalTableHeader>Invoiced</RouterTotalTableHeader>
                                  </RouterTotalContentWrapper>
                                  <RouterTotalContentWrapper>
                                    <RouterTableSubHeader>{`$${numberWithCommas(Math.round(monthShippedValue))} total`}</RouterTableSubHeader>
                                    <RouterTableHeaderVerticalDivider margin="0 16px" />
                                    {monthCredits.length > 0 && (
                                      <>
                                        <RouterTableSubHeader>{`$${numberWithCommas(Math.round(monthCreditTotal))} credits`}</RouterTableSubHeader>
                                        <RouterTableHeaderVerticalDivider margin="0 16px" />
                                      </>
                                    )}
                                    <RouterTableSubHeader>{`${formatPrice(Math.round(monthShippedBodyValue))} bodies`}</RouterTableSubHeader>
                                    <RouterTableHeaderVerticalDivider width="1px" height="16px" margin="0 4px" />
                                    <RouterTableTotalPartValue>{`${numberWithCommas(monthShippedBodyCount)} total (${Math.round(parseFloat(monthShippedBodiesPerDay))}/day)`}</RouterTableTotalPartValue>
                                    <RouterTableHeaderVerticalDivider margin="0 16px" />
                                    <RouterTableSubHeader>{`${formatPrice(Math.round(monthShippedNeckValue))} necks`}</RouterTableSubHeader>
                                    <RouterTableHeaderVerticalDivider width="1px" height="16px" margin="0 4px" />
                                    <RouterTableTotalPartValue>{`${numberWithCommas(monthShippedNeckCount)} total (${Math.round(parseFloat(monthShippedNecksPerDay))}/day)`}</RouterTableTotalPartValue>
                                  </RouterTotalContentWrapper>
                                </RouterMonthActualWrapper>
                              )}
                              <RouterMonthActualWrapper key={shortid.generate()} wrapperColor={shade(monthColor, 0.6)}>
                                <RouterTotalContentWrapper>
                                  <RouterTotalTableHeader>Material Cost</RouterTotalTableHeader>
                                </RouterTotalContentWrapper>
                                <RouterTotalContentWrapper>
                                  <RouterTableMonthEndSubHeader>{`Total: ${formatPrice(monthBodyBomCost + monthNeckBomCost)}`}</RouterTableMonthEndSubHeader>
                                  <RouterTableTotalPartValue>{`(${formatPercent((monthBodyBomCost + monthNeckBomCost) / (!isPastMonth ? monthProjectedValue : monthShippedValue))})`}</RouterTableTotalPartValue>
                                  {/* <RouterTableTotalPartValue>{`$${numberWithCommas(Math.round(monthShippedValue / allMonthWeeks.length))}/week`}</RouterTableTotalPartValue> */}
                                  <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                  <RouterTableMonthEndSubHeader>{`Bodies: ${formatPrice(monthBodyBomCost)}`}</RouterTableMonthEndSubHeader>
                                  <RouterTableTotalPartValue>{`(${formatPercent(monthBodyBomCost / (!isPastMonth ? monthProjectedBodyValue : monthShippedBodyValue))})`}</RouterTableTotalPartValue>
                                  <RouterTableHeaderVerticalDivider backgroundColor={tint(wrapperColor, 0.2)} />
                                  <RouterTableMonthEndSubHeader>{`Necks: ${formatPrice(monthNeckBomCost)}`}</RouterTableMonthEndSubHeader>
                                  <RouterTableTotalPartValue>{`(${formatPercent(monthNeckBomCost / (!isPastMonth ? monthProjectedNeckValue : monthShippedNeckValue))})`}</RouterTableTotalPartValue>
                                </RouterTotalContentWrapper>
                              </RouterMonthActualWrapper>
                            </>
                            )}
                          </>
                        </>
                      );
                    })}
                  </MonthWrapper>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
};
