import React from 'react';
import {
  Select, Space,
} from 'antd';
import { sortBy } from 'lodash';
import { ItemDetailsLabel, ItemDetailsColumnWrapper } from 'shared/styledComponents/inputs';
import styled from 'styled-components';
import { FlexRow } from '../../containers/FlexContainer';

interface IComponent {
  changeCallback: (value: string) => void;
  disabled: boolean;
  id: string;
  label: string;
  LabelExtra?: any
  showKey: boolean;
  placeholder: string;
  selectCallback?: (value: string) => void;
  style: any;
  value?: any;
  valueOptions: { label: string; key: string; }[];
  width?: string;
}

interface IValueOption {
  label?: string;
  keycode: string;
  shade: boolean;
  showKey: boolean;
}

const DetailDropdown = styled(Select)`
  width: ${(props: any) => (props.width ? props.width : '150px')};
  overflow-x: hidden;
`;
const DropdownSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

const PartDetailDropdownItemRow = styled(FlexRow)`
  width: 600px;
  height: 100%;
  justify-content: flex-start;
  padding: 0 12px;
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.03)' : 'transparent')};
  border-bottom: 1px solid lightgrey;
  & p {
    color: black;
    margin: 0;
    &:first-of-type {
      font-weight: bold;
      width: 120px;
    }
  }
  & span {
    width: 1px;
    height: 100%;
    margin: 0 8px;
    background-color: lightgrey;
  }
`;

const PartDetailDropdownMenuItem = ({
  showKey, keycode, shade, label = undefined,
}: IValueOption) => (
  <PartDetailDropdownItemRow key={`${keycode}-value-option`} id={`${keycode}-value-option`} shade={shade}>
    { showKey && (
      <>
        <p>{keycode}</p>
        <span />
      </>
    )}
    <p>{label}</p>
  </PartDetailDropdownItemRow>
);
const PartDetailDropdown = ({
  changeCallback, disabled, id, label, placeholder, selectCallback, value, valueOptions, showKey = true, width = undefined, LabelExtra = <div />,
}: IComponent) => {
  const items = valueOptions
    .map((o: { label: string, key: string }, index) => ({
      label: <PartDetailDropdownMenuItem label={o.label} keycode={o.key} shade={index % 2 === 1} showKey={showKey} />,
      value: `${o.key}-${o.label}`,
    }));

  const onSelect = (selectedValue: string) => {
    // this will be the key value for the item selected
    if (selectCallback) selectCallback(selectedValue);
  };

  return (
    <ItemDetailsColumnWrapper key={`${id}-details-wrapper`}>
      {!!label && (
        <LabelWrapper>
          <ItemDetailsLabel key={`${id}-item-details-wrapper`}>{label}</ItemDetailsLabel>
          {LabelExtra}
        </LabelWrapper>
      )}
      <DetailDropdown
        width={width}
        dropdownStyle={{ width: 600, minWidth: 600 }}
        showSearch
        disabled={disabled}
        options={items}
        key={id}
        placeholder={placeholder}
        value={value}
        optionFilterProp="children"
        // @ts-ignore
        onChange={changeCallback}
        onSelect={onSelect}
        filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
      />
    </ItemDetailsColumnWrapper>
  );
};
export default PartDetailDropdown;
