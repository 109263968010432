import React, { useState } from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import useFirebase from 'vendor/Firebase';
import {
  currentCustomerAtom, currentCustomerShippingAddressesAtom, CUSTOMER_SHIPPING_DB_COLLECTION, CUSTOMERS_DB_COLLECTION,
  selectedCustomerShippingAddressAtom,
} from 'shared/state/customerState';
import QBOAuth from 'shared/data/QBO/auth';
import QBOCustomer from 'shared/data/QBO/customer';
import IQBOCustomer from 'shared/types/qbo';
import { ICustomerRecord, IShippingAddress } from 'shared/types/dbRecords';
import { sortBy } from 'lodash';

const ComponentWrapper = styled(ItemDetailsColumnWrapper)`
  margin: 0;
`;
const ActiveSwitch = styled(Switch)`
  margin: 4px 0 6px 0;
`;

const ShippingAddressDefaultSwitch = () => {
  const { firestore, database } = useFirebase();
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const customerShippingAddressDbString = useRecoilValue(CUSTOMER_SHIPPING_DB_COLLECTION);
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const [currentCustomerShippingAddresses, setCurrentCustomerShippingAddresses] = useRecoilState(currentCustomerShippingAddressesAtom);
  const [selectedAddress, setSelectedAddress] = useRecoilState(selectedCustomerShippingAddressAtom);

  const onChange = (toggleState: boolean) => {
    const newAddresses = sortBy(
      currentCustomerShippingAddresses.map((c: IShippingAddress) => ({ ...c, default: c.Id === selectedAddress.Id })),
      (a: IShippingAddress) => !a.default,
    );
    firestore.collection(customerShippingAddressDbString).doc(currentCustomer.id).update({ shippingAddresses: newAddresses }).then(() => {
      setCurrentCustomerShippingAddresses(newAddresses);
      setSelectedAddress({ ...selectedAddress, default: true });
      QBOAuth.refreshToken(database).then(() => {
        QBOCustomer.updateDefaultShippingAddress(currentCustomer, selectedAddress).then((updatedCustomer: IQBOCustomer) => {
          firestore.collection(customerDbString).doc(currentCustomer.id).update({ ...updatedCustomer }).then(() => {
            setCurrentCustomer({ ...currentCustomer, ...updatedCustomer } as ICustomerRecord);
          });
        });
      });
    });
  };

  return (
    <ComponentWrapper key="address-item-details-wrapper">
      <ItemDetailsLabel key="address-item-details-label">Default?</ItemDetailsLabel>
      <ActiveSwitch
        disabled={selectedAddress.default}
        checked={selectedAddress.default}
        checkedChildren="TRUE"
        unCheckedChildren="FALSE"
        onChange={onChange}
      />
    </ComponentWrapper>
  );
};

export default ShippingAddressDefaultSwitch;
