import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Add } from '@styled-icons/material';
import { find, findIndex } from 'lodash';
import shortid from 'shortid';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { WorkOrderColumn } from 'pages/ProductionSchedule/Components/styledComponents';
import { AddButtonRow } from 'shared/components/AddPrice/styledComponents';
import { ORDER_ITEMS_DB_COLLECTION, orderItemsAtom } from 'shared/state/orderState';
import { IOrderItem } from 'shared/types/dbRecords';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import useFirebase from 'vendor/Firebase';
import WorkOrder from './WorkOrder';
import { IRunner } from '../../types';

const WorkOrderAddButton = styled(Button)`
  border-radius: 8px;
`;

const WorkOrderAddIcon = styled(Add)`
  width: 24px;
  bottom: 1px;
  position: relative;
  left: -4px;
`;

interface IComponent {
  editWorkOrderCallback: (workOrders: IRunner[]) => void;
  orderId: string;
  orderItems: IOrderItem[];
  releaseConfirmed: boolean;
  workOrders: IRunner[];
}
const WorkOrderList = ({
  editWorkOrderCallback, orderId, orderItems, releaseConfirmed, workOrders = [],
}: IComponent) => {
  const { firestore } = useFirebase();
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const orderItemsDbCollectionString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const [_workOrders, setWorkOrders] = useState<IRunner[]>(workOrders);
  const [_orderItems, _setOrderItems] = useState<IOrderItem[]>(orderItems);
  const setOrderItems = useSetRecoilState(orderItemsAtom);
  const id = shortid.generate();

  const onAdd = (e: any) => {
    e.preventDefault();

    const workOrder = {
      id: shortid.generate(),
      toppedOrBound: false,
      description: '',
      parts: [],
    } as unknown as IRunner;

    const updatedWorkOrders = [..._workOrders, workOrder];
    setWorkOrders(updatedWorkOrders);
    editWorkOrderCallback(updatedWorkOrders);
  };

  const onDeleteWorkOrder = async (deletedWorkOrder: IRunner) => {
    const newWorkOrders = _workOrders.filter((r: IRunner) => r.id !== deletedWorkOrder.id);
    // setWorkOrders(newWorkOrders);
    const updatedOrderItems = _orderItems.map((o: IOrderItem) => {
      const deletedWorkOrderPart = find(deletedWorkOrder.parts, (p: IOrderItem) => p.id === o.id);
      if (!deletedWorkOrderPart) return o;
      return {
        ...o,
        quantityAssigned: o.quantityAssigned - deletedWorkOrderPart.quantityAssigned,
      };
    });

    const availableItems = updatedOrderItems.filter((o: IOrderItem) => o.quantityAssigned === 0);
    const updatedWorkOrders = newWorkOrders.map((r: IRunner) => {
      const existingParts = [...r.parts];
      availableItems.forEach((o: IOrderItem) => {
        if (!find(r.parts, (p: IOrderItem) => p.id === o.id)) {
          existingParts.push(o);
        }
      });
      return { ...r, parts: existingParts };
    });
    // setCurrentShopOrder({ ...currentShopOrder, runners: newWorkOrders });
    await firestore.collection(orderItemsDbCollectionString).doc(orderId).update({ orderItems: updatedOrderItems });
    setWorkOrders(updatedWorkOrders);
    _setOrderItems(updatedOrderItems);

    editWorkOrderCallback(updatedWorkOrders);
  };

  const onChangeWorkOrder = (updatedWorkOrder: IRunner) => {
    const updatedWorkOrders = [..._workOrders];
    const index = findIndex(updatedWorkOrders, (r: IRunner) => r.id === updatedWorkOrder.id);
    if (index > -1) updatedWorkOrders[index] = updatedWorkOrder;
    setWorkOrders(updatedWorkOrders);
    editWorkOrderCallback(updatedWorkOrders);
  };

  const onUpdateOrderItems = (updatedOrderItems: IOrderItem[]) => {
    _setOrderItems(updatedOrderItems);
  };
  const onIncrementPartCount = (partId: string, workOrder: IRunner, suggestedValue: number, longPress: boolean): [IOrderItem, IOrderItem[]] => {
    const orderItem = find(_orderItems, (o: IOrderItem) => o.id === partId) as IOrderItem;
    const quantityOnWorkOrder = workOrder.parts.filter((p: IOrderItem) => p.id === orderItem.id)
      .map((p: IOrderItem) => p.quantityAssigned)
      .reduce((a, b) => a + b, 0);
    const quantityAssigned = workOrders
      .map((r: IRunner) => r.parts
        .filter((p: IOrderItem) => p.id === orderItem.id)
        .map((p: IOrderItem) => p.quantityAssigned)
        .reduce((a, b) => a + b, 0))
      .reduce((a, b) => a + b, 0);
    const quantityAvailable = orderItem.quantityOpen - quantityAssigned;
    const isIncrement = suggestedValue >= quantityOnWorkOrder;
    /*
      if the quantity available is zero, and the new value is more than the current value, return the item as-is
     */
    if (quantityAvailable === 0 && suggestedValue > quantityOnWorkOrder) return [orderItem, orderItems];

    const newValue = longPress
      ? isIncrement
        ? quantityAvailable + quantityOnWorkOrder
        : 0
      : suggestedValue;
    // if there are no more available parts, return;
    if (orderItem.quantityOpen - newValue < 0) return [orderItem, orderItems];
    // if the orderItem assigned quantity is incremented past 0 in the negative, return
    if (orderItem.quantityAssigned + newValue < 0) return [orderItem, orderItems];
    // if the incremented orderItem assigned quantity would exceed the number of open items, return
    // if (orderItem.quantityAssigned + newValue > orderItem.quantityOpen) return [orderItem, orderItems];
    // if there is a quantity available, increment the quantity assigned into a new copy of the order item
    const updatedOrderItem = { ...orderItem, quantityAssigned: quantityAssigned + (newValue - quantityOnWorkOrder) };
    // find the same part in the work order.
    const workOrderItem = find(workOrder.parts, (o: IOrderItem) => o.id === partId);
    // If it doesn't exist in the work order parts array, use the order item as the new addition to the work order
    const updatedWorkOrderItem = workOrderItem
      ? { ...workOrderItem, quantityAssigned: newValue }
      : { ...updatedOrderItem, quantityAssigned: newValue };

    // now we just need to replace the existing items in both the orderItems array and the workOrder array
    const orderItemIndex = findIndex(_orderItems, (o: IOrderItem) => o.id === updatedOrderItem.id);
    // there is no effective way the orderItemIndex can be -1, since we fetched it from the orderItems in the first place
    const newOrderItems = [..._orderItems];
    newOrderItems[orderItemIndex] = updatedOrderItem;
    firestore.collection(orderItemsDbString).doc(orderId).update({ orderItems: newOrderItems }).then(() => {
      _setOrderItems(newOrderItems);
      setOrderItems(newOrderItems);
    });

    return [updatedWorkOrderItem, newOrderItems];
  };
  useEffect(() => {
    setWorkOrders(workOrders);
    return () => {};
  }, [workOrders]);

  return (
    <WorkOrderColumn key={`${id}-work-order-wrapper`}>
      <WorkOrderColumn key={`${id}-work-order-column`}>
        {_workOrders.filter((w: IRunner) => !w.completed).map((workOrder: IRunner, index) => (
          <WorkOrder
            index={index}
            key={`${workOrder.id}-work-order-record`}
            orderItems={orderItems}
            workOrders={_workOrders}
            workOrder={workOrder}
            salesOrderId={orderId}
            editCallback={onChangeWorkOrder}
            updateWorkOrderItemsCallback={onUpdateOrderItems}
            deleteCallback={onDeleteWorkOrder}
            incrementPartCountCallback={onIncrementPartCount}
          />
        ))}
      </WorkOrderColumn>
      <ScopedComponent whitelist={[...superAdminUsers.emails, ...adminUsers.emails]}>
        <AddButtonRow key={`${id}-work-order-add-button-row`} justify="flex-start" visible>
          <WorkOrderAddButton
            key={`${id}-work-order-add-button`}
            disabled={!releaseConfirmed}
            icon={(<WorkOrderAddIcon />)}
            type="primary"
            onClick={onAdd}
          >
            Work Order
          </WorkOrderAddButton>
        </AddButtonRow>
      </ScopedComponent>
    </WorkOrderColumn>
  );
};

export default WorkOrderList;
