import { React, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';

interface IComponent {
  showModal: boolean;
  title: string;
  content: string;
  cancelLabel: string;
  okCallback: () => Promise<void>;
  cancelCallback: () => void;
  skipCallback: () => void;
}

const BackorderConfirmationModal = ({
  showModal, title, content, cancelLabel, okCallback, cancelCallback, skipCallback,
}: IComponent) => {
  const [show, setShowModal] = useState<boolean>(showModal);

  useEffect(() => {
    setShowModal(showModal);
  }, [showModal]);

  return (
    <Modal
      open={show}
      title={title}
      onOk={okCallback}
      onCancel={cancelCallback}
      footer={[
        <Button key="cancel" onClick={cancelCallback}>
          Edit Shipment
        </Button>,
        <Button
          key="skip"
          type="primary"
          onClick={skipCallback}
        >
          {cancelLabel}
        </Button>,
        <Button key="create" type="primary" onClick={okCallback}>
          Create backorder
        </Button>,
      ]}
    >
      <p>{content}</p>
    </Modal>
  );
};

export default BackorderConfirmationModal;
