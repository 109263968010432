import React from 'react';
import { useRecoilValue } from 'recoil';
import { ItemDetail, ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { orderType } from 'shared/data/order';
import { orderItemsAtom } from 'shared/state/orderState';

const OrderJobType = () => {
  const orderItems = useRecoilValue(orderItemsAtom);

  return (
    <ItemDetailsColumnWrapper key="order-job-type-details-wrapper" width="164px">
      <ItemDetailsLabel key="order-job-typ-details-label">Job Type</ItemDetailsLabel>
      <ItemDetail>{orderType(orderItems) === 0 ? 'Body' : 'Neck'}</ItemDetail>
    </ItemDetailsColumnWrapper>
  );
};

export default OrderJobType;
