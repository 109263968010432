import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Public } from '@styled-icons/material-twotone';

interface IComponent {
  url: string;
}

const WebsiteButton = styled(Button)``;
const WebsiteIcon = styled(Public)`
  width: 20px;
  margin-bottom: 2px;
`;
const CustomerWebsiteButton = ({ url }: IComponent) => {
  const onClick = (e: any) => {
    e.stopPropagation();
    if (url.length) window.open(url);
  };

  return (
    <WebsiteButton
      shape="circle"
      icon={<WebsiteIcon />}
      onClick={onClick}
      disabled={!url}
    />
  );
};

export default CustomerWebsiteButton;
