import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { every, sortBy } from 'lodash';
import { DatePicker } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  dataLoadingAtom,
} from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  adminUserEmailsAtom, nonConformanceViewEndDateAtom, nonConformanceStartDateAtom, shopUserEmailsAtom,
  superAdminUserEmailAtom, supervisorEmailsAtom,
} from 'shared/state/routingState';
import Loader from 'shared/components/Utility/Loader';
import {
  AdminToolsFilterWrapper, ButtonIsland, PageAdminToolsWrapper,
} from 'shared/styledComponents/containers';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import ActiveSelector from 'pages/Customer/Components/PriceListInputs/ActiveSelector';
import { PageTitle } from 'shared/styledComponents/typographicElements';
import ItemListFilter from 'shared/components/Input/ItemListFilter';
import { listFilterQuery } from 'shared/util';
import { IQATicket } from 'shared/types/dbRecords';
import { PricingCol, PageHeaderWrapper } from 'pages/styledComponents';
import moment from 'moment/moment';
import {
  currentQaTicketAtom,
  QA_TICKETS_COLLECTION,
} from 'shared/state/qualityAssuranceState';
import { DatePickerNextIcon, DatePickerPrevIcon } from 'shared/styledComponents/inputs';
import {
  NC_RECORD_END_KEY,
  NC_RECORD_START_KEY,
} from 'shared/data/calendar';
import useFirebase from 'vendor/Firebase';
import NewQATicketButton from './Components/Buttons/NewQATicketButton';
import TicketList from './Components/TicketList';
import NCReportPDFDrawer from '../../PDF/Display/NCReportDrawer';

const InventoryAdminWrapper = styled(FlexColumn)`
  height: auto;
  width: 100%;
  align-items: flex-start;
  background-color: ${(props: any) => (props.testMode ? '#FCEBEC' : theme.palette.neutral.white)};
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  position: sticky;
  top: opx;
  z-index: 1000;
  padding: 2px 0 16px 0;
  gap: 32px;
  @media ${theme.device.laptopL} {
    align-items: center;
  }
`;

const ToolsWrapper = styled(PageAdminToolsWrapper)`
    gap: 24px;
    @media ${theme.device.laptopL} {
      gap: 12px;
    }
`;

const ButtonWrapper = styled(FlexRow)`
    align-items: center;
    margin: 2px 0 0 72px;
    gap: 12px;
    
    @media ${theme.device.laptopL} {
      margin-left: 0;
    }
    
    & h2 {
      margin: 0;
    }
    & h3 {
        margin: 0;
    }
`;

/**
 *   Display component that lists and filters all inventory parts,
 *   e.g., not finished goods
 */
export default () => {
  // @ts-ignore
  const { firestore } = useFirebase();
  const [loading, setLoading] = useRecoilState(dataLoadingAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const supervisorEmails = useRecoilValue(supervisorEmailsAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const shopUsersEmails = useRecoilValue(shopUserEmailsAtom);
  const [qaTickets, setQATickets] = useState<IQATicket[]>([]);
  const currentQaTicket = useRecoilValue(currentQaTicketAtom);
  const qaTicketsDbCollection = useRecoilValue(QA_TICKETS_COLLECTION);
  const [startDate, setStartDate] = useRecoilState(nonConformanceStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(nonConformanceViewEndDateAtom);
  const { RangePicker } = DatePicker;
  const sessionKey = 'qaTicket.query.string';
  const sessionPageKey = 'qaTicket.currentPage';
  const sessionActiveKey = 'qaTicket.activeDisplay';
  const [renderItems, setRenderItems] = useState<IQATicket[]>(qaTickets);
  const [query, setQuery] = useState<string>(sessionStorage.getItem(sessionKey) || '');
  const [activeDisplayType, setActiveDisplayType] = useState<'open'|'closed'|'all'>(sessionStorage.getItem(sessionActiveKey) as 'open'|'closed'|'all' || 'open');
  const [currentPage, setCurrentPage] = useState<number>(parseInt((sessionStorage.getItem(sessionPageKey) || '1'), 10));

  const updatePageData = (tickets: IQATicket[] = qaTickets, searchQuery: string = query, activeType: 'under investigation'|'all' = activeDisplayType): void => {
    const _query = listFilterQuery(searchQuery);
    // @ts-ignore
    const items = sortBy(tickets, (t: IQATicket) => t.createdDate)
      .filter((t: IQATicket) => {
        if (activeType === 'under investigation') return t.underInvestigation && !t.resolutionDate;
        if (activeType === 'resolved') return t.resolutionDate !== null;
        return t;
      })
      .filter((t: IQATicket) => {
        const matchString = `${t.partDescription}${t.partNumber}${t.nonConformanceExceptionCode}${t.department}${t.type}`;
        const matches = _query.map((s: string) => matchString.match(new RegExp(s.trim(), 'i')));
        return every(matches, Boolean);
      });
    setRenderItems(items);
  };

  const fetchQATickets = async (_start: Date = startDate, _end: Date = endDate) => {
    const sDate = new Date(_start.getFullYear(), _start.getMonth(), _start.getDate());
    const eDate = new Date(_end.getFullYear(), _end.getMonth(), _end.getDate());

    let qaTicketDocs = [];
    if (activeDisplayType === 'under investigation') {
      qaTicketDocs = await firestore.collection(qaTicketsDbCollection)
        .where('underInvestigation', '==', true)
        .where('resolutionDate', '==', null)
        .get();
    } else if (activeDisplayType === 'resolved') {
      qaTicketDocs = await firestore.collection(qaTicketsDbCollection)
        .where('resolutionDate', '!=', null)
        .where('createdDate', '>=', sDate)
        .where('createdDate', '<=', eDate)
        .get();
    } else {
      qaTicketDocs = await firestore.collection(qaTicketsDbCollection)
        .where('createdDate', '>=', sDate)
        .where('createdDate', '<=', eDate)
        .get();
    }

    const qaTicketData = qaTicketDocs.docs.map((d) => d.data() as IQATicket);
    updatePageData(qaTicketData);
    return qaTicketData;
  };

  const onChangeFilter = (filterQuery: string) => {
    setQuery(filterQuery);
    sessionStorage.setItem(sessionKey, filterQuery);
    setCurrentPage(1);
    sessionStorage.setItem(sessionPageKey, '1');
    updatePageData(qaTickets, filterQuery, activeDisplayType);
  };

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
    sessionStorage.setItem(sessionPageKey, newPage.toString());
  };

  const onChangeActiveType = (type: 'active'|'inactive'|'all') => {
    setActiveDisplayType(type);
    sessionStorage.setItem(sessionActiveKey, type);
    updatePageData(qaTickets, query, type);
  };

  const onChangeDates = (date: any) => {
    // setSalesOrders([]);
    const [sDate, eDate] = date;
    // setHighlightRefreshDates(true);
    const newStartDate = new Date(sDate.year(), sDate.month(), sDate.date());
    setStartDate(newStartDate);
    localStorage.setItem(NC_RECORD_START_KEY, newStartDate.getTime().toString());

    const newEndDate = new Date(eDate.year(), eDate.month(), eDate.date());
    setEndDate(newEndDate);
    localStorage.setItem(NC_RECORD_END_KEY, newEndDate.getTime().toString());
  };

  useEffect(() => {
    fetchQATickets().then((ticketData) => {
      setQATickets(ticketData);
    });
  }, [startDate, endDate, activeDisplayType]);

  return (
    <>
      <NCReportPDFDrawer qaTicket={currentQaTicket} />
      <PageHeaderWrapper>
        <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails, ...shopUsersEmails.emails, ...supervisorEmails.emails]}>
          <InventoryAdminWrapper id="customer-admin-inputs">
            <ToolsWrapper>
              <ButtonWrapper>
                <PageTitle>Non-conformance Report</PageTitle>
                <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails, ...shopUsersEmails.emails, ...supervisorEmails.emails]}>
                  <ButtonIsland noMinWidth padding="8px 16px">
                    <NewQATicketButton />
                    {/* <ExportQATicketsButton /> */}
                  </ButtonIsland>
                </ScopedComponent>
              </ButtonWrapper>
              <AdminToolsFilterWrapper justify="flex-start">
                <ItemListFilter
                  id="qa-ticket-list-filter"
                  callback={onChangeFilter}
                  currentValue={query}
                  prompt="Filter QA tickets using comma-separated terms, e.g., NASHG, fret, placement"
                />
                <ActiveSelector
                  activeType={activeDisplayType}
                  callback={onChangeActiveType}
                  activeValue="Under Investigation"
                  inactiveValue="Resolved"
                />
                {activeDisplayType !== 'under investigation' && (
                <RangePicker
                  onChange={onChangeDates}
                  value={[moment(startDate), moment(endDate)]}
                  nextIcon={<DatePickerNextIcon />}
                  prevIcon={<DatePickerPrevIcon />}
                />
                )}
              </AdminToolsFilterWrapper>
            </ToolsWrapper>
          </InventoryAdminWrapper>
        </ScopedComponent>
        <PricingCol>
          {loading
            ? (
              <>
                <FlexColumn style={{ width: '100%', height: '50vw' }}>
                  <Loader />
                </FlexColumn>
              </>
            ) : (
              <>
                <TicketList renderItems={renderItems} currentPage={currentPage} onPageChange={onPageChange} />
              </>
            )}
        </PricingCol>
      </PageHeaderWrapper>
    </>
  );
};
