import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  Button, Collapse, DatePicker, Input, Popconfirm, Table,
} from 'antd';
// @ts-ignore
import {
  Box,
  CalendarCheck,
  Check,
  Cogs,
  Copy,
  ExclamationTriangle,
  Hammer,
  History,
  Info,
  Pen,
  Qrcode,
  QuestionCircle,
} from '@styled-icons/fa-solid';
import { DateRange, AttachMoney } from '@styled-icons/material-twotone';
import { rgba, rgbToHex } from 'shared/palette/utils';
import {
  Body, Caption, H3, H4, H6,
} from 'shared/typography';
import { ConfigurationCol, ConfigurationRow } from 'shared/pageElements/styledComponents';
import { IRouteStepButton } from './types';

import { ViewTypeRadioButton } from '../../shared/styledComponents/inputs';

const { RangePicker } = DatePicker;

export const RunnerPanel = styled(Collapse.Panel)`
  width: 100%;
  font-size: 18px;
  font-weight: 500;
`;

export const RunnerWorkcenterRow = styled(FlexRow)`
  width: 100%;
  
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${theme.spacing(16)};
  
  @media ${theme.device.laptop} {
    flex-direction: row;
  }
`;

export const RunnerWorkcenterColumn = styled(FlexColumn)`
  width: 100%;
  align-items: flex-start;
`;

export const RunnerTypeHeader = styled(H6)`
  margin-left: ${theme.spacing(2)};
  margin-top: ${theme.spacing(4)};
`;

export const RunnerColumn = styled(ConfigurationCol)`
    margin: 0 auto;
    margin-bottom: 200px;
    padding: 0px;
`;

export const RunnerButtonWrapper = styled(FlexRow)`
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 48px;
`;
export const RunnerWrapper = styled(Button)`
    width: 100%;
    max-width: 375px;
    //height: auto;
    padding: 24px;
    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    
    position: relative;
    
    border-radius: 12px;
    border: 2px solid ${theme.palette.neutral[200]};
    
    &:disabled {
        border-color: ${theme.palette.success.hue};
        background-color: ${theme.palette.success[200]};
        & p {
            color: ${theme.palette.neutral.black};
        }
        &:hover {
          background-color: ${theme.palette.success[200]};
        }
        // &:nth-child(even) {
        //   background-color: ${rgba(theme.palette.success[100], 0.25)};
        // }
    }
    
    // &:nth-child(even) {
    //   background-color: ${rgba(theme.palette.neutral[100], 0.25)};
    // }
    
    @media ${theme.device.laptop} {
      width: 100%;
      max-width: unset;
    }
`;

export const RunnerManualSearchWrapper = styled(FlexColumn)`
  height: calc(100vh - 48px);
  width: calc(100vw - 48px);
  align-items: flex-start;
  justify-content: flex-start;
  margin: 40px 24px 0px 24px;
  overflow-y: scroll;
  padding-top: 24px;
`;

export const SearchInputLabel = styled(Caption)`
    margin: 0 0 ${theme.spacing(0.5)} 0;
  `;
export const RunnerManualSearchInput = styled(Input)`
  margin-bottom: 16px;
`;

export const RunnerDetailsColumn = styled(FlexColumn)`
  height: 132px;
  justify-content: center;
  align-items: flex-start;
  padding: ${theme.spacing(2)} 0;
`;

export const RunnerCustomerOrderWrapper = styled(FlexRow)`
  justify-content: flex-start;
  gap: 8px;
`;
export const RunnerCustomer = styled(Body)`
  margin: 0;
  font-weight: 500;
  //text-align: left;
  
  @media ${theme.device.laptopL} {
    font-size: ${theme.typography.h4.size.large};
  }
`;

export const RunnerOrderColumn = styled(FlexRow)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  
  border-top-left-radius: ${(props: {topped?: boolean}) => (props.topped ? 0 : '6px')};
  border-bottom-left-radius: 6px;
  // background-color: ${rgba(theme.palette.neutral.white, 0.7)};
`;

export const RunnerSalesOrder = styled(H6)`
  font-weight: 700;
  margin: 0;
`;

export const RunnerOrder = styled(H4)`
  //font-weight: 500;
  margin: 0;
`;

export const RunnerMetaColumn = styled(FlexColumn)`
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: ${theme.spacing(2)};
    margin-left: auto;
    margin-right: ${theme.spacing(2)};
    gap: ${theme.spacing(1)};
`;

export const RunnerValue = styled(Body)`
    min-width: ${theme.spacing(6)};
    min-height: ${theme.spacing(3.5)};
    // position: absolute;
    // top: ${theme.spacing(2)};
    // right: ${theme.spacing(1.5)};
    padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
    background-color: ${theme.palette.success.hue};
    border-radius: ${theme.spacing(1.5)};
    color: white;
    box-shadow: 1px 1px 4px ${rgba(theme.palette.neutral.black, 0.12)};
    font-weight: bold;
    font-size: ${theme.spacing(2)};
    margin: 0;
`;

export const RunnerValuePlaceholder = styled(RunnerValue)`
    background-color: transparent;
    box-shadow: unset;
`;

export const RunnerShipDate = styled(RunnerValue)`
    // top: ${theme.spacing(6.5)};
    background-color: ${(props: {strike: boolean}) => (props.strike ? 'transparent' : theme.palette.neutral[100])};
    box-shadow: ${(props: {strike: boolean}) => (props.strike ? 'unset' : `1px 1px 4px ${rgba(theme.palette.neutral.black, 0.12)}`)};
    color: ${theme.palette.neutral.black};
    text-decoration: ${(props: {strike: boolean}) => (props.strike ? 'line-through' : 'none')};
`;

export const RunnerActualShipDate = styled(RunnerValue)`
    color: ${(props: {shipDelta: number}) => (props.shipDelta > -5 ? theme.palette.warning.D100 : theme.palette.error.D100)};
    background-color: ${theme.palette.neutral[100]};
    margin-right: 8px;
`;

export const ToppedBoundIndicator = styled(RunnerValue)`
  // top: ${theme.spacing(11)}; 
  color: ${theme.palette.neutral.white};
  background-color: ${theme.palette.primary.hue};
`;

export const RunnerPartWrapper = styled(FlexRow)`
  width: 100%;
  
  justify-content: flex-start;
  
  & p {
    margin-left: 8px;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const RunnerParts = styled(Body)`
    margin: 0;
    margin-top: 4px;
    min-width: ${theme.spacing(8)};
    transition: color 0.3s linear;
    
    &:hover {
        & h6 {
            color: ${theme.palette.primary.hue};
    }
    
    &:active{
        & h6 {
            color: ${theme.palette.primary.hue};
    }
`;

export const RunnerNumber = styled.div`
    width: 56px;
    height: 46px;
    background-color: ${theme.palette.primary[100]};
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
`;

export const RunnerCameraButton = styled(Button)`
  @media ${theme.device.laptop} {
    display: none;
  }
`;

export const RunnerDesktopButton = styled(Button)`
  display: none;
  
  margin-left: 24px;
  font-size: 16px;
  line-height: 0;
  
  @media ${theme.device.tablet} {
    font-size: 14px;
  }
  
 @media ${theme.device.laptop} { 
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   
   padding: 4px 16px;
 }
`;

export const OrderActionRow = styled(FlexRow)`
  width: auto;
  //border-top: 1px solid ${theme.palette.neutral[200]};
  padding-top: 6px;
  //margin-top: 2px;
`;

export const NewOrderButton = styled(Button)`
  border-radius: 8px;
  padding: 4px 12px;
  margin-left: 0;
`;

export const RunnerSearchButton = styled(RunnerDesktopButton)`
  margin-left: 4px;
  padding: 4px 12px;
  @media ${theme.device.laptopL} {
    margin-left: 12px;
    &:after {
        content: "Search";
    }
  }
`;

export const CopySalesOrderButton = styled(Button)`
  background-color: ${theme.palette.success.hue};
  border-color: ${theme.palette.success.hue};
  
  &:hover {
    background-color: ${theme.palette.success[900]};
    border-color: ${theme.palette.success[900]};
  }
`;

export const SplitSalesOrderButton = styled(Button)`
  background-color: ${theme.palette.warning.hue};
  border-color: ${theme.palette.warning.hue};

  &:hover {
    background-color: ${theme.palette.warning[900]};
    border-color: ${theme.palette.warning[900]};
  } 
`;

export const DeleteSalesOrderButton = styled(Button)`
  background-color: ${theme.palette.error.hue};
  border-color: ${theme.palette.error.hue};
  
  &:hover {
    background-color: ${theme.palette.error[900]};
    border-color: ${theme.palette.error[900]};
  }
`;

export const OrderRangePicker = styled(RangePicker)`
    //margin-left: 8px;
`;

export const RunnerPageTitle = styled(H3)`
    margin: 0;
  @media ${theme.device.tablet} {
    font-size: 24px;
  }
  @media ${theme.device.laptop} {
    font-size: ${theme.typography.h3.size.medium};
  }
`;

export const ActionRow = styled(FlexRow)`
  justify-content: flex-start;
  align-items: flex-end;
  flex-grow: 2;
  margin-top: 4px; 
  gap: 20px;
`;

export const RunnerHeaderRow = styled(FlexRow)`
    width: 100%;
    
    flex-direction: column;
    align-items: center;
    
    background-color: ${theme.palette.neutral.white};
    
    justify-content: flex-start;
    gap: 24px;
    
    padding: 16px 0px 12px 0px;
    margin-bottom: ${theme.spacing(6)};
    
    @media ${theme.device.tablet} {
      padding: ${theme.spacing(1)} 0;
    }
    @media ${theme.device.laptop} {
      flex-direction: row;
      position: sticky;
      top: 0px;
      z-index: 1000;
      align-items: flex-end;
      margin-bottom: 24px;
      padding: 16px 32px 12px 92px;
      box-shadow: 0px 20px 10px -20px rgba(0,0,0,0.3);
    }
    
    @media ${theme.device.laptopL} {
      padding: 16px 0px 12px 0px;
    }
  
    @media print {
      display: none;
    }
  // &::after {
  //   content: "";
  //   width: 100%;
  //   height: 1px;
  //   background-color: ${theme.palette.neutral[200]};
  //   position: absolute;
  //   bottom: 0px;
  // }
`;

export const ContextDrawerHeader = styled(RunnerHeaderRow)`
  justify-content: space-between;
`;

export const RunnerActionRow = styled(FlexRow)`
    display: none;
    
    @media ${theme.device.laptop} {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-grow: 2;
      margin-top: unset;
    }
`;

export const PartViewerControlRow = styled(FlexRow)`
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 4px;
`;

export const RunnerDetailBackButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RunnerDetailHeaderRow = styled(RunnerHeaderRow)`
    position: sticky;
    top: 0;
    z-index: 9999;
   
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px; 
    background-color: ${theme.palette.neutral.white};
`;

export const RunnerHistoryWrapper = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
`;

interface IDetailsWrapper {
  inverted: boolean;
}

export const DetailsWrapper = styled.div`
    width: 100vw;
    @media ${theme.device.laptopL}{ 
      width: calc(100vw - 100px);
    }
`;

export const RouteStepButtonWrapper = styled(ConfigurationRow)`
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
    @media ${theme.device.laptopL}{
      width: calc(100% - 100px);
      left: 24px;
    }
`;

export const RunnerNote = styled(Input)`
  height: ${theme.spacing(6)};
  margin: ${theme.spacing(2)} 0;
`;

export const RouteStepButton = styled(Button)`
    width: 100%;
    height: 80px; 
    text-align: center;
    border-radius: 12px;
    border: rgba(255, 255, 255, 0.2);
    
    whitespace: normal;
    
    background-color: ${(props: IRouteStepButton) =>
    (props.currentStep ? theme.palette.primary.hue : rgba(theme.palette.neutral[100], 0.2))};
    
    color: ${(props: IRouteStepButton) =>
    (props.currentStep ? theme.palette.neutral.white : theme.palette.neutral.black)};
    
    margin: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
    
    box-shadow: 4px 4px 8px ${rgba(theme.palette.neutral.black, 0.08)}, -4px -4px 8px #FFFFFF, 
      inset 1px 1px 8px ${rgba(theme.palette.neutral.black, 0.02)};
    
    & p {
      font-size: 18px;
      margin: 0;
    }
    
    &:last-of-type {
      margin-bottom: 64px;
    }
    
    @media ${theme.device.laptop} {
        width: 200px;
        height: 200px;
        font-size: 20px;
        min-width: 0;
        text-wrap: pretty;
        &:last-of-type {
          margin-bottom: ${theme.spacing(2)};
        }
    }
`;

export const SalesOrderCompleteButton = styled(Button)`
    width: calc(100vw - 48px);
    height: 48px;
    
    background-color: ${theme.palette.success.hue};
    border: unset;
    box-shadow: 4px 4px 8px ${rgba(theme.palette.neutral.black, 0.08)}, -4px -4px 8px #FFFFFF, 
      inset 1px 1px 8px ${rgba(theme.palette.neutral.black, 0.02)}; 
    color: ${theme.palette.neutral.white};
    
    margin-bottom: 48px;
    
    @media ${theme.device.laptop} {
        width: 400px;
    }
`;

export const AddRunnerColumn = styled(ConfigurationCol)`
    width: calc(100% - ${theme.spacing(4)});
    // height: calc(100vh - ${theme.spacing(4)});
    
    justify-content: flex-start;
    
    margin: ${theme.spacing(2)} 0;
    padding: ${theme.spacing(2)};
`;

export const QRBackButton = styled(Button)`
  position: absolute;
  top: 64px;
  left: 24px;
  z-index: 99999;
  cursor: pointer;
  
  @media print {
    display: none;
  }
`;

export const QRPage = styled(FlexColumn)`
    width: 100vw;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    padding: 0.5in 0 0 0;
  
  @media print {
    //background-color: rgb(255, 0, 0, 0.3);
    padding: 0 0.125in 0.125in 0.25in;
    height: 11in;
    width: 8.5in;
    clear: both;
    page-break-after: always;
  }
`;

export const ShippingPage = styled(QRPage)`
  @media print {
    padding: 0.25in 0 0.125in 0;
    page-break-after: unset;
    clear: unset;
  }
`;

export const QRView = styled(FlexColumn)`
  width: 90%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 0.5in;
  padding-left: 0.125in;
  @media print {
    width: 8.25in;
    height: 11in;
  }
`;

export const ShippingView = styled(FlexColumn)`
  align-items: flex-start;
  width: 90%;
  @media print {
    //width: 100vh;
    position: relative;
    top: -1vw;
    left: 104%;
    transform-origin: top left;
    transform: rotate(090deg);
    width: unset;
  }
`;
export const QRDetails = styled(FlexRow)`
    width: 94.5vw;
    position: relative;
    justify-content: center;
    align-items: flex-end;
    & h3 { margin-bottom: 0; }
    & h6 { margin-bottom: 0; }
    //padding-right: 4vw;
    margin-left: -1vw;
    padding-top: 40px;
    gap: 6px;
`;

export const DryCarveRow = styled(FlexRow)`
  height: 48px;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 24px;
  
  & p {
    font-size: 12px;
    margin-right: 2px;
  }
`;

export const DateUnderline = styled.div`
  height: 1px;
  width: 80px;
  border-bottom: 1px solid ${theme.palette.neutral.black};
`;

export const WorkOrderLabel = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  border: 2px solid ${theme.palette.neutral[500]};
  border-top-width: 1px;
  border-left-width: 1px;
  
  background-color: ${rgba(theme.palette.success[100], 0.35)};
  
  & * {
    //color: white;
    margin: 0;
  }
`;

export const CustomerNameLabel = styled.div`
  padding: 8px 16px;
`;

export const CustomerNameWrapper = styled(FlexColumn)`
  flex-grow: 2;
  align-items: flex-start;
  justify-content: center;
`;

export const DetailWrapper = styled(FlexRow)`
  justify-content: space-between;
  width: 132px;
  padding-bottom: 6px;
  border-bottom: 0.5px solid black;
  
  &:last-of-type {
    border-bottom: none;
  }
`;

export const WorkOrderShipDate = styled(FlexColumn)`
  //width: 100%;
  width: 100px;
  justify-content: flex-end;
  align-items: center;
  //padding-top: 80px;
`;

export const WorkOrderNumber = styled(H6)`
  //position: relative;
  //left: 24px;
  text-align: center;
  padding: 4px;
  border-top: 1px solid ${theme.palette.neutral[100]};
`;

interface ISalesOrderNumberWrapper {
  background?: boolean;
}
export const SalesOrderNumberWrapper = styled(FlexColumn)`
  padding: 4px 12px;
  //background-color: ${(props: ISalesOrderNumberWrapper) => (props.background ? 'rgba(255, 255, 0, 0.2)' : 'transparent')};
  margin-left: 8px;
`;

interface ISalesOrderText {
  bold?: boolean;
}
export const SalesOrderText = styled(Body)`
  margin: 0;
  font-weight: ${(props: ISalesOrderText) => (props.bold ? '500' : 'normal')};
`;

export const ShipDate = styled.h6`
  border-top: none;
  font-size: 20px;
  
  @media print {
    font-size: 20px;
  }
`;

export const HouseSampleMarker = styled.div`
  display: none;
  width: auto;
  height: 52px;
  
  background-color: yellow;
  border-radius: 4px;
  border-bottom: 2px solid #AAA;
  border-right: 2px solid #AAA;
  transform: rotate(-3deg);
  
  font-weight: bold;
  line-height: 0;
  
  & h4 {
    margin: 0;
  }
  padding: 8px 16px;
  margin-bottom: 28px;
  
  @media print {
    display: unset;
    position: relative;
    top: -16px;
    left: 24px;
   height: auto;
    & h4 {
      margin: 0;
    }
  }
`;
export const HeaderColumn = styled(FlexColumn)`
  width: calc(100% - 11*40px);
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
`;
export const HeaderRow = styled(FlexRow)`
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  & h3 {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
  }
  & p {
    font-size: 18px;
    font-weight: 300;
    margin: 6px 0 0 0;
    letter-spacing: -0.5px;
  }
`;
export const FooterColumn = styled(FlexColumn)`
  width: 100%;
  height: 96px;
  padding: 4px;
  margin-top: auto;
  
  justify-content: flex-end;
  align-items: center;
`;

export const QRCodeRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  @media print {
    margin-top: auto;
  }
`;

export const OrderInfoOuterWrapper = styled(FlexColumn)`
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

export const OrderInfoWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: flex-start;
`;

export const OrderInfoBlock = styled(FlexColumn)`
  width: 100px;
  height: 100px;
  border-right: 1px solid ${theme.palette.neutral[200]};
  align-items: flex-end;
  justify-content: center;
  padding: 0 12px 4px 4px;
  
  & * {
    margin: 0;
    color: ${theme.palette.neutral.black};
  }

  & p {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${theme.palette.neutral[800]};
  }
  & h2 {
    font-size: 24px;
    font-weight: 300;
    line-height: 0.8;
    margin-bottom: 8px;
    padding: 3px 4px;
  }
  
  & h4 {
    font-size: 18px;
    font-weight: 300;
    line-height: 0.8;
    padding: 3px 4px;
  }
`;

export const OrderDetailsBlock = styled(FlexColumn)`
  width: 180px;
  height: 100px;
  border-bottom: 0.5px solid ${theme.palette.neutral[400]};
   &:first-child {
     border-right: 1px solid ${theme.palette.neutral[400]};
  //   //margin-right: 8px;
   }
  // &:last-child {
  //   //padding-left: 8px;
  // }
`;

export const OrderDetailRow = styled(FlexRow)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid ${theme.palette.neutral[400]};
  flex-grow: 2;
  
  padding: 0 16px;
  
  & p {
    margin: 0;
    &:first-child {
      font-weight: 600;
    }
    font-size: 14px;
  }
  &:last-child {
    border-bottom: unset;
  }
`;

export const OrderDescriptionText = styled(Body)`
  font-size: 14px;
  align-self: flex-end;
  text-align: right;
  padding: 4px 8px 0 8px;
  //background-color: yellow;
  min-width: 360px;
  margin: 0;
`;

export const IHSCheckbox = styled.div`
  width: 14px;
  height: 14px;
  border: 0.5px solid ${theme.palette.neutral.black};
  background-color: ${theme.palette.neutral.white};
  box-shadow: 1px 1px 0 ${theme.palette.neutral.black};
`;

export const QRCodeWrapper = styled.div`
  position: relative;
  top: 4px;
  //left: 24px;
`;

export const WorkOrderValueLabel = styled(Body)`
  margin: 0;
  padding-bottom: 2px;
  border-bottom: 2px solid ${theme.palette.neutral.black};
`;

export const ShipDateLabel = styled(WorkOrderValueLabel)`
  margin-left: 0;
`;

export const WorkOrderValue = styled(H6)`
  margin: 0;
  margin-top: 8px;
`;

export const QRHeader = styled(H3)`
    margin-bottom: 0;
`;

interface IScannerWrapper {
  scanFound: boolean;
}

export const ScannerWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: ${(props: IScannerWrapper) => (props.scanFound ? theme.palette.success.hue : theme.palette.neutral.black)};
`;

export const ScreenSaverWrapper = styled(Button)`
  width: 100vw;
  position: relative;
  border: unset;
  border-radius: 0;
  background-color: ${theme.palette.neutral.black};
  overflow: hidden;
`;

interface IScreenSaverLogoWrapper {
  left: number|string;
  top: number|string;
}

export const ScreenSaverLogoWrapper = styled.div`
  position: absolute;
  top: ${(props: IScreenSaverLogoWrapper) => props.top};
  left: ${(props: IScreenSaverLogoWrapper) => props.left};
  
  animation: rotate 60s linear infinite;
  @keyframes rotate{
    to{ transform: rotate(360deg); }
  }


  transition: all 5s ease-in-out;
  
  & svg {
    & path {
      fill: ${theme.palette.neutral.white};
    }
  }
`;

export const ScannerLogoWrapper = styled(FlexColumn)`
  height: auto;
  flex-grow: 2;
  margin-top: 24px;
  margin-bottom: 24px;
  justify-content: space-between;
  
  & svg {
    & path {
        fill: ${(props: IScannerWrapper) =>
    (props.scanFound ? theme.palette.neutral.white : theme.palette.neutral.white)};
    }
  }
  
  & h4 {
    color: white;
  }
`;

export const ScannerLogoWrapperInverted = styled(ScannerLogoWrapper)`
  transform: rotate(180deg);
`;

export const ScannerName = styled(H3)`
  width: 100%;
  text-align: center;
  margin-bottom: 48px;
  color: ${theme.palette.neutral.black};
`;

export const ScannerReaderWrapper = styled.div`
  height: 75vh;
  overflow: hidden;
`;

export const NotScanningButton = styled(Button)`
  height: 48px;
  width: 60vw;
  
  color: ${theme.palette.neutral.white};
  border-radius: 4px;
  border: 4px solid ${rgba(theme.palette.warning.hue, 0.4)};
  background-color: ${rgba(theme.palette.warning.hue, 0.2)};
  margin-top: auto;
`;

export const NoOrdersText = styled(H6)`
    margin-top: 24px;
  color: black;
`;

export const RunnerTable = styled(Table)`
    width: 100%;
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(5)};
`;

export const MonthWrapper = styled.div`
    width: calc(100vw - 48px);
    border-radius: 8px;
    padding: 32px;
    margin-bottom: 32px;
    background-color: ${(props: { wrapperColor: string }) => rgba(props.wrapperColor, 0.1)};;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.2), 0px 0px 8px 3px rgba(0,0,0,0.05) inset;
    
    @media ${theme.device.laptopL} {
       width: calc(100% - 4px); 
      margin: 0 auto 32px auto;
    }   
`;

export const MonthHeader = styled.h1`
    position: sticky;
    top: 110px;
    width: 140px;
    height: 76px;
    padding: 16px;
    margin-bottom: 16px;
    z-index: 1000000;
`;
export const WeekPrintWrapper = styled(FlexColumn)`
    padding: 0 16px;
    border-radius: 16px;
    background-color: ${(props: { wrapperColor: string }) => rgba(props.wrapperColor, 0.08)};
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 0 2px 2px rgba(0,0,0,0.05), 0 4px 4px rgba(0,0,0,0.05), 0 8px 8px rgba(0,0,0,0.05);
  @media print {
    width: 100vw;
    height: 100vh;
    
    align-items: center;
    
    page-break-after: always;
  }
`;

export const TableHorizontalDivider = styled.div`
    width: 100px;
    height: 8px;
    border-radius: 4px;
    background-color: white;
    opacity: 0.3;
    margin: 32px auto;
    box-shadow: 0 1px 1px rgba(0,0,0,0.15), 0 2px 2px rgba(0,0,0,0.15), 0 4px 4px rgba(0,0,0,0.15), 0 8px 8px rgba(0,0,0,0.15);
`;

export const SalesOrderTable = styled(RunnerTable)`
  margin-bottom: 8px;
  margin-top: 4px;
  
  & td {
    height: 24px;
    padding-top: 4px;
    paddiing-bottom: 4px;
  }
  
  & th {
    padding: 8px !important;
  }
  
    @media ${theme.device.laptopL} {
        margin-bottom: 24px;
    }
`;

export const ComingSoonTable = styled(SalesOrderTable)`
  margin-bottom: 24px;
`;

export const SplitSalesOrderTable = styled(SalesOrderTable)`
  margin-bottom: 24px;
`;

export const RouterQuickLookTable = styled(RunnerTable)`
  margin-left: 0;
  margin-bottom: ${theme.spacing(2)};
`;

export const SalesWeekTotalTable = styled(RunnerTable)`
  margin-top: 4px;
  margin-bottom: 20px;
  width: 33%;
  display: inline-table;
`;

export const MonthTotalTable = styled(RunnerTable)`
  margin-top: 0;
`;

interface IRouterTableWrapper {
  show: boolean;
}

export const RouterTableWrapper = styled.div`
  display: ${(props: IRouterTableWrapper) => (props.show ? 'block' : 'none')};
`;

export const TableHeaderWrapper = styled.div`
  //width: 100%;
  height: auto;
  padding: 24px 0;
  
  background-color: ${theme.palette.neutral.white};
  border-right: none;
  //position: sticky;
  //top: 0;
  z-index: 100;
  
  @media ${theme.device.laptop} {
    padding: 48px 0 24px 0;
    //top: 224px;
  }
  
  @media print {
    position: unset;
    top: unset;
  }
`;

export const RouterTableSectionWrapper = styled(TableHeaderWrapper)`
  padding-left: 8px;
  @media ${theme.device.laptop} {
    top: 120px;
  }
`;

export const SalesOrderRecordsColumn = styled(FlexColumn)`
  width: 100%;
`;

export const PartTableWrapper = styled(FlexColumn)`
    width: ${(props: { addedWidth: number }) => `calc(100% + ${props.addedWidth || 0}px)`};
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
`;

export const TableHeaderRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  height: 48px;
  background-color: ${(props: { wrapperColor: string }) => `${rgba(props.wrapperColor, 0.7)}`};
  background-blend-mode: multiply;
  backdrop-filter: blur(48px);
  box-shadow: 0 1px 1px rgba(0,0,0,0.08), 0 2px 2px rgba(0,0,0,0.08), 0 4px 4px rgba(0,0,0,0.08), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.08);
  border: ${(props: { wrapperColor: string}) => `1px solid ${props.wrapperColor}`};
  position: sticky;
  z-index: 99;
  top: 154px;
  @media ${theme.device.laptop} {
    height: 36px;
    top: 138px;
  }
  @media ${theme.device.laptopL} {
    height: 48px;
    top: 136px;
  }
`;

export const ComingSoonSectionHeader = styled(H3)`
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
  border-bottom: 2px solid ${theme.palette.neutral[500]};  
`;

export const ComingSoonTableHeader = styled(TableHeaderRow)`
  //margin-bottom: 8px;
`;

export const TableSectionHeaderWrapper = styled(TableHeaderWrapper)`
  width: ${(props: width) => props.width || '5%'};
  height: 100%;
  display: flex;
  background-color: transparent;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0;
  cursor: pointer;

  @media ${theme.device.laptop} {
    flex-direction: row;
    align-content: center;
  } 
`;

export const TableSectionHeaderBlankSpace = styled(FlexRow)`
    width: ${(props: { width: string }) => props.width || '5%'};
`;

export const TableStatsWrapper = styled(TableSectionHeaderWrapper)`
  background-color: ${theme.palette.neutral.white}; 
  & * {
    color: ${theme.palette.neutral.black};
  }
`;

interface IMonthTotalHeader {
  invoiced: boolean;
}
export const MonthTotalHeader = styled(H4)`
  border-radius: 4px;
  padding: 8px;
  background-color: ${(props: IMonthTotalHeader) => (props.invoiced ? theme.palette.success.hue : theme.palette.warning.hue)};
  // foo
  color: white;
  margin: 0 16px;
`;

export const MonthTotalFrame = styled.div`
  height: 1px;
  background-color: ${(props: IMonthTotalHeader) => (props.invoiced ? theme.palette.success.hue : theme.palette.warning.hue)};
  padding: 0 16px;
  flex-grow: 2;
`;

export const TableSectionFooterWrapper = styled(TableSectionHeaderWrapper)`
  justify-content: center;
  background-color: ${(props: IMonthTotalHeader) => (props.invoiced ? rgba(theme.palette.success.hue, 0.35) : rgba(theme.palette.warning.hue, 0.35))};
  
  & h6 {
    letter-spacing: 0.5px;
  }
  
  @media ${theme.device.laptop} {
    top: 0;
    margin-top: 16px;
    margin-bottom: 36px;
    padding: 24px;
    border-bottom: ${(props: IMonthTotalHeader) => `2px solid ${props.invoiced ? theme.palette.success.hue : theme.palette.warning.hue}`};
  }
`;

export const MonthSummaryWrapper = styled(TableSectionFooterWrapper)`
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;
  @media ${theme.device.laptopL} {
    max-width: 1280px;
  }
`;

export const MonthSummaryDetailsWrapper = styled(FlexRow)`
  width: calc(100% - 132px);
  
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 12px;
  
  @media ${theme.device.laptopL} {
    max-width: 1000px;
  }
}}
 
`;

export const RouterTableFooterWrapper = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;

  padding-right: 24px;
  margin-right: 24px;
  border-right: 2px solid black;

  &:last-of-type {
    //border-right: none;
    margin-right: 0;
  }

  @media print {
    flex-direction: row;
    align-items: center;

    & h6 {
      font-size: 20px;
    }
  }
`;

export const MonthSummaryFooterWrapper = styled(RouterTableFooterWrapper)`
  //align-items: center;
  @media (max-width: 1400px) {
    margin-top: 24px;
    //border-right: none;
    &:last-of-type {
      padding-right: 0;
    }
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const RouterSectionHeaderWrapper = styled(TableSectionHeaderWrapper)`
  @media ${theme.device.laptop} {
    top: 124px;
    padding-top: 0;
  }
`;

export const RouterTableHeaderWrapper = styled(RouterTableFooterWrapper)`
  width: ${(props: { width: string }) => props.width || '10%'};
  height: 100%;
  flex-direction: row;
  align-items: center;
  color: ${theme.palette.neutral.white};
  
  & h6 {
    color: white;
  }
  
  &:first-of-type {
    border-right: none;
    padding: 4px 16px;
  }
    
    @media ${theme.device.laptopL} {
      width: ${(props: { width: string }) => props.width || 'unset'};
    }
`;

// @ts-ignore
export const ScheduleWeekHeaderRow = styled(RouterTableHeaderWrapper)`
    justify-content: space-between;
    cursor: pointer;
    width: ${(props: { addedWidth: number }) => `calc(100% + ${props.addedWidth}px)`};
    background-color: ${(props: { wrapperColor: string }) => `${rgba(props.wrapperColor, 0.7)}`};
    backdrop-filter: blur(48px);
    height: 56px;
    margin: 0;
    position: sticky;
    top: 98px;
    z-index: 999;
    scroll-margin-top: 40px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12);
    &:first-of-type {
      border-radius: 0;
      padding: ${(props: { padding: string }) => props.padding || '4px 16px'};
    }
    
    & p {
        text-shadow: rgba(0,0,0,0.15) 0px 0px 2px;
    }
    @media ${theme.device.laptopM} {
      height: 40px;
    }
    @media ${theme.device.laptopL} {
        height: 56px;
        top: 80px;
    }
`;

export const ComingSoonHeaderRow = styled(ScheduleWeekHeaderRow)`
    background-color: ${(props: { wrapperColor: string }) => `${rgba(props.wrapperColor, 0.7)}`};
    padding: ${(props: { padding: string }) => props.padding || '4px 16px'};
`;

export const CustomerRouterTableHeaderWrapper = styled(RouterTableHeaderWrapper)`
  background-color: ${theme.palette.neutral.white};
  border: 1px solid ${theme.palette.neutral[100]};
  & h6 {
    color: ${theme.palette.neutral.black};
  }
`;

export const NeckTableHeaderWrapper = styled(RouterTableHeaderWrapper)`
  background-color: ${theme.palette.primary.hue};
`;

export const RouterTableSubheaderWrapper = styled(RouterTableHeaderWrapper)`
  & p {
    color: black;
  }
`;

export const RouterStatHeaderWrapper = styled(RouterTableHeaderWrapper)`
  width: ${(props: { width: string }) => props.width || '90%'};
  padding: 4px 24px 4px 0px;
  justify-content: flex-start;
  border: none;
  align-items: center;
  margin: 4px 0 0 0;
  & p {
  color: white;
  }
  & h6 {
    color: white;
    font-weight: 400;
    font-size: 18px;
  }
    
  @media ${theme.device.laptopL} {
    width: ${(props: { width: string }) => props.width || '90%'};
  }
`;

export const RouterWeekSummaryWrapper = styled(RouterStatHeaderWrapper)`
  width: calc(100% + 32px);
  height: 52px;
  padding-left: 16px;
  border: none;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${(props: { wrapperColor: string }) => `${rgba(props.wrapperColor, 0.7)}`};
  background-blend-mode: multiply;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.1), 0 4px 4px rgba(0,0,0,0.1), 0 8px 8px rgba(0,0,0,0.1);
`;

export const RouterMonthProjectedWrapper = styled(RouterWeekSummaryWrapper)`
    width: 100%;
    margin-top: 0;
    background-color: ${(props: { wrapperColor: string }) => rgba(props.wrapperColor, 0.6)};
    border: ${(props: { wrapperColor: string }) => `1px solid ${props.wrapperColor}`};
    border-bottom: none;
    border-radius: ${(props: { fullRound: boolean }) => (props.fullRound ? '12px' : '12px 12px 0 0')};
    box-shadow: 0px 20px 10px -20px rgba(0, 0, 0, 0.6) inset, 20px 0px 10px -20px rgba(0, 0, 0, 0.6) inset, -20px 15px 10px -20px rgba(0, 0, 0, 0.6) inset;
`;
export const RouterMonthActualWrapper = styled(RouterMonthProjectedWrapper)`
    border-top: none;
    border-radius: ${(props: { borderRadius: string }) => (props.borderRadius || '0 0 12px 12px')};
    box-shadow: 0px -20px 10px -20px rgba(0, 0, 0, 0.6) inset, 20px 0px 10px -20px rgba(0, 0, 0, 0.6) inset, -20px 15px 10px -20px rgba(0, 0, 0, 0.6) inset;
`;

export const RouterTotalContentWrapper = styled(FlexRow)`
    width: 15%;
    justify-content: flex-start;
    &:nth-child(2) {
      flex-grow: 2;
      justify-content: flex-start;
    }
    &:nth-child(3) {
      justify-content: flex-end;
    }
`;
export const RouterTableHeader = styled(H6)`
  font-size: 22px;
  //font-weight: bold;
  margin: 0;
  
`;

export const RouterTableHeaderSectionWrapper = styled(FlexRow)`
    width: auto;
    height: 100%;
    gap: 6px;
    
    &:nth-child(2) {
      width: 60%;
      margin-left: 12px;
      justify-content: flex-start;
    }
    
    @media ${theme.device.laptopL} {
      width: 25%;
      &:nth-child(2) {
          width: 50%;
          justify-content: center;
      } 
    }
    
`;
export const RouterWeekHeader = styled(RouterTableHeader)`
    font-size: 24px;
    @media ${theme.device.laptopL} {
      font-size: 28px;
    }
`;

export const RouterTableStatWrapper = styled(FlexRow)`
  justify-content: flex-start;
  @media ${theme.device.laptopL} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;
export const RouterTableSubHeader = styled(RouterTableHeader)`
  margin: 0;
  color: ${theme.palette.neutral.white};
  
  & span {
    font-weight: normal;
  }
  
  font-size: 14px;
  
  @media ${theme.device.laptopL} {
    font-size: 16px;
  }
  @media ${theme.device.desktop} {
    font-size: 20px;
  }
`;

export const RouterTableMonthEndSubHeader = styled(RouterTableSubHeader)`

`;

export const RouterTableHeaderDivider = styled.div`
  height: 24px;
  width: 1px;
  margin: 0 16px;
  background-color: ${theme.palette.neutral[400]};
`;

export const RouterTableHeaderWhiteDivider = styled(RouterTableHeaderDivider)`
  width: 2px;
  background-color: ${theme.palette.neutral.white};
  margin: 0 8px;
`;

export const RouterTotalsHeader = styled(RouterTableHeader)`
  font-size: 24px;
  font-weight: bold;
`;

export const RouterTableHeaderDatesIcon = styled(DateRange)`
    width: 24px;
    color: ${theme.palette.neutral.white};
    margin-right: 4px;
    @media ${theme.device.laptopL} {
        width: 28px;
    }
`;
export const RouterTableHeaderInvoicedIcon = styled(AttachMoney)`
    height: 24px;
    color: ${theme.palette.neutral.white};
    @media ${theme.device.laptopL} {
        width: 28px;
    }
`;
export const RouterTableHeaderDates = styled(Body)`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.palette.neutral.white};
  //padding: 6px 8px;
  border-radius: 8px;
  margin: 0;
  //margin-bottom: 4px;
  
  @media ${theme.device.laptopL} {
    font-size: 20px;  
  }
  @media print {
    font-size: 16px;
    margin-left: 24px;
  }
`;
export const RouterTableHeaderDayCount = styled(RouterTableHeaderDates)`
  font-size: 14px;
  font-weight: 400;
  @media ${theme.device.laptopL} {
      font-size: 18px;
  }
`;

export const RouterTableHeaderVerticalDivider = styled.div`
    width: ${(props: { width: string }) => (props.width || '2px')};
    height: ${(props: { height: string }) => (props.height || '28px')};
    border-radius: 4px;
    background-color: ${(props: { backgroundColor: string }) => props.backgroundColor || theme.palette.neutral.white};
    margin: ${(props: { margin: string }) => props.margin || '0 8px'};
    opacity: 0.9;
`;

export const RouterTotalTableHeader = styled(RouterTableHeader)`
    font-size: 18px;
    @media ${theme.device.laptopL} {
      font-size: 22px;
    }
`;

export const CustomerTotalText = styled(RouterTableHeaderDates)`
  color: ${theme.palette.neutral.black}
`;

export const RouterSubItemValue = styled(RouterTableHeaderDates)`
  color: ${theme.palette.neutral.white};
  padding-top: 6px;
  padding-bottom: 0;
`;

export const RouterTablePartsPerDay = styled(RouterTableHeaderDates)`
  color: ${theme.palette.neutral.white};
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 3px 6px;
  margin-left: 8px;
`;

export const NeckTablePartsPerDay = styled(RouterTablePartsPerDay)`
  color: ${theme.palette.primary.hue};
`;

export const RouterTableCompletedPerDay = styled(RouterTablePartsPerDay)`
  padding: 3px 8px;
  background-color: ${theme.palette.success[100]};
  color: ${theme.palette.neutral.black};
  font-weight: bold;
`;

export const RouterTablePartValue = styled(RouterTablePartsPerDay)`
  font-size: 14px;
  font-weight: 400;
  color: black;
  margin-left: 0;
  @media ${theme.device.laptopL} {
      font-size: 16px;
  }
`;

export const RouterTableTotalPartValue = styled(RouterTablePartValue)`
    font-size: 12px;
    @media ${theme.device.laptopL} {
        font-size: 16px;
    }
`;

export const RouterSummaryDetails = styled(RouterTableHeaderDates)`
  color: ${theme.palette.neutral.black};  
  padding-left: 0;

  @media (min-width: 1400px) {
    padding-top: 0;
  }
`;

export const RouterSummaryPartValue = styled(RouterSummaryDetails)`
  font-size: 20px;
  font-weight: bold;
`;

export const RouterTableHeaderStatWrapper = styled(FlexRow)`
  width: 388px;
  padding: 0 8px;
  background-color: lightyellow;
  margin-left: 24px;
  
  justify-content: space-between;
`;
export const RouterTableHeaderStat = styled(Body)`
  margin: 0;
  margin-left: 8px;
  line-height: 0;
`;

export const RouterTableHeaderRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 8px;
`;

export const PartCount = styled(FlexRow)`
  font-size: 16px;
  padding: 0 6px;
  border-radius: 4px;
  & span {
    &:first-of-type {
      font-weight: bold;
    }
  }
  gap: 4px;
`;
export const WholesaleValue = styled(PartCount)`
  background-color: lightyellow;
`;
export const WIPValue = styled(PartCount)`
  background-color: lightblue;
`;
export const RouterTableHeaderDetail = styled(Body)`
    font-size: 18px;
    margin: 0;
`;

export const WorkOrderReleasedCheck = styled(Check)`
  width: 24px;
  color: ${theme.palette.success.hue};
  
  @media print {
    width: 16px;
  }
`;
interface IRouterTDCopy {
  completed?: boolean;
}

export const RouterTDButton = styled(Button)`
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin: 0;
  
  @media ${theme.device.laptopL} {
    font-size: ${theme.typography.body.size.small};
  }
`;

export const OrderDescriptionWrapper = styled(FlexColumn)`
  justify-content: center;
  align-items: flex-start;
  padding: 2px 0;
  margin-left: 16px;
  
  @media ${theme.device.laptopL} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: unset;
    margin-left: 12px;
  }
`;
export const TagWrapper = styled(FlexRow)`
  justify-content: flex-start;
  align-items: center;
  
  @media ${theme.device.laptopL} {
    flex-direction: row;
    justify-content: center;
    gap: 4px;
  }
`;
export const RouterTDCopy = styled(Body)`
    font-size: 12px;
    margin: 0;
    
    font-weight: ${(props: IRouterTDCopy) => (props.completed ? 500 : 400)};
    // font-style: ${(props: IRouterTDCopy) => (props.completed ? 'italic' : 'normal')};
    
    @media ${theme.device.tablet} {
      font-size: 13px;
      padding-top: 2px;
      padding-bottom: 4px;
    }
    @media ${theme.device.laptop} {
    }
    @media ${theme.device.laptopL} {
      font-size: 14px;
      font-size: ${theme.typography.body.size.small};
      margin: 0;
      //paddingg-left: 12px;
    }
    
    & code {
      font-size: 14px; 
      
      @media print {
        font-size: 12px;
      }
    }
  
    .MJT {
      font-weight: bold;
    }
  
  @media print {
    font-size: 12px;
    padding: 4px 0;
    
    & code {
      font-size: 12px;
    }
  }
`;
export const RouterOrderDate = styled(RouterTDCopy)`
  width: auto;
  background-color: rgba(0,0,0,0.05);
  padding: 2px;
  border-radius: 4px;
`;
export const RouterNcIcon = styled(ExclamationTriangle)`
  width: 20px;
  color: goldenrod;
  position: relative;
  right: 12px;
`;

export const OrderShippedIndicator = styled.div`
  width: auto;
  height: 8px;
  display: inline-block;
 
  border-radius: 4px;
  background-color: ${theme.palette.success[200]};
  
  font-size: 14px;
  font-weight: 500;
  line-height: 0;
  
  padding: 10px 12px;
  
  & p {
    margin: 0;
  }
  
  @media print {
    width: 100px;
    height: 12px;
    padding: 4px 2px;
    font-size: 12px;
  }
`;

interface IRunnerTimingDot {
  behind: boolean;
  complete: boolean;
  hold: boolean;
}

export const ConfirmPop = styled(Popconfirm)`
  & .ant-popover-message {
    display: flex;
  }
`;

export const ConfirmIcon = styled(QuestionCircle)`
  width: 18px;
  color: ${theme.palette.primary.hue};
  margin-right: 4px;
`;

export const DotTooltip = styled(FlexColumn)`
  margin-bottom: 0;
  border-radius: 4px;
`;

export const DotTooltipP = styled.p`
  width: 100%;
  text-align: center;
  margin: 0;
  //&:first-of-type{
    padding-bottom: 2px;
    border-bottom: 1px solid ${theme.palette.neutral.white};
  //}
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const RunnerTimingDot = styled.a`
  width: 12px;
  height: 12px;
  border-radius: ${(props: IRunnerTimingDot) => (props.complete ? '15%' : '50%')};
  border-bottom: 1px solid #AAA;
  border-right: 1px solid #AAA;
  // box-shadow: 1px 1px 2px ${rgba(theme.palette.neutral.black, 0.2)};
  background-color: ${(props: IRunnerTimingDot) => (props.hold ? theme.palette.neutral[500] : props.behind ? theme.palette.error.hue : theme.palette.success.hue)};
  
  margin-right: 2px;
  
  @media print {
    width: 8px;
    height: 8px;
    
  }
`;

export const RouterTDDescription = styled(RouterTDCopy)`
`;

export const RouterTDCaption = styled(Caption)`
    display: inline;
    font-size: 16px;
    font-style: italic;
    color: ${theme.palette.neutral[700]};
    margin: 0;
    margin-left: 8px;
`;

export const RouterTDPartCount = styled.div`
    width: 32px;
    height: 32px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-weight: bold;
    font-size: 16px;
    
    border-radius: 4px;
    border: 2px solid ${theme.palette.neutral.white};
    color: ${theme.palette.neutral.black};
    
    padding: 2px;
    margin: 4px 0;
    margin-right: 4px;
`;

export const RouterTDPartSwash = styled(RouterTDPartCount)`
    background-color: #F4FB03;
`;
export const RouterTDPartAlder = styled(RouterTDPartCount)`
    background-color: #FFB23E;
`;
export const RouterTDPartMahog = styled(RouterTDPartCount)`
    background-color: #3ED1FF;
`;
export const RouterTDPartPine = styled(RouterTDPartCount)`
    background-color: #FF87E5;
`;

export const RouterTDPartOther = styled(RouterTDPartCount)`
    background-color: ${(props: { desc: string }) =>
    (props.desc.match(/(c\/?s)/i) ? '#30F100' : '#E0E0E0')};
`;

export const RouterNote = styled.div`
    width: 0px;
    height: 0px;
    
    position: relative;
    top: -2px; 
    
    border-left: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid ${theme.palette.primary.hue};
    
    cursor: pointer;
`;

export const RouterTableActionButton = styled(Button)`
    border-radius: 4px;
    border: unset;
    background-color: ${theme.palette.primary.hue};
    box-shadow: 4px 4px 8px ${rgba(theme.palette.neutral.black, 0.08)}, -4px -4px 8px ${rgba(theme.palette.neutral.white, 0.08)}, 
      inset 1px 1px 8px ${rgba(theme.palette.neutral.black, 0.02)}; 
    &:hover {
        color: ${theme.palette.neutral.white};
        background-color: ${theme.palette.primary.hue};
    }
`;

export const RouterQrcode = styled(Qrcode)`
    width: ${theme.spacing(2.25)};
    color: ${theme.palette.primary.hue};
    transition: color 0.2s linear;
`;

export const RouterInfoIcon = styled(Info)`
   width: ${theme.spacing(1)};
   color: ${theme.palette.primary.hue};
   transition: color 0.2s linear; 
`;

export const SalesOrderWorkIcon = styled(Hammer)`
    width: ${theme.spacing(2.5)};
    color: ${theme.palette.primary.hue};
    transition: color 0.2s linear;
`;

export const CompleteOrderIcon = styled(Box)`
    width: ${theme.spacing(2.5)};
    color: ${theme.palette.primary.hue};
    transition: color 0.2s linear; 
`;

export const MakeItRightIcon = styled(CalendarCheck)`
    width: ${theme.spacing(2.5)};
    color: ${theme.palette.primary.hue};
    transition: color 0.2s linear; 
`;

export const RouterHistoryIcon = styled(History)`
    width: ${theme.spacing(2.5)};
    color: ${theme.palette.primary.hue};
    transition: color 0.2s linear;
`;

export const RouterTableInfoButton = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.neutral.white};
    border-radius: 50%; 
    box-shadow: 1px 1px 2px ${rgba(theme.palette.neutral.black, 0.08)}, -2px -2px 2px ${rgba(theme.palette.neutral.white, 0.08)}, 
      inset 1px 1px 4px ${rgba(theme.palette.neutral.black, 0.02)}; 
    cursor: pointer;
    margin: 2px 0;
    
    transition: all 0.3s ease-in-out;
    
    &:hover ${RouterInfoIcon} {
        color: ${theme.palette.neutral.white};
    }
     
      @media ${theme.device.laptop} {
        &:hover {
            background-color: ${theme.palette.primary.hue};
        } 
    }
`;

export const RouterTableQrButton = styled(RouterTableInfoButton)`
    display: none;
    
    margin-left: 12px;
    &:hover ${RouterQrcode} {
        color: ${theme.palette.neutral.white};
    }
    
    &:hover ${SalesOrderWorkIcon} {
        color: ${theme.palette.neutral.white};
    }
    
    &:hover ${CompleteOrderIcon} {
        color: ${theme.palette.neutral.white};
    }
  
    &:hover ${MakeItRightIcon} {
      color: ${theme.palette.neutral.white};
    }
    
    @media ${theme.device.laptop} {
        display: flex;
    }
`;

export const RouterTableHistoryButton = styled(RouterTableInfoButton)`
    display: none;
    margin-right: 12px;
    &:hover ${RouterHistoryIcon} {
        color: ${theme.palette.neutral.white};
    }
    @media ${theme.device.laptop} {
        display: flex;
    }
`;

export const CreateEditRow = styled(FlexRow)`
  align-items: flex-start;
  gap: 16px;
`;

export const NeckRadioButton = styled(ViewTypeRadioButton)`
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${theme.palette.primary.hue};
  }
`;

// export const TimeMachineOuterWrapper = styled(FlexColumn)``;

export const TimeMachineWrapper = styled(FlexColumn)`
  margin-left: 24px;
  position: relative;
  bottom: 10px;
`;

export const TimeMachineButton = styled(Button)`
  border: none;
  // background-color: ${theme.palette.primary.hue};
  & * {
    color: ${theme.palette.primary.hue};
    width: 12px;
    position: relative;
    bottom: 2px;
  }
  &:disabled {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
    & * {
      color: ${theme.palette.neutral[100]};
    }
  }
`;

export const CopyRecordIcon = styled(Copy)`
  width: 14px;
  top: -1px;
  left: 1px;
  position: relative;
  color: ${theme.palette.neutral[800]};
  
  @media ${theme.device.laptopL} {
    width: 20px;
    top: -1px;
  }
  
`;

export const RouterTableCopyButtonWrapper = styled(FlexRow)`
    visibility: ${(props: any) => (props.hidden ? 'hidden' : 'inherit')};
  height: ${(props: any) => (props.hidden ? '32px' : 'auto')};
  justify-content: flex-end;
  @media ${theme.device.laptopL} {
    padding-right: ${(props: any) => (props.hidden ? '0' : '24px')};
  }
`;
export const RouterTableCopyButton = styled(Button)`
  border-radius: 100%;
  width: 28px;
  height: 28px;
  padding: 0;
  &:hover ${CopyRecordIcon} {
    color: ${theme.palette.primary.hue};
  }
  &:disabled {
    opacity: 0.3;
  }
  
  @media ${theme.device.laptopL} {
    width: 40px;
    height: 40px;
  }
`;
