import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useRecoilValue } from 'recoil';
import useFirebase from '../../../vendor/Firebase';
import { sanitizePartDescription } from '../../../shared/partParser/util';
import { IOrderItem } from '../../../shared/types/dbRecords';
import { PART_VIEWER_COLLECTION } from '../../../shared/state/partViewState';
import { ORDER_ITEMS_DB_COLLECTION } from '../../../shared/state/orderState';

const ComponentButton = styled(Button)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const NormalizePartNamesButton = () => {
  const { firestore } = useFirebase();
  const orderItemsDbCollection = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const partViewerDbCollection = useRecoilValue(PART_VIEWER_COLLECTION);
  const onClick = (e: any) => {
    e.preventDefault();
    firestore.collection('part-viewer-data').get().then((snap) => {
      const sanitized: {
        id: string,
        description: string
      }[] = [];
      snap.forEach((doc) => {
        const data = doc.data();
        const newDescription = sanitizePartDescription(data.description);
        if (data.description !== newDescription) {
          sanitized.push({ id: doc.id, description: newDescription });
        }
      });

      let processed = 0;
      sanitized.forEach((part) => {
        firestore.collection(partViewerDbCollection).doc(part.id).update({ description: part.description }).then(() => {
          processed += 1;
          if (processed === sanitized.length) {
            processed = 0;
            sanitized.forEach((testPart) => {
              firestore.collection(`${partViewerDbCollection}-test`).doc(testPart.id).update({ description: part.description }).then(() => {
                processed += 1;
                if (processed === sanitized.length) {
                  firestore.collection(orderItemsDbCollection).get().then((itemsSnap) => {
                    itemsSnap.forEach((item) => {
                      const orderItems = item.data().orderItems.map((o: IOrderItem) => ({
                        ...o,
                        description: part.description,
                      }));
                      firestore.collection(orderItemsDbCollection).doc(item.id).update({ orderItems });
                    });
                  });
                  Modal.info({
                    title: 'Sanitization complete',
                    content: `Finished sanitizing ${processed} part names. Click OK to reload the page.`,
                  });
                }
              });
            });
          }
        });
      });
    });
  };

  return (
    <ComponentButton onClick={onClick}>Normalize part names</ComponentButton>
  );
};

export default NormalizePartNamesButton;
