import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { AddCircle, Brush, ContentCopy } from '@styled-icons/material';
import { ContentCopy as ContentCopyTwoTone } from '@styled-icons/material-twotone';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentCustomerAtom } from 'shared/state/customerState';
import { nextCustomerPart } from 'shared/data';
import {
  copyDataAtom,
  copyDataLoadedAtom,
  currentCustomerPrices,
} from 'shared/state/pricingState';
import theme from 'shared/theme';
import { configToDescription } from 'shared/partParser/util';

const PartCopyButton = styled(Button)`
  opacity: ${(props: any) => (!props.copyDataLoaded ? 1 : 0.2)};
  &:hover {
    cursor: ${(props: any) => (props.isPriced ? 'pointer' : 'default')};
  }
`;
const PricedPartCopyIcon = styled(ContentCopyTwoTone)`
  width: 18px;
  margin-bottom: 2px;
  color: ${theme.palette.primary.hue};
`;
const PartCopyIcon = styled(ContentCopy)`
  width: 18px;
  margin-bottom: 2px;
`;

const PartCloneButton = styled(Button)``;
const PartCloneIcon = styled(AddCircle)`
  width: 22px;
  margin-bottom: 3px;
  color: ${theme.palette.success.hue} !important;
`;

const PartPasteButton = styled(Button)`
  opacity: ${(props: any) => (props.copyDataLoaded ? 1 : 0.2)};
`;
const PasteButtonIcon = styled(Brush)`
  height: 22px;
  margin-bottom: 4px;
  color: ${theme.palette.success.hue} !important;
  // color: ${(props: any) => (props.copyData ? theme.palette.success.hue : theme.palette.neutral[800])} !important;
`;

interface IComponent {
  isPriced: boolean;
  partType: 'body'|'neck';
  pricedItem: any;
}
const CopyItemButton = ({ isPriced, partType, pricedItem }: IComponent) => {
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const customerPricing = useRecoilValue(currentCustomerPrices);
  const [copyData, setCopyData] = useRecoilState(copyDataAtom);
  const [copyDataLoaded, setCopyDataLoaded] = useRecoilState(copyDataLoadedAtom);
  const [cloneData, setCloneData] = useState<any>(null);

  const onCopy = (e: any) => {
    e.stopPropagation();
    const partTypePattern = pricedItem.type.match(/[G|B]N/) ? '[G|B]N' : '[G|B]B';
    const nextPart = nextCustomerPart(currentCustomer.id, customerPricing, partTypePattern);
    const _copyData = {
      ...pricedItem,
      Sku: nextPart,
      id: nextPart,
      oldPartNumber: pricedItem.Sku,
      oldDescription: configToDescription(pricedItem.config),
      childParts: null,
    };
    setCopyData(_copyData);
    setCloneData(_copyData);
    setCopyDataLoaded(true);
  };

  // if instead of pasting data on to another part, we need to simply clone a part, use this logic.
  const onClone = (e: any) => {
    e.stopPropagation();
    const partTypePattern = pricedItem.type.match(/[G|B]N/) ? '[G|B]N' : '[G|B]B';
    const nextPart = nextCustomerPart(currentCustomer.id, customerPricing, partTypePattern);
    const newRecord = {
      ...copyData,
      Sku: nextPart,
      Description: `COPY_${configToDescription(copyData.config)}`,
      customer: currentCustomer.id,
    };
    localStorage.setItem('pricing.temp.part', JSON.stringify(newRecord));
    window.location.href = `/pricing/${partType}?customer=${currentCustomer.id}&copy=true`;
  };

  const onPaste = (e: any) => {
    e.stopPropagation();
    const pasteObject = {
      ...pricedItem,
      customer: pricedItem.Sku.split('_')[0],
      Sku: pricedItem.Sku,
      oldPartNumber: pricedItem.Sku,
      Description: configToDescription(pricedItem.config),
      pricing: copyData.pricing,
    };
    setCopyDataLoaded(true);
    localStorage.setItem('pricing.temp.part', JSON.stringify(pasteObject));
    window.location.href = `/pricing/${partType}?customer=${currentCustomer.id}&copy=true&paste=true`;
  };

  return (
    <>
      {(isPriced && !cloneData) && (
        <Tooltip placement="top" title="Copy part data">
          <PartCopyButton
            shape="circle"
            isPriced={isPriced}
            copyDataLoaded={copyDataLoaded}
            // @ts-ignore
            icon={pricedItem ? <PricedPartCopyIcon /> : <PartCopyIcon />}
            onClick={onCopy}
          />
        </Tooltip>
      )}
      {(isPriced && cloneData) && (
      <Tooltip placement="top" title="Duplicate this part">
        <PartCloneButton
          shape="circle"
          isPriced={isPriced}
      // @ts-ignore
          icon={<PartCloneIcon />}
          onClick={onClone}
        />
      </Tooltip>
      )}
      {!isPriced && (
      <Tooltip placement="top" title="Paint data onto this part">
        <PartPasteButton
          shape="circle"
          copyDataLoaded={copyDataLoaded}
          disabled={!copyDataLoaded}
      // @ts-ignore
          icon={<PasteButtonIcon copyData={copyDataLoaded} />}
          onClick={onPaste}
        />
      </Tooltip>
      )}
    </>
  );
};

export default CopyItemButton;
