import React, { useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { Button, Modal } from 'antd';
import { AddCircle } from '@styled-icons/material';
import SimpleModal from '../../../../../../shared/components/Utility/SimpleModal';
import { currentCustomerAtom } from '../../../../../../shared/state/customerState';

const NewModal = styled(Modal)`
  width: 352px !important;
  text-align: center;
  border-radius: 12px;
  
  & .ant-modal-header {
    border-bottom: none;
  }
  
  & .ant-modal-body { 
    display: none;
  }
  
  & .ant-modal-footer {
    display: flex;
    justify-content: center;
    gap: 8px;
    border-top: none;
  }
  
  & .ant-modal-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 28px;
  }
`;
const NewButton = styled(Button)`
  border-radius: 8px;
  flex-grow: 2;
`;
const NewModalButton = styled(NewButton)`
  font-size: 16px;
  line-height: 16px;
  width: 50%;
`;
const NewIcon = styled(AddCircle)`
    width: 20px;
    position: relative;
    left: -4px;
    margin: 0 4px 3px 0px;
`;
/**
 * Button component that is responsible for creating new parts, exporting pricing information, and filtering existing parts.
 */
export default () => {
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const [modalOpen, setModalOpen] = useState(false);

  const onNew = (e: any) => {
    const path = currentCustomer.id ? `/pricing/${e.key}?customer=${currentCustomer.id}` : `/pricing/${e.key}`;
    window.location.href = path;
  };

  const onNewNeck = (e: any) => {
    const path = currentCustomer.id ? `/pricing/neck?customer=${currentCustomer.id}` : '/pricing/neck';
    window.location.href = path;
  };

  const onNewBody = (e: any) => {
    const path = currentCustomer.id ? `/pricing/body?customer=${currentCustomer.id}` : '/pricing/body';
    window.location.href = path;
  };

  const onNewCancel = (e: any) => {
    setModalOpen(false);
  };

  const onNewClick = (e: any) => {
    setModalOpen(true);
  };

  return (
    <>
      <SimpleModal
        modalOpen={modalOpen}
        title="What kind of part do you want to create?"
        buttons={[
          {
            label: 'Body', onClick: onNewBody, key: 'body', type: 'default',
          },
          {
            label: 'Neck', onClick: onNewNeck, key: 'neck', type: 'primary',
          },
        ]}
        onCancel={onNewCancel}
      />
      <NewButton type="primary" onClick={onNewClick} icon={<NewIcon />}>New</NewButton>
    </>
  );
};
