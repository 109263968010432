import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

interface IComponent {
  notes: string;
}

const BORDER_RADIUS = 3;
const COL_HEIGHT = 30;

const style = StyleSheet.create({
  componentWrapper: {
    width: '96%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 24,
  },
  notesHeader: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 10,
    paddingTop: 16,
    width: '100%',
    borderBottom: '2px solid black',
  },
  notes: {
    fontFamily: 'Red Hat Display',
    fontSize: 9,
    height: 'auto',
    paddingTop: 2,
  },
});
const PackingSlipNotes = ({ notes }: IComponent) => (
  <View style={style.componentWrapper}>
    <Text style={style.notesHeader}>Notes</Text>
    <Text style={style.notes}>{notes}</Text>
  </View>
);

export default PackingSlipNotes;
