import React from 'react';
import { Image, Tooltip } from 'antd';
import styled from 'styled-components';
import { FlexRow } from 'shared/containers/FlexContainer';
import { dateString } from 'shared/data/calendar';
import theme from 'shared/theme';
import { INCRecord } from 'shared/types/dbRecords';
import {
  RouterInfoIcon,
  RouterNote,
  RouterTableInfoButton,
  RouterTDCopy,
} from 'pages/ProductionSchedule/styledComponents';
import NCOrderLinkButton from './NCOrderLinkButton';
import ImageThumbnailButton from './ImageThumbnailButton';

const shortid = require('shortid');

const CellWrapper = styled(FlexRow)`
  height: 100%;
  width: calc(100% - 2px);
  padding: 0 2px;
  align-items: center;
  justify-content: ${(props) => (props.justify || 'center')};
  border-right: 1px solid lightgray;
  border-left: ${(props) => (props.firstCell ? '1px solid lightgray' : 'none')};
  &, & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & p {
    margin-left: ${(props) => (props.justify && props.justify !== 'center' ? '8px' : 0)};
  }
`;

const DetailsTooltip = styled(Tooltip)`
  margin-bottom: auto;
  right: -2px;
  top: 0;
`;

const PartDetailTooltip = styled(DetailsTooltip)`
  margin-bottom: auto;
  right: -2px;
  top: 0;
  @media ${theme.device.laptopL} {
    display: none;
  }
`;

const ImageLinkRow = styled(CellWrapper)`
  gap: 8px
`;

const ImagePreview = styled(Tooltip)`
  background-image: ${(props: any) => `url${props.imageUrl}`};
  background-size: contain;
  background-repeat: no-repeat;
`;

const SALES_ORDER_COLUMN = {
  title: 'SO#',
  dataIndex: shortid.generate(),
  render: (text: string, record: INCRecord) => {
    const orderInt = parseInt(record.salesOrder, 10);
    let orderText = orderInt.toString();

    if (!Number.isNaN(orderInt) && orderInt > 10000) {
      let zeroes = '';
      if (orderInt - 10000 < 100) zeroes = '0';
      if (orderInt - 10000 < 10) zeroes = '00';

      orderText = `${zeroes}${orderInt - 10000}`;
    } else {
      orderText = record.salesOrder;
    }
    return ({
      props: {
        style: {
          textAlign: 'center',
        },
      },
      children:
  <CellWrapper>
    <NCOrderLinkButton key={`${record.id}-salesOrder`} id={`so-${record.salesOrder}`} record={record} />
  </CellWrapper>,
    });
  },
};
const CUSTOMER_COLUMN = {
  title: 'Customer',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: INCRecord, b: INCRecord) => {
      if (a.customerId > b.customerId) return 1;
      if (b.customerId > a.customerId) return -1;
      return 0;
    },
    multiple: 1,
  },
  render: (text: string, record: any) => (
    <CellWrapper id={record.id}>
      <RouterTDCopy key={`${record.id}-customer`}>{record.customerId}</RouterTDCopy>
    </CellWrapper>
  ),
};
const DEPARTMENT_COLUMN = {
  title: 'Dept.',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: INCRecord, b: INCRecord) => {
      if (a.department > b.department) return 1;
      if (b.department > a.department) return -1;
      return 0;
    },
    multiple: 2,
  },
  render: (text: string, record: any) => {
    const departmentMap = {
      'QA/Shipping': 'QA',
      Finishing: 'FIN',
      CNC: 'CNC',
      Milling: 'MIL',
      Construction: 'CON',
      Office: 'OFF',
    };
    return (
      <CellWrapper>
        <RouterTDCopy key={`${record.id}-department`}>{departmentMap[record.department]}</RouterTDCopy>
      </CellWrapper>
    );
  },
};
const PART_COLUMN = {
  title: 'Part #',
  dataIndex: shortid.generate(),
  render: (text: string, record: INCRecord) => (
    <CellWrapper justify="center">
      <RouterTDCopy key={`${record.id}-part`}>{record.partNumber}</RouterTDCopy>
    </CellWrapper>
  ),
  ellipsis: true,
};
const PART_DESCRIPTION_COLUMN = {
  title: 'Part Desc.',
  dataIndex: shortid.generate(),
  ellipsis: true,
  render: (text: string, record: INCRecord) => {
    const chunks = record.partDescription.split('_');
    const bodyLastIndex = _.last(chunks).match(/lb/i) ? 2 : 1;
    const desc = record.type === 'body' ? `${chunks[0]}_${chunks[1]}_${chunks[chunks.length - bodyLastIndex]}` : `${chunks[0]}_${chunks[1]}_${chunks[chunks.length - 1]}`;
    return (
      <CellWrapper justify="space-between">
        <RouterTDCopy key={`${record.id}-partdesc`}>{`${record.customerId} - ${desc}`}</RouterTDCopy>
        <PartDetailTooltip trigger={['click']} title={record.partDescription} placement="top" overlayClassName="tooltip-blur" overlayStyle={{ maxWidth: 800 }}>
          <RouterNote key={shortid.generate()} />
        </PartDetailTooltip>
      </CellWrapper>
    );
  },
  // whitespace: 'nowrap',
};
const OPERATOR_COLUMN = {
  title: 'Operator',
  dataIndex: shortid.generate(),
  render: (text: string, record: INCRecord) => {
    const [first, last] = record.shopOperator.split(' ');
    return (
      <CellWrapper justify="center">
        <RouterTDCopy key={`${record.id}-operator`}>{first}</RouterTDCopy>
      </CellWrapper>
    );
  },
};
const REASON_COLUMN = {
  title: 'Reason',
  dataIndex: shortid.generate(),
  render: (text: string, record: INCRecord) => (
    <CellWrapper justify="flex-start">
      <RouterTDCopy key={`${record.id}-reason`}>{record.reasonCode}</RouterTDCopy>
    </CellWrapper>
  ),
};
const ACTION_COLUMN = {
  title: 'Action taken',
  dataIndex: shortid.generate(),
  render: (text: string, record: INCRecord) => (
    <CellWrapper justify="space-between">
      <RouterTDCopy key={`${record.id}-action`}>{record.correctiveAction}</RouterTDCopy>
    </CellWrapper>
  ),
};
const DATE_COLUMN = {
  title: 'Date',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: INCRecord, b: INCRecord) => {
      if (a.dateRecorded.toDate() > b.dateRecorded.toDate()) return 1;
      if (b.dateRecorded.toDate() > a.dateRecorded.toDate()) return -1;
      return 0;
    },
    multiple: 1,
  },
  render: (text: string, record: INCRecord) => (
    <CellWrapper firstCell justify="center">
      <RouterTDCopy key={`${record.id}-date`}>{dateString(record.dateRecorded.toDate(), false)}</RouterTDCopy>
    </CellWrapper>
  ),
  width: 75,
};

const QUANTITY_COLUMN = {
  title: 'Qty.',
  dataIndex: shortid.generate(),
  render: (text: string, record: INCRecord) => (
    <CellWrapper justify="center">
      <RouterTDCopy key={`${record.id}-quantity`}>{record.quantity}</RouterTDCopy>
    </CellWrapper>
  ),
  width: 75,
};

const onInfoClick = (record: INCRecord) => (e: any) => {
  e.preventDefault();
  window.location.href = `/nc/record?editMode=edit&orderId=${record.salesOrder.id}&ncId=${record.id}`;
};

export const INFO_BUTTON_COLUMN = {
  title: '',
  key: 'info button',
  render: (text: string, record: INCRecord) => (
    <FlexRow style={{ gap: 4 }} key={shortid.generate()} justify="flex-start">
      <ImageThumbnailButton imageUrls={record.imageUrls || []} />
      <Tooltip placement="top" title="View/Edit">
        <RouterTableInfoButton key={shortid.generate()} onClick={onInfoClick(record)}><RouterInfoIcon /></RouterTableInfoButton>
      </Tooltip>
    </FlexRow>
  ),
};

export const IMAGES_COLUMN = {
  title: '',
  key: 'images',
  render: (text: string, record: INCRecord) => (
    <ImageLinkRow key={shortid.generate()}>
      {record.imageUrls?.map((url: string, index) => (
        <Image
          placeholder={<RouterTDCopy>{index + 1}</RouterTDCopy>}
          width={50}
          alt={index + 1}
          src={url}

        />
      ))}
    </ImageLinkRow>
  ),
};

const MICRO_COLUMN = window.innerWidth > 800 ? 75 : 75;
const SMALL_COLUMN = window.innerWidth > 800 ? 80 : 80;
const MED_COLUMN = window.innerWidth > 800 ? 100 : 100;
const LARGE_COLUMN = window.innerWidth > 800 ? 500 : 500;
export const ncColumns = [
  { ...DATE_COLUMN, width: 75 },
  { ...DEPARTMENT_COLUMN, width: 80 },
  { ...OPERATOR_COLUMN, width: 80 },
  { ...PART_DESCRIPTION_COLUMN, width: 200 },
  { ...QUANTITY_COLUMN, width: 50 },
  { ...REASON_COLUMN, width: 150 },
  { ...ACTION_COLUMN, width: 150 },
  { ...INFO_BUTTON_COLUMN, width: 50 },
];

export const ncDepartmentColumns = [
  { ...DATE_COLUMN, width: 75 },
  { ...OPERATOR_COLUMN, width: 80 },
  // { ...CUSTOMER_COLUMN, width: SMALL_COLUMN },
  { ...PART_DESCRIPTION_COLUMN, width: 200 },
  { ...QUANTITY_COLUMN, width: 50 },
  { ...REASON_COLUMN, width: 150 },
  { ...ACTION_COLUMN, width: 150 },
  { ...INFO_BUTTON_COLUMN, width: 50 },
];
