import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { MarkEmailUnread, MarkEmailRead } from '@styled-icons/material';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import { useRecoilValue } from 'recoil';
import { omit } from 'lodash';
import { AuthContext } from '../../../../vendor/Firebase/AuthProvider';
import { MESSAGE_DB_PATH_ATOM } from '../../../../shared/state/messageState';

const UnreadButton = styled(Button)`
  width: 32px;
  height: 32px;
  border: none;
`;

const MarkEmailUnreadIcon = styled(MarkEmailUnread)`
  width: 28px;
  height: 28px;
  color: ${theme.palette.primary.hue};
`;

const MarkEmailReadIcon = styled(MarkEmailRead)`
  width: 28px;
  height: 28px;
  color: ${theme.palette.primary.hue};
`;

interface IComponent {
  id: string;
  isRead: boolean;
  personal: boolean;
}
const MarkEmailUnreadButton = ({ id, isRead, personal }: IComponent) => {
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const { database } = useFirebase();
  const [read, setRead] = useState<boolean>(isRead);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);

  const onClick = (e: any) => {
    const databasePath = `/${messageDbString}${personal ? `/${currentUser.email.split('@')[0]}` : ''}`;
    database.ref(databasePath).once('value').then((data) => {
      const messages = data.val();
      const messageGroup = isRead ? messages.read : messages.unread;
      const readMessage = messageGroup[id];
      const updateObject = isRead ? {
        read: omit(messages.read, [id]),
        unread: { ...messages.unread, [readMessage.id]: { ...readMessage, archived: false } },
      } : {
        unread: omit(messages.unread, [id]),
        read: { ...messages.read, [readMessage.id]: { ...readMessage, archived: true } },
      };

      database.ref(databasePath).set(updateObject).then(() => {
        setRead(read);
      });
    });
  };

  return (
    <Tooltip
      placement="left"
      title={read ? 'Mark unread' : 'Mark read'}
    >
      <UnreadButton key={`mark-mail-unread-${id}`} shape="circle" onClick={onClick} type="ghost" icon={read ? <MarkEmailUnreadIcon /> : <MarkEmailReadIcon />} />
    </Tooltip>
  );
};

export default MarkEmailUnreadButton;
