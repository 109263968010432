import React from 'react';
import styled from 'styled-components';
import { Calculate } from '@styled-icons/material';
import { Button } from 'antd';
import { useSetRecoilState } from 'recoil';
import { showDensityCalculatorAtom } from 'shared/state/pricingState';

const DensityCalcLinkButton = styled(Button)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-top: 12px;
`;
const DensityCalculatorIcon = styled(Calculate)`
  width: 24px;
  margin-left: 8px;
  position: relative;
  bottom: 1px;
`;

const DensityCalcButton = () => {
  const setCalcVisible = useSetRecoilState(showDensityCalculatorAtom);
  const openCalc = (e: any) => {
    setCalcVisible(true);
  };

  return (
    <DensityCalcLinkButton type="ghost" onClick={openCalc}>
      Density
      <Calculate style={{ width: 20, marginLeft: 8, marginBottom: 1 }} />
    </DensityCalcLinkButton>
  );
};

export default DensityCalcButton;
