import React, { useContext } from 'react';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  dataLoadingAtom,
} from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  adminUserEmailsAtom,
  superAdminUserEmailAtom,
} from 'shared/state/routingState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { PricingCol, PageHeaderWrapper } from '../styledComponents';
import Loader from '../../shared/components/Utility/Loader';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';
import ActiveSelector from '../Customer/Components/PriceListInputs/ActiveSelector';
import VendorHeader from './Components/VendorHeader';
import VendorFilter from './Components/VendorFilter';
import VendorList from './Components/VendorList';
import { activeVendorDisplayAtom } from '../../shared/state/vendorState';
import { AdminToolsFilterWrapper, PageAdminToolsWrapper } from '../../shared/styledComponents/containers';
import { PageTitle } from '../../shared/styledComponents/typographicElements';

const VendorAdminWrapper = styled(FlexColumn)`
  height: auto;
  width: 100%;
  align-items: flex-start;
  background-color: ${(props: any) => (props.testMode ? '#FCEBEC' : theme.palette.neutral.white)};
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  position: sticky;
  top: 0px;
  z-index: 1000;
  padding: 32px 0 4px 0;
  gap: 32px;
  @media ${theme.device.laptopL} {
    align-items: center;
  }
`;

const VendorSiteHeader = styled(VendorAdminWrapper)`
  width: 100%;
  gap: 0;
`;

const FilterWrapper = styled(FlexRow)`
  width: 100%;
  gap: 16px;
`;

const ToolsWrapper = styled(AdminToolsFilterWrapper)`
  justify-content: flex-start;
  width: calc(100% - 72px);
  margin: 2px 0 0 72px;
  gap: 12px;
    @media ${theme.device.laptopL} {
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-left: unset;
    }    
`;
/**
 *   Display component that lists and filters all vendor records,
 *   e.g., not finished goods
 */
export default () => {
  // @ts-ignore
  const [loading, setLoading] = useRecoilState(dataLoadingAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);

  return (
    <PageHeaderWrapper>
      <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
        <PageAdminToolsWrapper id="vendor-admin-inputs">
          <ToolsWrapper>
            <PageTitle>Vendors</PageTitle>
            <AdminToolsFilterWrapper>
              <VendorFilter />
              <ActiveSelector activeDisplayAtom={activeVendorDisplayAtom} type="Vendor" />
            </AdminToolsFilterWrapper>
          </ToolsWrapper>
          <VendorHeader />
        </PageAdminToolsWrapper>
      </ScopedComponent>
      <PricingCol>
        {loading
          ? (
            <>
              <FlexColumn style={{ width: '100%', height: '50vw' }}>
                <Loader />
              </FlexColumn>
            </>
          ) : (
            <>
              <VendorList />
            </>
          )}
      </PricingCol>
    </PageHeaderWrapper>
  );
};
