import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { every, sortBy } from 'lodash';
import { Table } from 'antd';
import { EmptyPriceItem } from 'pages/Customer/Components/styledComponents';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { activePartDisplayAtom } from 'shared/state/partViewState';
import { scrollToHash, scrollOptions } from 'shared/scroll';
import {
  activeCustomerDisplayAtom,
  customerEmailButtonsAtom,
  customersAtom,
  customerSearchQueryAtom,
  showCustomerEmailsAtom,
} from 'shared/state/customerState';
import { Body } from 'shared/typography';
import { ICustomerRecord, IInventoryPart } from 'shared/types/dbRecords';
import SimpleModal from 'shared/components/Utility/SimpleModal';
import { stageRedirect } from 'shared/util';
import { rgba as hexRgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import {
  ID_COLUMN, NAME_COLUMN, CONTACT_COLUMN, PHONE_COLUMN, ACTION_COLUMN,
} from './CustomerListColumns';

const RecordWrapper = styled(FlexColumn)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ItemTable = styled((props) => <Table {...props} />)`
    width: 100%;
    && tbody > tr:hover > td {
        background: ${hexRgba(theme.palette.primary.hue, 0.2)};
    }
`;

interface IComponent {
  renderItems: IInventoryPart[];
  currentPage: number;
  onPageChange: (newPage: number) => void;
}
const CustomerList = ({ renderItems, currentPage, onPageChange }: IComponent) => {
  const currentPageString = sessionStorage.getItem('currentCustomerPage');
  const customers = useRecoilValue(customersAtom);
  const customerSearchQuery = useRecoilValue(customerSearchQueryAtom);
  const activeDisplayType = useRecoilValue(activeCustomerDisplayAtom);
  const [showCustomerEmails, setShowCustomerEmails] = useRecoilState(showCustomerEmailsAtom);
  const customerEmailButtons = useRecoilValue(customerEmailButtonsAtom);
  const [_currentPage, setCurrentPage] = useState(currentPage);
  const [_renderItems, setRenderItems] = useState(renderItems);
  const tableColumns = [ID_COLUMN, NAME_COLUMN, CONTACT_COLUMN, PHONE_COLUMN, ACTION_COLUMN];

  const closeEmailModal = (e: any) => {
    setShowCustomerEmails(false);
  };

  const onRowClick = (record: ICustomerRecord) => (e: any) => {
    stageRedirect(`/customers#${record.DisplayName}`);
    window.location.href = `/customers/detail?customer=${record.DisplayName}&qboId=${record.Id}`;
  };

  useEffect(() => {
    setRenderItems(renderItems);
    setTimeout(() => {
      const el = document.getElementById(`${window.location.hash.replace('#', '')}-record-item`);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });
  }, [renderItems]);

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage]);

  return (
    <>
      <SimpleModal modalOpen={showCustomerEmails} buttons={customerEmailButtons} onCancel={closeEmailModal} title="Email customer" />
      {(_renderItems.length === 0)
        ? (
          <EmptyPriceItem>
            <Body>No matches available.</Body>
          </EmptyPriceItem>
        ) : (
          <RecordWrapper>
            <ItemTable
              dataSource={_renderItems.filter((r: ICustomerRecord) => !!r.DisplayName)}
              rowKey="Id"
              size="large"
              rowClassName="sales-order-row clickable-row"
              columns={tableColumns}
              pagination={{ pageSize: 100, current: _currentPage, onPageChange }}
              scroll={{ y: window.innerHeight * 0.8 }}
              onRow={(record: ICustomerRecord) => ({
                onClick: onRowClick(record),
              })}
            />
          </RecordWrapper>
        )}
    </>
  );
};

export default CustomerList;
