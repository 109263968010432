import React from 'react';
import styled from 'styled-components';
import { IOrderItem } from 'shared/types/dbRecords';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { formatPrice } from 'shared/data';
import theme from '../../../../shared/theme';

interface IComponent {
  item: IOrderItem;
  rowNumber: number;
}

const ComponentWrapper = styled(FlexRow)`
  width: 90%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  padding-bottom: 4px;
  background-color: ${theme.palette.neutral.white};
  border-bottom: 1px solid ${theme.palette.neutral[500]};
  gap: 16px;

  & p {
    text-wrap: wrap;
  }

  @media ${theme.device.laptopL} {
    width: 90%;
  }
`;
const ShippedStatus = styled.div`
    width: 100%;
    border-radius: 8px;
    margin-bottom: 2px;
    padding: 0px 6px;
    background-color: ${theme.palette.success[200]};
    color: ${theme.palette.neutral.black};
    font-size: 12px;
    text-align: center;
`;

const PartDescriptionWrapper = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
`;

const PartDetailsWrapper = styled(FlexColumn)`
   width: 150px;
   justify-content: flex-start;
   align-items: flex-start;
   margin-bottom: 8px;
 `;
const PartDescriptionRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 12px;
`;

const PartNumber = styled.p`
    font-weight: bolder;
    margin-bottom: 4px;
`;

const PartDescription = styled.p`
  margin: 0;
`;

const PartNotesWrapper = styled(PartDescriptionWrapper)`
  gap: 0;
`;

const PartJobNotes = styled.p`
  font-weight: 200;
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 0;
`;

const QuantityPriceWrapper = styled(FlexColumn)`
  width: 20%;
  align-items: flex-end;
  justify-content: center;
  margin: 0 0 0 auto;
`;
const PartTotalValue = styled.p`
    font-weight: bolder;
    margin: 0;
`;

const OrderShipmentItem = ({ item }: IComponent) => (
  <ComponentWrapper>
    <PartDetailsWrapper>
      <PartDescriptionRow>
        <PartNumber>{item.Sku}</PartNumber>
      </PartDescriptionRow>
      <ShippedStatus>{`${item.quantityShipped} SHIPPED`}</ShippedStatus>
    </PartDetailsWrapper>
    <PartDescriptionWrapper key={`${item.id}-shipment-item-description-wrapper`}>
      <PartDescription key={`${item.id}-shipment-item-description`}>{item.Description}</PartDescription>
      {!!item.notes && (
      <PartNotesWrapper key={`${item.id}-shipment-item-notes`}>
        { item.notes.split('\n').map((note: string) => <PartJobNotes>{note}</PartJobNotes>)}
      </PartNotesWrapper>
      )}
    </PartDescriptionWrapper>
    <QuantityPriceWrapper>
      <PartTotalValue>{formatPrice(item.unitPrice * item.quantityShipped)}</PartTotalValue>
    </QuantityPriceWrapper>
  </ComponentWrapper>
);

export default OrderShipmentItem;
