import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'antd';
import styled from 'styled-components';
import { IOrderItem, IShipment } from 'shared/types/dbRecords';
import { rgba as hexRgba } from 'shared/palette/utils';
import { stageRedirect } from 'shared/util';
import theme from 'shared/theme';
import { IShopOrder } from 'pages/Orders/types';
import { ORDER_VALUE_COLUMN, SALES_ORDER_COLUMN, DESCRIPTION_COLUMN } from 'pages/ProductionSchedule/Components/DataColumns';
import {
  CUSTOMER_COLUMN,
  JOB_TYPE_COLUMN,
  ORDER_DATE_COLUMN, QTY_ON_ORDER,
  SHIP_DATE_COLUMN,
} from 'pages/Orders/Components/OrderRecords/OrderRecordsColumns';

interface IQuickViewOrder extends IShopOrder {
  orderItems: IOrderItem[];
}
interface IComponent {
  partId: string;
  closeCallback: () => void;
  orders: IQuickViewOrder[];
  open: boolean;
}

const OrdersModal = styled(Modal)`
    width: 80vw !important;
`;
const OrdersTable = styled((props) => <Table {...props} />)`
    width: 100%;
    && tbody > tr:hover > td {
        background: ${hexRgba(theme.palette.primary.hue, 0.2)};
    }
`;
const QuickViewOrdersModal = ({
  partId, closeCallback, orders = [], open = false,
}: IComponent) => {
  const [_open, setOpen] = useState<boolean>(open);
  const [_orders, setOrders] = useState<IQuickViewOrder[]>(orders);
  const [currentPage, setCurrentPage] = useState(1);
  const tableColumns = [
    SALES_ORDER_COLUMN, // 100
    ORDER_VALUE_COLUMN,
    ORDER_DATE_COLUMN,
    SHIP_DATE_COLUMN,
    DESCRIPTION_COLUMN, // 600
    QTY_ON_ORDER,
  ];
  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const onRowClick = (record: IQuickViewOrder) => (e: any) => {
    e.preventDefault();
    stageRedirect();
    window.location.href = `/orders/create?edit=true&orderId=${record.id}`;
  };

  const onClose = (e: any) => {
    closeCallback();
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    setOrders(orders);
  }, [orders]);

  return (
    <OrdersModal
      open={_open}
      onCancel={onClose}
      onOk={onClose}
      closable
      title={`${partId}: Open Orders`}
    >
      <OrdersTable
        dataSource={_orders.filter((r: IQuickViewOrder) => !!r.salesOrder)}
        rowKey="Id"
        size="large"
        rowClassName="sales-order-row clickable-row"
        columns={tableColumns}
        pagination={{ pageSize: 10, current: currentPage, onChange: onPageChange }}
        scroll={{ y: window.innerHeight * 0.8 }}
        onRow={(record: IQuickViewOrder) => ({
          onClick: onRowClick(record),
        })}
      />
    </OrdersModal>
  );
};

export default QuickViewOrdersModal;
