import styled from 'styled-components';
import {
  Collapse, Input, Tabs,
} from 'antd';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { Body, H3 } from 'shared/typography';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import { rgba } from 'shared/palette/utils';

export const PricingColumn = styled(ConfigurationCol)`
    width: 100%;
    margin: 0;
    padding-bottom: ${theme.spacing(2)};
`;

export const PriceItemDetailsWrapper = styled(FlexRow)`
    width: 100%;
    height: auto;
    
    justify-content: flex-start;
    align-items: center;
    padding: ${theme.spacing(1.5)};
    gap: ${theme.spacing(1)};
    
    border-bottom: 1px solid ${theme.palette.neutral[100]};
    
    &:nth-child(2n+1) {
        background-color: ${rgba(theme.palette.primary.hue, 0.05)};
    }
`;

export const PriceItemWrapper = styled(FlexColumn)`
    width: 65%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    
    & h4 {
        line-height: 1;
        margin: 0;
    }
    
`;

export const PriceFieldWrapper = styled(FlexRow)`
    width: 35%;
    justify-content: flex-end;
    align-items: center;
    gap: ${theme.spacing(1)};
    
`;

export const PartCategoryLabel = styled(Body)`
  margin: 0;
`;

export const PriceFieldLabel = styled(Body)`
  margin: 0;
  margin-right: ${theme.spacing(0.5)};
`;

export const ItemNameInput = styled(Input)`
    font-size: 18px;
`;

export const ItemCollapse = styled(Collapse)`
    width: 100%;
    background-color: ${rgba(theme.palette.primary[100], 0.5)};
    position: relative;
    @media ${theme.device.laptopL} {
      width: 75%;
    }
`;

export const PricingHeader = styled(H3)`
    margin-bottom: ${theme.spacing(0.5)};
`;

export const PricingPageDescription = styled(Body)`
  width: calc(100% - ${theme.spacing(2)});
    margin-bottom: ${theme.spacing(4)};
    @media ${theme.device.laptop} {
      width: 45%;
    }
`;

export const ItemTypeRow = styled(FlexRow)`
  justify-content: flex-start;
  align-items: center;
  & p {
    margin: 0
  }
`;

export const PricingTabs = styled(Tabs)`
  margin-left: ${theme.spacing(3)};
    & .ant-tabs-nav-wrap {
        width: calc(100% - 100px);
        margin-left: 100px;
    }
`;

export const GlobalPricingRow = styled(FlexRow)`
  width: 50%;
  justify-content: space-between;
`;

export const PriceUpdateControls = styled(FlexRow)`
  gap: ${theme.spacing(1)};
`;
