import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  searchModalAtom,
  scheduleViewStartDateAtom,
  scheduleViewEndDateAtom,
  orderWeeksAtom,
  showBodiesAtom,
  showNecksAtom, showLossesAtom, showNCAtom,
} from 'shared/state/routingState';
import { AddCircle } from '@styled-icons/material';
import { useTestDataAtom } from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import moment from 'moment';
import {
  getOrderWeeks, NC_RECORD_END_KEY, NC_RECORD_START_KEY,
} from 'shared/data/calendar';
import { ncRecordsAtom } from 'shared/state/utilState';
import styled from 'styled-components';
import { Button } from 'antd';
import theme from 'shared/theme';
import Loader from 'shared/components/Utility/Loader';
import { INCRecord } from 'shared/types/dbRecords';
import {
  DatePickerNextIcon,
  DatePickerPrevIcon,
  ViewTypeRadioButton,
  ViewTypeRadioGroup,
  ViewTypeSelect, ViewTypeSelectLabel,
  ViewTypeSelectOption,
} from 'shared/styledComponents/inputs';
import { PageTitle } from 'shared/styledComponents/typographicElements';
import { ButtonIsland } from 'shared/styledComponents/containers';
import {
  RunnerActionRow,
  RunnerColumn,
  OrderRangePicker,
  RunnerHeaderRow,
} from 'pages/ProductionSchedule/styledComponents';
import NonComplianceReportByWeek from './NonComplianceReportByWeek';
import NonComplianceReportByDepartment from './NonComplianceReportByDepartment';

const _ = require('lodash');
const shortid = require('shortid');

const HeaderWrapper = styled(RunnerHeaderRow)`
    width: 100%;
    @media ${theme.device.laptopL} {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
        padding: 16px 16px 12px 16px;
    }
`;
const ButtonWrapper = styled(FlexRow)`
    align-items: center;
    margin: 2px 0 8px 72px;
    gap: 12px;
    
    @media ${theme.device.laptopL} {
      width: 100%;
      justify-content: flex-start;
      margin-left: 0;
      margin-bottom: 12px;
    }
`;

const ViewSelectWrapper = styled(FlexColumn)`
    align-items: center;
    justify-content: flex-start;
    @media ${theme.device.laptopL} {
      display: none;
    }
`;
const NewIcon = styled(AddCircle)`
    width: 20px;
    position: relative;
    left: -4px;
    margin: 0 4px 3px 0px;
`;

const FilterWrapper = styled(RunnerActionRow)`
    margin-left: 72px;
    gap: 12px;
    
    @media ${theme.device.laptopL} {
        justify-content: flex-start;
        margin-left: 0;
    }
`;
export const NewScanButton = styled(Button)`
    border-radius: 8px;
`;

export default () => {
  // @ts-ignore
  const setShowSearchModal = useSetRecoilState(searchModalAtom);
  const setOrderWeeks = useSetRecoilState(orderWeeksAtom);
  const setShowBodies = useSetRecoilState(showBodiesAtom);
  const setShowNecks = useSetRecoilState(showNecksAtom);

  const [showLosses, setShowLosses] = useRecoilState(showLossesAtom);
  const [showNcs, setShowNcs] = useRecoilState(showNCAtom);
  const [ncRecords, setNcRecords] = useRecoilState(ncRecordsAtom);
  const useTestData = useRecoilValue(useTestDataAtom);

  const [viewType, setViewType] = useState(localStorage.getItem('ncViewType') || 'Week');
  const [, setHighlightRefreshDates] = useState(false);
  const [partViewType, setPartViewType] = useState(localStorage.getItem('partViewType') || 'All');
  const [recordViewType, setRecordViewType] = useState(localStorage.getItem('recordViewType') || 'All');
  const [noNcRecords, setNoNcRecords] = useState(false);
  const [_records, _setRecords] = useState<INCRecord[]>([]);

  const [startDate, setStartDate] = useRecoilState(scheduleViewStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(scheduleViewEndDateAtom);

  const onChangeView = (e: any) => {
    const typeValue = e.target?.value || e;
    localStorage.setItem('ncViewType', typeValue);
    if (!window.location.hash.replace('#', '').match(/[0-9]+-[A-Za-z0-9]+/)) window.location.hash = '';
    setViewType(typeValue);
  };

  const onChangeParts = (e: any) => {
    const typeValue = e.target?.value || e;
    const bodies = _.includes(['all', 'body'], typeValue);
    const necks = _.includes(['all', 'neck'], typeValue);

    localStorage.setItem('partViewType', typeValue);
    setPartViewType(typeValue);
    setShowBodies(bodies);
    setShowNecks(necks);
  };

  const onChangeType = (e: any) => {
    const typeValue = e.target?.value || e;
    const losses = _.includes(['All', 'Loss'], typeValue);
    const nonConformances = _.includes(['All', 'NC'], typeValue);

    setShowLosses(losses);
    localStorage.setItem('ncShowLosses', losses);
    setShowNcs(nonConformances);
    localStorage.setItem('ncShowNC', nonConformances);

    setRecordViewType(typeValue);
    localStorage.setItem('recordViewType', typeValue);
  };

  const onChangeDates = (date: any) => {
    const [sDate, eDate] = date;
    setHighlightRefreshDates(true);
    const newStartDate = new Date(sDate.year(), sDate.month(), sDate.date());
    setStartDate(newStartDate);
    localStorage.setItem(NC_RECORD_START_KEY, newStartDate.getTime().toString());

    const newEndDate = new Date(eDate.year(), eDate.month(), eDate.date());
    setEndDate(newEndDate);
    localStorage.setItem(NC_RECORD_END_KEY, newEndDate.getTime().toString());
  };

  const searchCB = (e: any) => {
    if (_.includes([191], e.keyCode)) setShowSearchModal(true);
  };
  useEffect(() => {
    const sDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    // // set date to "1" to get stats for entire month, if month is passed but still shown in view
    // const sDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    sDate.setDate(sDate.getDate() - 1);
    const eDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    eDate.setDate(eDate.getDate() + 1);

    setOrderWeeks(getOrderWeeks(startDate, endDate));

    const recordTypeFilterRegex = (showLosses && !showNcs) ? new RegExp('(lost|loss|scrap)', 'i')
      : (showNcs && !showLosses) ? new RegExp('^((?!(lost|loss|scrap)).)*$', 'i')
        : new RegExp('.*');

    window.ncRecords = ncRecords;
    const filteredRecords = [...ncRecords]
      .filter((r: INCRecord) => r.correctiveAction.match(recordTypeFilterRegex))
      .filter((r: INCRecord) => r.dateRecorded.toDate() > sDate && r.dateRecorded.toDate() < eDate);
    _setRecords(filteredRecords);
    if (filteredRecords.length === 0) setNoNcRecords(true);

    onChangeParts({
      target: {
        value: partViewType,
      },
    });
  // }, []);
  }, [startDate, endDate, useTestData, showLosses, showNcs]);

  const onNewScan = (e: any) => {
    window.location.href = '/nc/scanner';
  };

  // @ts-ignore
  return (
    <>
      {/* <SalesSearchModal /> */}
      <RunnerColumn key={shortid.generate()}>
        <HeaderWrapper key={shortid.generate()}>
          <ButtonWrapper align="flex-start" justify="flex-start">
            <PageTitle key={shortid.generate()}>Non-conformance Report</PageTitle>
            <ButtonIsland noMinWidth expand={false} padding="8px 16px" gap="16px">
              <NewScanButton
                key={shortid.generate()}
                type="primary"
                onClick={onNewScan}
                icon={<NewIcon />}
              >
                New
              </NewScanButton>
              <OrderRangePicker
                onChange={onChangeDates}
                value={[moment(startDate), moment(endDate)]}
                nextIcon={<DatePickerNextIcon />}
                prevIcon={<DatePickerPrevIcon />}
              />
            </ButtonIsland>
          </ButtonWrapper>
          <RunnerActionRow key={shortid.generate()}>
            <FilterWrapper>
              <ViewSelectWrapper>
                <ViewTypeSelectLabel>View Type</ViewTypeSelectLabel>
                <ViewTypeSelect
                  label="View by"
                  size="default"
                  value={viewType}
                  onChange={onChangeView}
                  marginLeft="0px"
                >
                  <ViewTypeSelectOption value="Week">Week</ViewTypeSelectOption>
                  <ViewTypeSelectOption value="Department">Department</ViewTypeSelectOption>
                </ViewTypeSelect>
              </ViewSelectWrapper>
              <ViewTypeRadioGroup value={viewType} buttonStyle="solid" onChange={onChangeView} marginLeft="0px">
                <ViewTypeRadioButton
                  value="Week"
                >
                  Week
                </ViewTypeRadioButton>
                <ViewTypeRadioButton
                  value="Department"
                >
                  Department
                </ViewTypeRadioButton>
              </ViewTypeRadioGroup>
              <ViewSelectWrapper>
                <ViewTypeSelectLabel>Part type</ViewTypeSelectLabel>
                <ViewTypeSelect size="default" value={partViewType} onChange={onChangeParts} marginLeft="0px">
                  <ViewTypeSelectOption value="all">All</ViewTypeSelectOption>
                  <ViewTypeSelectOption value="body">Bodies</ViewTypeSelectOption>
                  <ViewTypeSelectOption value="neck">Necks</ViewTypeSelectOption>
                </ViewTypeSelect>
              </ViewSelectWrapper>
              <ViewTypeRadioGroup value={partViewType} buttonStyle="solid" onChange={onChangeParts} marginLeft="0px">
                <ViewTypeRadioButton
                  value="all"
                >
                  All
                </ViewTypeRadioButton>
                <ViewTypeRadioButton
                  value="body"
                >
                  Bodies
                </ViewTypeRadioButton>
                <ViewTypeRadioButton
                  value="neck"
                >
                  Necks
                </ViewTypeRadioButton>
              </ViewTypeRadioGroup>
              <ViewSelectWrapper>
                <ViewTypeSelectLabel>Record type</ViewTypeSelectLabel>
                <ViewTypeSelect size="default" value={recordViewType} onChange={onChangeType} marginLeft="0px">
                  <ViewTypeSelectOption value="All">All</ViewTypeSelectOption>
                  <ViewTypeSelectOption value="NC">Non-conformances</ViewTypeSelectOption>
                  <ViewTypeSelectOption value="Loss">Losses only</ViewTypeSelectOption>
                </ViewTypeSelect>
              </ViewSelectWrapper>
              <ViewTypeRadioGroup value={recordViewType} buttonStyle="solid" onChange={onChangeType} marginLeft="0px">
                <ViewTypeRadioButton
                  value="All"
                >
                  All
                </ViewTypeRadioButton>
                <ViewTypeRadioButton
                  value="NC"
                >
                  Non-conformances
                </ViewTypeRadioButton>
                <ViewTypeRadioButton
                  value="Loss"
                >
                  Losses only
                </ViewTypeRadioButton>
              </ViewTypeRadioGroup>
            </FilterWrapper>
            <FlexRow justify="flex-end" />
          </RunnerActionRow>
        </HeaderWrapper>
        {(_records.length === 0 && !noNcRecords) ? (
          <FlexColumn style={{ width: '100%', height: '50vw' }}>
            <Loader />
          </FlexColumn>
        ) : (
          <div style={{ width: '100%' }}>
            {viewType.match(/week/i)
             && <NonComplianceReportByWeek records={_records} key="nc-report-by-week" />}
            {viewType.match(/department/i)
            && <NonComplianceReportByDepartment records={_records} key="nc-report-by-department" />}
          </div>
        )}
      </RunnerColumn>
    </>
  );
};
