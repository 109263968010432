import { Divider, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Body } from 'shared/typography';
import { formatPrice } from 'shared/data';
import { ConfigurationCol, ItemPrice } from 'shared/pageElements/styledComponents';
import {
  customerPartAtom,
  partEditModeAtom, useTestDataAtom,
} from 'shared/state/pricingState';
import { PriceFieldWrapper, RateOptionNameWrapper, RateOptionWrapper } from '../styledComponents';

const shortid = require('shortid');

export default () => {
  // @ts-ignore
  const [itemState, setItemState] = useRecoilState(customerPartAtom);
  const [itemPriceAdjustment, setItemPriceAdjustment] = useState(itemState.priceAdjustment || 0);
  const editMode = useRecoilValue(partEditModeAtom);
  const useTestData = useRecoilValue(useTestDataAtom);

  const onChangePriceAdjustment = (e: any) => {
  // const onChangePriceAdjustment = (value: number) => {
    setItemPriceAdjustment(parseFloat(e.target.value));
    const newRecord = {
      ...itemState,
      // @ts-ignore
      // newRecord[partType] = {
      //   @ts-ignore
      // ...newRecord[partType],
      priceAdjustment: parseFloat(e.target.value),
    };
    setItemState(newRecord);
  };

  useEffect(() => {
    setItemPriceAdjustment(itemState.priceAdjustment || 0);
  }, [itemState]);

  return (
    <>
      { (editMode || itemState.priceAdjustment !== 0)
          && (
          <>
            <Divider orientation="left">Service Options</Divider>
            <ConfigurationCol>
              {(editMode || itemState.priceAdjustment !== 0)
                && (
                <RateOptionWrapper key={shortid.generate()}>
                  <RateOptionNameWrapper>
                    <Body>Flat price adjustment (USD)</Body>
                  </RateOptionNameWrapper>
                  <PriceFieldWrapper>
                    {(editMode)
                    && (
                    <InputNumber
                      placeholder="0.00"
                      step="1.00"
                      value={itemPriceAdjustment || 0}
                        // @ts-ignore
                      onBlur={onChangePriceAdjustment}
                    />
                    )}
                    <ItemPrice testMode={useTestData} selected={itemState.priceAdjustment !== 0}>
                      {formatPrice(itemPriceAdjustment)}
                    </ItemPrice>
                  </PriceFieldWrapper>
                </RateOptionWrapper>
                )}
            </ConfigurationCol>
          </>
          )}
    </>
  );
};
