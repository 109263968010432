import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { find } from 'lodash';
import {
  contactEditModeAtom,
  currentCustomerShippingAddressesAtom,
  selectedCustomerShippingAddressAtom,
} from 'shared/state/customerState';

const DeleteButton = styled(Button)`
  margin: 0 0 6px 0;
  padding: 2px 8px;
`;

const DiscardShippingAddressButton = () => {
  const currentCustomerShippingAddresses = useRecoilValue(currentCustomerShippingAddressesAtom);
  const setSelectedAddress = useSetRecoilState(selectedCustomerShippingAddressAtom);
  const setEditMode = useSetRecoilState(contactEditModeAtom);

  const onClick = (e: any) => {
    e.preventDefault();
    setEditMode(false);
    setSelectedAddress(find(currentCustomerShippingAddresses, (c) => c.default) ?? currentCustomerShippingAddresses[0]);
  };

  return (
    <DeleteButton type="default" onClick={onClick}>Discard</DeleteButton>
  );
};

export default DiscardShippingAddressButton;
