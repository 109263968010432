import styled from 'styled-components';
import theme from 'shared/theme';
import { Button, Form, Input } from 'antd';
import { Caption } from 'shared/typography';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';

export const HeadPadding = styled.div`
  margin-top: 40px;
  @media ${theme.device.laptop} {
    margin-top: ${theme.spacing(10)};
  }
`;

export const OuterWrapper = styled(FlexColumn)`
    gap: 20px;
`;

export const HelperWrapper = styled(FlexColumn)`
    gap: 8px;
`;

export const PageContentWrapper = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 90vh;
  margin: 0 auto;
  @media  screen and (max-height: 800px) and (min-width: 1024px) {
      flex-direction: row;
      gap: 40px;
      height: 80vh;
  }
  @media ${theme.device.laptopL} {
      flex-direction: column;
  }
`;

export const LogoWrapper = styled.div`
  width: 90%;
  min-height: 180px;
  background-image: ${(props: { siteImage: string }) => `url(${props.siteImage})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 40px auto 0 auto;
  
  @media ${theme.device.laptop} {
    width: 384px;
    height: 232px;
    margin: unset;
  }
 
`;

export const DescriptionWrapper = styled(FlexColumn)`
  align-items: flex-start;
  width: 90%;
  margin: 44px auto;
  margin-bottom: 40px;
  
  & p {
      color: ${theme.palette.neutral.black};
  }
  & h4 {
    margin-bottom: 8px;
  }
  
  @media ${theme.device.laptop} {
    margin: 24px 0;
      margin-bottom: ${theme.spacing(8)};
      width: 600px;
  }
`;

export const CopyrightWrapper = styled(FlexColumn)`
    //padding: 80px;
    & p {
      font-size: 12px;
    }
`;

export const LoginLink = styled.a`
    padding: 12px 0;
    margin: 0 auto;
`;

export const InputCaption = styled.p`
`;
export const AuthFormInput = styled(Input)`
    & * {
        border-radius: 6px !important;
    }
    & input {
        width: 100% !important;
        height: 48px !important;
        font-size: 16px !important;
        @media ${theme.device.laptopM} {
            width: calc(100% - 40px) !important;
        }
    }
`;

export const FormComponent = styled(Form)`
  width: 90%;
  margin-left: 4%;
  margin-top: 48px;
  
  & p {
    font-size: 22px;
    font-weight: lighter;
    margin: 0;
    color: ${theme.palette.primary.hue};
    //letter-spacing: -0.5px;
  }
    & .ant-col-16 {
      max-width: 100% !important;
    }
  & input {
      border-radius: 6px !important;
      border: none;
      background-color: ${theme.palette.primary[100]};
      width: 95% !important;
      height: 48px !important;
      font-size: 22px !important;
      font-weight: lighter;;
      // @media ${theme.device.laptopM} {
      //     width: calc(100% - 40px) !important;
      // }
  }
    & .ant-input-affix-wrapper {
        padding: 4px 0px !important;
        border: none;
        & input {
            padding: 4px 12px;
        }
    }
  
  @media ${theme.device.mobileL} {
    width: 320px;
  }
  @media ${theme.device.laptop} {
    margin-left: unset;
      width: 400px;
    // width: ${theme.spacing(72)};
    
    & p {
      letter-spacing: 0.5px;
    }
    & a {
      margin: 0 auto;
    }
  }
`;

export const LoginLinkWrapper = styled(FlexRow)`
    width: 95%;
`;
export const FormButtonWrapper = styled(Form.Item)`
    align-items: flex-start;
    @media ${theme.device.laptop} {
        margin-left: unset;
        width: 400px;
            // width: ${theme.spacing(72)};

        & button {
          font-family: 'Red Hat Display';
          width: 95%;
          height: 44px;
          border-radius: 6px;
          margin-top: 8px;
          font-size: 22px;
          font-weight: 300;
          line-height: 22px;
          padding: 8px 0;
        }
    }
`;

export const SubmitButton = styled(Button)`
    margin-left: ${theme.spacing(10)};
`;

export const HomePageLink = styled(Caption)`
    margin-top: ${theme.spacing(2)};
    color: ${theme.palette.neutral.black};
`;
