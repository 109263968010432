import React from 'react';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Upload as UploadIcon } from '@styled-icons/fa-solid';
import bodyParser from 'shared/partParser/bodyParser';
import bodyMaterial from 'shared/partParser/bodyMaterial';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { jobScheduleDataAtom, openOrdersDataAtom } from '../../../shared/state/orderState';

const UploadButton = styled(Button)`
  min-width: 240px;
  border-radius: 8px;
`;

interface InProcessRecord {
  order: string;
  inProcess: boolean;
}

export default () => {
  const setJobScheduleData = useSetRecoilState(jobScheduleDataAtom);
  const orders = useRecoilValue(openOrdersDataAtom);

  const onUpload = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result
        .split('\r')
        .slice(1)
        .map((d: string) => d.trim().split(','))
        .filter((part: string[]) => part.length > 1)
        .map((part: string[]) => {
          const partNumber = part[4].match(/^[A-Z]{5}_[0-9]{5}/);
          if (!partNumber) return;

          const jobNumber = part[0];
          const description = part[4].replace(new RegExp(`${partNumber}\\s?`), '');
          const quantity = part[6];
          const dateNeeded = part[7];

          return {
            partNumber: partNumber[0],
            description,
            unit: null,
            quantity,
            price: null,
            dateNeeded,
            jobNumber,
          };
        })
        .filter((part: string[]) => part);

      const inProcessOrders = orders.filter((o) => o.inProcess).map((o) => {
        const orderNumber = parseInt(o.order, 10);
        if (orderNumber < 10000) return (orderNumber + 10000).toString();
        return o.order;
      });

      const rawBodyData = data.filter((d: any) => d.description.match(/[G|B]B_/));
      const parsedBodyData = bodyParser(rawBodyData.map((d: any) => Object.values(d)));

      const bodyScheduleData = parsedBodyData.map((d: any) => {
        const rawDatum = _.find(rawBodyData, (r: any) => r.partNumber === d.partNumber);
        if (!rawDatum) return d;

        const bodyType = d.description.match(/^GB_/) ? 'GB' : 'BB';
        const partType = bodyMaterial(d.core, d.coreWeight || 'Standard', !!d.description.match(/1pc|1-piece/i), bodyType);
        const order = rawDatum.jobNumber.split('-')[0];

        if (!partType.part || _.includes(inProcessOrders, order)) return;

        return {
          order,
          customer: d.customer,
          jobNumber: rawDatum.jobNumber,
          partNumber: d.partNumber,
          bodyDescription: d.description,
          description: partType?.description,
          core: d.core,
          coreWeight: d.coreWeight || '',
          top: d.top || '',
          part: partType?.part,
          quantity: partType?.baseUnit * parseInt(rawDatum.quantity, 10),
          dateNeeded: rawDatum.dateNeeded,
        };
      }).filter((i) => i);

      const csvData = [
        'JobNumber,OrderNumber,OrderDescription,StepNo,QtyNeeded,Unit,PurchQty,PurchUnit,UnitCost,LineTotal,OutsideService,OrderBy,Order,Customer,BodyDescription',
        ...bodyScheduleData.map((l: any[]) => `${l.jobNumber},${l.part},${l.description},0,${l.quantity},EA,1,EA,,,,${l.dateNeeded},${l.order},${l.customer},${l.bodyDescription}`),
      ];

      setJobScheduleData(csvData.join('\r'));
    };

    reader.readAsText(file);

    return false;
  };

  return (
    <Upload
      accept=".txt, .csv"
      showUploadList={false}
      beforeUpload={onUpload}
    >
      <UploadButton type="default" icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}>Parse Job Schedule Data</UploadButton>
    </Upload>
  );
};
