import shortid from 'shortid';
import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { formatPrice } from 'shared/data';
import { IInventoryPart } from 'shared/types/dbRecords';
import { CellCopy, ParentCellCopy } from 'shared/styledComponents/typographicElements';
import { SubdirectoryArrowRight } from '@styled-icons/material';
import ReactHtmlParser from 'react-html-parser';
import theme from 'shared/theme';
import { configToDescription } from 'shared/partParser/util';
import { InactiveIndicator, SkuWrapper } from 'shared/styledComponents/utility';
import { FlexRow } from 'shared/containers/FlexContainer/styledComponents';
import { cleanMeta, uniqueChildPartNameSegments } from 'shared/text';
import ActionButtons from './PricedItem/Buttons/ActionButtons';
import PartSku from './Components/PartSku';

const CellCopyBold = styled(CellCopy)`
    font-weight: bold;
`;

const PriceCopy = styled(CellCopy)`
    font-size: ${theme.typography.body.size.small};
    margin-right: 4px;
    color: ${(props: { isPriced: boolean }) => (props.isPriced ? theme.palette.primary.hue : theme.palette.neutral.black)};
`;

const ChildWrapper = styled(FlexRow)`
    justify-content: flex-start;
`;
const ChildArrow = styled(SubdirectoryArrowRight)`
    width: 24px;
    margin-bottom: 6px;
    color: ${theme.palette.primary.hue};
`;

const PartDescriptionRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 12px;
`;

const RevisionNeededIndicator = styled.p`
  font-weight: bold;
  color: ${theme.palette.neutral.white};
  background-color: red;
  border-radius: 8px;
  padding: 0 8px;
  display: inline-block;
  margin: 0;
`;

const ChildRevisionNeededIndicator = styled(RevisionNeededIndicator)`
  height: 8px;
  width: 12px;
  border-radius: 4px;
`;

const SKU_COLUMN = {
  title: 'Sku',
  dataIndex: 'Sku',
  render: (text: string, record: any) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <PartSku active={record.active} notes={record.notes || null} sku={record.Sku} />,
  }),
  width: '8%',
};
const CHILD_SKU_COLUMN = {
  title: 'Sku',
  dataIndex: 'Sku',
  render: (text: string, record: any) => {
    const parent = record.parent || null;
    return {
      props: {
        style: {
          textAlign: record.Parent ? 'right' : 'center',
        },
      },
      children:
  <>
    {parent ? (
      <>
        <Tooltip title={`inherits from ${record.parent.Sku}  |  ${configToDescription(record.parent.config)}`} key={record.childSku} placement="right">
          <ChildWrapper>
            <ChildArrow />
            <SkuWrapper>
              <PartSku active={record.active} child={record.isChild} notes={record.notes || null} sku={record.Sku} />
            </SkuWrapper>
          </ChildWrapper>
        </Tooltip>
      </>
    ) : (
      <SkuWrapper>
        <PartSku active={record.active} child={record.isChild} notes={record.notes || null} sku={record.Sku} />
      </SkuWrapper>
    )}
  </>,
    };
  },
  width: '8%',
};

const CHILD_DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: 'Description',
  render: (text: string, record: any) => {
    const props = {
      style: {
        textAlign: 'left',
      },
    };
    const desc = record.Description.replace(/\*/g, '');
    const parentDesc = record.parent?.config ? record.parent.Description : null;
    if (!parentDesc) {
      return {
        props,
        children: 
        <PartDescriptionRow>
          <CellCopy key={shortid.generate()}>{desc}</CellCopy>
          {record.revisionNeeded && (
            <RevisionNeededIndicator>
              Rev needed
            </RevisionNeededIndicator>
          )}
        </PartDescriptionRow>,
      };
    }

    const uniqueTerms = uniqueChildPartNameSegments(parentDesc, [desc])[0];
    const formattedDescription = uniqueTerms.difference.map((t) => (t.unique ? `<span class="child-part-unique-term">${t.term.trim()}</span>` : `<span class="child-part-same-term">${t.term.trim()}</span>`)).join('<span class="child-part-same-term">_</span>');
    return {
      props,
      children: 
      <PartDescriptionRow>
        <CellCopy key={shortid.generate()}>{ReactHtmlParser(formattedDescription)}</CellCopy>
        {record.revisionNeeded && (
          <ChildRevisionNeededIndicator />
        )}
      </PartDescriptionRow>,
    };
  },
  width: '65%',
};
const DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: 'Description',
  render: (text: string, record: any) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
    <PartDescriptionRow>
      <CellCopy key={shortid.generate()}>{record.Description}</CellCopy>
      {record.revisionNeeded && (
        <RevisionNeededIndicator>
          Rev needed
        </RevisionNeededIndicator>
      )}
    </PartDescriptionRow>,
  }),
  width: '65%',
};
const SELL_COLUMN = {
  title: 'Price',
  dataIndex: 'UnitPrice',
  render: (text: string, record: any) => ({
    props: {
      style: {
        textAlign: 'right',
      },
    },
    children:
  <Tooltip title={`Original price: ${formatPrice(Math.round(record.price / (1 + (['GB', 'BB'].includes(record.type) ? record.customer.bodyDiscount / 100 : record.customer.neckDiscount / 100))))}`}>
    <PriceCopy
      isPriced={!!record.pricing}
      key={shortid.generate()}
    >
      {formatPrice(Math.round(record.price))}
    </PriceCopy>
  </Tooltip>,
  }),
  width: '8%',
};
const CUSTOMER_SELL_COLUMN = {
  title: 'Price',
  dataIndex: 'UnitPrice',
  render: (text: string, record: any) => ({
    props: {
      style: {
        textAlign: 'right',
      },
    },
    children:
  <Tooltip title={`Original price: ${formatPrice(record.price)}`}>
    <PriceCopy
      isPriced={false}
      key={shortid.generate()}
    >
      {formatPrice(Math.round(record.price))}
    </PriceCopy>
  </Tooltip>,
  }),
  width: '8%',
};

const ACTION_COLUMN = (sortMode: 'like'|'sku'|'description', viewSimilarCallback: (value: 'like'|'sku'|'description') => void) => ({
  title: '',
  dataIndex: 'actionsColumn',
  render: (text: string, record: IInventoryPart) => ({
    children:
  <ActionButtons customerPart={record} sortMode={sortMode} viewSimilarCallback={viewSimilarCallback} />,
  }),
  width: '5%',
});
export {
  SKU_COLUMN,
  CHILD_SKU_COLUMN,
  CHILD_DESCRIPTION_COLUMN,
  DESCRIPTION_COLUMN,
  SELL_COLUMN,
  CUSTOMER_SELL_COLUMN,
  ACTION_COLUMN,
};
