import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _, { flatten, flattenDeep, includes } from 'lodash';
import styled from 'styled-components';
import { Button } from 'antd';
import { CustomerDownloadButton } from 'pages/Customer/Components/styledComponents';
import { IRunner, IShopOrder } from 'pages/Orders/types';
import { openOrdersDataAtom } from 'shared/state/orderState';
import { IBomItem, IOrderItem } from 'shared/types/dbRecords';

const DownloadButton = styled(CustomerDownloadButton)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;
const ComponentButton = styled(Button)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const AllocatedNotConsumedButton = () => {
  const openOrders = useRecoilValue(openOrdersDataAtom);
  const [materialNotConsumed, setMaterialNotConsumed] = useState('');

  const onClick = (e: any) => {
    const productionOrders = openOrders.filter((o: IShopOrder) => o.runners);
    const notConsumed = {};

    const notConsumedBomItems = _.groupBy(flatten(productionOrders.map((order) => {
      const bomItems = flattenDeep(order.runners.filter((r: IRunner) => !r.completed).map((r: IRunner) => r.parts.map((p: IOrderItem) => p.bom.map((b: IBomItem) => ({ ...b, totalQuantity: b.totalQuantity * p.quantityAssigned })))));
      return bomItems.filter((b: IBomItem) => b.quantityConsumed === 0);
    })), (b: IBomItem) => b.partNumber);

    // console.log(_.groupBy(notConsumedBomItems, (b: IBomItem) => b.partNumber));
    // notConsumedBomItems.forEach((b: IBomItem) => {
    //   if (!includes(Object.keys(notConsumed), b.partNumber)) {
    //     @ts-ignore
    // notConsumed[b.partNumber] = { description: b.description, partNumber: b.partNumber, quantityNotConsumed: b.totalQuantity };
    // } else {
    //   @ts-ignore
    // notConsumed[b.partNumber] = { ...notConsumed[b.partNumber], quantityNotConsumed: notConsumed[b.partNumber].quantityNotConsumed + b.totalQuantity };
    // }
    // });

    Object.entries(notConsumedBomItems).forEach((entry: [string, IBomItem[]]) => {
      const [partNumber, entries] = entry;
      console.log(partNumber, entries);
      const toConsume = entries.map((b: IBomItem) => b.totalQuantity).reduce((a, b) => a + b, 0);
      notConsumed[partNumber] = { description: entries[0].description, partNumber, quantityNotConsumed: toConsume };
    });

    console.log(notConsumed);

    const data = [
      'PartNumber,Description,Qty',
      ...Object.values(notConsumed).map((item: any) => `${item.partNumber},${item.description},${item.quantityNotConsumed}`),
    ];

    setMaterialNotConsumed(data.join('\r'));
  };

  return (
    <>
      {materialNotConsumed.length > 0 ? (
        <DownloadButton
          key="not-consumed-data-download-button"
          disabled={materialNotConsumed === ''}
          filename={`Allocated_Not_Consumed_${new Date().toLocaleDateString()}.csv`}
          data={materialNotConsumed}
          onClick={() => {
            setTimeout(() => {
              setMaterialNotConsumed('');
            }, 200);
          }}
        >
          Download Body Part Data
        </DownloadButton>
      ) : (
        <ComponentButton type="default" onClick={onClick}>Allocated (JB) Not Consumed</ComponentButton>
      )}
    </>
  );
};

export default AllocatedNotConsumedButton;
