import React from 'react';
import styled from 'styled-components';
import { InputNumber } from 'antd';
import { useRecoilState } from 'recoil';
import { FlexRow } from 'shared/containers/FlexContainer';
import { Caption } from 'shared/typography';
import theme from 'shared/theme';
import { currentCustomerAtom } from 'shared/state/customerState';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';

const CustomerDiscountRow = styled(FlexRow)`
  justify-content: flex-start;
  margin-bottom: ${theme.spacing(2)};
  gap: 24px;
`;

const NumberInput = styled(InputNumber)`
  font-size: 16px;
`;
const CustomerDiscounts = () => {
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);

  const onNeckDiscountChange = (value: number) => {
    setCurrentCustomer({ ...currentCustomer, neckDiscount: value });
  };
  const onBodyDiscountChange = (value: number) => {
    setCurrentCustomer({ ...currentCustomer, bodyDiscount: value });
  };

  return (
    <>
      <CustomerDiscountRow>
        <ItemDetailsColumnWrapper key="neck-discount-item-details-wrapper">
          <ItemDetailsLabel key="neck-discount-item-details-label">Neck Discount</ItemDetailsLabel>
          <NumberInput
            placeholder="0"
            step="1"
            value={currentCustomer.neckDiscount}
            formatter={(value) => `${value}%`}
            parser={(value) => value!.replace('%', '')}
            onChange={onNeckDiscountChange}
          />
        </ItemDetailsColumnWrapper>
        <ItemDetailsColumnWrapper key="body-discount-item-details-wrapper">
          <ItemDetailsLabel key="body-discount-item-details-label">Body Discount</ItemDetailsLabel>
          <NumberInput
            placeholder="0.00"
            step="1"
            value={currentCustomer.bodyDiscount}
            formatter={(value) => `${value}%`}
            parser={(value) => value!.replace('%', '')}
            onChange={onBodyDiscountChange}
          />
        </ItemDetailsColumnWrapper>
      </CustomerDiscountRow>
    </>
  );
};

export default CustomerDiscounts;
