import React, { useEffect, useState } from 'react';
import { find, findIndex } from 'lodash';
import {
  Image, Text, View, StyleSheet,
} from '@react-pdf/renderer';
import { formatPrice } from 'shared/data';
import { IItemData } from 'pages/Orders/Components/OrderItems/OrderItem';
import { IOrderItem } from '../../../../shared/types/dbRecords';
import { IRunner, IWorkOrder } from '../../../Orders/types';

const style = StyleSheet.create({
  // Order Details + QR
  qrCodeRow: {
    width: 538,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'auto',
    marginLeft: 10,
  },
  qrCode: {
    width: 64,
    height: 64,
    marginLeft: 12,
  },
  orderDetailsWrapper: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  orderDetailsRow: {
    flexDirection: 'row',
    width: 279,
    height: 80,
  },
  orderDetailsColumn: {
    flexDirection: 'column',
    width: '50%',
  },
  orderDetailThree: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'auto',
    flexGrow: 2,
    borderBottom: '1px solid grey',
    borderRight: '1px solid grey',
    paddingRight: 8,
    paddingleft: 4,
  },
  orderDetailTwo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'auto',
    flexGrow: 2,
    borderBottom: '1px solid grey',
    paddingLeft: 8,
    paddingRight: 4,
  },
  detailName: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 11,
  },
  detailValue: {
    fontFamily: 'Red Hat Display',
    fontWeight: 'normal',
    fontSize: 11,
  },
  orderDescription: {
    marginTop: 4,
    fontFamily: 'Red Hat Display',
    fontWeight: 'normal',
    fontSize: 11,
  },
});

interface IComponent {
  items: IItemData[];
  shopOrder: IWorkOrder;
  runnerId: string;
}
const WorkOrderPage = ({ items, shopOrder, runnerId }: IComponent) => {
  const workOrder = find(shopOrder.runners, (r) => r.id === runnerId) as IRunner;
  const workOrderIndex = findIndex(shopOrder.runners, (r) => r.id === runnerId);
  const [qrCodeSource, setQrCodeSource] = useState<string>('');
  const itemCount = items.map((i: IItemData) => i.quantityToMake).reduce((a, b) => a + b, 0);
  const workOrderUnits = workOrder.parts
    .filter((i: IOrderItem) => i.Sku.match(/[A-Z]{5}/))
    .map((i: IOrderItem) => i.quantityAssigned).reduce((a, b) => a + b, 0);
  const workOrderValue = formatPrice(workOrder.parts.map((i: IOrderItem) => i.quantityAssigned * i.unitPrice).reduce((a, b) => a + b, 0));

  useEffect(() => {
    const qrElement = document.getElementById(`qr-${runnerId}`);
    if (!qrElement) return;
    // @ts-ignore
    setQrCodeSource(qrElement.toDataURL());
  }, []);

  return (
    <View style={style.qrCodeRow}>
      <Image src={qrCodeSource} style={{ width: 64, height: 64 }} />
      <View style={style.orderDetailsWrapper}>
        <View style={style.orderDetailsRow}>
          <View style={style.orderDetailsColumn}>
            <View style={style.orderDetailThree}>
              <Text style={style.detailName}>Customer</Text>
              <Text style={style.detailValue}>{shopOrder.customer.id}</Text>
            </View>
            <View style={style.orderDetailThree}>
              <Text style={style.detailName}>Units</Text>
              <Text style={style.detailValue}>{workOrderUnits}</Text>
            </View>
            <View style={style.orderDetailThree}>
              <Text style={style.detailName}>Value</Text>
              <Text style={style.detailValue}>{workOrderValue}</Text>
            </View>
          </View>
          <View style={style.orderDetailsColumn}>
            <View style={style.orderDetailTwo}>
              <Text style={style.detailName}>Sales Order</Text>
              <Text style={style.detailValue}>{shopOrder.salesOrder}</Text>
            </View>
            <View style={style.orderDetailTwo}>
              <Text style={style.detailName}>Work Order</Text>
              <Text style={style.detailValue}>{`${workOrderIndex + 1}/${shopOrder.runners.length}`}</Text>
            </View>
          </View>
        </View>
        <Text style={style.orderDescription}>{workOrder?.description}</Text>
      </View>
    </View>
  );
};

export default WorkOrderPage;
