import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { formatPrice } from '../../../../shared/data';
import { IBomItem } from '../../../../shared/types/dbRecords';

interface IBomDetailPopover {
  bomItem: IBomItem;
}

const PopoverDetailWrapper = styled(FlexColumn)`
  width: 200px;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 20px 8px 12px 8px;
  gap: 4px;
`;
const PopoverDetailRow = styled(FlexRow)`
  width: 200px;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
`;
const PopoverDetailLabel = styled.p`
  margin: 0;
  font-weight: bold;
`;
const PopoverDetailValue = styled.p`
  margin: 0;
`;

const BomDetailDetail = ({
  bomItem,
}: IBomDetailPopover) => {
  useEffect(() => {

  }, [bomItem]);
  return (
    <PopoverDetailWrapper>
      <PopoverDetailRow>
        <PopoverDetailLabel>Vendor</PopoverDetailLabel>
        <PopoverDetailValue>{bomItem.vendor}</PopoverDetailValue>
      </PopoverDetailRow>
      <PopoverDetailRow>
        <PopoverDetailLabel>Quantity</PopoverDetailLabel>
        <PopoverDetailValue>{`${bomItem.quantity} ${bomItem.unit}`}</PopoverDetailValue>
      </PopoverDetailRow>
      <PopoverDetailRow>
        <PopoverDetailLabel>Unit Cost</PopoverDetailLabel>
        <PopoverDetailValue>{formatPrice(bomItem.unitCost)}</PopoverDetailValue>
      </PopoverDetailRow>
      <PopoverDetailRow>
        <PopoverDetailLabel>Total Cost</PopoverDetailLabel>
        <PopoverDetailValue>{formatPrice(bomItem.totalCost)}</PopoverDetailValue>
      </PopoverDetailRow>
      <PopoverDetailRow>
        <PopoverDetailLabel>Unit Sell</PopoverDetailLabel>
        <PopoverDetailValue>{formatPrice(bomItem.unitPrice)}</PopoverDetailValue>
      </PopoverDetailRow>
      <PopoverDetailRow>
        <PopoverDetailLabel>Total Sell</PopoverDetailLabel>
        <PopoverDetailValue>{formatPrice(bomItem.totalPrice)}</PopoverDetailValue>
      </PopoverDetailRow>
    </PopoverDetailWrapper>
  );
};

export default BomDetailDetail;
