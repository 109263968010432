import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  reverse, sortBy, every, includes,
} from 'lodash';
import { useRecoilValue } from 'recoil';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { MESSAGE_DB_PATH_ATOM, messageDisplayTypeAtom, messageSearchQueryAtom } from 'shared/state/messageState';
import { IMessage } from 'shared/types/dbRecords';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import MessageCenterItem from './Components/MessageCenterItem';
import MessageFilter from './Components/MessageFilter';
import MessageTypeSelector from './Components/Buttons/MessageTypeSelector';

const ComponentWrapper = styled(FlexColumn)`
  height: calc(100% - 24px);
  justify-content: flex-start;
  gap: 16px;
  overflow-y: scroll;
  padding-top: 24px;
`;

const FilterRow = styled(FlexRow)`
  width: 100%;
  padding-bottom: 24px;
  margin-bottom: 24px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 10px -10px;
  gap: 16px;
`;

const MessageCenter = () => {
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const { database } = useFirebase();
  const mailDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const messageDisplayType = useRecoilValue(messageDisplayTypeAtom);
  const messageFilterQuery = useRecoilValue(messageSearchQueryAtom);
  const [messages, setMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    database.ref(`/${mailDbString}`).on('value', (snap) => {
      const user = currentUser.email.split('@')[0];
      const messageData = snap.val();
      if (!messageData) return;
      const userMessageData = messageData[currentUser.email.split('@')[0]] || { read: [], unread: [] };
      // @ts-ignore
      const _messages = messageDisplayType === 'active' ? Object.values(messageData.unread || []) as IMessage[] : Object.values(messageData.read || []) as IMessage[];
      const personalMessages = messageDisplayType === 'active' ? Object.values(userMessageData.unread || {}) as IMessage[] : Object.values(userMessageData.read || {}) as IMessage[];

      const unsortedData = [
        ..._messages.filter((m: IMessage) => includes(m.users, currentUser.email.split('@')[0])).map((m: IMessage) => ({ ...m, personal: false })),
        ...personalMessages.map((m: IMessage) => ({ ...m, personal: true })),
      ];

      const data = reverse(sortBy(unsortedData, (p: IMessage) => p.sent));

      setMessages(data
        .filter((p: IMessage) => {
          const terms = messageFilterQuery.split(',');
          const matches = terms.map((t: string) => `${p.subject}${p.message}`.match(new RegExp(t.trim(), 'i')));
          return every(matches, Boolean);
        }));
    });
  }, [messageFilterQuery, messageDisplayType]);

  return (
    <ComponentWrapper>
      <FilterRow>
        <MessageFilter />
        <MessageTypeSelector />
      </FilterRow>
      {!!messages.length && (
        <>
          {messages.map((message: IMessage) => (

            <MessageCenterItem
              key={`message-center-item-${message.id}`}
              clickUrl={message.clickUrl || undefined}
              id={message.id}
              archived={message.archived}
              sent={message.sent}
              subject={message.subject}
              message={message.message}
              personal={message.personal}
            />
          ))}
        </>
      )}
    </ComponentWrapper>
  );
};

export default MessageCenter;
