import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  every, find, first, flattenDeep, groupBy, reverse, sortBy,
} from 'lodash';
import {
  dataLoadingAtom,
} from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  adminUserEmailsAtom, orderViewEndDateAtom, orderViewStartDateAtom, scheduleViewEndDateAtom, scheduleViewStartDateAtom,
  superAdminUserEmailAtom,
} from 'shared/state/routingState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import moment from 'moment/moment';
import firebase from 'firebase';
import shortid from 'shortid';
import { IOrderItem, IOrderItemRecord } from 'shared/types/dbRecords';
import { PricingCol, PageHeaderWrapper } from '../styledComponents';
import Loader from '../../shared/components/Utility/Loader';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';
import ActiveSelector from '../Customer/Components/PriceListInputs/ActiveSelector';
import OrderRecordFilter from './Components/OrderRecords/OrderRecordFilter';
import OrdersHeader from './Components/OrderRecords/OrderRecordListHeader';
import OrderRecordsList from './Components/OrderRecords/OrderRecordList';
import {
  activeOrderDisplayAtom,
  ORDER_ITEMS_DB_COLLECTION,
  ORDERS_DB_COLLECTION,
  shopOrdersAtom,
} from '../../shared/state/orderState';
import { OrderRangePicker, RunnerPageTitle } from '../ProductionSchedule/styledComponents';
import {
  ORDERS_VIEW_END_KEY,
  ORDERS_VIEW_START_KEY,
} from '../../shared/data/calendar';
import { IRunner, ISalesOrder, IShopOrder } from './types';
import NewOrderButton from './Components/Buttons/NewOrderButton';
import { ButtonIsland } from '../../shared/styledComponents/containers';
import ExportOrdersButton from './Components/Buttons/ExportOrdersButton';
import ItemListFilter from '../../shared/components/Input/ItemListFilter';
import { listFilterQuery } from '../../shared/util';
import { DatePickerNextIcon, DatePickerPrevIcon } from '../../shared/styledComponents/inputs';

const OrderButtonIsland = styled(ButtonIsland)`
    @media ${theme.device.laptopL} {
      min-width: unset;
      justify-content: center;
    }
`;
const OrdersAdminWrapper = styled(FlexColumn)`
  height: auto;
  width: 100%;
  align-items: flex-start;
  background-color: ${(props: any) => (props.testMode ? '#FCEBEC' : theme.palette.neutral.white)};
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  position: sticky;
  top: 8px;
  z-index: 1000;
  padding: 32px 0 4px 0;
  gap: 32px;
`;

const PreFilterRow = styled(FlexRow)`
    width: 100%;
    justify-content: flex-start;
  gap: 16px;
`;
interface IOrderRecord extends IShopOrder{
  orderItems: IOrderItem[];
}
/**
 *   Display component that lists and filters all orders,
 */
export default () => {
  const { firestore } = useFirebase();
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useRecoilState(dataLoadingAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const [startDate, setStartDate] = useRecoilState(orderViewStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(orderViewEndDateAtom);
  const [orders, setOrders] = useState<IShopOrder[]>([]);
  const [renderItems, setRenderItems] = useState<IShopOrder[]>([]);
  const sessionKey = 'orders.query.string';
  const sessionPageKey = 'orders.currentPage';
  const sessionActiveKey = 'orders.activeDisplay';
  const [query, setQuery] = useState<string>(sessionStorage.getItem(sessionKey) || '');
  const [currentPage, setCurrentPage] = useState<number>(parseInt((sessionStorage.getItem(sessionPageKey) || '1'), 10));
  const [showActive, setShowActive] = useState<boolean>(true);
  const [activeDisplayType, setActiveDisplayType] = useState<'active'|'inactive'|'all'>(sessionStorage.getItem(sessionActiveKey) || 'all');
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);

  const updatePageData = (filterQuery: string, orderRecords: IShopOrder[] = orders, activeType = activeDisplayType): void => {
    const _query = listFilterQuery(filterQuery);
    // @ts-ignore
    const items = reverse(sortBy(orderRecords, (o: IOrderRecord) => o.orderDate.toDate()))
      .filter((o: IOrderRecord) => {
        if (activeType === 'active') return !o.completed;
        if (activeType === 'inactive') return o.completed;
        return o;
      })
      .filter((o: IOrderRecord) => {
        if (filterQuery === '') return o;
        const jobNotes = o.runners ? flattenDeep(o.runners.map((r: IRunner) => r.parts?.map((p: IOrderItem) => p.notes))).join('') : '';
        let matchString = `${o.purchaseOrder}${o.salesOrder}${o.description}${o.type}${(o.orderValue || 0).toString()}${o.customer.id}${o.customer.CompanyName}${jobNotes}`;

        const partNames = o.orderItems.filter((i: IOrderItem) => i).map((i) => i.Sku).join('');
        const descriptions = o.orderItems.filter((i: IOrderItem) => i).map((i) => i.Description).join('');
        matchString += `${partNames}${descriptions}`;

        if (o.orderDate) matchString += o.orderDate.toDate().toLocaleString();
        if (o.shipDate) matchString += o.shipDate.toDate().toLocaleString();
        const matches = _query.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return every(matches, Boolean);
      });
    // @ts-ignore
    setRenderItems(items);
  };

  const onChangeFilterTerms = (filterQuery: string) => {
    setQuery(filterQuery);
    sessionStorage.setItem(sessionKey, filterQuery);
    setCurrentPage(1);
    sessionStorage.setItem(sessionPageKey, '1');
    updatePageData(filterQuery);
  };

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
    sessionStorage.setItem(sessionPageKey, newPage.toString());
  };
  const onChangeDates = (date: any) => {
    const [sDate, eDate] = date;
    const newStartDate = new Date(sDate.year(), sDate.month(), sDate.date());
    setStartDate(newStartDate);
    localStorage.setItem(ORDERS_VIEW_START_KEY, newStartDate.getTime().toString());

    const newEndDate = new Date(eDate.year(), eDate.month(), eDate.date());
    setEndDate(newEndDate);
    localStorage.setItem(ORDERS_VIEW_END_KEY, newEndDate.getTime().toString());
  };
  useEffect(() => {
    setLoading(true);
    const sDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    sDate.setHours(0, 0, 0, 0);
    const eDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    eDate.setHours(23, 59, 59, 999);

    const _orderItems: IOrderRecord[] = [];
    const orderRecords: IShopOrder[] = [];
    firestore.collection(ordersDbString)
      .where('orderDate', '>=', sDate)
      .where('orderDate', '<=', eDate)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          orderRecords.push(doc.data() as ISalesOrder);
        });
        Promise.all(orderRecords.map((o) => firestore.collection(orderItemsDbString).doc(o.id).get())).then((itemDocs) => {
          itemDocs.forEach((doc) => {
            const items = doc.data()?.orderItems as IOrderItem[];
            const order = find(orderRecords, (o) => o.id === doc.id);
            // @ts-ignore
            _orderItems.push({ ...(order || {}), orderItems: (items?.filter((i: IOrderItem) => i) || []) });
          });
          setOrders(_orderItems);
          updatePageData(query, _orderItems);
          // setOrderItems(_orderItems);
          setLoading(false);
        });
      });
  }, [startDate, endDate]);

  const onChangeActiveType = (newType: string) => {
    setActiveDisplayType(newType);
    updatePageData(query, orders, newType);
  };

  return (
    <PageHeaderWrapper>
      <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
        <OrdersAdminWrapper id="order-list-inputs">
          <RunnerPageTitle key={shortid.generate()}>Orders</RunnerPageTitle>
          <PreFilterRow key="pre-filter-row">
            <OrderButtonIsland>
              <NewOrderButton />
              <ExportOrdersButton orders={orders} startDate={startDate} endDate={endDate} />
            </OrderButtonIsland>
            <ItemListFilter
              id="order-record-filter"
              callback={onChangeFilterTerms}
              callbackOnChange
              currentValue={query}
              prompt="Filter orders using comma-separated terms, e.g. NASH_00041, GN, Alder"
            />
            {/* <OrderRecordFilter key="order-record-filter" /> */}
            <ActiveSelector key="active-selector" callback={onChangeActiveType} activeType={activeDisplayType} />
            <OrderRangePicker
              key="order-range-picker"
              onChange={onChangeDates}
              value={[moment(startDate), moment(endDate)]}
              nextIcon={<DatePickerNextIcon />}
              prevIcon={<DatePickerPrevIcon />}
            />
          </PreFilterRow>
        </OrdersAdminWrapper>
      </ScopedComponent>
      <PricingCol key="data-column">
        {loading
          ? (
            <FlexColumn key="loader-component" style={{ width: '100%', height: '50vw' }}>
              <Loader />
            </FlexColumn>
          ) : (
            <OrderRecordsList key="order-records-list" renderItems={renderItems} currentPage={currentPage} pageChangeCallback={onChangePage} />
          )}
      </PricingCol>
    </PageHeaderWrapper>
  );
};
