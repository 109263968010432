import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { currentShopOrderAtom } from 'shared/state/orderState';
import { salesOrderEditedAtom } from 'shared/state/routingState';
import theme from 'shared/theme';
import { FlexRow } from '../../containers/FlexContainer';

// @ts-ignore
const FieldInput = styled(Input)`
  width: ${(props: { width: string }) => props.width || '144px'};
  padding: ${(props: { isLabel: boolean }) => (props.isLabel ? '4px 0' : '4px 11px')};
    &:disabled {
        background-color: ${(props: any) => (props.isLabel ? 'transparent' : '#EEEEEE')};
        color: ${(props: any) => (props.isLabel ? theme.palette.neutral.black : '#444444')} !important;
        border: ${(props: any) => (props.isLabel ? 'none' : '1px solid #d9d9d9')};
        ::placeholder {
          color: ${(props: any) => (props.isLabel ? theme.palette.neutral.black : '#444444')} !important;
        }
    }
    border: ${(props: { isLabel: boolean }) => (props.isLabel ? 'unset' : '1px solid lightgrey')};
`;

const ComponentInput = styled(ItemDetailsColumnWrapper)`
    flex-grow: ${(props: any) => (props.extend ? 2 : 0)};
    width: ${(props: any) => (props.extend ? '100%' : `${props.wrapperWidth ? props.wrapperWidth : 'auto'}`)};
    // margin: ${(props: any) => (props.noMargin ? '0' : '0 0 16px 0')};
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

interface IComponent {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  callback: (value: string, blur: boolean) => void;
  width?: string;
  wrapperWidth?: string;
  useNumber?: boolean;
  disabled?: boolean
  extend: boolean; // if true, set flex-grow to 2
  isLabel: boolean; // act as a label, i.e., disabled, but not greyed out
  noMargin?: boolean;
  LabelExtra?: any
}
const DetailInputWithCallback = ({
  id, value, callback, label, placeholder, width, wrapperWidth = 'auto', disabled = false, extend = false, isLabel = false, noMargin = false, LabelExtra = <div />,
}: IComponent) => {
  const [fieldValue, setFieldValue] = useState<string|undefined>(value);

  const onChange = (e: any) => {
    setFieldValue(e.target.value);
    callback(e.target.value, false);
  };

  const onBlur = (e: any) => {
    callback(fieldValue || e.target.value, true);
  };

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <ComponentInput noMargin={noMargin} extend={extend} wrapperWidth={wrapperWidth} key={`${id}-item-details-wrapper`}>
      <LabelWrapper>
        <ItemDetailsLabel key={`${id}-item-details-wrapper`}>{label}</ItemDetailsLabel>
        {LabelExtra}
      </LabelWrapper>
      <FieldInput
        width={extend ? '100%' : width}
        key={`${id}-input-field`}
        id={`${id}-input-field`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={fieldValue}
        disabled={disabled}
        isLabel={isLabel}
      />
    </ComponentInput>
  );
};

export default DetailInputWithCallback;
