const xl = require('excel4node');

export const generateWorkbook = () => new xl.Workbook();

export const workbookStyles = (workbook) => {
  const sheetOptions = {
    sheetFormat: {
      baseColWidth: 15,
      baseRowHeight: 24,
    },
  };
  const BaseFontStyle = { color: 'black', size: 14 };
  const BodyBorderStyle = {
    left: {
      style: 'thin',
      color: '#D9D9D9',
    },
    right: {
      style: 'thin',
      color: '#D9D9D9',
    },
    bottom: {
      style: 'thin',
      color: '#D9D9D9',
    },
    top: {
      style: 'thin',
      color: '#D9D9D9',
    },
  };
  const heroStyle = workbook.createStyle({
    font: {
      color: 'white',
      size: 18,
      bold: true,
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '1890ff',
    },
  });
  const sectionCurrencyStyle = workbook.createStyle({
    font: {
      color: 'black',
      size: 14,
      bold: true,
    },
    numberFormat: '$#,##0; ($#,##0); -',
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  });
  const sectionDataStyle = workbook.createStyle({
    font: {
      color: 'black',
      size: 14,
      bold: true,
    },
    numberFormat: '#,##0; (#,##0); -',
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  });
  const headerStyle = workbook.createStyle({
    font: {
      color: 'black',
      size: 18,
      bold: true,
    },
    border: {
      bottom: {
        style: 'thick',
        color: 'black',
      },
    },
  });
  const headerStyleVert = workbook.createStyle({
    font: {
      color: 'black',
      size: 18,
      bold: true,
    },
    alignment: {
      textRotation: 90,
      wrapText: true,
      horizontal: 'center',
    },
    border: {
      bottom: {
        style: 'thick',
        color: 'black',
      },
    },
  });
  const rowStyle = workbook.createStyle({
    font: BaseFontStyle,
    border: BodyBorderStyle,
  });
  const rowCurrencyStyle = workbook.createStyle({
    font: BaseFontStyle,
    numberFormat: '$#,##0; ($#,##0); -',
    border: BodyBorderStyle,
  });
  const rowNumberStyle = workbook.createStyle({
    font: BaseFontStyle,
    numberFormat: '#,##0; (#,##0); -',
    border: BodyBorderStyle,
  });
  const columnTotalNumberStyle = workbook.createStyle({
    font: {
      ...BaseFontStyle,
      bold: true,
    },
    numberFormat: '#,##0; (#,##0); -',
    border: {
      top: {
        style: 'thick',
        color: 'black',
      },
    },
  });
  const columnTotalCurrencyStyle = workbook.createStyle({
    font: {
      ...BaseFontStyle,
      bold: true,
    },
    numberFormat: '$#,##0; ($#,##0); -',
    border: {
      top: {
        style: 'thick',
        color: 'black',
      },
    },
  });

  return {
    BaseFontStyle,
    BodyBorderStyle,
    columnTotalCurrencyStyle,
    columnTotalNumberStyle,
    headerStyle,
    headerStyleVert,
    heroStyle,
    rowCurrencyStyle,
    rowNumberStyle,
    rowStyle,
    sectionCurrencyStyle,
    sectionDataStyle,
    sheetOptions,
  };
};

// eslint-disable-next-line no-shadow
export enum CUST_CELLS {
  CUSTOMER_PO = 1,
  SALES_ORDER,
  DESCRIPTION,
  ORDER_DATE,
  UNITS,
  VALUE,
  RELEASE_DATE,
  SHIP_DATE,
  STATUS
}

// eslint-disable-next-line no-shadow
export enum DATE_CELLS {
  CUSTOMER_PO = 1,
  SALES_ORDER,
  CUSTOMER,
  DESCRIPTION,
  ORDER_DATE,
  UNITS,
  VALUE,
  RELEASE_DATE,
  SHIP_DATE,
  STATUS
}

export const letterAtIndex = (index) => 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.substr(index - 1, 1);
export const rowValueAtCol = (colIndex, rowIndex = currentRow, lockRow = false, lockCol = false) =>
  `${lockCol ? '$' : ''}${letterAtIndex(colIndex)}${lockRow ? '$' : ''}${rowIndex}`;
