import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Search } from '@styled-icons/material-twotone';
import { Input } from 'antd';
import { includes, some } from 'lodash';
import { FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { cleanMeta } from '../../text';

const ComponentWrapper = styled(FlexRow)`
    width: ${(props: any) => (props.width ? props.width : 'calc(70% - 16px)')};
`;

const SearchIcon = styled(Search)`
  width: 32px;
  color: ${theme.palette.primary.hue};
`;

const ItemSearchInput = styled(Input)`
  & * {
    border-radius: 6px;
  }
  & input {
    width: 100%;
    height: 48px;
    flex-grow: 2;
    font-size: 16px;
    @media ${theme.device.laptopM} {
      width: calc(100% - 40px);
    }
  }
`;

interface IComponent {
  id: string;
  callback: (value: string) => void;
  callbackOnChange?: boolean;
  initialValue?: string;
  currentValue?: string;
  prompt: string;
  width?: string;
}
const ItemListFilter = ({
  id, callback, prompt, width = '50%', callbackOnChange = true, initialValue = undefined, currentValue = undefined,
}: IComponent) => {
  const [query, setQuery] = useState<string>(initialValue || currentValue);

  const onChangeSearch = (e: any) => {
    if (some('!@#$%^&*()+=`~[]{};:><?\\'.split(''), (c: string) => includes(e.target.value.split(''), c))) return;
    setQuery(e.target.value);
    if (callbackOnChange) callback(e.target.value);
  };

  const onBlur = (e: any) => {
    setQuery(e.target.value);
    callback(e.target.value);
  };

  useEffect(() => {
    if (currentValue) setQuery(currentValue);
  }, [currentValue]);

  return (
    // @ts-ignore
    <ComponentWrapper width={width}>
      <ItemSearchInput
        id={id}
        key={id}
        addonBefore={<SearchIcon />}
        value={query}
        placeholder={prompt}
        onChange={onChangeSearch}
        onBlur={onBlur}
        onPressEnter={onBlur}
      />
    </ComponentWrapper>
  );
};

export default ItemListFilter;
