// LAYOUT
import styled from 'styled-components';
import { Button, Input, InputNumber } from 'antd';
import { Calculator } from '@styled-icons/fa-solid';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';

import { ViewTypeRadioGroup } from '../../../shared/styledComponents/inputs';

export const PageWrapper = styled(FlexRow)`
  flex-direction: column;
  height: auto;
  width: 100%;
  padding-top: 28px;
  
  @media ${theme.device.laptopM} {
    padding-top: 0;
    height: calc(100vh - 48px);
    flex-direction: row;  
  }
`;
export const CalculatorColumn = styled(FlexColumn)`
  height: calc(100% - 48px);
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  
  @media ${theme.device.laptopM} {
    height: calc(100% - 80px);
    width: calc(50% - 24px);
  }
`;
export const CalculatorSectionWrapper = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: space-around;
  height: auto;
  width: 90%;
  border-radius: 20px;
  border: ${(props: any) => (props.enable ? '1px solid lightgray' : 'transparent')};
  padding: ${(props: any) => (props.enable ? '8px 0' : '4px 0')};
  //padding: 4px 0;
  margin-bottom: 24px;
  transition: padding 0.3s ease-in-out;
  
  &h2 {
    width: 100%;
    background-color: yellow;
    padding: 2px 0;
  }
  
  @media ${theme.device.tablet} {
    width: 50%;
  }
  @media ${theme.device.laptopM} {
    width: 80%;
  }
  @media ${theme.device.laptopL} {
    width: 60%
  }
`;
export const CalculatorInputWrapper = styled(FlexRow)`
  width: 100%;
  height: unset;
  opacity: 1;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  justify-content: flex-start;
  border: 1px solid lightgrey;
  border-radius: 8px;
  
  @media ${theme.device.laptopM} {
    height: auto;
  }
`;
export const LumberSpecWrapper = styled(FlexColumn)`
  width: 100%;
  margin-top: 8px;
`;
export const LumberValueColumn = styled(FlexRow)`
  width: 100%;
`;
export const WeightControlsWrapper = styled(CalculatorSectionWrapper)`
  height: auto;
  width: 80%;
  justify-content: space-around;
  border-radius: 20px;
  padding: 8px 0;
  //margin-bottom: 48px;

  @media ${theme.device.tablet} {
    width: 50%;
  }
  @media ${theme.device.laptopL} {
    width: 60%
  }
`;
export const MinMaxWeightColumn = styled(FlexColumn)`
  //margin-right: 48px;
  width: 50%;
  padding-top: 20px;
  margin-bottom: 16px;
  
  & h3 {
    font-size: 18px;
  }
  
  &:last-of-type {
    margin-right: 0;
  }
  
  @media ${theme.device.tablet} {
    & h3 {
      font-size: 20px;
    }
  }
`;
export const MinMaxWeightInputWrapper = styled(FlexColumn)`
  margin-right: 4px;
  & p {
    margin-bottom: 2px;
  }
`;
export const VolumeColumnWrapper = styled(WeightControlsWrapper)`
  flex-direction: column;
  align-items: center;
  padding: 0 0 24px 0;
  width: 90%;
  @media ${theme.device.tablet} {
    width: 50%;
    padding: 0 0 24px 0;
  }
  @media ${theme.device.laptopM} {
    width: 60%;
  }
`;
export const VolumeInputWrapper = styled(FlexRow)`
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;
export const ResultsColumnWrapper = styled(FlexColumn)`
  height: auto;
  width: 100%;
  margin-bottom: 200px;
  //scroll-margin-top: 56px;
  
  @media ${theme.device.laptopM} {
    height: 100%;
    width: 50%;
    overflow-y: scroll;
    padding: 48px 0;
    border-left: 2px solid lightgray;
    margin-bottom: 0;
    //scroll-margin-top: 0;
  }
`;
export const ResultsColumn = styled(CalculatorColumn)`
  padding-top: 0px;
  @media ${theme.device.tablet} {
    height: 100%; 
    width: 100%;
  }
`;
export const PartHeaderRow = styled(FlexColumn)`
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 16px auto;
  gap: 8px;
    
  
  @media ${theme.device.mobileS} {
    width: 100%;    
    padding: 18px 24px 10px 10px;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 10000;
    background-color: white;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12);
  }
  @media ${theme.device.laptopM} {
      padding: 18px 24px 10px 48px;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid grey;
      box-shadow: none;
  }
  @media ${theme.device.laptopL} {
      padding: 0 24px 10px 48px;
  }
`;
export const PartDataColumn = styled(FlexColumn)`
  align-items: flex-end;
  width: 100%;
  margin-bottom: 8px;
  & * {
    margin: 0;
  }
  & h3 {
    font-size: 22px;
  }
  @media ${theme.device.tablet} {
    width: 90%;
    margin-bottom: 0;
    align-items: flex-start;
  }
`;

export const PartDescription = styled.p`
  display: none;
    @media ${theme.device.tablet} {
        display: unset;
    }
`;
export const PartBoardFootage = styled.p`
    background-color: yellow;
    padding: 2px 4px;
`;
export const PartVolumeDataColumn = styled(PartDataColumn)`
    @media ${theme.device.laptopM} {
        align-items: flex-end;
    }
`;
export const PartMetricsWrapper = styled(FlexRow)`
  justify-content: flex-start;
  gap: 16px;
   
  & * {
    margin: 0;
  }
  & h3 {
    font-size: 22px;
  }
  @media ${theme.device.tablet}{ 
    justify-content: flex-end;
    width: 10%;
    margin-bottom: 0;
  }
`;

export const PartMetricsColumn = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: flex-start;
  @media ${theme.device.tablet} {
    align-items: flex-end;
  }
`;

export const CellText = styled(FlexRow)`
  width: 100%;
  height: 100%;
  font-size: 16px;
  margin: 0;
  
  background-color: ${(props) => (props.backgroundColor ? 'yellow' : 'transparent')};
`;

// INPUTS
export const LumberButton = styled(Button)`
  height: 48px;
  width: calc(100% - 16px);
  //width: ${(props) => (props.enable ? '48px' : 'calc(100% - 16px)')};
  border-radius: ${(props) => (props.enable ? '8px' : '16px')};
  position: relative;
  left: 8px;
  transition: all 0.35s ease-in-out;
`;
export const ToggleWeightButton = styled(LumberButton)``;
export const PartInputWrapper = styled(FlexColumn)`
  width: 100%;
  flex-grow: 2;
  align-items: flex-start;
`;
export const PartInput = styled(Input)`
  font-size: 24px;
  height: 80px;
  width: 100%;
  border-radius: 4px;
  padding: 0 8px;
  text-align: center;
  
  @media ${theme.device.laptopM} {
    font-size: 32px;
  }
`;
export const WeightInput = styled(InputNumber)`
  height: 52px;
  width: 64px;
  font-size: 24px;
  border-radius: 4px;
  padding: 0;

  & .ant-input-number-input-wrap {
    height: 100%;
  }

  & input {
    height: 100%;
    width: 100%;
    text-align: center;
  }

  @media ${theme.device.tablet} {
    width: 80px;
  }
  @media ${theme.device.laptopM} {
    font-size: 32px;
    width: 88px;
  }
`;
export const LumberInput = styled(WeightInput)`
  width: 100px;
  @media ${theme.device.tablet} {
    width: 128px;
  }
`;

// BUTTONS
export const CalcTypeRadioGroup = styled(ViewTypeRadioGroup)`
  display: unset;
`;

export const VolumeCalculateButton = styled(Button)`
  font-size: 22px;
  height: 80px;
  width: calc(25% - 8px);
  border-radius: 4px;
`;
export const ToggleColumn = styled(FlexColumn)`
  & * {
    margin: 0;
  }
  
  & h5 {
    margin-bottom: 4px;
  }
  
  margin-bottom: 16px;
`;
export const CalculateIcon = styled(Calculator)`
  color: ${theme.palette.neutral.white};
  //width: 24px;
  height: 36px;
`;
