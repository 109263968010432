import { Tooltip } from 'antd';
import React from 'react';
import { FlexRow } from 'shared/containers/FlexContainer';
import {
  RouterInfoIcon,
  RouterTableInfoButton,
  RouterTDCopy,
} from 'pages/ProductionSchedule/styledComponents';
import theme from '../../../shared/theme';
import { IPartWood, IRateOption, IUnitOption } from '../../../shared/types/pricingTool';

const shortid = require('shortid');
const _ = require('lodash');

const onEditClick = (option: IUnitOption|IRateOption) => (e: any) => {
  e.preventDefault();
  const partType = localStorage.getItem('pricingEditCategory');
  window.location.href = `/pricing/option/edit?partType=${partType}&optionType=${option.type}&optionId=${option.id}`;
};

export const NAME_COLUMN = {
  title: 'Option Name',
  dataIndex: shortid.generate(),
  align: 'left',
  sorter: {
    compare: (a: IUnitOption, b: IUnitOption) => {
      if (a.name > b.name) return 1;
      if (b.name > a.name) return -1;
      return 0;
    },
  },
  render: (text: string, record: any) => (
    <FlexRow id={record.id} key={shortid.generate()} align="center" justify="space-between">
      <RouterTDCopy key={shortid.generate()}>{record.name}</RouterTDCopy>
    </FlexRow>
  ),
  width: 450,
};
export const PART_NUMBER_COLUMN = {
  title: 'Part No.',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => (
    <RouterTDCopy key={shortid.generate()}>{`${(record.partNumber || '-')}`}</RouterTDCopy>
  ),
  width: 100,
};
export const PRICE_COLUMN = {
  title: 'Option $',
  dataIndex: shortid.generate(),
  render: (text: string, record: IUnitOption) => (
    <RouterTDCopy key={shortid.generate()}>{`$${record.price}`}</RouterTDCopy>
  ),
  width: 100,
};

export const COST_COLUMN = {
  title: 'Cost',
  dataIndex: shortid.generate(),
  render: (text: string, record: IUnitOption) => (
    <RouterTDCopy key={shortid.generate()}>{`$${record.cost || 0}`}</RouterTDCopy>
  ),
  width: 100,
};

export const MARGIN_COLUMN = {
  title: 'Margin',
  dataIndex: shortid.generate(),
  render: (text: string, record: IPartWood) => {
    let margin = (record.cost) ? ((1 - (record.cost / record.price)) * 100) : '--';
    let color = 'lightgrey';
    let fontWeight = 'normal';
    let backgroundColor = 'transparent';
    if (margin !== '--') {
      color = margin < 20 ? theme.palette.neutral.white : margin >= 30 ? theme.palette.success.D100 : theme.palette.warning.D100;
      fontWeight = margin < 20 ? 'bold' : 'normal';
      backgroundColor = margin < 20 ? theme.palette.error.hue : 'transparent';
      margin = `${(margin as number).toFixed(2)}%`;
    }
    return (
      <RouterTDCopy style={{ color, fontWeight, backgroundColor }}>{`${margin}`}</RouterTDCopy>
    );
  },
  width: 100,
};

export const RATE_COLUMN = {
  title: 'Rate $',
  dataIndex: shortid.generate(),
  render: (text: string, record: IRateOption) => (
    <RouterTDCopy key={shortid.generate()}>{`$${record.price} / ${record.rate}`}</RouterTDCopy>
  ),
  width: 100,
};

export const ACTIONS_COLUMN = {
  title: '',
  key: 'actions',
  render: (text: string, record: any) => (
    <FlexRow justify="flex-start" style={{ paddingLeft: 24 }} key={shortid.generate()}>
      <Tooltip placement="right" title="View/Edit">
        <RouterTableInfoButton key={shortid.generate()} onClick={onEditClick(record)}><RouterInfoIcon /></RouterTableInfoButton>
      </Tooltip>
    </FlexRow>
  ),
};

export const unitOptionColumns = [
  NAME_COLUMN,
  PRICE_COLUMN,
  ACTIONS_COLUMN,
];

export const marginUnitOptionColumns = [
  NAME_COLUMN,
  {
    title: 'Details',
    children: [
      PART_NUMBER_COLUMN,
      PRICE_COLUMN,
      COST_COLUMN,
      MARGIN_COLUMN,
    ],
  },
  ACTIONS_COLUMN,
];

export const rateOptionColumns = [
  NAME_COLUMN,
  RATE_COLUMN,
  ACTIONS_COLUMN,
];
