import React from 'react';
import { useRecoilState } from 'recoil';
import { Radio } from 'antd';
import { partViewerTypeAtom } from 'shared/state/partViewState';
import { ViewTypeRadioButton } from '../../../shared/styledComponents/inputs';

export default () => {
  const [partViewerType, setPartViewerType] = useRecoilState(partViewerTypeAtom);

  const onChangeView = (e: any) => {
    e.preventDefault();
    setPartViewerType(e.target.value.match(/body/i) ? 'body' : 'neck');
  };

  return (
    <Radio.Group value={partViewerType} buttonStyle="solid" onChange={onChangeView}>
      <ViewTypeRadioButton
        value="body"
      >
        Body
      </ViewTypeRadioButton>
      <ViewTypeRadioButton
        value="neck"
      >
        Neck
      </ViewTypeRadioButton>
    </Radio.Group>
  );
};
