import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import { Button } from 'antd';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { useLongPress } from 'use-long-press';
import { FlexRow } from '../containers/FlexContainer';
import theme from '../theme';

interface IComponent {
  disabled: boolean;
  label: string;
  initialValue: number;
  minValue: number;
  maxValue: number;
  callback?: (n: number, longPress: boolean) => void;
  useIncrementValue: boolean;
  width: string;
}

interface IValue {
  value: number;
  disabled: boolean;
}

const ComponentWrapper = styled(FlexRow)`
  width: ${(props: any) => (props.width ? props.width : 'auto')};
  min-width: ${(props: any) => (props.disabled ? '136px' : '224px')};
  height: 36px;
  //background-color: ${theme.palette.primary[200]};
  background-color: ${theme.palette.neutral.white};
  border: ${(props: { disabled: boolean }) => (props.disabled ? `1px solid ${theme.palette.primary.hue}` : `1px solid ${theme.palette.neutral[500]}`)};
  border-radius: 8px;
  justify-content: flex-start;
  margin-bottom: 2px;
  padding: 0;
    padding-right: ${(props: any) => (props.disabled ? '12px' : '0')};
  gap: 8px;
    overflow: hidden;
`;

const Value = styled(FlexRow)`
  width: 36px;
  height: 100%;
  font-weight: ${(props: IValue) => (props.value > 0 ? 600 : 400)};
  background-color: ${(props: IValue) => (props.disabled ? theme.palette.neutral[500] : props.value > 0 ? theme.palette.primary[900] : theme.palette.primary[100])};
  //border: 1px solid ${theme.palette.primary.hue};
  color: ${(props: IValue) => (props.value > 0 ? theme.palette.neutral.white : theme.palette.neutral.black)};
  transition: all .2s ease-out;
`;

const Label = styled.div`
  
`;

const ButtonWrapper = styled(FlexRow)`
  margin-left: auto;
`;

const PlusButtonIcon = styled(PlusCircleFilled)`
  color: ${theme.palette.primary.D100};
  font-size: 20px;  
  margin: 2px 0 0 0;
  transition: color .3s ease-in-out;
`;
const MinusButtonIcon = styled(MinusCircleFilled)`
  color: ${theme.palette.primary.D100};
  font-size: 20px;
  margin: 2px 0 0 0;
  transition: color .3s ease-in-out;
`;

const ComponentButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 100%;
  background-color: transparent;
  border: none;
  transition: background-color .3s ease-in-out;
  padding: 4px 0;
  
  &:hover {
    background-color: ${theme.palette.primary[900]};
    ${PlusButtonIcon} {
      color: ${theme.palette.primary[100]};
    } 
    ${MinusButtonIcon} {
      color: ${theme.palette.primary[100]};
    }
  }
  
  &:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const LabelCounter = ({
  label, initialValue, callback, minValue = 0, maxValue = 100, width = 'auto', useIncrementValue = false, disabled = false,
}: IComponent) => {
  const [value, setValue] = useState(initialValue);
  const [_maxValue, setMaxValue] = useState(maxValue);
  const [longClick, setLongClick] = useState(false);
  const id = shortid.generate();

  const updateValue = (v: number) => (e: any) => {
    if (longClick) {
      setLongClick(false);
      return;
    }
    let newValue = value + v;
    if (newValue < minValue) newValue = minValue;
    if (newValue > _maxValue) newValue = _maxValue;
    if (newValue === value) return;
    setValue(newValue);
    if (callback) callback(useIncrementValue ? v : newValue, false);
  };

  const longPressAddBind = useLongPress(() => {
    if (longClick) {
      setLongClick(false);
      return;
    }
    setLongClick(true);
    setValue(maxValue);
    if (callback) callback(maxValue, true);
  });

  const longPressMinusBind = useLongPress(() => {
    setLongClick(true);
    setValue(minValue);
    if (callback) callback(minValue, true);
  });

  useEffect(() => {
    setValue(initialValue);
    setMaxValue(maxValue);
  }, [maxValue]);

  return (
    <>
      <ComponentWrapper disabled={disabled} key={`${label}-increment-counter-${id}`} width={width}>
        <Value key={`${label}-increment-counter-${id}-value`} value={value}>{value}</Value>
        <Label key={`${label}-increment-counter-${id}-label`}>{label}</Label>
        {!disabled && (
        <ButtonWrapper key={`${label}-increment-counter-${id}-button-wrapper`}>
          <ComponentButton key={`${label}-increment-counter-${id}-minus-button`} onClick={updateValue(-1)} {...longPressMinusBind()}><MinusButtonIcon /></ComponentButton>
          <ComponentButton key={`${label}-increment-counter-${id}-plus-button`} onClick={updateValue(1)} {...longPressAddBind()}><PlusButtonIcon /></ComponentButton>
        </ButtonWrapper>
        )}
      </ComponentWrapper>
    </>
  );
};

export default LabelCounter;
