import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import {
  ItemDetailsColumnWrapper,
  ItemDetailsLabel, ViewTypeRadioButton,
  ViewTypeRadioGroup,
} from 'shared/styledComponents/inputs';
import { currentShopOrderAtom } from 'shared/state/orderState';

const Picker = styled(ViewTypeRadioGroup)`
  display: unset;
  margin-left: 0;
`;
const OrderJobType = () => {
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);

  const onChangeType = (e: any) => {
    setCurrentShopOrder({ ...currentShopOrder, type: e.target.value });
  };

  return (
    <ItemDetailsColumnWrapper key="order-job-type-details-wrapper" width="164px">
      <ItemDetailsLabel key="order-job-typ-details-label">Job Type</ItemDetailsLabel>
      <Picker value={currentShopOrder.type} buttonStyle="solid" onChange={onChangeType}>
        <ViewTypeRadioButton
          value="body"
        >
          Body
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="neck"
        >
          Neck
        </ViewTypeRadioButton>
      </Picker>
    </ItemDetailsColumnWrapper>
  );
};

export default OrderJobType;
