import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  salesOrdersAtom, showNecksAtom, showBodiesAtom,
} from 'shared/state/routingState';

import NotFound from 'shared/components/Utility/NotFound';
import { numberWithCommas } from 'shared/text';
import theme from 'shared/theme';
import {
  RouterTableHeader,
  RouterTableHeaderWrapper,
  TableSectionHeaderWrapper,
  SplitSalesOrderTable,
  RouterTableHeaderDivider,
  RouterTableSubHeader,
  RouterStatHeaderWrapper,
  RouterTablePartsPerDay,
  CustomerRouterTableHeaderWrapper,
  PartTableWrapper,
  TableHeaderRow,
  RouterTableStatWrapper,
  RouterTableHeaderSectionWrapper,
  RouterWeekHeader,
  RouterTableHeaderDatesIcon,
  RouterTableHeaderDates,
  RouterTableHeaderDayCount, RouterTableHeaderVerticalDivider, RouterTableHeaderInvoicedIcon, ScheduleWeekHeaderRow, MonthWrapper,
} from './styledComponents';
import {
  salesOrderByCustomerBodyColumns,
  salesOrderByCustomerNeckColumns,
} from './Components/DataColumns';
import { FlexColumn, FlexRow } from '../../shared/containers/FlexContainer';
import Loader from '../../shared/components/Utility/Loader';
import DownloadCustomerOrdersButton from './Components/Buttons/DownloadCustomerOrdersButton';
import { ICustomerRecord } from '../../shared/types/dbRecords';
import { ISalesOrder, IShopOrder } from '../Orders/types';
import CustomerMenu from '../Customer/Components/CustomerMenu';
import { currentCustomerAtom, customersAtom } from '../../shared/state/customerState';
import { formatPercent } from '../../shared/data';
import DownloadWeekOrdersButton from './Components/Buttons/DownloadWeekOrdersButton';

const shortid = require('shortid');

interface IComponent {
  renderItems: IShopOrder[];
  showBodies: boolean;
  showNecks: boolean;
}
export default ({
  renderItems, showNecks, showBodies,
}: IComponent) => {
  const [orderRecords, setOrderRecords] = useState<IShopOrder[]>([]);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const customers = useRecoilValue(customersAtom);
  const [_showBodies, setShowBodies] = useState(showBodies);
  const [_showNecks, setShowNecks] = useState(showNecks);
  const [customerOrderDetail, setCustomerOrderDetail] = useState<any>({
    customerTotal: 0,
    invoicedTotal: 0,
    bodies: [],
    bodyInvoicedCount: 0,
    bodyValue: 0,
    bodiesInvoiced: 0,
    necks: [],
    neckInvoicedCount: 0,
    neckValue: 0,
    necksInvoiced: 0,
  });

  useEffect(() => {
    const customerOrders = renderItems.filter((o: IShopOrder) => o.customer.id === currentCustomer.id);
    setOrderRecords(customerOrders);
    const customerTotal = Math.round(customerOrders.map((o: IShopOrder) => o.orderValue).reduce((a: number, b: number) => a + b, 0));
    const invoicedTotal = Math.round(customerOrders
      .filter((o: IShopOrder) => o.completed)
      .map((o: IShopOrder) => o.orderValue)
      .reduce((a: number, b: number) => a + b, 0));

    const bodies = customerOrders.filter((o: ISalesOrder) => o.type.match(/body/i));

    const bodyInvoicedCount = bodies.filter((o: IShopOrder) => o.completed).length;
    const bodyValue = Math.round(bodies.map((o: IShopOrder) => o.orderValue).reduce((a: number, b: number) => a + b, 0));
    const bodiesInvoiced = Math.round(bodies.filter((o: IShopOrder) => o.completed).map((o: IShopOrder) => o.orderValue).reduce((a: number, b: number) => a + b, 0));

    const necks = customerOrders.filter((o: ISalesOrder) => o.type.match(/neck/i));
    const neckInvoicedCount = necks.filter((o: IShopOrder) => o.completed).length;
    const neckValue = Math.round(necks.map((o: IShopOrder) => o.orderValue).reduce((a: number, b: number) => a + b, 0));
    const necksInvoiced = Math.round(necks.filter((o: IShopOrder) => o.completed).map((o: IShopOrder) => o.orderValue).reduce((a: number, b: number) => a + b, 0));

    setCustomerOrderDetail({
      customerTotal,
      invoicedTotal,
      bodies,
      bodyInvoicedCount,
      bodyValue,
      bodiesInvoiced,
      necks,
      neckInvoicedCount,
      neckValue,
      necksInvoiced,
    });

    setTimeout(() => {
      const hash = window.location.hash.replace('#', '');
      if (hash.length === 0) return;

      let el = document.getElementById(hash);
      if (!el) {
        el = document.querySelector(`[data-row-key="${hash}"]`);
      }
      if (!el) return;
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 0);
  }, [currentCustomer.id, renderItems]);

  useEffect(() => {
    setShowBodies(showBodies);
    setShowNecks(showNecks);
  }, [showBodies, showNecks]);

  // @ts-ignore
  return (
    <MonthWrapper wrapperColor={theme.palette.schedulePalette[0]}>
      <ScheduleWeekHeaderRow
        id={`${currentCustomer.DisplayName}-schedule-table`}
        key={shortid.generate()}
        addedWidth={0}
        wrapperColor={theme.palette.schedulePalette[0]}
        padding="0 16px 0 0"
      >
        <RouterTableHeaderSectionWrapper justify="flex-start">
          <CustomerMenu key="sales-order-customer-select" showLabel={false} style={{ transparent: true }} />
        </RouterTableHeaderSectionWrapper>
        <RouterTableHeaderSectionWrapper>
          <RouterTableSubHeader>{`${orderRecords.length} ${orderRecords.length === 1 ? 'Order' : 'Total orders'}`}</RouterTableSubHeader>
          <RouterTablePartsPerDay>{`$${numberWithCommas(customerOrderDetail.customerTotal)}`}</RouterTablePartsPerDay>
          <RouterTableHeaderVerticalDivider />
          <RouterTablePartsPerDay style={{ marginLeft: 0 }}>{`$${numberWithCommas(customerOrderDetail.invoicedTotal)}`}</RouterTablePartsPerDay>
          <RouterTableSubHeader>invoiced</RouterTableSubHeader>
        </RouterTableHeaderSectionWrapper>
        <RouterTableHeaderSectionWrapper justify="flex-end">
          <DownloadCustomerOrdersButton customerId={currentCustomer.id} />
        </RouterTableHeaderSectionWrapper>
      </ScheduleWeekHeaderRow>
      <>
        {showBodies && (
        <PartTableWrapper addedWidth={0}>
          <TableHeaderRow wrapperColor={theme.palette.schedulePalette[0]}>
            <RouterTableHeaderWrapper id={`${currentCustomer.DisplayName}-bodies-schedule`} key={shortid.generate()}>
              <RouterTableHeader wrapperColor={theme.palette.schedulePalette[0]}>Bodies</RouterTableHeader>
            </RouterTableHeaderWrapper>
            <RouterTableStatWrapper key={shortid.generate()}>
              <RouterTableSubHeader>{`${customerOrderDetail.bodies.length} ${customerOrderDetail.bodies.length === 1 ? 'order' : 'orders'}`}</RouterTableSubHeader>
              <RouterTablePartsPerDay>{`$${numberWithCommas(customerOrderDetail.bodyValue)}`}</RouterTablePartsPerDay>
              <RouterTableHeaderDivider />
              <RouterTableSubHeader>{`${customerOrderDetail.bodyInvoicedCount} invoiced`}</RouterTableSubHeader>
              <RouterTablePartsPerDay>{`$${numberWithCommas(customerOrderDetail.bodiesInvoiced)}`}</RouterTablePartsPerDay>
            </RouterTableStatWrapper>
          </TableHeaderRow>
          {customerOrderDetail.bodies.length > 0 ? (
            <SplitSalesOrderTable
              rowKey="recordId"
              size="small"
             // @ts-ignore
              columns={salesOrderByCustomerBodyColumns}
              dataSource={customerOrderDetail.bodies}
              pagination={false}
              rowClassName="sales-order-row"
            />
          ) : (
            <NotFound title="No body orders found" message="If you were expecting to see orders here, try adjusting your time range." compact />
          )}
          {!customerOrderDetail.bodies.length && <div style={{ marginBottom: 24 }} />}
        </PartTableWrapper>
        )}
        {showNecks && (
        <PartTableWrapper addedWidth={0}>
          <TableHeaderRow wrapperColor={theme.palette.schedulePalette[0]}>
            <RouterTableHeaderWrapper id={`${currentCustomer.DisplayName}-necks-schedule`} key={shortid.generate()}>
              <RouterTableHeader wrapperColor={theme.palette.schedulePalette[0]}>Necks</RouterTableHeader>
            </RouterTableHeaderWrapper>
            <RouterTableStatWrapper key={shortid.generate()}>
              <RouterTableSubHeader>{`${customerOrderDetail.necks.length} ${customerOrderDetail.necks.length === 1 ? 'order' : 'orders'}`}</RouterTableSubHeader>
              <RouterTablePartsPerDay>{`$${numberWithCommas(customerOrderDetail.neckValue)}`}</RouterTablePartsPerDay>
              <RouterTableHeaderDivider />
              <RouterTableSubHeader>{`${customerOrderDetail.neckInvoicedCount} invoiced`}</RouterTableSubHeader>
              <RouterTablePartsPerDay>{`$${numberWithCommas(customerOrderDetail.necksInvoiced)}`}</RouterTablePartsPerDay>
            </RouterTableStatWrapper>
          </TableHeaderRow>
          {customerOrderDetail.necks.length > 0 ? (
            <SplitSalesOrderTable
              rowKey="recordId"
              size="small"
             // @ts-ignore
              columns={salesOrderByCustomerNeckColumns}
              dataSource={customerOrderDetail.necks}
              pagination={false}
              rowClassName="sales-order-row"
            />
          ) : (
            <NotFound title="No neck orders found" message="If you were expecting to see orders here, try adjusting your time range." compact />
          )}
        </PartTableWrapper>
        )}
      </>
    </MonthWrapper>
  );
};
