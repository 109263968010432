import React from 'react';
import { useRecoilState } from 'recoil';
import { Drawer } from 'antd';
import { packSlipPDFDrawerAtom } from 'shared/state/orderState';
import PackSlipPDF from '../PackSlipPDF';
import { IShipment } from '../../../shared/types/dbRecords';

interface IComponent {
  shipment: IShipment;
}
const PackSlipPDFDrawer = ({ shipment }: IComponent) => {
  const [open, setOpen] = useRecoilState(packSlipPDFDrawerAtom);

  const closeDrawer = (e: any) => {
    setOpen(false);
  };

  return (
    <Drawer
      placement="right"
      width="78vw"
      open={open}
      onClose={closeDrawer}
    >
      <PackSlipPDF shipment={shipment} />
    </Drawer>
  );
};

export default PackSlipPDFDrawer;
