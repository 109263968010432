import React from 'react';
import {
  StyleSheet, View, Text,
} from '@react-pdf/renderer';
import { formatPrice } from '../../../../shared/data';

const style = StyleSheet.create({
  componentWrapper: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  totalLine: {
    // width: 175,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
    marginRight: 4,
  },
  invoiceTotal: {
    width: 100,
  },
  topBorder: {
    borderTop: '2px solid black',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  totalSubLine: {
    width: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 4,
  },
  totalHeaderColumn: {
    position: 'relative',
    right: 8,
  },
  totalCopy: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 9,
  },
  totalDiscountRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  totalDiscountCopy: {
    fontSize: 7,
    position: 'relative',
    bottom: 1,
  },
  totalValue: {
    marginLeft: 'auto',
  },
  total: {
    marginTop: 8,
  },
});

interface IComponent {
  discountShipping: boolean;
  total: number;
  tax: number;
  shipmentCost: number;
}

const InvoiceFooter = ({
  discountShipping, total, tax, shipmentCost,
}: IComponent) => (
  <View style={style.componentWrapper}>
    <View style={style.totalRow}>
      <View style={[style.totalSubLine, style.totalHeaderColumn]}>
        <Text style={style.totalCopy}>Subotal: </Text>
        <Text style={style.totalCopy}>Sales tax: </Text>
        <View style={style.totalDiscountRow}>
          <Text style={style.totalCopy}>Shipping charges</Text>
          {discountShipping && (
            <Text style={[style.totalCopy, style.totalDiscountCopy]}>&nbsp;(discounted 10%):</Text>
          )}
        </View>
        <View style={style.total}>
          <Text style={style.totalCopy}>Total: </Text>
        </View>
      </View>
      <View style={style.totalSubLine}>
        <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(total)}</Text>
        <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(tax)}</Text>
        <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(shipmentCost)}</Text>
        <View style={[style.topBorder, style.total]}>
          <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(total + tax + shipmentCost)}</Text>
        </View>
      </View>
    </View>
    {/* <View style={style.totalSubLine} /> */}
    {/* <View debug style={[style.totalRow, style.topBorder, style.invoiceTotal]}> */}
    {/*  <View style={[style.totalLine, style.totalHeaderColumn]}> */}
    {/*    <Text style={style.totalCopy}>Total: </Text> */}
    {/*  </View> */}
    {/*  <View style={style.totalLine}> */}
    {/*    <Text style={[style.totalCopy, style.totalValue]}>{formatPrice(total + tax + shipmentCost)}</Text> */}
    {/*  </View> */}
    {/* </View> */}
  </View>
);

export default InvoiceFooter;
