import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { useRecoilValue } from 'recoil';
import PartDetailDropdown from 'shared/components/Input/PartDetailDropdown';
import { productCodesAtom } from 'shared/state/utilState';
import { IProductCode } from 'shared/types/jb';

interface IComponent {
  callback: (productCode: string) => void;
  productCode: string;
  disabled: boolean;
}
const InventoryProductCode = ({ disabled, productCode, callback }: IComponent) => {
  // @ts-ignore
  const [_productCode, setProductCode] = useState<string>('');
  const productCodes = useRecoilValue(productCodesAtom);
  // const setEdited = useSetRecoilState(inventoryPartEditedAtom);
  // @ts-ignore
  const productCodeOptions = sortBy(productCodes, (p: IProductCode) => p.productCode).map((p: IProductCode) => (
    { label: p.description, key: p.productCode }
  ));

  const onProductCodeChange = (e: any) => {
    callback(e.split('-')[0]);
  };

  useEffect(() => {
    setProductCode(productCode);
  }, [productCode]);

  return (
    <PartDetailDropdown
      width="200px"
      changeCallback={onProductCodeChange}
      disabled={disabled}
      id="inventory-product-code"
      label="Product Code"
      placeholder="Select code"
      // @ts-ignore
      value={_productCode}
      valueOptions={productCodeOptions}
    />
  );
};

export default InventoryProductCode;
