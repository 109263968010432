type SimilarityResult = {
  index: number;
  similarity: number;
};

export const calculateSimilarity = (str1: string, str2: string): number => {
  const longer = str1.length > str2.length ? str1 : str2;
  const shorter = str1.length > str2.length ? str2 : str1;
  const longerLength = longer.length;
  if (longerLength === 0) return 1.0;
  return (longerLength - editDistance(longer, shorter)) / longerLength;
};

const editDistance = (s1: string, s2: string): number => {
  const str1 = s1.toLowerCase();
  const str2 = s2.toLowerCase();
  const costs: number[] = [];

  for (let i = 0; i <= str1.length; i += 1) {
    let lastValue = i;
    for (let j = 0; j <= str2.length; j += 1) {
      if (i === 0) {
        costs[j] = j;
      } else if (j > 0) {
        let newValue = costs[j - 1];
        if (str1.charAt(i - 1) !== str2.charAt(j - 1)) {
          newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
        }
        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i > 0) {
      costs[str2.length] = lastValue;
    }
  }
  return costs[str2.length];
};

const findSimilarPart = (
  part: string,
  parts: string[],
  startIndex: number,
  similarityThreshold: number,
): SimilarityResult => {
  for (let i = startIndex; i < parts.length; i += 1) {
    const similarity = calculateSimilarity(part, parts[i]);
    if (similarity >= similarityThreshold) {
      return { index: i, similarity };
    }
  }
  return { index: -1, similarity: 0 };
};

// Define the list of patterns to exclude
const excludePatterns = {
  body: [
    /(\*{2})?(LOOSE)?[0-9]{5}(SS)?(EVO)?(EG)?(NoFrets)?(\*{2})?/i,
    /([a-z\s]+)(Frame[s]?|Crown[s]?|Block[s]?|Leaves|Oval[s]?|F)(-[^\/]+)?\/(([0-9\.]{1,3}mm)?[a-z]+)(S)/i,
    /[123]Pc/i,
  ],
  neck: [
    /[a-z]+bind/i,
    /(\*{2})?(LOOSE)?[0-9]{5}(SS)?(EVO)?(EG)?(NoFrets)?(\*{2})?/i,
    /([a-z\s]+)(Frame[s]?|Crown[s]?|Block[s]?|Leaves|Oval[s]?|F)(-[^\/]+)?\/(([0-9\.]{1,3}mm)?[a-z]+)(S)/i,
  ]
};

// Special pattern for BB and GB cases
const gbBbPattern = /^(GB|BB)/;
const gnBnPattern = /^(GN|BN)/;
const materialPattern = /_[a-z]+\/[a-z]+$/i;
// Special pattern for CS and WW material -- remove prefix then test for material pattern to see if there's a match
const csPattern = /^(CS|WW)\s?/;

const removeCsPrefix = (part: string): string => part.replace(csPattern, '');

const preprocessGbBbString = (input: string): string => {
  if (!/^(BB|GB|GN|BN)/.test(input)) return input;

  let parts = input.split('_');
  
  if (/^(GB|BB)/.test(input)) {
    const lastPart = parts[parts.length - 1];
    const weightPattern = /^\d+\s*[Ll]b[s]?\s*\d+$/;
    
    // If weight pattern matches, drop last two segments (weight and wood)
    // Otherwise just drop the last segment (wood)
    parts = weightPattern.test(lastPart) 
      ? parts.slice(0, -2)
      : parts.slice(0, -1);
  }

  // For GN and BN, always remove the last part
  if (/^(GN|BN)/.test(input)) {
    parts = parts.slice(0, -1);
  }

  return parts.join('_');
};

const injectMissingParts = (a: string, b: string, similarityThreshold = 0.8): string => {
  if (!a || !b) return b;
  const partsA = preprocessGbBbString(a).split('_');
  const partsB = b.split('_');

  const result: string[] = [...partsB];

  const getExcludePatterns = (description: string) => {
    if (gbBbPattern.test(description)) {
      return excludePatterns.body;
    }
    if (gnBnPattern.test(description)) {
      return excludePatterns.neck;
    }
    return [...excludePatterns.body, ...excludePatterns.neck];
  };

  const shouldExcludePart = (part: string, patterns: RegExp[]): boolean => {
    // Add a specific check for wood specifications
    const woodSpecPattern = /^([A-Za-z]+\s?(X{0,2}Lite)?\/[A-Za-z]+\/[A-Za-z]+|[A-Za-z]+\/[A-Za-z]+\/[A-Za-z]+)(_[0-9][Ll]b(s)?\s?[0-9]+)?$/;
    if (woodSpecPattern.test(part)) {
      return true;
    }
    return patterns.some((pattern) => pattern.test(part));
  };

  const excludePatternsToUse = getExcludePatterns(b);

  partsA.forEach((partA, indexA) => {
    if (shouldExcludePart(partA, excludePatternsToUse)) {
      return;
    }

    const similarIndex = result.findIndex((partB) => calculateSimilarity(partA, partB) >= similarityThreshold);

    if (similarIndex === -1) {
      // If no similar part found, insert the new part at the appropriate index
      result.splice(indexA, 0, partA);
    }
  });

  return result.join('_');
};

export default injectMissingParts;
