import React from 'react';
import {
  StyleSheet, View, Text, Image,
} from '@react-pdf/renderer';
import { IItemData } from 'pages/Orders/Components/OrderItems/OrderItem';
import { IShopOrder } from '../../../Orders/types';
import { IQATicket } from '../../../../shared/types/dbRecords';
import { dateString } from '../../../../shared/data/calendar';

const style = StyleSheet.create({
  headerWrapper: {
    width: '98%',
    height: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginTop: 2,
  },
  contactColumn: {
    width: 200,
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginLeft: 10,
  },
  contactBlock: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    top: -20,
    left: -24,
  },
  contactInfo: {
    fontFamily: 'Red Hat Display',
    fontSize: 8,
    margin: 0,
  },
  confirmationDetailColumn: {
    flexDirection: 'column',
    width: 'auto',
    height: 'auto',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    textAlign: 'right',
    paddingTop: 12,
  },
  pageTitle: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 20,
  },
  orderDescription: {
    fontFamily: 'Red Hat Display',
    fontSize: 9,
    marginTop: -2,
  },
  orderDetailRow: {
    width: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'right',
    marginTop: 6,
  },
  orderDetailTypeColumn: {
    width: '60%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 6,
  },
  orderDetailValueColumn: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  orderDetailType: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 10,
    margin: 0,
  },
  orderDetailValue: {
    fontFamily: 'Red Hat Display',
    fontSize: 10,
    margin: 0,
  },
});

interface IComponent {
  qaTicket: IQATicket;
}

const NCHeader = ({ qaTicket }: IComponent) => (
  <View fixed style={style.headerWrapper}>
    <View style={style.contactColumn}>
      <Image src="/images/horizontal_lockup.jpg" width={188} height={72} />
    </View>
    <View style={style.confirmationDetailColumn}>
      <Text style={style.pageTitle}>Non-conformance</Text>
      <Text style={style.orderDescription}>{qaTicket.title}</Text>
      <View style={style.orderDetailRow}>
        <View style={style.orderDetailTypeColumn}>
          <Text style={style.orderDetailType}>Report Number:</Text>
          <Text style={style.orderDetailType}>Date:</Text>
        </View>
        <View style={style.orderDetailValueColumn}>
          <Text style={style.orderDetailValue}>{qaTicket.id}</Text>
          <Text style={style.orderDetailValue}>{dateString(qaTicket.createdDate.toDate())}</Text>
        </View>
      </View>
    </View>
  </View>
);

export default NCHeader;
