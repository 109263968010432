import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { find } from 'lodash';
import styled from 'styled-components';
import {
  currentCustomerContactsAtom, CUSTOMER_CONTACT_DB_COLLECTION,
  selectedCustomerContactAtom,
} from 'shared/state/customerState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { IContact } from 'shared/types/jb';
import ContactSearchSelector from 'pages/Customer/Components/Contacts/ContactDetailFields/ContactSelector';
import qs from 'qs';
import EmailContactButton from './Buttons/EmailContactButton';
import NotFound from '../../../../shared/components/Utility/NotFound';
import useFirebase from '../../../../vendor/Firebase';
import ContactDefaultSwitch from './ContactDetailFields/ContactDefaultSwitch';
import NewContactButton from './Buttons/NewContactButton';
import SaveContactButton from './Buttons/SaveContactButton';
import DeleteContactButton from './Buttons/DeleteContactButton';
import DiscardContactButton from './Buttons/DiscardContactButton';
import { urlQueryString } from '../../../../shared/util';
import DetailInputWithCallback from '../../../../shared/components/Input/DetailInputWithCallback';

const ComponentWrapper = styled(FlexColumn)`
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
`;

const ContactSelectorRow = styled(FlexRow)`
  width: calc(100% - 12px);
  gap: 24px;
  align-items: flex-end;
  justify-content: flex-start;
`;

const DetailRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 32px;
`;
const EmailRow = styled(FlexRow)`
  width: 50%;
  justify-content: flex-start;
  gap: 12px;
`;
const SearchDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: lightgrey;
  margin: 16px 0;
`;
const ContactDetail = () => {
  const { firestore } = useFirebase();
  const customerContactDbString = useRecoilValue(CUSTOMER_CONTACT_DB_COLLECTION);
  const [currentCustomerContacts, setCurrentCustomerContacts] = useRecoilState(currentCustomerContactsAtom);
  const [selectedContact, setSelectedContact] = useRecoilState(selectedCustomerContactAtom);
  const [edited, setEdited] = useState<boolean>(false);

  // @ts-ignore
  const { customer } = qs.parse(urlQueryString(window.location.href)) as string;

  useEffect(() => {
    // @ts-ignore
    firestore.collection(customerContactDbString).doc(customer).get().then((doc) => {
      const contacts = doc?.data()?.contacts as IContact[];
      if (!contacts) {
        const defaultContact = {
          active: true,
          cellPhone: '',
          comments: '',
          contact: '',
          contactCode: '',
          default: true,
          email: '',
          extension: '',
          lastModDate: '',
          mobileEmail: '',
          phone: '',
          title: '',
          uniqueID: '',
        };
        setCurrentCustomerContacts([defaultContact]);
        setSelectedContact(defaultContact);
      } else {
        setCurrentCustomerContacts(contacts);
        setSelectedContact(find(contacts, (c: IContact) => c.default) as IContact);
      }
    });
    return () => { };
  }, []);

  const onChangeName = (value: string) => {
    setSelectedContact({ ...selectedContact, contact: value });
    setEdited(true);
  };

  const onChangeTitle = (value: string) => {
    setSelectedContact({ ...selectedContact, title: value });
    setEdited(true);
  };

  const onChangeEmail = (value: string) => {
    setSelectedContact({ ...selectedContact, email: value });
    setEdited(true);
  };

  const onChangePhone = (value: string) => {
    setSelectedContact({ ...selectedContact, phone: value });
    setEdited(true);
  };

  const onChangeCellPhone = (value: string) => {
    setSelectedContact({ ...selectedContact, cellPhone: value });
    setEdited(true);
  };

  const onFinishSave = (newContacts: IContact[]) => {
    setCurrentCustomerContacts(newContacts);
    setEdited(false);
  };

  return (
    <>
      {currentCustomerContacts.length ? (
        <ComponentWrapper>
          <ContactSelectorRow>
            <ContactSearchSelector />
            {edited ? (
              <>
                <SaveContactButton callback={onFinishSave} />
                {find(currentCustomerContacts, (c) => c.uniqueID === selectedContact.uniqueID) ? (
                  <DeleteContactButton />
                ) : (
                  <DiscardContactButton />
                )}
              </>
            ) : (
              <>
                <NewContactButton />
                <DeleteContactButton />
              </>
            )}
          </ContactSelectorRow>
          <SearchDivider />
          <DetailRow>
            <DetailInputWithCallback
              id="customer-contact-name-input"
              width="380px"
              label="Name"
              placeholder=""
              value={selectedContact.contact}
              callback={onChangeName}
              extend={false}
              isLabel={false}
            />
            {/* <ContactName /> */}
            <ContactDefaultSwitch />
          </DetailRow>
          <DetailInputWithCallback
            id="customer-contact-title-input"
            width="380px"
            label="Title"
            placeholder="e.g., Owner"
            value={selectedContact.title}
            callback={onChangeTitle}
            extend={false}
            isLabel={false}
          />
          {/* <ContactTitle /> */}
          <EmailRow>
            {/* <ContactEmail /> */}
            <DetailInputWithCallback
              id="customer-contact-email-input"
              width="380px"
              label="Email"
              placeholder=""
              value={selectedContact.email}
              callback={onChangeEmail}
              extend={false}
              isLabel={false}
            />
            {selectedContact && (
            <EmailContactButton />
            )}
          </EmailRow>
          <DetailRow>
            <DetailInputWithCallback
              id="customer-contact-phone-input"
              width="380px"
              label="Phone"
              placeholder=""
              value={selectedContact.phone}
              callback={onChangePhone}
              extend={false}
              isLabel={false}
            />
            <DetailInputWithCallback
              id="customer-contact-cell-phone-input"
              width="380px"
              label="Mobile Phone"
              placeholder=""
              value={selectedContact.cellPhone}
              callback={onChangeCellPhone}
              extend={false}
              isLabel={false}
            />
          </DetailRow>
        </ComponentWrapper>
      ) : (
        <NotFound compact title="No contacts found" key="customers-not-found-placeholder" />
      )}
    </>
  );
};

export default ContactDetail;
