import React from 'react';
import styled from 'styled-components';
import { IMessage } from 'shared/types/dbRecords';
import theme from 'shared/theme';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { dateDistance, isSameDay, localTime } from 'shared/data/calendar';
import MarkEmailUnreadButton from './Buttons/MarkUnreadButton';
import ArchiveMessageButton from './Buttons/ArchiveMessageButton';
import JumpButton from './Buttons/JumpButton';

const ComponentWrapper = styled(FlexColumn)`
  width: 80%;
  background-color: ${(props: any) => (props.read ? 'white' : theme.palette.primary[100])};
  justify-content: flex-start;
  padding: 0 32px;
  gap: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

const DateRow = styled(FlexRow)`
  width: 100%;
  padding: 4px 20px 0 0;
  justify-content: flex-end;
`;

const DateText = styled.p`
  font-size: 16px;
  color: ${theme.palette.neutral[600]};
`;

const DataRow = styled(FlexRow)`
  width: 100%;
  position: relative;
  bottom: 12px;
  justify-content: space-between;
`;

const MessageColumn = styled(FlexColumn)`
  align-items: flex-start;
  width: 80%;
`;

const SubjectText = styled.h2`
  font-size: 22px;
`;

const MessageText = styled.p`
  font-size: 16px;
`;

const ActionColumn = styled(FlexColumn)`
  width: 8%;
  padding-left: 12px;
  border-left: 2px solid lightgrey;
  gap: 8px;
`;
const MessageCenterItem = ({
  archived, id, sent, subject, message, personal, clickUrl = undefined,
}: IMessage) => {
  const sentDate = new Date(sent);
  let daySent = sentDate.toLocaleDateString();
  const daysAgo = dateDistance(sent, new Date());
  if (daysAgo === 1) daySent = 'Yesterday';
  if (daysAgo === 0) daySent = 'Today';

  return (
    <ComponentWrapper read={archived}>
      <DateRow>
        <DateText>{`${daySent}, ${localTime(sentDate)}`}</DateText>
      </DateRow>
      <DataRow>
        <MessageColumn>
          <SubjectText>{subject}</SubjectText>
          <MessageText>{message}</MessageText>
        </MessageColumn>
        <ActionColumn>
          <MarkEmailUnreadButton id={id} isRead={archived} personal={personal} />
          {/* {!archived && ( */}
          {/*  <ArchiveMessageButton id={id} /> */}
          {/* )} */}
          {clickUrl && (
            <JumpButton id={id} url={clickUrl} />
          )}
        </ActionColumn>
      </DataRow>
    </ComponentWrapper>
  );
};

export default MessageCenterItem;
