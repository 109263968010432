import React, { useEffect, useState } from 'react';
import { Button, Upload } from 'antd';
import { sortBy, groupBy, uniq } from 'lodash';
import { RcFile } from 'antd/lib/upload';
import { Upload as UploadIcon } from '@styled-icons/fa-solid';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import firebase from 'firebase';
import { FlexColumn } from '../../../shared/containers/FlexContainer';

const UploadButton = styled(Button)`
  //margin: 48px;
  min-width: 240px;
  border-radius: 8px;
`;

interface InProcessRecord {
  order: string;
  inProcess: boolean;
}

const SalesDataUploader = () => {
  const { firestore } = useFirebase();

  const onUpload = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result
        .split('\r')
        .slice(1)
        .map((d: string) => d.trim().split(','))
        .filter((d: string[]) => d[0].match(/^[A-Z]{5}_/));

      const partGroups = groupBy(data, (d) => d[0]);

      Object.keys(partGroups).forEach((sku) => {
        const sales = sortBy(partGroups[sku].map((d: string[]) => d[d.length - 1])
          .filter((s: string) => s.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/))
          .map((s: string) => new Date(s)), (d: Date) => d);
        const recentSale = sales[sales.length - 1] ? firebase.firestore.Timestamp.fromDate(sales[sales.length - 1]) : null;
        firestore.collection('part-viewer-data').doc(sku).get().then((doc) => {
          if (doc.exists) {
            firestore.collection('part-viewer-data').doc(sku).update({ lastSold: recentSale }).then(() => {
              console.log(sku);
            });
          }
        });
      });
    };

    reader.readAsText(file);

    return false;
  };

  return (
    <FlexColumn align="center" style={{ gap: 8 }}>
      <Upload
        accept=".csv"
        showUploadList={false}
        beforeUpload={onUpload}
      >
        <UploadButton
          type="default"
          icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}
        >
          Upload Part Sales Data
        </UploadButton>
      </Upload>
    </FlexColumn>
  );
};

export default SalesDataUploader;
