import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { find, sortBy } from 'lodash';
import { IRouterStep } from 'pages/ProductionSchedule/types';
import { routerStepsAtom } from 'shared/state/routingState';
import HelpButton from 'shared/components/Utility/HelpButton';
import DetailDropdownWithCallback from 'shared/components/Input/DetailDropdownWithCallback';
import { IActionOption } from 'shared/types';

interface IComponent {
  workOrderStep: string;
  callback: (newValue: string) => void;
  value: string;
}
const QATicketDepartmentDropdown = ({ workOrderStep, callback, value }: IComponent) => {
  const [selectedItem, setSelectedItem] = useState<string>(value);
  const routerSteps = useRecoilValue(routerStepsAtom);
  const [optionsList, setOptionsList] = useState<IActionOption[]>([]);

  const onChangeDepartment = (newDepartment: string) => {
    callback(newDepartment);
  };

  useEffect(() => {
    const step = find(routerSteps, (r: IRouterStep) => r.id === workOrderStep);
    if (step) {
      setSelectedItem(step.department);
    }
    const _optionsList = sortBy(routerSteps.map((r: IRouterStep) => ({
      value: r.department,
      label: r.department,
    })), (o: IActionOption) => o.value);
    setOptionsList(_optionsList.filter((o, index) => {
      const firstIndex = _optionsList.findIndex((e) => e.value === o.value);
      return firstIndex === index;
    }));
  }, [workOrderStep]);

  useEffect(() => {
    setSelectedItem(value);
  }, [value]);

  return (
    <DetailDropdownWithCallback
      id="qa-ticket-department-list"
      width="248px"
      listPrompt="Select Department"
      label="Department"
      optionsList={optionsList}
      selectedOption={selectedItem}
      LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="Select the department in which the non-conformance occurred" />}
      changeCallback={onChangeDepartment}
    />
  );
};

export default QATicketDepartmentDropdown;
