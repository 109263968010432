import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  adminUserEmailsAtom,
  customerUserEmailsAtom,
  scannerUserEmailsAtom,
  shopUserEmailsAtom, superAdminUserEmailAtom,
} from 'shared/state/routingState';
import useDependencies from 'hooks/useDependencies';
import { FlexColumn } from 'shared/containers/FlexContainer';
import Loader from 'shared/components/Utility/Loader';
import { AuthContext } from '../../../vendor/Firebase/AuthProvider';

const shortid = require('shortid');
const _ = require('lodash');

interface IComponent {
  exact: boolean;
  path: string;
  deps: string[];
  children: React.ReactNode;
  whitelist: string[];
}

export default ({
  exact, path, deps, children, whitelist,
}: IComponent) => {
  const depsReady = useDependencies(deps);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const shopUserEmails = useRecoilValue(shopUserEmailsAtom);
  const scannerStationEmails = useRecoilValue(scannerUserEmailsAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const { currentUser } = useContext(AuthContext);

  const redirectUrlFor = (userEmail) => {
    if (_.includes([...superAdminEmails.emails, ...adminEmails.emails], userEmail)) return '/';
    if (_.includes(shopUserEmails.emails, userEmail)) return '/';
    if (_.includes(scannerStationEmails.emails, userEmail)) return '/scanner';
    if (_.includes(customerEmails.emails, userEmail)) return '/part-finder';
    return '/login';
  };

  return (
    <>
      {currentUser && _.includes(whitelist, currentUser.email) ? (
        <>
          {!depsReady() ? (
            <FlexColumn key={shortid.generate()} style={{ width: '100%', height: '50vw' }}>
              <Loader />
            </FlexColumn>
          ) : (
            <Route
              key={shortid.generate()}
              exact={exact}
              path={path}
              render={(routeProps) => children}
            />
          )}
        </>
      ) : (
        <Redirect to={redirectUrlFor(currentUser?.email)} />
      )}
    </>
  );
};
