import { INCRecord } from '../types/dbRecords';

const generateImageBlocks = (ncRecord: INCRecord, imageDataItems: any[] = []): any[] => {
  if (!imageDataItems.length) return [];
  // console.log(imageDataItems);
  return imageDataItems.map((imageDataItem, index) => ({
    type: 'image',
    title: {
      type: 'plain_text',
      text: `${ncRecord.customerId} order ${ncRecord.salesOrder.orderNumber} - Image ${index + 1}`,
    },
    block_id: `${ncRecord.salesOrder.orderNumber}-image-${index + 1}`,
    image_url: imageDataItem,
    alt_text: `${ncRecord.customerId} order ${ncRecord.salesOrder.orderNumber} - Image ${index + 1}`,
  }));
};

export default (ncRecord: INCRecord, imageDataItems: any[] = []) => {
  const blockData: any[] = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: `${ncRecord.shopOperator} reported a new non-conformance`,
        emoji: true,
      },
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Department:*\n${ncRecord.department}`,
        },
        {
          type: 'mrkdwn',
          text: `*Reported:*\n${ncRecord.shopOperator}`,
        },
      ],
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Customer:*\n${ncRecord.customerId}`,
        },
        {
          type: 'mrkdwn',
          text: `*Order #:*\n${ncRecord.salesOrder.orderNumber}`,
        },
      ],
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Part:*\n${ncRecord.partDescription}`,
        },
      ],
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Issue:*\n${ncRecord.reasonCode}`,
        },
        {
          type: 'mrkdwn',
          text: `*Action:*\n${ncRecord.correctiveAction}`,
        },
      ],
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Notes:*\n${ncRecord.notes}`,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `<https://admin.wildwoodmfg.com/nc/report#${ncRecord.id}|View Record>`,
      },
    },
  ];
  const imageBlocks = imageDataItems.length ? generateImageBlocks(ncRecord, imageDataItems) : [];
  return {
    blocks: [...blockData, ...imageBlocks],
  };
};
