import React from 'react';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';
import { LocalShipping } from '@styled-icons/material-twotone';
import theme from 'shared/theme';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { stageRedirect } from 'shared/util';

const shortid = require('shortid');

const ShipIcon = styled(LocalShipping)`
  color: ${theme.palette.primary.hue};
  width: 24px;
`;

const ShipButton = styled(Button)`
  border: none; 
  opacity: ${(props: { disabled: boolean }) => (props.disabled ? 0.3 : 1)};
  &:hover {
    background-color: ${theme.palette.primary.hue};
    & svg {
      color: ${theme.palette.neutral.white};
    }
  }
`;

interface IComponentInterface {
  shopOrder: any;
  disabled?: boolean;
}

const CompleteOrderButton = ({ shopOrder, disabled = false }: IComponentInterface) => {
  const onShipOrder = (e: any) => {
    stageRedirect(`/schedule/week#${shopOrder.id}`);
    window.location.href = `/shipments/create?orderId=${shopOrder.id}`;
  };

  return (
    <ScopedComponent whitelist={['keithh@wildwoodmfg.com', 'bruceh@wildwoodmfg.com', 'cathyh@wildwoodmfg.com']}>
      {disabled ? (
        <ShipButton
          key={shortid.generate()}
          shape="circle"
          onClick={onShipOrder}
          disabled={disabled}
        >
          <ShipIcon />
        </ShipButton>
      ) : (
        <Tooltip placement="top" title="Complete order">
          <ShipButton
            key={shortid.generate()}
            shape="circle"
            onClick={onShipOrder}
          >
            <ShipIcon />
          </ShipButton>
        </Tooltip>
      )}
    </ScopedComponent>
  );
};

export default CompleteOrderButton;
