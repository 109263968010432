import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import PartDetailInput from 'shared/components/Input/PartDetailInput';
import { formatPrice } from '../../../../shared/data';
import { currentInventoryPartAtom, inventoryPartEditedAtom } from '../../../../shared/state/inventoryState';

const InventoryOnOrder = () => {
  // @ts-ignore
  const [partState, setPartState] = useRecoilState(currentInventoryPartAtom);
  const setEdited = useSetRecoilState(inventoryPartEditedAtom);

  const onChange = (e: any) => {
    e.preventDefault();
    setEdited(true);
    const onOrder = e.target.value;
    // @ts-ignore
    setPartState({ ...partState, quantityOnOrder: onOrder });
  };

  return (
    <PartDetailInput
      changeCallback={onChange}
      disabled
      id="inventory-on-order"
      label="On Order"
      placeholder="0"
      // @ts-ignore
      value={partState?.quantityOnOrder}
    />
  );
};

export default InventoryOnOrder;
