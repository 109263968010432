import styled from 'styled-components';
import theme from '../theme';
import { Body, H3, H4 } from '../typography';
import { ItemDetailsLabel } from '../../pages/PartDetail/Components/PartDetailFields/styledComponents';

// eslint-disable-next-line import/prefer-default-export
export const PartDetailColumnHeader = styled.h2`
  width: 100%;
  padding-left: 8px;
  background-color: rgba(82, 81, 102, 0.7);
  //background-color: ${theme.palette.primary.hue};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: white;
  margin: ${(props: { marginTop: boolean }) => `${props.marginTop || 0}px 0 8px 0`};
  border-bottom: 4px solid rgba(52, 51, 52, 0.7);
  // border-bottom: 4px solid ${theme.palette.primary.D100};
`;
export const TotalPrice = styled(H4)`
  color: ${theme.palette.neutral.white};
  margin: 0 0 0 8px;
`;
export const PriceDiscount = styled(Body)`
  color: ${theme.palette.neutral.white};
  margin: 0 0 0 8px;
`;

export const CellCopy = styled(Body)`
    font-size: 14px;
    font-family: 'Red Hat Display', 'Helvetica Neue', sans-serif !important;
    margin: 0;
    
    font-weight: 400;
    
    @media ${theme.device.laptopL} {
        font-size: ${theme.typography.body.size.small};
    }
    
    & code {
      font-size: 10px; 
      
      @media print {
        font-size: 12px;
      }
    }
  
  @media print {
    font-size: 10px;
    padding: 4px 0;
    
    & code {
      font-size: 12px;
    }
  }
`;

export const ParentCellCopy = styled(CellCopy)`
    font-weight: bold;
    color: ${theme.palette.primary.hue};
`;

export const PageTitle = styled(H3)`
    margin: 0;
  @media ${theme.device.tablet} {
    font-size: 24px;
  }
  @media ${theme.device.laptop} {
    font-size: ${theme.typography.h3.size.medium};
  }
`;

export const PartViewerFilterLabel = styled(ItemDetailsLabel)`
    display: none;
    @media ${theme.device.laptopL} {
        display: unset;
    }
`;
