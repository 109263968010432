import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { ConfigurationRow } from 'shared/pageElements/styledComponents';
import { Button, Input, PageHeader } from 'antd';
import { rgba } from 'shared/palette/utils';
import { Caption } from 'shared/typography';

export const PriceFieldWrapper = styled(FlexRow)`
    width: 35%;
    justify-content: flex-end;
    align-items: center;
    gap: ${theme.spacing(1)};
    
    & p {
        margin: 0;
    }
`;

export const RateOptionWrapper = styled(ConfigurationRow)`
    margin-left: 0;
`;
export const RateOptionNameWrapper = styled(FlexRow)`
    width: 65%;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    
    margin-left: 0;
    
    & p {
        font-size: 16px;
        margin: 0;
    }
`;

export const EditRecordButton = styled(Button)`
    margin-left: ${theme.spacing(3)};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.success[900])} !important;
    border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.neutral.white)};
    border-radius: ${theme.spacing(1)};
    border: none;
    color: ${theme.palette.neutral.white};
    
    &:hover {
        color: ${theme.palette.neutral.white};
        background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.success.D100)} !important;
        border-color: transparent;
    }
`;

export const NewItemCancel = styled(Button)`
    border-radius: 50%;
    border: none;
    margin-left: ${theme.spacing(1.5)};
    color: ${theme.palette.neutral.white};
    box-shadow: unset;
    
    &:first-of-type {
      margin-left: 0;
    }
    &:hover {
    }
`;

export const NewItemHeader = styled(ConfigurationRow)`
    width: calc(100% - 32px);
    height: 72px;
    flex-shrink: 0;
  
    background-color: ${(props: any) => (props.testMode ? '#FF0000' : 'rgba(82, 81, 102, 0.7)')};
    
    position: sticky;
    top: 0;
    z-index: 1000;
    
    box-shadow: 0 1px 4px ${rgba(theme.palette.neutral.black, 0.12)};
    
    margin-left: 32px;
    padding-left: ${theme.spacing(3.5)};
`;

export const NewItemHeaderLabel = styled(PageHeader)`
    & span {
        color: ${theme.palette.neutral.white};
    }
`;

export const NewItemBackButton = styled(Button)`
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : '#2f9bff')} !important;
    color: ${theme.palette.neutral.white};
    // border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.primary[900])};
    // border: 1px solid ${theme.palette.neutral.white};
    // box-shadow: 1px 1px 4px solid ${theme.palette.primary.D200};
    
    &:hover {
      color: ${theme.palette.neutral.white};
      background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.primary.D100)} !important;
    }
`;
