import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import shortid from 'shortid';
import { flatten, max, uniq } from 'lodash';
import {
  salesOrderCopyItemAtom,
  salesOrdersAtom, showBodiesAtom, showNecksAtom,
} from 'shared/state/routingState';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { getWeek, workDatesBetween } from 'shared/data/calendar';
import {
  RouterTableHeaderWrapper,
  RouterTableHeader,
  WeekPrintWrapper,
  ComingSoonTable,
  RouterTableSubHeader,
  RouterStatHeaderWrapper,
  RouterTableHeaderSectionWrapper,
  RouterWeekHeader,
  MonthWrapper,
  RouterTableHeaderDivider,
  RouterTableStatWrapper,
  RouterTablePartValue,
  TableSectionHeaderBlankSpace,
  TableHeaderRow, ComingSoonHeaderRow, PartTableWrapper, RouterWeekSummaryWrapper, TableHorizontalDivider,
} from './styledComponents';
import {
  salesOrderBodyColumns, salesOrderNeckColumns,
} from './Components/DataColumns';
import Loader from '../../shared/components/Utility/Loader';
import { IShopOrder } from '../Orders/types';
import { numberText, numberWithCommas } from '../../shared/text';
import theme from '../../shared/theme';
import { tint } from '../../shared/palette/utils';

interface IComponent {
  items: IShopOrder[];
  showBodies: boolean;
  showNecks: boolean;
}
export default ({ items, showBodies, showNecks }: IComponent) => {
  const salesOrderCopyItem = useRecoilValue(salesOrderCopyItemAtom);
  const [_showBodies, setShowBodies] = useState(showBodies);
  const [_showNecks, setShowNecks] = useState(showNecks);
  const [firstLoad, setFirstLoad] = useState(true);
  const [orderRecords, setOrderRecords] = useState<IShopOrder[]>([]);
  const [bodyRecords, setBodyRecords] = useState<IShopOrder[][]>([]);
  const [bodyTotalCount, setBodyTotalCount] = useState<number>(0);
  const [bodyTotalValue, setBodyTotalValue] = useState<number>(0);
  const [neckRecords, setNeckRecords] = useState<IShopOrder[][]>([]);
  const [neckTotalCount, setNeckTotalCount] = useState<number>(0);
  const [neckTotalValue, setNeckTotalValue] = useState<number>(0);

  const setRowClass = (record: any) => {
    if (!salesOrderCopyItem.id) return 'sales-order-row';
    return record.id === salesOrderCopyItem.id ? 'sales-order-row sales-order-row-clicked' : 'sales-order-row';
  };

  const WEEKS = [
    'Overdue',
    'This Week',
    'Next Week',
    'Coming Soon',
  ];

  const ordersByWeek = (partType: string, week: Date[], orders: IShopOrder[]) => orders.filter((o: IShopOrder) => {
    const _week = week.map((w: Date) => new Date(w));

    _week[_week.length - 1].setDate(_week[_week.length - 1].getDate() + 1);
    return o.type === partType
          && o.releaseDate >= _week[0]
          && o.releaseDate <= _week[_week.length - 1];
  });

  useEffect(() => {
    setShowBodies(showBodies);
    setShowNecks(showNecks);
  }, [showBodies, showNecks]);

  useEffect(() => {
    if (items.length) {
      const today = new Date();
      const currentWeek = getWeek(today);

      const oneWeekOut = new Date(today.setDate(today.getDate() + 7));
      const nextWeek = getWeek(oneWeekOut);

      const twoWeeksOut = new Date(today.setDate(today.getDate() + 7));
      const twoWeeks = getWeek(twoWeeksOut);

      const overdueOrders = items.filter((o: IShopOrder) => !o.runners?.filter((r) => r).length && o.releaseDate < currentWeek[0]);

      const comingSoonOrders = items.filter((o: IShopOrder) =>
        !o.runners?.filter((r) => r).length
          && o.releaseDate >= currentWeek[0]
          && o.releaseDate <= twoWeeks[twoWeeks.length - 1]);

      const _bodyRecords = [
        overdueOrders.filter((o: IShopOrder) => o.type.match(/body/i)),
        ordersByWeek('body', currentWeek, comingSoonOrders),
        ordersByWeek('body', nextWeek, comingSoonOrders),
        ordersByWeek('body', twoWeeks, comingSoonOrders),
      ];
      setBodyRecords(_bodyRecords);
      setBodyTotalCount(flatten(_bodyRecords).map((o: IShopOrder) => o.partCount).reduce((a, b) => a + b, 0));
      setBodyTotalValue(flatten(_bodyRecords).map((o: IShopOrder) => o.orderValue).reduce((a, b) => a + b, 0));

      const _neckRecords = [
        overdueOrders.filter((o: IShopOrder) => o.type.match(/neck/i)),
        ordersByWeek('neck', currentWeek, comingSoonOrders),
        ordersByWeek('neck', nextWeek, comingSoonOrders),
        ordersByWeek('neck', twoWeeks, comingSoonOrders),
      ];
      setNeckRecords(_neckRecords);
      setNeckTotalCount(flatten(_neckRecords).map((o: IShopOrder) => o.partCount).reduce((a, b) => a + b, 0));
      setNeckTotalValue(flatten(_neckRecords).map((o: IShopOrder) => o.orderValue).reduce((a, b) => a + b, 0));
    } else {
      setOrderRecords(items);
    }
    setFirstLoad(false);
  }, [items]);

  // @ts-ignore
  return (
    <MonthWrapper wrapperColor={theme.palette.schedulePalette[0]}>
      {(bodyRecords.length === 0 || neckRecords.length === 0) && firstLoad ? (
        <FlexColumn style={{ width: '100%', height: '50vw' }}>
          <Loader />
        </FlexColumn>
      ) : (
        <div key="sales-order-outer-wrapper" style={{ width: '100%' }}>
          <div key={`sales-order-inner-wrapper-${shortid.generate()}`}>
            <WeekPrintWrapper key="coming-soon">
              {showBodies && (
              <>
                <ComingSoonHeaderRow id="coming-soon-bodies" key={shortid.generate()} wrapperColor={theme.palette.schedulePalette[0]} addedWidth={32}>
                  <RouterTableHeaderSectionWrapper justify="flex-start">
                    <RouterWeekHeader>Bodies</RouterWeekHeader>
                  </RouterTableHeaderSectionWrapper>
                </ComingSoonHeaderRow>
                {bodyRecords.map((week: IShopOrder[], index: number) => {
                  if (week.length === 0) return <></>;

                  const bodyCount = week.map((o: IShopOrder) => o.partCount).reduce((a, b) => a + b, 0);
                  const weekDays = uniq(week.map((o: IShopOrder) => o.releaseDate));
                  const datesBetween = workDatesBetween(new Date(), max(weekDays)).length;
                  const wrapperColor = theme.palette.schedulePalette[0];
                  const section = shortid.generate();

                  const groupWeek = WEEKS[index];
                  return (
                    <PartTableWrapper addedWidth={32}>
                      <TableHeaderRow wrapperColor={tint(wrapperColor, (0.08 * (bodyRecords.length - index)))}>
                        <RouterTableHeaderWrapper id={`${section}-bodies-header`} key={shortid.generate()} width="30%">
                          <RouterTableHeader wrapperColor={wrapperColor}>{groupWeek}</RouterTableHeader>
                        </RouterTableHeaderWrapper>
                        <RouterStatHeaderWrapper key={shortid.generate()} width="70%">
                          <RouterTableStatWrapper>
                            <RouterTableSubHeader>{`${bodyCount} scheduled to release in the next ${numberText(datesBetween + 1)} working day${datesBetween === 0 ? '' : 's'}`}</RouterTableSubHeader>
                          </RouterTableStatWrapper>
                        </RouterStatHeaderWrapper>
                        <TableSectionHeaderBlankSpace width="20%" />
                      </TableHeaderRow>
                      <ComingSoonTable
                        key="coming-soon-body-table"
                        rowKey="recordId"
                        size="small"
                                  // @ts-ignore
                        columns={salesOrderBodyColumns}
                        dataSource={week}
                        pagination={false}
                        rowClassName={setRowClass}
                      />
                    </PartTableWrapper>
                  );
                })}
                <RouterWeekSummaryWrapper key={shortid.generate()} wrapperColor={theme.palette.schedulePalette[0]}>
                  <RouterTableHeader>Total</RouterTableHeader>
                  <RouterTableHeaderDivider />
                  <RouterTableStatWrapper>
                    <RouterTableSubHeader>{`$${numberWithCommas(Math.round(bodyTotalValue))}`}</RouterTableSubHeader>
                    <RouterTableHeaderDivider />
                    <RouterTableSubHeader>{`${bodyTotalCount} bodies coming up`}</RouterTableSubHeader>
                  </RouterTableStatWrapper>
                </RouterWeekSummaryWrapper>
              </>
              )}
            </WeekPrintWrapper>
            { (showBodies && showNecks) && (
              <TableHorizontalDivider />
            )}
            <WeekPrintWrapper>
              {showNecks && (
              <>
                <ComingSoonHeaderRow id="coming-soon-necks" key={shortid.generate()} wrapperColor={theme.palette.schedulePalette[1]} addedWidth={32}>
                  <RouterTableHeaderSectionWrapper justify="flex-start">
                    <RouterWeekHeader>Necks</RouterWeekHeader>
                  </RouterTableHeaderSectionWrapper>
                </ComingSoonHeaderRow>
                {neckRecords.map((week: IShopOrder[], index: number) => {
                  if (week.length === 0) return <></>;

                  const groupWeek = WEEKS[index];
                  const neckCount = week.map((o: IShopOrder) => o.partCount).reduce((a, b) => a + b, 0);
                  const weekDays = uniq(week.map((o: IShopOrder) => o.releaseDate));
                  const datesBetween = workDatesBetween(new Date(), max(weekDays)).length;
                  const wrapperColor = theme.palette.schedulePalette[1];
                  const section = shortid.generate();

                  return (
                    <PartTableWrapper addedWidth={32}>
                      <TableHeaderRow wrapperColor={tint(wrapperColor, (0.08 * (bodyRecords.length - index)))}>
                        <RouterTableHeaderWrapper id={`${section}-necks-header`} key={shortid.generate()} width="30%">
                          <RouterTableHeader wrapperColor={wrapperColor}>{groupWeek}</RouterTableHeader>
                        </RouterTableHeaderWrapper>
                        <RouterStatHeaderWrapper key={shortid.generate()} width="60%">
                          <RouterTableStatWrapper>
                            <RouterTableSubHeader>{`${neckCount} scheduled to release in the next ${numberText(datesBetween + 1)} working day${datesBetween === 0 ? '' : 's'}`}</RouterTableSubHeader>
                          </RouterTableStatWrapper>
                        </RouterStatHeaderWrapper>
                        <TableSectionHeaderBlankSpace width="20%" />
                      </TableHeaderRow>
                      <ComingSoonTable
                        key="coming-soon-body-table"
                        rowKey="recordId"
                        size="small"
                        columns={salesOrderNeckColumns}
                        dataSource={week}
                        pagination={false}
                        rowClassName={setRowClass}
                      />
                    </PartTableWrapper>
                  );
                })}
                <RouterWeekSummaryWrapper key={shortid.generate()} wrapperColor={theme.palette.schedulePalette[1]}>
                  <RouterTableHeader>Total</RouterTableHeader>
                  <RouterTableHeaderDivider />
                  <RouterTableStatWrapper>
                    <RouterTableSubHeader>{`$${numberWithCommas(Math.round(neckTotalValue))}`}</RouterTableSubHeader>
                    <RouterTableHeaderDivider />
                    <RouterTableSubHeader>{`${neckTotalCount} necks coming up`}</RouterTableSubHeader>
                  </RouterTableStatWrapper>
                </RouterWeekSummaryWrapper>
              </>
              )}
            </WeekPrintWrapper>
          </div>
        </div>
      )}
    </MonthWrapper>
  );
};
