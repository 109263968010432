import styled from 'styled-components';
import { FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';

export const ItemDetailsColumnWrapper = styled(FlexColumn)`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
`;
export const ItemDetailsLabel = styled.h3`
  font-weight: 500;
  margin: 0 0 4px 0;
`;
