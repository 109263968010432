import React from 'react';
import { useRecoilState } from 'recoil';
import { Radio } from 'antd';
import { ViewTypeRadioButton } from 'shared/styledComponents/inputs';
import { messageDisplayTypeAtom } from 'shared/state/messageState';

export default () => {
  const [messageDisplayType, setMessageDisplayType] = useRecoilState(messageDisplayTypeAtom);

  const onChangeView = (e: any) => {
    e.preventDefault();
    setMessageDisplayType(e.target.value);
  };

  return (
    <Radio.Group value={messageDisplayType} buttonStyle="solid" onChange={onChangeView}>
      <ViewTypeRadioButton
        value="active"
      >
        Unread
      </ViewTypeRadioButton>
      <ViewTypeRadioButton
        value="archive"
      >
        Archive
      </ViewTypeRadioButton>
    </Radio.Group>
  );
};
