import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { stageRedirect } from 'shared/util';

const NewButton = styled(Button)`
  width: 100px;
  border-radius: 8px;
`;
const NewOrderButton = () => {
  const onAddOrder = (e: any) => {
    localStorage.removeItem('currentShopOrder.id');
    localStorage.removeItem('currentOrder');
    stageRedirect();
    window.location.href = '/orders/create';
  };

  return (
    <>
      <NewButton type="primary" onClick={onAddOrder}>New Order</NewButton>
    </>
  );
};

export default NewOrderButton;
