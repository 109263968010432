import React, { useState, useEffect } from 'react';
import { IOrderItem } from 'shared/types/dbRecords';
import { ItemDetail, ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { cncLoad, totalCncLoad } from 'shared/data/order';

interface IComponent {
  orderItems: IOrderItem[];
}
const CNCLoad = ({ orderItems }: IComponent) => {
  const [preConstructionLoad, setPreConstructionLoad] = useState<string>('--');
  const [postConstructionLoad, setPostConstructionLoad] = useState<string>('--');

  useEffect(() => {
    const [preConstLoad, postConstLoad] = totalCncLoad(orderItems);
    setPreConstructionLoad(preConstLoad.toFixed(2));
    setPostConstructionLoad(postConstLoad.toFixed(2));
  }, [orderItems]);

  return (
    <ItemDetailsColumnWrapper key="order-job-type-details-wrapper" width="164px">
      <ItemDetailsLabel key="order-job-typ-details-label">CNC Load (hrs.)</ItemDetailsLabel>
      <ItemDetail>{`${preConstructionLoad} :: ${postConstructionLoad}`}</ItemDetail>
    </ItemDetailsColumnWrapper>
  );
};

export default CNCLoad;
