import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Edit } from '@styled-icons/material';
import { Button, Tooltip } from 'antd';
import { stageRedirect } from 'shared/util';
import theme from 'shared/theme';
import { currentCustomerAtom } from 'shared/state/customerState';

const EditIcon = styled(Edit)`
    height: 24px;
    color: ${theme.palette.neutral[800]};
    transition: color 0.3s ease-in-out;
`;
const EditButton = styled(Button)`
    border: none;
    &:hover {
        & ${EditIcon} {
            color: ${theme.palette.primary.hue};
        }
    }
`;

const EditShipAddressButton = () => {
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const onClick = (e: any) => {
    stageRedirect();
    window.location.href = `/customers/detail?customer=${currentCustomer.id}&qboId=${currentCustomer.Id}`;
  };

  return (
    <Tooltip placement="top" title="Edit customer address(es)">
      <EditButton shape="round" icon={<EditIcon />} onClick={onClick} />
    </Tooltip>
  );
};

export default EditShipAddressButton;
