import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { Select } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FlexRow } from 'shared/containers/FlexContainer';
import { ItemDetailsColumnWrapper, ItemDetailsLabel, SearchSelect } from 'shared/styledComponents/inputs';
import theme from 'shared/theme';
import {
  currentCustomerContactsAtom, customerContactsEditedAtom, CUSTOMER_CONTACT_DB_COLLECTION,
  selectedCustomerContactAtom,
} from 'shared/state/customerState';
import { IContact } from 'shared/types/jb';
import useFirebase from 'vendor/Firebase';
import { useParams } from 'react-router-dom';
import { ResultsColumnWrapper } from 'pages/Util/DensityCalculator/styledComponents';

interface IValueOption {
  label?: string;
  keycode: string;
}
const PartDetailDropdownItemRow = styled(FlexRow)`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.03)' : 'transparent')};
  & p {
    color: black;
    margin: 0;
    &:first-of-type {
      font-weight: bold;
      min-width: 180px;
    }
  }
  & span {
    width: 1px;
    height: 100%;
    margin: 0 8px;
    background-color: lightgrey;
  }
`;

const ContactSelector = () => {
  const { firestore } = useFirebase();
  const customerContactDbString = useRecoilValue(CUSTOMER_CONTACT_DB_COLLECTION);
  const [customersEdited, setCustomersEdited] = useRecoilState(customerContactsEditedAtom);
  const [currentOptions, setCurrentOptions] = useState<{ label: Element, value: string}[]>([]);
  const [currentCustomerContacts, setCurrentCustomerContacts] = useRecoilState(currentCustomerContactsAtom);
  const [selectedContact, setSelectedContact] = useRecoilState(selectedCustomerContactAtom);
  // @ts-ignore
  const { customerId } = useParams();

  const updateCurrent = (contactName: string) => {
    // if the contact name is blank, do nothing -- the user likely just clicked away from the field
    if (contactName === '') return;
    const contact = find(currentCustomerContacts, (c: IContact) => c.contact === contactName) as IContact;
    const items = currentCustomerContacts
      .map((c, index) => ({
        label: <PartDetailDropdownMenuItem label={c.title} keycode={c.contact} shade={index % 2 === 1} />,
        value: `${c.contact}`,
      }));
    // @ts-ignore
    setCurrentOptions(items);
    setSelectedContact(contact);
  };
  const onSelect = (value: unknown) => {
    const _value = typeof (value) === 'string' ? value : value.target.value;
    if (customersEdited) {
      firestore.collection(customerContactDbString).doc(customerId).update({ contacts: currentCustomerContacts }).then(() => {
        setCustomersEdited(false);
        updateCurrent((_value as string).toString());
      });
    } else {
      updateCurrent((_value as string).toString());
    }
  };

  const PartDetailDropdownMenuItem = ({ keycode, label = undefined }: IValueOption) => (
    <PartDetailDropdownItemRow key={`${keycode}-value-option`} id={`${keycode}-value-option`}>
      <p>{keycode}</p>
      <span />
      <p>{label}</p>
    </PartDetailDropdownItemRow>
  );
  useEffect(() => {
    // @ts-ignore
    const items = currentCustomerContacts
      .map((c, index) => ({
        label: <PartDetailDropdownMenuItem label={c.title} keycode={c.contact} />,
        value: `${c.contact}`,
      }));
      // @ts-ignore
    setCurrentOptions(items);
    setSelectedContact(currentCustomerContacts[0]);
    return () => { };
  }, [currentCustomerContacts.length]);
  return (
    <ItemDetailsColumnWrapper key="customer-search-select-item-details-wrapper" noMargin>
      <ItemDetailsLabel key="customer-search-select-item-details-label">Contacts</ItemDetailsLabel>
      <SearchSelect
        dropdownStyle={{ width: 400, minWidth: 400 }}
        id="order-customer-contact-search-dropdown"
        key="order-customer-contact-item-search-dropdown"
        placeholder="Select a contact"
      // @ts-ignore
        onSelect={onSelect}
        onBlur={onSelect}
        options={currentOptions}
        value={selectedContact.contact}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default ContactSelector;
