import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useRecoilValue } from 'recoil';
import useFirebase from '../../../vendor/Firebase';
import { sanitizePartDescription } from '../../../shared/partParser/util';
import { IOrderItem } from '../../../shared/types/dbRecords';
import { PART_VIEWER_COLLECTION } from '../../../shared/state/partViewState';
import { ORDER_ITEMS_DB_COLLECTION } from '../../../shared/state/orderState';
import TestDatabaseSyncModal from './TestDatabaseSyncModal';

const ComponentButton = styled(Button)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const TestDatabaseSyncButton = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const onClick = async (e: any) => {
    e.preventDefault();
    setShowModal(true);
  };
  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <TestDatabaseSyncModal showModal={showModal} closeCallback={onClose} />
      <ComponentButton onClick={onClick}>Sync test databases</ComponentButton>
    </>
  );
};

export default TestDatabaseSyncButton;
