import React, { useState } from 'react';
import { Switch } from 'antd';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import useFirebase from 'vendor/Firebase';
import {
  currentCustomerAtom, currentCustomerContactsAtom, CUSTOMER_CONTACT_DB_COLLECTION, CUSTOMERS_DB_COLLECTION,
  selectedCustomerContactAtom,
} from 'shared/state/customerState';
import { IContact } from 'shared/types/jb';
import QBOAuth from 'shared/data/QBO/auth';
import QBOCustomer from 'shared/data/QBO/customer';
import IQBOCustomer from 'shared/types/qbo';
import { ICustomerRecord } from 'shared/types/dbRecords';

const ComponentWrapper = styled(ItemDetailsColumnWrapper)`
  margin: 0;
`;
const ActiveSwitch = styled(Switch)`
  margin: 4px 0 6px 0;
`;

const ContactDefaultSwitch = () => {
  const { firestore, database } = useFirebase();
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const customerContactsDbString = useRecoilValue(CUSTOMER_CONTACT_DB_COLLECTION);
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const [currentCustomerContacts, setCurrentCustomerContacts] = useRecoilState(currentCustomerContactsAtom);
  const [selectedContact, setSelectedContact] = useRecoilState(selectedCustomerContactAtom);

  const onChange = (toggleState: boolean) => {
    const newContacts = sortBy(
      currentCustomerContacts.map((c: IContact) => ({ ...c, default: c.uniqueID === selectedContact.uniqueID })),
      (c: IContact) => !c.default,
    );
    firestore.collection(customerContactsDbString).doc(currentCustomer.id).update({ contacts: newContacts }).then(() => {
      setCurrentCustomerContacts(newContacts);
      setSelectedContact({ ...selectedContact, default: true });
      // QBOAuth.refreshToken(database).then(() => {
      QBOCustomer.updateDefaultContact(currentCustomer, selectedContact).then((updatedCustomer: IQBOCustomer) => {
        firestore.collection(customerDbString).doc(currentCustomer.id).update({ ...updatedCustomer }).then(() => {
          setCurrentCustomer({ ...currentCustomer, ...updatedCustomer } as ICustomerRecord);
        });
      });
      // });
    });
  };

  return (
    <ComponentWrapper key="part-number-item-details-wrapper">
      <ItemDetailsLabel key="part-number-item-details-label">Default?</ItemDetailsLabel>
      <ActiveSwitch
        disabled={selectedContact.default}
        checked={selectedContact.default}
        checkedChildren="TRUE"
        unCheckedChildren="FALSE"
        onChange={onChange}
      />
    </ComponentWrapper>
  );
};

export default ContactDefaultSwitch;
