import React, { useEffect } from 'react';
import { Collapse } from 'antd';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import ReactHtmlParser from 'react-html-parser';
import { useRecoilValue } from 'recoil';
import {
  bodyWoodAtom,
  fretboardWoodAtom, 
  neckWoodAtom,
  topWoodAtom,
} from 'shared/state/pricingState';
import { ItemCollapse, PricingHeader, PricingPageDescription } from './styledComponents';
import WoodTable from './Components/WoodTable';
import OptionsTable from './Components/OptionsTable';
import ArchetypeTable from './Components/ArchetypeTable';

const { Panel } = Collapse;

export default () => {
  const bodyWoodTypes = useRecoilValue(bodyWoodAtom);
  const topWoodTypes = useRecoilValue(topWoodAtom);
  const neckWoodTypes = useRecoilValue(neckWoodAtom);
  const fretboardWoodTypes = useRecoilValue(fretboardWoodAtom);

  useEffect(() => {
  }, [bodyWoodTypes, topWoodTypes, neckWoodTypes, fretboardWoodTypes]);

  const onPaneChange = (pane: string) => {
    localStorage.setItem('neckPricingPane', pane);
  };
  return (
    <>
      <ConfigurationCol>
        <PricingHeader>View & Edit Neck Pricing</PricingHeader>
        <PricingPageDescription>
          {ReactHtmlParser(`Use this page to view and edit pricing for all neck-related 
           items.<br/><br/><b>Note:</b> prices and labels are <i>live</i>, so any edits made will be applied to 
           all existing and future priced parts.`)}
        </PricingPageDescription>
        <ItemCollapse
          accordion
          defaultActiveKey={localStorage.getItem('neckPricingPane') || ''}
          // @ts-ignore
          onChange={onPaneChange}
        >
          <Panel key="archetype" header="Neck Models">
            <ArchetypeTable partType="neck" />
          </Panel>
          <Panel key="neckWood" header="Neck Wood">
            <WoodTable woodCategory="neckWood" />
          </Panel>
          <Panel key="fretboardWood" header="Fretboard Wood">
            <WoodTable woodCategory="fretboardWood" />
          </Panel>
          <Panel key="trussRodOptions" header="Truss Rod">
            <OptionsTable listType="unitOption" optionType="trussRodOptions" />
          </Panel>
          <Panel key="frettingOptions" header="Fretting Options">
            <OptionsTable listType="unitOption" optionType="frettingOptions" />
          </Panel>
          <Panel key="inlayOptions" header="Inlay Options">
            <OptionsTable listType="unitOption" optionType="inlayOptions" />
          </Panel>
          <Panel key="dotOptions" header="Dot Options">
            <OptionsTable listType="unitOption" optionType="dotOptions" />
          </Panel>
          <Panel key="accessoryOptions" header="Accessory Options">
            <OptionsTable listType="unitOption" optionType="accessoryOptions" />
          </Panel>
          <Panel key="constructionOptions" header="Construction (CNC) Options">
            <OptionsTable listType="unitOption" optionType="constructionOptions" />
          </Panel>
          <Panel key="finishingOptions" header="Finishing Options">
            <OptionsTable listType="unitOption" optionType="finishingOptions" />
          </Panel>
          {/* <Panel key="handlingOptions" header="Handling Options"> */}
          {/*  <OptionsTable listType="unitOption" optionType="handlingOptions" /> */}
          {/* </Panel> */}
          <Panel key="customerOptions" header="Customer-Specific Options">
            <OptionsTable listType="unitOption" optionType="customerSpecificOptions" />
          </Panel>
          <Panel key="laborOptions" header="Labor Options (hourly rates)">
            <OptionsTable listType="rateOption" optionType="laborOptions" />
          </Panel>
        </ItemCollapse>
        <div style={{ height: 100 }} />
      </ConfigurationCol>
    </>
  );
};
