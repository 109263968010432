export const BODY_WOOD_MAP = {
  '5AQuiltMap': ['5AQuiltMap', '5AQuilt', '5A QLT', '5A Quilt', '5AQuilt Map', '5AQltMap'],
  '3AQuiltMap': ['3AQuiltMap', '3AQuilt', '3A QLT', '3A Quilt', '3AQuilt Map', '3AQltMap'],
  '6AQuiltMap': ['6AQuiltMap', '6AQuilt', '6A QLT', '6A Quilt', '6AQuilt Map', '6AQltMap'],
  '5AFlameMap': ['5AFlameMap', '5AFlame', '5A FLM', '5A FLM MAP', '5A FMap', '5A Flame', '5AFlmMap'],
  '6AFlameMap': ['6AFlameMap', '6AFlame', '6A FLM', 'MG FLM', '6A Flame'],
  '3AFlameMap': ['3AFlameMap', '3AFlame', '3A FLM', '3A FLMap', 'FLM 3A', '3A Flame', '3AFlmMap'],
  // '5ASpaltedMap': ['5ASpaltMap(?!ed)', '5ASpalt', '5A Spalted', '5A Spalt'],
  VinFlame: ['VinFlameMap', 'Vin Flame', 'VinFlame', 'VinFLM', 'Vin FLM'],
  FlameMap: ['FlameMap', 'Flame Map', 'FlmMap', 'Flm Map'],
  Basswood: ['basswood'],
  CS: ['customer supplied', 'cs', 'c/s'],
  Alder: ['alder', 'ald', '1Pc Alder', '1 Pc Alder'],
  RstAlder: ['rst alder', 'rstalder'],
  KhyMah: ['kamah', 'khnmah', 'kha mah', 'khamah', 'khayan', 'khymah', 'khmah', 'khy mah', 'kymah', 'kh mah', 'khmah', 'k mah', '1pc khymah', 'kmah', '1pc khmah'],
  Okoume: ['okm', 'okoume'],
  GenMah: ['gen mah', 'genmah', '1Pc genmah', '1 pc genmah'],
  OGRW: ['ogvg rwd', 'redwood', 'ogvg redwood', 'vtg ogr', 'vtg redwood', 'ogr(?!w)', 'ogr vg', 'ogvrw'],
  Paulownia: ['paulownia', 'palownia', 'pawlonia', 'paw'],
  'White Korina': ['wht korina', 'white korina', 'wht limba'],
  'Black Korina': ['BlkKor', 'BlackKor', 'BlkKorina', 'BlackKorina', 'Blk Kor', 'Black Korina', 'Blk Korina', 'black korina', 'blk korina', 'bkor'],
  EWPine: ['ew pine', 'ewp(?!i)', 'ewht pine', 'ewhtpine', 'whtpine', 'e wht pine'],
  RstEWPine: ['rst ew pine', 'rst ewp(?!i)', 'rst whtpine', 'rstwhtpine', 'rstwpine', 'rst wht pine', 'rst ewpine', 'rst ewhtpine', 'rst e wht pine'],
  BlackVen: ['Blk\\s?Ven'],
  SugarPine: ['sugerpine', 'sugarpine', 'sugar pine'],
  SpaltedMap: ['spaltmaple', 'spalted maple', 'spalt maple', 'spaltedmaple', 'spalt map', 'SpaltedMap'],
  Ash: ['swampash', 'swamp ash', 'swash', 'sw ash', 'stained swash', '1Pc swash', 'Fig. swash', 'ash(_\s)?', 'ash'],
  RstAsh: ['rst swash', 'rstswash', 'rstswashlite'],
  Map: ['Maple', 'Map$'],
  MapVen: ['Mpl Ven'],
  PlainMap: ['Plain Map', 'Plain Maple', 'PlainMap', 'PlainMaple'],
  Buckeye: ['Buckeye'],
  HVen: ['Hven'],
  'Black Walnut': ['Black Walnut'],
  Sycamore: ['SYC'],
};

export const BODY_WOOD_WEIGHTS = { Lite: ['Lite'], XXLite: ['XXLite'], XLite: ['XLite', '(?<![a-z0-9])XL', '(?<![a-z0-9])XXL', 'X\\sLite', 'Xlite'] };

export const BODY_PICKUPS = {
  CCS: ['CCS'],
  CCH: ['CCH'],
  DC: ['DC'],
  DMH: ['DMH'],
  DS1: ['DS1'],
  DarkStar: ['DarkStar', 'DkStar'],
  DeepS: ['DeepS'],
  DualCoil: ['DualCoil'],
  'EMG-35': ['EMG-35'],
  'EMG-40': ['EMG-40'],
  'EMG-45': ['EMG-45'],
  'EMG-MM': ['EMG-MM'],
  'EMG-LJV': ['EMG-LJV'],
  'EMG-SJV': ['EMG-SJV'],
  EMGS: ['EMGS'],
  FB: ['FB', 'Fb'],
  GF: ['GF', 'Goldfoil'],
  'H-CUSTOM': ['H-CUSTOM'],
  Hf: ['Hf'],
  HDm: ['HDm'],
  // H: ['H(?:\\/)?', 'H'],
  H: ['H'],
  '60s J': ['60s J', '60s J(\\/)?J'],
  '70s J': ['70s J', '70s J(\\/)?J'],
  J: ['J'],
  LFS: ['LFS', 'LF S'],
  LJ: ['LJ', 'Lj'],
  LolH: ['LolH', 'Lolh', 'LollerH'],
  M: ['M', 'M-'],
  MH: ['MH', 'Mh'],
  NDC: ['NDC', 'Ndc'],
  NoPu: ['NoPu', 'NoPU', 'NPu', 'Npu', 'NPU', 'NPUS'],
  P: ['P'],
  P90: ['P90', 'P90-'],
  P90Plastic: ['P90Plastic', 'P90-Plastic'],
  RevS: ['RevS', 'Revs', 'Rev\\sS'],
  S: ['S'],
  SB: ['SB', 'Sb'],
  SJ: ['SJ', 'Sj'],
  T: ['T', 'T-'],
  T4: ['T4', 'T4-'],
  T5: ['T5', 'T5-'],
  TVJ: ['TVJ', 'Tvj'],
  UniLg: ['UniLg', 'Unilg', 'Uni large'],
  UniMd: ['UniMd', 'Unimd', 'Uni med'],
  UniSm: ['UniSm', 'Unism', 'Unismall'],
  UNI: ['UNI', 'Uni'],
  WRH: ['WRH', 'WRHB'],
};

export const BODY_PICKUP_MODIFIERS = {
  Rev: ['Rev'],
  Slanted: ['Slanted'],
  Baretta: ['Baretta'],
};

export const NECK_WOOD_MAP = {
  None: ['None'],
  Wenge: ['Wenge'],
  RstFlatMap: ['Flt RstMap', 'FltRstMap', 'FltS Rst Map', 'RstFlatMap', 'RstFltMap'],
  Map: ['Map', '(?<=_)Mp', 'Mpl', 'Maple'],
  QSMap: ['QTR MAP', 'Qtr  Map', 'QTRMAP', 'Qtr Sawn Map', 'Quarter\\s?sawn\\s?Map'],
  RstQSMap: ['QtrMap RST', 'RstQAMap', 'RstQtr Map', 'RST QTR MAP', 'Rst Qtr  Map', 'Rst\\s?QTRMAP', 'Rst\\s?QtrMap', 'RstQSMap'],
  EBY: ['Ebony', 'EBY', ' EBY', 'Ebony -Black'],
  MacEBY: ['MacEBY', 'Mac Eby', 'MCSR EBY', 'MCSREBY'],
  'EBY PH': ['EBY PhCap', 'Eb PHCap', 'Eb PH cap', 'Eby PH'],
  IRW: ['Dark IRW', 'IRW'],
  'Hon RW': ['HRW'],
  'Mad RW': ['MADIRW', 'MadRW'],
  'IRW PH': ['IRW PH', 'IRW PH cap'],
  '5ANatFlameMap': ['(?<!Rst)5AFlame(?!Map)', '(?<!Rst)5AFlameMaple', '(?<!Rst)5AFlameMap', '5ANatFlame(?!Map)', '5AFlameNat(?!Map)'],
  '6ANatFlameMap': ['(?<!Rst)6AFlame(?!Map)', '(?<!Rst)6AFlameMaple', '(?<!Rst)6AFlameMap', '6ANatFlame(?!Map)', '6AFlameNat(?!Map)'],
  '3ANatFlameMap': ['(?<!Rst)3AFlame(?!Map)', '(?<!Rst)3AFlameMaple', '(?<!Rst)3AFlameMap', '3ANatFlame(?!Map)', '3AFlameNat(?!Map)', 'FLM 3A'],
  Bse: ['\\bBSE\\b', 'BSE'],
  '5ANatBseMap': ['5ABSE', 'BSE 5A', '5A BSE'],
  '4ANatBseMap': ['4ABSE', 'BSE 4A', '4A BSE'],
  '3ANatBseMap': ['3ABSE', 'BSE 3A', '3A BSE'],
  '5ARstFlameMap': ['Rst5AFlameMap', '5A\\s?Roasted\\s?Map', '5ARstFlameMap', '5ARstMap', 'RstMap5A', '5ARstFlmMap', 'RstFLM 5A', 'RST5AFLM', 'Rst FLM 5A', 'Rst 5A FMap', 'Rst5AFlmMap', '5A RstFlm'],
  '3ARstFlameMap': ['Rst3AFlameMap', '3A\\s?Roasted\\s?Map', '3ARstFlameMap', '3ARstMap', 'RstMap3A', '3ARstFlmMap', 'RST 3A FLM RST', 'RstFLM 3A', 'RST3AFLM', 'Rst FLM 3A', 'Rst 3A FMap', 'Rst3AFlmMap', 'Rst 3AFlmMap', '3ARstFLM', '3A Rst Flm Map', '3A FLM RST'],
  '2ARstFlameMap': ['Rst2AFlameMap', '2A\\s?Roasted\\s?Map', '2ARstFlameMap', '2ARstMap', 'RstMap2A', '2ARstFlmMap', '2 A Flm Rst', '2A Rst Flm', 'RST 2A FLM RST', 'RstFLM 2A', 'RST2AFLM', 'Rst FLM 2A', 'Rst 2A FMap', 'Rst2AFlmMap', 'Rst 2AFlmMap'],
  '6ARstFlameMap': ['Rst6AFlameMap', '6A\\s?Roasted\\s?Map', '6ARstFlameMap', '6ARstMap', 'RstMap6A', '6ARstFlmMap', '6AFlame', '6A FLM', 'MG FLM'],
  '5ARstBseMap': ['RstBSE 5A', '5A BSE Rst Map'],
  '3ARstBseMap': ['RSTBSE 3A', '3A BSE Rst Map'],
  '6ARstBseMap': ['RSTBSE 6A', '6A BSE Rst Map'],
  MGRstFlameMap: ['RstMap MG'],
  'HoneyRst Map': ['HonRST Map'],
  'Black Richlite': ['Blk RIC'],
  PauFerro: ['PAU(?!F)', 'PauF(?!e)'],
  VinFlame: ['Vin Flame', 'VinFlame', 'VinFLM'],
  CS: ['customer supplied', 'cs', 'c/s', 'cs ph', '1Pc CS', '1 Pc CS'],
  KhyMah: ['kamah', 'khnmah', 'kha mah', 'khamah', 'khayan', 'khymah', 'khmah', 'khy mah', 'kh mah', 'khmah', 'k mah'],
  GenMah: ['1pc genmah', '1pc gen mah', 'gen mah', 'genmah'],
  QSGenMah: ['Qtr GenMah'],
  OGRW: ['ogrw', 'ogvg rwd', 'redwood'],
  EWPine: ['ew pine', 'ewp', 'ewpine', 'ewht pine', 'ewhtpine', 'whtpine', 'e wht pine'],
  'Rst EWPine': ['rst ew pine', 'rst ewp', 'rst whtpine', 'rstwhtpine', 'rstwpine', 'rst wht pine'],
  'Black Veneer': ['BlkVen', 'Blk Ven', 'BlackVeneer', 'Black Veneer'],
  'White Korina': ['wht korina', 'white korina'],
  'Black Korina': ['Blk Limba', 'BlkKor', 'BlackKor', 'BlkKorina', 'BlackKorina', 'Blk Kor', 'Black Korina', 'Blk Korina'],
  Self: ['SELF', 'Slf'],
};

export const INLAY_MAP = {
  Abalone: ['Abl\\s?', 'Aba(?!l)\\s?', 'Abalon(?!e)\\s?'],
  Black: ['Blk(?:\\s|$)', 'Black(?:\\s|$)', 'BlackP(?:\\s|$)', 'BlackPl(?:\\s|$)'],
  Cream: ['Crm\\s?', 'Cream\\s?'],
  Gold: ['Gold\\s?', 'GLD\\s?'],
  White: ['Wht\\s?', 'White\\s?P?\\s?'],
  Pearloid: ['Pearld', 'Pearl(?!o)', 'Pearloid'],
  PearloidBlock: ['Pearloid\\s?Block', 'Pearloid\\s?Blk'],
  PearloidBind: ['Pearloid\\sBind'],
  BlackMop: ['MOP\\s?Blk', 'MOP Black'],
  WhiteMop: ['MOP\\s?Wht', 'MOP White'],
  GoldMop: ['MOP\\s?Gld', 'MOP Gold'],
  Mop: ['MOP\\s?', 'Mop\\s?'],
  Lumi: ['Luminlay\\s?', 'Lum(?!i)\\s?', 'Lumilay\\s?'],
  'BlackF\/BlackS': ['Blk\\s?F&S', 'BlkPl\\s?F&S', 'BlackPl\\s?F&S', 'BlackP\\s?F&S'],
  'IvoryF\/IvoryS': ['Ivory\\s?F&S'],
  'MopF\/MopS': ['MopF&S'],
  'AbaloneF\/AbaloneS': ['Abl\\s?F&S', 'Aba\\s?F&S', 'Abalone\\s?F&S'],
  'BlackBlock/BlackS': ['BlackBlock & BlackS'],
  Block: ['\\s?Block[s]?'],
};
