import styled from 'styled-components';
import {
  Button, Divider, Dropdown, Input, Radio,
} from 'antd';
import { ConfigurationCol, ConfigurationRow } from 'shared/pageElements/styledComponents';
import theme from 'shared/theme';
import { CSVLink } from 'react-csv';
import { QuestionCircle } from '@styled-icons/fa-solid';
import CaptionedInputNumber from 'shared/components/Input/CaptionedInputNumber';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { Caption } from 'shared/typography';
import { BellIcon } from '@heroicons/react/20/solid';

export const NewItemButton = styled(Button)`
  width: 20%;
  min-width: 116px;
    margin-left: ${theme.spacing(1)};
    border-radius: ${theme.spacing(0.5)};
    
    &:first-of-type {
        margin-left: 0;
    }
  @media ${theme.device.laptopM} {
    width: 15%;
  }
`;

export const CustomerDetailsWrapper = styled(FlexRow)`
    //width: 50%;
    height: auto;
    
    justify-content: space-between;
    align-items: flex-start;
  margin-top: 24px;
    margin-left: ${theme.spacing(4)};
    gap: ${theme.spacing(7.5)};
`;

export const CustomerNameWrapper = styled(FlexColumn)`
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    
    & h4 {
        line-height: 1;
        margin: 0;
    }
`;

export const EditCustomerButton = styled(Button)`
  margin-top: 2px;
  padding-left: 3px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 8px;
`;

export const PercentLockButton = styled(EditCustomerButton)`
  padding-left: 12px;
  margin-left: unset;
  margin-right: unset;
  align-self: flex-end;
`;

export const CustomerDiscountWrapper = styled(FlexRow)`
    //width: 40%;
  justify-content: flex-end;
  align-items: flex-end;
    
  & p {
    margin: 0;
    margin-right: 4px;
    width: unset;
  }
`;

export const CustomerDiscountField = styled(CaptionedInputNumber)`
  & * {
    &:disabled {
      color: ${theme.palette.neutral.black};
      background-color: #EEEEEE;
    }
  }
`;

export const CustomerAdjustmentType = styled(Radio.Group)`
`;

export const CustomerFilter = styled(Radio.Group)`
    margin: ${theme.spacing(2)} 0;
    position: sticky;
    top: 0;
    z-index: 1000;
`;

export const CustomerActionButtonWrapper = styled(FlexRow)`
  justify-content: flex-start;
  margin-top: 24px;
`;

export const DropdownButton = styled(Dropdown.Button)`
    padding-left: 0;
  &:last-of-type {
    margin-left: 12px;
  }
  & .ant-btn {
    background-color: ${theme.palette.primary.hue};
    color: ${theme.palette.neutral.white};
    border-color: transparent;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    transition: background-color 0.2s linear;
    &:last-of-type{
      border-left-color: rgba(255, 255, 255, 0.3) !important;
    }
    &:last-of-type{
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important; 
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }
  &:hover {
    & .ant-btn {
      background-color: ${theme.palette.primary.D100};
      border-color: transparent;
      color: ${theme.palette.neutral.white};
      &:first-of-type{
        border-right-color: rgba(255, 255, 255, 0.3) !important;
      }
    }
  }
`;

export const ExportDropdownButton = styled(DropdownButton)`
  & .ant-btn {
    background-color: ${theme.palette.success.hue};
  }
  &:hover {
    & .ant-btn {
      background-color: ${theme.palette.success.D100};
    }
  }
`;

export const CustomerDownloadButton = styled(CSVLink)`
    min-width: 152px;
  flexGrow: 2;
    height: ${theme.spacing(4)};
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
    
    border: 1px solid ${theme.palette.success.hue};
    border-radius: ${theme.spacing(1)};
    background-color: ${theme.palette.success.hue};
    color: ${theme.palette.neutral.white};
    font-size: 14px;
    
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  
    margin-left: 12px;
    
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover {
        color: ${theme.palette.neutral.white};
        background-color: ${theme.palette.success[900]};
        border: 1px solid ${theme.palette.success[900]};
    }
  
`;

export const CustomerMenuPlaceholder = styled.div`
  @media ${theme.device.laptop} {
    height: 20px;
    width: 100%;
  }
`;

export const CustomerListCol = styled(ConfigurationCol)`
    width: 100%;
    border-right: 1px solid ${theme.palette.neutral[300]};
    max-height: 90vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    
    align-items: flex-start;
    justify-content: flex-start;
    
    margin: 0;
    @media ${theme.device.laptop} {
        width: 25%;
    }
`;

export const CustomerListColInnerWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

export const PartViewerCol = styled(CustomerListCol)`
  //overflow-y: unset;
  min-height: 75vh;
  max-height: 85vh;
  width: 27vw;
  position: sticky;
  top: ${window.location.href.match('customer') ? '128px' : '132px'};
  
  // @media ${theme.device.laptop} {
  //  width: 35vw;
  //}
  
  @media ${theme.device.laptopL} {
    width: 15vw;
  }
`;

export const PartViewerDetailsCol = styled(FlexColumn)`
  margin-left: 40px;
  width: 75vw;
`;

export const PriceFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  //margin-left: ${theme.spacing(3)};
`;

export const StrikeThrough = styled.p`
    text-decoration: line-through;
    color: ${theme.palette.neutral[500]};
    
    margin: 0;
    margin-left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(2)};
`;

export const PricingActionRow = styled(FlexRow)`
  width: 100px;
  justify-content: flex-end;
  gap: ${theme.spacing(1)};
`;

export const PriceItem = styled.button`
  display: flex;
  flex-direction: row;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.spacing(1.5)} 8px; 
  border-bottom: 1px solid ${theme.palette.neutral[100]};
  
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.035)' : 'white')};
  margin: 0;
  transition: background-color 0.3s ease-in-out; 
  &:hover {
    background-color: ${theme.palette.primary[200]};
  }
  @media ${theme.device.laptop} {
    flex-direction: row;
  }
`;

export const NotPricedIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${theme.palette.primary[800]};
  margin-right: 20px;
  margin-top: 4px;
`;

export const PriceItemClickWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  cursor: pointer;
  flex-grow: 2;
  margin: 0;
  padding: 0;
  gap: 12px;
  & p {
    font-size: 0.8rem;
    //margin: 0;
    padding: 0;
    &:first-of-type {
      font-weight: 500;
    }
  }
`;

export const PricedItemLink = styled.a`
  font-size: 0.7rem;
  margin: 0;
    color: ${theme.palette.neutral[900]};
    &:hover {
        color: ${theme.palette.primary.hue};
    }
  @media ${theme.device.laptopL} {
    font-size: 0.8rem;
  }
`;

export const EmptyPriceItem = styled(PriceItem)`
  margin-top: ${theme.spacing(3)};
`;

export const CustomerColWrapper = styled.div`
    width: calc(100% - ${theme.spacing(2)});
    overflow-x: scroll;
    
    @media ${theme.device.laptop} {
        width: 100%;
    }
`;

export const CustomerCol = styled(FlexColumn)`
    flex-direction: row;
    justify-content: flex-start;
    gap: ${theme.spacing(1)};
    
    @media ${theme.device.laptop} {
        gap: ${theme.spacing(0.5)};
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const NewCustomerInputLabel = styled(Caption)`
  margin: 0 0 ${theme.spacing(0.5)} 0;
`;
export const NewCustomerButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(1)};
`;

export const NewCustomerInput = styled(Input)`
    font-size: 18px;
`;

export const NewCustomerFormWrapper = styled(FlexColumn)`
  width: 100%;
  //padding-top: 48px;
`;
export const NewCustomerModalColumn = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    margin-bottom: ${theme.spacing(2)};
`;

export const NewCustomerIdColumn = styled(NewCustomerModalColumn)`
    width: 100%;
    margin-right: ${theme.spacing(3)};
`;

export const NewCustomerNameColumn = styled(NewCustomerModalColumn)`
    width: 100%;
`;

export const NewCustomerAdjustmentType = styled(Radio.Group)`
    width: 200px;
    height: 40px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 18px;
`;

export const ConfirmDeleteIcon = styled(QuestionCircle)`
  width: 18px;
  color: ${theme.palette.error.hue};
  margin-right: 4px;
`;

export const PartViewerRow = styled(FlexRow)`
  align-items: flex-start;
  justify-content: flex-start;
  
  width: 100%;
`;
