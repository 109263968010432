import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { LeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ConfigurationRow, ListPriceLabel } from 'shared/pageElements/styledComponents';
import { formatPercent, formatPrice } from 'shared/data';
import {
  currentInventoryPartAtom,
  INVENTORY_ITEMS_COLLECTION,
  inventoryPartEditedAtom,
} from 'shared/state/inventoryState';
import { ItemDetailBackButton } from 'shared/styledComponents/inputs';
import useFirebase from 'vendor/Firebase';
import SimpleModal from 'shared/components/Utility/SimpleModal';
import { popRedirect } from 'shared/util';
import { adminUserEmailsAtom, customerUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import theme from 'shared/theme';
import { rgba } from 'shared/palette/utils';
import { noop } from 'lodash';
import SaveQATicketButton from './Buttons/SaveQATicketButton';
import { currentQaTicketAtom, qaTicketEdited } from '../../../../shared/state/qualityAssuranceState';
import DetailInputWithCallback from '../../../../shared/components/Input/DetailInputWithCallback';
import HelpButton from '../../../../shared/components/Utility/HelpButton';

const ItemDetailHeader = styled(ConfigurationRow)`
    width: calc(100% - 16px);
    padding-left: 72px;
    height: 72px;
    flex-shrink: 0;
    gap: 8px;
    margin-top: ${(props: any) => (props.quickEditMode ? '0' : '16px')};
  
    position: sticky;
    top: 16px;
    z-index: 1000;
    
    @media ${theme.device.laptop} {
      width: calc(100% - 120px);
      margin-left: 96px;
      padding-left: unset;
      // background-color: ${theme.palette.primary.hue};
      background-color: rgba(82, 81, 102, 0.7);
    }
`;

const BackButton = styled(ItemDetailBackButton)`
    margin-left: ${theme.spacing(3)};
    box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.08);
`;

const ReportCopy = styled.h2`
    color: ${theme.palette.neutral.black};
    font-weight: bold;
    margin: 0 0 0 16px;
    @media ${theme.device.laptop} {
       color: ${theme.palette.neutral.white};
    }
`;
interface IComponent {
  imageData: any[];
  id: string;
}
export default ({ id }: IComponent) => {
  const qaTicketState = useRecoilValue(currentQaTicketAtom);
  const recordEdited = useRecoilValue(qaTicketEdited);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);

  const redirect = () => {
    const redirectUrl = popRedirect();
    if (redirectUrl) window.location.href = redirectUrl;
    else window.location.href = `/qa/tickets?ticketId=${qaTicketState.id}`;
  };

  return (
    <>
      <ItemDetailHeader>
        {!recordEdited ? (
          <BackButton
            shape="circle"
            type="primary"
          // @ts-ignore
            icon={<LeftOutlined />}
            onClick={redirect}
          />
        ) : (
          <ScopedComponent blacklist={customerEmails.emails}>
            <SaveQATicketButton />
          </ScopedComponent>
        )}
        <ReportCopy>{`Report #${id}`}</ReportCopy>
      </ItemDetailHeader>
    </>
  );
};
