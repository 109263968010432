import React from 'react';
import { useRecoilValue } from 'recoil';
import { ItemDetail, ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { noFinishing } from 'shared/data/order';
import { orderItemsAtom } from 'shared/state/orderState';

const OrderFinishingRequired = () => {
  const orderItems = useRecoilValue(orderItemsAtom);

  return (
    <ItemDetailsColumnWrapper key="order-finishing-required-details-wrapper" width="164px">
      <ItemDetailsLabel key="order-finishing-required-details-label">Finishing required?</ItemDetailsLabel>
      <ItemDetail>{!noFinishing(orderItems) ? 'Yes' : 'No'}</ItemDetail>
    </ItemDetailsColumnWrapper>
  );
};

export default OrderFinishingRequired;
