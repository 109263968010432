import React from 'react';
import {
  View, Text, StyleSheet,
} from '@react-pdf/renderer';
import { IOrderItem } from 'shared/types/dbRecords';
import { includes } from 'lodash';
import { cleanMeta, isMeta, splitAtMeta } from '../../../../shared/text';
import workOrder from '../../../Orders/Components/WorkOrder/WorkOrder';

const bodyFontSize = 9;
const notesFontSize = 8.5;
const style = StyleSheet.create({
  // General
  divider: {
    height: 1,
    backgroundColor: 'lightgrey',
    width: 542,
    marginLeft: 14,
    marginBottom: 4,
  },
  nonOrderItemDivider: {
    marginBottom: 4,
  },
  // Order item (part number, job number, IHS prompt)
  orderItemRow: {
    width: 552,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingBottom: 6,
    // marginBottom: 4,
    // borderBottom: '1px solid lightgrey',
  },
  nonOrderItemRow: {
    width: 552,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    opacity: 0.5,
  },
  orderItemNumberCol: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  orderItemNumberRow: {
    width: 88,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ihsRow: {
    width: 70,
    height: 16,
    borderRadius: 8,
    backgroundColor: '#1e85d3',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 4,
    // marginLeft: 2,
  },
  ihs: {
    color: 'white',
    fontFamily: 'Red Hat Display-700',
    fontSize: bodyFontSize,
  },
  revRow: {
    backgroundColor: '#FF9800',
  },
  partNumber: {
    width: 88,
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: bodyFontSize,
    backgroundColor: 'yellow',
    // padding: 2,
    margin: 0,
    marginLeft: 18,
  },
  nonOrderPartNumber: {
    backgroundColor: 'transparent',
    fontSize: bodyFontSize - 1,
  },
  jobNumber: {
    width: 12,
    fontFamily: 'Red Hat Display',
    fontWeight: 600,
    fontSize: bodyFontSize,
    margin: 0,
    color: 'lightgrey',
  },

  // Description Column
  descriptionCol: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 422,
    paddingLeft: 6,
  },
  description: {
    fontFamily: 'Red Hat Display',
    fontWeight: 500,
    fontSize: bodyFontSize,
    margin: 0,
  },
  nonOrderDescription: {
    fontSize: bodyFontSize - 1,
  },
  highlightDescriptionTerm: {
    paddingRight: 2,
    paddingLeft: 2,
    fontFamily: 'Red Hat Display-700',
    backgroundColor: 'yellow',
    fontSize: bodyFontSize + 2,
    fontWeight: 700,
  },
  notes: {
    fontFamily: 'Red Hat Display',
    fontWeight: 300,
    fontSize: notesFontSize,
    margin: 0,
    marginLeft: 4,
    marginTop: 2,
    color: '#666666',
  },

  // Quantity + Volume
  quantityVolumeRow: {
    width: 45,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  quantityNoVolumeRow: {
    justifyContent: 'flex-end',
  },
  quantity: {
    fontFamily: 'Red Hat Display',
    fontWeight: 400,
    fontSize: bodyFontSize,
    margin: 0,
    backgroundColor: 'yellow',
  },
  nonOrderQuantity: {
    fontSize: bodyFontSize - 2,
    backgroundColor: 'transparent',
  },
  volume: {
    fontFamily: 'Red Hat Display',
    fontWeight: 400,
    fontSize: bodyFontSize,
    margin: 0,
  },
});

interface IComponent {
  orderType: 'body' | 'neck';
  workOrderItem: IOrderItem;
  nonOrderItem: boolean;
}
const WorkOrderItem = ({ workOrderItem, orderType, nonOrderItem = false }: IComponent) => {
  const quantityVolumeStyle = [style.quantityVolumeRow];

  if (orderType === 'neck') quantityVolumeStyle.push(style.quantityNoVolumeRow);
  const preHighlightWords = [
    'LEFTY',
    'REV PH',
    'REVPH',
  ];

  const description = workOrderItem.Description.split('_').map((s: string, index: number) => {
    const separatedString = splitAtMeta(s);
    console.log(separatedString);
    return (
      <Text>
        <>
          {separatedString.map((term) => {
            const styles = [style.description, nonOrderItem ? style.nonOrderDescription : {}];
            const metaTag = isMeta(term);
            if ((metaTag || includes(preHighlightWords, term)) && !nonOrderItem) styles.push(style.highlightDescriptionTerm);
            return <Text style={styles}>{cleanMeta(term)}</Text>;
          })}
        </>
        {index !== workOrderItem.Description.split('_').length - 1 && (
          <Text style={style.description}>_</Text>
        )}
      </Text>
    );
  });

  return (
    <>
      <View style={[style.orderItemRow, nonOrderItem ? style.nonOrderItemRow : {}]}>
        <View style={style.orderItemNumberCol}>
          <View style={style.orderItemNumberRow}>
            {/* <Text style={style.jobNumber}> */}
            {/*  {workOrderItem.jobNumber.split('-')[1]} */}
            {/* </Text> */}
            <Text style={[style.partNumber, nonOrderItem ? style.nonOrderPartNumber : {}]}>
              {workOrderItem.Sku}
            </Text>
          </View>
          {(!nonOrderItem && workOrderItem.houseSample) && (
          <View style={style.ihsRow}>
            <Text style={style.ihs}>IHS</Text>
            {/* <View style={style.ihsUnderline} /> */}
          </View>
          )}
          {(!nonOrderItem && (!!workOrderItem.pdRequirement && !workOrderItem.pdCompletedDate)) && (
            <View style={[style.ihsRow, style.revRow]}>
              <Text style={style.ihs}>NEEDS DEV</Text>
              {/* <View style={style.ihsUnderline} /> */}
            </View>
          )}
        </View>
        <View style={style.descriptionCol}>
          <Text style={[style.description, nonOrderItem ? style.nonOrderDescription : {}]}>
            <>
              {description}
            </>
          </Text>
          {!nonOrderItem && (
            <Text style={style.notes}>{workOrderItem.notes}</Text>
          )}
        </View>
        <View style={quantityVolumeStyle}>
          {orderType === 'body' && (
          <Text style={style.volume}>{workOrderItem.volume}</Text>
          )}
          <Text style={[style.quantity, nonOrderItem ? style.nonOrderQuantity : {}]}>{workOrderItem.quantityAssigned}</Text>
        </View>
      </View>
      <View style={[style.divider, nonOrderItem ? style.nonOrderItemDivider : {}]} />
    </>
  );
};

export default WorkOrderItem;
