import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import qs from 'qs';
import { find, includes, noop } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentInventoryPartAtom, INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from 'shared/state/inventoryState';
import { PartDetailColumnHeader } from 'shared/styledComponents/typographicElements';
import {
  PartDetailColumn, DetailComponentWrapper, DetailRow, DetailColumn,
} from 'shared/styledComponents/containers';
import useFirebase from 'vendor/Firebase';
import { Modal } from 'antd';
import { qboDateString } from 'shared/data/calendar';
import QBOItem from 'shared/data/QBO/item';
import { redirect } from 'shared/util';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { productCodesAtom } from 'shared/state/utilState';
import {
  IGlCode, IInventoryChild, IInventoryPart, IProductCode,
} from 'shared/types/dbRecords';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import DetailLabel from 'shared/components/Input/DetailLabel';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import InventoryProductCode from './Components/InventoryDetailFields/InventoryProductCode';
import InventoryPartCosting from './Components/InventoryPartCosting';
import InventoryStockInfo from './Components/InventoryStockInfo';
import InventoryDetailHeader from './Components/InventoryDetailHeader';
import DetailActiveSelectorWithCallback from '../../shared/components/Input/DetailActiveSelectorWithCallback';
import InventoryPartConsumedStep from './Components/InventoryDetailFields/InventoryPartConsumedStep';
import { nextPartNumber } from './Components/InventoryItem';
import InventoryVendorName from './Components/InventoryVendorDetailFields/InventoryVendorName';
import SubItemParentDescription from './Components/InventorySubItem/SubItemParentDescription';
import InventoryPartChildren from './Components/InventoryPartChildren';
import QuickViewButton from './Components/QuickView/Buttons/QuickViewButton';

// gap between detail blocks
const GAP = 20;

interface IComponent {
  quickEdit: boolean;
}

const PartNumberWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
    //margin-right: 12px;
`;

const GLRow = styled(FlexRow)`
    justify-content: flex-start;
    gap: 12px;
`;

const InventoryDetail = ({ quickEdit = false }: IComponent) => {
  const { currentUser } = useContext(AuthContext);
  // @ts-ignore;
  const partId = quickEdit ? localStorage.getItem('inventory.quick.edit.partNumber') : qs.parse(window.location.search.replace('?', '')).partId;
  const { firestore } = useFirebase();
  const productCodes = useRecoilValue(productCodesAtom);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const [inventoryPart, setInventoryPart] = useRecoilState(currentInventoryPartAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const isShopUser = !includes([...superAdminUsers.emails, ...adminUsers.emails], currentUser.email);

  const onChangeActive = (active: boolean) => {
    setInventoryPart({ ...inventoryPart, Active: active });
  };

  const onChangeCritical = (critical: boolean) => {
    setInventoryPart({ ...inventoryPart, criticalPart: critical });
  };
  const onChangePartNumber = (Sku: string, blur: boolean = false) => {
    if (blur) {
      if (Sku.length < 3) {
        Modal.info({
          title: 'Part prefix',
          content: 'Type in the first three digits of the part you are making to automatically select the next part number in their list.',
        });
      } else if (Sku.length > 3) {
        setInventoryPart({ ...inventoryPart, Sku });
      } else {
        setInventoryPart({ ...inventoryPart, Sku: nextPartNumber(Sku.substring(0, 3), inventoryItems) });
      }
    } else {
      setInventoryPart({ ...inventoryPart, Sku });
    }
  };

  const onChangePartText = (key: string) => (description: string) => {
    const updated = { ...inventoryPart };
    updated[key] = description;
    setInventoryPart(updated);
  };

  const onChangeChildPurchaseWeight = async (children: { Sku: string, PurchaseCost: number, purchaseWeight: number}[]) => {
    const newChildren = inventoryPart.Children.map((c: IInventoryChild) => {
      const updated = find(children, (u) => u.Sku === c.sku);
      if (!updated) return c;
      return { ...c, purchaseWeight: updated.purchaseWeight };
    });
    const newAverageCost = children.map((c) => c.PurchaseCost * c.purchaseWeight).reduce((a, b) => a + b, 0);
    await firestore.collection(inventoryDbString).doc(inventoryPart.Sku).update({ PurchaseCost: newAverageCost, Children: newChildren });
    setInventoryPart({ ...inventoryPart, Children: newChildren, PurchaseCost: newAverageCost });
  };

  const onProductCodeChange = (productCode: string) => {
    const _productCode = find(productCodes, (p: IProductCode) => p.productCode === productCode) as unknown as IProductCode;
    if (_productCode) {
      setInventoryPart({ ...inventoryPart, ProductCode: _productCode });
    }
  };

  useEffect(() => {
    if (partId) {
      firestore.collection(inventoryDbString).doc(partId).get().then((doc) => {
        if (!doc.exists) {
          Modal.error({
            title: 'Uh-oh',
            content: "Couldn't find that part in our database! Click OK to go back.",
            onOk: () => {
              redirect();
            },
          });
        } else {
          const inventoryStartDate = qboDateString(new Date());
          const part = { ...doc.data(), InvStartDate: inventoryStartDate };
          setInventoryPart(part);
        }
      });
    } else if (localStorage.getItem('inventory.temp.part')) {
      const partData = JSON.parse(localStorage.getItem('inventory.temp.part'));
      const inventoryStartDate = qboDateString(new Date());
      setInventoryPart({ ...partData, InvStartDate: inventoryStartDate });
    } else {
      setInventoryPart({
        Name: '',
        Sku: '',
        Description: '',
        Active: true,
        FullyQualifiedName: '',
        Taxable: true,
        UnitPrice: 0,
        Type: 'Inventory',
        IncomeAccountRef: {
          value: '21',
          name: 'Sales Income',
        },
        PurchaseDesc: '',
        PurchaseCost: '',
        ExpenseAccountRef: {
          value: '82',
          name: 'COGS- Raw Materials',
        },
        AssetAccountRef: {
          value: '250',
          name: 'Inventory Asset',
        },
        PrefVendorRef: {
          value: '',
          name: '',
        },
        TrackQtyOnHand: true,
        QtyOnHand: 0,
        ReorderPoint: 0,
        InvStartDate: qboDateString(new Date()),
        TaxClassificationRef: {
          value: 'EUC-09020802-V1-00120000',
          name: 'General taxable retail products - use this if nothing else fits',
        },
        domain: 'QBO',
        Id: '',
      } as unknown as IInventoryPart);
    }
  }, []);
  return (
    <>
      <InventoryDetailHeader />
      <DetailComponentWrapper>
        <PartDetailColumn>
          <PartDetailColumnHeader>Part Details</PartDetailColumnHeader>
          {inventoryPart && (
          <>
            {/* General Info */}
            <DetailRow width="60%">
              <PartNumberWrapper>
                <DetailInputWithCallback id="inventory-part-number" label="Part Number" placeholder="e.g., 150..." value={inventoryPart.Sku} callback={onChangePartNumber} disabled={isShopUser} />
                <QuickViewButton partSku={inventoryPart.Sku} />
              </PartNumberWrapper>
              {inventoryPart.Sku.length > 0 && !inventoryPart.Children && (
              <>
                <InventoryVendorName productCode={inventoryPart.ProductCode?.productCode} disabled={isShopUser} />
              </>
              )}
              <DetailActiveSelectorWithCallback
                initialState={inventoryPart.Active}
                callback={onChangeActive}
                disabled={isShopUser}
                componentLabel={null}
                checkedLabel="ACTIVE"
                uncheckedLabel="INACTIVE"
              />
            </DetailRow>
            <FlexColumn align="flex-start" style={{ gap: 8, width: '60%' }}>
              <DetailTextAreaWithCallback id="inventory-part-description" label="Description" placeholder="Enter a part description here" value={inventoryPart.Description} changeCallback={onChangePartText('Description')} blurCallback={onChangePartText('Description')} disabled={isShopUser} />
              <DetailTextAreaWithCallback id="inventory-part-notes" label="Part Notes" placeholder="Enter any notes about this part here" value={inventoryPart.notes} changeCallback={onChangePartText('notes')} blurCallback={onChangePartText('notes')} disabled={false} />
            </FlexColumn>
            <DetailRow>
              <InventoryProductCode disabled={isShopUser} productCode={inventoryPart.ProductCode?.productCode} callback={onProductCodeChange} />
              <GLRow>
                <DetailLabel
                  width="auto"
                  key="inventory-part-asset-gl-code"
                  label="Asset GL"
                  value={inventoryPart.ProductCode?.AssetAccountRef ? `${inventoryPart.ProductCode?.AssetAccountRef.accountNumber} - ${inventoryPart.ProductCode?.AssetAccountRef.name}` : 'N/A'}
                  extend={false}
                />
                <DetailLabel
                  width="auto"
                  key="inventory-part-expense-gl-code"
                  label="Expense GL"
                  value={inventoryPart.ProductCode?.ExpenseAccountRef ? `${inventoryPart.ProductCode?.ExpenseAccountRef.accountNumber} - ${inventoryPart.ProductCode?.ExpenseAccountRef.name}` : 'N/A'}
                  extend={false}
                />
                <DetailLabel
                  width="auto"
                  key="inventory-part-income-gl-code"
                  label="Income GL"
                  value={inventoryPart.ProductCode?.IncomeAccountRef ? `${inventoryPart.ProductCode?.IncomeAccountRef.accountNumber} - ${inventoryPart.ProductCode?.IncomeAccountRef.name}` : 'N/A'}
                  extend={false}
                />
              </GLRow>
            </DetailRow>
            <InventoryPartConsumedStep disabled={isShopUser} productCode={inventoryPart.ProductCode?.productCode} />

            {/* Costing Info */}
            <PartDetailColumnHeader marginTop={GAP}>Part Costing</PartDetailColumnHeader>
            <InventoryPartCosting partType={inventoryPart.ProductCode?.productCode} disabled={isShopUser} />

            {/* Stock Info */}
            {QBOItem.partType(inventoryPart.ProductCode?.productCode || '') === 'Inventory' && (
            <>
              <PartDetailColumnHeader marginTop={GAP}>Inventory Data</PartDetailColumnHeader>
              <InventoryStockInfo
                partChildren={inventoryPart.Children}
                onHand={inventoryPart.QtyOnHand}
                disabled={isShopUser}
                criticalPart={inventoryPart.criticalPart}
                reorderQty={inventoryPart.reorderQty}
                inventoryAdjustDate={inventoryPart.InvStartDate}
              />
              {!!inventoryPart.Children?.length && (
              <InventoryPartChildren partChildren={inventoryPart.Children} weightChangeCallback={onChangeChildPurchaseWeight} />
              )}

              {/* Allocation Info */}
              <PartDetailColumnHeader>BOM Allocation</PartDetailColumnHeader>
              <SubItemParentDescription disabled={isShopUser} />
            </>
            )}
          </>
          )}
        </PartDetailColumn>
      </DetailComponentWrapper>
    </>
  );
};

export default InventoryDetail;
