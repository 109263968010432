import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Modal, Progress } from 'antd';
import firebase from 'firebase';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NON_CONFORMANCE_COLLECTION } from 'shared/state/siteState';
import useFirebase from 'vendor/Firebase';
import { routerStepsAtom, scannerUserEmailsAtom } from 'shared/state/routingState';
import { currentNcRecordAtom, ncImageDataItemsAtom, ncScanDepartmentAtom } from 'shared/state/utilState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { currentShopOrderAtom, ORDER_ITEMS_DB_COLLECTION, ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { MESSAGE_DB_PATH_ATOM, nonConformanceNotificationUsersAtom } from 'shared/state/messageState';
import { IBomItem, INCRecord, IOrderItem } from 'shared/types/dbRecords';
import { PART_VIEWER_COLLECTION } from 'shared/state/partViewState';
import { nonConformanceNotification } from 'shared/messaging';
import { numberText } from 'shared/text';
import { devLog } from 'shared/util/logging';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import PartNonComplianceForm from './Components/PartNonComplianceForm';
import { IRouterStep } from '../ProductionSchedule/types';
import { IRunner, IShopOrder } from '../Orders/types';

const shortid = require('shortid');
const qs = require('qs');

const ScanButton = styled(Button)`
    height: 86px;
    width: 85%;
    border-radius: 12px;
    margin: 24px auto;

  @media ${theme.device.laptopL} {
    width: 460px;
    margin: 0 auto;
  } 
  `;

export default () => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const { database, firestore, storage } = useFirebase();
  const orderDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const ncRecordDbString = useRecoilValue(NON_CONFORMANCE_COLLECTION);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const scannerStationEmails = useRecoilValue(scannerUserEmailsAtom);

  // const alertUsers = ['keithh'];
  const routerSteps = useRecoilValue(routerStepsAtom);
  const ncImageDataItems = useRecoilValue(ncImageDataItemsAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const ncNotificationUsers = useRecoilValue(nonConformanceNotificationUsersAtom);
  const [shopOrder, setShopOrder] = useRecoilState<IShopOrder>(currentShopOrderAtom);
  const [ncRecord, setNcRecord] = useRecoilState<INCRecord>(currentNcRecordAtom);
  const [department, setDepartment] = useRecoilState(ncScanDepartmentAtom);
  const [salesOrderNumber, setSalesOrderNumber] = useState('');
  const [workOrderNumber, setWorkOrderNumber] = useState(1);
  const [isSaving, setIsSaving] = useState(false);
  const [uploadPercentComplete, setUploadPercentComplete] = useState(0);
  const [savingTextCount, setSavingTextCount] = useState(0);
  const [savingText, setSavingText] = useState('Saving.');

  const {
    editMode, orderId, runnerId, ncId,
  } = qs.parse(location.search.replace('?', ''));

  const preflightForm = () => ncRecord.department.length
    && ncRecord.shopOperator.length
    && ncRecord.partNumber?.length
    && ncRecord.quantity > 0
    && (((ncRecord.correctiveAction.match(/other/i) || ncRecord.reasonCode.match(/other/i)) && ncRecord.notes?.length)
        || (ncRecord.reasonCode.length && ncRecord.correctiveAction.length));

  const redirect = () => {
    if (_.includes(scannerStationEmails.emails, currentUser.email)) {
      window.location.href = '/scanner?ncRedirect=true';
    } else {
      Modal.confirm({
        title: 'Success!',
        okText: 'See Report',
        cancelText: 'Scan another',
        onOk: () => {
          if (_.includes(scannerStationEmails.emails, currentUser.email)) window.location.href = '/scanner?ncRedirect=true';
          else window.location.href = '/nc/report';
        },
        onCancel: () => {
          window.location.href = '/nc/scanner';
        },
      });
    }
  };

  const onSave = async (e: any) => {
    if (preflightForm()) {
      setIsSaving(true);
      const doc = await firestore.collection(partViewerDbString).doc(ncRecord.partNumber).get();
      const alertUsers = ncNotificationUsers;
      const partData = doc.data() as any;
      const dateRecorded = firebase.firestore.Timestamp.fromDate(new Date());
      const id = ncRecord.id?.length ? ncRecord.id : shortid.generate();

      const newRecord = { ...ncRecord, partDescription: partData?.Description || 'No part description available' };

      await firestore.collection(ncRecordDbString).doc(id).set(newRecord);
      const orderUpdateObject = {
        nonConformanceHistory: [
          ...shopOrder.nonConformanceHistory || [],
          { id, dateRecorded, reasonCode: ncRecord.reasonCode },
        ],
        runners: shopOrder.runners,
        partCount: shopOrder.partCount,
        orderValue: shopOrder.orderValue,
        notes: shopOrder.notes || shopOrder.description,
      };

      /*
       * AUTO CANCELLATION - The next block of code is intended to assist in removing parts
       * from play once they've been lost in production.
       */
      if (ncRecord.correctiveAction.match(/scrapped/i)) {
        const orderItemsDoc = await firestore.collection(orderItemsDbString).doc(shopOrder.id || orderId).get();
        const orderItems = orderItemsDoc.data() as { orderItems: IOrderItem[] };
        let updatedOrderItems = orderItems.orderItems;

        if (ncRecord.correctiveAction.match(/relaunch/i)) {
          // if the corrective action is "scrapped" and we are relaunching the part,
          // reset bom allocation, since we will need to reallocate any material already consumed
          updatedOrderItems = orderItems.orderItems.map((o: IOrderItem) => {
            if (o.Sku !== partData.Sku) return o;
            return {
              ...o,
              bom: o.bom.map((b: IBomItem) => ({
                ...b,
                quantityConsumed: 0,
              })),
            };
          });
        } else {
          updatedOrderItems = orderItems.orderItems.map((o: IOrderItem) => {
            if (o.Sku !== partData.Sku) return o;
            // if the corrective action is "scrapped" and we are not relaunching this part,
            // immediately cancel the appropriate quantity
            return {
              ...o,
              quantityOpen: o.quantityOpen - ncRecord.quantity,
              quantityCanceled: o.quantityCanceled + ncRecord.quantity,
            };
          });

          // update the order value and new part count accordingly.
          orderUpdateObject.orderValue = updatedOrderItems.map((o: IOrderItem) => o.quantityOpen * o.unitPrice).reduce((a, b) => a + b, 0);
          orderUpdateObject.partCount = updatedOrderItems.filter((o: IOrderItem) => o.Sku.match(/^[A-Z]{5}/))
            .map((o: IOrderItem) => o.quantityOpen * o.unitPrice).reduce((a, b) => a + b, 0);

          // if the corrective action is "scrapped" and we are not relaunching this part,
          // we will also want to decrement the quantity Assigned to the current work order,
          // so that future BOM consumption will be based on the remaining part count
          orderUpdateObject.runners = shopOrder.runners.map((r: IRunner) => {
            if (r.id !== runnerId) return r;
            return {
              ...r,
              parts: r.parts.map((p: IOrderItem) => {
                if (p.Sku !== partData.Sku) return p;
                return {
                  ...p,
                  quantityAssigned: p.quantityAssigned - ncRecord.quantity,
                };
              }),
            };
          });
          orderUpdateObject.partCount = orderUpdateObject.runners.map((r: IRunner) => {
            const parts = r.parts.map((p: IOrderItem) => p.quantityAssigned);
            return parts.reduce((a, b) => a + b, 0);
          }).reduce((a, b) => a + b, 0);
          orderUpdateObject.notes = `${orderUpdateObject.notes}\r**Lost ${ncRecord.quantity} ${ncRecord.partNumber} in process (at ${ncRecord.department}) - ${ncRecord.reasonCode}**`;
        }
        // finally, update the order items with the new data based on the scrap status
        await firestore.collection(orderItemsDbString).doc(shopOrder.id || orderId).set({
          orderItems: updatedOrderItems,
        });
      }

      // After auto-cancellation has run, update the order with the appropriate data
      await firestore.collection(orderDbString).doc(shopOrder.id || orderId).update(orderUpdateObject);

      const today = new Date();
      let month: string|number = today.getMonth() + 1;
      if (month < 10) month = `0${month}`;

      let date: string|number = today.getDate();
      if (date < 10) date = `0${date}`;

      const imageUrls: string[] = [];
      const totalBytes = ncImageDataItems.map((i) => i.size).reduce((a, b) => a + b, 0);
      let bytesProcessed = 0;
      const imagePercent = 50 / ncImageDataItems.length;

      const message = {
        subject: 'A non-conformance was reported.',
        message: `${ncRecord.shopOperator} reported a non-conformance for ${numberText(ncRecord.quantity)} ${ncRecord.partNumber}${ncRecord.quantity > 1 ? 's' : ''} on sales order #${ncRecord.salesOrder.orderNumber}.`,
        clickUrl: `/nc/report#${ncRecord.id}`,
      };

      if (ncImageDataItems.length) {
        devLog('NonComplianceEditor', 118, `Uploading ${ncImageDataItems.length} image(s) to google cloud`);

        ncImageDataItems.forEach((item: any, index) => {
          const path = `/qa_images/${shopOrder.customer.id || ncRecord.customerId}-${shopOrder.salesOrder || ncRecord.salesOrder.orderNumber}-${index}-${today.getFullYear()}${month}${date}-${today.getTime()}.jpg`;
          devLog(`Uploading ${path}`);

          const imageRef = storage.ref(path);
          const upload = imageRef.put(item);
          upload.on('state_changed',
            (s) => {
              devLog('NonComplianceEditor', 128, s.state);
              const progress = (s.bytesTransferred / s.totalBytes) * 100;
              bytesProcessed += s.bytesTransferred;
              devLog('NonComplianceEditor', 131, `${progress}% finished`);
              const uploadPercent = ((bytesProcessed * 0.95) / totalBytes) * 100;
              setUploadPercentComplete(Math.round(uploadPercent));
            }, (error) => devLog(error.message),
            () => {
              upload.snapshot.ref.getDownloadURL().then((urlPath) => {
                devLog('NonComplianceEditor', 137, urlPath);
                imageUrls.push(urlPath);
                devLog('NonComplianceEditor', 139, 'complete!');

                devLog('NonComplianceEditor', 141, `Processed ${imageUrls.length} out of ${ncImageDataItems.length}`);

                if (imageUrls.length === ncImageDataItems.length) {
                  firestore.collection(ncRecordDbString).doc(id).update({ imageUrls }).then(() => {
                    if (editMode.match(/edit/i)) {
                      return (() => {
                        window.location.href = '/nc/report';
                      })();
                    }
                    nonConformanceNotification(database, messageDbString, message, alertUsers).then(() => {
                      Modal.info({
                        content: 'Thanks! Please note the non-conformance on the order\'s shipping sheet.',
                        onOk: () => { window.location.href = '/nc/report'; },
                      });
                    });
                  });
                }
              });
            });
        });
      } else {
        nonConformanceNotification(database, messageDbString, message, alertUsers).then(() => {
          const modalMessage = editMode.match(/edit/i) ? 'Thanks for the update!' : 'Thanks! Please note the non-conformance on the order\'s shipping sheet.';
          Modal.info({
            content: modalMessage,
            onOk: () => { window.location.href = '/nc/report'; },
          });
        });
      }
    } else {
      Modal.error({
        title: 'Please fill out all fields (Notes optional).',
      });
    }
  };

  useEffect(() => {
    if (editMode.match(/edit/i)) {
      if (!ncId) {
        Modal.error({
          content: 'Uh-oh! Couldn\'t find the NC data for this record',
          okText: 'OK',
          onOk: () => { window.location.pathname = '/nc/record'; },
        });
      }
      firestore.collection(ncRecordDbString).doc(ncId).get().then((doc) => {
        setNcRecord(doc.data() as INCRecord);
      });
    } else {
      firestore.collection(orderDbString).doc(orderId).get().then((doc) => {
        const docData = doc.data() as IShopOrder;
        setShopOrder(docData);
        const wONumber = _.findIndex(docData.runners, (r: IRunner) => r.id === runnerId) + 1;
        setWorkOrderNumber(wONumber);
        setSalesOrderNumber(docData.salesOrder.split('-')[0]);
        const currentRunner = _.find(docData.runners, (r: IRunner) => r.id === runnerId);
        const routerStep = _.find(routerSteps, (rs: IRouterStep) => rs.id === currentRunner.step);
        setDepartment(routerStep.department);

        const recordId = shortid.generate();

        setNcRecord({
          id: recordId,
          salesOrder: { id: docData.id, orderNumber: docData.salesOrder },
          customerId: docData.customer.id,
          shopOperator: '',
          workOrder: wONumber,
          department: routerStep.department,
          partNumber: '',
          partDescription: '',
          quantity: 1,
          reasonCode: '',
          correctiveAction: '',
          notes: '',
          type: docData.type,
          dateRecorded: firebase.firestore.Timestamp.fromDate(new Date()),
          imageUrls: [],
        } as unknown as INCRecord);
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const text = uploadPercentComplete < 100 ? 'Saving' : 'Finishing';
      if (savingTextCount === 1) {
        setSavingText(`${text}.`);
        setSavingTextCount(2);
      } else if (savingTextCount === 2) {
        setSavingText(`${text}..`);
        setSavingTextCount(3);
      } else {
        setSavingText(`${text}...`);
        setSavingTextCount(1);
      }
    }, 400);
  }, [savingTextCount]);

  return (
    <FlexColumn>
      <PartNonComplianceForm runnerId={runnerId} />
      <FlexRow justify="flex-start">
        {isSaving ? (
          <FlexColumn>
            <h3 style={{ textAlign: 'left' }}>
              {savingText}
            </h3>
            <Progress percent={uploadPercentComplete} steps={ncImageDataItems.length * 4} />
          </FlexColumn>
        ) : (
          <ScanButton onClick={onSave} type="primary">{editMode.match(/edit/i) ? 'Save' : 'Submit'}</ScanButton>
        )}
      </FlexRow>
    </FlexColumn>
  );
};
