import {
  Button, Divider, Dropdown,
} from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { DownOutlined } from '@ant-design/icons/lib';
import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  DropdownOuterWrapper,
  // DropdownWrapper,
  ItemPrice,
} from 'shared/pageElements/styledComponents';
import { formatPrice } from 'shared/data';
import { hideUnselectedOptionsAtom, partEditModeAtom, useTestDataAtom } from 'shared/state/pricingState';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from '../../../../shared/containers/FlexContainer';
import theme from '../../../../shared/theme';

const shortid = require('shortid');

interface ComponentInterface {
  MenuNode: any;
  children?: any;
  title: string;
  price: number;
}

const DividerChildrenRow = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

const DividerChild = styled.h4`
    font-size: 16px;
    font-weight: 500;
    margin: 0;
`;
const DividerPrice = styled(DividerChild)`
    color: ${theme.palette.primary.hue};
`;
const DropdownWrapper = styled(FlexColumn)`
    align-items: flex-start;
    justify-content: center;
    
    @media ${theme.device.laptopL} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

export default ({
  MenuNode, title, price, children,
}: ComponentInterface) => {
  const useTestData = useRecoilValue(useTestDataAtom);
  const editMode = useRecoilValue(partEditModeAtom);

  return (
    <>
      {(editMode || price !== 0)
          && (
          <>
            <Divider orientation="left">
              <FlexRow style={{ gap: 2 }} justify="flex-start">
                <DividerChild>{title}</DividerChild>
                <DividerChild>&nbsp;-&nbsp;</DividerChild>
                <DividerPrice>{formatPrice(price)}</DividerPrice>
              </FlexRow>
            </Divider>
            <DropdownOuterWrapper>
              <DropdownWrapper>
                {MenuNode}
                {/* <ItemPrice testMode={useTestData} selected={price !== 0}> */}
                {/*  {formatPrice(price)} */}
                {/* </ItemPrice> */}
              </DropdownWrapper>
            </DropdownOuterWrapper>
              {children}
          </>
          )}
    </>
  );
};
