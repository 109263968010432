import React from 'react';
import styled from 'styled-components';
import { Button, notification, Tooltip } from 'antd';
import { ContentCopy } from '@styled-icons/material-twotone';

const CopyButton = styled(Button)`
   border: none;
`;

const CopyIcon = styled(ContentCopy)`
    width: 20px;
    margin-bottom: 4px;
`;

interface IComponent {
  trackingNumber: string;
}
const CopyTrackingNumberButton = ({ trackingNumber }: IComponent) => {
  const foo = 'bar';
  const onClick = (e: any) => {
    navigator.clipboard.writeText(trackingNumber)
      .then(() => {
        notification.open({
          message: 'Tracking Number copied!',
          description:
            'The tracking number has been copied to your clipboard.',
          placement: 'bottomRight',
          className: 'data-copy-notification',
        });
        navigator.clipboard.readText();
      });
  };
  return (
    <Tooltip placement="right" title="Copy tracking number">
      <CopyButton shape="circle" type="default" icon={<CopyIcon />} onClick={onClick} />
    </Tooltip>
  );
};

export default CopyTrackingNumberButton;
