import React, { useState } from 'react';
import { Drawer, Modal } from 'antd';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { showSettingsCenterAtom } from 'shared/state/messageState';
import SettingsCenter from './SettingsCenter';

const ComponentDrawer = styled(Drawer)`
  height: 100vh;
`;
const SettingsDrawer = () => {
  const [showSettingsCenter, setShowSettingsCenter] = useRecoilState(showSettingsCenterAtom);
  const [needsReload, setNeedsReload] = useState<boolean>(false);
  const onClose = (e: any) => {
    if (needsReload) {
      return Modal.confirm({
        title: 'Reload to apply settings',
        content: 'Some changes to your user settings require a reload to take effect. Reload now?',
        onOk: () => {
          window.location.reload();
        },
        onCancel: () => {
          setShowSettingsCenter(false);
        },
      });
    }
    setShowSettingsCenter(false);
  };

  const triggerReload = () => {
    setNeedsReload(true);
  };

  return (
    <ComponentDrawer
      open={showSettingsCenter}
      placement="right"
      width="35vw"
      height="100vh"
      onClose={onClose}
      title="Settings"
    >
      <SettingsCenter reloadCallback={triggerReload} />
    </ComponentDrawer>
  );
};

export default SettingsDrawer;
