import axios from 'axios';
import { devLog } from 'shared/util/logging';
import { isDevSite, useTestData } from 'shared/util';
import API_BASE_URL from './index';

const authUri = (): Promise<string> => new Promise((resolve, reject) => {
  let apiUrl = `${API_BASE_URL}/authUri`;
  if (isDevSite()) apiUrl = `${API_BASE_URL}/authUri-dev`;
  if (useTestData) apiUrl = `${API_BASE_URL}/authUri-staging`;

  axios.get(apiUrl)
    .then((authUrl) => {
      resolve(authUrl.data as unknown as string);
    })
    .catch((e) => {
      console.log(e);
      reject(e);
    });
});

const retrieveToken = ():Promise<string> => new Promise((resolve, reject) => {
  axios.get(`${API_BASE_URL}/retrieveToken`).then((data) => {
    resolve(data.data);
  });
});
const refreshToken = (database: any, authPath: string): Promise<Object|string> => new Promise((resolve, reject) => {
  // const _refreshToken = localStorage.getItem('qbo.refreshToken');
  database.ref(authPath).once('value').then((authData) => {
    const _refreshToken = authData.val().refresh_token;
    const url = _refreshToken ? `${API_BASE_URL}/refreshAccessToken?token=${_refreshToken}` : `${API_BASE_URL}/refreshAccessToken`;
    axios.get(url)
      .then((data) => {
        database.ref(authPath).set(data.data).then(() => {
          devLog('qboApi:refreshToken', 32, data.data);
          localStorage.setItem('qbo.refreshToken', data.data.refresh_token);
          resolve(data.data);
        });
      })
      .catch((e) => {
        devLog('qboApi:refreshToken', 37, e);
        reject(e);
      });
  });
});

export default {
  authUri,
  retrieveToken,
  refreshToken,
};
