import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { includes, sortBy } from 'lodash';
import PartDetailDropdown from 'shared/components/Input/PartDetailDropdown';
import { inventoryPartEditedAtom } from 'shared/state/inventoryState';
import { configToDescription } from 'shared/partParser/util';
import JumpToParentButton from '../Buttons/JumpToParentButton';

interface IComponent {
  disabled: boolean;
  customerParts: any[];
  partType: string;
  partState: any;
  callback: (parentSku: string) => void;
}
const PartParentDropdown = ({
  customerParts, partState, partType, disabled, callback,
}: IComponent) => {
  // @ts-ignore
  const setEdited = useSetRecoilState(inventoryPartEditedAtom);
  const [_partState, setPartState] = useState<any>(partState);
  const [parentOptions, setParentOptions] = useState<{ label: string; key: string; }[]>([]);
  const [type, setType] = useState<string>(partType);
  const [_LabelExtra, setLabelExtra] = useState<any>(<div />);

  const onParentChange = (e: any) => {
    setEdited(true);
    const parentSku = e.split('-')[0];
    // @ts-ignore
    callback(parentSku);
  };

  useEffect(() => {
    const options = sortBy(customerParts
      .filter((i: any) => i.type === configToDescription(_partState.config).replace('COPY_', '').substring(0, 2))
      .filter((i: any) => !i.parent)
      .map((i: any) => (
        { label: configToDescription(i.config), key: i.Sku }
      )), (e: { label: string, key: string }) => e.key);
    // @ts-ignore
    setParentOptions(options);
    setPartState(partState);
    if (partState.parent) {
      setLabelExtra(<JumpToParentButton sku={_partState.parent?.Sku || null} partType={includes(['GB', 'BB'], _partState.type) ? 'body' : 'neck'} border={false} description="Go to parent record" />);
    }
  }, [customerParts, partState]);

  useEffect(() => {
    setType(partType);
  }, [partType]);

  useEffect(() => {
    setPartState(partState);
  }, [partState]);

  return (
    <PartDetailDropdown
      changeCallback={onParentChange}
      disabled={disabled || false}
      id="inventory-part-allocated-part-selector"
      label="Parent part"
      LabelExtra={_LabelExtra}
      placeholder="Select part"
      // @ts-ignore
      value={_partState.parent?.Sku || ''}
      valueOptions={parentOptions}
    />
  );
};

export default PartParentDropdown;
