import React, { useEffect, useState } from 'react';
import {
  RadioGroupWrapper,
  ViewTypeRadioButton,
  ViewTypeRadioGroup,
  ViewTypeSelect,
  ViewTypeSelectLabel,
  ViewTypeSelectOption,
} from 'shared/styledComponents/inputs';
import { ViewTypeSelectWrapper } from 'shared/styledComponents/containers';

import { useRecoilValue } from 'recoil';
import ScopedComponent from '../../../../shared/components/Utility/ScopedComponent';
import { currentCustomerAtom } from '../../../../shared/state/customerState';

interface IComponent {
  viewType: 'week'|'customer'|'up-next'|'requirements';
}
const ScheduleViewSelector = ({ viewType }: IComponent) => {
  // @ts-ignore
  const [scheduleViewType, setScheduleViewType] = useState<'week'|'customer'|'up-next'|'requirements'>(window.location.pathname.split('/')[2]);
  const currentCustomer = useRecoilValue(currentCustomerAtom);

  const onChangeView = (e: any) => {
    const typeValue = e.target?.value || e;
    localStorage.setItem('salesOrderViewType', typeValue);
    window.location.href = `/schedule/${typeValue}`;
  };

  useEffect(() => {
    const orderView = window.location.pathname.split('/')[2];
    setScheduleViewType(orderView as 'week'|'customer'|'up-next'|'requirements');
  }, []);

  return (
    <RadioGroupWrapper>
      <ViewTypeSelectWrapper align="center" justify="flex-start">
        <ViewTypeSelectLabel>View Type</ViewTypeSelectLabel>
        <ViewTypeSelect
          label="View type"
          size="default"
          value={scheduleViewType}
          onChange={onChangeView}
          marginLeft="0"
        >
          <ViewTypeSelectOption value="week">Week</ViewTypeSelectOption>
          <ViewTypeSelectOption value="customer">Customer</ViewTypeSelectOption>
          <ViewTypeSelectOption value="up-next">Up next</ViewTypeSelectOption>
          <ViewTypeSelectOption value="requirements">Job reqs</ViewTypeSelectOption>
        </ViewTypeSelect>
      </ViewTypeSelectWrapper>
      <ViewTypeRadioGroup value={scheduleViewType} buttonStyle="solid" onChange={onChangeView} marginLeft="0px">
        <ViewTypeRadioButton
          value="week"
        >
          Week
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="customer"
        >
          Customer
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="up-next"
        >
          Up next
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="requirements"
        >
          Job reqs
        </ViewTypeRadioButton>
      </ViewTypeRadioGroup>
    </RadioGroupWrapper>
  );
};

export default ScheduleViewSelector;
