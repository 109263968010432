import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { Button, Collapse, Divider } from 'antd';
import type { CollapseProps } from 'antd';
import { IOrderItem, IShipment } from 'shared/types/dbRecords';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { formatPrice } from 'shared/data';
import OrderShipmentItem from './OrderShipmentItem';
import theme from '../../../../shared/theme';
import CopyTrackingNumberButton from './Buttons/CopyTrackingNumber';
import { stageRedirect } from '../../../../shared/util';

const { Panel } = Collapse;

interface IComponent {
  shipment: IShipment;
  orderId: string;
}

const ComponentWrapper = styled(FlexColumn)`
  width: 75%;
  align-items: flex-start;
`;

const DataRow = styled(FlexRow)`
  width: calc(100% - 16px);
  justify-content: space-between;
  padding: 0 8px;
  gap: 12px;
`;

const OrderShipmentCollapse = styled(Collapse)`
  width: 100%;
`;

const OrderLabel = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border: none;
  background-color: ${(props: { complete: boolean }) => (props.complete ? theme.palette.neutral[500] : theme.palette.primary.hue)};
  color: #FFFFFF;
  border-radius: 4px 4px 0 0;
  padding-left: 16px;
  margin-bottom: 16px;
  & h3 {
    margin: 0;
    font-size: 22px;
  }
  & p {
    color: white;
  }
`;

const HeaderDetailsRow = styled(FlexRow)`
  justify-content: flex-end;
  gap: 16px;
  margin: 0 0 0 auto;
`;
const TrackingNumberWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 8px;
`;
const TrackingCarrier = styled.h3`
    margin: 0;
`;
const TrackingLink = styled.a``;

const EditShipmentButton = styled(Button)`
    margin-left: 16px;
    height: 28px;
    line-height: 14px;
    border-radius: 8px;
`;
const OrderShipment = ({ shipment, orderId }: IComponent) => {
  const id = shortid.generate();
  const trackingNumber = shipment.trackingNumber ? shipment.trackingNumber : '';
  let trackingNumberString = null;
  let trackingHref = null;
  let trackingCarrier = 'UPS';
  if (trackingNumber.length > 0) {
    trackingCarrier = shipment.trackingNumber.match(/1Z/) ? 'UPS' : 'FedEx';
    trackingNumberString = shipment.trackingNumber;
    trackingHref = trackingCarrier === 'UPS'
      ? `https://www.ups.com/track?track=yes&trackNums=${shipment.trackingNumber}&loc=en_US`
      : `https://www.fedex.com/fedextrack/?tracknumbers=${shipment.trackingNumber}`;
  }

  const onEditShipment = (e: any) => {
    e.preventDefault();
    stageRedirect();
    window.location.href = `/shipments/edit?shipmentId=${shipment.id}&orderId=${orderId}`;
  };
  return (
    <ComponentWrapper>
      <OrderLabel key={`${id}-component-label`}>
        <h3 key={`${id}-component-label-h3`}>{`Shipment ${shipment.shipmentNumber}`}</h3>
        <EditShipmentButton onClick={onEditShipment}>Edit</EditShipmentButton>
        <HeaderDetailsRow>
          <h3 key={`${id}-shipment-date`}>{shipment.shipDate.toDate().toLocaleDateString()}</h3>
          <h3 key={`${id}-shipment-value`}>{formatPrice(shipment.value)}</h3>
        </HeaderDetailsRow>
      </OrderLabel>
      <Divider orientation="left">Tracking</Divider>
      {trackingNumberString && (
        <TrackingNumberWrapper>
          <TrackingCarrier>{trackingCarrier}</TrackingCarrier>
          <TrackingLink href={trackingHref} target="_blank" rel="noreferrer">{trackingNumberString}</TrackingLink>
          <CopyTrackingNumberButton trackingNumber={trackingNumber} />
        </TrackingNumberWrapper>
      )}
      <Divider orientation="left">Parts shipped</Divider>
      <>
        {shipment.shippedItems.map((o: IOrderItem, index: number) => <OrderShipmentItem item={o} rowNumber={index} />)}
      </>
      <Divider orientation="left">Shipment notes</Divider>
      <p>{shipment.notes}</p>
    </ComponentWrapper>
  );
};

export default OrderShipment;
