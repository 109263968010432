import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { every, find, sortBy } from 'lodash';
import { Select } from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FlexRow } from 'shared/containers/FlexContainer';
import {
  currentCustomerAtom, currentCustomerShippingAddressesAtom,
  CUSTOMER_SHIPPING_DB_COLLECTION,
  customersAtom,
  selectedCustomerShippingAddressAtom,
} from 'shared/state/customerState';
import { currentShopOrderAtom } from 'shared/state/orderState';
import { customerPartsAtom } from 'shared/state/pricingState';
import { ItemDetailsColumnWrapper, ItemDetailsLabel, SearchSelect } from 'shared/styledComponents/inputs';
import { ICustomerRecord, IShippingAddress } from 'shared/types/dbRecords';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import { PART_VIEWER_COLLECTION } from '../../../../shared/state/partViewState';

interface IComponent {
  customerId: string;
  changeCallback: (e: any) => void;
}
interface IValueOption {
  label?: string;
  keycode: string;
  shade: boolean;
}
const PartDetailDropdownItemRow = styled(FlexRow)`
  width: 600px;
  height: 100%;
  justify-content: flex-start;
  padding: 0 12px;
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.03)' : 'transparent')};
  border-bottom: 1px solid lightgrey;
  & p {
    color: black;
    margin: 0;
    &:first-of-type {
      font-weight: bold;
      width: 120px;
    }
  }
  & span {
    width: 1px;
    height: 100%;
    margin: 0 8px;
    background-color: lightgrey;
  }
`;
const OrderCustomerSearchSelect = ({ changeCallback, customerId }: IComponent) => {
  const [currentOptions, setCurrentOptions] = useState<{ label: Element, value: string}[]>([]);
  // const [currentCustomer, setCurrentCustomer] = useState<string|undefined>(undefined);
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const customers = useRecoilValue(customersAtom);
  const { firestore } = useFirebase();
  const partViewerDbCollection = useRecoilValue(PART_VIEWER_COLLECTION);
  const shippingAddressDbString = useRecoilValue(CUSTOMER_SHIPPING_DB_COLLECTION);
  const setCustomerParts = useSetRecoilState(customerPartsAtom);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const setSelectedShippingAddress = useSetRecoilState(selectedCustomerShippingAddressAtom);
  const setCurrentCustomerShippingAddresses = useSetRecoilState(currentCustomerShippingAddressesAtom);

  const onChangeSearch = (value: unknown) => {
    const customer = typeof (value) === 'string' ? value as string : value.target.value as string;
    const customerName = customer.slice(5);
    const cust = find(customers, (c: ICustomerRecord) => c.CompanyName === customerName) as ICustomerRecord;
    setCurrentCustomer(cust);
    localStorage.setItem('currentCustomerId', cust.id);
  };
  const onSelect = (value: unknown, option: IValueOption) => {
    const customer = (value as string).toString();
    // customerId is a mashup of customer id and shortname
    // the first five characters are the partNumber.
    const _customerId = customer.slice(0, 5);
    const _customerName = customer.slice(5);
    const cust = find(customers, (c: ICustomerRecord) => c.CompanyName === _customerName) as ICustomerRecord;
    setCurrentCustomer(cust);
    setCurrentShopOrder({ ...currentShopOrder, customer: cust });
    firestore.collection(partViewerDbCollection).where('customer', '==', _customerId).get().then((snap) => {
      const parts: any[] = [];
      snap.forEach((doc) => {
        const data = doc.data();
        parts.push(data);
      });
      // @ts-ignore
      setCustomerParts(parts);
      firestore.collection(shippingAddressDbString).doc(_customerId).get().then((doc) => {
        const shipData = doc.data();
        if (!shipData) return;
        setCurrentCustomerShippingAddresses(shipData.shippingAddresses);
        setSelectedShippingAddress(find(shipData.shippingAddresses, (a: IShippingAddress) => a.default));
      });
    });
  };
  const PartDetailDropdownMenuItem = ({ keycode, shade, label = undefined }: IValueOption) => (
    <PartDetailDropdownItemRow key={`${keycode}-value-option`} id={`${keycode}-value-option`} shade={shade}>
      <p>{keycode}</p>
      <span />
      <p>{label}</p>
    </PartDetailDropdownItemRow>
  );
  useEffect(() => {
    const customerRecords = sortBy(customers.filter((c: ICustomerRecord) => c.active), (c: ICustomerRecord) => c.id);
    // @ts-ignore
    const items = customerRecords
      .map((c, index) => ({
        label: <PartDetailDropdownMenuItem label={c.CompanyName} keycode={c.id} shade={index % 2 === 1} />,
        value: `${c.id}${c.CompanyName}`,
      }));
    // @ts-ignore
    setCurrentOptions(items);
    return () => { };
  }, [customerId]);
  return (
    <ItemDetailsColumnWrapper key="customer-search-select-item-details-wrapper">
      <ItemDetailsLabel key="customer-search-select-item-details-label">Customer</ItemDetailsLabel>
      <SearchSelect
        transparent={false}
        showSearch
        dropdownStyle={{ width: 600, minWidth: 600 }}
        // @ts-ignore
        onChange={onChangeSearch}
        // disabled={!!window.location.href.match(/edit/)}
        id="order-customer-search-dropdown"
        key="order-customer-item-search-dropdown"
        placeholder="Select a customer"
        // @ts-ignore
        onSelect={onSelect}
        options={currentOptions}
        value={currentShopOrder.customer.CompanyName}
        filterOption={(input, option) => {
          const terms = input.split(',').map((s) => s.trim());
          const matchString = option?.value;
          const matches = terms.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
          return every(matches, Boolean);
        }}
        filterSort={(optionA, optionB) =>
          (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default OrderCustomerSearchSelect;
