import React, { useContext, useState } from 'react';
import {
  chunk, find, includes, omit,
} from 'lodash';
import { CSVDownload } from 'react-csv';
import {
  Progress, Modal, Upload, Tooltip, Button,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Upload as UploadIcon } from '@styled-icons/fa-solid';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import theme from 'shared/theme';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom, showQuickbooksSyncModalAtom } from 'shared/state/inventoryState';
import { devLog } from 'shared/util/logging';
import { glCodesAtom, productCodesAtom } from 'shared/state/utilState';
import { VENDOR_COLLECTION } from 'shared/state/vendorState';
import { IVendor } from 'shared/types/vendor';
import { IInventoryPart, IUploadItem, IProductCode } from 'shared/types/dbRecords';
import QBOItem from 'shared/data/QBO/item';
import QBOInventoryAdjustment from 'shared/data/QBO/inventoryAdjustment';
import { reorderPointNotification } from '../../../../shared/messaging';
import { MESSAGE_DB_PATH_ATOM, reorderPointNotificationUsersAtom } from '../../../../shared/state/messageState';
import { IItemAdjustment, IQBOItem, IQuantityAdjustmentItem } from '../../../../shared/types/qbo';
import { AuthContext } from '../../../../vendor/Firebase/AuthProvider';
import AdjustQBOInventoryModal from '../Panels/AdjustQBOInventoryModal';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const UploadButton = styled(Button)`
  flex-grow: 2;
  border-radius: 8px;
`;
const SaveSpinner = styled.div`
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-left: 32px;

  border-radius: 50%;
  border: 4px solid ${theme.palette.neutral[100]};
  border-top: 4px solid ${theme.palette.primary.hue};

  animation: ${rotation} 1s linear infinite;
`;

// eslint-disable-next-line no-shadow
enum DATA_COLUMNS {
  ACTIVE,
  SKU,
  DESCRIPTION,
  QTY_ON_HAND, // not used in import
  QTY_COUNTED,
  DIFFERENCE, // not used in import
  PURCHASE_UNIT,
  PRODUCT_CODE,
  GL_CODE,
  VENDOR,
  PURCHASE_COST,
  UNIT_PRICE,
  MARGIN,
  REORDER_QTY
}
const ImportInventoryButton = () => {
  const { currentUser } = useContext(AuthContext);
  const { firestore, database } = useFirebase();
  const inventoryItemsDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const reorderItemNotificationUsers = useRecoilValue(reorderPointNotificationUsersAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const productCodes = useRecoilValue(productCodesAtom);
  const vendorsDbString = useRecoilValue(VENDOR_COLLECTION);
  const setShowQboSyncModal = useSetRecoilState(showQuickbooksSyncModalAtom);

  const [processedPercent, setProcessedPercent] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [qboCsvData, setQboCsvData] = useState<any>([]);

  const onClickDownload = (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      setQboCsvData([]);
      Modal.confirm({
        title: 'Refresh Inventory Data',
        content: 'Reload the page to see changes?',
        onOk: () => {
          window.location.reload();
        },
      });
    }, 1000);
  };

  const onUpload = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const allQBOInventory = await QBOItem.fetchAllInventoryParts();
      const vendorDocs = await firestore.collection(vendorsDbString).get();
      const vendors = vendorDocs.docs.map((d) => d.data());
      const shopSupplyProductCode = find(productCodes, (p: IProductCode) => p.productCode === 'SHOPSUP');
      const data = e.target.result
        .split('\n')
        .slice(1) // discard headers
        .map((d: string) => d.trim().replace(/"/g, '').split(','))
        .map((d: string[]) => {
          const Sku = parseFloat(d[DATA_COLUMNS.SKU]).toString();
          const ProductCode = find(productCodes, (p) => p.productCode === d[DATA_COLUMNS.PRODUCT_CODE]);
          const vendor = find(vendors, (v: IVendor) => v.DisplayName === d[DATA_COLUMNS.VENDOR]) as IVendor;
          const PurchaseCost = Number.isNaN(parseFloat(d[DATA_COLUMNS.PURCHASE_COST])) ? 0 : parseFloat(d[DATA_COLUMNS.PURCHASE_COST]);
          const UnitPrice = Number.isNaN(parseFloat(d[DATA_COLUMNS.UNIT_PRICE])) ? 0 : parseFloat(d[DATA_COLUMNS.UNIT_PRICE]);
          const reorderQty = Number.isNaN(parseFloat(d[DATA_COLUMNS.REORDER_QTY])) ? 0 : parseFloat(d[DATA_COLUMNS.REORDER_QTY]);

          const qboItem = find(allQBOInventory, (i: IQBOItem) => i.Sku === Sku) as IQBOItem;
          /*
            QtyOnHand is a special case, because if we are uploading a counted item, the value will be in the "QTY_COUNTED" column.
            If we are uploading a sheet for some other reason, the value of "QTY_ON_HAND" needs to be used. Ultimately,
            the "QTY_COUNTED" column takes precedence over "QTY_ON_HAND" so resolve the value in that order.
           */
          let QtyOnHand = 0;
          const qtyOnHand = parseFloat(d[DATA_COLUMNS.QTY_ON_HAND]);
          const qtyCounted = parseFloat(d[DATA_COLUMNS.QTY_COUNTED]);
          if (!Number.isNaN(qtyOnHand)) QtyOnHand = qtyOnHand;
          if (!Number.isNaN(qtyCounted)) QtyOnHand = qtyCounted;

          const uploadItem = {
            Active: includes(['Y', 'YES', 'FOR SURE', 'FUCKING TOTALLY', 'TRUE'], d[DATA_COLUMNS.ACTIVE]),
            Sku,
            Name: Sku,
            FullyQualifiedName: Sku,
            Description: d[DATA_COLUMNS.DESCRIPTION] || 0,
            ProductCode: ProductCode || shopSupplyProductCode,
            PurchasingUnit: d[DATA_COLUMNS.PURCHASE_UNIT] || 'EA',
            PurchaseCost,
            UnitPrice,
            QtyOnHand,
            reorderQty,
            adjustmentQuantity: QtyOnHand - (qboItem?.QtyOnHand || 0),
          } as IUploadItem;
          if (vendor) {
            uploadItem.PrefVendorRef = { name: vendor.DisplayName, value: vendor.Id };
          }
          return uploadItem;
        });

      const partCount = data.length;
      // const qboDownloadData = [['Product Name', 'Sku', 'Quantity On Hand', 'Taxable', 'Income Account', 'Expense Account']];
      let processedCount = 0;
      setUploadInProgress(true);

      const finishUp = () => {
        setProcessedPercent(Math.ceil((processedCount / partCount) * 100));
        if (processedCount === partCount) {
          devLog('InventoryPartUploader', 92, 'Upload complete. Processing quantity adjustments in QBO...');
          setShowQboSyncModal(true);
          setTimeout(() => {
            setUploadInProgress(false);
          }, 2000);
        }
      };

      data.forEach((o: IUploadItem) => {
        const part = find(inventoryItems, (p: IInventoryPart) => p.Sku === o.Sku);
        if (!part) {
          processedCount += 1;
          finishUp();
        } else {
          const helmUpdateObject = omit(o, ['adjustmentQuantity']);
          // @ts-ignore -- we are handling the case where part is undefined above.
          firestore.collection(inventoryItemsDbString).doc(part.Sku).update({ ...helmUpdateObject }).then(() => {
            processedCount += 1;
            if (o.QtyOnHand <= part.reorderQty && part.reorderQty > 0) {
              reorderPointNotification(database, messageDbString, reorderItemNotificationUsers, { ...part, QtyOnHand: o.QtyOnHand });
            }
            // @ts-ignore -- we are handling the case where part is undefined above.
            // qboDownloadData.push([part.Name, part.Sku, part.QtyOnHand, 'Yes', part.ProductCode.AssetAccountRef.name, part.ProductCode.ExpenseAccountRef.name]);
            finishUp();
          });
        }
      });
    };

    reader.readAsText(file);

    return false;
  };

  return (
    <>
      {uploadInProgress ? (
        <>
          {processedPercent >= 1 ? (
            <Progress type="circle" percent={processedPercent} width={64} style={{ marginLeft: 24 }} />
          ) : (
            <SaveSpinner />
          )}
        </>
      ) : (
        <>
          {qboCsvData.length > 0 ? (
            <CSVDownload data={qboCsvData} target="_self" />
          ) : (
            <Tooltip placement="top" title="Upload an inventory list using the same format as the downloaded file. Items not present in the database will be ignored.">
              <Upload
                accept=".txt, .csv"
                showUploadList={false}
                beforeUpload={onUpload}
              >
                <UploadButton icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}>
                  Upload
                  .CSV
                </UploadButton>
              </Upload>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

export default ImportInventoryButton;
