import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Switch } from 'antd';
import { FlexRow } from 'shared/containers/FlexContainer';
import { ItemDetailsLabel } from 'shared/styledComponents/inputs';
import HelpButton from '../../../shared/components/Utility/HelpButton';

const SettingRow = styled(FlexRow)`
    width: 100%;
    justify-content: space-between;
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

interface IComponent {
  label: string;
  helpMessage: string;
  value: boolean;
  storageKey: string;
  reloadRequired: boolean;
  callback: (key: string, value: boolean, reloadRequired: boolean) => void;
}

const BooleanSetting = ({
  label, storageKey, value, callback, helpMessage, reloadRequired = false,
}: IComponent) => {
  const [switchState, setSwitchState] = useState<boolean>(value || false);
  const onToggle = (e: any) => {
    const newState = !switchState;
    setSwitchState(newState);
    callback(storageKey, newState, reloadRequired);
  };

  useEffect(() => {
    setSwitchState(value);
  }, [value]);
  return (
    <SettingRow key={`setting-${label}-row-container`}>
      <LabelWrapper>
        <ItemDetailsLabel key={`setting-${label}-label`}>{label}</ItemDetailsLabel>
        <HelpButton
          style={{ marginBottom: 2 }}
          helpMessage={helpMessage}
        />
      </LabelWrapper>
      <Switch key={`setting-${label}-switch`} onClick={onToggle} checked={switchState} />
    </SettingRow>
  );
};

export default BooleanSetting;
