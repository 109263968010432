import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';

const PartNotesInput = styled(Input.TextArea)`
  width: 100%; 
  height: 64px;
  line-height: 16px;
`;

interface IComponent {
  // partNotes: string;
  // open: boolean;
  orderItem: IOrderItem;
  callback: (notes: string) => void;
}
const PartNotes = ({ orderItem, callback }: IComponent) => {
  const { open } = orderItem;
  const [notes, setNotes] = useState<string>(orderItem.notes || '');
  const onChange = (e: any) => {
    setNotes(e.target.value);
  };
  const onBlur = (e: any) => {
    setNotes(e.target.value);
    if (callback) callback(e.target.value);
  };

  useEffect(() => {
    setNotes(orderItem.notes);
  }, [orderItem]);

  return (
    <ItemDetailsColumnWrapper key="part-notes-item-details-wrapper">
      <ItemDetailsLabel key="part-notes-item-details-label">Notes</ItemDetailsLabel>
      <PartNotesInput
        key="part-Notes-input"
        id="part-Notes-input"
        placeholder="Part Notes"
        value={notes}
        onChange={onChange}
        onBlur={onBlur}
        disabled={!open}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default PartNotes;
