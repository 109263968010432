import React, { useState, useEffect } from 'react';
import {
  Document, Font, Page, PDFViewer, StyleSheet, View, Text,
} from '@react-pdf/renderer';
import { IQATicket } from 'shared/types/dbRecords';
import useFirebase from 'vendor/Firebase';
import NCHeader from './Components/NCReport/NCHeader';

Font.register({
  family: 'Red Hat Display-300',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg.ttf',
});

Font.register({
  family: 'Red Hat Display',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-700',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-600',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

const HORIZONTAL_PADDING = 18;
const VERTICAL_PADDING = 36;

const style = StyleSheet.create({
  viewer: {
    width: '75vw',
    height: '100vh',
  },
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: VERTICAL_PADDING * 1.5,
    paddingLeft: HORIZONTAL_PADDING,
    paddingRight: HORIZONTAL_PADDING * 2,
    paddingBottom: VERTICAL_PADDING,
  },
  confirmationBody: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: -4,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 7,
    bottom: 24,
    left: 0,
    right: 24,
    textAlign: 'right',
    color: 'grey',
  },
  notesBuffer: {
    height: 8,
    width: '100%',
  },

});
interface IComponent {
  qaTicket: IQATicket;
}

const NCReportPDF = ({ qaTicket }: IComponent) => {
  const [_qaTicket, setQaTicket] = useState<IQATicket>(qaTicket);
  const { firestore } = useFirebase();

  useEffect(() => {
    setQaTicket(qaTicket);
  }, [qaTicket]);

  return (
    <>
      {(_qaTicket && (
        <>
          <PDFViewer showToolbar style={style.viewer}>
            <Document title={`Non-conformance Report #${_qaTicket.id}`}>
              <Page size="LETTER" style={style.page}>
                <NCHeader qaTicket={_qaTicket} />
                <View style={style.confirmationBody} />
                <Text
                  style={style.pageNumber}
                  render={({ pageNumber, totalPages }) => (
                    totalPages > 1 ? `Page ${pageNumber} of ${totalPages}` : ''
                  )}
                  fixed
                />
              </Page>
            </Document>
          </PDFViewer>
        </>
      ))}
    </>
  );
};

export default NCReportPDF;
