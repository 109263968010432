import React, { useState } from 'react';
import { Bolt } from '@styled-icons/material';
import styled from 'styled-components';
import { Button, Popover } from 'antd';
import theme from 'shared/theme';
import QuickViewOptions from '../QuickViewOptions';

const ButtonPopover = styled(Popover)`
  & .ant-popover-inner {
    border-radius: 12px !important;
  }
`;

const BoltIcon = styled(Bolt)`
    width: 26px;
    //color: ${theme.palette.neutral[800]};
    color: ${theme.palette.warning.hue};
    transition: color 0.2s ease-in-out;
`;
const ComponentButton = styled(Button)`
    width: 32px;
    height: 32px;
    border: none;
    background: transparent;
    margin: 8px 0 0 -4px;
    
    &: hover {
        background: transparent;
    }
    & ${BoltIcon} {
        &:hover {
            color: ${theme.palette.warning.D100};
        }
    }
`;

interface IComponent {
  partId: string;
}

const QuickViewButton = ({ partId }: IComponent) => {
  const [open, setOpen] = useState<boolean>(false);
  const onClick = (e: any) => {
    setOpen(true);
  };
  const onQuickViewOpen = () => {
    setOpen(false);
  };

  return (
    <ButtonPopover open={open} trigger="click" placement="right" content={<QuickViewOptions partId={partId} onQuickViewOpenCallback={onQuickViewOpen} />}>
      <ComponentButton
        shape="circle"
        icon={<BoltIcon />}
        onClick={onClick}
      />
    </ButtonPopover>
  );
};

export default QuickViewButton;
