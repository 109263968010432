import { stepForPart } from '../router/utils';
import { IBomItem, IInventoryPart } from '../types/dbRecords';

const inventoryPartToBomItem = (i: IInventoryPart, partType: 'body'|'neck'): IBomItem => ({
  Description: i.Description,
  isPurchased: true,
  lastModDate: i.MetaData?.LastUpdatedTime || '',
  // lastModUser: i.lastModUser,
  Sku: i.Sku,
  // purchaseFactor: i.purchaseFactor,
  quantity: 1,
  quantityConsumed: 0,
  stepId: stepForPart(i.Sku, partType),
  totalCost: i.PurchaseCost,
  totalPrice: i.UnitPrice,
  totalQuantity: 1,
  uniqueID: i.Id,
  unit: i.PurchasingUnit || 'EA',
  unitCost: i.PurchaseCost,
  unitPrice: i.UnitPrice,
  vendor: i.Vendor?.vendorCode || '',
});

export default inventoryPartToBomItem;
