import React from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { Category } from '@styled-icons/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { priceSearchQueryAtom } from 'shared/state/pricingState';
import { cleanMeta } from '../../../../../../shared/text';
import { PART_VIEWER_COLLECTION } from '../../../../../../shared/state/partViewState';
import useFirebase from '../../../../../../vendor/Firebase';
import { geometricDescriptionParts } from '../../../../../../shared/partParser/util';

interface IComponent {
  sku: string;
  type: 'neck'|'body';
}

const ShapeIcon = styled(Category)`
  width: 20px;
  margin-bottom: 2px;
`;
const SearchGeometricPartButton = ({ sku, type }: IComponent) => {
  const { firestore } = useFirebase();
  const partDbCollectionString = useRecoilValue(PART_VIEWER_COLLECTION);
  const setPricingSearchQuery = useSetRecoilState(priceSearchQueryAtom);

  const onClick = async (e: any) => {
    e.stopPropagation();
    const partDoc = await firestore.collection(partDbCollectionString).doc(sku).get();
    if (partDoc.exists) {
      const query = geometricDescriptionParts(partDoc.data(), type);
      setPricingSearchQuery(cleanMeta(query));
    }
  };

  return (
    <Tooltip placement="top" title="Search for parts with like geometry">
      <Button
        shape="circle"
      // @ts-ignore
        icon={<ShapeIcon />}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default SearchGeometricPartButton;
