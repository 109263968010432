import React, { useEffect, useState } from 'react';
import { find, includes, sortBy } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import PartDetailDropdown from 'shared/components/Input/PartDetailDropdown';
import { currentInventoryPartAtom, inventoryPartEditedAtom } from 'shared/state/inventoryState';
import { routerStepsAtom } from 'shared/state/routingState';
import QBOItem from 'shared/data/QBO/item';
import { IRouterStep } from '../../../ProductionSchedule/types';

const routerStepName = (routerSteps: IRouterStep[], stepId: string|undefined) => {
  if (!stepId) return null;
  const routerStep = find(routerSteps, (r: IRouterStep) => r.id === stepId);
  if (!routerStep) return null;
  return `${routerStep.name}${routerStep.subName ? ` ${routerStep.subName}` : ''}`;
};

interface IComponent {
  productCode: string;
  disabled: boolean;
}
const InventoryPartConsumedStep = ({ disabled, productCode }: IComponent) => {
  // @ts-ignore
  const [partState, setPartState] = useRecoilState(currentInventoryPartAtom);
  const [_disabled, setDisabled] = useState<boolean>(disabled || QBOItem.partType(productCode) !== 'Inventory');
  const setEdited = useSetRecoilState(inventoryPartEditedAtom);
  const routerSteps = useRecoilValue(routerStepsAtom).filter((r: IRouterStep) => {
    if (includes(['BODYBLANK', 'LUMBER', 'TOPWOOD'], partState.ProductCode?.productCode)) return r.type === 'body';
    return r.type === 'neck';
  });
  const [partRouterStep, setPartRouterStep] = useState<string|null>(routerStepName(routerSteps, partState.routerStep));

  const sortedSteps = [...sortBy(routerSteps, (r: IRouterStep) => r.daysToCompletion)].reverse();
  const routerStepOptions = sortedSteps.map((p: IRouterStep) => (
    { label: `${p.name} ${p.subName ? p.subName : ''}`, key: p.id }
  ));

  const onRouterStepChange = (e: any) => {
    setEdited(true);
    const stepId = e.split('-')[0];
    const routerStep = routerStepName(routerSteps, stepId);
    // @ts-ignore
    setPartState({ ...partState, routerStep: stepId });
    setPartRouterStep(routerStep);
  };

  useEffect(() => {
    const routerStep = routerStepName(routerSteps, partState.routerStep);
    setPartRouterStep(routerStep);
  }, [partState.Id]);

  useEffect(() => {
    if (QBOItem.partType(productCode) !== 'Inventory') {
      setDisabled(disabled || true);
      setPartState({ ...partState, routerStep: null });
      setPartRouterStep(null);
    } else {
      setDisabled(disabled || false);
    }
  }, [disabled, productCode]);

  return (
    <>
      {!_disabled && (
      <PartDetailDropdown
        width="200px"
        showKey={false}
        changeCallback={onRouterStepChange}
        disabled={_disabled}
        id="inventory-router-step"
        label="Consumed at"
        placeholder="Select router step"
      // @ts-ignore
        value={partRouterStep}
        valueOptions={routerStepOptions}
      />
      )}
    </>
  );
};

export default InventoryPartConsumedStep;
