import React, { useState } from 'react';
import styled from 'styled-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { currentInventoryPartAtom, inventoryPartEditedAtom } from 'shared/state/inventoryState';
import {
  NoLeftMarginViewTypeRadioGroup,
  ViewTypeSelect,
  ViewTypeSelectOption,
  ViewTypeRadioButton, ItemDetailsLabel, ItemDetailsColumnWrapper,
} from 'shared/styledComponents/inputs';
import { FlexRow } from 'shared/containers/FlexContainer';
import SimpleModal from 'shared/components/Utility/SimpleModal';

const ComponentWrapper = styled(FlexRow)`
  justify-content: flex-start;
  gap: 12px;
`;

interface IComponent {
  disabled: boolean;
}
const SubItemStatusSelector = ({ disabled }: IComponent) => {
  const [partState, setPartState] = useRecoilState(currentInventoryPartAtom);
  const setEdited = useSetRecoilState(inventoryPartEditedAtom);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const itemIsSubItem = () => {
    setPartState({ ...partState, Parent: { PartNumber: '', Description: '', ParentPerChild: 1 } });
    setModalOpen(false);
  };

  const itemIsNotSubItem = () => {
    setPartState({ ...partState, Parent: null });
    setModalOpen(false);
  };

  const onChangeView = (e: any) => {
    if (disabled) return;
    const value = (e.target?.value || e) === 'true';
    if (value && partState.Children) {
      setModalOpen(true);
    } else {
      setEdited(true);
      if (value) itemIsSubItem();
      else itemIsNotSubItem();
    }
  };

  return (
    <>
      <SimpleModal
        modalOpen={modalOpen}
        title="Create subitem?"
        content="Nesting subitems is currently not supported. Click confirm to remove this part's subitems and continue, or cancel to keep this as a subitem."
        buttons={[
          {
            label: 'Cancel', onClick: itemIsNotSubItem, key: 'cancel', type: 'default',
          },
          {
            label: 'Create', onClick: itemIsSubItem, key: 'create', type: 'primary',
          },
        ]}
        onCancel={itemIsNotSubItem}
      />
      <ItemDetailsColumnWrapper key={`${partState.Id}-active-selector-wrapper`}>
        <ItemDetailsLabel key={`${partState.Id}-active-selector-label`}>Subitem?</ItemDetailsLabel>
        <ComponentWrapper>
          <ViewTypeSelect
            label="Subitem?"
            size="default"
            value={!!partState.Parent}
            onChange={onChangeView}
          >
            <ViewTypeSelectOption value="false">No</ViewTypeSelectOption>
            <ViewTypeSelectOption value="true">Yes</ViewTypeSelectOption>
          </ViewTypeSelect>
          <NoLeftMarginViewTypeRadioGroup
            value={(!!partState.Parent).toString()}
            buttonStyle="solid"
            onChange={onChangeView}
          >
            <ViewTypeRadioButton
              value="false"
            >
              No
            </ViewTypeRadioButton>
            <ViewTypeRadioButton
              value="true"
            >
              Yes
            </ViewTypeRadioButton>
          </NoLeftMarginViewTypeRadioGroup>
        </ComponentWrapper>
      </ItemDetailsColumnWrapper>
    </>
  );
};

export default SubItemStatusSelector;
