import React from 'react';
import { useRecoilState } from 'recoil';
import { routerStepsAtom } from 'shared/state/routingState';
import { Popconfirm, Tooltip } from 'antd';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { dateString } from 'shared/data/calendar';
import { QuestionCircle } from '@styled-icons/fa-solid';
import theme from 'shared/theme';
import { IRouterStep } from '../../types';
import {
  RunnerTimingDot, ConfirmIcon, ConfirmPop, DotTooltip, DotTooltipP,
} from '../../styledComponents';
import { stageRedirect } from '../../../../shared/util';
import { IRunner } from '../../../Orders/types';

const shortid = require('shortid');
const _ = require('lodash');

interface IComponentInterface {
  record: any;
}

export default ({ record }: IComponentInterface) => {
  const routerSteps = useRecoilState(routerStepsAtom);

  const onView = (type: string, id: string) => (e: any) => {
    const viewType = type.match(/body/i) ? 'Body' : 'Neck';
    stageRedirect(`${window.location.pathname}#${record.id}`);
    localStorage.setItem('routerViewType', viewType);
    window.location.href = `/router#${id}`;
  };

  const onEdit = (salesOrderId: string, recordId: string) => (e: any) => {
    stageRedirect(`${window.location.pathname}#${record.id}`);
    window.location.href = `/router/edit?shopOrderId=${salesOrderId}&runnerId=${recordId}`;
  };

  return (
    <FlexRow align="center" justify="center">
      {record.runners.filter((r: IRunner) => !r.completed).map((r: IRunner) => {
        const step = _.find(routerSteps[0], (rS: IRouterStep) => rS.id === r.step);
        if (!step) console.log(record);
        const isHold = record.hold || step.name.match(/hold/i);
        const stepName = step.subName ? `${step.name} - ${step.subName}` : step.name;
        let complete = 'est. complete: unknown';
        if (!isHold) {
          const completeDate = record.shipDate.toDate();
          if (!step.name.match(/ready/i)) completeDate.setDate(completeDate.getDate() + r.shipsInDays); // r.shipsInDays calculated in shared/router/clientRunner/salesOrder -> shared/data/calendar/shipDelta
          complete = `est. complete: ${dateString(completeDate, true)}`;
        }

        const color = isHold ? theme.palette.neutral[700] : r.shipsInDays >= 5 ? theme.palette.error.D500 : theme.palette.success.D500;
        const ToolTipContent = (
          <DotTooltip>
            <DotTooltipP style={{ color: '#FAFAFA' }}>{r.description || record.description}</DotTooltipP>
            <DotTooltipP style={{ color: '#FAFAFA' }}>{`${stepName}, ${complete}`}</DotTooltipP>
          </DotTooltip>
        );

        return (
          <Tooltip key={shortid.generate()} overlayStyle={{ width: 'auto' }} color={color} placement="bottom" title={ToolTipContent}>
            {/* <Tooltip key={shortid.generate()} overlayStyle={{ width: 'auto' }} placement="top" title={`${stepName}, ${complete}`}> */}
            <ConfirmPop
              title="View or Edit?"
              onConfirm={onEdit(record.id, r.id)}
              onCancel={onView(record.type, r.id)}
              okText="Edit"
              cancelText="View"
              icon={<ConfirmIcon />}
            >
              <RunnerTimingDot hold={isHold} complete={step.name.match(/qa/i)} behind={r.shipsInDays >= 5} />
            </ConfirmPop>
          </Tooltip>
        );
      })}
    </FlexRow>
  );
};
