import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  every, find, sortBy, flattenDeep, reverse,
} from 'lodash';
import qs from 'qs';
import { List, Table } from 'antd';
import styled from 'styled-components';
import { EmptyPriceItem } from 'pages/Customer/Components/styledComponents';
import { Body } from 'shared/typography';
import { activePartDisplayAtom } from 'shared/state/partViewState';
import { scrollToHash, scrollOptions } from 'shared/scroll';
import { IInventoryPart, IOrderItem, IOrderItemRecord } from 'shared/types/dbRecords';
import { orderSearchQueryAtom } from 'shared/state/orderState';
import {
  DESCRIPTION_COLUMN,
  ORDER_VALUE_COLUMN,
  SALES_ORDER_COLUMN,
} from 'pages/ProductionSchedule/Components/DataColumns';
import {
  CUSTOMER_COLUMN, JOB_TYPE_COLUMN, ORDER_DATE_COLUMN, SHIP_DATE_COLUMN,
} from './OrderRecordsColumns';
import OrderItem from './OrderRecordListItem';
import { CustomerPricingWrapper } from '../../../styledComponents';
import { IRunner, ISalesOrder, IShopOrder } from '../../types';
import { rgba as hexRgba } from '../../../../shared/palette/utils';
import theme from '../../../../shared/theme';
import { stageRedirect } from '../../../../shared/util';

// const ItemList = styled(List)`
//   width: 100%;
//   & li {
//     padding: 0;
//   }
// `

const ItemTable = styled((props) => <Table {...props} />)`
    width: 100%;
    && tbody > tr:hover > td {
        background: ${hexRgba(theme.palette.primary.hue, 0.2)};
    }
`;

interface IOrderRecord extends IShopOrder{
  orderItems: IOrderItem[];
}
interface IComponent {
  renderItems: IOrderRecord[];
  currentPage: number;
  pageChangeCallback: (value: number) => void;
}
const PartList = ({ renderItems, currentPage, pageChangeCallback }: IComponent) => {
  const currentPageString = sessionStorage.getItem('currentOrdersPage');
  const ordersSearchQuery = useRecoilValue(orderSearchQueryAtom);
  const activeDisplayType = useRecoilValue(activePartDisplayAtom);
  const [_currentPage, setCurrentPage] = useState(currentPage);
  const [_renderItems, setRenderItems] = useState(renderItems);
  const { orderId } = qs.parse(window.location.search.replace('?', ''));
  const tableColumns = [
    SALES_ORDER_COLUMN, // 100
    CUSTOMER_COLUMN, // 150
    ORDER_DATE_COLUMN,
    SHIP_DATE_COLUMN,
    JOB_TYPE_COLUMN,
    DESCRIPTION_COLUMN, // 600
    ORDER_VALUE_COLUMN,
  ];

  const updatePageData = (): void => {
    // @ts-ignore
    const items = reverse(sortBy(orders, (o: IOrderRecord) => o.orderDate.toDate()))
      .filter((o: IOrderRecord) => {
        if (activeDisplayType === 'active') return !o.completed;
        if (activeDisplayType === 'inactive') return o.completed;
        return o;
      })
      .filter((o: IOrderRecord) => {
        const terms = ordersSearchQuery.split(',');
        const jobNotes = o.runners ? flattenDeep(o.runners.map((r: IRunner) => r.parts?.map((p: IOrderItem) => p.notes))).join('') : '';
        let matchString = `${o.purchaseOrder}${o.salesOrder}${o.description}${o.type}${(o.orderValue || 0).toString()}${o.customer.id}${o.customer.CompanyName}${jobNotes}`;

        const partNames = o.orderItems.filter((i: IOrderItem) => i).map((i) => i.Sku).join('');
        const descriptions = o.orderItems.filter((i: IOrderItem) => i).map((i) => i.Description).join('');
        matchString += `${partNames}${descriptions}`;

        if (o.orderDate) matchString += o.orderDate.toDate().toLocaleString();
        if (o.shipDate) matchString += o.shipDate.toDate().toLocaleString();
        const matches = terms.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return every(matches, Boolean);
      });
    console.log(items);
    // @ts-ignore
    setRenderItems(items);
  };
  const onPageChange = (newPage: number) => {
    sessionStorage.setItem('currentOrdersPage', newPage.toString());
    setCurrentPage(newPage);
    updatePageData();
    scrollToHash('#orders-filter', {
      ...scrollOptions,
      time: 1000,
    });
  };

  const onRowClick = (record: IShopOrder) => (e: any) => {
    stageRedirect(`/orders?orderId=${record.id}`);
    window.location.href = `/orders/create?edit=true&orderId=${record.id}`;
  };

  useEffect(() => {
    setRenderItems(renderItems);
  }, [renderItems]);

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage]);
  return (
    <>
      {(_renderItems.length === 0)
        ? (
          <EmptyPriceItem>
            <Body>No matches available.</Body>
          </EmptyPriceItem>
        ) : (
          <CustomerPricingWrapper>
            <ItemTable
              dataSource={_renderItems.filter((o) => o)}
              rowKey="Id"
              size="large"
              rowClassName="sales-order-row clickable-row"
              columns={tableColumns}
              pagination={{ pageSize: 100, current: _currentPage, onChange: onPageChange }}
              scroll={{ y: window.innerHeight * 0.8 }}
              onRow={(record: IShopOrder) => ({
                onClick: onRowClick(record),
              })}
            />
          </CustomerPricingWrapper>
        )}
    </>
  );
};

export default PartList;
