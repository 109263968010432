import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  orderWeeksAtom,
  showBodiesAtom, showNecksAtom, scheduleViewStartDateAtom,
} from 'shared/state/routingState';
import { ncRecordsAtom } from 'shared/state/utilState';
import {
  dateString, MONTHS, startOfCurrentWeek, monthDates, workWeeksForMonth,
} from 'shared/data/calendar';
import { sortList } from 'shared/util';
import { INCRecord } from 'shared/types/dbRecords';
import { rgba, shade } from 'shared/palette/utils';
import theme from 'shared/theme';
import {
  RouterTableHeaderWrapper,
  RouterTableHeaderDates,
  RouterTableHeader,
  SalesOrderTable,
  TableSectionHeaderWrapper,
  WeekPrintWrapper,
  TableHeaderRow,
  RouterTableHeaderWhiteDivider,
} from 'pages/ProductionSchedule/styledComponents';
import { ncColumns } from './Components/NcDataColumns';
import { setRowClass } from './util';
import NCExpandedRow from './Components/NCExpandedRow';
import { DownCaret, UpCaret } from './NonComplianceReportByDepartment';

const _ = require('lodash');
const shortid = require('shortid');

interface IComponentInterface {
  records: INCRecord[];
}
interface IExpandFunction {
  record: INCRecord;
  expanded: boolean;
  onExpand: (e: any) => void;
}

const NcTableHeaderRow = styled(TableHeaderRow)`
    width: ${(props: { addedWidth: number}) => `calc(100% + ${props.addedWidth || 0}px)`};
    background-color: ${(props: { wrapperColor: string }) => `${rgba(props.wrapperColor, 0.7)}`};
    margin-left: 0;
`;
export default ({ records }: IComponentInterface) => {
  const ncRecords = useRecoilValue(ncRecordsAtom);
  const start = useRecoilValue(scheduleViewStartDateAtom);
  const showBodies = useRecoilValue(showBodiesAtom);
  const showNecks = useRecoilValue(showNecksAtom);
  const orderWeeks = useRecoilValue(orderWeeksAtom);

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setFirstLoad(false);

    if (window.location.hash.length === 0) {
      // const date = dateString(startOfCurrentWeek(new Date(), true) as Date);
      window.location.hash = startOfCurrentWeek().toString();
      // window.location.hash = date.replace(/\//g, '-');
    }
    const hash = window.location.hash?.replace('#', '');
    const isDate = !Number.isNaN(parseInt(hash, 10));

    const timeout = setTimeout(() => {
      const el = isDate
        ? document.getElementById(hash)
        : document.querySelector(`[data-row-key=${hash}]`);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // @ts-ignore
  return (
    <div key="sales-order-outer-wrapper" style={{ width: '100%' }}>
      {orderWeeks.map((month: Date[][], _index: number) => {
        const monthIndex = month[0][0].getMonth();
        const monthColorIndex = _index % theme.palette.schedulePalette.length;
        const monthColor = theme.palette.schedulePalette[monthColorIndex];
        const wrapperColor = shade(monthColor, _index * 0.05);

        return (
          <div key={`sales-order-inner-wrapper-${monthIndex}-${shortid.generate()}`}>
            { month.map((week: Date[], index) => {
              const monthWeeks = workWeeksForMonth(
                monthDates()[week[0].getMonth()],
              );
              const weekIndex = month.length === 1 ? monthWeeks.length : index + 1;
              const headerMonth = `${MONTHS[monthIndex]} W${weekIndex}`;
              const headerDates = `${dateString(week[0], true)}-${dateString(_.last(week), true)} (${week.length} days)`;
              const firstDay = new Date(week[0]);
              firstDay.setHours(0, 0, 0, 0);
              const sectionId = firstDay.getTime();

              const weekRecords = records.filter((o: INCRecord) => {
                const weekDates = week.map((d) => dateString(d, true));
                return _.includes(weekDates, dateString(o.dateRecorded.toDate(), true));
              });

              const filteredRecords = sortList(weekRecords, ['customerId', 'dateRecorded']);
              const bodies = filteredRecords.filter((o: INCRecord) => o.type.match(/body/i));
              const necks = filteredRecords.filter((o: INCRecord) => o.type.match(/neck/i));

              // @ts-ignore
              return (
                <>
                  {!!filteredRecords.length
                            && (
                            <WeekPrintWrapper key={`${sectionId}-week`}>
                              {showBodies && (
                              <div style={{ width: 'calc(100% + 32px)', marginBottom: showBodies ? 24 : 0 }}>
                                <NcTableHeaderRow wrapperColor={wrapperColor}>
                                  <TableSectionHeaderWrapper width="100%">
                                    <RouterTableHeaderWrapper id={sectionId.toString()} key={shortid.generate()} width="100%">
                                      <RouterTableHeader>{headerMonth}</RouterTableHeader>
                                      <RouterTableHeaderDates>{headerDates}</RouterTableHeaderDates>
                                      <RouterTableHeaderWhiteDivider />
                                      <RouterTableHeader>Bodies</RouterTableHeader>
                                    </RouterTableHeaderWrapper>
                                  </TableSectionHeaderWrapper>
                                </NcTableHeaderRow>
                                <SalesOrderTable
                                  key={`${sectionId}-body-week-table`}
                                  rowKey="id"
                                  size="small"
                                            // @ts-ignore
                                  columns={ncColumns}
                                  dataSource={bodies}
                                  pagination={false}
                                  tableLayout="fixed"
                                  rowClassName={setRowClass}
                                  expandable={{
                                    expandedRowRender: (record) => <NCExpandedRow record={record} />,
                                    rowExpandable: (record) => record.id !== 'Not Expandable',
                                    expandIcon: ({ expanded, onExpand, record }: IExpandFunction) =>
                                      (expanded ? (
                                        <UpCaret onClick={(e) => onExpand(record, e)} />
                                      ) : (
                                        <DownCaret onClick={(e) => onExpand(record, e)} />
                                      )),
                                  }}
                                />
                              </div>
                              )}
                              {showNecks && (
                              <>
                                <NcTableHeaderRow addedWidth={32} wrapperColor={wrapperColor}>
                                  <TableSectionHeaderWrapper width="100%">
                                    <RouterTableHeaderWrapper id={sectionId.toString()} key={shortid.generate()} width="100%">
                                      <RouterTableHeader>{headerMonth}</RouterTableHeader>
                                      <RouterTableHeaderDates>{headerDates}</RouterTableHeaderDates>
                                      <RouterTableHeaderWhiteDivider />
                                      <RouterTableHeader>Necks</RouterTableHeader>
                                    </RouterTableHeaderWrapper>
                                  </TableSectionHeaderWrapper>
                                </NcTableHeaderRow>
                                <SalesOrderTable
                                  key={`${sectionId}-neck-week-table`}
                                  rowKey="id"
                                  size="small"
                                  // @ts-ignore
                                  columns={ncColumns}
                                  dataSource={necks}
                                  pagination={false}
                                  rowClassName={setRowClass}
                                  expandable={{
                                    expandedRowRender: (record) => <NCExpandedRow record={record} />,
                                    rowExpandable: (record) => record.id !== 'Not Expandable',
                                    expandIcon: ({ expanded, onExpand, record }: IExpandFunction) =>
                                      (expanded ? (
                                        <UpCaret onClick={(e) => onExpand(record, e)} />
                                      ) : (
                                        <DownCaret onClick={(e) => onExpand(record, e)} />
                                      )),
                                  }}
                                />
                              </>

                              )}
                            </WeekPrintWrapper>
                            )}
                </>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
