/* eslint react/prop-types: 0 */
import React from 'react';
import { InputNumber } from 'antd';
import { Caption } from 'shared/typography';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';

interface ICaptionedInputNumber {
  useRow?: boolean;
  highlightCaption: boolean|undefined;
  label: string;
  fieldValue: any;
  formatter?: (s: string) => string;
  parser?: (s: string) => number;
  onChange: any;
  step: string;
  placeholder: string;
  style?: object;
}

const Column = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
`;

const Row = styled(FlexRow)`
  flex-direction: row;
  gap: ${theme.spacing(0.5)};
  
  & p {
    margin-right: 8px;
  }
`;

const Label = styled(Caption)`
  margin: 0;
  background-color: ${(props) => (props.highlightCaption ? 'yellow' : 'transparent')};
  font-weight: ${(props) => (props.highlightCaption ? 500 : 400)};
`;

const RowLabel = styled(Caption)`
  width: ${theme.spacing(10)};
  background-color: ${(props) => (props.highlightCaption ? 'yellow' : 'transparent')};
  font-weight: ${(props) => (props.highlightCaption ? 500 : 400)};
`;

export default ({
  highlightCaption,
  label,
  fieldValue,
  onChange,
  step,
  placeholder,
  formatter = (value) => value,
  parser = (value) => parseInt(value, 10),
  style = {},
  useRow = true,
  ...rest
}: ICaptionedInputNumber) => {
  const Wrapper = useRow ? Row : Column;
  const LabelComponent = useRow ? RowLabel : Label;
  const inputStyle = style || {};
  return (
    <Wrapper>
      <LabelComponent highlightCaption={false}>{label}</LabelComponent>
      <InputNumber
        // @ts-ignore
        formatter={formatter}
        // @ts-ignore
        parser={parser}
        placeholder={placeholder}
        step={step}
        defaultValue={0}
        onChange={onChange}
        value={fieldValue}
        style={inputStyle}
        {...rest}
      />
    </Wrapper>
  );
};
