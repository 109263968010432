import React, { useState, useEffect } from 'react';
import {
  Button, Checkbox, Divider, Modal,
} from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CheckCircleFilled } from '@ant-design/icons/lib';
import useFirebase from 'vendor/Firebase';
import { routerStepsAtom, searchModalAtom } from 'shared/state/routingState';
import theme from 'shared/theme';
import { FlexRow } from 'shared/containers/FlexContainer';
import { expectedShipDate } from 'shared/data/calendar';
import { numberWithCommas } from 'shared/text';
import { ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { H6 } from 'shared/typography';
import {
  ACTIONS_COLUMN,
  baseBodyColumns,
  baseNeckColumns, COMPLETION_DATE_COLUMN,
  CUSTOMER_COLUMN,
  DESCRIPTION_COLUMN,
  LOCATION_COLUMN,
  SALES_ORDER_COLUMN, SHIP_DATE_COLUMN,
  WORK_ORDER_COLUMN,
} from './DataColumns';
import {
  NoOrdersText,
  RouterTDCopy,
  RouterTDDescription,
  RouterTDPartAlder,
  RouterTDPartMahog, RouterTDPartOther, RouterTDPartPine,
  RouterTDPartSwash,
  RunnerManualSearchInput,
  RunnerTable,
} from '../styledComponents';
import {
  IRouterStep,
} from '../types';
import {
  IClientRunner, IRunner, IShopOrder, IStepRunner,
} from '../../Orders/types';

const _ = require('lodash');
const shortid = require('shortid');

export default () => {
  const { firestore } = useFirebase();
  const [orderRunners, setOrderRunners] = useState<IClientRunner[]|null>(null);
  const [filteredRunners, setFilteredRunners] = useState<IClientRunner[]>([]);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showBodies, setShowBodies] = useState(true);
  const [showNecks, setShowNecks] = useState(true);
  const [orderNumber, setOrderNumber] = useState('');
  const [showSearchModal, setShowSearchModal] = useRecoilState(searchModalAtom);
  const routerSteps = useRecoilValue(routerStepsAtom);
  const [noOrderFound, setNoOrderFound] = useState(false);

  const onOrderNumberChange = (e: any) => {
    setNoOrderFound(false);
    setOrderNumber(e.target.value);
  };

  const onCloseModal = (e: any) => {
    e.preventDefault();
    setOrderNumber('');
    setOrderRunners(null);
    setShowSearchModal(false);
  };

  const onSearch = (e: any) => {
    if (orderNumber.trim() === '') return;
    setOrderRunners(null);
    firestore.collection(ORDERS_DB_COLLECTION)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) setNoOrderFound(true);
        else {
          // const data = snapshot.docs[0].data();
          const data: IShopOrder[] = [];
          snapshot.forEach((doc) => {
            const d = doc.data() as IShopOrder;
            if (d.salesOrder.toString().match(new RegExp(orderNumber.trim()))
                || d.customer.name.match(new RegExp(orderNumber.trim(), 'i'))) data.push(d);
          });

          const runners = _.flatten(data.map((d: IShopOrder) => d.runners.map((r: IRunner) => {
            const clientRunner = {
              ...r,
              orderId: d.id,
              completedDate: d.completedDate?.toDate() as Date,
              customer: d.customer.shortName,
              description: d.description || '',
              shipDate: d.shipDate,
              salesOrder: d.salesOrder,
              stepHash: _.find(routerSteps, (_r: IRouterStep) => _r.id === r.step).name.replace(/\s/g, '-'),
              runnersOnOrder: d.runners.length,
              type: d.type,
            };

            const runnerStep = _.find(routerSteps, (s: IRouterStep) => s.id === r.step);

            const [, expectedShip, shipDelta] = expectedShipDate(routerSteps, clientRunner);

            const inDryRoom = clientRunner.step.match(/7kN0pSmXBw3/i);
            let daysRemaining = 2;
            if (inDryRoom) {
              // is in dry room
              const dateEntered = clientRunner.history[clientRunner.history.length - 1].dateEntered.toDate();
              dateEntered.setHours(0, 0, 0);
              const today = new Date();
              today.setHours(0, 0, 0);
              // @ts-ignore
              const day = Math.round((today - dateEntered) / (1000 * 3600 * 24));
              daysRemaining = daysRemaining - day < 0 ? 0 : daysRemaining - day;
              if (daysRemaining === 0) expectedShip.setDate(expectedShip.getDate() - 1);
            }

            return {
              ...clientRunner,
              dryRoomDay: daysRemaining,
              step: runnerStep ? runnerStep.name : '?',
              runnerValue: `$${numberWithCommas(clientRunner.value || 0)}`,
              shipDate: `${d.shipDate.toDate().getMonth() + 1}/${d.shipDate.toDate().getDate()}`,
              actualShip: `${expectedShip.getMonth() + 1}/${expectedShip.getDate()}`,
              runnerNumber: `${clientRunner.runnerNumber + 1}/${d.runners.length}`,
              shipDelta,
            };
          })));

          const sortedRunners = _.sortBy(runners, (r: IClientRunner) => r.salesOrder);

          setOrderRunners(sortedRunners);
          setFilteredRunners(sortedRunners);
        }
      });
  };

  const columns: any[] = [
    {
      title: 'Customer',
      dataIndex: 'customer',
      render: (text: string, record: any) => (
        <RouterTDCopy>{record.customer}</RouterTDCopy>
      ),
    },
    {
      title: 'Sales Order',
      dataIndex: 'salesOrder',
      sorter: {
        compare: (a: IClientRunner, b: IClientRunner) => {
          if (a.salesOrder > b.salesOrder) return 1;
          if (b.salesOrder > a.salesOrder) return -1;
          return 0;
        },
        multiple: 2,
      },
      render: (text: string, record: any) => (
        <RouterTDCopy>{record.salesOrder}</RouterTDCopy>
      ),
    },
    {
      title: 'Work Order',
      dataIndex: 'runnerNumber',
      render: (text: string, record: any) => {
        const node = record.toppedOrBound
          ? <CheckCircleFilled style={{ marginLeft: 16, fontSize: 18, color: theme.palette.primary.hue }} /> : <div />;

        return (
          <FlexRow justify="flex-start">
            <RouterTDCopy>{record.runnerNumber}</RouterTDCopy>
            {node}
          </FlexRow>
        );
      },
      sorter: {
        compare: (a: IClientRunner, b: IClientRunner) => {
          if (a.runnerNumber > b.runnerNumber) return 1;
          if (b.runnerNumber > a.runnerNumber) return -1;
          return 0;
        },
        multiple: 3,
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text: string, record: any) => (
        <RouterTDDescription>{record.description}</RouterTDDescription>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: {
        compare: (a: IClientRunner, b: IClientRunner) => {
          if (a.step > b.step) return 1;
          if (a.step < b.step) return -1;
          return 0;
        },
        multiple: 4,
      },
      render: (text: string, record: any) => (
        <RouterTDCopy>{record.step}</RouterTDCopy>
      ),
    },
    {
      title: 'Units',
      dataIndex: 'partCount',
      render: (text: string, record: any) => {
        if (record.type.match(/neck/i)) {
          return (
            <RouterTDCopy>{record.partCount}</RouterTDCopy>
          );
        }
        const partCounts = record.partCounts?.filter((pC: {type: string, count: number}) => pC.count);
        const swampAsh = _.find(partCounts, (pc: any) => pc.type.match(/swash/i));
        const alder = _.find(partCounts, (pc: any) => pc.type.match(/alder/i));
        const mahog = _.find(partCounts, (pc: any) => pc.type.match(/mahog/i));
        const pine = _.find(partCounts, (pc: any) => pc.type.match(/pine/i));
        const other = _.find(partCounts, (pc: any) => pc.type.match(/other/i));

        return (
          <>
            <FlexRow key={shortid.generate()} justify="flex-start">
              {swampAsh?.count > 0
                  && <RouterTDPartSwash key={shortid.generate()}>{swampAsh.count}</RouterTDPartSwash>}
              {alder?.count > 0
                  && <RouterTDPartAlder key={shortid.generate()}>{alder.count}</RouterTDPartAlder>}
              {mahog?.count > 0
                  && <RouterTDPartMahog key={shortid.generate()}>{mahog.count}</RouterTDPartMahog>}
              {pine?.count > 0
                  && <RouterTDPartPine key={shortid.generate()}>{pine.count}</RouterTDPartPine>}
              {other?.count > 0
                  && <RouterTDPartOther key={shortid.generate()} desc={record.description || ''}>{other.count}</RouterTDPartOther>}
            </FlexRow>

          </>
        );

        // const partCount = record.type.match(/neck/i) ? record.partCount
        //   : record.partCounts.map((pC: { type: string, count: number }) => pC.count)
        //     .reduce((a: number, b: number) => a + b, 0);
        // return (
        //   <RouterTDCopy>{partCount}</RouterTDCopy>
        // );
      },
    },
    {
      title: 'Ship Date',
      dataIndex: 'shipDate',
      render: (text: string, record: any) => (
        <RouterTDCopy>{record.shipDate}</RouterTDCopy>
      ),
    },
    {
      title: 'Est. Ship',
      dataIndex: 'actualShip',
      render: (text: string, record: any) => (
        <RouterTDCopy>{record.actualShip}</RouterTDCopy>
      ),
    },
  ];

  const bodyColumns = [
    CUSTOMER_COLUMN,
    SALES_ORDER_COLUMN,
    WORK_ORDER_COLUMN,
    DESCRIPTION_COLUMN,
    LOCATION_COLUMN,
    SHIP_DATE_COLUMN,
    COMPLETION_DATE_COLUMN,
    ACTIONS_COLUMN,
  ];

  const neckColumns = [
    ...baseNeckColumns.slice(0, 3),
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: {
        compare: (a: IClientRunner, b: IClientRunner) => {
          if (a.step > b.step) return 1;
          if (a.step < b.step) return -1;
          return 0;
        },
        multiple: 4,
      },
      render: (text: string, record: any) => (
        <RouterTDCopy>{record.step}</RouterTDCopy>
      ),
    },
    ...baseNeckColumns.slice(4),
  ];

  const toggleShowCompleted = (e: any) => {
    setShowCompleted(e.target.checked);
  };
  const toggleShowNecks = (e: any) => {
    setShowNecks(e.target.checked);
  };
  const toggleShowBodies = (e: any) => {
    setShowBodies(e.target.checked);
  };

  useEffect(() => {
    setOrderNumber('');
  }, []);

  return (
    <Modal
      title="Shop Order Search"
      centered
      visible={showSearchModal}
      onOk={onCloseModal}
      onCancel={onCloseModal}
      width="90vw"
    >
      <RunnerManualSearchInput
        autoFocus
        onChange={onOrderNumberChange}
        onPressEnter={onSearch}
        value={orderNumber}
        placeholder="Sales order number, e.g., 1234"
        size="large"
        inputMode="numeric"
      />
      <FlexRow justify="flex-start">
        <Button onClick={onSearch} type="primary">Search</Button>
        <Checkbox style={{ marginLeft: 24 }} checked={showCompleted} onClick={toggleShowCompleted}>Show completed matches</Checkbox>
        <Checkbox style={{ marginLeft: 24 }} checked={showBodies} onClick={toggleShowBodies}>Show bodies</Checkbox>
        <Checkbox style={{ marginLeft: 24 }} checked={showNecks} onClick={toggleShowNecks}>Show necks</Checkbox>
      </FlexRow>

      <Divider />
      {noOrderFound
      && <NoOrdersText>{`No order found with SO #${orderNumber}`}</NoOrdersText>}
      {showBodies && orderRunners
      && (
        <>
          <H6>Body search results</H6>
          <RunnerTable
            columns={bodyColumns as any[]}
            dataSource={showCompleted ? filteredRunners.filter((r: IClientRunner) => r.type.match(/body/i))
              : filteredRunners.filter((r: any) => r.type.match(/body/i) && !r.step.match(/complete/i))}
            pagination={false}
            rowClassName={(record: any) => {
              if (record.step.match(/(hold|complete)/i)) return 'router-on-hold';
              if (record.shipDelta <= -5) return 'router-ship-schedule-behind';
              // if (record.shipDelta < -2 && record.shipDelta > -5) return 'router-ship-schedule-warning';
              return 'router-ship-schedule-ontime';
            }}
          />
        </>
      )}
      {showNecks && orderRunners
      && (
        <>
          <H6>Neck search results</H6>
          <RunnerTable
            columns={neckColumns as any[]}
            dataSource={showCompleted ? filteredRunners.filter((r: IClientRunner) => r.type.match(/neck/i))
              : filteredRunners.filter((r: any) => r.type.match(/neck/i) && !r.step.match(/complete/i))}
            pagination={false}
            rowClassName={(record: any) => {
              if (record.step.match(/(hold|complete)/i)) return 'router-on-hold';
              if (record.shipDelta <= -5) return 'router-ship-schedule-behind';
              // if (record.shipDelta < -2 && record.shipDelta > -5) return 'router-ship-schedule-warning';
              return 'router-ship-schedule-ontime';
            }}
          />
        </>
      )}
    </Modal>
  );
};
