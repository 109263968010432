import React, { useEffect, useState } from 'react';
import { Image, Popover, Tooltip } from 'antd';
import { Image as ImageIcon } from '@styled-icons/fa-solid';
import styled from 'styled-components';
import theme from 'shared/theme';
import { rgba } from 'shared/palette/utils';

const shortid = require('shortid');

interface IComponent {
  imageUrls: string[]
}

const PreviewIcon = styled(ImageIcon)`
  width: 18px;
  margin-left: 1px;
  color: ${theme.palette.primary.hue};
`;

const PreviewButton = styled.div`
  width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.neutral.white};
    border-radius: 50%; 
    box-shadow: 1px 1px 2px ${rgba(theme.palette.neutral.black, 0.08)}, -2px -2px 2px ${rgba(theme.palette.neutral.white, 0.08)}, 
      inset 1px 1px 4px ${rgba(theme.palette.neutral.black, 0.02)}; 
    cursor: pointer;
    margin: 2px 0;
    
    transition: all 0.3s ease-in-out;
    
    &:hover ${PreviewIcon} {
        color: ${theme.palette.neutral.white};
    }
     
      @media ${theme.device.laptop} {
        &:hover {
            background-color: ${theme.palette.primary.hue};
        } 
    }
`;

const ImagePreviewDot = styled.div`
  background-color: ${theme.palette.primary.hue};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const ImageWrapper = styled.div`
  display: ${(props: any) => props.display};
  width: 50%;
  border-radius: 4px;
  
  padding: 4px;
  padding-bottom: 0;
  margin-top: 2px;
`;

export default ({ imageUrls }: IComponent) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      {imageUrls.length ? (
        <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
          {imageUrls.map((url, index) => (
            <ImageWrapper display={index !== 0 ? 'none' : 'unset'}>
              <Image
                key={shortid.generate()}
                src={url}
              />
            </ImageWrapper>
          ))}
        </Image.PreviewGroup>
      ) : (
        <div style={{ width: '50%', height: 52, padding: 4 }} />
      )}
    </>
  );
};
