import _, {
  every, find, findIndex, includes, some, uniq,
} from 'lodash';
import { IBomItem, IOrderItem } from 'shared/types/dbRecords';
import {
  IOrderWithItems,
  IRunner, IRunnerHistory, IShopOrder, IWorkOrder,
} from '../../pages/Orders/types';

export const toppedOrBound = (orderParts: IOrderItem[] = []) => {
  if (orderParts.filter((i: any) => i).length === 0) return false;
  // true if the part type is "GB" or "BB" and the 'top' property is true, or
  // if the part type is "GN" or "BN" and the 'bound' property is true
  // @ts-ignore
  return some(orderParts, (p: IOrderItem) => {
    const description = p.Description;
    if (!description) return false;
    const topped = p.top && p.top !== 'None';
    const bound = p.bound && p.bound !== 'None';
    return (description.match(/[G|B]B/) && topped) || (description.match(/[G|B]N/) && bound);
  });
};

export const orderType = (orderParts: IOrderItem[]) => {
  if (orderParts.filter((i: IOrderItem) => i).length === 0) return 0;
  const partTypes = uniq(orderParts.filter((i: IOrderItem) => i)
    // @ts-ignore
    .filter((i: IOrderItem) => (i.partNumber || i.Sku || '').match(/^[A-Z]{5}/))
    // @ts-ignore
    .map((i: IOrderItem) => i.Description.replace(/_/g, '').slice(0, 2)));
  // 1 is neck, 0 is body
  return every(partTypes, (p: string) => p.match(/[G|B]N/)) ? 1 : 0;
};

export const noFinishing = (orderParts: IOrderItem[]) => {
  if (orderParts.filter((p: IOrderItem) => p).length === 0) return false;
  const noFinishBodyCustomers = ['EGGLE', 'XOTIC', 'PROSO'];
  // @ts-ignore
  const parts = orderParts.map((p: IOrderItem) => ({ description: p.Description, customer: p.Sku.slice(0, 5) }));
  return (some(parts, (p: { description: string, customer: string}) => p.description?.match(/_CNC_/) || (orderType(orderParts) === 0 && includes(noFinishBodyCustomers, p.customer))));
};

export const houseSample = (orderParts: IOrderItem[]) => some(orderParts, (o: IOrderItem) => o?.houseSample);

export const fancyTops = (orderParts: IOrderItem[]) => some(orderParts, (o: IOrderItem) => o.Description.match(/([356]A)?(spalt|flame|quilt|burl|buckeye)|(\bCS\b)/g));

export const orderItemsWithoutDevelopment = (items: IOrderItem[]) => items.filter((i: IOrderItem) => !i.Sku.match('9910015'));

export const isCustomerPart = (orderItem: IOrderItem) => !!orderItem.Sku.match(/^[A-Z]{5}_[0-9]{5}/);
export const cncLoad = (orderItem: IOrderItem) => {
  const ROUND_FACTOR = 4;
  const CNC_LOAD_FACTOR = 1.25; // load time inflation factor to account for error, tool load, etc.
  const loadType = orderItem.cncLoad || 'mama';

  const loadValue = {
    baby: 22,
    mama: 30,
    papa: 50,
  };

  const postConstructionLoadFactor = {
    baby: 0.8,
    mama: 0.65,
    papa: 0.5,
  };

  // if the order hasn't been released yet, quantity assigned will be zero, so if quantity open is greater than zero, use that.
  const partQuantity = orderItem.quantityAssigned === 0 && orderItem.quantityOpen > 0 ? orderItem.quantityOpen : orderItem.quantityAssigned;
  const preConstLoad = (CNC_LOAD_FACTOR * partQuantity * loadValue[loadType] * (1 - postConstructionLoadFactor[loadType])) / 60;
  const postConstLoad = (CNC_LOAD_FACTOR * partQuantity * loadValue[loadType] * postConstructionLoadFactor[loadType]) / 60;
  return [
    Math.round(parseFloat(preConstLoad.toFixed(2)) * ROUND_FACTOR) / ROUND_FACTOR,
    Math.round(parseFloat(postConstLoad.toFixed(2)) * ROUND_FACTOR) / ROUND_FACTOR,
  ];
};

export const totalCncLoad = (orderItems: IOrderItem[]) => {
  if (!orderItems) return [0, 0];
  const [pre, post] = orderItems.filter((o) => isCustomerPart(o)).map((o) => cncLoad(o)).reduce((a, b) => [a[0] + b[0], a[1] + b[1]], [0, 0]);
  return [pre, post];
};
export const neckOrderCNCLoad = (order: IShopOrder) => {
  const neckConstructionStep = '8itJFuQJVT0';
  const neckFinishingStep = 'aaKxatWdehe';

  /*
    * To determine how much load the order will place on the CNC, we need to find out if it has arrived or is beyond one or more steps in the process.
    * If the order has not yet reached the construction step, it will place pre-construction load on the CNC.
    * If the order has cleared the construction step, it will place post-construction load on the CNC.
    * If the order has cleared the first neck finishing step, no load will be placed on the CNC.
    *
    * In order to determine this, we will use each work order's history object to determine if the step has been cleared.
   */

  if (!order) return { active: 0, latent: 0 };

  if (!order.runners || order.runners.filter((r) => r).length === 0) {
    const [pre, post] = totalCncLoad(order.orderItems);
    return { active: 0, latent: pre + post };
  }
  const load = order.runners.map((r: IWorkOrder) => {
    const [preCncLoad, postCncLoad] = totalCncLoad(r.parts);
    const historySteps = r.history.map((s: IRunnerHistory) => s.step);

    const inFinishing = findIndex(historySteps, (s) => s === neckFinishingStep) > -1;
    if (inFinishing) return { active: 0, latent: 0 };

    const pastConstruction = findIndex(historySteps, (s) => s === neckConstructionStep) > -1;
    if (pastConstruction) return { active: postCncLoad, latent: 0 };

    return { active: preCncLoad, latent: postCncLoad };
  }).reduce((a, b) => ({ active: a.active + b.active, latent: a.latent + b.latent }), { active: 0, latent: 0 });
  // console.log(`Order ${order.salesOrder} has following load: active: ${load.active}, latent: ${load.latent}`);
  return load;
};

export const updateOrderItemBomConsumption = (order: IShopOrder|IOrderWithItems, orderItems: IOrderItem[]) => orderItems.map((o: IOrderItem) => {
  if (!order.runners || order.runners.filter((r) => r).length === 0) {
    const bom = o.bom.map((b: IBomItem) => ({ ...b, quantityConsumed: 0 }));
    return { ...o, bom };
  }
  const parts = _.groupBy(_.flatten(order.runners.map((r: IRunner) => r.parts)), (i) => i.Sku);
  const orderParts = parts[o.Sku];
  if (orderParts === undefined || Object.keys(parts).length === 0) return { ...o, quantityConsumed: 0 };
  const bomItems = _.groupBy(_.flatten(orderParts.map((p: any) => p.bom)), (b) => b.Sku);
  const updatedBom = Object.values(bomItems).map((q: IBomItem[]) => {
    const quantityConsumed = q.map((bb: IBomItem) => bb.quantityConsumed || 0).reduce((a, x) => a + x, 0);
    return { ...q[0], quantityConsumed };
  });
  return { ...o, bom: updatedBom };
});
