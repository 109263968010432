import React, { useEffect, useState, useRef } from 'react';
import {
  findIndex, groupBy, some, sortBy,
} from 'lodash';
import {
  Page, Text, View, StyleSheet,
} from '@react-pdf/renderer';
import { IOrderItem } from 'shared/types/dbRecords';
import WorkOrderItem from '../Components/WorkOrder/WorkOrderItem';
import WorkOrderFooter from '../Components/WorkOrder/WorkOrderFooter';
import WorkOrderTimeLog from '../Components/WorkOrder/WorkOrderTimeLog';
import { IWorkOrder, IWorkOrderItem } from '../../Orders/types';
import PickItem from '../Components/WorkOrder/PickItem';

const style = StyleSheet.create({
  buffer: {
    height: 36,
    width: '100%',
  },
  headerColumn: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 2,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginBottom: 0,
    marginLeft: 10,
  },
  workOrderHeader: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 0,
    marginRight: 8,
  },
  salesOrderNumber: {
    fontFamily: 'Red Hat Display',
    fontWeight: 'normal',
    fontSize: 12,
    margin: 0,
    paddingBottom: 2,
  },
  csDisclaimer: {
    fontSize: 11,
    fontFamily: 'Red Hat Display',
    fontWeight: 'normal',
    marginTop: 12,
    marginLeft: 14,
    color: '#333333',
    backgroundColor: 'yellow',
  },
  customer: {
    fontFamily: 'Red Hat Display-300',
    fontWeight: 'light',
    fontSize: 28,
    marginLeft: 10,
  },
  page: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 60,
    paddingLeft: 32,
    paddingRight: 18,
    paddingBottom: 36,
  },
  columnHeaders: {
    width: 556,
    height: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: 2,
  },

  pickItemDetailRow: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  columnHeaderUnderline: {
    height: 1,
    backgroundColor: 'grey',
    width: 548,
    marginLeft: 12,
    marginBottom: 4,
  },
  alsoOnThisOrder: {
    width: 512,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 16,
    margin: '0 auto',
    gap: 8,
  },
  alsoOnThisOrderDivider: {
    flexGrow: 2,
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  alsoOnThisOrderText: {
    fontFamily: 'Red Hat Display',
    fontSize: 8,
    padding: '0 12px',
    margin: 0,
  },
  modelHeaderRow: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  headerText: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 10,
  },
  modelHeaderText: {
    width: 60,
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 10,
    marginLeft: 16,
  },
  descriptionHeaderText: {
    marginLeft: 0,
  },
  quantityHeaderRow: {
    height: 'auto',
    width: 50,
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  groupWrapper: {
    width: 550,
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 12,
    marginBottom: 20,
    paddingTop: 2,
    // paddingTop: 8,
    borderTop: '1px solid lightgrey',
  },
  firstGroup: {
    borderTop: 'none',
  },
});

interface IPickItem {
  Sku: string;
  Description: string;
  pickQty: number;
  forParts: { PartSku: string; PartCount: number; }[];
  partBucket: string;
}
interface IComponent {
  pickItems: IPickItem[];
  shopOrder: IWorkOrder;
  runnerIndex: number;
  csParts: boolean;
}
const PickListPage = ({
  pickItems, shopOrder, runnerIndex, csParts,
}: IComponent) => {
  const partBuckets = {
    110: 'Milling',
    120: 'Milling',
    122: 'Construction',
    150: 'Milling',
    151: 'Milling',
    152: 'Milling',
    153: 'Construction',
    154: 'Construction',
    155: 'Finishing',
    156: 'Finishing',
    157: 'Finishing',
    159: 'Construction',
    140: 'Shipping',
  };
  const partOrder = [
    'Milling',
    'Construction',
    'Finishing',
    'Shipping',
  ];
  const itemGroups = groupBy(pickItems.map((p: IPickItem) => ({ ...p, partBucket: partBuckets[p.Sku.substring(0, 3)] })), (p: IPickItem) => p.partBucket);
  const keyOrdinals = sortBy(Object.keys(itemGroups), (k: string) => findIndex(partOrder, (p: string) => p === k));
  const sortedGroups = keyOrdinals.map((k: string) => sortBy(itemGroups[k], (i: IPickItem) => i.Sku));

  return (
    <>
      <Page size="LETTER" style={style.page}>
        {/* {shopOrder.type === 'neck' && ( */}
        {/*  <WorkOrderTimeLog /> */}
        {/* )} */}
        <View style={style.headerColumn}>
          <View style={style.headerRow}>
            <Text style={style.workOrderHeader}>Work Order</Text>
            <Text style={style.salesOrderNumber}>{`S.O. #1${shopOrder.salesOrder.split('-')[0]}, cart ${runnerIndex + 1}/${shopOrder.runners.length}`}</Text>
          </View>
          <Text style={style.customer}>Pick List</Text>
          {csParts && (
            <Text style={style.csDisclaimer}>** Note: pick list does not include CS parts and/or wood - see work order notes for details **</Text>
          )}
          <View style={style.buffer} />
          <View style={style.columnHeaders}>
            <View style={style.modelHeaderRow}>
              <Text style={style.modelHeaderText}>Part</Text>
              <Text style={[style.modelHeaderText, style.descriptionHeaderText]}>Description</Text>
            </View>
          </View>
          <View style={style.columnHeaderUnderline} />
          <>
            {sortedGroups.map((group: IPickItem[], index: number) => (
              <View style={[style.groupWrapper, index === 0 ? style.firstGroup : {}]}>
                <>
                  {group.map((i: IPickItem) => (
                    <PickItem key={i.Sku} Sku={i.Sku} Description={i.Description} PullQty={i.pickQty} PartsNeededFor={i.forParts} />
                  ))}
                </>
              </View>
            ))}
          </>
          {/* <WorkOrderFooter items={allOrderItems} shopOrder={shopOrder} runnerId={runnerId} /> */}
        </View>
      </Page>
    </>
  );
};

export default PickListPage;
