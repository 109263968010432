import React from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from '../../../shared/theme';

const BaseTag = styled(FlexRow)`
  min-width: 52px;
  font-weight: 500;
  font-size: 11px;
  border-radius: 4px;
  margin: 0 2px;
  
  @media ${theme.device.laptopL} {
    padding: 4px 8px;
    font-size: 14px;
  }
  
  &:last-of-type {
    margin-right: 8px;
  }
`;

export const OrderNumberTag = styled(BaseTag)`
  background-color: black;
  color: white;
  margin-right: 4px;
`;

export const BlockAndBoundTag = styled(BaseTag)`
  background-color: teal;
  color: ${theme.palette.neutral.white};
`;

export const CompoundRadiusTag = styled(BaseTag)`
  background-color: #b7eb8f;
  color: #0A0A0A;
`;

export const TiltbackTag = styled(BaseTag)`
  background-color: #870083;
  color: ${theme.palette.neutral.white};
`;

export const HouseSampleTag = styled(BaseTag)`
  background-color: #4087c5;
  color: ${theme.palette.neutral.white};
`;

export const ToppedTag = styled(BaseTag)`
  background-color: #e000d9;
  color: ${theme.palette.neutral.white};
`;

export const WRSeverityWrapper = styled(FlexRow)`
  padding-top: 1px;
  gap: 3px;
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  flex-direction: row-reverse;
  
  @media ${theme.device.laptopL} {
    flex-direction: column;
    left: unset;
    bottom: unset;
    right: 0;
  }
`;

export const WRSeverityBar = styled.div`
  height: 3px;
  width: 8px;
  background-color: ${(props: any) => (props.live ? theme.palette.neutral.black : '#E1BB00')};
`;
export const WeightReducedTag = styled(BaseTag)`
  background-color: #ffd608;
  color: ${theme.palette.neutral.black};
  
  & span {
    margin-bottom: 2px;
    @media ${theme.device.laptopL} {
      margin-right: 4px;
      margin-bottom: unset;
      padding-right: 4px;
    }
  }
`;

export const EmptyTag = styled(BaseTag)`
  display: none;
  background-color: transparent;
  padding: 0 8px;
  box-shadow:inset 0px 0px 0px 3px transparent;
  @media ${theme.device.laptopL} {
    display: unset;
  }
`;

export const AndiTag = styled(BaseTag)`
  font-size: 14px;
  box-shadow: inset 0px 0px 0px 2px #358717;
  background-color: transparent;
  color: #358717;
  font-weight: bolder;
  @media ${theme.device.laptopL} {
    padding: 0 8px;
    font-size: 18px;
    box-shadow:inset 0px 0px 0px 3px #358717;
    margin-left: -6px;
  }
`;

export const AnniTag = styled(AndiTag)`
  color: #EB3EC0;
  box-shadow:inset 0px 0px 0px 3px #EB3EC0;
`;

export const BackorderTag = styled(BaseTag)`
    background-color: #119082;
    color: ${theme.palette.neutral.white};
`;

export const CNCTag = styled(BaseTag)`
  background-color: #c61d75;
  color: ${theme.palette.neutral.white};
`;

export const HoldTag = styled(BaseTag)`
  background-color: rgba(0, 0, 0, 0.7);
  color: ${theme.palette.neutral.white};
`;

export const PrototypeTag = styled(BaseTag)`
  background-color: rgba(255, 255, 0, 0.25);
  color: ${theme.palette.neutral.black};
`;

export const DailyTagWrapper = styled(FlexColumn)`
  margin-left: 4px;
  @media ${theme.device.laptopL} {
    flex-direction: row;
  } 
`;

export const ScheduledTagWrapper = styled(FlexColumn)`
    margin-left: 4px;
    align-items: flex-start;
    
    @media ${theme.device.laptopL} {
        margin-left: unset;
        flex-direction: row;
        justify-content: flex-start;
        gap: 4px;
    }
`;
export const FinishDailyTag = styled(BaseTag)`
  min-width: 88px;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
    margin-left: unset;
  //background-color: ${(props: { backgroundColor: string}) => props.backgroundColor || theme.palette.primary.D300};
  //border: 1px solid rgba(255, 255, 255, 0.3);
  color: ${theme.palette.neutral.white};
  
  
  @media ${theme.device.laptopL} {
      font-size: 16px;
      margin-left: 8px;
  }
`;

export const CNCDailyTag = styled(FinishDailyTag)`
  background-color: #c61d75;
  @media ${theme.device.laptopL} {
      margin-left: unset;
  }
`;
