import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { Help } from '@styled-icons/material-twotone';
import theme from 'shared/theme';

interface IComponent {
  helpMessage: string;
  style: any;
}

const HelpIcon = styled(Help)`
  width: 24px;
  color: ${theme.palette.primary.hue};
  cursor: pointer;
`;

const HelpButton = ({ helpMessage, style = {} }:IComponent) => {
  const foo = 'bar';
  return (
    <Tooltip placement="right" title={helpMessage} overlayClassName="tooltip-overlay">
      <HelpIcon style={style} />
    </Tooltip>
  );
};

export default HelpButton;
