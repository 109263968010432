import React, { useCallback, useContext, useEffect } from 'react';
import qs from 'qs';
import { Modal } from 'antd';
import { includes } from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router, Route, Switch, useLocation,
  Redirect,
} from 'react-router-dom';

import ContentWrapper from 'pages/ContentWrapper';
import CreateEditArchetype from 'pages/ItemPricing/Components/CreateEditArchetype';
import CreateEditOption from 'pages/ItemPricing/Components/CreateEditOption';
import CreateEditWoodPart from 'pages/ItemPricing/Components/CreateEditWoodPart';
import CustomerBrowser from 'pages/Customer/CustomerBrowser';
import CustomerDetail from 'pages/Customer/CustomerDetail';
import CustomerPriceList from 'pages/Customer/CustomerPriceList';
import DataParsers from 'pages/Util/DataParsers';
import EditPricing from 'pages/ItemPricing/EditPricing';
import InventoryDetail from 'pages/Inventory/InventoryDetail';
import InventoryList from 'pages/Inventory/InventoryList';
import Login from 'pages/Auth/Login';
import MessageCenterDrawer from 'pages/MessageCenter/MessageCenterDrawer';
import NameGame from 'pages/Util/NameGame';
import NonComplianceEditor from 'pages/QualityAssurance/NonCompliance/NonComplianceEditor';
import NonComplianceReport from 'pages/QualityAssurance/NonCompliance/NonComplianceReport';
import OrdersList from 'pages/Orders/OrderRecords';
import PartNonComplianceScanner from 'pages/QualityAssurance/NonCompliance/NonComplianceScanner';
import PartRecord from 'pages/PartDetail/PartRecord';
import PartViewer from 'pages/Customer/CustomerPartViewer';
import PurchaseOrderBrowser from 'pages/PurchaseOrders/PurchaseOrderBrowser';
import QATicketBrowser from 'pages/QualityAssurance/QATickets/QATicketBrowser';
import QATicketDetail from 'pages/QualityAssurance/QATickets/QATicketDetail';
import SalesOrder from 'pages/ProductionSchedule/SalesOrders';
import SalesOrderRecord from 'pages/Orders/SalesOrderRecord';
import SettingsDrawer from 'pages/Settings/SettingsDrawer';
import ShipmentDetail from 'pages/Shipment/ShipmentDetail';
import ShipmentList from 'pages/Shipment/ShipmentList';
import ShopRouter from 'pages/ProductionSchedule/ShopRouter';
import ShopScanner from 'pages/ProductionSchedule/ShopScanner';
import ShopScannerManualEntry from 'pages/ProductionSchedule/ShopScannerManualEntry';
import UniversalDensityCalculatorByPart from 'pages/Util/DensityCalculator/UniversalDensityCalculatorByPart';
import VendorBrowser from 'pages/Vendor/VendorBrowser';
import WorkOrderRecord from 'pages/Orders/WorkOrderRecord';
import useFirebase from 'vendor/Firebase';

import theme from 'shared/theme';
import { BODY_SIZE } from 'shared/typography/constants';
import { startOfCurrentWeek } from 'shared/data/calendar';
import PrivateRouteWithDeps from 'shared/components/Routing/PrivateRouteWithDeps';
import QBOAuth from 'shared/data/QBO/auth';
import SiteMenu from 'shared/pageElements/SiteMenu';
import { useTestDataRemote, clearRedirect } from 'shared/util';
import { QBO_AUTH_PATH, QBO_USERS_ATOM } from 'shared/state/qboState';
import { userSettingsAtom } from 'shared/state/siteState';
import {
  adminUserEmailsAtom,
  customerUserEmailsAtom,
  scannerUserEmailsAtom,
  shopUserEmailsAtom, superAdminUserEmailAtom, supervisorEmailsAtom,
} from 'shared/state/routingState';

import { AuthContext, AuthProvider } from 'vendor/Firebase/AuthProvider';
import PartTerms from 'pages/ProductDevelopment/PartTerms';
import ProductDevTasks from 'pages/ProductDevelopment/ProductDevTasks';

const AppSections = () => {
  const location = useLocation();
  const { database } = useFirebase();
  const adminEmails = useRecoilValue(adminUserEmailsAtom).emails;
  const supervisorEmails = useRecoilValue(supervisorEmailsAtom).emails;
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom).emails;
  const shopUserEmails = useRecoilValue(shopUserEmailsAtom).emails;
  const scannerEmails = useRecoilValue(scannerUserEmailsAtom).emails;
  const customerEmails = useRecoilValue(customerUserEmailsAtom).emails;
  const qboAuthPath = useRecoilValue(QBO_AUTH_PATH);
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const qboUserEmails = useRecoilValue(QBO_USERS_ATOM);

  useEffect(() => {
    const intervalFunc = () => {
      // @ts-ignore
      if (currentUser && includes(qboUserEmails, currentUser.email)) {
        QBOAuth.refreshToken(database, qboAuthPath)
          .then((data) => {
            if (typeof (data) === 'object') return;
            Modal.info({
              title: 'QBO Token Refresh',
              content: 'Your Quickbooks Online token needs to be refreshed manually. Click "Refresh" to proceed.',
              onOk: () => {
                localStorage.removeItem('qbo.refreshToken');
                window.location.href = data;
              },
            });
          })
          .catch((e) => {
            Modal.error({
              title: 'QBO Connection Error',
              content: 'Something went wrong with the connection to Quickbooks Online. Please let Keith know.',
            });
          });
      }
    };

    // refresh the token every 5 minutes
    const interval = setInterval(intervalFunc, 5 * 60 * 1000);
    // call intervalFunc for the first time
    intervalFunc();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const titles: Record<string, string> = {
    schedule: 'Production Plan',
    router: 'Shop Router',
    pricing: 'Pricing',
    'pricing/edit': 'Edit Pricing',
    orders: 'Orders',
    'util/parsers': 'Utilties',
    customers: 'Customers',
    shipments: 'Shipments',
    scanner: 'Shop Scanner',
    inventory: 'Inventory',
    vendors: 'Vendors',
    'purchase-orders': 'Purchase Orders',
    'nc/report': 'Non-Compliance',
    'nc/scanner': 'Non-Compliance',
    'pricing/new-parts': 'New Parts',
  };

  const searchTitles = {
    'orders/create': 'Order Detail',
    'inventory/edit': 'Inventory Detail',
  };

  useEffect(() => {
    const root = location.pathname.replace('/', '');
    let title = 'Production Plan';
    if (!location.search) {
      title = titles[root];
      document.title = title || 'Production Plan';
    } else {
      if (root === 'orders/create') {
        const { customer, salesOrder } = qs.parse(location.search.replace('?', ''));
        if (customer && salesOrder) title = `${customer} Order #${salesOrder} - Detail`;
        else title = 'Order Detail';
      } else if (root === 'inventory') {
        title = 'Inventory List';
      } else if (root === 'inventory/edit') {
        const { partId } = qs.parse(location.search.replace('?', ''));
        if (partId) title = `${partId} - Inventory Detail`;
        else title = 'Inventory Detail';
      } else if (root === 'shipments/edit') {
        title = 'Shipment Detail';
      } else if (root === 'pricing/body' || root === 'pricing/neck') {
        const { partId } = qs.parse(location.search.replace('?', ''));
        if (partId) title = `${partId} - Pricing Detail`;
        else title = 'Customer Part Detail';
      }
      document.title = title || 'HELM';
    }
  }, [location.pathname]);

  return (
    <>
      <Switch location={location} key={location.pathname}>
        <Route key="login-root" exact path="/login">
          <Login key="login" />
        </Route>

        {/* ------------------- ORDER MANAGEMENT --------------------- */}
        <PrivateRouteWithDeps key="orders-list" exact path="/orders" deps={['customers']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails]}>
          <OrdersList />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="add-sales-order" exact path="/orders/create" deps={['inventory', 'configTerms', 'routerSteps', 'customers', 'shopOrderData']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <SalesOrderRecord />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="router-edit" exact path="/work-order/edit" deps={['routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
          <WorkOrderRecord />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="router-edit" exact path="/router/edit" deps={['routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
          <WorkOrderRecord />
        </PrivateRouteWithDeps>

        {/* ------------------- PRODUCTION SCHEDULE --------------------- */}

        <PrivateRouteWithDeps key="orders-at-root" exact path="/" deps={[]} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <Redirect to="/schedule/week" />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="orders-at-root" exact path="/schedule" deps={[]} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <Redirect to="/schedule/week" />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="orders-at-order-path" exact path="/schedule/week" deps={['routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <SalesOrder />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="customer-orders-at-order-path" exact path="/schedule/customer" deps={['routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <SalesOrder />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="next-up-orders-at-order-path" exact path="/schedule/up-next" deps={['routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <SalesOrder />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="orders-at-order-path" exact path="/schedule/requirements" deps={['routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <SalesOrder />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="router-root" exact path="/router" deps={['routerSteps']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <ShopRouter />
        </PrivateRouteWithDeps>
        {/* <PrivateRouteWithDeps key="router-edit" exact path="/router/edit" deps={['routerSteps', 'customers']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...scannerEmails]}> */}
        {/*  <RunnerDetail /> */}
        {/* </PrivateRouteWithDeps> */}

        {/* ------------------- PART VIEWER (CUSTOMER) --------------------- */}

        <Route key="part-finder-deprecated" exact path="/part-finder">
          <Redirect to="/tools/part-finder" />
        </Route>
        <PrivateRouteWithDeps key="part-finder" exact path="/tools/part-finder" deps={['settings', 'customers', 'partViewerConfig']} whitelist={[...superAdminEmails, ...adminEmails, ...customerEmails, ...supervisorEmails, ...shopUserEmails]}>
          <PartViewer />
        </PrivateRouteWithDeps>

        {/* ------------------- CUSTOMER --------------------- */}

        <PrivateRouteWithDeps key="customer-management" exact path="/customers" deps={['settings', 'customers', 'customerContacts']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <CustomerBrowser />
          {/* <CustomerManager /> */}
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="customer-detail" exact path="/customers/detail" deps={['settings', 'shippingAddresses']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <CustomerDetail />
        </PrivateRouteWithDeps>

        {/* ------------------- SHIPMENT --------------------- */}

        <PrivateRouteWithDeps key="shipment-management" exact path="/shipments" deps={['settings', 'customers']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <ShipmentList />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="shipment-detail" exact path="/shipments/create" deps={['settings', 'routerSteps']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <ShipmentDetail />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="shipment-detail" exact path="/shipments/edit" deps={['settings', 'routerSteps']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <ShipmentDetail />
        </PrivateRouteWithDeps>
        {/* <PrivateRouteWithDeps key="customer-detail" exact path="/shipments/detail" deps={['shippingAddresses']} whitelist={[...superAdminEmails, ...adminEmails]}> */}
        {/*  <CustomerDetail /> */}
        {/* </PrivateRouteWithDeps> */}

        {/* ------------------- CUSTOMER PART MANAGEMENT --------------------- */}

        <PrivateRouteWithDeps key="pricing-root" exact path="/pricing" deps={['settings', 'configTerms', 'customers', 'partData', 'partViewerConfig']} whitelist={[...superAdminEmails, ...adminEmails, ...customerEmails, ...supervisorEmails, 'edwardl@wildwoodmfg.com']}>
          <CustomerPriceList />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="pricing-edit" exact path="/pricing/edit" deps={['settings', 'configTerms', 'customers', 'partData', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <EditPricing />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="pricing-archetype-edit" exact path="/pricing/archetype/edit" deps={['settings']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <CreateEditArchetype />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="pricing-material-edit" exact path="/pricing/material/edit" deps={['settings', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <CreateEditWoodPart />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="pricing-option-edit" exact path="/pricing/option/edit" deps={['settings']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <CreateEditOption />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="pricing-neck" exact path="/pricing/neck" deps={['settings', 'configTerms', 'customers', 'partData', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, 'edwardl@wildwoodmfg.com']}>
          <PartRecord />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="pricing-body" exact path="/pricing/body" deps={['settings', 'configTerms', 'customers', 'partData', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, 'edwardl@wildwoodmfg.com']}>
          <PartRecord />
        </PrivateRouteWithDeps>
        {/* ------------------- INVENTORY MANAGEMENT --------------------- */}
        <PrivateRouteWithDeps key="inventory-list" exact path="/inventory" deps={['settings', 'inventory', 'glCodes', 'productCodes']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <InventoryList />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="inventory-detail" exact path="/inventory/edit" deps={['settings', 'inventory', 'glCodes', 'productCodes', 'routerSteps', 'vendors']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <InventoryDetail quickEdit={false} />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="inventory-create" exact path="/inventory/new" deps={['settings', 'inventory', 'glCodes', 'productCodes', 'routerSteps', 'vendors']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails]}>
          <InventoryDetail quickEdit={false} />
        </PrivateRouteWithDeps>

        {/* ------------------- PURCHASE ORDERS --------------------- */}
        <PrivateRouteWithDeps key="purchase-order-list" exact path="/purchase-orders" deps={['settings', 'inventory', 'purchaseOrders']} whitelist={[...superAdminEmails, ...adminEmails, 'edwardl@wildwoodmfg.com']}>
          <PurchaseOrderBrowser />
        </PrivateRouteWithDeps>

        {/* ------------------- VENDOR MANAGEMENT --------------------- */}
        <PrivateRouteWithDeps key="vendor-list" exact path="/vendors" deps={['settings', 'vendors']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails]}>
          <VendorBrowser />
        </PrivateRouteWithDeps>
        {/* <PrivateRouteWithDeps key="vendor-detail" exact path="/vendor/:vendorCode" deps={['vendors']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails]}> */}
        {/*  <VendorDetail /> */}
        {/* </PrivateRouteWithDeps> */}

        {/* ------------------- UTILITY --------------------- */}

        <PrivateRouteWithDeps key="parsers-list" exact path="/util/parsers" deps={['settings', 'configTerms', 'inventory', 'routerSteps', 'customers', 'openOrders']} whitelist={[...superAdminEmails, 'christineh@wildwoodmfg.com']}>
          <DataParsers />
        </PrivateRouteWithDeps>
        <Route key="udc-deprecated" exact path="/util/udc">
          <Redirect to="/tools/udc" />
        </Route>
        <PrivateRouteWithDeps key="weight-chart-by-part" exact path="/util/density-calculator" deps={['settings', 'customers']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <UniversalDensityCalculatorByPart />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="name-game" exact path="/util/name-game" deps={['settings', 'configTerms']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <NameGame />
        </PrivateRouteWithDeps>

        {/* ------------------- SCANNERS --------------------- */}

        {/* ------------------- ROUTER SCANNER --------------------- */}
        <PrivateRouteWithDeps key="scanner-root" exact path="/scanner" deps={['settings', 'routerSteps', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
          <ShopScanner />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="scanner-manual" exact path="/scanner/manual-entry" deps={['settings', 'routerSteps']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
          <ShopScannerManualEntry />
        </PrivateRouteWithDeps>

        {/* ------------------- QUALITY ASSURANCE & NON_COMPLIANCE --------------------- */}
        <PrivateRouteWithDeps key="qa-ticket-browser" exact path="/qa/tickets" deps={['settings']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails]}>
          <QATicketBrowser />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="qa-ticket-new" exact path="/qa/ticket/new" deps={['settings', 'customers', 'routerSteps', 'operatorData', 'ncConfig']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails]}>
          <QATicketDetail />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="qa-ticket-edit" exact path="/qa/ticket/edit" deps={['settings', 'customers', 'routerSteps', 'operatorData', 'ncConfig']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails]}>
          <QATicketDetail />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="non-compliance-scanner" exact path="/nc/scanner" deps={['settings']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
          <PartNonComplianceScanner />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="non-compliance-scanner" exact path="/nc/record" deps={['settings', 'operatorData', 'ncConfig', 'routerSteps']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
          <NonComplianceEditor />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="non-compliance-report" exact path="/nc/report" deps={['settings', 'operatorData', 'nonConformanceData', 'ncConfig']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
          <NonComplianceReport />
        </PrivateRouteWithDeps>

        {/* ------------------- NEW PARTS --------------------- */}
        <PrivateRouteWithDeps key="new-parts" exact path="/pd/queue" deps={['settings', 'openOrders', 'configTerms']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <ProductDevTasks />
        </PrivateRouteWithDeps>
        <PrivateRouteWithDeps key="part-terms" exact path="/pd/part-terms" deps={['settings', 'configTerms']} whitelist={[...superAdminEmails, ...adminEmails]}>
          <PartTerms />
        </PrivateRouteWithDeps>

      </Switch>
    </>
  );
};

const App = () => {
  // const setSalesOrderViewType = useSetRecoilState(orderViewTypeAtom);
  // const setOrderViewType = useSetRecoilState(orderPartViewTypeAtom);
  // const setShowBodies = useSetRecoilState(showBodiesAtom);
  // const setShowNecks = useSetRecoilState(showNecksAtom);

  // set default order view type
  // if (!localStorage.getItem('salesOrderViewType')) {
  //   setSalesOrderViewType('week');
  //   localStorage.setItem('salesOrderViewType', 'week');
  // }
  // if (!localStorage.getItem('partViewType')) {
  //   setOrderViewType('all');
  //   setShowBodies(true);
  //   setShowNecks(true);
  //   localStorage.setItem('partViewType', 'all');
  // }
  // const useTestData = useRecoilValue(useTestDataAtom);

  // const commandMode = false;
  let keystack: string[] = [];

  const handleKeyPress = useCallback((event) => {
    if (event.ctrlKey && event.code === 'ShiftLeft') {
      keystack = [];
      return;
    }
    keystack.push(event.code);
    if (keystack[0] === 'Escape') {
      if (keystack[0] === 'Escape' && includes(['o', 'r', 'd', 's', 'f', 'u', 'p', 'c', 'v', 'h', 'j', 'k'], event.key)) {
        if (event.key === 'o') {
          localStorage.setItem('currentMenuSection', 'tables');
          window.location.href = '/orders';
        }
        if (event.key === 's') {
          localStorage.setItem('currentMenuSection', 'production');
          window.location.href = '/schedule';
        }
        if (event.key === 'r') {
          localStorage.setItem('currentMenuSection', 'production');
          window.location.href = '/router';
        }
        if (event.key === 'd') {
          localStorage.setItem('currentMenuSection', 'density_calculator');
          window.location.href = '/tools/udc';
        }
        // if (event.key === 'f') window.location.href = '/tools/part-finder';
        if (event.key === 'u') {
          localStorage.setItem('currentMenuSection', 'parsers');
          window.location.href = '/util/parsers';
        }
        if (event.key === 'p') {
          localStorage.setItem('currentMenuSection', 'pricing');
          window.location.href = '/pricing';
        }
        if (event.key === 'c') {
          localStorage.setItem('currentMenuSection', 'customers');
          window.location.href = '/customers';
        }
        if (event.key === 'h') {
          alert('QUICK NAVIGATE!!\r\r========================\r\rPress Esc, then press any key to navigate:\r\ro = orders (production plan)\r\rr = router\r\rd = density calculator\r\rp = pricing\r\rc = customers\r\rv = switch customer view type');
          keystack = [];
        }
        // if (event.key === 'j') {
        //   window.scrollTo(window.scrollX, window.scrollY + 500);
        //   keystack = [];
        // }
        // if (event.key === 'k') {
        //   window.scrollTo(window.scrollX, window.scrollY - 500);
        //   keystack = [];
        // }
        if (event.key === 'v') {
          if (localStorage.getItem('salesOrderViewType') === 'Week') {
            localStorage.setItem('salesOrderViewType', 'Customer');
            window.location.hash = '';
          } else {
            localStorage.setItem('salesOrderViewType', 'Week');
            window.location.hash = startOfCurrentWeek().toString();
          }
          keystack = [];
        }
      } else if (keystack.length >= 3) {
        keystack = [];
      }
    } else {
      keystack = [];
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('hashchange', clearRedirect);
    // remove the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('hashchange', clearRedirect);
      // clearInterval(interval);
    };
  }, [handleKeyPress]);

  const GlobalStyle = createGlobalStyle`
    html {
      font-size: ${BODY_SIZE}px;
    }
    body {
      background-color: ${useTestDataRemote ? 'rgba(255, 0, 0, 0.075)' : window.location.href.match(/nc-scanner/) ? '#F0F0F0' : '#FFF'};
      & p {
        color: ${useTestDataRemote ? '#FF0000' : 'unset'};
      }
      & h3 {
        color: ${useTestDataRemote ? '#FF0000' : 'unset'};
      }
      & h6 {
        color: ${useTestDataRemote ? '#FF0000' : 'unset'};
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${useTestDataRemote ? '#FF0000' : '#1e85d3'};
      border-color: ${useTestDataRemote ? '#FF0000' : '#1e85d3'};
    }
    .ant-btn-primary {
      background-color: ${useTestDataRemote ? '#7a211b' : '#1e85d3'};
      border-color: ${useTestDataRemote ? '#7a211b' : '#1e85d3'};
      
      &:hover {
        border-color: ${useTestDataRemote ? '#c72d22' : '#1e85d3'};
        background-color: ${useTestDataRemote ? '#c72d22' : '#1e85d3'};
        filter: brightness(95%);
      }
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${useTestDataRemote ? '#FF0000' : '#1e85d3'};
      border-color: ${useTestDataRemote ? '#FF0000' : '#1e85d3'};

      &:hover {
        border-color: ${useTestDataRemote ? '#FF0000' : '#1e85d3'};
        background-color: ${useTestDataRemote ? '#FF0000' : '#1e85d3'};
        filter: brightness(95%);
      }
    }
    .ant-menu-submenu-title, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
      & a {
        color: ${useTestDataRemote ? '#FF0000' : '#1e85d3'};
      }
      &:hover {
        color: ${useTestDataRemote ? '#FF0000' : '#1e85d3'};
        //border-bottom: 2px solid ${useTestDataRemote ? '#FF0000' : '#1e85d3'};
      }
    }
  `;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AuthProvider>
        <Router>
          <SiteMenu />
          <MessageCenterDrawer />
          <SettingsDrawer />
          <ContentWrapper>
            <AppSections />
          </ContentWrapper>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
