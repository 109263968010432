import React from 'react';
import {
  View, Text, StyleSheet,
} from '@react-pdf/renderer';
import { IOrderItem } from 'shared/types/dbRecords';
import { includes } from 'lodash';
import { cleanMeta, isMeta } from '../../../../shared/text';
import PartSku from '../../../Customer/Components/PriceList/Components/PartSku';

const bodyFontSize = 9;
const style = StyleSheet.create({
  pickItemRow: {
    width: 550,
    height: 28,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingBottom: 4,
    // marginBottom: 2,
    borderBottom: '1px solid lightgrey',
  },
  pickItemDetailRow: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  orderItemNumberRow: {
    width: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  partNumber: {
    width: 60,
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: bodyFontSize,
    // padding: 2,
    margin: 0,
    marginLeft: 2,
  },
  descriptionColumn: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  description: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 700,
    fontSize: bodyFontSize,
    margin: 0,
    textAlign: 'left',
  },
  quantity: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 700,
    fontSize: bodyFontSize + 2,
    margin: 0,
  },
  quantityWrapper: {
    height: 28,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    marginRight: 2,
  },
  initialsCheckbox: {
    width: 28,
    height: 28,
    border: '1px solid lightgrey',
  },
  notes: {
    fontFamily: 'Red Hat Display',
    fontWeight: 300,
    fontSize: bodyFontSize - 1,
    margin: 0,
    marginLeft: 4,
    marginTop: 1,
    color: '#666666',
  },
});

interface IComponent {
  Sku: string;
  Description: string;
  PullQty: number;
  PartsNeededFor: { PartSku: string; PartCount: number; }[];
}
const PickItem = ({
  Sku, Description, PullQty, PartsNeededFor,
}: IComponent) => {
  const partsNeeded = PartsNeededFor.map((p: { PartSku: string, PartCount: number }) => `${p.PartSku} (${p.PartCount})`).join(', ');
  return (
    <>
      <View style={style.pickItemRow} wrap={false}>
        <View style={style.pickItemDetailRow}>
          <Text style={style.partNumber}>
            {Sku}
          </Text>
          <View style={style.descriptionColumn}>
            <Text style={style.description}>
              <>
                {Description}
              </>
            </Text>
            <Text style={style.notes}>{partsNeeded}</Text>
          </View>
        </View>
        <View style={style.quantityWrapper}>
          <Text style={style.quantity}>{PullQty}</Text>
        </View>
        <View style={style.initialsCheckbox} />
      </View>
    </>
  );
};

export default PickItem;
