import React, { useContext, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  find, flatten, includes, sortBy,
} from 'lodash';
// import * as _ from 'lodash';
import qs from 'qs';
import {
  NON_CONFORMANCE_COLLECTION, ROUTER_DATA_PATH,
  shopOperatorsObjectAtom, GL_CODES_COLLECTION, PRODUCT_CODES_COLLECTION, userSettingsAtom,
} from 'shared/state/siteState';
import { routerStepsAtom, customerUserEmailsAtom } from 'shared/state/routingState';
import {
  BODY_DATA_PATH,
  bodyDataAtom, customerPartsAtom, NECK_DATA_PATH, neckDataAtom,
} from 'shared/state/pricingState';

import {
  PART_CONFIG_TERMS_COLLECTION,
  PART_CONFIG_TERM_TYPES_COLLECTION,
  PART_VIEWER_COLLECTION,
  partConfigTermsAtom,
  partConfigTermTypesAtom,
  partViewerConfigDataAtom,
  newCustomerPartsAtom,
} from 'shared/state/partViewState';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { useParams } from 'react-router-dom';
import firebase from 'firebase';
import {
  glCodesAtom,
  ncCorrectiveActionsObjectAtom,
  ncReasonsObjectAtom,
  ncRecordsAtom,
  productCodesAtom,
} from 'shared/state/utilState';
import {
  accessToken, orderShippingAddress, orderBillingAddress,
} from 'shared/data/jb_api';
import {
  ICustomerRecord,
  IGlCode,
  IInventoryPart,
  INCRecord,
  IOrderItem,
  IPurchaseOrder,
  IQATicket,
  IShipment,
  IShippingAddress,
} from 'shared/types/dbRecords';
import {
  IContact,
  IProductCode,
} from 'shared/types/jb';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from 'shared/state/inventoryState';
import { IVendor } from 'shared/types/vendor';
import { VENDOR_COLLECTION, vendorRecordsAtom } from 'shared/state/vendorState';
import {
  currentShopOrderAtom, openOrdersDataAtom, ORDER_ITEMS_DB_COLLECTION,
  orderBillingDataAtom,
  orderItemsAtom,
  ORDERS_DB_COLLECTION, orderShipmentsAtom,
  orderShippingDataAtom, shopOrdersAtom,
} from 'shared/state/orderState';
import { useTestData, urlQueryString } from 'shared/util';
import { ISalesOrder, IShopOrder } from 'pages/Orders/types';
import {
  activeCustomerDisplayAtom,
  currentCustomerAtom,
  currentCustomerShippingAddressesAtom,
  CUSTOMER_CONTACT_DB_COLLECTION, CUSTOMER_SHIPPING_DB_COLLECTION,
  customerContactsAtom,
  CUSTOMERS_DB_COLLECTION,
  customersAtom,
  customerShippingAddressesAtom,
} from 'shared/state/customerState';
import { devLog } from 'shared/util/logging';
import { purchaseOrdersAtom } from 'shared/state/purchaseOrderState';
import { SHIPMENT_ITEMS_COLLECTION } from 'shared/state/shipmentState';
import { QA_TICKETS_COLLECTION, qualityAssuranceTicketsAtom } from 'shared/state/qualityAssuranceState';
import { orderTermsByRank } from 'shared/partParser/util';
import { IConfigTerm, IConfigTermType, ICustomerPart } from 'shared/types/parts';

export default (dependencyList: string[]) => {
  const { database, firestore } = useFirebase();
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const routeParams = useParams();
  const [routerSteps, setRouterSteps] = useRecoilState(routerStepsAtom);
  const [customers, setCustomers] = useRecoilState(customersAtom);
  const [customerContacts, setCustomerContacts] = useRecoilState(customerContactsAtom);
  const [customerShippingAddresses, setCustomerShippingAddresses] = useRecoilState(customerShippingAddressesAtom);
  const [ncRecords, setNcRecords] = useRecoilState(ncRecordsAtom);
  const [openOrders, setOpenOrders] = useRecoilState(openOrdersDataAtom);
  const [orders, setOrders] = useRecoilState(shopOrdersAtom);
  const [bodyData, setBodyData] = useRecoilState(bodyDataAtom);
  const [neckData, setNeckData] = useRecoilState(neckDataAtom);
  const [inventoryItems, setInventoryItems] = useRecoilState(inventoryItemsAtom);
  const [purchaseOrders, setPurchaseOrders] = useRecoilState(purchaseOrdersAtom);
  const [glCodes, setGlCodes] = useRecoilState(glCodesAtom);
  const [productCodes, setProductCodes] = useRecoilState(productCodesAtom);
  const [partViewerConfigData, setPartViewerConfigData] = useRecoilState(partViewerConfigDataAtom);
  const [ncReasonCodes, setNcReasonCodes] = useRecoilState(ncReasonsObjectAtom);
  const [ncCorrectiveActions, setNcCorrectiveActions] = useRecoilState(ncCorrectiveActionsObjectAtom);
  const [newParts, setNewParts] = useRecoilState(newCustomerPartsAtom);
  const [shopOperators, setShopOperators] = useRecoilState(shopOperatorsObjectAtom);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const [customerParts, setCustomerParts] = useRecoilState(customerPartsAtom);
  const [currentCustomerShippingAddresses, setCurrentCustomerShippingAddresses] = useRecoilState(currentCustomerShippingAddressesAtom);
  const [vendors, setVendors] = useRecoilState(vendorRecordsAtom);
  const [qaTickets, setQaTickets] = useRecoilState(qualityAssuranceTicketsAtom);
  const setCurrentShopOrder = useSetRecoilState(currentShopOrderAtom);
  const setOrderShipments = useSetRecoilState(orderShipmentsAtom);
  const [billingData, setBillingData] = useRecoilState(orderBillingDataAtom);
  const customersDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const customerContactDbString = useRecoilValue(CUSTOMER_CONTACT_DB_COLLECTION);
  const customerShippingAddressDbString = useRecoilValue(CUSTOMER_SHIPPING_DB_COLLECTION);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const vendorsDbString = useRecoilValue(VENDOR_COLLECTION);
  const qaTicketsDbCollection = useRecoilValue(QA_TICKETS_COLLECTION);
  const glCodeDbString = useRecoilValue(GL_CODES_COLLECTION);
  const nonConformanceDbString = useRecoilValue(NON_CONFORMANCE_COLLECTION);
  const productCodeDbString = useRecoilValue(PRODUCT_CODES_COLLECTION);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const orderShipmentsDbString = useRecoilValue(SHIPMENT_ITEMS_COLLECTION);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const bodyDataString = useRecoilValue(BODY_DATA_PATH);
  const neckDataString = useRecoilValue(NECK_DATA_PATH);
  const routerDataString = useRecoilValue(ROUTER_DATA_PATH);
  const partConfigTermsCollection = useRecoilValue(PART_CONFIG_TERMS_COLLECTION);
  const partConfigTermTypesCollection = useRecoilValue(PART_CONFIG_TERM_TYPES_COLLECTION);
  const [partConfigTerms, setPartConfigTerms] = useRecoilState(partConfigTermsAtom);
  const [partConfigTermTypes, setPartConfigTermTypes] = useRecoilState(partConfigTermTypesAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const customerViewType = useRecoilValue(activeCustomerDisplayAtom);
  const [userSettings, setUserSettings] = useRecoilState(userSettingsAtom);

  const dbDeps = {
    routerSteps: {
      fn: async () => {
        database.ref(`${routerDataString}/steps`).once('value').then((snapshot) => {
          const data = snapshot.val();
          setRouterSteps(data);
          devLog('useDependencies', 92, 'Router steps loaded');
        });
      },
      test: () => routerSteps.length > 0,
    },
    configTerms: {
      fn: async () => {
        const terms = {};
        const termTypes: IConfigTermType[] = [];
        const termTypesDocs = await firestore.collection(partConfigTermTypesCollection).get();
        const termsDocs = await firestore.collection(partConfigTermsCollection).get();
        termTypesDocs.docs.forEach((d) => {
          // @ts-ignore
          termTypes.push({ ...d.data(), id: d.id });
        });
        termsDocs.docs.forEach((d) => {
          const termData = d.data();
          // @ts-ignore
          terms[d.id] = termData;
        });
        setPartConfigTerms(terms as IConfigTerm[]);
        setPartConfigTermTypes(termTypes as IConfigTermType[]);
      },
      test: () => partConfigTermTypes.length > 0 && Object.keys(partConfigTerms).length > 0,
    },
    customers: {
      fn: async () => {
        const includeInactive = includes(['all', 'inactive'], customerViewType);
        devLog('useDependencies', 115, `Customer view type: ${customerViewType}`);
        devLog('useDependencies', 115, `Show inactive customers: ${includeInactive}`);
        devLog('useDependencies', 115, routeParams);
        const customerDocs = await firestore.collection(customersDbString).get();
        const _customers = customerDocs.docs.map((c) => c.data() as ICustomerRecord)
          .filter((c: ICustomerRecord) => c.active || includeInactive);
        setCustomers(sortBy(_customers, (d: ICustomerRecord) => d.DisplayName));

        if (includes(customerEmails.emails, currentUser.email)) {
          const login = currentUser.email.split('@')[0];
          const cust = find(_customers, (c: ICustomerRecord) => c.login === login);
          if (cust) setCurrentCustomer(cust);
        } else if (localStorage.getItem('currentCustomerId')) {
          const localId = localStorage.getItem('currentCustomerId');
          const cust = find(_customers, (c: ICustomerRecord) => c.id === localId);
          if (cust) setCurrentCustomer(cust);
        }
        devLog('useDependencies', 166, 'Customers loaded');
      },
      test: () => customers.length > 0,
    },
    newCustomerParts: {
      fn: async () => {
        firestore.collection(partViewerDbString)
          .where('new', '==', true)
          .onSnapshot((snapshot) => {
            const data: ICustomerPart[] = snapshot.docs.map((d) => d.data() as ICustomerPart);
            setNewParts(data);
          });
      },
      test: () => newParts.length > 0,
    },
    customerContacts: {
      fn: async () => {
        firestore.collection(customerContactDbString).get().then((snap) => {
          const data: IContact[] = [];
          snap.forEach((doc) => {
            data.push(doc.data().contacts as IContact);
          });
          setCustomerContacts(flatten(data));
        });
      },
      test: () => customerContacts.length > 0,
    },
    glCodes: {
      fn: async () => {
        const glCodeDocs = await firestore.collection(glCodeDbString).get();
        const data: IGlCode[] = glCodeDocs.docs.map((g) => g.data() as IGlCode);
        setGlCodes(data);
        devLog('useDependencies', 186, 'GL Codes loaded');
        devLog('useDependencies', 187, data);
      },
      test: () => glCodes.length > 0,
    },
    inventory: {
      fn: async () => {
        firestore.collection(inventoryDbString).get().then((snapshot) => {
          const data: IInventoryPart[] = snapshot.docs.map((d) => d.data() as IInventoryPart);
          setInventoryItems(data);
          devLog('useDependencies', 195, 'Inventory loaded');
          devLog('useDependencies', 196, data);
        });
      },
      test: () => inventoryItems.length > 0,
    },
    purchaseOrders: {
      fn: async () => {
        firestore.collection('purchase-orders').get().then((snapshot) => {
          const data: IPurchaseOrder[] = snapshot.docs.map((d) => d.data() as IPurchaseOrder);
          if (!data.length) setPurchaseOrders([null]);
          setPurchaseOrders(data);
          devLog('useDependencies', 208, 'Purchase Orders loaded');
        });
      },
      test: () => purchaseOrders.length > 0,
    },
    openOrders: {
      fn: async () => {
        const orderDocs = await firestore.collection(ordersDbString).where('completed', '!=', true).get();
        const _orders = await Promise.all(orderDocs.docs.map((d) => {
          const data = d.data() as IShopOrder;
          return firestore.collection(orderItemsDbString).doc(d.id).get().then((itemDoc) => {
            const _orderItems = itemDoc.exists ? (itemDoc.data()?.orderItems || []) as IOrderItem[] : [];
            return { ...data, orderItems: _orderItems };
          });
        }));
        setOpenOrders(_orders);
      },
      test: () => openOrders.length > 0,
    },
    allOrders: {
      fn: async () => {
        firestore.collection(ordersDbString)
          .get().then((snapshot) => {
            const data: ISalesOrder[] = [];
            snapshot.forEach((d) => {
              data.push(d.data() as ISalesOrder);
            });
            setOrders(data as ISalesOrder);
          });
      },
      test: () => orders.length > 0,
    },
    productCodes: {
      fn: async () => {
        const productCodeDocs = await firestore.collection(productCodeDbString).get();
        const data: IProductCode[] = productCodeDocs.docs.map((p) => p.data() as IProductCode);
        // @ts-ignore
        setProductCodes(data);
        devLog('useDependencies', 243, 'Product Codes loaded');
        devLog('useDependencies', 244, data);
      },
      test: () => productCodes.length > 0,
    },
    nonConformanceData: {
      fn: async () => {
        firestore.collection(nonConformanceDbString)
          .get().then((snapshot) => {
            const data: INCRecord[] = [];
            snapshot.forEach((c) => {
              data.push(c.data() as INCRecord);
            });
            if (!data.length) {
              const dateRecorded = firebase.firestore.Timestamp.fromDate(new Date(1980, 0, 21));
              setNcRecords([{
                id: 'foooooo',
                dateRecorded,
                salesOrder: '9999',
                customerId: 'FOOBAR',
                workOrder: '1/1',
                department: 'Office',
                partNumber: '666',
                partDescription: 'The Devil\'s Bane',
                quantity: 1,
                reasonCode: 'Other',
                correctiveAction: 'None',
                notes: 'Blue hunnids',
                type: 'body',
              }]);
            } else {
              setNcRecords(_.sortBy(data, (d: INCRecord) => d.dateRecorded.toDate()));
            }
          });
      },
      test: () => ncRecords.length > 0,
    },
    partData: {
      fn: async () => {
        database.ref('/').once('value').then((dbData) => {
          const bData = dbData.val()[bodyDataString];
          const nData = dbData.val()[neckDataString];
          setBodyData(bData);
          setNeckData(nData);
        });
      },
      test: () => Object.keys(bodyData).length > 0 && Object.keys(neckData).length > 0,
    },
    partViewerConfig: {
      fn: async () => {
        database.ref('/customerPartViewerConfig').once('value').then((dbData) => {
          const data = dbData.val();
          if (_.includes(customerEmails.emails, currentUser.email)) {
            const customerKey = _.find(Object.keys(data), (k: string) => currentUser.email.match(new RegExp(`^${k.slice(0, 4)}`, 'i')));
            const config = {
              UNIVERSAL: data.UNIVERSAL,
            };
            config[customerKey] = data[customerKey];
            setPartViewerConfigData(config);
          } else {
            setPartViewerConfigData(data);
          }
        });
      },
      test: () => Object.keys(partViewerConfigData).length > 1,
    },
    ncConfig: {
      fn: async () => {
        database.ref('/ncConfig').once('value').then((dbData) => {
          const { correctiveActions, reasonCodes } = dbData.val();
          setNcCorrectiveActions(correctiveActions);
          setNcReasonCodes(reasonCodes);
        });
      },
      test: () => (Object.values(ncCorrectiveActions).length > 0 && Object.values(ncReasonCodes).length > 0),
    },
    operatorData: {
      fn: async () => {
        database.ref('/operators').once('value').then((dbData) => {
          const operators = dbData.val();
          setShopOperators(operators);
        });
      },
      test: () => !!shopOperators.length,
    },
    orderBillingData: {
      fn: async () => {
        const currentOrderId = qs.parse(window.location.href.replace('?', '')).orderId;
        if (currentOrderId === 'ph') {
          setBillingData(false);
          return;
        }
        if (currentOrderId) {
          firestore.collection(ordersDbString).doc(currentOrderId).get().then((doc) => {
            if (!doc.exists) setBillingData(false);
            const id = doc.data().customer.id;

            accessToken().then((token) => {
              // @ts-ignore
              orderBillingAddress(token, id)
                .then((res) => {
                  // @ts-ignore
                  setBillingData(res);
                  devLog('useDependencies', 282, 'Billing info loaded');
                })
                .catch((err) => setBillingData(false));
            });
          });
        }
      },
      test: () => billingData !== null,
    },
    shopOrderData: {
      fn: async () => {
        const currentOrderId = qs.parse(window.location.href.replace('?', '')).orderId;
        if (currentOrderId) {
          // get the shop order data
          const shopOrderDoc = await firestore.collection(ordersDbString).doc(currentOrderId).get();
          const shopOrderData = shopOrderDoc.data();
          if (!shopOrderDoc.exists || !shopOrderData) {
            setOrderItems([null]);
            return;
          }
          setCurrentShopOrder(shopOrderData as IShopOrder);
          setCurrentCustomer(shopOrderData.customer);
          devLog('useDependencies', 360, 'shop order data loaded');

          const customerPartDocs = await firestore.collection(partViewerDbString).where('customer', '==', shopOrderData.customer.DisplayName).get();
          setCustomerParts(customerPartDocs.docs.map((d) => {
            const partData = d.data();
            return { ...partData, Description: orderTermsByRank(partData.config).join('_') };
          }) as any);
          devLog('useDependencies', 364, 'customer parts data loaded');

          // @ts-ignore
          const orderItemDoc = await firestore.collection(orderItemsDbString).doc(shopOrderDoc.id).get();
          const itemsData = orderItemDoc.data();
          if (!orderItemDoc.exists || !itemsData || Object.keys(itemsData).length === 0 || itemsData.orderItems.filter((i: IOrderItem) => i).length === 0) {
            setOrderItems([null]);
          } else {
            const orderItemData = itemsData.orderItems
              .filter((i: IOrderItem) => !i.Sku.match(/^[1]/))
              .map((i: IOrderItem) => ({
                ...i,
                Sku: i.Sku,
                Description: i.Description,
                quantityOpen: i.quantityOrdered - (i.quantityShipped + i.quantityCanceled),
              }));
            setOrderItems(orderItemData);
            devLog('useDependencies', 338, 'shop order items loaded');
          }
          const shippingAddressesDoc = await firestore.collection(customerShippingAddressDbString).doc(shopOrderData.customer.DisplayName).get();
          const shippingAddresses = shippingAddressesDoc.data()?.shippingAddresses;
          if (!shippingAddressesDoc.exists || !shippingAddresses || shippingAddresses.filter((i: IOrderItem) => i).length === 0) {
            setCurrentCustomerShippingAddresses([null]);
          } else {
            setCurrentCustomerShippingAddresses(shippingAddresses as IShippingAddress[]);
            devLog('useDependencies', 374, `shipping addresses for ${shopOrderData.customer.DisplayName} loaded`);
          }

          const orderShipmentsDoc = await firestore.collection(orderShipmentsDbString).where('orderId', '==', currentOrderId).get();
          const orderShipments = orderShipmentsDoc.docs.map((d) => d.data() as IShipment);
          setOrderShipments(orderShipments);
        } else {
          devLog('useDependencies', 376, 'No order data found, exiting dependency loader');
          setOrderItems([null]);
        }
      },
      test: () => orderItems && !!orderItems.length,
    },
    shippingAddresses: {
      fn: async () => {
        // @ts-ignore
        const { customer } = qs.parse(urlQueryString(window.location.href)) as string;
        const shippingAddressesDoc = await firestore.collection(customerShippingAddressDbString).doc(customer).get();
        const shippingAddresses = shippingAddressesDoc.data()?.shippingAddresses;
        if (!shippingAddressesDoc.exists || !shippingAddresses || shippingAddresses.filter((i: IOrderItem) => i).length === 0) {
          setCurrentCustomerShippingAddresses([null]);
        } else {
          setCurrentCustomerShippingAddresses(shippingAddresses as IShippingAddress[]);
          devLog('useDependencies', 338, `shipping addresses for ${customer} loaded`);
        }
      },
      test: () => !!currentCustomerShippingAddresses.length,
    },
    qualityAssuranceTickets: {
      fn: async () => {
        const qaTicketDocs = await firestore.collection(qaTicketsDbCollection).get();
        const qaTicketData = qaTicketDocs.docs.map((d) => d.data() as IQATicket);
        setQaTickets(qaTicketData);
      },
      test: () => qaTickets.length > 0,
    },
    vendors: {
      fn: async () => {
        firestore.collection(vendorsDbString).get().then((snapshot) => {
          const data: IVendor[] = [];
          snapshot.forEach((v) => {
            data.push(v.data() as IVendor);
          });
          setVendors(data);
        });
      },
      test: () => vendors.length > 0,
    },
    settings: {
      fn: () => {
        if (!includes(customerEmails.emails, currentUser.email)) {
          database.ref(`/settings/${currentUser.email.split('@')[0]}`).once('value').then((ref) => {
            const data = ref.val();
            if (!data) {
              const defaultData = { boolean: { useTestData: false } };
              database.ref(`/settings/${currentUser.email.split('@')[0]}`).set(defaultData).then(() => {
                setUserSettings(defaultData);
              });
            } else {
              setUserSettings(ref.val());
            }
          });
        } else {
          setUserSettings({ isCustomer: true });
        }
      },
      test: () => Object.keys(userSettings).length > 0,
    },
  };

  // @ts-ignore
  const depsReady = () => _.every(dependencyList.map((d: string) => dbDeps[d].test()));

  // @ts-ignore
  useEffect(() => {
    if (!currentUser) return;

    dependencyList.forEach((d: string) => {
      // @ts-ignore
      dbDeps[d].fn();
    });
  }, [useTestData]);

  return depsReady;
};
