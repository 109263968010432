import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { find } from 'lodash';
import LabelCounter from 'shared/buttons/LabelCounter';
import { currentShopOrderAtom, orderItemsAtom } from 'shared/state/orderState';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { IOrderItem } from 'shared/types/dbRecords';
import { customerPartsAtom } from 'shared/state/pricingState';
import { reduceObjectArrayValues } from 'shared/data';
import { IWeightReductionObject } from 'shared/types/order';
import OrderWeightReductionTag from './OrderWeightReductionTag';

interface IWeightReductionTag {
  label: string;
  count: number;
}

interface IComponent {
  callback: (weightReduction: IWeightReductionObject) => void;
}
const OrderWeightReduction = ({ callback }: IComponent) => {
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const orderItems = useRecoilValue(orderItemsAtom);
  const customerParts = useRecoilValue(customerPartsAtom);
  const [weightReduction, setWeightReduction] = useState({
    none: 0,
    cavity: 0,
    carveTop: 0,
    slot: 0,
    honeycomb: 0,
  });
  useEffect(() => {
    const weightReductionItems = orderItems.map((o: IOrderItem) => {
      const wrObject = {
        none: 0, cavity: 0, carveTop: 0, slot: 0, honeycomb: 0,
      };
      const part = find(customerParts, (p: any) => p.Sku === o.Sku);
      if (!part) return wrObject;
      const isCarveTop = !!part.Description.match(/_CT_|semihollow|semi[-\s]hollow/i);
      const isHoneycomb = !!part.Description.match(/HC_/);
      const isSlot = !!part.Description.match(/WR_/) && !part.Description.match(/HC_/);
      const isTL = !!part.Description.match(/GB_TL/);
      if (isCarveTop) return { ...wrObject, carveTop: o.quantityOpen };
      if (isHoneycomb) return { ...wrObject, honeycomb: o.quantityOpen };
      if (isSlot) return { ...wrObject, slot: o.quantityOpen };
      if (isTL) return { ...wrObject, cavity: o.quantityOpen };
      return { wrObject };
    });

    const weightReductionObject = reduceObjectArrayValues(weightReductionItems);
    setWeightReduction(weightReductionObject);
    callback(weightReductionObject);
  }, [orderItems, customerParts]);

  const onChange = (key: 'honeycomb'|'cavity'|'slot'|'carveTop'|'none') => (n: number) => {
    const newWeightReduction = { ...weightReduction };
    newWeightReduction[key] = n;
    setWeightReduction(newWeightReduction);
    setCurrentShopOrder(({
      ...currentShopOrder,
      weightReduction: newWeightReduction,
    }));
    callback(newWeightReduction);
  };

  return (
    <ItemDetailsColumnWrapper key="order-value-item-details-wrapper">
      <ItemDetailsLabel key="order-value-item-details-label">Weight Reduction</ItemDetailsLabel>
      {weightReduction.none > 0 && (
      <LabelCounter
        width="auto"
        useIncrementValue={false}
        label="None"
        initialValue={weightReduction.none || 0}
        minValue={0}
        maxValue={currentShopOrder.partCount}
        callback={onChange('none')}
        disabled
      />
      )}
      {weightReduction.cavity > 0 && (
      <LabelCounter
        width="auto"
        useIncrementValue={false}
        label="Cavity/TL"
        initialValue={weightReduction.cavity || 0}
        minValue={0}
        maxValue={currentShopOrder.partCount}
        callback={onChange('cavity')}
        disabled
      />
      )}
      {weightReduction.slot > 0 && (
      <LabelCounter
        width="auto"
        useIncrementValue={false}
        label="Slots"
        initialValue={weightReduction.slot || 0}
        minValue={0}
        maxValue={currentShopOrder.partCount}
        callback={onChange('slot')}
        disabled
      />
      )}
      {weightReduction.honeycomb > 0 && (
      <LabelCounter
        width="auto"
        useIncrementValue={false}
        label="Honeycomb"
        initialValue={weightReduction.honeycomb || 0}
        minValue={0}
        maxValue={currentShopOrder.partCount}
        callback={onChange('honeycomb')}
        disabled
      />
      )}
      {weightReduction.carveTop > 0 && (
      <LabelCounter
        width="auto"
        useIncrementValue={false}
        label="Carve Top"
        initialValue={weightReduction.carveTop || 0}
        minValue={0}
        maxValue={currentShopOrder.partCount}
        callback={onChange('carveTop')}
        disabled
      />
      )}
    </ItemDetailsColumnWrapper>
  );
};

export default OrderWeightReduction;
