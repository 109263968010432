import { atom } from 'recoil';
import { resolveFirestorePath, resolveRealtimePath, useTestData } from 'shared/util';
import { IConfigTerm, IConfigTermType, ICustomerPart } from 'shared/types/parts';
import { IBomItem } from '../types/dbRecords';

export const PART_BOM_COLLECTION = atom({
  key: 'PART_BOM_COLLECTION',
  default: resolveFirestorePath('part-bom-data'),
});
export const PART_VIEWER_COLLECTION = atom({
  key: 'PART_VIEWER_COLLECTION',
  default: resolveFirestorePath('parts'),
});
export const PART_PRICING_COLLECTION = atom({
  key: 'PART_PRICING_COLLECTION',
  default: resolveFirestorePath('part-pricing-data'),
});
export const PART_CONFIG_TERMS_COLLECTION = atom({
  key: 'PART_CONFIG_TERMS_COLLECTION',
  default: resolveFirestorePath('part-config-terms'),
});
export const PART_CONFIG_TERM_TYPES_COLLECTION = atom({
  key: 'PART_CONFIG_TERM_TYPES_COLLECTION',
  default: resolveFirestorePath('part-config-term-types'),
});

export const partConfigTermsAtom = atom<IConfigTerm[]>({
  key: 'partConfigTermsAtom',
  default: [],
});

export const partConfigTermTypesAtom = atom<IConfigTermType[]>({
  key: 'partConfigTermTypesAtom',
  default: [],
});

export const newCustomerPartsAtom = atom<ICustomerPart[]>({
  key: 'newCustomerPartsAtom',
  default: [],
});

export const BODY_DATA_PATH = resolveRealtimePath('body');
export const NECK_DATA_PATH = resolveRealtimePath('neck');

export const priceCatalogDataAtom = atom<any>({
  key: 'priceCatalogDataAtom',
  default: {
    body: [],
    neck: [],
  },
});

export const partViewerConfigDataAtom = atom<any>({
  key: 'partViewerConfigDataAtom',
  default: {},
});

export const partViewerTypeAtom = atom<'body'|'neck'>({
  key: 'partViewerTypeAtom',
  default: 'body',
});

export const partViewerStateAtom = atom<any>({
  key: 'partViewerStateAtom',
  default: {
    body: {
      battery: '.*',
      bentTop: '.*',
      bmh: '.*',
      bridge: '.*',
      bridgePickup: '.*',
      carveTop: '.*',
      comfy: '.*',
      construction: '.*',
      control: '.*',
      core: '.*',
      crvHeel: '.*',
      jackType: '.*',
      lefty: '.*',
      middlePickup: '.*',
      model: '.*',
      neckPickup: '.*',
      scale: '.*',
      sth: '.*',
      top: '.*',
      topped: '.*',
      veneer: '.*',
      weight: '.*',
      waistCarve: '.*',
    },
    neck: {
      bound: '.*',
      carve: '.*',
      cnc: '.*',
      fbs: '.*',
      fretCount: '.*',
      fretwire: '.*',
      fretboard: '.*',
      frontInlay: '.*',
      lefty: '.*',
      looseFrets: '.*',
      model: '.*',
      neckCore: '.*',
      nfs: '.*',
      nut: '.*',
      nutType: '.*',
      peghead: '.*',
      radius: '.*',
      revPh: '.*',
      scale: '.*',
      sideInlay: '.*',
      stringCount: '.*',
      tilt: '.*',
      trAccess: '.*',
      trType: '.*',
      vintage: '.*',
    },
  },
});

export const partFilterClearedAtom = atom<number>({
  key: 'PartFilterClearedAtom',
  default: 0,
});

export const activePartDisplayAtom = atom<'active'|'inactive'|'all'>({
  key: 'ActivePartDisplayAtom',
  default: sessionStorage.getItem('activePartDisplayType') || 'all',
});

export const pricedStatusDisplayAtom = atom<'priced'|'unpriced'|'all'>({
  key: 'PricedStatusDisplayAtom',
  default: 'all',
});

export const pricedSortSelectorAtom = atom<'partNumber'|'description'>({
  key: 'pricedStatusSortSelector',
  default: 'partNumber',
});

export const uploadProgressAtom = atom<boolean>({
  key: 'UploadProgressAtom',
  default: false,
});

export const partBomItemsAtom = atom<IBomItem[]>({
  key: 'partBomItemsAtom',
  default: [] as IBomItem[],
});

export const showBomEditorAtom = atom({
  key: 'showBomEditorAtom',
  default: false,
});

// keeps track of the bom item and is used to prompt an update if a bom is edited at the order level.
export const bomEditedAtom = atom<boolean>({
  key: 'bomEditedAtom',
  default: false,
});

export const showBomCopyDrawerAtom = atom({
  key: 'showBomCopyDrawerAtom',
  default: false,
});

export const copiedBomPartNumberAtom = atom<string>({
  key: 'copiedBomPartNumberAtom',
  default: '',
});

export const childViewTypeAtom = atom<'sku'|'like'>({
  key: 'childViewTypeAtom',
  default: sessionStorage.getItem('part.viewer.childViewType') || 'sku',
});
