import React, { useContext, useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import {
  findIndex, sortBy, last,
} from 'lodash';
import {
  CopyTwoTone, DeleteOutlined,
} from '@ant-design/icons/lib';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import theme from 'shared/theme';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import { formatPrice } from 'shared/data';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from 'shared/state/inventoryState';
import useFirebase from 'vendor/Firebase';
import QBOAuth from 'shared/data/QBO/auth';
import QBOItem from 'shared/data/QBO/item';

import {
  PriceItem,
  PricingActionRow,
  ConfirmDeleteIcon,
  PriceItemClickWrapper,
} from 'pages/Customer/Components/styledComponents';
import { stageRedirect } from 'shared/util';
import ScopedComponent from '../../../shared/components/Utility/ScopedComponent';
import { IInventoryPart } from '../../../shared/types/dbRecords';

interface IComponent{
  inventoryItem: IInventoryPart;
  shade: boolean;
}

const PartSubItem = styled.div`
    width: 2px;
  margin: 0;
`;

const PartNumber = styled.p`
  width: 8%;
  text-align: left;
  margin: 0;
`;
const PartDescription = styled.p`
  width: 60%;
  text-align: left;
  margin: 0 0 0 ${(props: { subItem: boolean }) => (props.subItem ? '-14px' : '0')};
`;
const PartVendor = styled.p`
  width: 10%;
  text-align: left;
  margin: 0;
`;
const PartProductCode = styled.p`
  width: 10%;
  text-align: left;
  margin: 0 0 0 ${(props: { subItem: boolean }) => (props.subItem ? '-2px' : '0')};
`;

const PartCost = styled.p`
  width: 5%;
  text-align: right;
  margin: 0;
`;

const PartCopyButton = styled(Button)`
  opacity: ${(props: any) => (!props.copyDataLoaded ? 1 : 0.2)};
`;
const PartCopyIcon = styled(CopyTwoTone)`
  color: ${theme.palette.primary.hue} !important;
`;

export const nextPartNumber = (partNumber: string, items: IInventoryPart[]) => {
  const likeParts = items
    .filter((i: IInventoryPart) => i.Sku?.substring(0, 3) === partNumber)
    .map((i: IInventoryPart) => parseInt(i.Sku, 10));
  const sorted = sortBy(likeParts, (i) => i);
  // @ts-ignore
  const newPartNumber = last(sorted) + 1;
  return newPartNumber.toString();
};

export default ({ inventoryItem, shade }: IComponent) => {
  // @ts-ignore
  const { firestore, database } = useFirebase();
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const inventoryCollectionDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);

  const onDelete = () => {
    const currentPartNumber = parseInt(inventoryItem.Sku, 10);
    firestore.collection(inventoryCollectionDbString).doc(inventoryItem.Id).delete()
      .then(() => {
        // @ts-ignore
        if (findIndex(inventoryItems, (i) => (i.partNumber === currentPartNumber - 1).toString()) > -1) {
          window.location.href = `/inventory?partId=${currentPartNumber - 1}`;
        } else {
          window.location.href = '/inventory';
        }
      });
  };
  const onCopy = async () => {
    // const newItemId = shortid.generate();
    const newPartNumber = nextPartNumber(inventoryItem.Sku, inventoryItems);
    const newRecord = {
      ...inventoryItem,
      description: `COPY_${inventoryItem.Description}`,
      partNumber: newPartNumber,
    };
    // await QBOAuth.refreshToken(database);
    const qboItem = QBOItem.create();
    firestore.collection(inventoryCollectionDbString).doc(newItemId).set(newRecord).then(() => {
      window.location.href = `/inventory/${newRecord.partNumber}`;
    });
  };

  const onRowClick = (e: any) => {
    stageRedirect(`/inventory?partId=${inventoryItem.Sku}`);
    window.location.href = `/inventory/edit?partId=${inventoryItem.Sku}`;
  };

  return (
    <PriceItem shade={shade}>
      <PriceItemClickWrapper onClick={onRowClick}>
        {inventoryItem.Parent && (
          <PartSubItem />
        )}
        <PartNumber>{inventoryItem.Sku}</PartNumber>
        <PartDescription subItem={!!inventoryItem.Parent}>{inventoryItem.Description}</PartDescription>
        <PartProductCode subItem={!!inventoryItem.Parent}>{inventoryItem.PrefVendorRef?.name || ''}</PartProductCode>
        <PartProductCode subItem={!!inventoryItem.Parent}>{inventoryItem.ProductCode.productCode}</PartProductCode>
        <PartCost>{formatPrice(inventoryItem.PurchaseCost)}</PartCost>
        <PartCost>{formatPrice(inventoryItem.UnitPrice)}</PartCost>
      </PriceItemClickWrapper>
      <PricingActionRow>
        <ScopedComponent whitelist={[...adminUsers.emails, ...superAdminUsers.emails]}>
          <PartCopyButton
            shape="circle"
            icon={<PartCopyIcon />}
            onClick={onCopy}
          />
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete this item?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
              placement="left"
          // @ts-ignore
              icon={<ConfirmDeleteIcon />}
            >
              <Button
                shape="circle"
              // @ts-ignore
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </ScopedComponent>
      </PricingActionRow>
    </PriceItem>
  );
};
