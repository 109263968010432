import React, { useContext } from 'react';
import { includes } from 'lodash';
import { AuthContext } from 'vendor/Firebase/AuthProvider';

interface IComponent {
  children: React.ReactNode;
  whitelist?: string[];
  blacklist?: string[];
}
export default ({ children, whitelist = [], blacklist = [] }: IComponent) => {
  const { currentUser } = useContext(AuthContext);
  /*
    If `whitelist` is not passed to the ScopedComponent, it is effectively an
    implicit whitelist for anyone. This is typically used in the case that
    you want to explicitly blacklist someone without whitelisting everyone
    else at the same time.

    Blacklisting is explicit-only, so user emails must be added to blacklist
    someone from a component.
   */
  const userIsWhitelisted = whitelist.length === 0 || includes(whitelist, currentUser?.email);
  const userIsBlacklisted = includes(blacklist, currentUser?.email);
  return (
    <>
      {(userIsWhitelisted && !userIsBlacklisted) && (
        children
      )}
    </>
  );
};
