import { ISwatchRange } from './types';

const percentToHex = (percent = 1.0): string => {
  const result = Math.round(percent * 255).toString(16);
  if (result.length === 1) return `0${result}`;

  return result;
};

const hexToRgb = (hex: string): Array<number> => {
  const parsedColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (parsedColor) {
    return parsedColor.slice(1).map((val) => parseInt(val, 16));
  }

  return [0, 0, 0];
};

export const rgbToHex = (r: number, g: number, b: number) => {
  const hexString = [r, g, b].map((x) => {
    const hex = x.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }).join('');
  return `#${hexString}`;
};
const hexWithAlpha = (hex: string, alpha: number = 1.0): string => `${hex}${percentToHex(alpha)}`;

const rgba = (hex: string, alpha: number = 1.0): string => {
  const rgb = hexToRgb(hex);
  if (rgb) {
    const [r, g, b] = rgb;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return hex;
};

const tint = (hex: string, percent: number = 1.00): string => {
  const [r, g, b] = hexToRgb(hex)
    .map((value) => Math.min(255, Math.floor(value + ((255 - value) * percent))))
    .map((value) => value.toString(16));

  return `#${r}${g}${b}`;
};

const shade = (hex: string, percent: number = 1.00): string => {
  const [r, g, b] = hexToRgb(hex)
    .map((value) => Math.max(0, Math.floor(value * (1 - percent))))
    .map((value) => value.toString(16))
    .map((value) => (value.length === 1 ? `0${value}` : value));

  return `#${r}${g}${b}`;
};

export const randomColor = (color: string = '#'): string => {
  if (color.length === 7) return color;
  const letters = '0123456789ABCDEF';
  const letter = letters[Math.floor(Math.random() * 16)];
  return randomColor(`${color}${letter}`);
};

const swatchRange = (hex: string): ISwatchRange => ({
  hue: hex,
  100: tint(hex, 0.9),
  200: tint(hex, 0.8),
  300: tint(hex, 0.7),
  400: tint(hex, 0.6),
  500: tint(hex, 0.5),
  600: tint(hex, 0.4),
  700: tint(hex, 0.3),
  800: tint(hex, 0.2),
  900: tint(hex, 0.1),
  D100: shade(hex, 0.1),
  D200: shade(hex, 0.2),
  D300: shade(hex, 0.3),
  D400: shade(hex, 0.4),
  D500: shade(hex, 0.5),
  D600: shade(hex, 0.6),
  D700: shade(hex, 0.7),
  D800: shade(hex, 0.8),
  D900: shade(hex, 0.9),
});

export {
  hexToRgb,
  hexWithAlpha,
  rgba,
  shade,
  swatchRange,
  tint,
};
