import React from 'react';
import shortid from 'shortid';
import { RouterTDCopy } from 'pages/ProductionSchedule/styledComponents';
import { Tooltip } from 'antd';
import { IClientRunner, IShopOrder } from '../../types';
import { SALES_CUSTOMER_COLUMN } from '../../../ProductionSchedule/Components/DataColumns';

const DATE_OPTIONS = { year: '2-digit', month: '2-digit', day: '2-digit' };

export const JOB_TYPE_COLUMN = {
  title: 'Type',
  dataIndex: 'type',
  render: (text: string, record: any) => {
    const jobType = record.type.match(/body/i) ? 'Body' : 'Neck';
    return (
      <RouterTDCopy key={shortid.generate()}>{jobType}</RouterTDCopy>
    );
  },
  width: '5%',
};

export const SHIP_DATE_COLUMN = {
  title: 'Ship',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IShopOrder, b: IShopOrder) => {
      const aDate = a.shipDate.toDate().getTime();
      const bDate = b.shipDate.toDate().getTime();
      if (aDate > bDate) return 1;
      if (bDate > aDate) return -1;
      return 0;
    },
    multiple: 2,
  },
  render: (text: string, record: any) => (
    <RouterTDCopy key={shortid.generate()}>{record.shipDate.toDate().toLocaleDateString('en-us', DATE_OPTIONS)}</RouterTDCopy>
  ),
  // width: 100,
  defaultSortOrder: 'ascend',
  width: '6%',
};
export const ORDER_DATE_COLUMN = {
  ...SHIP_DATE_COLUMN,
  sorter: {
    compare: (a: IShopOrder, b: IShopOrder) => {
      const aDate = a.orderDate.toDate().getTime();
      const bDate = b.orderDate.toDate().getTime();
      if (aDate > bDate) return 1;
      if (bDate > aDate) return -1;
      if (bDate > aDate) return -1;
      return 0;
    },
    multiple: 2,
  },
  title: 'Ordered',
  render: (text: string, record: any) => {
    const orderDate = record.orderDate.toDate().toLocaleDateString() === '12/21/20' ? '--/--/--' : record.orderDate.toDate().toLocaleDateString('en-us', DATE_OPTIONS);
    if (record.completed) {
      const timeToShip = Math.floor((record.shipDate.toDate() - record.orderDate) / 86400000);

      return (
        <Tooltip placement="top" title={`Shipped ${timeToShip} days after order`}>
          <RouterTDCopy key={shortid.generate()}>{orderDate}</RouterTDCopy>
        </Tooltip>
      );
    }
    return <RouterTDCopy key={shortid.generate()}>{orderDate}</RouterTDCopy>;
  },
  defaultSortOrder: 'descend',
  width: '6%',
};

export const CUSTOMER_COLUMN = {
  ...SALES_CUSTOMER_COLUMN,
  width: '5%',
};

export const QTY_ON_ORDER = {
  title: 'Qty. ordered',
  dataIndex: 'qty_ordered',
  render: (text: string, record: any) => (
    <RouterTDCopy key={shortid.generate()}>{record.orderItems[0].quantityOrdered}</RouterTDCopy>
  ),
  width: '5%',
};
