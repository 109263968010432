const _ = require('lodash');

export const regexMaker = (regexOptions: string[], modifiers = [], reverseModifier = false, insensitive = true, matchType: 'start'|'end'|'whole' = 'whole') => {
  if (reverseModifier) {
    return new RegExp(
      `${matchType === 'start' ? '^' : ''}_?((${regexOptions.join('|')})\\s?(${modifiers.join('|')})?\\s?/?)+(\\s|-|_|$)?${matchType === 'end' ? '$' : ''}`,
      insensitive ? 'ig' : 'g',
    );
  }
  return new RegExp(
    `${matchType === 'start' ? '^' : ''}_((${modifiers.join('|')})\\s)?((${regexOptions.join('|')})\\s?/?)+(\\s|-|_|$)?${matchType === 'end' ? '$' : ''}`,
    insensitive ? 'ig' : 'g',
  );
};
export const simpleTest = (partDescription: string, pattern: string, trueMessage = '', falseMessage = '') => {
  const _trueMessage = trueMessage === '' ? null : trueMessage;
  const _falseMessage = falseMessage === '' ? null : falseMessage;

  const match = !!partDescription.match(new RegExp(pattern, 'i'));
  return match ? (_trueMessage || match) : (_falseMessage || '');
};

export const extractPattern = (partDescription: string, pattern: string, notFound?: string) => {
  const match = partDescription.match(new RegExp(pattern, 'i'));
  return match ? match[0].replace(/_/g, '') : notFound || '';
};

export const filterCustomerAttributes = (dataSet: any, customerAttributes: string[]) => {
  const outData = Object.entries(dataSet)
    .filter(([k, v]) => _.includes(customerAttributes, k))
    .map(([k, v]) => ({ [k]: v }));
  // @ts-ignore
  return Object.entries(outData).length ? Object.assign(...outData) : null;
};

export const normalizeTerm = (termSet: any, term: string) => Object.entries(termSet).map((e) => {
  const [k, v]: [any, any] = e;
  const values = v.map((_v: string) => _v.toLowerCase());
  const match = _.includes(values, term.toLowerCase());
  return match ? k : null;
}).filter((i) => i);

export const extractSpecies = (woodType: string, woodWeights = {}) => {
  if (!woodType) return null;
  const modMatcher = new RegExp(`(${_.flatten(Object.values(woodWeights)).join('|')})`, 'ig');
  const modifier = woodType.match(modMatcher);
  if (modifier) {
    const normalized = normalizeTerm(woodWeights, modifier[0]);
    const replacer = new RegExp(normalized[0], 'i');
    // @ts-ignore
    return [woodType.replace(replacer, '').trim(), normalized];
  }
  return [woodType, ''];
};

export const normalizeSpecies = (TERMS_MAP: any, [woodType, weightMod = null]: [string, string|null]) => {
  const match = Object.entries(TERMS_MAP).map((e) => {
    const [key, values]: [any, any] = e;
    // @ts-ignore
    const [species, _weightMod] = extractSpecies(woodType);
    const matched = _.includes(values.map((v: string) => v.toLowerCase()), species.toLowerCase());

    return matched ? key : null;
  }).filter((i) => i);

  return match ? `${match[0]} ${weightMod || ''}`.trim() : null;
};

export const partType = (partDescription: string) => {
  if (partDescription.match(/^gn_/i)) return 'GN';
  if (partDescription.match(/^bn_/i)) return 'BN';
  if (partDescription.match(/^bb_/i)) return 'BB';
  return 'GB';
};
export const resolveActive = (active: string | boolean) => {
  if (active === 'N') return false;
  if (active === 'Y') return true;
  return active;
};
