import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const style = StyleSheet.create({
  multiPageFooter: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    right: 36,
    position: 'absolute',
    left: '25%',
    marginTop: 16,
  },
  multiPageFooterCopy: {
    fontFamily: 'Red Hat Display-300',
    fontSize: 8,
    color: 'black',
  },
});

interface IComponent {
  pageNumber: number;
}
const OrderConfirmationContinuation = ({ pageNumber }: IComponent) => (
  <View style={style.multiPageFooter}>
    <Text style={style.multiPageFooterCopy}>{`Continued from page ${pageNumber - 1}`}</Text>
  </View>
);

export default OrderConfirmationContinuation;
