import scrollIntoView from 'scroll-into-view';
import { devLog } from 'shared/util/logging';

const scrollOptions = {
  time: 2000,
  align: {
    topOffset: 224,
  },
  ease: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
};

const scrollToHash = (hash: string, options = scrollOptions): void => {
  devLog('shared/scroll', 14, `scrolling to: ${hash.slice(1)}`);
  if (hash.length > 0) {
    const element = document.getElementById(hash.slice(1));

    if (element) scrollIntoView(element, options);
  } else {
    devLog('shared/scroll', 20, `Couldn't find element with hash ${hash}`);
    window.scrollTo(0, 0);
  }
};

const scrollToRowKey = (rowKey: string, options = scrollOptions): void => {
  if (rowKey.length > 0) {
    const query = document.querySelectorAll(`[data-row-key="${rowKey}"]`);
    const element = query.length ? query[0] as HTMLElement : null;
    if (!element) return;

    scrollIntoView(element, scrollOptions);
  }
};

const locationChange = () => {
  const element = document.getElementById(window.location.hash.replace('#', ''));
  if (element) {
    scrollIntoView(element, scrollOptions);
  }
};

export { scrollOptions, scrollToHash, scrollToRowKey };
