import React from 'react';
import styled from 'styled-components';
import { FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';

interface IComponent {
  name: string;
  totalQuantity: number;
}

const ComponentWrapper = styled(FlexRow)`
    justify-content: space-between;
    padding-right: 24px;
`;
const TotalQuantity = styled.h4`
    padding: 2px 12px;
    color: ${theme.palette.neutral.white};
    background-color: ${theme.palette.primary.hue};
    border-radius: 12px;
    margin: 0;
`;
const Name = styled.h4`
    margin: 0;
    min-width: 180px;
    font-weight: bold;
`;

const UsagePanelHeader = ({ name, totalQuantity }: IComponent) => (
  <ComponentWrapper>
    <Name>{name}</Name>
    <TotalQuantity>{`${totalQuantity} required`}</TotalQuantity>
  </ComponentWrapper>
);

export default UsagePanelHeader;
