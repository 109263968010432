import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as _ from 'lodash';
import qs from 'qs';
import { Table } from 'antd';
import styled from 'styled-components';
import { Body } from 'shared/typography';
import { scrollToHash, scrollOptions } from 'shared/scroll';
import {
  ICustomerRecord, IShipment, IShipmentItem,
} from 'shared/types/dbRecords';
import { EmptyPriceItem } from 'pages/Customer/Components/styledComponents';
import { shipmentItemsAtom, shipmentSearchQueryAtom } from 'shared/state/shipmentState';
import { rgba as hexRgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import { stageRedirect } from 'shared/util';
import { CustomerPricingWrapper } from '../../../styledComponents';
import {
  SHIPMENT_NUMBER_COLUMN, SHIP_DATE_COLUMN, TYPE_COLUMN, SALES_ORDER_COLUMN, TRACKING_NUMBER_COLUMN, DESCRIPTION_COLUMN, CUSTOMER_COLUMN,
} from './ShipmentListColumns';

const ShipmentTable = styled((props) => <Table {...props} />)`
    width: 100%;
    && tbody > tr:hover > td {
        background: ${hexRgba(theme.palette.primary.hue, 0.2)};
    }
`;

interface IComponent {
  shipments: IShipment[];
}
const ShipmentItemsList = ({ shipments }: IComponent) => {
  const currentPageString = sessionStorage.getItem('currenShipmentsPage');
  // const shipments = useRecoilValue(shipmentItemsAtom);
  const shipmentSearchQuery = useRecoilValue(shipmentSearchQueryAtom);
  const [currentPage, setCurrentPage] = useState(currentPageString ? parseInt(currentPageString, 10) : 1);
  const [renderItems, setRenderItems] = useState([]);
  const { partId } = qs.parse(window.location.search.replace('?', ''));
  const tableColumns = [
    SHIPMENT_NUMBER_COLUMN,
    SHIP_DATE_COLUMN,
    CUSTOMER_COLUMN,
    SALES_ORDER_COLUMN,
    // TYPE_COLUMN,
    DESCRIPTION_COLUMN,
    TRACKING_NUMBER_COLUMN,
  ];

  const updatePageData = (): void => {
    // @ts-ignore
    setRenderItems(_.sortBy(shipments, (s: IShipment) => s.shipmentNumber)
      .reverse()
      .filter((s: IShipment) => {
        const terms = shipmentSearchQuery.split(',');
        const partData = s.shippedItems.map((i: IShipmentItem) => `${i.Sku}${i.Description}${i.notes}`).join('');
        const matchString = `${s.shipmentNumber}${s.salesOrder}${s.trackingNumber}${(s.value).toString()}${s.shipDate.toDate().toLocaleString()}${s.customer.id}${partData}`;
        const matches = terms.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return _.every(matches, Boolean);
      }));
  };
  const onPageChange = (newPage: number) => {
    sessionStorage.setItem('currentShipmentsPage', newPage.toString());
    setCurrentPage(newPage);
    updatePageData();
    scrollToHash('#shipment-filter', {
      ...scrollOptions,
      time: 1000,
    });
  };

  const onRowClick = (shipment: IShipment) => (e: any) => {
    stageRedirect(`/shipments#${shipment.shipmentNumber}`);
    window.location.href = `/shipments/edit?shipmentId=${shipment.id}&orderId=${shipment.orderId}`;
  };

  useEffect(() => {
    const currentPricingPage = sessionStorage.getItem('currentInventoryPage');
    setCurrentPage(currentPricingPage ? parseInt(currentPricingPage, 10) : 1);
    updatePageData();

    setTimeout(() => {
      const el = document.getElementById(`${partId}-inventory-item`);
      if (el) {
        const top = el.offsetTop;
        const parent = el.parentElement;
        if (parent) parent.scrollTop = top - 10;
        // .el.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'center',
        // });
      }
    });
  }, [shipmentSearchQuery]);

  return (
    <>
      {(renderItems.length === 0)
        ? (
          <EmptyPriceItem>
            <Body>No matches available.</Body>
          </EmptyPriceItem>
        ) : (
          <CustomerPricingWrapper>
            <ShipmentTable
              dataSource={renderItems.filter((r: IShipment) => !!r.shipmentNumber)}
              rowKey="Id"
              size="large"
              rowClassName="sales-order-row clickable-row"
              columns={tableColumns}
              pagination={{ pageSize: 100, current: currentPage, onChange: onPageChange }}
              scroll={{ y: window.innerHeight * 0.8 }}
              onRow={(record: IShipment) => ({
                onClick: onRowClick(record),
              })}
            />
          </CustomerPricingWrapper>
        )}
    </>
  );
};

export default ShipmentItemsList;
