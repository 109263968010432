import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  every, find, reverse, sortBy,
} from 'lodash';
import { Table } from 'antd';
import { EmptyPriceItem } from 'pages/Customer/Components/styledComponents';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { Body } from 'shared/typography';
import {
  IInventoryPart, IPurchaseOrder, IPurchaseOrderItem,
} from 'shared/types/dbRecords';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import { rgba as hexRgba } from 'shared/palette/utils';
import {
  activePurchaseOrderDisplayAtom,
  purchaseOrdersAtom,
  purchaseOrderSearchQueryAtom,
} from 'shared/state/purchaseOrderState';
import theme from 'shared/theme';
import {
  ORDER_NUMBER_COLUMN, PO_DATE_COLUMN, VENDOR_COLUMN, DESCRIPTION_COLUMN, AMOUNT_COLUMN, DUE_DATE_COLUMN,
} from './TableColumns';

const RecordWrapper = styled(FlexColumn)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ItemTable = styled((props) => <Table {...props} />)`
    width: 100%;
    && tbody > tr:hover > td {
        background: ${hexRgba(theme.palette.primary.hue, 0.2)};
    }
`;

interface IComponent {
  renderItems: IPurchaseOrder[];
}

const PurchaseOrderList = ({ renderItems }: IComponent) => {
  const currentPageString = sessionStorage.getItem('currentPurchaseOrderPage');
  const purchaseOrders = useRecoilValue(purchaseOrdersAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const purchaseOrderSearchQuery = useRecoilValue(purchaseOrderSearchQueryAtom);
  const activeDisplayType = useRecoilValue(activePurchaseOrderDisplayAtom);
  const [currentPage, setCurrentPage] = useState(currentPageString ? parseInt(currentPageString, 10) : 1);
  const [_renderItems, setRenderItems] = useState(renderItems);
  const tableColumns = [ORDER_NUMBER_COLUMN, PO_DATE_COLUMN, DUE_DATE_COLUMN, VENDOR_COLUMN, DESCRIPTION_COLUMN, AMOUNT_COLUMN];

  const updatePageData = (): void => {
    // @ts-ignore
    setRenderItems(reverse(sortBy(purchaseOrders.filter((p: IPurchaseOrder) => p), (p: IPurchaseOrder) => p.Id))
      .filter((p: IPurchaseOrder) => {
        if (activeDisplayType === 'open') return p.POStatus === 'Open';
        if (activeDisplayType === 'closed') return p.POStatus !== 'Open';
        return p;
      })
      .filter((p: IPurchaseOrder) => {
        const terms = purchaseOrderSearchQuery.split(',');
        const children = p.Line.filter((c: IPurchaseOrderItem) => c.ItemBasedExpenseLinteDetail).map((c: IPurchaseOrderItem) => {
          const inventoryName = c.ItemBasedExpenseLineDetail.ItemRef.name.split(':');
          const inventorySku = inventoryName.length > 1 ? inventoryName[1] : inventoryName[0];
          const inventoryPart = find(inventoryItems, (i: IInventoryPart) => i.Sku === inventorySku);
          const inventoryProductCode = inventoryPart ? inventoryPart.ProductCode.productCode : '';
          return `${c.ItemBasedExpenseLineDetail.ItemRef.name}${c.Description}${inventoryProductCode}`;
        });
        const matchString = `${p.Id}${p.PrivateNote}${p.VendorRef.name}${children.join('')}`;
        const matches = terms.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return every(matches, Boolean);
      }));
  };
  const onRowClick = (record: IPurchaseOrder) => (e: any) => {
    window.open(`https://app.qbo.intuit.com/app/purchaseorder?txnId=${record.Id}`);
  };

  useEffect(() => {
    setRenderItems(renderItems);
    setTimeout(() => {
      const el = document.getElementById(`${window.location.hash.replace('#', '')}-record-item`);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });
  }, [renderItems]);

  return (
    <>
      {(_renderItems.length === 0)
        ? (
          <EmptyPriceItem>
            <Body>No matches available.</Body>
          </EmptyPriceItem>
        ) : (
          <RecordWrapper>
            <ItemTable
              dataSource={_renderItems.filter((r: IPurchaseOrder) => !!r.Id)}
              rowKey="Id"
              size="large"
              rowClassName="sales-order-row clickable-row"
              columns={tableColumns}
              pagination={{ pageSize: 100, current: currentPage }}
              scroll={{ y: window.innerHeight * 0.8 }}
              onRow={(record: IPurchaseOrder) => ({
                onClick: onRowClick(record),
              })}
            />
          </RecordWrapper>
        )}
    </>
  );
};

export default PurchaseOrderList;
