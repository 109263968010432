import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { find, sortBy } from 'lodash';
import { IRouterStep } from 'pages/ProductionSchedule/types';
import { routerStepsAtom } from 'shared/state/routingState';
import HelpButton from 'shared/components/Utility/HelpButton';
import DetailDropdownWithCallback from 'shared/components/Input/DetailDropdownWithCallback';
import { IActionOption } from 'shared/types';
import { IWorkOrder } from '../../../Orders/types';
import { IOrderItem } from '../../../../shared/types/dbRecords';

interface IComponent {
  workOrder: IWorkOrder;
  callback: (value: string) => void;
  value: string;
}
const QATicketPartSelectorDropdown = ({ workOrder, callback, value }: IComponent) => {
  const [selectedItem, setSelectedItem] = useState<string>(value);
  const [optionsList, setOptionsList] = useState<IActionOption[]>([]);

  const onChangePartNumber = (partNumber: string) => {
    callback(partNumber);
  };

  useEffect(() => {
    const _optionsList = (workOrder.parts || []).map((p: IOrderItem) => ({
      value: p.Sku,
      label: p.Sku,
    }));
    setOptionsList(_optionsList.filter((o, index) => {
      const firstIndex = _optionsList.findIndex((e) => e.value === o.value);
      return firstIndex === index;
    }));
  }, [workOrder]);

  useEffect(() => {
    setSelectedItem(value);
  }, [value]);

  return (
    <DetailDropdownWithCallback
      id="qa-ticket-part-number-list"
      width="248px"
      listPrompt="Select Part"
      label="Part Number"
      optionsList={optionsList}
      selectedOption={selectedItem}
      LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="Select the part for which this non-conformance report is being created." />}
      changeCallback={onChangePartNumber}
    />
  );
};

export default QATicketPartSelectorDropdown;
