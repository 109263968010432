import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import shortid from 'shortid';
import styled from 'styled-components';
import { Button } from 'antd';
import { Add } from '@styled-icons/material';
import {
  orderItemsAtom,
  currentShopOrderAtom,
  showOrderItemEditDrawerAtom, currentOrderItemAtom,
} from 'shared/state/orderState';
import { IOrderItem } from 'shared/types/dbRecords';

const ComponentButton = styled(Button)`
  border-radius: 8px;
`;

const AddIcon = styled(Add)`
  width: 24px;
  bottom: 1px;
  position: relative;
  left: -4px;
`;

interface IComponent {
  salesOrderNumber: string;
  callback?: () => void;
}
const AddOrderItemButton = ({ salesOrderNumber, callback = undefined }: IComponent) => {
  // const currentShopOrder = useRecoilValue(currentShopOrderAtom);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const setShowOrderItemEditDrawer = useSetRecoilState(showOrderItemEditDrawerAtom);
  const setCurrentOrderItem = useSetRecoilState(currentOrderItemAtom);

  const onAdd = (e: any) => {
    e.preventDefault();
    const newItemId = shortid.generate();
    const newItem = {
      id: newItemId,
      orderNumber: salesOrderNumber,
      Sku: '',
      Description: '',
      houseSample: false,
      open: true,
      unitPrice: 0,
      notes: '',
      lastSold: '',
      quantityAssigned: 0,
      volume: '',
      quantityOrdered: 1,
      quantityCanceled: 0,
      quantityShipped: 0,
      quantityOpen: 1,
      bom: [],
    } as unknown as IOrderItem;
    const newItems = orderItems[0] === null ? [newItem] : [...orderItems, newItem];
    // @ts-ignore
    setOrderItems(newItems);
    setCurrentOrderItem(newItemId);
    setShowOrderItemEditDrawer(true);
    if (callback) callback();
  };

  return (
    <ComponentButton type="primary" disabled={salesOrderNumber === 'PH'} icon={<AddIcon />} onClick={onAdd}>Add part</ComponentButton>
  );
};

export default AddOrderItemButton;
