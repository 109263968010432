import axios from 'axios';
import { omit } from 'lodash';
import API_BASE_URL from './index';
import IQBOCustomer, { IQBOInvoice } from '../../types/qbo';
import { IContact } from '../../types/jb';
import { IShippingAddress } from '../../types/dbRecords';
import { qboDateString } from '../calendar';
import { devLog } from '../../util/logging';

// eslint-disable-next-line import/prefer-default-export
const fetchOne = async (id: string, retry = true): Promise<IQBOCustomer> => {
  try {
    const data = await axios.get(`${API_BASE_URL}/customer/fetchOne?id=${id}`);
    return data.data.Customer as IQBOCustomer;
  } catch (e) {
    if (retry) return fetchOne(id, false);
    throw new Error(e);
  }
};

const fetchById = async (qboId: string): Promise<IQBOCustomer> => {
  const response = await axios.get(`${API_BASE_URL}/customer/fetchOne?id=${qboId}`);
  return response.data.Customer;
};
const update = async (id: string, data: any, retry = true): Promise<IQBOCustomer> => {
  try {
    const qboCustomer = await fetchById(id);
    const updateData = {
      Id: id, sparse: true, ...data, SyncToken: qboCustomer.SyncToken,
    };
    const response = await axios.post(`${API_BASE_URL}/customer/update`, updateData);
    const responseData = response.data.json.Customer;
    devLog('data/QBO/customer', 23, responseData);
    return responseData;
  } catch (e) {
    if (retry) return update(id, data, false);
    throw e;
  }
};

const create = (customer: IQBOCustomer): Promise<IQBOCustomer> => {

};

const updateDefaultContact = (currentCustomer: IQBOCustomer, customerContact: IContact): Promise<IQBOCustomer> => {
  const [GivenName, FamilyName] = customerContact.contact.split(' ');
  const data = {
    GivenName,
    FamilyName,
    PrimaryEmailAddr: {
      Address: customerContact.email,
    },
    PrimaryPhone: {
      FreeFormNumber: customerContact.phone,
    },
    SyncToken: currentCustomer.SyncToken,
  };

  return update(currentCustomer.Id, data);
};

const updateDefaultShippingAddress = (currentCustomer: IQBOCustomer, shippingAddress: IShippingAddress): Promise<IQBOCustomer> => {
  const data = {
    ShipAddr: {
      ...omit(shippingAddress, ['name', 'default', 'id', 'shippingVendor', 'shippingAccount']),
      Id: currentCustomer.ShipAddr.Id,
    },
    SyncToken: currentCustomer.SyncToken,
    MetaData: {
      ...currentCustomer.MetaData,
      LastUpdatedTime: qboDateString(new Date(), true),
    },
  };

  return update(currentCustomer.Id, data);
};

const stripHelmFields = (customer: IQBOCustomer) => omit(customer,
  [
    'id',
    'active',
    'adjustLock',
    'bodyDiscount',
    'neckDiscount',
    'login',
    'quickbooksId',
    'shipsWith',
    'shortName',
    'website',
  ]);

export default {
  create,
  fetchOne,
  update,
  updateDefaultContact,
  updateDefaultShippingAddress,
  // helpers
  stripHelmFields,
};
