import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { showQuickEditDrawerAtom } from '../../../shared/state/inventoryState';
import InventoryDetail from '../InventoryDetail';

const QuickEditDrawer = styled(Drawer)`
  height: 100vh;
  position: absolute;
  z-index: 900000000;
`;

const InventoryQuickEditDrawer = () => {
  const [showEditDrawer, setShowEditDrawer] = useRecoilState(showQuickEditDrawerAtom);
  const partNumber = localStorage.getItem('inventory.quick.edit.partNumber');
  const onClose = (e: any) => {
    localStorage.removeItem('inventory.quick.edit.partNumber');
    const saveButton = document.getElementById('inventory-record-save-button');
    if (saveButton) saveButton.click();
    setShowEditDrawer(false);
  };

  return (
    <QuickEditDrawer
      title={`Inventory Part Quick Edit: ${partNumber}`}
      open={showEditDrawer}
      closable
      onClose={onClose}
      width="100vw"
      height="100vh"
      placement="top"
    >
      <InventoryDetail quickEdit />
    </QuickEditDrawer>
  );
};

export default InventoryQuickEditDrawer;
