import { atom } from 'recoil';
import { resolveFirestorePath } from 'shared/util';
import { IPurchaseOrder } from '../types/dbRecords';

export const PURCHASE_ORDER_DB_COLLECTION = atom<string>({
  key: 'PURCHASE_ORDER_DB_COLLECTION',
  default: resolveFirestorePath('purchase-orders'),
});

export const purchaseOrdersAtom = atom<IPurchaseOrder[]>({
  key: 'purchaseOrdersAtom',
  default: [],
});

export const currentPurchaseOrderPageAtom = atom<number>({
  key: 'currentPurchaseOrderPageAtom',
  default: (() => {
    const currentPage = sessionStorage.getItem('currentPurchaseOrderPage');
    if (currentPage) return parseInt(currentPage, 10);
    return 1;
  })(),
});

export const purchaseOrderSearchQueryAtom = atom({
  key: 'purchaseOrderSearchQueryAtom',
  default: sessionStorage.getItem('purchaseOrder.query.string') || '',
});

export const activePurchaseOrderDisplayAtom = atom<'open'|'closed'|'all'>({
  key: 'ActivePurchaseOrderDisplayAtom',
  default: sessionStorage.getItem('activePurchaseOrderDisplayType') || 'all',
});
