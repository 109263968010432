import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { atom, useRecoilState } from 'recoil';
import { activePartDisplayAtom } from 'shared/state/partViewState';

import {
  ItemDetailsLabel,
  NoLeftMarginViewTypeRadioGroup,
  ViewTypeRadioButton, ViewTypeSelect, ViewTypeSelectLabel,
  ViewTypeSelectOption,
} from 'shared/styledComponents/inputs';
import { ViewTypeSelectWrapper } from 'shared/styledComponents/containers';
import { FlexColumn } from '../../../../shared/containers/FlexContainer/styledComponents';
import { FlexRow } from '../../../../shared/containers/FlexContainer';

const ComponentWrapper = styled(FlexColumn)`
  margin: ${(props: { margin: string }) => props.margin};
    align-items: flex-start;
    justify-content: flex-start;
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

interface IComponent {
  // @ts-ignore
  allValue: string;
  activeValue: string;
  inactiveValue?: string;
  activeType: string;
  callback: (value: string) => void;
  label?: string;
  showLabel: boolean;
  margin: string;
  disabled?: boolean;
}

const ActiveSelector = ({
  activeType, callback, activeValue = 'Active', inactiveValue = undefined, allValue = 'All', label = 'Active Status', margin = '0 0', showLabel = false, disabled = false,
}: IComponent) => {
  const [activeDisplayType, setActiveDisplayType] = useState(activeType);
  const [_disabled, setDisabled] = useState<boolean>(disabled);
  const onChangeView = (e: any) => {
    const value = e.target?.value || e;
    callback(value);
  };

  useEffect(() => {
    setActiveDisplayType(activeType);
  }, [activeType]);

  useEffect(() => {
    setDisabled(disabled);
  }, [disabled]);

  return (
    <ComponentWrapper margin={margin}>
      <ViewTypeSelectWrapper>
        <ViewTypeSelectLabel>{label}</ViewTypeSelectLabel>
        <ViewTypeSelect
          label="View type"
          size="default"
          value={activeDisplayType}
          onChange={onChangeView}
          marginLeft="0px"
          disabled={_disabled}
        >
          <ViewTypeSelectOption value={allValue.toLowerCase()}>{allValue}</ViewTypeSelectOption>
          <ViewTypeSelectOption value={activeValue.toLowerCase()}>{activeValue}</ViewTypeSelectOption>
          {inactiveValue && (
            <ViewTypeSelectOption value={inactiveValue.toLowerCase()}>{inactiveValue}</ViewTypeSelectOption>
          )}
        </ViewTypeSelect>
      </ViewTypeSelectWrapper>
      {showLabel && (
        <LabelWrapper>
          <ItemDetailsLabel key="report-type-select-details-wrapper">{label}</ItemDetailsLabel>
        </LabelWrapper>
      )}
      <NoLeftMarginViewTypeRadioGroup value={activeDisplayType} buttonStyle="solid" onChange={onChangeView} disabled={_disabled}>
        <ViewTypeRadioButton
          value={allValue.toLowerCase()}
        >
          {allValue}
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value={activeValue.toLowerCase()}
        >
          {activeValue}
        </ViewTypeRadioButton>
        {inactiveValue && (
        <ViewTypeRadioButton
          value={inactiveValue.toLowerCase()}
        >
          {inactiveValue}
        </ViewTypeRadioButton>
        )}
      </NoLeftMarginViewTypeRadioGroup>
    </ComponentWrapper>
  );
};

export default ActiveSelector;
