import React from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { Outbound } from '@styled-icons/material-twotone';
import { stageRedirect } from 'shared/util';
import theme from 'shared/theme';

const JumpButton = styled(Button)`
   border: ${(props: { border: boolean }) => (props.border ? '1px solid lightgrey' : 'none')};
`;

const JumpIcon = styled(Outbound)`
    width: 24px;
    margin-bottom: 4px;
    color: ${theme.palette.primary.hue};
`;

interface IComponent {
  sku: string;
  partType: 'body'|'neck';
  description: string;
  border: boolean;
}
const JumpToParentButton = ({
  sku, description, partType, border = false,
}: IComponent) => {
  const onClick = (e: any) => {
    stageRedirect();
    window.location.href = `/pricing/${partType}?partId=${sku}`;
  };
  return (
    <Tooltip placement="top" title={description}>
      <JumpButton border={border} shape="circle" type="default" icon={<JumpIcon />} onClick={onClick} />
    </Tooltip>
  );
};

export default JumpToParentButton;
