import axios from 'axios';
import { find } from 'lodash';
import { IQBOItem } from 'shared/types/qbo';
import API_BASE_URL from './index';
import { qboDateString } from '../calendar';
import { devLog } from '../../util/logging';

// eslint-disable-next-line import/prefer-default-export
const fetchMany = (minDate = null): Promise<IQBOItem[]> => {
  const date = minDate || new Date();

  const year = date.getFullYear() - 1;
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return new Promise((resolve, reject) => axios.get(`${API_BASE_URL}/purchase-order/fetchMany?minDate=${year}-${month}-${day}`)
    .then((response) => {
      const data = response.data.QueryResponse.PurchaseOrder;
      resolve(data);
    })
    .catch((e) => {
      reject(e);
    }));
};

export const dateFromMemo = (memo: string) => {
  if (!memo) return '';
  const dateMatcher = /((0?[1-9])|(1[012]))[-/.]((0?[1-9])|([12][0-9])|(3[01]))[-/.](19|20)?[0-9]{2}/;
  const dueDateMatch = memo.match(dateMatcher);
  const dueDateText = dueDateMatch ? dueDateMatch[0] : '';
  return dueDateText;
};

export default {
  fetchMany,

  // helpers
  dateFromMemo,
};
