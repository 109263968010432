import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Drawer } from 'antd';
import WorkOrderPDF from 'pages/PDF/WorkOrderPDF';

import { ORDERS_DB_COLLECTION, workOrderPDFDrawerAtom } from '../../../shared/state/orderState';
import { IShopOrder } from '../../Orders/types';
import useFirebase from '../../../vendor/Firebase';

interface IComponent {
  orderId: string;
}
const WorkOrderPDFDrawer = ({ orderId }: IComponent) => {
  const { firestore } = useFirebase();
  const [shopOrder, setShopOrder] = useState<IShopOrder>({} as IShopOrder);
  const [open, setOpen] = useRecoilState(workOrderPDFDrawerAtom);
  const ordersCollectionDbString = useRecoilValue(ORDERS_DB_COLLECTION);

  const closeDrawer = (e: any) => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      firestore.collection(ordersCollectionDbString).doc(orderId).get().then((doc) => {
        if (!doc) return;
        setShopOrder(doc.data() as IShopOrder);
      });
    }
  }, [open]);
  return (
    <>
      {shopOrder.id && (
      <Drawer
        placement="right"
        width="78vw"
        open={open}
        onClose={closeDrawer}
      >
        <WorkOrderPDF currentShopOrder={shopOrder} />
      </Drawer>
      )}
    </>
  );
};

export default WorkOrderPDFDrawer;
