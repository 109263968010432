import { Select } from 'antd';
import styled from 'styled-components';
import { ConfigurationRow } from 'shared/pageElements/styledComponents';
import theme from 'shared/theme';

export const BodyWeightRow = styled(ConfigurationRow)`
    margin-bottom: 0;
`;

export const CustomerInfoWrapper = styled.div`
    height: auto;
    background-color: ${theme.palette.neutral.white};
    
    position: sticky;
    top: 72px;
    z-index: 100;
    
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(3)};
`;

export const MenuSelect = styled(Select)`
  min-width: 400px;
  
  &:disabled {
    color: ${theme.palette.neutral.black};
  }
`;
