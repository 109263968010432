import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { useRecoilValue } from 'recoil';
import {
  NoLeftMarginViewTypeRadioGroup,
  ViewTypeRadioButton, ViewTypeSelect, ViewTypeSelectLabel,
  ViewTypeSelectOption,
} from '../../../../../shared/styledComponents/inputs';
import { ItemDetailsLabel } from '../../../../PartDetail/Components/PartDetailFields/styledComponents';
import ScopedComponent from '../../../../../shared/components/Utility/ScopedComponent';
import { customerUserEmailsAtom } from '../../../../../shared/state/routingState';
import { PartViewerFilterWrapper, ViewTypeSelectWrapper } from '../../../../../shared/styledComponents/containers';
import { PartViewerFilterLabel } from '../../../../../shared/styledComponents/typographicElements';

const ComponentWrapper = styled(FlexColumn)`
    @media ${theme.device.laptopL} {
        //flex-direction: ;
        height: inherit;
        justify-content: center;
    }
`;
interface IComponent {
  callback: (value: 'all'|'active'|'inactive') => void;
  currentValue: 'all'|'active'|'inactive';
}

const PartActiveSelector = ({ callback, currentValue }: IComponent) => {
  const [status, setStatus] = useState<'all'|'active'|'inactive'>(currentValue);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const onChangeView = (e: any) => {
    const value = e.target?.value || e;
    callback(value);
  };

  useEffect(() => {
    setStatus(currentValue);
  }, [currentValue]);

  return (
    <PartViewerFilterWrapper>
      {/* <ScopedComponent whitelist={customerEmails.emails}> */}
      <PartViewerFilterLabel key="part-sort-type-item-details-label">Status</PartViewerFilterLabel>
      {/* </ScopedComponent> */}
      <ViewTypeSelectWrapper>
        <ViewTypeSelectLabel>Status</ViewTypeSelectLabel>
        <ViewTypeSelect
          label="View type"
          size="default"
          value={status}
          onChange={onChangeView}
        >
          <ViewTypeSelectOption value="all">All parts</ViewTypeSelectOption>
          <ViewTypeSelectOption value="active">Active parts</ViewTypeSelectOption>
          <ViewTypeSelectOption value="inactive">Inactive parts</ViewTypeSelectOption>
        </ViewTypeSelect>
      </ViewTypeSelectWrapper>
      <NoLeftMarginViewTypeRadioGroup value={status} buttonStyle="solid" onChange={onChangeView}>
        <ViewTypeRadioButton
          value="all"
        >
          All
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="active"
        >
          Active
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="inactive"
        >
          Inactive
        </ViewTypeRadioButton>
      </NoLeftMarginViewTypeRadioGroup>
    </PartViewerFilterWrapper>
  );
};

export default PartActiveSelector;
