import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  dataLoadingAtom,
} from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  adminUserEmailsAtom,
  superAdminUserEmailAtom,
} from 'shared/state/routingState';
import Loader from 'shared/components/Utility/Loader';
import {
  every, find, reverse, sortBy,
} from 'lodash';
import { PricingCol, PageHeaderWrapper } from '../styledComponents';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';
import PurchaseOrderList from './Components/PurchaseOrderList/PurchaseOrderList';
import { AdminToolsFilterWrapper, ButtonIsland, PageAdminToolsWrapper } from '../../shared/styledComponents/containers';
import ActiveSelector from '../Customer/Components/PriceListInputs/ActiveSelector';
import { activePurchaseOrderDisplayAtom, purchaseOrdersAtom } from '../../shared/state/purchaseOrderState';
import NewPurchaseOrderButton from './Components/PurchaseOrderList/Buttons/NewPurchaseOrderButton';
import ExportPurchaseOrdersButton from './Components/PurchaseOrderList/Buttons/ExportPurchaseOrdersButton';
import PurchaseOrderFilter from './Components/PurchaseOrderList/PurchaseOrderFilter';
import { PageTitle } from '../../shared/styledComponents/typographicElements';
import { IInventoryPart, IPurchaseOrder, IPurchaseOrderItem } from '../../shared/types/dbRecords';
import { listFilterQuery } from '../../shared/util';
import { inventoryItemsAtom } from '../../shared/state/inventoryState';
import ItemListFilter from '../../shared/components/Input/ItemListFilter';

const CustomerAdminWrapper = styled(FlexColumn)`
  height: auto;
  width: 100%;
  align-items: flex-start;
  background-color: ${(props: any) => (props.testMode ? '#FCEBEC' : theme.palette.neutral.white)};
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  position: sticky;
  top: 0px;
  z-index: 1000;
  padding: 32px 0 4px 0;
  gap: 32px;
  @media ${theme.device.laptopL} {
    align-items: center;
  }
`;

const ToolsWrapper = styled(AdminToolsFilterWrapper)`
  justify-content: flex-start;
  width: calc(100% - 72px);
  margin: 2px 0 0 72px;
  gap: 12px;
  @media ${theme.device.laptopL} {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      margin-left: unset;
  }    
`;

const ButtonWrapper = styled(FlexRow)`
    align-items: center;
    margin: 0;
    gap: 12px;
    
    @media ${theme.device.laptopL} {
      margin-left: 0;
    }
`;
const FilterWrapper = styled(AdminToolsFilterWrapper)`
    justify-content: flex-start;
    gap: 12px;
    margin-left: 12px;
    @media ${theme.device.laptopL} {
      margin-left: 0;
    }
`;
const FilterRow = styled(FlexRow)`
  width: 100%;
  gap: 16px;
  margin-bottom: 28px;
`;

/**
 *   Display component that lists and filters all customer records,
 */
const POBrowser = () => {
  // @ts-ignore
  const [loading, setLoading] = useRecoilState(dataLoadingAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const purchaseOrders = useRecoilValue(purchaseOrdersAtom);
  const sessionKey = 'po.query.string';
  const sessionPageKey = 'po.currentPage';
  const sessionActiveKey = 'po.activeType';
  const [activeDisplayType, setActiveDisplayType] = useState(sessionStorage.getItem(sessionActiveKey) || 'all');
  const [query, setQuery] = useState<string>(sessionStorage.getItem(sessionKey) || '');
  const [renderItems, setRenderItems] = useState<IPurchaseOrder[]>(purchaseOrders);
  const updatePageData = (items: IPurchaseOrder[] = purchaseOrders, filterQuery: string = query, activeType = activeDisplayType): void => {
    // @ts-ignore
    setRenderItems(reverse(sortBy(items.filter((p: IPurchaseOrder) => p), (p: IPurchaseOrder) => p.Id))
      .filter((p: IPurchaseOrder) => {
        if (activeType === 'open') return p.POStatus === 'Open';
        if (activeType === 'closed') return p.POStatus !== 'Open';
        return p;
      })
      .filter((p: IPurchaseOrder) => {
        const _query = listFilterQuery(filterQuery);
        const children = p.Line.filter((c: IPurchaseOrderItem) => c.ItemBasedExpenseLineDetail).map((c: IPurchaseOrderItem) => {
          const inventoryName = c.ItemBasedExpenseLineDetail.ItemRef.name.split(':');
          const inventorySku = inventoryName.length > 1 ? inventoryName[1] : inventoryName[0];
          const inventoryPart = find(inventoryItems, (i: IInventoryPart) => i.Sku === inventorySku);
          const inventoryProductCode = inventoryPart ? inventoryPart.ProductCode.productCode : '';
          return `${c.ItemBasedExpenseLineDetail.ItemRef.name}${c.Description}${inventoryProductCode}`;
        });
        const matchString = `${p.Id}${p.PrivateNote}${p.VendorRef.name}${children.join('')}`;
        const matches = _query.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return every(matches, Boolean);
      }));
  };

  const onChangeActiveType = (newType: string) => {
    setActiveDisplayType(newType);
    sessionStorage.setItem(sessionActiveKey, newType);
    updatePageData(purchaseOrders, query, newType);
  };

  const onChangeFilter = (newValue: string) => {
    setQuery(newValue);
    updatePageData(purchaseOrders, newValue, activeDisplayType);
  };

  useEffect(() => {
    const _query = sessionStorage.getItem(sessionKey) || '';
    const _displayType = sessionStorage.getItem(sessionKey) || 'all';
    setQuery(_query);
    setActiveDisplayType(_displayType);
    updatePageData(purchaseOrders, _query, _displayType);
  }, []);

  return (
    <PageHeaderWrapper>
      <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
        <PageAdminToolsWrapper id="purchase-order-admin-inputs">
          <ToolsWrapper>
            <ButtonWrapper>
              <PageTitle>POs</PageTitle>
              <ButtonIsland expand={false} noMinWidth padding="8px 16px">
                <NewPurchaseOrderButton />
                <ExportPurchaseOrdersButton />
              </ButtonIsland>
            </ButtonWrapper>
            <FilterWrapper justify="flex-start">
              <ItemListFilter
                id="purchase-order-list-filter"
                callback={onChangeFilter}
                currentValue={query}
                prompt="Filter purchase orders using comma-separated terms, e.g., JESENT, 55090"
              />
              {/* <PurchaseOrderFilter /> */}
              <ActiveSelector
                activeType={activeDisplayType}
                callback={onChangeActiveType}
                activeValue="Open"
                inactiveValue="Closed"
                allValue="All"
              />
            </FilterWrapper>
          </ToolsWrapper>
        </PageAdminToolsWrapper>
      </ScopedComponent>
      <PricingCol>
        {loading
          ? (
            <>
              <FlexColumn style={{ width: '100%', height: '50vw' }}>
                <Loader />
              </FlexColumn>
            </>
          ) : (
            <>
              <PurchaseOrderList renderItems={renderItems} />
            </>
          )}
      </PricingCol>
    </PageHeaderWrapper>
  );
};

export default POBrowser;
