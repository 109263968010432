import React from 'react';
import { useSetRecoilState } from 'recoil';
import { Tabs } from 'antd';
import { currentPartTypeAtom } from 'shared/state/pricingState';
import BodyPricing from './BodyPricing';
import NeckPricing from './NeckPricing';
import GlobalPricing from './GlobalPricing';
import { PricingTabs } from './styledComponents';

const { TabPane } = Tabs;

export default () => {
  const setCurrentPartType = useSetRecoilState(currentPartTypeAtom);
  const onTabClick = (key: 'global'|'body'|'neck', e: any) => {
    localStorage.setItem('pricingEditCategory', key);
    setCurrentPartType(key);
  };
  return (
    <PricingTabs onTabClick={onTabClick} defaultActiveKey={localStorage.getItem('pricingEditCategory') || 'global'}>
      <TabPane key="global" tab="Global Pricing">
        <GlobalPricing />
      </TabPane>
      <TabPane key="body" tab="Body Pricing">
        <BodyPricing />
      </TabPane>
      <TabPane key="neck" tab="Neck Pricing">
        <NeckPricing />
      </TabPane>
    </PricingTabs>
  );
};
