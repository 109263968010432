import React, { useState } from 'react';
import { Button, notification } from 'antd';
import { CloudArrowDownIcon } from '@heroicons/react/24/solid';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import { CSVDownload, CSVLink } from 'react-csv';
import theme from 'shared/theme';
import { useRecoilValue } from 'recoil';
import {
  DATE_CELLS, generateWorkbook, rowValueAtCol, workbookStyles,
} from 'shared/reports/workbook';
import { saveAs } from 'file-saver';
import { dateString } from '../../../../shared/data/calendar';
import { ORDERS_DB_COLLECTION } from '../../../../shared/state/orderState';
import { IShopOrder, IWorkOrder } from '../../../Orders/types';

const _ = require('lodash');
const shortid = require('shortid');

interface IComponent {
  weekStart: Date;
  weekColor: string;
  weekEnd: Date;
  context: 'customerPage'|'ordersPage';
  type: 'week'|'all';
}

const ComponentButton = styled(Button)`
  display: flex;
  width: auto;
  height: auto;
  padding: 4px 12px;
  margin-left: 8px;
  margin-bottom: unset;
  background-color: ${theme.palette.primary.hue};
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  
  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: ${(props: { weekColor: string }) => props.weekColor};
    border: 2px solid rgba(255, 255, 255, 0.2);
  }
`;

export const ComponentWrapper = styled.div`
  display: none;

  @media ${theme.device.laptopM} {
    display: unset;
  }
`;
const DownloadIcon = styled(CloudArrowDownIcon)`
  width: 24px;
  padding: 0;
  color: ${theme.palette.neutral.white};
`;
export default ({
  weekStart, weekEnd, context, weekColor, type = 'week',
}: IComponent) => {
  const { firestore } = useFirebase();
  const orderDbString = useRecoilValue(ORDERS_DB_COLLECTION);

  const onClickDownload = (e: any) => {
    const endDate = new Date(weekEnd);
    endDate.setHours(23, 59, 59, 999);
    firestore.collection(orderDbString)
      .where('shipDate', '>=', weekStart)
      .where('shipDate', '<=', endDate)
      .get()
      .then((snapshot) => {
        const bodyData = [];
        const neckData = [];
        snapshot.forEach((record) => {
          const recordData = record.data();
          if (recordData.type.match(/body/i)) bodyData.push(recordData);
          else neckData.push(recordData);
        });

        const workbook = generateWorkbook();
        const wbStyle = workbookStyles(workbook);

        const reportSheet = workbook.addWorksheet('Orders by Date', wbStyle.sheetOptions);
        reportSheet.column(DATE_CELLS.SALES_ORDER).setWidth(12);
        reportSheet.column(DATE_CELLS.CUSTOMER).setWidth(12);
        reportSheet.column(DATE_CELLS.DESCRIPTION).setWidth(68);
        reportSheet.column(DATE_CELLS.UNITS).setWidth(8);
        reportSheet.column(DATE_CELLS.VALUE).setWidth(10);
        reportSheet.column(DATE_CELLS.RELEASE_DATE).setWidth(12);
        reportSheet.column(DATE_CELLS.ORDER_DATE).setWidth(12);
        reportSheet.column(DATE_CELLS.SHIP_DATE).setWidth(12);

        let currentRow = 1;
        [bodyData, neckData].forEach((data) => {
          const partType = data[0].type.match(/body/i) ? 'Bodies' : 'Necks';

          reportSheet.cell(currentRow, DATE_CELLS.SALES_ORDER).string(partType).style(wbStyle.heroStyle);
          reportSheet.cell(currentRow, DATE_CELLS.CUSTOMER).string('').style(wbStyle.heroStyle);
          reportSheet.cell(currentRow, DATE_CELLS.DESCRIPTION).string(`${dateString(weekStart, false, true)} - ${dateString(weekEnd, false, true)}`).style(wbStyle.heroStyle);
          reportSheet.cell(currentRow, DATE_CELLS.ORDER_DATE).string('Orders').style(wbStyle.sectionDataStyle);
          reportSheet.cell(currentRow, DATE_CELLS.VALUE).string('Value').style(wbStyle.sectionDataStyle);
          reportSheet.cell(currentRow, DATE_CELLS.SHIP_DATE).string('Invoiced').style(wbStyle.sectionDataStyle);

          currentRow += 1;
          const totalsRow = currentRow;
          currentRow += 2;

          reportSheet.cell(currentRow, DATE_CELLS.SALES_ORDER).string('Order').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, DATE_CELLS.CUSTOMER).string('Cust.').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, DATE_CELLS.DESCRIPTION).string('Description').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, DATE_CELLS.ORDER_DATE).string('Order\nDate').style(wbStyle.headerStyleVert);
          reportSheet.cell(currentRow, DATE_CELLS.UNITS).string('Units').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, DATE_CELLS.VALUE).string('Value').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, DATE_CELLS.RELEASE_DATE).string('Release\nDate').style(wbStyle.headerStyleVert);
          reportSheet.cell(currentRow, DATE_CELLS.SHIP_DATE).string('Ship\nDate').style(wbStyle.headerStyleVert);
          reportSheet.cell(currentRow, DATE_CELLS.STATUS).string('Status').style(wbStyle.headerStyle);

          currentRow += 1;
          const firstDataRow = currentRow;
          _.sortBy(data, (d: IShopOrder) => d.shipDate.toDate()).forEach((record: IShopOrder) => {
            let status = 'In Production';
            if (!record.runners?.length) status = 'Not Released';
            else if (record.completed) status = 'Shipped';
            const partCount = record.runners.length === 0 ? record.partCount : record.runners.map((r: IWorkOrder) => r.parts.filter((p: any) => p.Sku.match(/[A-Z]{5}/)).length).reduce((a, b) => a + b, 0);

            reportSheet.cell(currentRow, DATE_CELLS.SALES_ORDER).string(record.salesOrder).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, DATE_CELLS.CUSTOMER).string(record.customer.id).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, DATE_CELLS.DESCRIPTION).string(record.description).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, DATE_CELLS.ORDER_DATE).string(dateString(record.orderDate.toDate(), false, true)).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, DATE_CELLS.UNITS).number(partCount).style(wbStyle.rowNumberStyle);
            reportSheet.cell(currentRow, DATE_CELLS.VALUE).number(record.orderValue).style(wbStyle.rowCurrencyStyle);
            reportSheet.cell(currentRow, DATE_CELLS.RELEASE_DATE).string(dateString(record.releaseDate.toDate(), false, true)).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, DATE_CELLS.SHIP_DATE).string(dateString(record.shipDate.toDate(), false, true)).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, DATE_CELLS.STATUS).string(status).style(wbStyle.rowStyle);
            currentRow += 1;
          });

          currentRow += 1;
          reportSheet.cell(currentRow, DATE_CELLS.SALES_ORDER).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, DATE_CELLS.CUSTOMER).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, DATE_CELLS.DESCRIPTION).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, DATE_CELLS.ORDER_DATE).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, DATE_CELLS.UNITS).formula(`SUM(${rowValueAtCol(DATE_CELLS.UNITS, firstDataRow)}:${rowValueAtCol(DATE_CELLS.UNITS, currentRow - 1)})`).style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, DATE_CELLS.VALUE).formula(`SUM(${rowValueAtCol(DATE_CELLS.VALUE, firstDataRow)}:${rowValueAtCol(DATE_CELLS.VALUE, currentRow - 1)})`).style(wbStyle.columnTotalCurrencyStyle);
          reportSheet.cell(currentRow, DATE_CELLS.RELEASE_DATE).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, DATE_CELLS.SHIP_DATE).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, DATE_CELLS.STATUS).string('').style(wbStyle.columnTotalNumberStyle);

          // reportSheet.cell(totalsRow, DATE_CELLS.SALES_ORDER).string(type).style(wbStyle.heroStyle);
          reportSheet.cell(totalsRow, DATE_CELLS.ORDER_DATE).number(data.length).style(wbStyle.sectionDataStyle);
          reportSheet.cell(totalsRow, DATE_CELLS.VALUE).formula(`SUM(${rowValueAtCol(DATE_CELLS.VALUE, firstDataRow)}:${rowValueAtCol(DATE_CELLS.VALUE, currentRow - 1)})`).style(wbStyle.sectionCurrencyStyle);
          reportSheet.cell(totalsRow, DATE_CELLS.SHIP_DATE).formula(`SUMIFS(${rowValueAtCol(DATE_CELLS.VALUE, firstDataRow)}:${rowValueAtCol(DATE_CELLS.VALUE, currentRow - 1)}, ${rowValueAtCol(DATE_CELLS.STATUS, firstDataRow)}:${rowValueAtCol(DATE_CELLS.STATUS, currentRow - 1)}, "Shipped")`).style(wbStyle.sectionCurrencyStyle);

          currentRow += 4;
        });
        workbook.writeToBuffer().then((workbookBuffer) => {
          const blob = new Blob([workbookBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'Orders_By_Date_Report.xlsx');
          const button = document.getElementById(`week-${weekStart.getTime()}-order-download-button`);
          if (button) button.blur();
        });
      });
    // .catch((err) => console.log(`Error! ${err}`));
  };

  return (
    <ComponentWrapper>
      <ComponentButton
        key={`week-${weekStart.getTime()}-order-download-button`}
        id={`week-${weekStart.getTime()}-order-download-button`}
        onClick={onClickDownload}
        context={context}
        weekColor={weekColor}
      >
        <DownloadIcon />
      </ComponentButton>
    </ComponentWrapper>
  );
};
