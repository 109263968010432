import firebase from 'firebase';
import { resolveFirestorePath } from 'shared/util';
import { IPartHistoryEntry } from '../types/parts';

export const addHistoryEntry = async (
  firestore: firebase.firestore.Firestore,
  sku: string, 
  entry: IPartHistoryEntry
): Promise<void> => {
  const historyRef = firestore.collection(resolveFirestorePath('part-history')).doc(sku);
  const historyDoc = await historyRef.get();

  if (!historyDoc.exists) {
    await historyRef.set({
      sku,
      entries: [entry]
    });
  } else {
    await historyRef.update({
      entries: firebase.firestore.FieldValue.arrayUnion(entry)
    });
  }
};

export default addHistoryEntry;
