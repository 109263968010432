import React from 'react';
import shortid from 'shortid';
import { Popover } from 'antd';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { TextSnippet } from '@styled-icons/material';
import { CellCopy } from 'shared/styledComponents/typographicElements';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  adminUserEmailsAtom, superAdminUserEmailAtom, supervisorEmailsAtom, shopUserEmailsAtom,
} from 'shared/state/routingState';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';

interface IComponent {
  active: boolean;
  notes: string|null;
  sku: string;
}

const ComponentWrapper = styled(FlexRow)`
  width: 100%;
  height: calc(100% + 4px);
  justify-content: flex-start;
  gap: 4px;
`;
const NotesIcon = styled(TextSnippet)`
    width: 18px;
    color: ${theme.palette.neutral[800]};
`;

const PartSku = ({ active, sku, notes = null }: IComponent) => {
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const supervisorEmails = useRecoilValue(supervisorEmailsAtom);
  const shopUserEmails = useRecoilValue(shopUserEmailsAtom);

  return (
    <ComponentWrapper key={shortid.generate()}>
      <CellCopy>{sku}</CellCopy>
      <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails, ...supervisorEmails.emails, ...shopUserEmails.emails]}>
        {notes && (
        <Popover content={notes} placement="top">
          <NotesIcon />
        </Popover>
        )}
      </ScopedComponent>
    </ComponentWrapper>
  );
};

export default PartSku;
