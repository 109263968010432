import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  NoLeftMarginViewTypeRadioGroup,
  ViewTypeRadioButton, ViewTypeSelect, ViewTypeSelectLabel,
  ViewTypeSelectOption,
} from 'shared/styledComponents/inputs';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { PartViewerFilterWrapper } from 'shared/styledComponents/containers';
import { ItemDetailsLabel } from '../../PartDetail/Components/PartDetailFields/styledComponents';

const ViewTypeSelectWrapper = styled(FlexColumn)`
    position: relative;
    bottom: 10px;
`;
const RadioSelectLabel = styled(ItemDetailsLabel)`
  display: none;
  @media ${theme.device.laptopL} {
    display: unset;
  }
`;
interface IComponent {
  callback: (value: 'All'|'New'|'Variant'|'Revision') => void;
  currentValue: 'All'|'New'|'Variant'|'Revision';
}

export default ({ callback, currentValue }: IComponent) => {
  const [status, setStatus] = useState<'All'|'New'|'Variant'|'Revision'>(currentValue);
  const onChangeView = (e: any) => {
    const value = e.target?.value || e;
    callback(value);
  };

  useEffect(() => {
    setStatus(currentValue);
  }, [currentValue]);

  return (
    <PartViewerFilterWrapper>
      <RadioSelectLabel key="part-sort-type-item-details-label">Development status</RadioSelectLabel>
      <ViewTypeSelectWrapper>
        <ViewTypeSelectLabel>Development Status</ViewTypeSelectLabel>
        <ViewTypeSelect
          label="Price Status"
          size="default"
          value={status}
          onChange={onChangeView}
        >
          <ViewTypeSelectOption value="All">All parts</ViewTypeSelectOption>
          <ViewTypeSelectOption value="New">New parts</ViewTypeSelectOption>
          <ViewTypeSelectOption value="Variant">Variants</ViewTypeSelectOption>
          <ViewTypeSelectOption value="Revision">Revision needed</ViewTypeSelectOption>
        </ViewTypeSelect>
      </ViewTypeSelectWrapper>
      <NoLeftMarginViewTypeRadioGroup value={status} buttonStyle="solid" onChange={onChangeView}>
        <ViewTypeRadioButton
          value="All"
        >
          All
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="New"
        >
          New
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="Variant"
        >
          Variant
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="Revision"
        >
          Revision
        </ViewTypeRadioButton>
      </NoLeftMarginViewTypeRadioGroup>
    </PartViewerFilterWrapper>
  );
};
