import React from 'react';
import styled from 'styled-components';
import { IPurchaseOrderItem } from 'shared/types/dbRecords';
import { FlexRow, FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { formatPrice } from '../../../../shared/data';
import { rgba } from '../../../../shared/palette/utils';

const ComponentWrapper = styled(FlexColumn)`
    align-items: flex-start;
    gap: 2px;
    width: 800px;
`;

const ItemWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
    width: 100%;
    height: 48px;
    background-color: ${(props: any) => (props.shade ? rgba(theme.palette.neutral[100], 0.5) : 'transparent')};
`;

const HeaderWrapper = styled(ItemWrapper)`
    border-bottom: 1px solid lightgrey;
    height: 60px;
`;

const ItemCopy = styled.p`
    margin: 0;
`;
const ItemNumber = styled(ItemCopy)`
  font-weight: bold;
    width: 12%;
`;

const ItemDescription = styled(ItemCopy)`
    width: 60%;
`;
const ItemQuantity = styled(ItemCopy)`
    width: 10%;
`;
const ItemUnitAmount = styled(ItemCopy)`
    width: 10%;
`;
const ItemTotalAmount = styled(ItemCopy)`
    width: 10%;
`;

interface IComponent {
  items: IPurchaseOrderItem[];
}
const PurchaseOrderItemsPopover = ({ items }: IComponent) => (
  <ComponentWrapper>
    <HeaderWrapper shade={false}>
      <ItemNumber>Sku</ItemNumber>
      <ItemDescription>Description</ItemDescription>
      <ItemQuantity>Open</ItemQuantity>
      <ItemUnitAmount>Each</ItemUnitAmount>
      <ItemTotalAmount>Total</ItemTotalAmount>
    </HeaderWrapper>
    {items.filter((i: IPurchaseOrderItem) => !!i.ItemBasedExpenseLineDetail?.ItemRef).map((i: IPurchaseOrderItem, index) => {
      const itemName = i.ItemBasedExpenseLineDetail.ItemRef.name.split(':');
      const itemNumber = itemName.length > 1 ? itemName[1] : itemName[0];
      return (
        <ItemWrapper shade={index % 2 === 1}>
          <ItemNumber>{itemNumber}</ItemNumber>
          <ItemDescription>{i.Description}</ItemDescription>
          <ItemQuantity>{i.ItemBasedExpenseLineDetail.Qty}</ItemQuantity>
          <ItemUnitAmount>{formatPrice(i.ItemBasedExpenseLineDetail.UnitPrice)}</ItemUnitAmount>
          <ItemTotalAmount>{formatPrice(i.ItemBasedExpenseLineDetail.Qty * i.ItemBasedExpenseLineDetail.UnitPrice)}</ItemTotalAmount>
        </ItemWrapper>
      );
    })}
  </ComponentWrapper>
);
export default PurchaseOrderItemsPopover;
