import axios from 'axios';
import {
  IContact,
  ICustomerPart,
  IGlCode,
  IInventoryItem,
  IJBOrderItem,
  IProductCode,
  IShippingDetail,
  IShippingLineItem,
  ITermsCode,
} from 'shared/types/jb';
import { IVendor } from 'shared/types/vendor';
import { devLog } from 'shared/util/logging';

export const simpleFetch = (token: string, url: string): Promise<any> => new Promise((resolve, reject) => axios.get(url, {
  headers: {
    accept: 'application/json',
    Authorization: `Bearer ${token}`,
  },
}).then((res) => {
  if (res.status !== 200) {
    reject(res);
  }
  const data = res.data.Data;
  resolve(data);
})
  .catch((e) => {
    console.log(e);
    resolve(null);
    // reject(new Error('500'));
  }));

export const accessToken = async (): Promise<string> => axios.post('https://api-user.integrations.ecimanufacturing.com:443/oauth2/api-user/token', {
  client_id: process.env.REACT_APP_JB_CLIENT_ID,
  client_secret: process.env.REACT_APP_JB_CLIENT_SECRET,
  scope: 'openid',
  grant_type: 'client_credentials',
}, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    accept: 'text/plain',
  },
})
  .then((response: any) => new Promise((resolve, reject) => {
    if (response.status !== 200) {
      reject(response);
    } else {
      resolve(response.data.access_token);
    }
  }));

// @ts-ignore
export const getCustomerParts = async (
  token: string,
  customerId: string,
  start: number = 0,
  take: number = 200,
  acc: any[] = [],
): Promise<ICustomerPart[]> => {
  const fields = [
    'active',
    'partNumber',
    'description',
    'price',
    'user_Text1',
  ];
  const url = `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/estimates?fields=${fields.join('%2C')}&customerCode=${customerId}&skip=${start}&take=${take}`;
  return new Promise((resolve, reject) => axios.get(url, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.status !== 200) {
      reject(res);
    }
    const data = res.data.Data;
    const _acc = [...acc, ...data];

    if (data.length < take) {
      resolve(_acc);
    } else {
      resolve(getCustomerParts(token, customerId, start + take, take, _acc));
    }
  })
    .catch((e) => {
      console.log(e);
      reject(new Error('500'));
    }));
};

export const fetchPart = (token: string, partNumber: string): Promise<IInventoryItem> => {
  const url = `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/estimates/${partNumber}?fields=active%2Cbin1Lot%2Cbin2Lot%2Cbin3Lot%2Cbin4Lot%2Cbin5Lot%2CbinLocation1%2CbinLocation2%2CbinLocation3%2CbinLocation4%2CbinLocation5%2CbinQuantity1%2CbinQuantity2%2CbinQuantity3%2CbinQuantity4%2CbinQuantity5%2Ccomments%2CcustomerCode%2CdefaultBinLocation%2Cdescription%2CGLCode%2CimageRepositoryID%2CisTaxable%2ClastModDate%2ClastModUser%2ClastPriceChange%2ClastPurchaseOrderCost%2ClastPurchaseOrderDate%2ClastPurchaseOrderNumber%2ClastPurchaseOrderQuantity%2CleadTime%2CmarkupPercent%2CmatchQuantityBreaks%2CpartNumber%2Cprice1%2Cprice2%2Cprice3%2Cprice4%2Cprice5%2Cprice6%2Cprice7%2Cprice8%2CpricingUnit%2CproductCode%2CpurchaseCost1%2CpurchaseCost2%2CpurchaseCost3%2CpurchaseCost4%2CpurchaseCost5%2CpurchaseCost6%2CpurchaseCost7%2CpurchaseCost8%2CpurchaseFactor%2CpurchaseQuantity1%2CpurchaseQuantity2%2CpurchaseQuantity3%2CpurchaseQuantity4%2CpurchaseQuantity5%2CpurchaseQuantity6%2CpurchaseQuantity7%2CpurchaseQuantity8%2CpurchasingGLCode%2CpurchasingUnit%2CQBItemType%2Cquantity1%2Cquantity2%2Cquantity3%2Cquantity4%2Cquantity5%2Cquantity6%2Cquantity7%2Cquantity8%2CquantityOnHand%2CquantityOnOrder%2CreOrderLevel%2CreOrderQuantity%2CrevisedDate%2Crevision%2CrevisionDate%2CrouteDate%2CrouteEmployee%2CuniqueID%2Cuser_Text1%2Cuser_Text2%2CvendorCode1%2CvendorCode2%2CvendorCode3`;
  return simpleFetch(token, url);
};

export const glCodes = (token: string): Promise<IGlCode[]> => simpleFetch(token, 'https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/gl-codes');
export const partBom = (token: string, partNumber: string): Promise<IBomItem[]> =>
  simpleFetch(token, `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/materials?partNumber=${partNumber}`);
export const termsCodes = (token: string): Promise<ITermsCode[]> => simpleFetch(token, 'https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/terms');
export const orderBillingAddress = (token: string, customerCode: string) => {
  const url = `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/customers/${customerCode}?fields=billingAddress1%2CbillingCity%2CbillingCountry%2CbillingState%2CbillingZIPCode`;
  return new Promise((resolve, reject) => axios.get(url, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.status !== 200) {
      reject(res);
    }
    const data = res.data.Data;
    resolve(data);
  })
    .catch((e) => {
      console.log(e);
      reject(new Error('500'));
    }));
};
export const orderDetail = async (token: string, orderNumber: string): Promise<any> =>
  simpleFetch(token, `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/orders/1${orderNumber}`);
export const orderItems = async (token: string, orderNumber: string): Promise<IJBOrderItem[]> =>
  simpleFetch(token, `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/order-line-items?orderNumber=1${orderNumber}`);

export const orderShippingAddress = (token: string, orderNumber: string): Promise<IShippingDetail[]> => {
  const url = `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/orders?fields=shippingAddress1%2CshippingAddress2%2CshippingCity%2CshippingState%2CshipZIP%2Ccountry%2CshipVia%2CtermsCode%2CPONumber&orderNumber=${orderNumber}`;
  return new Promise((resolve, reject) => axios.get(url, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.status !== 200) {
      reject(res);
    }
    const data = res.data.Data;
    resolve(data);
  })
    .catch((e) => {
      console.log(e);
      reject(new Error('500'));
    }));
};

export const allShippingAddresses = (token: string): Promise<IShippingDetail[]> =>
  simpleFetch(token, 'https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/shipping-addresses');
export const customerShippingAddresses = (token: string, customerCode: string): Promise<IShippingDetail[]> => {
  const url = `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/shipping-addresses?customerCode=${customerCode}`;
  return simpleFetch(token, url);
};

export const singleContact = (token: string, customerCode: string): Promise<IContact> =>
  simpleFetch(token, `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/contacts?contactCode=${customerCode}`);
export const allContacts = (token: string): Promise<IContact[]> =>
  simpleFetch(token, 'https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/contacts');
export const orderPONumber = (token: string, orderNumber: string): Promise<{ orderNumber: string, poNumber: string }> => {
  const url = `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/orders?fields=PONumber&orderNumber=${orderNumber}`;
  return new Promise((resolve, reject) => axios.get(url, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.status !== 200) {
      reject(res);
    }
    const data = res.data.Data;
    resolve({ poNumber: data[0]?.PONumber ?? '', orderNumber });
  })
    .catch((e) => {
      console.log(e);
      reject(new Error('500'));
    }));
};

export const partDetail = (token: string, partNumber: string): Promise<{ partNumber: string, lastJobDateFinished: string, user_Text1: string}[]> => {
  const url = `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/estimates/${partNumber}?fields=partNumber%2ClastJobDateFinished%2Cuser_Text1`;
  return new Promise((resolve, reject) => axios.get(url, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (!_.includes([200, 404], res.status)) {
      reject(res);
    }
    const data = res.data.Data;
    resolve(data);
  })
    .catch((e) => {
      // if we get a 404, it just means the record was deleted from JB, but it may still exist on an order.
      // in this case we just want to return a null value to indicate as much, but not throw an error.
      if (e.response.status === 404) {
        resolve(null);
      }
      console.log(e);
      reject(new Error(e.response.status));
    }));
};

export const productCodes = async (token: string): Promise<IProductCode[]> =>
  simpleFetch(token, 'https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/product-codes');

export const saveNewEstimate = async (
  token: string,
  customerCode: string,
  description: string,
  partNumber: string,
  price: number,
  volume: string = '',
  existingPart: boolean = false,

): Promise<number> => {
  const revisionDate = new Date();
  const monthString = revisionDate.getMonth() < 9 ? `0${revisionDate.getMonth() + 1}` : revisionDate.getMonth() + 1;
  const dateString = revisionDate.getDate() < 10 ? `0${revisionDate.getDate()}` : revisionDate.getDate();
  const revisionDateString = `${revisionDate.getFullYear()}-${monthString}-${dateString}`;
  devLog('jb_api', 91, revisionDateString);
  devLog('jb_api', 91, `Existing part? ${existingPart}`);

  const postData = {
    active: true,
    customerCode,
    description,
    lockPrice: true,
    GLCode: '15900',
    partNumber,
    price1: price,
    productCode: 'FINISHGOOD',
    pricingUnit: 'USD',
    quantity1: 1,
    revision: 'admin-tool-autogen',
    revisionDate: revisionDateString,
    user_Text1: volume,
    user_Text4: 'autogen',
  };

  return new Promise((resolve, reject) => {
    if (existingPart) {
      devLog('jb_api', 113, 'Existing');
      if (description.match(/^[G|B]B/)) {
        devLog('jb_api', 115, 'Updating volume...');
        const patchData = {
          user_Text1: volume,
          revisionDate: revisionDateString,
        };
        axios.patch(
          `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/estimates/${partNumber}`,
          patchData,
          {
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        ).then((res) => {
          resolve(204);
        });
      } else {
        resolve(200);
      }
    } else {
      axios.post(
        'https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/estimates',
        postData,
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((res) => {
          if (res.status !== 201) reject(res);
          resolve(res.status);
        })
        .catch((reason) => {
          console.log(reason);
          reject(reason.response.data.detail);
        });
    }
  });
};

export const shipmentItems = async (token: string, shipmentNumber: string): Promise<IShippingLineItem[]> => {
  const url = `https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/packing-list-line-items?deliveryTicketNumber=${shipmentNumber}'`;
  return new Promise((resolve, reject) => axios.get(url, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.status !== 200) {
      reject(res);
    }
    // don't return any items we haven't at least partially shipped
    const data = res.data.Data.filter((d: { quantityToShip: number }) => d.quantityToShip > 0);
    resolve(data);
  })
    .catch((e) => {
      // if we get a 404, it just means the record was deleted from JB, but it may still exist on an order.
      // in this case we just want to return a null value to indicate as much, but not throw an error.
      console.log(e);
      reject(new Error(e.response.status));
    }));
};

export const vendorData = async (token: string): Promise<IVendor[]> => {
  const url = 'https://api-jb2.integrations.ecimanufacturing.com:443/api/v1/vendors?fields=accountingID%2Cactive%2CARContact%2CARPhone%2CaverageDaysToPay%2Ccomments1%2Ccomments2%2CcurrencyCode%2CdateLast%2CdateOpen%2CdefaultEmployeeCode%2CdefaultFOB%2CenteredBy%2CenteredDate%2CFAX%2CfederalIDNumber%2Cform1099Vendor%2CGLAccount1%2CGSTCode%2CisWebUser%2ClastModDate%2ClastModUser%2CleadTime%2Cmarkup%2CminimumOrder%2CoutsideService%2Cphone%2CprintTicket%2CpurchasingAddress1%2CpurchasingCity%2CpurchasingContact%2CpurchasingCountry%2CpurchasingPhone%2CpurchasingState%2CpurchasingZipCode%2CQBVendorCode%2CremittanceAddress1%2CremittanceCity%2CremittanceCountry%2CremittanceState%2CremittanceZipCode%2CrestockingPercent%2CrevisedDate%2CsalesTaxCode%2CsetupCharge%2CshipCode%2CshippingAddress1%2CshippingCity%2CshippingCountry%2CshippingState%2CshippingZipCode%2CshipVia%2CtermsCode%2CuniqueID%2Cuser_Currency1%2Cuser_Currency2%2Cuser_Date1%2Cuser_Date2%2Cuser_Memo1%2Cuser_Number1%2Cuser_Number2%2Cuser_Number3%2Cuser_Number4%2Cuser_Text1%2Cuser_Text2%2Cuser_Text3%2Cuser_Text4%2CvendorAccountNumber%2CvendorCode%2CvendorName%2CvendorType%2Cwebsite%2CworkdayBegin%2CworkdayEnd%2CworkSaturday%2CworkSunday%2CYTDPurchases';

  return new Promise<IVendor[]>((resolve, reject) => (
    Promise.all([
      simpleFetch(token, url),
      simpleFetch(token, `${url}&skip=200`),
    ]).then((results) => resolve(results.reduce((a, b) => [...a, ...b], [])))));
};
