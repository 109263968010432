import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Button, Divider, InputNumber, Modal,
} from 'antd';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
import { H6 } from 'shared/typography';
import useFirebase from 'vendor/Firebase';
import {
  bodyDataAtom, neckDataAtom, pricingExportDataAtom,
} from 'shared/state/pricingState';
import { useTestData } from 'shared/util';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import {
  GlobalPricingRow, PriceUpdateControls, PricingHeader, PricingPageDescription,
} from './styledComponents';
import PricingExportButton from '../Util/Components/PricingExportButton';
import { CustomerDownloadButton } from '../Customer/Components/styledComponents';

const ParserRow = styled(FlexRow)`
  width: 50%;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px 0px 0px;
  padding: 24px 0px;
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
`;

const ImportHeader = styled(FlexColumn)`
  align-items: flex-start;
`;
const ImportRow = styled(ParserRow)`
  margin-top: 0;
  border-top: none;
`;

const DownloadButton = styled(CustomerDownloadButton)`
  min-width: 240px;
  text-align: center;
  margin-bottom: unset;
`;

export default () => {
  const bodyData = useRecoilValue(bodyDataAtom);
  const neckData = useRecoilValue(neckDataAtom);
  const [pricingExportData, setPricingExportData] = useRecoilState(pricingExportDataAtom);

  const { database } = useFirebase();
  const [updatePercent, setUpdatePercent] = useState(0);

  const updatePrice = (price: number) => Math.round(price / (1 - (updatePercent / 100)));

  const onConfirmUpdate = () => {
    try {
      const newBodyData = { ...bodyData };
      const newNeckData = { ...neckData };

      // @ts-ignore
      newBodyData.archetype.items = bodyData.archetype.items.map((item: any) => ({
        // @ts-ignore
        ...item,
        price: updatePrice(item.price),
      }));

      // @ts-ignore
      newBodyData.bodyWood.items = bodyData.bodyWood.items.map((item: any) => ({
        ...item,
        price: {
          bass: updatePrice(item.price + 5),
          guitar: updatePrice(item.price),
        },
      }));

      // @ts-ignore
      newBodyData.topWood.items = bodyData.topWood.items.map((item: any) => ({
        ...item,
        price: {
          bass: updatePrice(item.price.L),
          guitar: updatePrice(item.price.M),
        },
      }));

      // @ts-ignore
      const newBodyOptions = Object.entries(bodyData.options).map((entry) => {
        const [optionSetName, optionSet] = entry;
        return [optionSetName, {
          // @ts-ignore
          ...optionSet,
          items: optionSet.items.map((item: any) => ({
            ...item,
            price: updatePrice(item.price),
          })),
        }];
      });

      newBodyOptions.forEach((option) => {
        const [optionName, optionSet] = option;
        // @ts-ignore
        newBodyData.options[optionName] = optionSet;
      });

      // @ts-ignore
      newNeckData.archetype.items = neckData.archetype.items.map((item: any) => ({
        // @ts-ignore
        ...item,
        price: updatePrice(item.price),
      }));

      // @ts-ignore
      newNeckData.neckWood.items = neckData.neckWood.items.map((item: any) => ({
        ...item,
        price: {
          bass: updatePrice(item.price.L),
          guitar: updatePrice(item.price.M),
        },
      }));

      // @ts-ignore
      newNeckData.fretboardWood.items = neckData.fretboardWood.items.map((item: any) => ({
        ...item,
        price: {
          bass: updatePrice(item.price.L),
          guitar: updatePrice(item.price.M),
        },
      }));

      // @ts-ignore
      const newNeckOptions = Object.entries(neckData.options).map((entry) => {
        const [optionSetName, optionSet] = entry;
        return [optionSetName, {
          // @ts-ignore
          ...optionSet,
          items: optionSet.items.map((item: any) => ({
            ...item,
            price: updatePrice(item.price),
          })),
        }];
      });

      newNeckOptions.forEach((option) => {
        const [optionName, optionSet] = option;
        // @ts-ignore
        newNeckData.options[optionName] = optionSet;
      });

      database.ref('/').set({
        body: newBodyData,
        neck: newNeckData,
      });

      Modal.success({
        content: 'All done! Prices will be reflected everywhere in the app, immediately.',
        onOk: () => { window.location.reload(); },
      });
    } catch {
      Modal.error({
        content: 'Uh-oh! Something went wrong. Let Keith know so he can look into it.',
        onOk: () => { window.location.reload(); },
      });
    }
  };

  const onUpdate = (e: any) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Confirm global update',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to update ALL prices in the price database by ${updatePercent.toFixed(2)}%?`,
      okText: 'Yes, I\'m sure',
      cancelText: 'Nevermind',
      onOk: onConfirmUpdate,
      onCancel: () => {},
    });
  };

  const onPercentChange = (value: any) => {
    setUpdatePercent(value);
  };

  return (
    <ConfigurationCol>
      <PricingHeader>Edit Global Pricing</PricingHeader>
      <PricingPageDescription>
        {ReactHtmlParser(`Use this page to edit <u><b>ALL</b></u> prices globally, by a fixed percent.
           <br/><br/><b>WARNING:</b> This action will take effect immediately upon submission.
           To revert, you will need to change prices by the same negative percent.`)}
      </PricingPageDescription>
      <Divider />

      <GlobalPricingRow justify="space-between">
        <H6>Update prices by</H6>
        <PriceUpdateControls>
          <InputNumber
            formatter={(value) => `${value}%`}
          // @ts-ignore
            parser={(value) => value.replace('%', '')}
            placeholder="0.00%"
            step="0.25"
            defaultValue={0}
            value={updatePercent}
            onChange={onPercentChange}
          />
          <Button type="primary" onClick={onUpdate}>Update</Button>
        </PriceUpdateControls>
      </GlobalPricingRow>
      <ParserRow align="center" justify="flex-start">
        <H6>Export Pricing Data</H6>
        {pricingExportData.length === 0 ? (
          <PricingExportButton />
        ) : (
          <DownloadButton
            key="pricing-information-download-button"
            filename={`${useTestData ? 'TEST_' : ''}Pricing_Data_${new Date().toLocaleString()}.csv`}
            data={pricingExportData}
            // enclosingCharacter={"'"}
            headers={[
              { label: 'JB Part #', key: 'jbPartNumber' },
              { label: 'Item Category', key: 'itemCategory' },
              { label: 'Instrument Type', key: 'instrumentType' },
              { label: 'Description', key: 'description' },
              { label: 'Price', key: 'price' },
              { label: '', key: '' },
              { label: 'PartId', key: 'id' },
            ]}
            onClick={() => {
              setTimeout(() => {
                setPricingExportData([]);
              }, 200);
            }}
          >
            Export Pricing Data
          </DownloadButton>
        )}
      </ParserRow>
    </ConfigurationCol>
  );
};
