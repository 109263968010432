import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { find, sortBy } from 'lodash';
import PartDetailDropdown from 'shared/components/Input/PartDetailDropdown';
import { currentInventoryPartAtom, inventoryItemsAtom, inventoryPartEditedAtom } from 'shared/state/inventoryState';
import { IGlCode, IInventoryPart } from 'shared/types/dbRecords';

interface IComponent {
  disabled: boolean;
}
const SubItemParentDropdown = ({ disabled }: IComponent) => {
  // @ts-ignore
  const [partState, setPartState] = useRecoilState(currentInventoryPartAtom);
  const setEdited = useSetRecoilState(inventoryPartEditedAtom);
  const [parentPartNumber, setParentPartNumber] = useState<string>();
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const [parentOptions, setParentOptions] = useState<{ label: string; key: string; }[]>([]);

  const onParentChange = (e: any) => {
    setEdited(true);
    const parentSku = e.split('-')[0];
    const parentPerChild = partState.Parent?.ParentPerChild || 1;
    const parent = find(inventoryItems, (i: IInventoryPart) => i.Sku === parentSku);
    // @ts-ignore
    setPartState({
      ...partState,
      Parent: {
        Id: parent?.Id,
        Sku: parentSku,
        Description: parent?.Description || '',
        ParentPerChild: parentPerChild,
      },
    });
    setParentPartNumber(parentSku);
  };

  useEffect(() => {
    setParentPartNumber(partState.Parent?.Sku);
    setParentOptions(sortBy(inventoryItems
      .filter((i: IInventoryPart) => {
        // i.partNumber is a band-aid -- there are still some parts that don't
        // have the new item structure, and I'm passing on this to get this feature built.
        // TODO: convert all old parts to new part schema and remove this boolean or
        const parentSkuPrefix = (i.Sku || i.partNumber).substring(0, 3);
        return parentSkuPrefix === partState.Sku.substring(0, 3);
      })
      .map((i: IInventoryPart) => (
        { label: i.Description, key: i.Sku }
      )), (e: { label: string, key: string }) => e.key));
  }, [partState.Id]);

  return (
    <PartDetailDropdown
      changeCallback={onParentChange}
      disabled={disabled || false}
      id="inventory-part-allocated-part-selector"
      label="Parent part"
      placeholder="Select part"
      // @ts-ignore
      value={parentPartNumber}
      valueOptions={parentOptions}
    />
  );
};

export default SubItemParentDropdown;
