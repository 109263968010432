import { atom } from 'recoil';
import { IConsumptionRecord } from '../types/order';

export const hidePositiveQuantityAtom = atom<boolean>({
  key: 'hidePositiveQuantityAtom',
  default: true,
});

export const showPartReqDrawerAtom = atom<boolean>({
  key: 'showPartReqDrawerAtom',
  default: false,
});

export const currentRequirementRecordAtom = atom<IConsumptionRecord>({
  key: 'currentRequirementRecordAtom',
  default: { } as IConsumptionRecord,
});
