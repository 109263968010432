import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Add, AddCircle } from '@styled-icons/material';
import { stageRedirect } from '../../../../shared/util';

const NewPartButton = styled(Button)`
  border-radius: 8px;
`;

const NewIcon = styled(AddCircle)`
    width: 20px;
    position: relative;
    left: -4px;
    margin: 0 4px 3px 0px;
`;

const NewInventoryPartButton = () => {
  const onClick = (e: any) => {
    stageRedirect();
    window.location.href = '/inventory/new';
  };
  return (
    <NewPartButton key="new-part-inventory-button" type="primary" onClick={onClick} icon={<NewIcon />}>New</NewPartButton>
  );
};

export default NewInventoryPartButton;
