import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { rgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import {
  H3, H6,
} from 'shared/typography';
import { Exclamation, ThumbsUp } from '@styled-icons/fa-solid';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { Button } from 'antd';
import { useSetRecoilState } from 'recoil';
import { messageAcknolwedgeAtom } from 'shared/state/routingState';

interface IComponent {
  station: string;
}

interface IMessageComponent {
  callback: (e: any) => void;
  station: string;
  note: string;
}

const AutoScanOverlay = styled(FlexColumn)`
    width: 100vw;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1000;

    background-color: ${rgba(theme.palette.neutral.black, 0.8)};
    backdrop-filter: blur(4px);

    color: ${theme.palette.neutral.white};
    padding: ${theme.spacing(3)};
`;

const AutoScanMessageOverlay = styled(AutoScanOverlay)`
  background-color: ${theme.palette.warning.hue};
`;

const MessageWrapper = styled(FlexColumn)`
  height: 50%;
  justify-content: space-between;
`;

const OverlayCopy = styled(H3)`
    font-size: 10vw;
    margin: 0;
    color: ${theme.palette.neutral.white};
    margin-bottom: 24px;
    text-align: center;
`;

const MessageAckButton = styled(Button)`
    width: calc(100vw - ${theme.spacing(4)});
    height: 100px; 
    text-align: center;
    border-radius: 12px;
    border: rgba(255, 255, 255, 0.2);
    
    whitespace: normal;
    
    background-color: ${theme.palette.neutral.white}; 
    
    color: ${theme.palette.primary.hue};
    
    margin: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
    margin-top: auto;
    
    box-shadow: 4px 4px 8px ${rgba(theme.palette.neutral.black, 0.08)}, 
      -4px -4px 8px ${rgba(theme.palette.neutral.black, 0.1)}, 
      inset 1px 1px 8px ${rgba(theme.palette.neutral.black, 0.02)};
    
    & p {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }
    
    @media ${theme.device.laptop} {
        width: 256px;
        height: 256px;
        font-size: 20px;
        
        &:last-of-type {
          margin-bottom: ${theme.spacing(2)};
        }
    }
  
  `;

const IconBackground = styled(FlexColumn)`
    width: 100px;
    height: 100px;

    background-color: ${theme.palette.success.hue};
    border-radius: 50%;
`;

const IconWarningBackground = styled(IconBackground)`
  width: 80px;
  height: 80px;
  background-color: ${theme.palette.neutral.white};
`;

export default ({ station }: IComponent) => (
  <AutoScanOverlay>
    <OverlayCopy>{ReactHtmlParser(`Checking in to:<br/> ${station}`)}</OverlayCopy>
    <IconBackground>
      <ThumbsUp style={{ color: theme.palette.neutral.white, width: 64 }} />
    </IconBackground>
  </AutoScanOverlay>
);

export const AutoScanWithMessageOverlay = ({ callback, station, note }: IMessageComponent) => {
  const setMessageAcknolwedge = useSetRecoilState(messageAcknolwedgeAtom);

  const setMessageAck = (e: any) => {
    e.preventDefault();
    callback(e);
  };

  return (
    <AutoScanMessageOverlay>
      <MessageWrapper>
        <FlexColumn>
          <IconWarningBackground>
            <Exclamation style={{ color: theme.palette.warning.hue, width: 20 }} />
          </IconWarningBackground>
          <OverlayCopy>{`NOTE: ${note}`}</OverlayCopy>
        </FlexColumn>
        <MessageAckButton onClick={setMessageAck}><H6 style={{ margin: 0 }}>I understand</H6></MessageAckButton>
      </MessageWrapper>
    </AutoScanMessageOverlay>
  );
};
