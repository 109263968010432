import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { every, sortBy } from 'lodash';
import { List } from 'antd';
import { EmptyPriceItem } from 'pages/Customer/Components/styledComponents';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { activePartDisplayAtom } from 'shared/state/partViewState';
import { scrollToHash, scrollOptions } from 'shared/scroll';
import { vendorRecordsAtom, vendorSearchQueryAtom } from 'shared/state/vendorState';
import { Body } from 'shared/typography';
import { IVendor } from 'shared/types/vendor';
import VendorRecord from './VendorRecord';

const RecordWrapper = styled(FlexColumn)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ItemList = styled(List)`
  width: 100%;
  & li {
    padding: 0;
  }
`;

const VendorList = () => {
  const currentPageString = sessionStorage.getItem('currentVendorPage');
  const vendors = useRecoilValue(vendorRecordsAtom);
  const vendorSearchQuery = useRecoilValue(vendorSearchQueryAtom);
  const activeDisplayType = useRecoilValue(activePartDisplayAtom);
  const [currentPage, setCurrentPage] = useState(currentPageString ? parseInt(currentPageString, 10) : 1);
  const [renderItems, setRenderItems] = useState([]);
  // @ts-ignore
  const { vendorCode } = useParams();

  const updatePageData = (): void => {
    // @ts-ignore
    setRenderItems(sortBy(vendors, (i: IInventoryItem) => i.partNumber)
      .filter((v: IVendor) => {
        if (activeDisplayType === 'active') return v.active;
        if (activeDisplayType === 'inactive') return !v.active;
        return v;
      })
      .filter((v: IVendor) => {
        const terms = vendorSearchQuery.split(',');
        const matchString = `${v.vendorName}${v.vendorCode}${v.vendorType}`;
        const matches = terms.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return every(matches, Boolean);
      }));
  };
  const onPageChange = (newPage: number) => {
    sessionStorage.setItem('currentVendorPage', newPage);
    setCurrentPage(newPage);
    updatePageData();
    scrollToHash('#vendor-filter', {
      ...scrollOptions,
      time: 1000,
    });
  };

  useEffect(() => {
    const currentVendorPage = sessionStorage.getItem('currentVendorPage');
    setCurrentPage(currentVendorPage ? parseInt(currentVendorPage, 10) : 1);
    updatePageData();

    setTimeout(() => {
      const el = document.getElementById(`${vendorCode}-record`);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });
  }, [vendorSearchQuery, activeDisplayType]);

  return (
    <>
      {(renderItems.length === 0)
        ? (
          <EmptyPriceItem>
            <Body>No matches available.</Body>
          </EmptyPriceItem>
        ) : (
          <RecordWrapper>
            <ItemList
              dataSource={renderItems}
              renderItem={(v: IVendor, index: number) => <List.Item id={`${v.vendorCode}-record-item`}><VendorRecord shade={index % 2 === 1} key={`vendor-item-${index}`} record={v} /></List.Item>}
              pagination={{
                current: currentPage,
                onChange: onPageChange,
                simple: true,
                total: renderItems.length,
                pageSize: 100,
                position: 'both',
              }}
            />
          </RecordWrapper>
        )}
    </>
  );
};

export default VendorList;
