import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Modal } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { ListPriceLabel } from 'shared/pageElements/styledComponents';
import { formatPercent, formatPrice } from 'shared/data';
import {
  currentInventoryPartAtom,
  INVENTORY_ITEMS_COLLECTION,
  inventoryPartEditedAtom, showQuickEditDrawerAtom,
} from 'shared/state/inventoryState';
import { ItemDetailHeader } from 'shared/styledComponents/containers';
import { ItemDetailBackButton } from 'shared/styledComponents/inputs';
import { TotalPrice, PriceDiscount } from 'shared/styledComponents/typographicElements';
import useFirebase from 'vendor/Firebase';
import SimpleModal from 'shared/components/Utility/SimpleModal';
import { currentCustomerAtom, CUSTOMERS_DB_COLLECTION } from 'shared/state/customerState';
import { redirect } from 'shared/util';
import SaveCustomerRecordButton from './Buttons/SaveCustomerRecordButton';

const CustomerDetailHeader = () => {
  const [customer, setCustomer] = useRecoilState(currentCustomerAtom);
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const { firestore } = useFirebase();
  const [showModal, setShowModal] = useState(false);

  const onBack = (e: any) => {
    e.preventDefault();
    redirect();
  };

  const onSave = (e: any) => {
    firestore.collection(customerDbString).doc(customer.id).set(customer).then(() => {
      redirect();
    });
  };

  const onCancel = (e: any) => {
    setShowModal(false);
  };

  const onDiscard = (e: any) => {
    redirect();
  };

  // useEffect(() => {
  //   const partCost = partState.useWeightedCost ? partState.weightedCost : partState.purchaseCost;
  //   setPrice(partState.price);
  //   setDiscount(((partState.price || 0) - ((partCost || 0) * partState.purchaseFactor)) / (partState.price || 1));
  // }, [partState.weightedCost, partState.purchaseCost, partState.price]);

  return (
    <>
      <SimpleModal
        title="Save changes before leaving?"
        modalOpen={showModal}
        buttons={[
          {
            label: 'Discard', onClick: onCancel, key: 'discard-button', type: 'default',
          },
          {
            label: 'Save', onClick: onSave, key: 'save-button', type: 'priamry',
          },
        ]}
      />
      <ItemDetailHeader>
        <ItemDetailBackButton
          shape="circle"
          type="primary"
          // @ts-ignore
          icon={<LeftOutlined />}
          onClick={onBack}
        />
        <SaveCustomerRecordButton />
      </ItemDetailHeader>
    </>
  );
};

export default CustomerDetailHeader;
