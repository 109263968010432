import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'antd';
import styled from 'styled-components';
import { IShipment } from 'shared/types/dbRecords';
import { rgba as hexRgba } from 'shared/palette/utils';
import { stageRedirect } from 'shared/util';
import theme from 'shared/theme';
import {
  SHIPMENT_NUMBER_COLUMN, SHIP_DATE_COLUMN, DESCRIPTION_COLUMN, QTY_SHIPPED_COLUMN, TRACKING_NUMBER_COLUMN,
} from 'pages/Shipment/Components/ShipmentList/ShipmentListColumns';

interface IComponent {
  partId: string;
  closeCallback: () => void;
  shipments: IShipment[];
  open: boolean;
}

const ShipmentModal = styled(Modal)`
    width: 80vw !important;
`;
const ShipmentTable = styled((props) => <Table {...props} />)`
    width: 100%;
    && tbody > tr:hover > td {
        background: ${hexRgba(theme.palette.primary.hue, 0.2)};
    }
`;
const QuickViewShipmentsModal = ({
  partId, closeCallback, shipments = [], open = false,
}: IComponent) => {
  const [_open, setOpen] = useState<boolean>(open);
  const [_shipments, setShipments] = useState<IShipment[]>(shipments);
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const onRowClick = (record: IShipment) => (e: any) => {
    e.preventDefault();
    stageRedirect();
    window.location.href = `/shipments/edit?shipmentId=${record.id}&orderId=${record.orderId}`;
  };

  const onClose = (e: any) => {
    closeCallback();
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    setShipments(shipments);
  }, [shipments]);

  return (
    <ShipmentModal
      open={_open}
      onCancel={onClose}
      onOk={onClose}
      closable
      title={`${partId}: Shipments`}
    >
      <ShipmentTable
        dataSource={_shipments.filter((r: IShipment) => !!r.shipmentNumber)}
        rowKey="Id"
        size="large"
        rowClassName="sales-order-row clickable-row"
        columns={[SHIPMENT_NUMBER_COLUMN, SHIP_DATE_COLUMN, QTY_SHIPPED_COLUMN, DESCRIPTION_COLUMN, TRACKING_NUMBER_COLUMN]}
        pagination={{ pageSize: 10, current: currentPage, onChange: onPageChange }}
        scroll={{ y: window.innerHeight * 0.8 }}
        onRow={(record: IShipment) => ({
          onClick: onRowClick(record),
        })}
      />
    </ShipmentModal>
  );
};

export default QuickViewShipmentsModal;
