import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import WhereUsedPanel from '../Panels/WhereUsed';

interface IComponent {
  partSku: string;
  callback: () => void;
}

const QuickViewOptionsColumn = styled(FlexColumn)`
    width: 120px;
    align-items: flex-start;
    justify-content: center;
    padding: 8px;
    gap: 4px
`;

const QuickViewOptionWrapper = styled(FlexRow)`
    width: 100%;
`;

const QuickViewOptionsButton = styled(Button)`
    width: 100%;
    height: 28px;
    margin: 0;
    border-radius: 4px;
    line-height: 18px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
`;

const QuickViewOptions = ({ partSku, callback }: IComponent) => {
  const [showWhereUsedPanel, setShowWhereUsedPanel] = useState<boolean>(false);
  const onShowWhereUsedPanel = (e: any) => {
    e.preventDefault();
    callback();
    setShowWhereUsedPanel(true);
  };
  const onCloseWhereUsedPanel = (e: any) => {
    e.preventDefault();
    setShowWhereUsedPanel(false);
  };
  return (
    <>
      <WhereUsedPanel key="where-used-data-panel" showDrawer={showWhereUsedPanel} inventoryPartNumber={partSku} onClose={onCloseWhereUsedPanel} />
      <QuickViewOptionsColumn>
        <QuickViewOptionWrapper>
          <QuickViewOptionsButton onClick={onShowWhereUsedPanel}>Where Used</QuickViewOptionsButton>
        </QuickViewOptionWrapper>
      </QuickViewOptionsColumn>
    </>
  );
};

export default QuickViewOptions;
