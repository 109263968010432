import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Drawer } from 'antd';
import { IQATicket } from 'shared/types/dbRecords';
import { showNCReportAtom } from 'shared/state/qualityAssuranceState';
import NCReportPDF from '../NCReportPDF';

interface IComponent {
  qaTicket: IQATicket;
}
const NCReportPDFDrawer = ({ qaTicket }: IComponent) => {
  const [open, setOpen] = useRecoilState(showNCReportAtom);
  const [_qaTicket, setQATicket] = useState<IQATicket>(qaTicket);

  const closeDrawer = (e: any) => {
    setOpen(false);
  };

  useEffect(() => {
    setQATicket(qaTicket);
  }, [qaTicket]);

  return (
    <Drawer
      placement="right"
      width="78vw"
      open={open}
      onClose={closeDrawer}
    >
      <NCReportPDF qaTicket={_qaTicket} />
    </Drawer>
  );
};

export default NCReportPDFDrawer;
