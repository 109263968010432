import shortid from 'shortid';
import React from 'react';
import { formatMargin, formatPrice } from 'shared/data';
import { SubdirectoryArrowRight } from '@styled-icons/material';
import { ICustomerRecord, IInventoryPart } from 'shared/types/dbRecords';
import { CellCopy, ParentCellCopy } from 'shared/styledComponents/typographicElements';
import { FlexRow } from 'shared/containers/FlexContainer/styledComponents';
import styled from 'styled-components';
import theme from 'shared/theme';
import { Tooltip } from 'antd';
import ActionButtons from './Buttons/ActionButtons';

const ChildArrow = styled(SubdirectoryArrowRight)`
    width: 24px;
    margin-bottom: 6px;
    color: ${theme.palette.primary.hue};
`;
export const CustomerIdCopy = styled(CellCopy)`
    font-weight: bold;
`;

const ID_COLUMN = {
  title: 'ID',
  dataIndex: 'ID',
  render: (text: string, record: ICustomerRecord) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <>
    <CustomerIdCopy key={shortid.generate()}>{record.DisplayName}</CustomerIdCopy>
  </>,
  }),
  width: '5%',
};
const NAME_COLUMN = {
  title: 'Name',
  dataIndex: 'Name',
  render: (text: string, record: ICustomerRecord) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.CompanyName}</CellCopy>,
  }),
  width: '30%',
};
const CONTACT_COLUMN = {
  title: 'Contact',
  dataIndex: 'Contact',
  render: (text: string, record: ICustomerRecord) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{`${record.GivenName || ''} ${record.FamilyName || ''}`}</CellCopy>,
  }),
  width: '15%',
};
const PHONE_COLUMN = {
  title: 'Phone',
  dataIndex: 'Phone',
  render: (text: string, record: ICustomerRecord) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.PrimaryPhone?.FreeFormNumber || ''}</CellCopy>,
  }),
  width: '8%',
};

const ACTION_COLUMN = {
  title: '',
  dataIndex: 'actionsColumn',
  render: (text: string, record: ICustomerRecord) => ({
    children:
  <ActionButtons customer={record} />,
  }),
  width: '10%',
};
export {
  ID_COLUMN,
  NAME_COLUMN,
  CONTACT_COLUMN,
  PHONE_COLUMN,
  ACTION_COLUMN,
};
