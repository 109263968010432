import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatPrice } from '../../../../shared/data';
import { cleanMeta } from '../../../../shared/text';

interface IComponent {
  quantity: number;
  partNumber: string;
  description: string;
  notes: string;
  unitPrice: number;
}

const HEADER_FONT_SIZE = 10;
const BODY_FONT_SIZE = 8;
const HORIZONTAL_PADDING = 24;

const style = StyleSheet.create({
  componentWrapper: {
    width: '95%',
    height: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderBottom: '1px solid lightgrey',
    paddingTop: 4,
    paddingBottom: 4,
    marginLeft: HORIZONTAL_PADDING,
    // gap: 4,
  },
  headerComponentWrapper: {
    marginTop: 24,
    paddingBottom: 0,
  },
  quantityWrapper: {
    width: 28,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  partNumberWrapper: {
    width: 96,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 4,
    marginRight: 6,
  },
  unitPriceWrapper: {
    width: 64,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  headerText: {
    fontFamily: 'Red Hat Display-700',
    fontSize: HEADER_FONT_SIZE,
  },
  bodyText: {
    fontFamily: 'Red Hat Display',
    fontSize: BODY_FONT_SIZE,
  },
  partNumberText: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
  },
  // Description Column
  descriptionCol: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 480,
  },
  description: {
    fontFamily: 'Red Hat Display',
    fontWeight: 500,
    fontSize: BODY_FONT_SIZE,
    margin: 0,
  },
  notes: {
    fontFamily: 'Red Hat Display',
    fontWeight: 300,
    fontSize: BODY_FONT_SIZE,
    margin: 0,
    marginLeft: 4,
    marginTop: 2,
    color: '#666666',
  },
});

const OrderConfirmationItemHeader = () => (
  <View fixed style={[style.componentWrapper, style.headerComponentWrapper]}>
    <View style={style.quantityWrapper}>
      <Text style={style.headerText}>Qty</Text>
    </View>
    <View style={style.partNumberWrapper}>
      <Text style={style.headerText}>Model</Text>
    </View>
    <View style={style.descriptionCol} />
    <View style={style.unitPriceWrapper}>
      <Text style={style.headerText}>Unit Price</Text>
    </View>
    <View style={style.unitPriceWrapper}>
      <Text style={style.headerText}>Amount</Text>
    </View>
  </View>
);
const OrderConfirmationItem = ({
  quantity, partNumber, description, notes, unitPrice,
}: IComponent) => (
  <View wrap={false} style={style.componentWrapper}>
    <View style={style.quantityWrapper}>
      <Text style={style.bodyText}>{quantity}</Text>
    </View>
    <View style={style.partNumberWrapper}>
      <Text style={[style.bodyText, style.partNumberText]}>{partNumber}</Text>
    </View>
    <View style={style.descriptionCol}>
      <Text wordBreak="break-all" style={style.description}>{cleanMeta(description)}</Text>
      <Text style={style.notes}>{notes}</Text>
    </View>
    <View style={style.unitPriceWrapper}>
      <Text style={style.bodyText}>{formatPrice(unitPrice)}</Text>
    </View>
    <View style={style.unitPriceWrapper}>
      <Text style={style.bodyText}>{formatPrice(unitPrice * quantity)}</Text>
    </View>
  </View>
);

export {
  OrderConfirmationItemHeader,
  OrderConfirmationItem,
};
