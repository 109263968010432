import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import shortid from 'shortid';
import styled from 'styled-components';
import { Button } from 'antd';
import { Add } from '@styled-icons/material';
import {
  showOrderItemEditDrawerAtom,
} from 'shared/state/orderState';
import { IOrderItem, IShipmentItem } from 'shared/types/dbRecords';
import { currentShipmentItemAtom } from '../../../../../shared/state/shipmentState';

const ComponentButton = styled(Button)`
  border-radius: 8px;
`;

const AddIcon = styled(Add)`
  width: 24px;
  bottom: 1px;
  position: relative;
  left: -4px;
`;

interface IComponent {
  orderId: string;
  disabled: boolean;
  salesOrder: string;
  onAddCallback: (newItem: IShipmentItem) => void;
}
const AddShipmentItemButton = ({
  orderId, salesOrder, onAddCallback, disabled = false,
}: IComponent) => {
  const [showOrderItemEditDrawer, setShowOrderItemEditDrawer] = useRecoilState(showOrderItemEditDrawerAtom);
  const setCurrentShipmentItem = useSetRecoilState(currentShipmentItemAtom);

  const onAdd = (e: any) => {
    e.preventDefault();
    const newItemId = shortid.generate();
    const newItem = {
      id: newItemId,
      orderId,
      orderNumber: salesOrder,
      Sku: '',
      Description: '',
      houseSample: false,
      open: true,
      unitPrice: 0,
      notes: '',
      lastFinishedDate: '',
      volume: '',
      quantityAssigned: 0,
      quantityOrdered: 1,
      quantityCanceled: 0,
      quantityShipped: 1,
      quantityOpen: 0,
      bom: [],
    } as unknown as IShipmentItem;
    // @ts-ignore
    if (onAddCallback) onAddCallback(newItem);
  };

  return (
    <ComponentButton type="primary" disabled={disabled} icon={<AddIcon />} onClick={onAdd}>Add part</ComponentButton>
  );
};

export default AddShipmentItemButton;
