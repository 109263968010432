import styled from 'styled-components';
import {
  Button, Divider, Dropdown, Input,
} from 'antd';
import { PlusCircle, Print, TimesCircle } from '@styled-icons/fa-solid';
import theme from 'shared/theme';
import { Body, Caption } from 'shared/typography';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { rgba } from 'shared/palette/utils';

export const CustomerInfoWrapper = styled.div`
    width: 100%;
    height: auto;
    background-color: ${theme.palette.neutral.white};

    padding: ${theme.spacing(1)} 0 ${theme.spacing(3)} 0;
`;

export const InfoDivider = styled(Divider)`
    & span {
        padding: 0;
    }

`;

export const InputCaption = styled(Caption)`
    font-size: 16px;
    margin: 0;
    margin-bottom: ${theme.spacing(1)};
`;

export const SOInput = styled(Input)`
    width: ${theme.spacing(20)};
`;

export const DescInput = styled(Input)`
  width: 100%;
`;

export const FieldRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 24px;
`;

export const FieldWrapper = styled(FlexColumn)`
    width: ${(props: any) => props.width || 'auto'};
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: ${theme.spacing(4)};
`;

export const OrderPartFieldWrapper = styled(FieldWrapper)`
  width: 100%;
  height: unset;
  max-height: ${(props: any) => (props.expand ? '200vh' : '0px')};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  @media ${theme.device.desktop} {
    width: 80%;
  }
`;

export const OrderItemHeader = styled(FlexRow)`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 12px;
  margin-bottom: 20px;
  gap: 12px;
`;

export const WorkOrderWrapper = styled(FieldWrapper)`
    width: 100%;
    height: auto;
`;

export const OrderShipmentsWrapper = styled(WorkOrderWrapper)`
  width: 100%;
  margin-bottom: 48px;
`;

export const WorkOrderColumn = styled(FlexColumn)`
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`;

export const RunnerExportButton = styled(Button)`
    margin-left: 24px;
`;

export const RouterHistoryLi = styled.li`
  list-style-type: none;
  margin-left: -36px;

  &:first-child {
    margin-top: 20px;
  }

  & p {
    font-size: 16px;
    margin: 0;

    &:last-child {
      margin-top: 2px;
      margin-bottom: 20px;
    }
  }
`;

export const PasteButton = styled(Button)`
  margin-right: 0;
  margin-left: auto;

  background-color: ${theme.palette.success.hue};
  color: ${theme.palette.neutral.white};

  &:hover {
    background-color: ${theme.palette.success.D200};
    border-color: ${theme.palette.success.hue};
    color: ${theme.palette.neutral.white};
  }
`;
