import shortid from 'shortid';
import React from 'react';
import { Button, Popover, Tooltip } from 'antd';
import { formatMargin, formatPrice } from 'shared/data';
import { LocationSearching, SubdirectoryArrowRight } from '@styled-icons/material';
import { IInventoryPart, IShipment, IShipmentItem } from 'shared/types/dbRecords';
import { CellCopy, ParentCellCopy } from 'shared/styledComponents/typographicElements';
import { FlexRow } from 'shared/containers/FlexContainer/styledComponents';
import styled from 'styled-components';
import theme from 'shared/theme';
import {
  every, find, includes, some, uniq,
} from 'lodash';
import { stageRedirect } from '../../../../shared/util';
import { mmddyyDate } from '../../../../shared/data/calendar';
import PurchaseOrderItemsPopover from '../../../PurchaseOrders/Components/PurchaseOrderList/PurchaseOrderItemsPopover';
import ShipmentItemsPopover from './ShipmentItemsPopover';
import { FlexColumn } from '../../../../shared/containers/FlexContainer';
// import ActionButtons from './Buttons/ActionButtons';

const ChildArrow = styled(SubdirectoryArrowRight)`
    width: 24px;
    margin-bottom: 6px;
    color: ${theme.palette.primary.hue};
`;
export const AverageCostCopy = styled(CellCopy)`
    color: ${theme.palette.primary.hue};
    font-weight: bold;
`;

const DescriptionWrapper = styled(FlexColumn)`
    align-items: flex-start;
    justify-content: flex-start;
`;
const DescriptionNotesCopy = styled(CellCopy)`
    font-size: 14px;
    white-space: pre-line;
    color: ${theme.palette.neutral[500]};
`;

const LocationIcon = styled(LocationSearching)`
    color: ${theme.palette.primary.hue};
    width: 20px;
    margin-bottom: 2px;
    transition: color 0.2s ease-in-out;
`;
const RowButton = styled(Button)`
    font-size: 16px;
    border: none;
    padding: 0;
    line-break: auto;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: ${theme.palette.primary.hue};
        & ${LocationIcon} {
            color: ${theme.palette.neutral.white};
        }
    }
`;

export const TrackWrapper = styled(FlexRow)`
    justify-content: flex-end;
    gap: 4px;
`;

export const SHIPMENT_NUMBER_COLUMN = {
  title: 'Ship #',
  dataIndex: 'shipmentNumber',
  render: (text: string, record: IShipment) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy id={record.shipmentNumber} key={shortid.generate()}>{record.shipmentNumber}</CellCopy>,
  }),
  width: '5%',
};
export const SALES_ORDER_COLUMN = {
  title: 'Sales Order',
  dataIndex: 'salesOrder',
  render: (text: string, record: IShipment) => {
    const onClick = (e: any) => {
      e.stopPropagation();
      stageRedirect(`/shipments#${record.shipmentNumber}`);
      window.location.href = `/orders/create?edit=true&orderId=${record.orderId}`;
    };
    return {
      props: {
        style: {
          textAlign: 'center',
        },
      },
      children:
  <RowButton type="ghost" onClick={onClick}>{record.salesOrder}</RowButton>,
    };
  },
  width: '8%',
};
export const SHIP_DATE_COLUMN = {
  title: 'Ship Date',
  dataIndex: 'shipDate',
  render: (text: string, record: IShipment) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{mmddyyDate(record.shipDate.toDate())}</CellCopy>,
  }),
  width: '7%',
};
export const DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: 'description',
  render: (text: string, record: IShipment) => {
    const notes = record.notes.split('\n');
    const [primary, ...rest] = notes;
    return {
      props: {
        style: {
          textAlign: 'left',
        },
      },
      children:
  <Popover placement="topLeft" trigger="contextMenu" content={<ShipmentItemsPopover items={record.shippedItems} />}>
    <CellCopy key={shortid.generate()}>{primary}</CellCopy>
    <DescriptionNotesCopy>{rest.filter((s) => s.length).join('\n')}</DescriptionNotesCopy>
  </Popover>,
    };
  },
  width: '55%',
};
export const CUSTOMER_COLUMN = {
  title: 'Customer',
  dataIndex: 'customer.DisplayName',
  render: (text: string, record: IShipment) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.customer.DisplayName}</CellCopy>,
  }),
  width: '6%',
};
export const TYPE_COLUMN = {
  title: 'Type',
  dataIndex: 'type',
  render: (text: string, record: IShipment) => {
    const partTypes = uniq(record.shippedItems.map((i: IShipmentItem) => i.Description.substring(0, 2)));
    const bodyType = some(partTypes, ((s: string) => includes(['GB', 'BB'], s)));
    const type = bodyType ? 'Body' : 'Neck';
    return {
      props: {
        style: {
          textAlign: 'center',
        },
      },
      children:
  <CellCopy key={shortid.generate()}>{type}</CellCopy>,
    };
  },
  width: '8%',
};

export const TRACKING_NUMBER_COLUMN = {
  title: 'Shipped via',
  dataIndex: 'trackingNumber',
  render: (text: string, record: IShipment) => {
    const carrier = record.trackingNumber?.match(/^1Z/) ? 'UPS' : 'FedEx';
    const trackingHref = record.trackingNumber?.match(/^1Z/)
      ? `https://www.ups.com/track?track=yes&trackNums=${record.trackingNumber}&loc=en_US`
      : `https://www.fedex.com/fedextrack/?tracknumbers=${record.trackingNumber}`;
    const onClick = (e: any) => {
      e.stopPropagation();
      window.open(trackingHref);
    };
    return {
      props: {
        style: {
          textAlign: 'center',
        },
      },
      children:
  <>
    {record.trackingNumber && (
    <TrackWrapper key={shortid.generate()}>
      {carrier}
      <Tooltip title="Track">
        <RowButton shape="circle" type="ghost" icon={<LocationIcon />} onClick={onClick} />
      </Tooltip>
    </TrackWrapper>
    )}
  </>,
    };
  },
  width: '8%',
};

interface IQuickViewShipment extends IShipment {
  quantityShipped: number;
}
// Used for shipment quick view only
export const QTY_SHIPPED_COLUMN = {
  title: 'Qty. Shipped',
  dataIndex: 'quantityShipped',
  render: (text: string, record: IQuickViewShipment) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.quantityShipped}</CellCopy>,
  }),
  width: '5%',
};

// export const ACTION_COLUMN = {
//   title: '',
//   dataIndex: 'actionsColumn',
//   render: (text: string, record: IInventoryPart) => ({
//     children:
//   <ActionButtons inventoryPart={record} />,
//   }),
//   width: '10%',
// };
