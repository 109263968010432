import React, { useEffect, useState, useRef } from 'react';
import { differenceWith, find, flatten } from 'lodash';
import {
  Page, Text, View, StyleSheet,
} from '@react-pdf/renderer';
import { IOrderItem } from 'shared/types/dbRecords';
import WorkOrderItem from '../Components/WorkOrder/WorkOrderItem';
import WorkOrderFooter from '../Components/WorkOrder/WorkOrderFooter';
import WorkOrderTimeLog from '../Components/WorkOrder/WorkOrderTimeLog';
import { IWorkOrder, IWorkOrderItem } from '../../Orders/types';

const style = StyleSheet.create({
  buffer: {
    height: 36,
    width: '100%',
  },
  headerColumn: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 2,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginBottom: 0,
    marginLeft: 10,
  },
  workOrderHeader: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 0,
    marginRight: 8,
  },
  salesOrderNumber: {
    fontFamily: 'Red Hat Display',
    fontWeight: 'normal',
    fontSize: 12,
    margin: 0,
    paddingBottom: 2,
  },
  customer: {
    fontFamily: 'Red Hat Display-300',
    fontWeight: 'light',
    fontSize: 28,
    marginLeft: 10,
  },
  page: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 60,
    paddingLeft: 32,
    paddingRight: 18,
    paddingBottom: 36,
  },
  columnHeaders: {
    width: 556,
    height: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: 2,
  },
  columnHeaderUnderline: {
    height: 1,
    backgroundColor: 'grey',
    width: 548,
    marginLeft: 12,
    marginBottom: 4,
  },
  alsoOnThisOrder: {
    width: 512,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 16,
    margin: '0 auto',
    gap: 8,
  },
  alsoOnThisOrderDivider: {
    flexGrow: 2,
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  alsoOnThisOrderText: {
    fontFamily: 'Red Hat Display',
    fontSize: 8,
    padding: '0 12px',
    margin: 0,
  },
  modelHeaderRow: {
    width: 512,
    height: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  headerText: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 10,
  },
  modelHeaderText: {
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
    fontSize: 10,
    marginLeft: 16,
  },
  quantityHeaderRow: {
    height: 'auto',
    width: 50,
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
});

interface IComponent {
  allOrderItems: IWorkOrderItem[];
  thisOrderItems: IWorkOrderItem[];
  orderDisplayItems: IOrderItem[];
  shopOrder: IWorkOrder;
  runnerId: string;
}
const WorkOrderPage = ({
  allOrderItems, thisOrderItems, orderDisplayItems, shopOrder, runnerId,
}: IComponent) => {
  const [qrCodeSource, setQrCodeSource] = useState<string>('');

  useEffect(() => {
    const qrElement = document.getElementById(`qr-${runnerId}`);
    if (!qrElement) return;
    // @ts-ignore
    setQrCodeSource(qrElement.toDataURL());
  }, []);

  return (
    <>
      <Page size="LETTER" style={style.page}>
        {shopOrder.type === 'neck' && (
          <WorkOrderTimeLog />
        )}
        <View style={style.headerColumn}>
          <View style={style.headerRow}>
            <Text style={style.workOrderHeader}>Work Order</Text>
            <Text style={style.salesOrderNumber}>{`S.O. #1${shopOrder.salesOrder.split('-')[0]}`}</Text>
          </View>
          <Text style={style.customer}>{shopOrder.customer.shortName}</Text>
          <View style={style.buffer} />
          <View style={style.columnHeaders}>
            <View style={style.modelHeaderRow}>
              <Text style={style.modelHeaderText}>Model</Text>
            </View>
            <View style={[style.quantityHeaderRow, { justifyContent: shopOrder.type === 'body' ? 'space-between' : 'flex-end' }]}>
              {shopOrder.type === 'body' && (
                <Text style={style.headerText}>Vol.</Text>
              )}
              <Text style={style.headerText}>Qty.</Text>
            </View>
          </View>
          <View style={style.columnHeaderUnderline} />
          <>
            {allOrderItems.map((i: IWorkOrderItem) => {
              const onThisWorkOrder = !!find(thisOrderItems, (o: IWorkOrderItem) => o.runnerId === i.runnerId && o.id === i.id && o.quantityAssigned === i.quantityAssigned);
              return (
                <>
                  <WorkOrderItem key={i.id} workOrderItem={i} nonOrderItem={!onThisWorkOrder} orderType={shopOrder.type} />
                </>
              );
            })}
            {/* {nonWorkOrderItems.length > 0 && ( */}
            {/* <View style={style.alsoOnThisOrder}> */}
            {/*   <View style={style.alsoOnThisOrderDivider} /> */}
            {/*   <Text style={style.alsoOnThisOrderText}>Also on this sales order</Text> */}
            {/*   <View style={style.alsoOnThisOrderDivider} /> */}
            {/* </View> */}
            {/* )} */}
            {/* {nonWorkOrderItems.map((i: IOrderItem) => ( */}
            {/*  <div style={{ opacity: 0.5 }}> */}
            {/*    <WorkOrderItem key={i.id} nonOrderItem workOrderItem={i} orderType={shopOrder.type} /> */}
            {/*  </div> */}
            {/* ))} */}
          </>
          <WorkOrderFooter items={allOrderItems} shopOrder={shopOrder} runnerId={runnerId} />
        </View>
      </Page>
    </>
  );
};

export default WorkOrderPage;
