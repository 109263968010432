import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';

interface IComponent {
  blurCallback?: (e: any) => void;
  changeCallback: (e: any) => void;
  disabled: boolean;
  id: string;
  label?: string;
  placeholder: string;
  value: any;
  noMargin: boolean;
  width: string;
}

const PartInput = styled(Input)`
  width: 100%; 
`;
const PartDetailInput = ({
  blurCallback, changeCallback, disabled, id, placeholder, value, label = undefined, noMargin = false, width = 'auto',
}: IComponent) => {
// @ts-ignore
  const onBlur = (e: any) => {
    if (blurCallback) blurCallback(e);
  };
  return (
    <ItemDetailsColumnWrapper key={`${id}-details-wrapper`} width={width} noMargin={noMargin}>
      {label && (<ItemDetailsLabel key={`${id}-details-label`}>{label}</ItemDetailsLabel>)}
      <PartInput
        key={id}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={changeCallback}
        onBlur={onBlur}
        width={width}
      />
    </ItemDetailsColumnWrapper>
  );
};
export default PartDetailInput;
