import styled, { keyframes } from 'styled-components';
import { Caption, H3 } from 'shared/typography';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { Button, Checkbox, Dropdown } from 'antd';
import { IInputFieldWrapper } from './types';

export const Logo = styled.button`
  width: 140px;
  height: auto;
  
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
  background-color: ${theme.palette.neutral.transparent};
  border: 0;
  cursor: pointer;
  
  & img {
    flex-shrink: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  
  @media ${theme.device.tablet} {
    width: 200px;
  }
`;
Logo.displayName = 'Logo';

export const FeatureTitle = styled(H3)`
  color: ${theme.palette.neutral.white};
  text-align: center;
  margin: 0;
  
  @media ${theme.device.tablet} {
    width: 100%;
    text-align: left;
  }
  @media ${theme.device.laptopM} {
    text-align: center;
    font-size: 36px;
  }
`;

// forms
export const InputLabel = styled(Caption)`
  width: 100%;
  
  color: ${theme.palette.neutral[700]};
  
  margin: 0;
`;

export const InputFieldWrapper = styled(FlexColumn)`
  height: ${(props: IInputFieldWrapper) => props.height || theme.spacing(12)};
  width: calc(100vw - ${theme.spacing(6)});
  
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  
  transition: all 0.3s ${theme.easing.transitionTiming};
  
  @media ${theme.device.laptop} {
    width: 50vw;
    margin-left: 0;
  }
`;

export const FieldValidationError = styled(Caption)`
  width: 100%;
  
  color: ${theme.palette.error.hue};
  text-align: left;
  
  margin-top: ${theme.spacing(0.5)};
`;

export const ConfigurationRow = styled(FlexRow)`
  width: 100%;
  width: calc(100vw - 48px);
  
  align-items: center;
  justify-content: ${(props: {marginLeft?: string, justify?: string}) => props.justify || 'flex-start'};
  
    margin: 16px auto;   
  // margin: 0px 6% 16px ${(props: { marginLeft?: string, justify?: string}) => props.marginLeft || '6%'};
  
  &:first-of-type{
    margin-bottom: 4px;
  }
  
  @media ${theme.device.laptop} {
  } 
  
  @media ${theme.device.laptopM} {
    width: 80%;
    //margin: 0px 6% 16px ${(props: { marginLeft?: string, justify?: string}) => props.marginLeft || '6%'};
  } 
  
  @media ${theme.device.desktop} {
    width: 70%;
  }
`;

export const DropdownOuterWrapper = styled(ConfigurationRow)`
  @media ${theme.device.laptopM} {
    width: 90%;
  }
`;

export const DropdownWrapper = styled(ConfigurationRow)`
  margin-left: 0;
  justify-content: space-between;
`;

export const CheckboxWrapper = styled(ConfigurationRow)`
  justify-content: space-between;
  margin: 16px 6% 0 0;
`;

export const ModificationWrapper = styled.div`
  width: 100%; 
  display: flex; 
  flex-direction: column; 
  gap: 8px; 
  margin-top: 16px;
`;

export const ModificationSelectorWrapper = styled(ConfigurationRow)`
  justify-content: space-between;
  margin: 2px 0 0 0;
`;

export const ModificationLabel = styled(Caption)`
  margin: 0;
  font-weight: 500;
`;

export const ConfigurationCol = styled(FlexColumn)`
  width: 100%;
  
  align-items: flex-start;
  justify-content: center;
  
  @media ${theme.device.laptop} {
    width: calc(100vw - 48px);
    margin: 16px auto;   
  } 
  
  @media ${theme.device.laptopM} {
    width: 90%;
    margin: 16px 6% 0 ${(props: { marginLeft?: string}) => props.marginLeft || '6%'};   
    //margin: 16px auto;
  } 
  
  @media print {
    width: 100vw;
    margin: 0;
    padding: 0;
    gap: 12px;
  }
`;

export const OptionCheckbox = styled(Checkbox)`
  & span {
    font-size: 16px;
    font-family: 'Red Hat Display', Arial, sans-serif;
  }
  
  margin-left: 0;
`;

export const ListPriceLabel = styled(Caption)`
  color: ${theme.palette.neutral.white};
  margin: 0;
  margin-top: ${theme.spacing(1.75)};
  margin-left: ${theme.spacing(4)};
`;

export const ItemPrice = styled(Caption)`
  font-size: 16px;
  width: ${theme.spacing(9.5)};
  height: 100%;
  text-align: right;
  margin: 0;
  margin-top: ${theme.spacing(0.5)};
  color: ${(props) => (props.selected ? (props.testMode ? theme.palette.error.hue : theme.palette.primary.hue) : (props.testMode ? theme.palette.neutral[600] : theme.palette.neutral[300]))};
  font-weight: 500;
`;

export const PricedItemPrice = styled(ItemPrice)`
  color: ${(props) => (props.isPriced ? theme.palette.primary.hue : theme.palette.neutral.black)};
`;

export const UnpricedItem = styled(ItemPrice)`
  font-weight: 400;
  color: ${(props) => (props.selected ? (props.testMode ? '#FF0000' : theme.palette.primary.hue) : theme.palette.neutral[800])};
`;

export const ItemDiscountPrice = styled(ItemPrice)`
  // width: ${theme.spacing(14)};
  font-weight: 500;
`;
export const UnpricedItemDiscountPrice = styled(UnpricedItem)`
  //width: ${theme.spacing(14)};
  font-weight: 400;
`;
export const CustomerButton = styled(Button)`
  margin-bottom: ${theme.spacing(0.5)};
`;

export const InactiveCustomerButton = styled(CustomerButton)`
  background-color: ${theme.palette.neutral[100]};
`;

export const CustomerPricedItemsIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${theme.palette.primary.hue};

  margin-left: 8px;
`;

export const PartDropdown = styled(Dropdown)`
  max-height: 300px;
  overflow-y: scroll;
`;

const rotation = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  `;

export const ProgressSpinner = styled.div`
    width: 48px;
    height: 48px;
    display: inline-block;
    
    border-radius: 50%;
    
    border: 10px solid ${theme.palette.neutral[100]};
    border-top: 10px solid ${theme.palette.primary.hue};
    
    animation: ${rotation} 1s linear infinite;
  `;

export const MiniProgressSpinner = styled(ProgressSpinner)`
  width: 24px;
  height: 24px;
  
  position: relative;
  
  border: 4px solid ${theme.palette.neutral[100]};
  border-top: 4px solid ${theme.palette.primary.hue};
  animation-duration: 0.75s;
`;

export const MiniDeleteProgressSpinner = styled(MiniProgressSpinner)`
  border-top-color: ${theme.palette.error.hue};
`;
