import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import { ICustomerRecord } from 'shared/types/dbRecords';
import { FlexRow } from 'shared/containers/FlexContainer';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { omit } from 'lodash';
import CustomerEmailButton from './CustomerEmailButton';
import CustomerWebsiteButton from './CustomerWebsiteButton';
import CustomerDeactivateButton from './CustomerDeactivateButton';

const ComponentWrapper = styled(FlexRow)`
    gap: 4px;
`;

interface IComponent {
  customer: ICustomerRecord;
}
const ActionButtons = ({ customer }: IComponent) => {
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);

  return (
    <ScopedComponent whitelist={[...adminUsers.emails, ...superAdminUsers.emails]}>
      <ComponentWrapper>
        <CustomerEmailButton email={customer.PrimaryEmailAddr.Address} />
        <CustomerWebsiteButton url={customer.website} />
        <CustomerDeactivateButton Id={customer.Id} />
      </ComponentWrapper>
    </ScopedComponent>
  );
};

export default ActionButtons;
