import React from 'react';
import { useRecoilValue } from 'recoil';
import { find, flattenDeep, includes } from 'lodash';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { IRunner, IShopOrder } from 'pages/Orders/types';
import { IRouterStep } from 'pages/ProductionSchedule/types';
import { routerStepsAtom } from 'shared/state/routingState';
import { openOrdersDataAtom, ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { IBomItem, IOrderItem } from 'shared/types/dbRecords';
import firebase from 'firebase';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from '../../../shared/state/inventoryState';
import { reduceObjectArrayValues } from '../../../shared/data';
import useFirebase from '../../../vendor/Firebase';

const ComponentButton = styled(Button)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const IDIntegrityButton = () => {
  const { firestore } = useFirebase();
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);
  const onClick = (e: any) => {
    firestore.collection(ordersDbCollectionString).get().then((snap) => {
      const invalidIDs: string[] = [];
      let processed = 0;
      snap.forEach((doc) => {
        const data = doc.data();
        if (data.id !== doc.id) invalidIDs.push(doc.id);
      });
      if (invalidIDs.length === 0) {
        Modal.info({
          title: 'Operation complete',
          content: 'No bad records found!',
        });
      } else {
        invalidIDs.forEach((id: string) => {
          firestore.collection(ordersDbCollectionString).doc(id).update({ id }).then(() => {
            processed += 1;
            if (processed === invalidIDs.length) {
              Modal.info({
                title: 'Operation complete',
                content: `Fixed ${processed} records!`,
              });
            }
          });
        });
      }
    });
  };

  return (
    <ComponentButton type="default" onClick={onClick}>Ensure ID Integrity</ComponentButton>
  );
};

export default IDIntegrityButton;
