import React, { useEffect, useState } from 'react';
import {
  find, findIndex, flatten, includes,
} from 'lodash';
import {
  Collapse, Divider, Switch,
} from 'antd';
import styled from 'styled-components';
import shortid from 'shortid';
import qs from 'qs';
import { useRecoilState, useRecoilValue } from 'recoil';
import firebase from 'firebase';
import useFirebase from 'vendor/Firebase';
import {
  COMPLETE_STEPS, reduceObjectArrayValues,
} from 'shared/data';
import {
  ORDERS_DB_COLLECTION,
  currentShopOrderAtom,
  workOrderUpdateLabelAtom,
  ORDER_ITEMS_DB_COLLECTION,
} from 'shared/state/orderState';
import { currentRunnerAtom, routerStepsAtom } from 'shared/state/routingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { runnerHistory, updateRunnerBom, updateRunners } from 'shared/scanner';
import { ChevronLeft } from '@styled-icons/fa-solid';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from 'shared/state/inventoryState';
import { redirect } from 'shared/util';
import { devLog } from 'shared/util/logging';
import { consumeInventoryParts } from 'shared/router/utils';

import {
  DetailsWrapper,
  RouteStepButton,
  RouteStepButtonWrapper,
  RunnerDetailHeaderRow,
  RunnerHistoryWrapper,
  RunnerPageTitle,
  RunnerDetailBackButton,
} from 'pages/ProductionSchedule/styledComponents';
import { IRouterStep } from 'pages/ProductionSchedule/types';
import { ItemCollapse } from 'pages/ItemPricing/styledComponents';
import RunnerHistory from 'pages/ProductionSchedule/Components/RunnerHistory';
import { IRunner, IRunnerHistory, IShopOrder } from './types';
import theme from '../../shared/theme';
import Loader from '../../shared/components/Utility/Loader';
import { MESSAGE_DB_PATH_ATOM, reorderPointNotificationUsersAtom } from '../../shared/state/messageState';
import { IOrderItem } from '../../shared/types/dbRecords';

const CheckinButtonRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;

const WorkingScreen = styled(FlexColumn)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
    & h6 {
      font-weight: bold;
      color: ${theme.palette.neutral.white};
    }
`;

const PageHeader = styled.div`
    position: sticky;
    top: 0;
    padding-top: 20px;
    padding-left: 100px;
    margin-bottom: 40px;
    background-color: white;
    width: 100vw;
    z-index: 10000;;
    @media ${theme.device.laptopL} {
        padding-left: 60px;
    }
`;

const StepButtonColumn = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    padding: 0 16px;
    @media ${theme.device.laptopL} {
        padding: 0 56px;
        width: calc(100% - 112px);
    }
`;
const PageTitle = styled(RunnerPageTitle)`
    margin-top: 0;
`;

const WorkOrderRecord = () => {
  const { firestore, database } = useFirebase();
  const routerSteps = useRecoilValue(routerStepsAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const orderDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const reorderItemNotificationUsers = useRecoilValue(reorderPointNotificationUsersAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const [shopOrder, setShopOrder] = useRecoilState(currentShopOrderAtom);
  const [runner, setRunner] = useRecoilState(currentRunnerAtom);
  const [workOrderUpdateLabel, setWorkOrderUpdateLabel] = useRecoilState(workOrderUpdateLabelAtom);
  const [historyPanel, setHistoryPanel] = useState(0);
  const [autoScan, setAutoScan] = useState(false);
  const [autoScanStation, setAutoScanStation] = useState('');
  const [subStations, setSubStations] = useState<IRouterStep[]>([]);
  const [, setDescription] = useState('');
  const [applyToAll, setApplyToAll] = useState(false);
  const [partType, setPartType] = useState<'body'|'neck'>('body');
  const [working, setWorking] = useState<boolean>(false);

  const search = qs.parse(window.location.search.replace('?', ''));
  const runnerId = search.runnerId;
  const shopOrderId = search.shopOrderId;
  const scanner = search.scanner === 'true';

  const workOrderNumber = findIndex(shopOrder.runners, (r: IRunner) => r.id === runnerId);

  const updateShopOrder = (order: IShopOrder, scannedAtStation = false) => {
    devLog('WorkOrderRecord', 77, `Updating shop order ${order.salesOrder}`);
    firestore.collection(orderItemsDbString).doc(order.id).get().then((doc) => {
      const orderItems = doc.data() as { orderId: string, orderItems: IOrderItem[] };
      const parts = flatten(order.runners.map((r: IRunner) => r.parts));
      firestore.collection(orderDbString).doc(order.id).set(order).then(() => {
        if (scanner || scannedAtStation) window.location.href = '/scanner';
        else {
          localStorage.setItem('routerViewType', partType);
          redirect();
        }
      });
    });
  };

  const onUpdateAll = (e: any) => {
    const stepId = e.currentTarget.value;
    const now = new Date();
    now.setHours(0, 0, 0);
    const isComplete = includes(COMPLETE_STEPS, e.currentTarget.value);

    const _updatedRunners = shopOrder.runners.map((r: IRunner) => {
      const { updatedRunner, consumedParts } = updateRunnerBom(r, stepId, routerSteps, partType);
      return [{
        ...updatedRunner,
        history: runnerHistory(r, e.currentTarget.value),
        step: e.currentTarget.value,
        completed: isComplete,
      }, consumedParts];
    });

    const updatedRunners = _updatedRunners.map((r: any) => r[0]);
    const _consumption = reduceObjectArrayValues(_updatedRunners.map((r: any) => r[1])) as any;

    consumeInventoryParts(
      _consumption,
      inventoryItems,
      inventoryDbString,
      firestore,
      database,
      messageDbString,
      reorderItemNotificationUsers,
      setWorkOrderUpdateLabel,
      new Date(),
      shopOrder,
    )
      .then(() => {
        const order = {
          ...shopOrder,
          completed: isComplete,
          runners: updatedRunners,
        };

        if (isComplete) order.completedDate = firebase.firestore.Timestamp.fromDate(now);
        updateShopOrder(order);
      });
  };

  const onClick = (e: any) => {
    setWorking(true);
    if (applyToAll) return onUpdateAll(e);
    const stepId = e.currentTarget.value;
    const { updatedRunner, consumedParts } = updateRunnerBom(runner, stepId, routerSteps, partType);
    const today = new Date();
    consumeInventoryParts(
      consumedParts,
      inventoryItems,
      inventoryDbString,
      firestore,
      database,
      messageDbString,
      reorderItemNotificationUsers,
      setWorkOrderUpdateLabel,
      today,
      shopOrder,
    )
      .then((() => {
        const r: any = {
          ...updatedRunner,
          history: runnerHistory(updatedRunner, stepId),
          stale: false,
          daysAtStep: 0,
          completed: includes(COMPLETE_STEPS, stepId),
        };

        if (includes(COMPLETE_STEPS, stepId)) {
          const shipDate = new Date();
          shipDate.setHours(0, 0, 0);
          r.shipDate = firebase.firestore.Timestamp.fromDate(shipDate);
        }
        const [, order] = updateRunners(shopOrder, r, stepId);
        updateShopOrder(order);
      }));
    // }
  };
  const onBack = (e: any) => {
    e.preventDefault();
    const [, order] = updateRunners(shopOrder, runner, runner.step);
    updateShopOrder(order);
  };

  const onPanelChange = (e: any) => {
    setHistoryPanel(e);
  };
  const onApplyToAll = (checked: boolean) => {
    setApplyToAll(checked);
  };

  const autoScanComplete = (_order: IShopOrder, _runner: IRunner, sId: string, stationName: string) => {
    setAutoScanStation(stationName);
    setAutoScan(true);
    setSubStations([]);
    const [, order] = updateRunners(_order, _runner, sId);
    setTimeout(() => {
      updateShopOrder(order, true);
    }, 500);
  };

  const autoScanCallback = (sId: string, stationName: string) => (e: any) => {
    autoScanComplete(shopOrder, runner, sId, stationName);
  };

  useEffect(() => {
    firestore.collection(orderDbString).doc(shopOrderId).get().then((doc) => {
      const docData = doc.data() as IShopOrder;
      setShopOrder(docData);
      setPartType(docData.type);
      setDescription(docData.description || '');

      const currentRunner = find(docData.runners, (r: IRunner) => r.id === runnerId);
      setRunner(currentRunner as IRunner);
      devLog('WorkOrderRecord', 221, JSON.stringify(currentRunner));
    });
  }, []);

  return (
    <>
      {working && (
      <WorkingScreen>
        <Loader label={workOrderUpdateLabel} radius={48} fontSize="20px" />
      </WorkingScreen>
      )}
      <DetailsWrapper>
        <RouteStepButtonWrapper>
          {!!shopOrder.runners.length
          && (
          <>
            <PageHeader>
              <PageTitle>
                {shopOrder.customer.DisplayName}
                <p style={{ fontSize: 20, margin: 0 }}>{`Order #${shopOrder.salesOrder}`}</p>
                <p style={{ fontSize: 20, margin: 0 }}>{`Work Order ${workOrderNumber + 1} of ${shopOrder.runners.length}`}</p>
                <p style={{ fontSize: 20, margin: 0, fontWeight: 'normal' }}>{`${shopOrder.description || ''}`}</p>
              </PageTitle>
            </PageHeader>
            {/* <ItemCollapse activeKey={historyPanel} onChange={onPanelChange} accordion> */}
            {/*  <Collapse.Panel key={1} header="Work order history"> */}
            {/*    <RunnerHistoryWrapper> */}
            {/*      <RunnerHistory runner={runner} /> */}
            {/*    </RunnerHistoryWrapper> */}
            {/*  </Collapse.Panel> */}
            {/* </ItemCollapse> */}
            <StepButtonColumn>
              {!!routerSteps.length
              && (
              <FlexRow justify="flex-start" align="flex-start" style={{ gap: 24 }}>
                <Switch checked={applyToAll} onClick={onApplyToAll} />
                <p>Apply to all carts on order</p>
              </FlexRow>
              )}

              <CheckinButtonRow>
                {routerSteps.filter((step: IRouterStep) => step.type === partType
                && !step.name.match(/hold/i)
                && !step.subName?.match(/hand/i)
                && !step.name.match(/complete/i))
                  .map((step: IRouterStep) => (
                    <RouteStepButton
                      key={shortid.generate()}
                      currentStep={runner.step === step.id}
                      onClick={onClick}
                      value={step.id}
                    >
                      <p>{`${step.name}${step.subName ? ` - ${step.subName}` : ''}`}</p>
                    </RouteStepButton>
                  ))}
              </CheckinButtonRow>
            </StepButtonColumn>
          </>
          )}
        </RouteStepButtonWrapper>
      </DetailsWrapper>
    </>
  );
};

export default WorkOrderRecord;
