import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { includes, some } from 'lodash';
import PartListFilter from 'shared/components/Input/PartListFilter';
import { vendorSearchQueryAtom, currentVendorPageAtom } from 'shared/state/vendorState';

const VendorFilter = () => {
  const [vendorSearchQuery, setVendorSearchQuery] = useRecoilState(vendorSearchQueryAtom);
  const setCurrentPage = useSetRecoilState(currentVendorPageAtom);

  const onChangeSearch = (e: any) => {
    if (some('!@#$%^&*()+=`~[]{};:><?\\'.split(''), (c: string) => includes(e.target.value.split(''), c))) return;

    setCurrentPage(1);
    setVendorSearchQuery(e.target.value);
    sessionStorage.setItem('vendor.query.string', e.target.value);
  };

  return (
    <PartListFilter
      id="vendor-filter"
      prompt="Filter vendors using comma-separated terms, e.g. STEMAC, RAW"
      value={vendorSearchQuery}
      callback={onChangeSearch}
      storageKey="vendor.query.string"
      width="65%"
    />
  );
};

export default VendorFilter;
