import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';

const ModalContainer = styled(Modal)`
  width: auto !important;
  text-align: center;
  border-radius: 12px;
  
  & .ant-modal-header {
    border-bottom: none;
  }
  
  & .ant-modal-body { 
    display: ${(props: any) => (props.content ? 'block' : 'none')} !important;
  }
  
  & .ant-modal-footer {
    display: flex;
    justify-content: center;
    gap: 8px;
    border-top: none;
  }
  
  & .ant-modal-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 28px;
  }
`;
const ModalButton = styled(Button)`
  border-radius: 8px;
  flex-grow: 2;
  font-size: 16px;
  line-height: 16px;
  width: 50%;
`;

const ContentWrapper = styled.div`
    max-width: 380px;
    & p {
      font-size: 18px;
    }
`;
/**
 * Modal component that can be used to make a simple binary choice.
 */
interface IComponent {
  modalOpen: boolean;
  buttons: { label: string, onClick: (e: any) => void, key: string, type: string}[];
  onCancel: (e: any) => void;
  title: string;
  content?: string;
}
const SimpleModal = ({
  modalOpen,
  buttons,
  onCancel,
  title,
  content = '',
}: IComponent) => (
  <>
    <ModalContainer
      open={modalOpen}
      centered
      closeIcon={<span />}
        // @ts-ignore -- using content's presence to either show or hide the body.
      content={!!content}
      title={title}
      onCancel={onCancel}
      footer={buttons.map((b) => (
        <ModalButton key={b.key} type={b.type} onClick={b.onClick}>{b.label}</ModalButton>
      ))}
    >
      <ContentWrapper>
        <p>{content}</p>
      </ContentWrapper>
    </ModalContainer>
  </>
);

export default SimpleModal;
