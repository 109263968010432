import React from 'react';
import { Collapse } from 'antd';
import { ConfigurationCol } from 'shared/pageElements/styledComponents';
import ReactHtmlParser from 'react-html-parser';
import { ItemCollapse, PricingHeader, PricingPageDescription } from './styledComponents';
import WoodTable from './Components/WoodTable';
import OptionsTable from './Components/OptionsTable';
import ArchetypeTable from './Components/ArchetypeTable';

const { Panel } = Collapse;

const onPaneChange = (pane: string) => {
  localStorage.setItem('bodyPricingPane', pane);
};

export default () => (
  <>
    <ConfigurationCol>
      <PricingHeader>View & Edit Body Pricing</PricingHeader>
      <PricingPageDescription>
        {ReactHtmlParser(`Use this page to view and edit pricing for all body-related 
          items.<br/><br/><b>Note:</b> prices and labels are <i>live</i>, so any edits made will be applied to all existing 
          and future priced parts.`)}
      </PricingPageDescription>
      <ItemCollapse
        accordion
        defaultActiveKey={localStorage.getItem('bodyPricingPane') || ''}
        // @ts-ignore
        onChange={onPaneChange}
      >
        <Panel key="1" header="Body Model BOL">
          <ArchetypeTable partType="body" />
        </Panel>
        <Panel key="bodyWood" header="Body Wood">
          <WoodTable woodCategory="bodyWood" />
        </Panel>
        <Panel key="body-modifications" header="Body Modifications">
          <OptionsTable listType="unitOption" optionType="bodyBlankModifications" />
        </Panel>
        <Panel key="topWood" header="Top Wood">
          <WoodTable woodCategory="topWood" />
        </Panel>
        <Panel key="5" header="Weight Reduction Options">
          <OptionsTable listType="unitOption" optionType="weightReductionOptions" />
        </Panel>
        <Panel key="6" header="Construction (CNC) Options">
          <OptionsTable listType="unitOption" optionType="constructionOptions" />
        </Panel>
        <Panel key="7" header="Accessory Options">
          <OptionsTable listType="unitOption" optionType="accessoryOptions" />
        </Panel>
        <Panel key="8" header="Finishing Options">
          <OptionsTable listType="unitOption" optionType="finishingOptions" />
        </Panel>
        {/* <Panel key="9" header="Handling Options"> */}
        {/*  <OptionsTable listType="unitOption" optionType="handlingOptions" /> */}
        {/* </Panel> */}
        <Panel key="11" header="Customer-Specific Options">
          <OptionsTable listType="unitOption" optionType="customerSpecificOptions" />
        </Panel>
        <Panel key="10" header="Labor Options (hourly rates)">
          <OptionsTable listType="rateOption" optionType="laborOptions" />
        </Panel>
      </ItemCollapse>
      <div style={{ height: 100 }} />
    </ConfigurationCol>
  </>
);
