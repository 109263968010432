import React from 'react';
import {
  Button, Modal, Popconfirm, Tooltip,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { useTestDataAtom } from 'shared/state/pricingState';
import { PART_VIEWER_COLLECTION } from 'shared/state/partViewState';
import useFirebase from 'vendor/Firebase';
import QBOAuth from 'shared/data/QBO/auth';
import QBOItem from 'shared/data/QBO/item';
import { IQBOItem } from '../../../../../../shared/types/qbo';

interface IComponent {
  partNumber: string;
}
const DeleteItemButton = ({ partNumber }: IComponent) => {
  const { database, firestore } = useFirebase();
  const partCollectionDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const isDev = useRecoilValue(useTestDataAtom);

  const onDelete = async () => {
    try {
      await QBOItem.update({ Active: false, Name: partNumber } as IQBOItem);
    } catch (e) {
      console.log(e);
    }
    await firestore.collection(partCollectionDbString).doc(partNumber).delete();
    if (!isDev) {
      await firestore.collection(`${partCollectionDbString}-test`).doc(partNumber).delete();
    }
    window.location.reload();
  };
  const onConfirmDelete = (e: any) => {
    e.stopPropagation();
    Modal.confirm({
      title: 'Delete item?',
      content: 'Are you sure you want to delete this item? This action cannot be undone.',
      onOk: onDelete,
      okText: 'Delete',
    });
  };

  return (
    <Tooltip title="Delete">
      {/* <Popconfirm */}
      {/*  title="Are you sure you want to delete this item?" */}
      {/*  onConfirm={onDelete} */}
      {/*  okText="Yes" */}
      {/*  cancelText="No" */}
      {/*  placement="left" */}
      {/*  // @ts-ignore */}
      {/*  icon={<ConfirmDeleteIcon />} */}
      {/* > */}
      <Button
        shape="circle"
          // @ts-ignore
        icon={<DeleteOutlined />}
        onClick={onConfirmDelete}
      />
      {/* </Popconfirm> */}
    </Tooltip>
  );
};

export default DeleteItemButton;
