import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  flatten, groupBy,
  noop, sortBy,
} from 'lodash';
import { ArrowDropDown } from '@styled-icons/material';
import { CSVDownload } from 'react-csv';
import {
  IShipment, IOrderItem, IShipmentItem, IBomItem,
} from 'shared/types/dbRecords';
import { Menu } from 'antd';
import { DownloadButton } from 'shared/styledComponents/inputs';
import { formatPercent, formatPrice } from 'shared/data';

const DropdownIcon = styled(ArrowDropDown)`
    height: 28px;
    position: relative;
    top: -2px;
    left: -14px;
`;
interface IComponent {
  shipments: IShipment[];
}
export default ({ shipments }: IComponent) => {
  const [_shipments, setShipments] = useState<IShipment[]>(shipments);
  // @ts-ignore
  const [csvData, setCsvData] = useState<any>([]);
  const [fileName, setFilename] = useState<string>('');

  const resetDownload = () => {
    setCsvData([]);
    setFilename('');
  };

  const exportShipmentReport = () => {
    const today = new Date();
    const csvHeaders = ['Invoice', 'SalesOrder', 'ShipDate', 'Customer', 'SalesOrder', 'Description', 'ShippedVia', 'TrackingNo', 'ItemQty', 'Value', 'BilledShipping'];
    const rowData = _shipments.map((shipment: IShipment) => {
      const shipmentValue = shipment.shippedItems.map((i) => i.quantityShipped * i.unitPrice).reduce((a, b) => a + b, 0);
      const shippedWith = !shipment.trackingNumber ? 'Customer Arranged' : (shipment.trackingNumber.match(/^1Z/) ? 'UPS' : 'FedEx');
      return (
        [
          shipment.shipmentNumber,
          shipment.salesOrder,
          shipment.shipDate.toDate().toLocaleDateString(),
          shipment.customer.DisplayName,
          shipment.salesOrder,
          shipment.description,
          shippedWith,
          shipment.trackingNumber,
          shipment.shippedItems.map((i: IOrderItem) => i.quantityShipped).reduce((a, b) => a + b, 0),
          formatPrice(shipmentValue),
          formatPrice(shipment.shippingCost),
        ]);
    });

    setFilename(`HELM_Shipment_${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate()}.csv`);
    setCsvData([csvHeaders, ...rowData]);
    setTimeout(resetDownload, 1000);
  };

  const exportItemDetailReport = () => {
    const ItemExportColumns = [
      'Shipment',
      'SalesOrder',
      'Date',
      'Sku',
      'Description',
      'Ordered',
      'Canceled',
      'Backordered',
      'Shipped',
      'Value',
      'Unit BOM',
      'Unit BOM %',
      'Notes',
    ];
    const shippedItems = flatten(_shipments.map((i: IShipment) => {
      const items = i.shippedItems;
      return items.map((p: IShipmentItem) => {
        const notes = p.notes;
        return [
          i.shipmentNumber,
          i.salesOrder,
          i.shipDate.toDate().toLocaleDateString(),
          p.Sku,
          p.Description,
          p.quantityOrdered,
          p.quantityCanceled,
          p.quantityBackordered || 0,
          p.quantityShipped,
          formatPrice(p.quantityShipped * p.unitPrice),
          formatPrice(p.bom.map((b: IBomItem) => b.unitCost * b.quantity).reduce((a, b) => a + b, 0)),
          formatPercent(p.bom.map((b: IBomItem) => b.unitCost * b.quantity).reduce((a, b) => a + b, 0) / p.unitPrice, 1),
          (p.notes || '').split('\n').join('; '),
        ];
      });
    }));
    const downloadData = [
      ItemExportColumns,
      ...sortBy(shippedItems, (s) => s[0]),
    ];
    const today = new Date();
    setCsvData(downloadData);
    setFilename(`HELM_Shipment_Detail_${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate()}.csv`);
    setTimeout(resetDownload, 1000);
  };

  const exportItemSummaryReport = () => {
    const ItemExportColumns = [
      'Sku',
      'Description',
      'Ordered',
      'Unit $',
      'Shipped',
      'Shipped $',
      '% Total',
      'Canceled',
      'Backordered',
      'Unit BOM',
      'Unit BOM %',
    ];
    interface IItem {
      Sku: string;
      Description: string;
      quantityOrdered: number;
      unitPrice: number;
      quantityCanceled: number;
      quantityBackordered: number;
      quantityShipped: number;
      unitBomCost: string;
      unitBomPercent: string;
    }
    const shippedItems = flatten(_shipments.map((i: IShipment): IItem[] => {
      const items = i.shippedItems;
      return items.map((p: IShipmentItem) => ({
        Sku: p.Sku,
        Description: p.Description,
        quantityOrdered: p.quantityOrdered,
        unitPrice: p.unitPrice,
        quantityCanceled: p.quantityCanceled,
        quantityBackordered: p.quantityBackordered || 0,
        quantityShipped: p.quantityShipped,
        unitBomCost: formatPrice(p.bom.map((b: IBomItem) => b.unitCost * b.quantity).reduce((a, b) => a + b, 0)),
        unitBomPercent: formatPercent(p.bom.map((b: IBomItem) => b.unitCost * b.quantity).reduce((a, b) => a + b, 0) / p.unitPrice, 1),
      }));
    }));
    const itemsBySku = Object.values(groupBy(shippedItems, (i: IItem) => i.Sku)) as IItem[][];
    const totalShipValue = flatten(itemsBySku)
      .map((i: IItem) => i.unitPrice * i.quantityShipped)
      .reduce((a, b) => a + b, 0);
    const reducedItems = itemsBySku.map((group: IItem[]) => {
      const itemShippedTotal = group.map((i: IItem) => i.unitPrice * i.quantityShipped).reduce((a, b) => a + b, 0);
      return [
        group[0].Sku,
        group[0].Description,
        group.map((i: IItem) => i.quantityOrdered).reduce((a, b) => a + b, 0),
        // average unit price in case we are reporting on items shipped on either side of a price increase
        formatPrice(group.map((i: IItem) => i.unitPrice).reduce((a, b) => a + b, 0) / group.length),
        group.map((i: IItem) => i.quantityShipped).reduce((a, b) => a + b, 0),
        formatPrice(itemShippedTotal),
        formatPercent(itemShippedTotal / totalShipValue),
        group.map((i: IItem) => i.quantityCanceled).reduce((a, b) => a + b, 0),
        group.map((i: IItem) => (i.quantityBackordered || 0)).reduce((a, b) => a + b, 0),
        group[0].unitBomCost,
        group[0].unitBomPercent,
      ];
    });

    setCsvData([
      ItemExportColumns,
      ...sortBy(reducedItems, (i) => i[0]),
    ]);
    const today = new Date();
    setFilename(`HELM_Shipment_Detail_${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate()}.csv`);
    setTimeout(resetDownload, 1000);
  };

  const onExportContext = (context: { key: 'item-summary'|'item-detail'|'shipments' }) => {
    if (context.key === 'shipments') {
      exportShipmentReport();
    } else if (context.key === 'item-detail') {
      exportItemDetailReport();
    } else {
      exportItemSummaryReport();
    }
  };

  const exportMenu = (
    <Menu onClick={onExportContext}>
      <Menu.Item key="shipments">
        Shipments
      </Menu.Item>
      <Menu.Item key="item-summary">
        Shipment Summary
      </Menu.Item>
      <Menu.Item key="item-detail">
        Shipment Detail
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setShipments(shipments);
  }, [shipments]);

  return (
    <>
      {(!!csvData.length && !!fileName.length) && (
        <CSVDownload data={csvData} filename={fileName} />
      )}
      <DownloadButton
        trigger={['click']}
        icon={<DropdownIcon />}
        onClick={noop}
        overlay={exportMenu}
        type="primary"
        placement="bottom"
      >
        Export
      </DownloadButton>
    </>
  );
};
