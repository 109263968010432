import React from 'react';
import styled from 'styled-components';
import theme from 'shared/theme';
import { Tooltip } from 'antd';
import { FlexColumn, FlexRow } from '../../../shared/containers/FlexContainer/styledComponents';
import { formatPercent, formatPrice } from '../../../shared/data';
import { numberWithCommas } from '../../../shared/text';
import { rgba } from '../../../shared/palette/utils';

const ComponentWrapper = styled(FlexColumn)`
    height: 32px;
    gap: 0px;
`;
const Headercopy = styled.p`
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 0;
`;
const PerDayWrapper = styled(FlexRow)`
    height: 24px;
    width: auto;
    border-radius: 8px;
    overflow: hidden;
    margin-left: 6px;
    padding: 0px 2px;
    & * {
      font-size: 16px;
    }
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;`;

const FinishWrapper = styled(FlexRow)`
    width: auto;
    height: 24px;
    padding: 0px 8px;
    background-color: ${rgba(theme.palette.neutral[800], 0.8)};
    font-weight: bold;
    & p {
        font-weight: bold;
        margin: 0;
        @keyframes blink {
            0% {
                opacity: 1;
                scale: 1;
            }
            30% {
                opacity: 0.7;
                scale: 0.98;
            }
            100% {
                opacity: 1;
                scale: 1;
            }
        }
        animation: ${(props: { weighted: boolean }) => (props.weighted ? 'blink 5s linear infinite' : 'unset')};
    }
`;

const WeekWrapper = styled(FinishWrapper)`
    background-color: ${(props: { color: string }) => props.color};
`;

const Divider = styled.div`
    height: 100%;
    width: 1px;
    background-color: ${theme.palette.neutral.white};
`;

const CNCWrapper = styled(FinishWrapper)`
    background-color: rgb(198, 29, 117);
`;
interface IUnitsPerDayIndicator {
  partType: 'bodies'|'necks';
  weekTotal: number;
  finishPerDay: number;
  cncPerDay: number;
  color: string;
  weighted: boolean;
}

export const UnitsPerDayIndicator = ({
  partType, weekTotal, finishPerDay, cncPerDay, color, weighted = false,
}: IUnitsPerDayIndicator) => (
  <PerDayWrapper>
    <Tooltip placement="top" title={`${weekTotal} ${partType} scheduled for this week`}>
      <WeekWrapper color={color}>{weekTotal}</WeekWrapper>
    </Tooltip>
    <Divider />
    <Tooltip placement="top" title={`${finishPerDay}${weighted ? ' (weighted)' : ''} ${partType} per day scheduled for finish in this week`}>
      <FinishWrapper weighted={weighted}><p>{finishPerDay}</p></FinishWrapper>
    </Tooltip>
    <Divider />
    <Tooltip placement="top" title={`${cncPerDay} ${partType} per day scheduled for CNC or NFS this week`}>
      <CNCWrapper>{cncPerDay}</CNCWrapper>
    </Tooltip>
  </PerDayWrapper>
);

interface IDollarTotalIndicator {
  weekTotal: number;
  perDay: number;
  color: string;
  invoiced?: boolean;
}
export const DollarTotalIndicator = ({
  weekTotal, perDay, color, invoiced = false,
}: IDollarTotalIndicator) => (
  <PerDayWrapper>
    <Tooltip placement="top" title={`$${numberWithCommas(weekTotal)} ${invoiced ? 'invoiced' : 'scheduled'} for this week`}>
      <WeekWrapper color={color}>{`$${numberWithCommas(weekTotal)}`}</WeekWrapper>
    </Tooltip>
    <Divider />
    <Tooltip placement="top" title={`$${numberWithCommas(perDay)} ${invoiced ? 'invoiced' : 'scheduled'} per day this week`}>
      <FinishWrapper>{`$${numberWithCommas(perDay)}`}</FinishWrapper>
    </Tooltip>
  </PerDayWrapper>
);
interface IMaterialCostTotalIndicator {
  weekTotalValue: number;
  materialPercentage: number;
  color: string;
}
export const MaterailCostTotalIndicator = ({ weekTotalValue, materialPercentage, color }: IMaterialCostTotalIndicator) => (
  <PerDayWrapper>
    <Tooltip placement="top" title={`$${numberWithCommas(weekTotalValue)} total material value in this week`}>
      <WeekWrapper color={color}>{`$${numberWithCommas(weekTotalValue)}`}</WeekWrapper>
    </Tooltip>
    <Divider />
    <Tooltip placement="top" title={`Material cost is ${formatPercent(materialPercentage, 0)} of sales this week`}>
      <FinishWrapper>{`${formatPercent(materialPercentage, 0)}`}</FinishWrapper>
    </Tooltip>
  </PerDayWrapper>
);
interface ICNCTotalIndicator {
  weekTotalHours: number;
  cncPercentage: number;
  color: string;
}
export const CNCHoursTotalIndicator = ({ weekTotalHours, cncPercentage, color }: ICNCTotalIndicator) => (
  <PerDayWrapper>
    <Tooltip placement="top" title={`${weekTotalHours} total CNC hours needed for this week`}>
      <WeekWrapper color={color}>{weekTotalHours}</WeekWrapper>
    </Tooltip>
    <Divider />
    <Tooltip placement="top" title={`${formatPercent(cncPercentage, 0)} CNC load for this week`}>
      <FinishWrapper>{`${formatPercent(cncPercentage, 0)}`}</FinishWrapper>
    </Tooltip>
  </PerDayWrapper>
);
