import React from 'react';
import { includes, some } from 'lodash';
import styled from 'styled-components';
import { Input } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Search } from '@styled-icons/material-twotone';
import { priceSearchQueryAtom } from 'shared/state/pricingState';
import theme from 'shared/theme';
import { FlexRow } from 'shared/containers/FlexContainer';

import { currentInventoryPageAtom } from '../../state/inventoryState';

const ComponentWrapper = styled(FlexRow)`
    width: ${(props: any) => (props.width ? props.width : 'calc(64% - 16px)')};
`;

const SearchIcon = styled(Search)`
  width: 32px;
  color: ${theme.palette.primary.hue};
`;

const PriceSearchInput = styled(Input)`
  & * {
    border-radius: 6px;
  }
  & input {
    width: 100%;
    height: 48px;
    flex-grow: 2;
    font-size: 16px;
    @media ${theme.device.laptopM} {
      width: calc(100% - 40px);
    }
  }
`;

interface IComponent {
  id: string;
  prompt: string;
  value: any;
  callback: (e: any) => void;
  blurCallback?: (e: any) => void;
  width?: string;
}
const PartListFilter = ({
  id, prompt, value, callback, blurCallback = undefined, width = undefined,
}: IComponent) => (
  <ComponentWrapper width={width}>
    <PriceSearchInput
      id={id}
      addonBefore={<SearchIcon />}
      key="part-search-query-field"
      value={value}
      placeholder={prompt}
      onChange={callback}
      onBlur={blurCallback}
      onPressEnter={blurCallback}
    />
  </ComponentWrapper>
);

export default PartListFilter;
