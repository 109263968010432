import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import {
  Button, Dropdown, Radio, Select,
} from 'antd';
import { ArrowCircleLeft, ArrowCircleRight } from '@styled-icons/material-twotone';
import { CSVLink } from 'react-csv';
import theme from '../theme';

export const ItemDetailsColumnWrapper = styled(FlexColumn)`
  width: ${(props: any) => props.width};
  align-items: flex-start;
  justify-content: flex-start;
  margin: ${(props: any) => (props.noMargin ? '0' : '0 0 16px 0')};
`;
export const ItemDetailsLabel = styled.h3`
  font-weight: 500;
  margin: 0 0 4px 0;
`;

export const ItemDetail = styled.h2`
  font-weight: 500;
`;
export const ItemDetailsDescription = styled.p`
  font-size: 14px;
  margin: 0;
`;
export const ItemDetailBackButton = styled(Button)`
  background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : '#2f9bff')} !important;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.primary.D100)} !important;
  }
`;
export const ViewTypeRadioButton = styled(Radio.Button)`
  text-align: center;
  padding: 0 12px;

    // @media ${theme.device.tablet} {
  //  min-width: 110px;
  //}

    // @media ${theme.device.laptopL} {
  //  min-width: 0;
  //}

  &:first-of-type {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  &:last-of-type {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;
export const RadioGroupWrapper = styled(FlexRow)`
    // @media ${theme.device.tablet} {
  //  flex-direction: column;
  //  align-items: flex-start;
  //  justify-content: center;
  //}

  @media ${theme.device.laptopL} {
    flex-direction: row;
    height: inherit;
    align-items: flex-end;
  }
`;

export const ViewTypeSelectLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #AAA;
  margin: 0;
  position: relative;
  top: 10px;
  left: -10px;
  z-index: 10;
  padding: 0 4px;
  background-color: ${theme.palette.neutral.white};

  @media ${theme.device.laptopL} {
    display: none;
  }
`;
export const ViewTypeSelect = styled(Select)`
  width: 144px;
  border-radius: 8px;

  & .ant-select-selector {
    height: 32px;
    line-height: 32px;
  }

  margin-left: ${(props: any) => (props.marginLeft ?? '24px')};
  @media ${theme.device.laptopL} {
    display: none;
  }
`;
export const ViewTypeSelectOption = styled(Select.Option)``;
export const ViewTypeRadioGroup = styled(Radio.Group)`
  display: none;
  margin-left: ${(props: { marginLeft: string }) => props.marginLeft || '24px'};
  @media ${theme.device.laptopL} {
    display: unset;
  }
`;
export const NoLeftMarginViewTypeRadioGroup = styled(ViewTypeRadioGroup)`
  margin-left: 0;
`;
export const HoldRadioGroup = styled(ViewTypeRadioGroup)`
  &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: ${(props: any) => (props.hold ? theme.palette.warning.hue : theme.palette.primary.hue)};
    border-color: ${(props: any) => (props.hold ? theme.palette.warning.hue : theme.palette.primary.hue)};
  }
`;

export const SearchSelect = styled(Select)`
  min-width: 320px;
  height: inherit;
  
  & .ant-select-selector {
    height: ${(props: any) => (props.transparent ? '100% !important' : '46px !important')};
    border-radius: ${(props: any) => (props.transparent ? '0 !important' : '8px !important')};
    background-color: ${(props: any) => (props.testMode ? '#FCEBEC' : props.transparent ? 'rgba(255, 255, 255, 0.1)' : theme.palette.neutral.white)} !important;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 6px 16px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding-left: 0 !important;
    padding-right: 16px !important;
    border: ${(props: any) => (props.transparent ? 'none !important' : `1px solid ${theme.palette.neutral[100]} !important`)};
  }
  & .ant-select-selection-placeholder {
    color: ${(props: any) => (props.transparent ? theme.palette.neutral.white : theme.palette.neutral.black)};
    font-size: 24px;
    font-weight: bold;
    line-height: ${(props: any) => (props.transparent ? '56px !important' : '44px !important')};
    margin-left: 12px;
  }
  & .ant-select-selection-item {
    color: ${(props: any) => (props.transparent ? theme.palette.neutral.white : theme.palette.neutral.black)};
    font-size: 24px;
    font-weight: bold;
    line-height: ${(props: any) => (props.transparent ? '56px !important' : '44px !important')};
    margin-left: 12px;
  }
  & .ant-select-selection-search-input {
    position: relative;
    top:  ${(props: any) => (props.transparent ? '14px !important' : '4px !important')};
    font-size:  ${(props: any) => (props.transparent ? '24px !important' : '20px !important')};
    font-weight:  ${(props: any) => (props.transparent ? 'bold !important' : 'normal !important')};
    line-height: ${(props: any) => (props.transparent ? '56px !important' : '44px !important')};
    color: ${(props: any) => (props.transparent ? `${theme.palette.neutral.white} !important` : `${theme.palette.neutral.black} !important`)};
  }
  & .ant-select-arrow {
    color: ${(props: any) => (props.transparent ? theme.palette.neutral.white : theme.palette.neutral.black)};
  }
`;

export const DatePickerNextIcon = styled(ArrowCircleRight)`
    position: relative;
    width: 32px;
    color: ${theme.palette.primary.hue};
    transition: transform 0.3s ease;
    &:hover {
        transform: scale(1.125);
    }
`;
export const DatePickerPrevIcon = styled(ArrowCircleLeft)`
    position: relative;
    width: 32px;
    color: ${theme.palette.primary.hue};
    transition: transform 0.3s ease;
    &:hover {
        transform: scale(1.125);
    }
`;

export const DownloadButton = styled(Dropdown.Button)`
    &.ant-btn-primary {
        border: 1px solid ${theme.palette.success.hue} !important;
        background-color: ${theme.palette.success.hue} !important; 
    }
    & button {
        height: ${theme.spacing(4)};
        padding: ${theme.spacing(0.5)} ${theme.spacing(2)};

        border: 1px solid ${theme.palette.success.hue};
        border-radius: ${theme.spacing(1)};
        background-color: ${theme.palette.success.hue};
        color: ${theme.palette.neutral.white};
        font-size: 14px;

        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);


        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
            color: ${theme.palette.neutral.white};
            background-color: ${theme.palette.success[900]};
            border: 1px solid ${theme.palette.success[900]};
        }
        &:focus {
            color: ${theme.palette.neutral.white};
            background-color: ${theme.palette.success[900]};
            border: 1px solid ${theme.palette.success[900]};
        }
    } 
`;

export const CSVDownloadButton = styled(CSVLink)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 2;
    height: ${theme.spacing(4)};
    padding: 0 16px;
    
    border: 1px solid ${theme.palette.success.hue};
    border-radius: ${theme.spacing(1)};
    background-color: ${theme.palette.success.hue};
    color: ${theme.palette.neutral.white};
    font-size: 14px;
    
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  
    
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover {
        color: ${theme.palette.neutral.white};
        background-color: ${theme.palette.success[900]};
        border: 1px solid ${theme.palette.success[900]};
    }
  
`;
