import React, { useState } from 'react';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Upload as UploadIcon } from '@styled-icons/fa-solid';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import {
  every, first, flatten, groupBy, omit, uniq,
} from 'lodash';
import { CSVDownload } from 'react-csv';
import { FlexColumn } from '../../../shared/containers/FlexContainer';
import bodyParser from '../../../shared/partParser/bodyParser';
import neckParser from '../../../shared/partParser/neckParser';
import { checkGeometricDifferences, isSamePart, sanitizePartDescription } from '../../../shared/partParser/util';

const UploadButton = styled(Button)`
  //margin: 48px;
  min-width: 240px;
  border-radius: 8px;
`;

const NewPartOrderUploader = () => {
  const { firestore } = useFirebase();
  const [csvData, setCsvData] = useState([]);
  const onUpload = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const data = e.target.result
        .split('\r')
        .slice(1)
        .map((d: string) => d.trim().split(','));
        // .map((d: string[]) => d.filter((s: string) => s.match(/^[G|B][B|N]/)));

      const customer = data[0][0].split('_')[0];
      const partCandidates = data.map((d: string[]) => ({ sku: d[0], description: d[1] })).filter((d) => d && d.description);
      const bodyCandidates = partCandidates.filter((c: { sku: string, description: string }) => c.description.match(/^[G|B]B/));
      const neckCandidates = partCandidates.filter((c: { sku: string, description: string }) => c.description.match(/^[G|B]N/));

      const parsedBodies = bodyParser(bodyCandidates.map((c: { sku: string, description: string }) => [true, '', sanitizePartDescription(c.description), '', '', '']));
      const parsedNecks = neckParser(neckCandidates.map((c: { sku: string, description: string }) => [true, '', sanitizePartDescription(c.description), '', '', '']));

      const customerPartDocs = await firestore.collection('part-viewer-data').where('customer', '==', customer).get();
      const customerParts = customerPartDocs.docs.map((d) => d.data());

      const newParts = [...parsedBodies, ...parsedNecks].map((parsedItem: any) => {
        // FIRST, check if this part has a twin, e.g., it exists already
        const twin = first(customerParts
          .filter((p) => !!p.Description)
          .map((p) => (isSamePart(p, parsedItem) ? p : null))
          .filter((m) => m));

        // @ts-ignore -- twin is checked for definition before calling
        if (twin !== undefined) return { requested: parsedItem.Description, part: omit(twin, 'parent'), result: 'exists' };

        // NEXT, if no twin is found, check for a parent, e.g. a part that is geometrically equivalent
        const parentCandidates = customerParts
          .map((p) => {
            const geoDiff = checkGeometricDifferences(p, parsedItem);
            if (every(geoDiff)) {
              return p;
            }
            return null;
          }).filter((p) => p);

        if (parentCandidates.length > 0) {
          const parent = parentCandidates[0] as any;
          return {
            requested: parsedItem.Description,
            part: { ...parsedItem, Sku: 'NEW', parent: parent.Sku },
            result: 'new child',
          };
        }

        // If neither a twin nor a parent exists, this is a new part
        return {
          requested: parsedItem.Description,
          part: { ...parsedItem, Sku: 'NEW' },
          result: 'new part',
        };
      });

      console.log(groupBy(newParts, (n) => n.result));
      console.log(newParts.map((p) => [p.part.Sku, p.requested, p.result, p.part.parent || '']));
      const records: string[][] = newParts.map((p) => [p.part.Sku as string, p.requested as string, p.result as string, p.part.parent as string || '']);
      setCsvData(records);
    };

    reader.readAsText(file);

    return false;
  };

  return (
    <FlexColumn align="center" style={{ gap: 8 }}>
      <Upload
        accept=".csv"
        showUploadList={false}
        beforeUpload={onUpload}
      >
        <UploadButton
          type="default"
          icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}
        >
          Upload customer order data
        </UploadButton>
      </Upload>
      {csvData.length > 0 && (
        <CSVDownload data={csvData} target="_self" />
      )}
    </FlexColumn>
  );
};

export default NewPartOrderUploader;
