import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FlexRow } from 'shared/containers/FlexContainer';
import { ItemDetailsColumnWrapper, ItemDetailsLabel, SearchSelect } from 'shared/styledComponents/inputs';
import {
  CUSTOMER_SHIPPING_DB_COLLECTION,
  customerShippingAddressesEditedAtom,
  currentCustomerShippingAddressesAtom, selectedCustomerShippingAddressAtom, currentCustomerAtom,
} from 'shared/state/customerState';
import { currentShopOrderAtom, ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { SHIPMENT_ITEMS_COLLECTION } from 'shared/state/shipmentState';
import { IShippingAddress } from 'shared/types/dbRecords';
import useFirebase from 'vendor/Firebase';
import EditShipAddressButton
  from 'pages/Orders/Components/SalesOrderRecord/ShipAddress/Buttons/EditShipAddressButton';

const SearchWrapper = styled(FlexRow)`
    justify-content: flex-start;
`;

interface IValueOption {
  itemLabel?: string;
  keycode: string;
}
const PartDetailDropdownItemRow = styled(FlexRow)`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.03)' : 'transparent')};
  & p {
    color: black;
    margin: 0;
    &:first-of-type {
      font-weight: bold;
      min-width: 180px;
    }
  }
  & span {
    width: 1px;
    height: 100%;
    margin: 0 8px;
    background-color: lightgrey;
  }
`;

interface IComponent {
  label: string;
  callback: (address: IShippingAddress, blur: boolean) => void;
  showEdit: boolean;
}

const ShippingAddressSelector = ({ callback, label = 'Addresses', showEdit = false }: IComponent) => {
  const { firestore } = useFirebase();
  const customerShippingAddressesDbString = useRecoilValue(CUSTOMER_SHIPPING_DB_COLLECTION);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const [addressEdited, setAddressEdited] = useRecoilState(customerShippingAddressesEditedAtom);
  const [currentOptions, setCurrentOptions] = useState<{ label: Element, value: string}[]>([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useRecoilState(selectedCustomerShippingAddressAtom);
  const currentCustomerShippingAddresses = useRecoilValue(currentCustomerShippingAddressesAtom);
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const shipmentsDbString = useRecoilValue(SHIPMENT_ITEMS_COLLECTION);

  const PartDetailDropdownMenuItem = ({ keycode, itemLabel = undefined }: IValueOption) => (
    <PartDetailDropdownItemRow key={`${keycode}-value-option`} id={`${keycode}-value-option`}>
      <p>{itemLabel}</p>
    </PartDetailDropdownItemRow>
  );
  const menuItems = (addresses: IShippingAddress[]) => addresses
    .map((a: IShippingAddress, index) => ({
      label: <PartDetailDropdownMenuItem itemLabel={a.name} keycode={a.Id} shade={index % 2 === 1} />,
      value: `${a.Id}`,
    }));
  const updateCurrent = (id: string) => {
    const address = find(currentCustomerShippingAddresses, (a: IShippingAddress) => a.Id === id) as IShippingAddress;
    const items = menuItems(currentCustomerShippingAddresses);
    // @ts-ignore
    setCurrentOptions(items);
    setSelectedShippingAddress(address);
    if (callback) callback(address, true);
    // const updatedOrder = { ...currentShopOrder, customer: { ...currentShopOrder.customer, ShipAddr: address } };
    // setCurrentShopOrder(updatedOrder);
  };
  const onSelect = (value: unknown, option: IValueOption) => {
    if (addressEdited) {
      firestore.collection(customerShippingAddressesDbString).doc(currentCustomer.id).update({ shippingAddresses: currentCustomerShippingAddresses }).then(() => {
        setAddressEdited(false);
        updateCurrent((value as string).toString());
      });
    } else {
      updateCurrent((value as string).toString());
    }
  };

  const updateDbAddresses = useCallback(async (address: IShippingAddress) => {
    const updatedCustomer = { ...currentShopOrder.customer, ShipAddr: address };
    await firestore.collection(ordersDbString).doc(currentShopOrder.id).update({ customer: updatedCustomer });
    const shipmentDocs = await firestore.collection(shipmentsDbString).where('orderId', '==', currentShopOrder.id).get();
    shipmentDocs.forEach((doc) => {
      firestore.collection(shipmentsDbString).doc(doc.id).update({ customer: updatedCustomer });
      setCurrentShopOrder({ ...currentShopOrder, customer: updatedCustomer });
    });
  }, [selectedShippingAddress]);

  useEffect(() => {
    if (currentCustomerShippingAddresses.filter((i) => i).length === 0) return;
    // @ts-ignore
    const items = menuItems(currentCustomerShippingAddresses);
    // @ts-ignore
    setCurrentOptions(items);
    // if we are looking at the shipping address dropdown from the context of an order
    if (currentShopOrder.id.length > 0 && currentShopOrder.customer?.ShipAddr) {
      let address = null;
      address = find(currentCustomerShippingAddresses, (a: IShippingAddress) => a.id === currentShopOrder.customer.ShipAddr.id);
      if (address) {
        setSelectedShippingAddress(address);
        updateDbAddresses(address);
      } else {
        let defaultAddress = find(currentCustomerShippingAddresses, (a: IShippingAddress) => a.default);
        if (!defaultAddress) defaultAddress = currentCustomerShippingAddresses[0];
        setSelectedShippingAddress(defaultAddress);
        updateDbAddresses(defaultAddress);
      }
    } else {
      // context of customer record.
      if (currentCustomerShippingAddresses.filter((i) => i).length === 0) return;
      const defaultAddress = find(currentCustomerShippingAddresses, (a: IShippingAddress) => a.default);
      setSelectedShippingAddress(defaultAddress || currentCustomerShippingAddresses[0]);
    }
    return () => { };
  }, [currentCustomerShippingAddresses.length]);
  return (
    <ItemDetailsColumnWrapper key="address-search-select-item-details-wrapper">
      <ItemDetailsLabel key="address-search-select-item-details-label">{label}</ItemDetailsLabel>
      <SearchWrapper>
        <SearchSelect
          dropdownStyle={{ width: 400, minWidth: 400 }}
          id="order-customer-address-search-dropdown"
          key="order-customer-address-item-search-dropdown"
          placeholder="Select an address"
      // @ts-ignore
          onSelect={onSelect}
          options={currentOptions}
          value={selectedShippingAddress.name}
        />
        {showEdit && (
          <EditShipAddressButton />
        )}
      </SearchWrapper>
    </ItemDetailsColumnWrapper>
  );
};

export default ShippingAddressSelector;
