import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { find } from 'lodash';
import {
  contactEditModeAtom,
  currentCustomerContactsAtom,
  selectedCustomerContactAtom,
} from 'shared/state/customerState';

const DeleteButton = styled(Button)`
  margin: 0 0 6px 0;
  padding: 2px 8px;
`;

const DiscardContactButton = () => {
  const currentCustomerContacts = useRecoilValue(currentCustomerContactsAtom);
  const setSelectedCustomerContact = useSetRecoilState(selectedCustomerContactAtom);
  const setEditMode = useSetRecoilState(contactEditModeAtom);

  const onClick = (e: any) => {
    e.preventDefault();
    setEditMode(false);
    setSelectedCustomerContact(find(currentCustomerContacts, (c) => c.default) ?? currentCustomerContacts[0]);
  };

  return (
    <DeleteButton type="default" onClick={onClick}>Discard</DeleteButton>
  );
};

export default DiscardContactButton;
