import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { Add, AddCircle } from '@styled-icons/material';

const ComponentButton = styled(Button)`
    border-radius: 8px;
`;
const NewIcon = styled(AddCircle)`
    width: 20px;
    position: relative;
    left: -4px;
    margin: 0 4px 3px 0px;
`;

const NewPurchaseOrderButton = () => {
  const onClick = (e: any) => {
    Modal.confirm({
      title: 'Creating POs',
      content: 'To create a new PO, visit Quickbooks Online, select a customer, then use the "New Transaction" button to create a PO. All POs created in Quickbooks will show up on this screen.',
      onOk: () => { window.open('https://app.qbo.intuit.com/app/customers', '_blank'); },
      okText: 'Go to QBO',
      cancelText: 'Stay here',
    });
  };

  return (
    <ComponentButton key="new-purchase-order-button" type="primary" icon={<NewIcon />} onClick={onClick}>New</ComponentButton>
  );
};

export default NewPurchaseOrderButton;
