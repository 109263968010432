import React from 'react';
import { PriorityHigh } from '@styled-icons/material-twotone';
import styled from 'styled-components';
import theme from 'shared/theme';
import { FlexColumn } from '../../containers/FlexContainer';

const NotFoundIcon = styled(PriorityHigh)`
  width: 32px;
  height: 32px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: ${(props: { compact: boolean }) => (props.compact ? '18px' : '28px')};
`;

const ComponentWrapper = styled(FlexColumn)`
  height: 100px;
  margin: ${(props: { compact: boolean }) => (props.compact ? '40px auto' : '100px auto')};
  color: ${theme.palette.warning.hue};
`;

interface IComponent {
  title: string;
  message: string;
  compact: boolean;
}

const NotFound = ({ title, message, compact = false }: IComponent) => (
  <ComponentWrapper compact={compact}>
    <NotFoundIcon />
    <Title compact={compact}>{title}</Title>
    <h4>{message}</h4>
  </ComponentWrapper>
);

export default NotFound;
