import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  currentCustomerAtom,
  CUSTOMERS_DB_COLLECTION,
} from 'shared/state/customerState';
import QBOAuth from 'shared/data/QBO/auth';
import QBOCustomer from 'shared/data/QBO/customer';
import Loader from 'shared/components/Utility/Loader';
import { qboDateString } from '../../../../../shared/data/calendar';

const SaveButton = styled(Button)`
  margin: 0 0 6px 0;
  padding: 2px 8px;
  background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.success[900])} !important;
  border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.neutral.white)};
  border-radius: ${theme.spacing(1)};
  border: none;
  color: ${theme.palette.neutral.white};

    &:disabled {
        background-color: ${theme.palette.neutral[100]} !important;
    }
  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.success.D100)} !important;
    border-color: transparent;
  }
`;

interface IComponent {
  disabled: boolean;
}
const SaveShippingAddressButton = ({ disabled = false }: IComponent) => {
  const { database, firestore } = useFirebase();
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(!disabled);

  const onClick = async (e: any) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      // await QBOAuth.refreshToken(database);
      const customerRecord = await QBOCustomer.fetchOne(currentCustomer.Id);
      const MetaData = { ...customerRecord.MetaData, LastUpdatedTime: qboDateString(new Date(), true) };
      await QBOCustomer.update(currentCustomer.Id, {
        BillAddr: currentCustomer.BillAddr,
        SyncToken: customerRecord.SyncToken,
      });
      await firestore.collection(customerDbString).doc(currentCustomer.id).update({
        BillAddr: currentCustomer.BillAddr,
        MetaData,
      });
      setIsSaving(false);
      setIsDisabled(true);
    } catch (saveError) {
      Modal.error({
        title: 'Error saving address!',
        content: "There was an error saving the customer's billing address. Please retry, and if the issue persists, let Keith know.",
        onOk: () => {
          setIsSaving(false);
        },
        onCancel: () => {
          setIsSaving(false);
        },
      });
    }
  };

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  return (
    <>
      { isSaving ? (
        <Loader label="Saving" radius={32} />
      ) : (
        <SaveButton disabled={isDisabled} onClick={onClick}>Save</SaveButton>
      )}
    </>
  );
};

export default SaveShippingAddressButton;
