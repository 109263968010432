import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Lock, LockOpen } from '@styled-icons/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import useFirebase from 'vendor/Firebase';
import { currentCustomerAtom, CUSTOMERS_DB_COLLECTION } from '../../../../shared/state/customerState';

const ComponentWrapper = styled(Button)`
  min-width: 100px;
  margin-top: 2px;
  padding-left: 3px;
  border-radius: 8px;
  padding-left: 12px;
  margin-left: unset;
  margin-right: unset;
`;

const LockIcon = styled(Lock)`
  width: 20px;
  position: relative;
  bottom: 1px;
  left: -4px;
`;
const LockOpenIcon = styled(LockOpen)`
  width: 20px;
  position: relative;
  bottom: 1px;
  left: -4px;
`;

interface IComponent {
  locked: boolean;
  changeCallback: (locked: boolean) => void;
}
const PercentLockButton = ({ changeCallback, locked }: IComponent) => {
  // const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const [_locked, setLocked] = useState<boolean>(true);

  const onLockAdjust = (e: any) => {
    setLocked(!_locked);
    changeCallback(!_locked);
  };

  useEffect(() => {
    setLocked(locked);
  }, [locked]);

  return (
    <ComponentWrapper
      type={_locked ? 'primary' : 'default'}
      icon={(
        <>
          { _locked ? (
            <LockIcon />
          ) : (
            <LockOpenIcon />
          )}
        </>
    )}
      onClick={onLockAdjust}
    >
      {_locked ? 'Unlock %' : 'Lock %'}
    </ComponentWrapper>
  );
};

export default PercentLockButton;
