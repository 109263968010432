import React from 'react';
import styled from 'styled-components';
import { Button, Popover } from 'antd';
import { MoreHoriz } from '@styled-icons/material';
import { useRecoilValue } from 'recoil';
import CopyDescriptionButton from 'pages/Orders/Components/SalesOrderRecord/Buttons/CopyDescriptionButton';
import { FlexRow } from 'shared/containers/FlexContainer';
import { customerUserEmailsAtom } from 'shared/state/routingState';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { includes } from 'lodash';
import OtherActions from 'shared/buttons/OtherActionsButton';
import DeleteItemButton from './DeleteItemButton';
import SearchItemTermsButton from './SearchItemTermsButton';
import SeeParentPartButton from './SeeParentPartButton';
import SearchGeometricPartButton from './SearchGeometricPartButton';

interface IComponent {
  description: string;
  partNumber: string;
  parentSku: string;
}

const ButtonPopover = styled(Popover)`
  & .ant-popover-inner {
    border-radius: 12px !important;
  }
`;
const MoreIcon = styled(MoreHoriz)`
  width: 20px;
  margin-bottom: 2px;
`;

const MoreButtonsWrapper = styled(FlexRow)`
    gap: 4px;
`;

const MoreButtons = ({
  description, partNumber, parentSku,
}: IComponent) => {
  const customerUserEmails = useRecoilValue(customerUserEmailsAtom);
  const partType = includes(['GN', 'BN'], description.substring(0, 2)) ? 'neck' : 'body';
  return (
    <MoreButtonsWrapper>
      {/* <ScopedComponent blacklist={customerUserEmails.emails}> */}
      {/* <SearchItemTermsButton description={description} /> */}
      <SearchGeometricPartButton sku={partNumber} type={partType} />
      {/* </ScopedComponent> */}
      {!!parentSku?.length && (
      <SeeParentPartButton parentSku={parentSku} />
      )}
      <CopyDescriptionButton description={description} border />
      <ScopedComponent blacklist={customerUserEmails.emails}>
        <DeleteItemButton partNumber={partNumber} />
      </ScopedComponent>
    </MoreButtonsWrapper>
  );
};

const OtherActionsButton = ({
  description, partNumber, parentSku,
}: IComponent) => (
  <OtherActions MoreButtons={<MoreButtons description={description} partNumber={partNumber} parentSku={parentSku} />} />
);

export default OtherActionsButton;
