import React, { useEffect, useState } from 'react';
import {
  RadioGroupWrapper,
  ViewTypeRadioButton,
  ViewTypeRadioGroup,
  ViewTypeSelect,
  ViewTypeSelectLabel,
  ViewTypeSelectOption,
} from 'shared/styledComponents/inputs';
import { ViewTypeSelectWrapper } from 'shared/styledComponents/containers';

interface IComponent {
  callback: (type: 'all'|'body'|'neck') => void;
  partViewType: 'all'|'body'|'neck';
}
const PartTypeSelector = ({ callback, partViewType }: IComponent) => {
  const [_partViewType, setPartViewType] = useState<'all'|'body'|'neck'>(partViewType);

  const onChangeParts = (e: any) => {
    const typeValue = e.target?.value || e;
    callback(typeValue);
  };

  useEffect(() => {
    setPartViewType(partViewType);
  }, [partViewType]);

  return (
    <RadioGroupWrapper>
      <ViewTypeSelectWrapper>
        <ViewTypeSelectLabel>Show</ViewTypeSelectLabel>
        <ViewTypeSelect
          size="default"
          value={_partViewType}
          onChange={onChangeParts}
          marginLeft="0px"
        >
          <ViewTypeSelectOption value="all">All</ViewTypeSelectOption>
          <ViewTypeSelectOption value="body">Bodies</ViewTypeSelectOption>
          <ViewTypeSelectOption value="neck">Necks</ViewTypeSelectOption>
        </ViewTypeSelect>
      </ViewTypeSelectWrapper>
      <ViewTypeRadioGroup value={_partViewType} buttonStyle="solid" onChange={onChangeParts}>
        <ViewTypeRadioButton
          value="all"
        >
          All
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="body"
        >
          Bodies
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="neck"
        >
          Necks
        </ViewTypeRadioButton>
      </ViewTypeRadioGroup>
    </RadioGroupWrapper>
  );
};

export default PartTypeSelector;
