import React from 'react';
import { useRecoilValue } from 'recoil';
import { routerStepsAtom } from 'shared/state/routingState';
import ReactHtmlParser from 'react-html-parser';
import { Caption } from 'shared/typography';
import { IRouterStep } from '../types';
import { RouterHistoryLi } from './styledComponents';
import { IRunner, IRunnerHistory } from '../../Orders/types';

const _ = require('lodash');
const shortid = require('shortid');

interface ComponentInterface {
  runner: IRunner;
}

export default ({ runner }: ComponentInterface) => {
  const routerSteps = useRecoilValue(routerStepsAtom);
  return (
    <ul>
      {runner.history.map((history: IRunnerHistory) => {
        const step = _.find(routerSteps, (s: IRouterStep) => s.id === history.step);
        const hD = history.dateEntered.toDate();
        if (step) {
          const stepName = step.subName ? `${step.name} - ${step.subName}` : step.name;
          return (
            <RouterHistoryLi key={shortid.generate()}>
              <Caption>{ReactHtmlParser(`${hD.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })}  <b>${stepName}</b>`)}</Caption>
            </RouterHistoryLi>
          );
        }
        return <div />;
      })}
    </ul>
  );
};
