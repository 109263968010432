import styled from 'styled-components';
import { FlexColumn, FlexRow } from '../containers/FlexContainer';
import { ConfigurationRow } from '../pageElements/styledComponents';
import theme from '../theme';
import { rgba } from '../palette/utils';

// eslint-disable-next-line import/prefer-default-export
export const PartDetailColumn = styled(FlexColumn)`
  width: calc(70% - 2px);
  height: 100%;
  flex-grow: 2;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: 2px solid white;
  padding-top: 40px;
  padding-left: 32px;
  overflow-y: scroll;
`;
export const DetailComponentWrapper = styled(FlexRow)`
  width: calc(80% - 68px);
    margin-left: 68px;
  align-items: flex-start;
`;
export const DetailRow = styled(FlexRow)`
  width: ${(props: { width: string }) => props.width || '100%'};
  justify-content: ${(props: { justifyContent: string }) => props.justifyContent || 'flex-start'};
  gap: ${(props: { gap: string }) => props.gap || '12px'};
`;
export const DetailColumn = styled(FlexColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 12px;
`;
export const ItemDetailHeader = styled(ConfigurationRow)`
    width: calc(80% - 100px);
    margin-left: 100px;
    height: 72px;
    flex-shrink: 0;
    gap: 8px;
    margin-top: ${(props: any) => (props.quickEditMode ? '0' : '16px')};
  
    background-color: ${(props: any) => (props.testMode ? '#FF0000' : 'rgba(82, 81, 102, 0.7)')};
    
    position: sticky;
    top: ${(props: any) => (props.quickEditMode ? '0' : '0')};
    z-index: 1000;
    
    box-shadow: 0 1px 4px ${rgba(theme.palette.neutral.black, 0.12)};
    
    padding-left: ${theme.spacing(3.5)};
`;

// @ts-ignore
export const ButtonIsland = styled(FlexRow)`
  width: auto;
  justify-content: space-between;
  padding: ${(props: { padding: string }) => props.padding || '0px 16px'};
  border-radius: 8px;
  margin: ${(props: { margin: string }) => props.margin || '0 8px 0 0'};
  gap: ${(props: { gap: string }) => props.gap || '8px'};
  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 2px 0px, rgba(60, 64, 67, 0.08) 0px 2px 6px 2px;
  flex-grow: ${(props: any) => (props.expand ? 2 : 0)};
  @media ${theme.device.laptopL} { 
    padding: ${(props: { padding: string }) => props.padding || '8px 16px'};
    min-width: ${(props: { noMinWidth: boolean }) => (props.noMinWidth ? '0px' : '500px')};
    justify-content: space-between;
      flex-grow: ${(props: { flexGrow: number, expand: boolean }) => (props.flexGrow || props.expand ? 2 : 0)};
  }
`;

export const PageAdminToolsWrapper = styled(FlexColumn)`
  height: auto;
  width: 100%;
  align-items: flex-start;
  background-color: ${(props: any) => (props.testMode ? '#FCEBEC' : theme.palette.neutral.white)};
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  position: sticky;
  top: 0px;
  z-index: 1000;
  padding: 2px 0 32px 0px;
  gap: 12px;
  @media ${theme.device.laptopL} {
    padding: 32px 0 32px 0px;
    align-items: flex-start;
  }
`;

export const AdminToolsButtonWrapper = styled(FlexColumn)`
  align-items: flex-start;
  flex-grow: 2;
  margin-top: 4px;
  @media ${theme.device.laptopL} {
    flex-direction: row;
    align-items: flex-end;
    margin-top: 0px;
  }
`;

export const AdminToolsFilterWrapper = styled(FlexRow)`
  width: calc(100% - 12px);
  padding-right: 12px;
  justify-content: ${(props: { justify: string }) => props.justify || 'center'};
  @media ${theme.device.laptopL} {
    width: 100%;
    height: 80px;
    padding-right: unset;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
  }
     & h2 {
        margin: 0;
     }
    
    & h3 {
      margin: 0
    }
`;

export const PartViewerFilterWrapper = styled(FlexColumn)`
    @media ${theme.device.laptopL} {
        //flex-direction: ;
        margin-top: -12px;
        justify-content: center;
    }
`;

export const ViewTypeSelectWrapper = styled(FlexColumn)`
    position: relative;
    bottom: 10px;
`;
