import React, { useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { FlexColumn } from 'shared/containers/FlexContainer';
import ReactHtmlParser from 'react-html-parser';
import {
  currentRunnerOpenAtom, inProcessValueAtom, productionItemsAtom, queueItemsAtom, routerMachineTypeAtom,
  routerStepsAtom, timeMachineAtom,
} from 'shared/state/routingState';
import { numberWithCommas, toHash } from 'shared/text';
import { scrollOptions, scrollToHash } from 'shared/scroll';
import {
  basicClientRunner, generalRunner, stepRunners,
} from 'shared/router/clientRunner';
import { runnerValue, stepRunnerData } from 'shared/router/utils';
import { dateBefore } from 'shared/data/calendar';
import { useTestDataAtom } from 'shared/state/pricingState';
import { ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { IBomItem, IOrderItem } from 'shared/types/dbRecords';
import Loader from 'shared/components/Utility/Loader';
import { flatten } from 'lodash';
import {
  baseBodyColumns, completedBodyColumns, onHoldBodyColumns, readyBodyColumns,
} from './Components/DataColumns';
import {
  RouterTableHeader,
  RouterTableHeaderDetail,
  RunnerTable, TableHeaderWrapper, RouterTableSectionWrapper, RouterTableHeaderRow, PartCount, WholesaleValue, WIPValue,
} from './styledComponents';
import {
  IRouterStep,
} from './types';
import {
  IClientRunner, IRunner, IShopOrder, IStepRunner,
} from '../Orders/types';

const _ = require('lodash');
const shortid = require('shortid');
const qs = require('qs');

export default () => {
  const { firestore } = useFirebase();
  const location = useLocation();
  const { shopOrderId } = qs.parse(location.search.replace('?', ''));

  const [, setShopOrders] = useState<IShopOrder[]>([]);
  const routerSteps = useRecoilValue<IRouterStep[]>(routerStepsAtom);
  const [, setCurrentOpen] = useRecoilState(currentRunnerOpenAtom);
  const routerMachineType = useRecoilValue(routerMachineTypeAtom);
  const [runners, setRunners] = useState<IStepRunner[]>([]);
  const setQueueItems = useSetRecoilState(queueItemsAtom);
  const setProductionItems = useSetRecoilState(productionItemsAtom);
  const setInProcessValue = useSetRecoilState(inProcessValueAtom);
  const [firstLoad, setFirstLoad] = useState(true);
  const timeMachine = useRecoilValue(timeMachineAtom);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const useTestData = useRecoilValue(useTestDataAtom);

  const runnerPartCount = (runnerList: IRunner[]): number => {
    const parts = flatten(runnerList.map((r: IRunner) => r.parts));
    return parts.map((p: IOrderItem) => p.quantityAssigned).reduce((a, b) => a + b, 0);
  };

  useEffect(() => {
    firestore.collection(ordersDbString)
      .where('type', '==', 'body')
      .where('completed', '==', false)
      .get()
      .then((snap) => {
        const orders: IShopOrder[] = [];
        const today = new Date();
        snap.forEach((doc) => {
          const d = doc.data();
          if (d.runners && (!d.completed || dateBefore(timeMachine, today))) {
            orders.push({
              ...d as IShopOrder,
              id: d.id,
            });
          }
        });
        const clientRunners = _.flatten(orders.map((o: IShopOrder) => o.runners.map((r: IRunner) => {
          const clientRunner = basicClientRunner(routerSteps, o, r, timeMachine);
          if (r.completed || !clientRunner) return null;
          // @ts-ignore
          return generalRunner(routerSteps, clientRunner, timeMachine);
        }))).filter((r: IClientRunner) => r && (routerMachineType === 'All' || r.machine.match(new RegExp(routerMachineType, 'i'))));

        setQueueItems(runnerPartCount(clientRunners
          .filter((r: IRunner) => r.step.match(/(ready|release)/i))));

        setProductionItems(runnerPartCount(clientRunners
          .filter((r: IRunner) => !r.step.match(/(ready|hold|complete)/i))));

        // setProductionItems(prodBods
        //   // @ts-ignore
        //   .map((r: IRunner) => r.partCounts
        //     .map((partCount: {type: string, count: number}) => partCount.count)
        //     .reduce((a: number, b: number) => a + b, 0))
        //   .reduce((a: number, b: number) => a + b, 0));

        const _runners = stepRunners('body', routerSteps, clientRunners);
        setRunners(_runners);
        setShopOrders(_.reverse(_.sortBy(orders, (o: IShopOrder) => o.dateCreated)));

        setInProcessValue(runnerValue(_runners));

        if (shopOrderId) {
          setCurrentOpen([shopOrderId]);
        }

        if (window.location.hash.length && firstLoad) {
          setFirstLoad(false);
          const options = {
            ...scrollOptions,
            align: {
              top: 0,
              topOffset: 348,
            },
          };
          scrollToHash(window.location.hash, options);
        }
      });
  }, [timeMachine, useTestData, routerMachineType]);

  // @ts-ignore
  return (
    <>
      {runners.length === 0
        && (
        <>
          <FlexColumn style={{ width: '100%', height: '50vw' }}>
            <Loader />
          </FlexColumn>
        </>
        )}
      {runners.map((item: IStepRunner) => {
        const { partCount, value, wipValue } = stepRunnerData(item);

        let columns = baseBodyColumns;
        if (item.step.match(/hold/i)) columns = onHoldBodyColumns;
        if (item.step.match(/ready/i)) columns = readyBodyColumns;
        if (item.step.match(/complete/i)) columns = completedBodyColumns;
        // hiding hand finishing for the time being - 5/14/21
        if (item.step.match(/hand/i)) return;

        // @ts-ignore
        return (
          <>
            <RouterTableSectionWrapper>
              <RouterTableHeader
                id={toHash(item.step)}
                key={shortid.generate()}
              >
                {item.step}
              </RouterTableHeader>
              <RouterTableHeaderRow>
                <PartCount>
                  <span>{partCount}</span>
                  <span>bodies</span>
                </PartCount>
                <WholesaleValue>
                  <span>{`$${numberWithCommas(value)}`}</span>
                  <span>Wholesale</span>
                </WholesaleValue>
                {!Number.isNaN(wipValue) && (
                  <WIPValue>
                    <span>{`$${numberWithCommas(Math.round(wipValue))}`}</span>
                    <span>WIP</span>
                  </WIPValue>
                )}
              </RouterTableHeaderRow>
            </RouterTableSectionWrapper>
            <RunnerTable
                  // scroll={{ x: true }}
              size="small"
                  // @ts-ignore
              columns={columns}
              dataSource={item.runners}
              pagination={false}
              rowClassName={(record: any) => {
                if (!item.step.match(/hold/i)) {
                  if (record.shipDelta <= -5 && !item.step.match(/complete/i)) return 'router-ship-schedule-behind';
                  return 'router-ship-schedule-ontime';
                }
                return 'router-on-hold';
              }}
            />
          </>
        );
      })}
    </>
  );
};
