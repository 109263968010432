import React from 'react';
import styled from 'styled-components';
import * as shortid from 'shortid';
import * as _ from 'lodash';
import { Button, Tooltip } from 'antd';
import { Info } from '@styled-icons/material-twotone';
import theme from 'shared/theme';
import { stageRedirect } from '../../../../shared/util';

const InfoIcon = styled(Info)`
  color: ${theme.palette.primary.hue};
  width: 24px;
  margin-bottom: 1px;
`;

const InfoButton = styled(Button)`
  border: none;
  &:hover {
    background-color: ${theme.palette.primary.hue};
    & svg {
      color: ${theme.palette.neutral.white};
    }
  }
`;

interface IComponentInterface {
  recordId: string;
  customerId: string;
  salesOrder: string;
}

export default ({ recordId, customerId, salesOrder }: IComponentInterface) => {
  const onSalesOrderClick = (e: any) => {
    e.preventDefault();

    stageRedirect(`${window.location.pathname}#${recordId}`);
    window.location.href = `/orders/create?edit=true&orderId=${recordId}&customer=${customerId}&salesOrder=${salesOrder}`;
  };

  return (
    <Tooltip placement="top" title="View/Edit">
      <InfoButton
        key={shortid.generate()}
        shape="circle"
        onClick={onSalesOrderClick}
      >
        <InfoIcon />
      </InfoButton>
    </Tooltip>
  );
};
