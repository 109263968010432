import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { findIndex } from 'lodash';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentCustomerAtom,
  currentCustomerShippingAddressesAtom,
  CUSTOMER_SHIPPING_DB_COLLECTION,
  CUSTOMERS_DB_COLLECTION,
  customerShippingAddressesEditedAtom,
  selectedCustomerShippingAddressAtom,
} from 'shared/state/customerState';
import { ICustomerRecord, IShippingAddress } from 'shared/types/dbRecords';
import QBOAuth from 'shared/data/QBO/auth';
import QBOCustomer from 'shared/data/QBO/customer';
import Loader, { Spinner } from '../../../../../shared/components/Utility/Loader';
import { qboDateString } from '../../../../../shared/data/calendar';

const SaveButton = styled(Button)`
  margin: 0 0 6px 0;
  padding: 2px 8px;
  background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.success[900])} !important;
  border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.neutral.white)};
  border-radius: ${theme.spacing(1)};
  border: none;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.success.D100)} !important;
    border-color: transparent;
  }
`;

const SaveShippingAddressButton = () => {
  const { database, firestore } = useFirebase();
  const customerShippingAddressDbString = useRecoilValue(CUSTOMER_SHIPPING_DB_COLLECTION);
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const [currentShippingAddresses, setCurrentShippingAddresses] = useRecoilState(currentCustomerShippingAddressesAtom);
  const selectedAddress = useRecoilValue(selectedCustomerShippingAddressAtom);
  const setEditMode = useSetRecoilState(customerShippingAddressesEditedAtom);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const updateDefaultAddress = async (address: IShippingAddress) => {
    // await QBOAuth.refreshToken(database);
    try {
      return QBOCustomer.updateDefaultShippingAddress(currentCustomer, address);
    } catch (e) {
      Modal.error({
        title: 'Error saving address!',
        content: "There was an error saving the customer's shipping address. Please retry, and if the issue persists, let Keith know.",
        onOk: () => {
          setIsSaving(false);
        },
        onCancel: () => {
          setIsSaving(false);
        },
      });
    }
  };

  const addNewAddress = async (address: IShippingAddress) => {
    let newAddresses = [...currentShippingAddresses];
    newAddresses.push(address);
    if (!address.default) {
      await firestore.collection(customerShippingAddressDbString).doc(currentCustomer.id).update({ shippingAddresses: newAddresses });
    } else {
      const updatedCustomer = await updateDefaultAddress(address);
      const MetaData = { ...currentCustomer.MetaData, LastUpdatedTime: qboDateString(new Date(), true) };
      await firestore.collection(customerDbString).doc(currentCustomer.id).update({ ShipAddr: updatedCustomer.ShipAddr, MetaData });
      newAddresses = [...currentShippingAddresses, { ...address, ...updatedCustomer.ShipAddr }];
      await firestore.collection(customerShippingAddressDbString).doc(currentCustomer.id).update({ shippingAddresses: newAddresses });
    }
  };

  const updateAddress = async (address: IShippingAddress, addressIndex: number) => {
    let newAddresses = [...currentShippingAddresses];
    newAddresses[addressIndex] = address;
    if (!address.default) {
      await firestore.collection(customerShippingAddressDbString).doc(currentCustomer.id).update({ shippingAddresses: newAddresses });
    } else {
      const updatedCustomer = await updateDefaultAddress(address);
      await firestore.collection(customerDbString).doc(currentCustomer.id).update({ ShipAddr: updatedCustomer.ShipAddr });
      newAddresses = [...currentShippingAddresses];
      newAddresses[addressIndex] = { ...address, ...updatedCustomer.ShipAddr };
      await firestore.collection(customerShippingAddressDbString).doc(currentCustomer.id).update({ shippingAddresses: newAddresses });
    }
  };
  const onClick = (e: any) => {
    e.preventDefault();
    setIsSaving(true);
    const selectedAddressIndex = findIndex(currentShippingAddresses, (a: IShippingAddress) => a.Id === selectedAddress.Id);
    if (selectedAddressIndex === -1) {
      addNewAddress({ ...selectedAddress, default: currentShippingAddresses.length === 0 ? true : selectedAddress.default }).then(() => {
        setIsSaving(false);
        setEditMode(false);
      });
    } else {
      updateAddress({ ...selectedAddress, default: currentShippingAddresses.length === 0 ? true : selectedAddress.default }, selectedAddressIndex).then(() => {
        setIsSaving(false);
        setEditMode(false);
      });
    }
  };

  return (
    <>
      { isSaving ? (
        <Loader label="Saving" radius={32} />
      ) : (
        <SaveButton onClick={onClick}>Save</SaveButton>
      )}
    </>
  );
};

export default SaveShippingAddressButton;
