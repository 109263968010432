import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { PlusCircle, MinusCircle } from '@styled-icons/fa-solid';
import {
  showNecksAtom, showBodiesAtom,
} from 'shared/state/routingState';
import { scrollOptions, scrollToHash } from 'shared/scroll';
import { ncRecordsAtom } from 'shared/state/utilState';
import theme from 'shared/theme';
import { INCRecord } from 'shared/types/dbRecords';
import {
  RouterTableHeader,
  RouterTableHeaderWrapper,
  TableSectionHeaderWrapper,
  SplitSalesOrderTable,
} from 'pages/ProductionSchedule/styledComponents';
import { ncDepartmentColumns } from './Components/NcDataColumns';
import { setRowClass } from './util';
import NCExpandedRow from './Components/NCExpandedRow';

const _ = require('lodash');
const shortid = require('shortid');

export const NCPartHeaderWrapper = styled(RouterTableHeaderWrapper)`
  border: 1px solid #e6e6e6;
  background-color: ${theme.palette.neutral.white};
  & h6 {
    color: ${theme.palette.neutral.black};
  }
  
  &:first-of-type {
    border-right: 1px solid #e6e6e6;
  }
`;

export const DownCaret = styled(PlusCircle)`
  width: 18px;
  color: ${theme.palette.primary.hue};
`;
export const UpCaret = styled(MinusCircle)`
  width: 18px;
  color: ${theme.palette.primary.hue};
`;

interface IComponentInterface {
  records: INCRecord[];
}
interface IExpandFunction {
  record: INCRecord;
  expanded: boolean;
  onExpand: (e: any) => void;
}

export default ({ records }: IComponentInterface) => {
  const showBodies = useRecoilValue(showBodiesAtom);
  const showNecks = useRecoilValue(showNecksAtom);
  const ncRecords = useRecoilValue(ncRecordsAtom);
  const [departments, setDepartments] = useState<string[]>([]);
  const [_records, _setRecords] = useState<INCRecord[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    setDepartments(_.sortBy(
      _.uniq(ncRecords.map((r: INCRecord) => r.department)),
      (r: INCRecord) => r.department,
    ));

    const [orderNumber, ncId] = window.location.hash?.replace('#', '').split('@');
    const isDate = parseInt(window.location.hash.replace('#', ''), 10).toString();

    const scrollHash = isDate.match(/NaN/) ? window.location.hash : ncId;
    const cbFunc = () => {
      const options = {
        ...scrollOptions,
        // time: 0,
        align: {
          top: 0,
          topOffset: isDate.match(/NaN/) ? 372 : 164,
        },
      };
      scrollToHash(`#${scrollHash}`, options);
    };
    const timeout = setTimeout(cbFunc, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // @ts-ignore
  return (
    <>
      {_.sortBy(departments, (d: string) => d).map((department: string) => {
        // @ts-ignore
        const departmentRecords = records.filter((r: INCRecord) => r.department === department);

        const bodies = departmentRecords.filter((r: INCRecord) => r.type.match(/body/i));
        const necks = departmentRecords.filter((r: INCRecord) => r.type.match(/neck/i));

        // @ts-ignore
        return (
          <div>
            {!!departmentRecords.length
                      && (
                      <div style={{ marginBottom: 72 }}>
                        <TableSectionHeaderWrapper style={{ marginBottom: 16 }}>
                          <TableSectionHeaderWrapper>
                            <RouterTableHeaderWrapper id={`${department}-header-row`} key={`${department}-header-row`}>
                              <RouterTableHeader>{department}</RouterTableHeader>
                            </RouterTableHeaderWrapper>
                          </TableSectionHeaderWrapper>
                        </TableSectionHeaderWrapper>
                        {showBodies
                            && (
                            <>
                              <TableSectionHeaderWrapper>
                                <TableSectionHeaderWrapper>
                                  <NCPartHeaderWrapper id={`${department}-bodies-table`} key={`${department}-bodies-table`}>
                                    <RouterTableHeader>Bodies</RouterTableHeader>
                                  </NCPartHeaderWrapper>
                                </TableSectionHeaderWrapper>
                              </TableSectionHeaderWrapper>
                              {!!bodies.length
                                  && (
                                  <SplitSalesOrderTable
                                    rowKey="recordId"
                                    size="small"
                                          // @ts-ignore
                                    columns={ncDepartmentColumns}
                                    dataSource={bodies}
                                    pagination={false}
                                    rowClassName={setRowClass}
                                    expandable={{
                                      expandedRowRender: (record) => <NCExpandedRow record={record} />,
                                      rowExpandable: (record) => record.id !== 'Not Expandable',
                                      expandIcon: ({ expanded, onExpand, record }: IExpandFunction) =>
                                        (expanded ? (
                                          <UpCaret onClick={(e) => onExpand(record, e)} />
                                        ) : (
                                          <DownCaret onClick={(e) => onExpand(record, e)} />
                                        )),
                                    }}
                                  />
                                  )}
                              {!bodies.length && <div style={{ marginBottom: 24 }} />}
                            </>
                            )}
                        {showNecks
                            && (
                            <>
                              <TableSectionHeaderWrapper>
                                <TableSectionHeaderWrapper>
                                  <NCPartHeaderWrapper id={`${department}-necks-table`} key={`${department}-necks-table`}>
                                    <RouterTableHeader>Necks</RouterTableHeader>
                                  </NCPartHeaderWrapper>
                                </TableSectionHeaderWrapper>
                              </TableSectionHeaderWrapper>
                              {!!necks.length
                                  && (
                                  <SplitSalesOrderTable
                                    rowKey="recordId"
                                    size="small"
                                          // @ts-ignore
                                    columns={ncDepartmentColumns}
                                    dataSource={necks}
                                    pagination={false}
                                    rowClassName={setRowClass}
                                    expandable={{
                                      expandedRowRender: (record) => <NCExpandedRow record={record} />,
                                      rowExpandable: (record) => record.id !== 'Not Expandable',
                                      expandIcon: ({ expanded, onExpand, record }: IExpandFunction) =>
                                        (expanded ? (
                                          <UpCaret onClick={(e) => onExpand(record, e)} />
                                        ) : (
                                          <DownCaret onClick={(e) => onExpand(record, e)} />
                                        )),
                                    }}
                                  />
                                  )}
                            </>
                            )}
                      </div>
                      )}
          </div>

        );
      })}
    </>
  );
};
