import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import styled from 'styled-components';
import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  dataLoadingAtom,
} from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  adminUserEmailsAtom,
  shipmentsViewEndDateAtom,
  shipmentsViewStartDateAtom,
  superAdminUserEmailAtom,
} from 'shared/state/routingState';
import Loader from 'shared/components/Utility/Loader';
import { IShipment } from 'shared/types/dbRecords';
import { SHIPMENTS_DB_COLLECTION } from 'shared/state/shipmentState';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import {
  SHIPMENTS_VIEW_END_KEY,
  SHIPMENTS_VIEW_START_KEY,
} from 'shared/data/calendar';
import useFirebase from 'vendor/Firebase';
import { Button } from 'antd';
import { PricingCol, PageHeaderWrapper } from '../styledComponents';
import ShipmentFilter from './Components/ShipmentList/ShipmentFilter';
import ShipmentItemsList from './Components/ShipmentList/ShipmentItemsList';
import { OrderRangePicker } from '../ProductionSchedule/styledComponents';
import { PageTitle } from '../../shared/styledComponents/typographicElements';
import { ButtonIsland } from '../../shared/styledComponents/containers';
import ExportShipmentsButton from './Components/ShipmentList/Buttons/ExportShipmentsButton';
import { DatePickerNextIcon, DatePickerPrevIcon } from '../../shared/styledComponents/inputs';

const ShippingCol = styled(PricingCol)`
    padding-bottom: 0;
`;

const ShipmentListAdminWrapper = styled(FlexColumn)`
  height: auto;
  width: 100%;
  align-items: flex-start;
  background-color: ${(props: any) => (props.testMode ? '#FCEBEC' : theme.palette.neutral.white)};
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  position: sticky;
  top: 0px;
  z-index: 1000;
  padding: 32px 0 4px 0;
  gap: 32px;
  @media ${theme.device.laptopL} {
    align-items: center;
  }
`;

const ButtonWrapper = styled(FlexRow)`
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 2px 0 0 72px;
    gap: 12px;
    
    @media ${theme.device.laptopL} {
      justify-content: flex-start;
      margin-left: 0;
    }
`;

const InvoicesButton = styled(Button)`
    border-radius: 8px;
`;

/**
 *   Display component that lists and filters all shipments
 */
const ShipmentList = () => {
  const { firestore } = useFirebase();
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const [loading, setLoading] = useRecoilState(dataLoadingAtom);
  const shipmentsDbString = useRecoilValue(SHIPMENTS_DB_COLLECTION);
  const [shipments, setShipments] = useState<IShipment[]>([]);
  const [startDate, setStartDate] = useRecoilState(shipmentsViewStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(shipmentsViewEndDateAtom);
  const onChangeDates = (date: any) => {
    const [sDate, eDate] = date;
    const newStartDate = new Date(sDate.year(), sDate.month(), sDate.date());
    setStartDate(newStartDate);
    localStorage.setItem(SHIPMENTS_VIEW_START_KEY, newStartDate.getTime().toString());

    const newEndDate = new Date(eDate.year(), eDate.month(), eDate.date());
    setEndDate(newEndDate);
    localStorage.setItem(SHIPMENTS_VIEW_END_KEY, newEndDate.getTime().toString());
  };

  const goToInvoices = (e: any) => {
    window.open('https://app.qbo.intuit.com/app/invoices');
  };

  useEffect(() => {
    setLoading(true);
    // set date to "1" to get stats for entire month, if month is passed but still shown in view
    const sDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    sDate.setHours(0, 0, 0, 0);
    const eDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    eDate.setHours(23, 59, 59, 999);

    const shipmentRecords: IShipment[] = [];
    firestore.collection(shipmentsDbString)
      .where('shipDate', '>=', firebase.firestore.Timestamp.fromDate(sDate))
      .where('shipDate', '<=', firebase.firestore.Timestamp.fromDate(eDate))
      .get()
      .then((snap) => {
        setShipments(snap.docs.map((d) => d.data() as IShipment));
        setLoading(false);
        setTimeout(() => {
          const el = document.getElementById(window.location.hash.replace('#', ''));
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }, 100);
      });
  }, [startDate, endDate]);

  return (
    <PageHeaderWrapper>
      <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
        <ShipmentListAdminWrapper id="customer-admin-inputs">
          <ButtonWrapper>
            <PageTitle>Shipments</PageTitle>
            <ButtonIsland noMinWidth padding="8px 16px">
              <ExportShipmentsButton shipments={shipments} />
              <InvoicesButton onClick={goToInvoices}>View Invoices</InvoicesButton>
            </ButtonIsland>
            <ShipmentFilter width="50%" />
            <OrderRangePicker
              key="shipments-date-range-picker"
              onChange={onChangeDates}
              value={[moment(startDate), moment(endDate)]}
              nextIcon={<DatePickerNextIcon />}
              prevIcon={<DatePickerPrevIcon />}
            />
          </ButtonWrapper>
        </ShipmentListAdminWrapper>
      </ScopedComponent>
      <ShippingCol>
        {loading
          ? (
            <>
              <FlexColumn style={{ width: '100%', height: '50vw' }}>
                <Loader />
              </FlexColumn>
            </>
          ) : (
            <>
              <ShipmentItemsList shipments={shipments} />
            </>
          )}
      </ShippingCol>
    </PageHeaderWrapper>
  );
};

export default ShipmentList;
