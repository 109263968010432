import React from 'react';
import { useRecoilState } from 'recoil';
import { Drawer } from 'antd';
import { IShipment } from 'shared/types/dbRecords';
import { invoicePDFDrawerAtom } from 'shared/state/orderState';
import InvoicePDF from '../InvoicePDF';

interface IComponent {
  shipment: IShipment;
}
const InvoicePDFDrawer = ({ shipment }: IComponent) => {
  const [open, setOpen] = useRecoilState(invoicePDFDrawerAtom);

  const closeDrawer = (e: any) => {
    setOpen(false);
  };

  return (
    <Drawer
      placement="right"
      width="78vw"
      open={open}
      onClose={closeDrawer}
    >
      <InvoicePDF shipment={shipment} />
    </Drawer>
  );
};

export default InvoicePDFDrawer;
