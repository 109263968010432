import React, { useState } from 'react';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { CloudDownload } from '@styled-icons/material';
import { saveAs } from 'file-saver';
import useFirebase from 'vendor/Firebase';
import theme from 'shared/theme';
import { dateString } from 'shared/data/calendar';
import {
  CUST_CELLS, generateWorkbook, rowValueAtCol, workbookStyles,
} from 'shared/reports/workbook';
import { ORDER_ITEMS_DB_COLLECTION, ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { useRecoilValue } from 'recoil';

const ExportModal = styled(Modal)`
  width: 352px !important;
  text-align: center;
  border-radius: 12px;
  
  & .ant-modal-header {
    border-bottom: none;
  }
  
  & .ant-modal-body { 
    display: none;
  }
  
  & .ant-modal-footer {
    display: flex;
    justify-content: center;
    gap: 8px;
    border-top: none;
  }
  
  & .ant-modal-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 28px;
  }
`;

const ExportButton = styled(Button)`
  width: 52px;
  border-radius: 8px;
  background-color: ${theme.palette.success.hue};
  border-color: ${theme.palette.success.hue};
  color: ${theme.palette.neutral.white};
  
  &:hover {
    background-color: ${theme.palette.success.D100};
    border-color: ${theme.palette.success.D100};
    color: ${theme.palette.neutral.white};
  }
`;

const ExportButtonIcon = styled(CloudDownload)`
  width: 28px;
  padding: 0;
  color: ${theme.palette.neutral.white};
`;

const ExportModalButton = styled(Button)`
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  width: 50%;
  background-color: unset;
`;

interface IComponent {
  customerId: string;
}

export default ({ customerId }: IComponent) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { firestore } = useFirebase();
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);

  const storedStartDate = localStorage.getItem('salesOrderStartDate');
  const storedEndDate = localStorage.getItem('salesOrderEndDate');
  const now = new Date();
  const startDate = storedStartDate ? new Date(parseInt(storedStartDate, 10)) : new Date(now.getFullYear(), 0, 1);
  const endDate = storedEndDate ? new Date(parseInt(storedEndDate, 10)) : new Date(now.getFullYear(), 11, 31);

  const exportOrders = async () => {
    setModalOpen(false);
    firestore.collection(ordersDbString)
      .where('customer.DisplayName', '==', customerId)
      .where('shipDate', '>=', startDate)
      .where('shipDate', '<=', endDate)
      .get()
      .then((snapshot) => {
        const bodyData: any[] = [];
        const neckData: any[] = [];
        const dataSets = [];
        snapshot.forEach((record) => {
          const recordData = record.data();
          if (recordData.type.match(/body/i)) bodyData.push(recordData);
          else neckData.push(recordData);
        });

        if (bodyData.length > 0) dataSets.push(bodyData);
        if (neckData.length > 0) dataSets.push(neckData);

        const workbook = generateWorkbook();
        const wbStyle = workbookStyles(workbook);

        const reportSheet = workbook.addWorksheet('Customer Orders', wbStyle.sheetOptions);
        reportSheet.column(CUST_CELLS.CUSTOMER_PO).setWidth(12);
        reportSheet.column(CUST_CELLS.SALES_ORDER).setWidth(12);
        reportSheet.column(CUST_CELLS.DESCRIPTION).setWidth(68);
        reportSheet.column(CUST_CELLS.UNITS).setWidth(8);
        reportSheet.column(CUST_CELLS.VALUE).setWidth(10);
        reportSheet.column(CUST_CELLS.RELEASE_DATE).setWidth(12);
        reportSheet.column(CUST_CELLS.ORDER_DATE).setWidth(12);
        reportSheet.column(CUST_CELLS.SHIP_DATE).setWidth(12);

        let currentRow = 1;
        dataSets.forEach((data) => {
          const type = data[0].type.match(/body/i) ? 'Bodies' : 'Necks';

          reportSheet.cell(currentRow, CUST_CELLS.SALES_ORDER).string(customerId).style(wbStyle.heroStyle);
          reportSheet.cell(currentRow, CUST_CELLS.DESCRIPTION).string(`Report Date: ${dateString(new Date(), false, true)}`).style(wbStyle.BaseFontStyle);
          reportSheet.cell(currentRow, CUST_CELLS.ORDER_DATE).string('Orders').style(wbStyle.sectionDataStyle);
          reportSheet.cell(currentRow, CUST_CELLS.VALUE).string('Value').style(wbStyle.sectionDataStyle);
          reportSheet.cell(currentRow, CUST_CELLS.SHIP_DATE).string('Invoiced').style(wbStyle.sectionDataStyle);

          currentRow += 1;
          const totalsRow = currentRow;
          currentRow += 2;

          reportSheet.cell(currentRow, CUST_CELLS.CUSTOMER_PO).string('PO').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, CUST_CELLS.SALES_ORDER).string('Order #').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, CUST_CELLS.DESCRIPTION).string('Description').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, CUST_CELLS.ORDER_DATE).string('Order Date').style(wbStyle.headerStyleVert);
          reportSheet.cell(currentRow, CUST_CELLS.UNITS).string('Units').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, CUST_CELLS.VALUE).string('Value').style(wbStyle.headerStyle);
          reportSheet.cell(currentRow, CUST_CELLS.RELEASE_DATE).string('Release\nDate').style(wbStyle.headerStyleVert);
          reportSheet.cell(currentRow, CUST_CELLS.SHIP_DATE).string('Ship Date').style(wbStyle.headerStyleVert);
          reportSheet.cell(currentRow, CUST_CELLS.STATUS).string('Status').style(wbStyle.headerStyle);

          currentRow += 1;
          const firstDataRow = currentRow;
          _.sortBy(data, (d: IShopOrder) => d.shipDate.toDate()).forEach((record: IShopOrder) => {
            let status = 'In Production';
            if (!record.runners?.length) status = 'Not Released';
            else if (record.completed) status = 'Shipped';

            reportSheet.cell(currentRow, CUST_CELLS.CUSTOMER_PO).string(record.purchaseOrder).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.SALES_ORDER).string(record.salesOrder).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.DESCRIPTION).string(record.description).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.ORDER_DATE).string(dateString(record.orderDate.toDate(), false, true)).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.UNITS).number(record.partCount).style(wbStyle.rowNumberStyle);
            reportSheet.cell(currentRow, CUST_CELLS.VALUE).number(record.orderValue).style(wbStyle.rowCurrencyStyle);
            reportSheet.cell(currentRow, CUST_CELLS.RELEASE_DATE).string(dateString(record.releaseDate.toDate(), false, true)).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.SHIP_DATE).string(dateString(record.shipDate.toDate(), false, true)).style(wbStyle.rowStyle);
            reportSheet.cell(currentRow, CUST_CELLS.STATUS).string(status).style(wbStyle.rowStyle);
            currentRow += 1;
          });

          currentRow += 1;
          reportSheet.cell(currentRow, CUST_CELLS.CUSTOMER_PO).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.SALES_ORDER).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.DESCRIPTION).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.ORDER_DATE).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.UNITS).formula(`SUM(${rowValueAtCol(CUST_CELLS.UNITS, firstDataRow)}:${rowValueAtCol(CUST_CELLS.UNITS, currentRow - 1)})`).style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.VALUE).formula(`SUM(${rowValueAtCol(CUST_CELLS.VALUE, firstDataRow)}:${rowValueAtCol(CUST_CELLS.VALUE, currentRow - 1)})`).style(wbStyle.columnTotalCurrencyStyle);
          reportSheet.cell(currentRow, CUST_CELLS.RELEASE_DATE).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.SHIP_DATE).string('').style(wbStyle.columnTotalNumberStyle);
          reportSheet.cell(currentRow, CUST_CELLS.STATUS).string('').style(wbStyle.columnTotalNumberStyle);

          reportSheet.cell(totalsRow, CUST_CELLS.SALES_ORDER).string(type).style(wbStyle.heroStyle);
          reportSheet.cell(totalsRow, CUST_CELLS.ORDER_DATE).number(data.length).style(wbStyle.sectionDataStyle);
          reportSheet.cell(totalsRow, CUST_CELLS.VALUE).formula(`SUM(${rowValueAtCol(CUST_CELLS.VALUE, firstDataRow)}:${rowValueAtCol(CUST_CELLS.VALUE, currentRow - 1)})`).style(wbStyle.sectionCurrencyStyle);
          reportSheet.cell(totalsRow, CUST_CELLS.SHIP_DATE).formula(`SUMIFS(${rowValueAtCol(CUST_CELLS.VALUE, firstDataRow)}:${rowValueAtCol(CUST_CELLS.VALUE, currentRow - 1)}, ${rowValueAtCol(CUST_CELLS.STATUS, firstDataRow)}:${rowValueAtCol(CUST_CELLS.STATUS, currentRow - 1)}, "Shipped")`).style(wbStyle.sectionCurrencyStyle);

          currentRow += 4;
        });

        workbook.writeToBuffer().then((workbookBuffer) => {
          const blob = new Blob([workbookBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, `${customerId}_Orders_Report.xlsx`);
        });
      });
  };

  const exportParts = async () => {
    setModalOpen(false);
    const orderDocs = await firestore.collection(ordersDbString)
      .where('customer.DisplayName', '==', customerId)
      .where('shipDate', '>=', startDate)
      .where('shipDate', '<=', endDate)
      .get();

    const orders = orderDocs.docs.map((d) => d.data());
    const orderItemsPromises = orders.map((order) =>
      firestore.collection(orderItemsDbString)
        .doc(order.id)
        .get());

    const orderItemsDocs = await Promise.all(orderItemsPromises);
    const rows = [];
    const customerName = orders[0]?.customer?.CompanyName || customerId;
    const nonPlaceholderOrders = orders.filter((o) => !o.salesOrder.match(/PH/));

    if (nonPlaceholderOrders.length === 0) {
      return Modal.info({
        title: 'No open orders found',
        content: 'There are no open orders for this customer in the selected date range.',
      });
    }

    nonPlaceholderOrders.forEach((order) => {
      const orderItems = orderItemsDocs
        .find((doc) => doc.id === order.id)
        ?.data()?.orderItems || [];

      const status = order.completed ? 'Shipped' : order.runners.length > 0 ? 'In Process' : 'Not Launched';

      orderItems.filter((i) => i.Sku.match(new RegExp(customerId))).forEach((item) => {
        if (item.quantityOpen > 0) {
          rows.push({
            po: order.purchaseOrder,
            so: order.salesOrder,
            sku: item.Sku,
            description: item.Description,
            quantity: item.quantityOpen,
            shipDate: order.shipDate.toDate(),
            status,
          });
        }
      });
    });

    const workbook = generateWorkbook();
    const wbStyle = workbookStyles(workbook);

    const reportSheet = workbook.addWorksheet('Open Parts', wbStyle.sheetOptions);
    
    // Set column widths
    // Order Date
    reportSheet.column(1).setWidth(16);
    // Order #
    reportSheet.column(2).setWidth(12);
    // SKU
    reportSheet.column(3).setWidth(18);
    // Description
    reportSheet.column(4).setWidth(108);
    // Quantity
    reportSheet.column(5).setWidth(8);
    // Ship Date
    reportSheet.column(6).setWidth(14);
    // Status
    reportSheet.column(7).setWidth(15);

    // Add title and subtitle
    let currentRow = 1;
    reportSheet.cell(currentRow, 1)
      .string(`${customerName} Necks in Process`)
      .style({ ...wbStyle.heroStyle, 
        alignment: { horizontal: 'left' },
        fill: { type: 'pattern', patternType: 'solid', fgColor: '#FFFFFF' },
        font: { color: 'black', size: 18, bold: true },
      }); 

    reportSheet.cell(currentRow, 7).string(`Printed ${dateString(new Date(), false, true)}`)
      .style({ ...wbStyle.BaseFontStyle, 
        alignment: { horizontal: 'right' },
        font: { color: 'black', size: 12, bold: true },
      });

    currentRow += 1;
    reportSheet.cell(currentRow, 1)
      .string('sorted by ship date')
      .style({ ...wbStyle.BaseFontStyle, alignment: { horizontal: 'left' } });

    const headerRowStyle = {
      ...wbStyle.headerStyle,
      alignment: {
        horizontal: 'center',
      },
      fill: { type: 'pattern', patternType: 'solid', fgColor: '#E6E6E6' },
      border: {
        left: { style: 'thin', color: '#D9D9D9' },
        right: { style: 'thin', color: '#D9D9D9' },
        top: { style: 'thin', color: '#D9D9D9' },
        bottom: { style: 'thick', color: '#000000' }
      },
    };
    currentRow += 2;
    reportSheet.cell(currentRow, 1).string('PO #').style(headerRowStyle);
    reportSheet.cell(currentRow, 2).string('Order #').style(headerRowStyle);
    reportSheet.cell(currentRow, 3).string('Sku').style(headerRowStyle);
    reportSheet.cell(currentRow, 4).string('Description').style(headerRowStyle);
    reportSheet.cell(currentRow, 5).string('Qty').style(headerRowStyle);
    reportSheet.cell(currentRow, 6).string('Ship Date').style(headerRowStyle);
    reportSheet.cell(currentRow, 7).string('Status').style(headerRowStyle);

    // Sort rows by ship date
    const sortedRows = sortBy(rows, (row) => row.shipDate);

    // Add data rows with alternating background colors
    sortedRows.forEach((row, index) => {
      currentRow += 1;
      reportSheet.row(currentRow).setHeight(24);
      const rowStyle = {
        ...wbStyle.rowStyle,
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: index % 2 === 0 ? 'FFFFFF' : 'F0F0F0',
        },
        border: {
          left: { style: 'thin', color: '#D9D9D9' },
          right: { style: 'thin', color: '#D9D9D9' },
          top: { style: 'thin', color: '#D9D9D9' },
          bottom: { style: 'thin', color: '#D9D9D9' }
        },
        alignment: {
          vertical: 'center',
        },
      };

      reportSheet.cell(currentRow, 1).string(row.po).style(rowStyle);
      reportSheet.cell(currentRow, 2).string(`1${row.so}`).style({ ...rowStyle, alignment: { horizontal: 'center' } });
      reportSheet.cell(currentRow, 3).string(row.sku).style(rowStyle);
      reportSheet.cell(currentRow, 4).string(row.description).style(rowStyle);
      reportSheet.cell(currentRow, 5).number(row.quantity).style({ ...rowStyle, alignment: { horizontal: 'center' } });
      reportSheet.cell(currentRow, 6).string(dateString(row.shipDate, false, true)).style(rowStyle);
      reportSheet.cell(currentRow, 7).string(row.status).style(rowStyle);
    });

    workbook.writeToBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${customerName}_Open_Parts.xlsx`);
    });
  };

  return (
    <>
      <ExportModal
        open={modalOpen}
        closeIcon={<span />}
        title="Export which data?"
        onCancel={() => setModalOpen(false)}
        footer={[
          <ExportModalButton key="parts" type="primary" onClick={exportParts}>
            Parts
          </ExportModalButton>,
          <ExportModalButton key="orders" type="default" onClick={exportOrders}>
            Orders
          </ExportModalButton>,
        ]}
      />
      <ExportButton icon={<ExportButtonIcon />} onClick={() => setModalOpen(true)} />
    </>
  );
};
