import React, { useState, useEffect } from 'react';
import { FlexRow } from 'shared/containers/FlexContainer';
import styled from 'styled-components';
import theme from 'shared/theme';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';
import {
  CustomerDiscountWrapper,
} from './styledComponents';
import PercentLockButton from './PriceListInputs/PercentLockButton';

/**
 * Header component on "Customer Pricing" page that displays basic customer info & customer discounts
 */

const ComponentWrapper = styled(FlexRow)`
  justify-content: space-between;
  gap: 24px;
  padding: 8px;

  border-radius: 8px;
  @media ${theme.device.laptopL} {
    width: unset;
    justify-content: center;
    align-items: flex-end;
  }
`;

interface IComponent {
  changePercentCallback: (bodyDiscount: number, neckDiscount: number) => void;
  changeLockCallback: (locked: boolean) => void;
  locked: boolean;
  bodyDiscount: number;
  neckDiscount: number;
}

export default ({
  changePercentCallback, changeLockCallback, neckDiscount, bodyDiscount, locked,
}: IComponent) => {
  const [_bodyDiscount, setBodyDiscount] = useState<number>(bodyDiscount);
  const [_neckDiscount, setNeckDiscount] = useState<number>(neckDiscount);
  const [adjustLock, setAdjustLock] = useState<boolean>(true);

  const onDiscountBlur = (type: 'body'|'neck') => (value: number) => {
    const discount = type.match(/body/i)
      ? { bodyDiscount: value, neckDiscount: _neckDiscount }
      : { bodyDiscount: _bodyDiscount, neckDiscount: value };

    changePercentCallback(discount.bodyDiscount, discount.neckDiscount);
  };

  useEffect(() => {
    setBodyDiscount(bodyDiscount);
    setNeckDiscount(neckDiscount);
    setAdjustLock(locked);
  }, [bodyDiscount, neckDiscount, locked]);

  return (
    <ComponentWrapper>
      <PercentLockButton changeCallback={changeLockCallback} locked />
      {!adjustLock && (
        <>
          <CustomerDiscountWrapper>
            <DetailNumberInputWithCallback
              key="bodyDiscount-input"
              label={`Body ${neckDiscount > 0 ? 'Inc.' : 'Dec'}`}
              placeholder="0"
              value={bodyDiscount}
              callback={onDiscountBlur('body')}
              disabled={adjustLock}
              noMargin
              width="80px"
            />
          </CustomerDiscountWrapper>
          <CustomerDiscountWrapper>
            <DetailNumberInputWithCallback
              key="neckDiscount-input"
              label={`Neck ${neckDiscount > 0 ? 'Inc.' : 'Dec'}`}
              placeholder="0"
              value={neckDiscount}
              callback={onDiscountBlur('neck')}
              disabled={adjustLock}
              noMargin
              width="80px"
            />
          </CustomerDiscountWrapper>
        </>
      )}
    </ComponentWrapper>
  );
};
