import React, { useState, useEffect, useContext } from 'react';
import { Tabs } from 'antd';
import { every, find, flatten } from 'lodash';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import { resolveFirestorePath } from 'shared/util';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { newPartsAtom } from 'shared/state/pricingState';
import useFirebase from 'vendor/Firebase';
import { PageTitle } from 'shared/styledComponents/typographicElements';
import { PageHeaderWrapper } from 'pages/styledComponents';
import { PageAdminToolsWrapper, AdminToolsFilterWrapper } from 'shared/styledComponents/containers';
import ItemListFilter from 'shared/components/Input/ItemListFilter';
import { cleanMeta, escapeMeta } from 'shared/text';
import { ICustomerPart, PDRequirement } from 'shared/types/parts';
import { configToDescription, updateConfig } from 'shared/partParser/util';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { openOrdersDataAtom } from 'shared/state/orderState';
import { partConfigTermsAtom } from 'shared/state/partViewState';
import ProductDevList from './Components/ProductDevList';
import ProductDevStatusSelector from './Components/ProductDevStatusSelector';
import ProductDevReleaseDateSelector from './Components/ProductDevReleaseDateSelector';

const TabsWrapper = styled.div`
  padding: 24px;
  width: 100%;
  
  .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ant-tabs-content-holder {
    width: 100%;
  }
`;

const ProductDevTasks = () => {
  const { currentUser } = useContext(AuthContext);
  const { firestore } = useFirebase();
  const newPartFilterQueryKey = 'newParts.filter';
  const newPartCurrentTabKey = 'newParts.currentTab';
  const newPartFilterKey = 'newParts.statusFilter';
  const newPartReleaseFilterKey = 'newParts.releaseStatusFilter';
  const defaultActiveKey = sessionStorage.getItem(newPartCurrentTabKey) ? sessionStorage.getItem(newPartCurrentTabKey) : (currentUser?.email === 'keithh@wildwoodmfg.com' ? 'materials' : 'product-dev');
  const [activeTab, setActiveTab] = useState<string>(defaultActiveKey as string);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const [parts, setParts] = useState([]);
  const [filteredParts, setFilteredParts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState<PDRequirement|'All'>(sessionStorage.getItem(newPartFilterKey) as PDRequirement|'All' || 'All');
  const [releaseStatus, setReleaseStatus] = useState<'all'|'onOrder'>(sessionStorage.getItem(newPartReleaseFilterKey) as 'all'|'onOrder' || 'all');
  const setNewParts = useSetRecoilState(newPartsAtom);
  const openOrders = useRecoilValue(openOrdersDataAtom);
  const configTerms = useRecoilValue(partConfigTermsAtom);

  const filterParts = (partsToFilter, query = '') => (partsToFilter.filter((part) => {
    if (!query || !query.length) return true;
    
    const matchText = cleanMeta(`${part.notes || ''}${escapeMeta(configToDescription(part.config) || '')}${part.Sku}${part.customer?.DisplayName || ''}`);
    const matches = query.split(',').map((t) => {
      if (t.trim().match('not')) {
        const matcher = t.replace('not', '');
        return !matchText.match(new RegExp(matcher.trim(), 'i'));
      }
      if (t.trim().match('\bor\b')) {
        const matcher = t.replace(' or ', '|');
        return matchText.match(new RegExp(matcher.trim(), 'i'));
      }
      return matchText.match(new RegExp(t.trim(), 'i'));
    });
    return every(matches, Boolean);
  }));

  const onTabChange = (key: string) => {
    setActiveTab(key);
    sessionStorage.setItem(newPartCurrentTabKey, key);
    const query = sessionStorage.getItem(newPartFilterQueryKey);
    const _filteredParts = filterParts(parts, query);
    setFilteredParts(_filteredParts);
  };

  const onSearchChange = (value: string) => {
    setSearchQuery(value);
    const _filteredParts = filterParts(parts, value);
    sessionStorage.setItem(newPartFilterQueryKey, value);
    setFilteredParts(_filteredParts);
  };

  const onChangeStatus = (value: PDRequirement) => {
    setStatus(value);
    console.log('value', value);
    sessionStorage.setItem(newPartFilterKey, value);
  };

  const onChangeReleaseStatus = (value: 'all'|'onOrder') => {
    setReleaseStatus(value);
    sessionStorage.setItem(newPartReleaseFilterKey, value);
  };

  const resolveParts = (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => (
    snapshot.docs.map((d) => {
      const _data = d.data() as ICustomerPart;
      const openOrder = find(openOrders, (o) => o?.orderItems?.some((i) => i?.Sku === _data.Sku));
      const releaseDate = openOrder ? openOrder.releaseDate : null;
      return { ..._data, releaseDate };
    })
  );

  const updatePartsState = (_parts) => {
    let _filteredParts;
    if (releaseStatus === 'onOrder') {
      _filteredParts = _parts.filter((p) => p.releaseDate != null);
    } else {
      _filteredParts = _parts;
    }
    
    // if (status === 'All') {
    //   _filteredParts = _filteredParts.filter((p) => p.pdRequirement != null);
    // } else {
    //   _filteredParts = _filteredParts.filter((p) => p.pdRequirement === status);
    // }

    const allParts = _filteredParts.map((p) => ({
      ...p,
      config: updateConfig(p.config, configTerms),
    }));

    setParts(allParts);
    setFilteredParts(filterParts(allParts, searchQuery));
    setNewParts(allParts);
  };

  useEffect(() => {
    const partsPath = resolveFirestorePath('parts');
    const unsubscribers = [];
    let allPartsData = [];

    const query = status === 'All'
      ? firestore.collection(partsPath).where('pdRequirement', '!=', null)
      : firestore.collection(partsPath).where('pdRequirement', '==', status);

    const unsubscribe = query.onSnapshot((snapshot) => {
      const _parts = resolveParts(snapshot);
      allPartsData = [...new Set([...allPartsData, ..._parts].map((p) => p.Sku))]
        .map((sku) => _parts.find((p) => p.Sku === sku) || allPartsData.find((p) => p.Sku === sku));
      updatePartsState(allPartsData);
    });

    unsubscribers.push(unsubscribe);

    return () => {
      unsubscribers.forEach((unsub) => unsub());
    };
  }, [firestore, setNewParts, status, searchQuery, configTerms, releaseStatus]);

  const items = [
    {
      key: 'product-dev',
      label: 'Product Dev',
      children: <ProductDevList isCompleted={false} stage="product" parts={filteredParts} />,
    },
    {
      key: 'materials',
      label: 'Materials',
      children: <ProductDevList isCompleted={false} stage="material" parts={filteredParts} />,
    },
  ];

  return (
    <PageHeaderWrapper>
      <ScopedComponent whitelist={flatten([adminEmails.emails, superAdminEmails.emails])}>
        <PageAdminToolsWrapper>
          <PageTitle>Development Queue</PageTitle>
          <AdminToolsFilterWrapper>
            <ItemListFilter
              id="new-parts-filter"
              callback={onSearchChange}
              currentValue={searchQuery}
              prompt="Filter parts using comma-separated terms (SKU, description, customer)"
              width="40%"
            />
            <ProductDevReleaseDateSelector currentValue={releaseStatus} callback={onChangeReleaseStatus} />
            <ProductDevStatusSelector currentValue={status} callback={onChangeStatus} />
          </AdminToolsFilterWrapper>
        </PageAdminToolsWrapper>
        <TabsWrapper>
          <Tabs
            activeKey={activeTab}
            items={items}
            onChange={onTabChange}
          />
        </TabsWrapper>
      </ScopedComponent>
    </PageHeaderWrapper>
  );
};

export default ProductDevTasks; 
