const PART_MAP = {
  BB: {
    SwAsh: {
      XLite: {
        twoPiece: {
          part: '1200033',
          description: 'BBK_ASH_BASS_2Pc C/L_2.65 or less_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200029',
          description: 'BBK_ASH_BASS_2Pc C/L_2.54 or less_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1100072',
          description: 'SWAMP ASH_8/4_SORTED_HEAVY_>2.5 lb',
          baseUnit: 6,
        },
        onePiece: {
          part: '1100072',
          description: 'SWAMP ASH_8/4_SORTED_HEAVY_>2.5 lb',
          baseUnit: 6,
        },
      },
    },
    Alder: {
      XLite: {
        twoPiece: {
          part: '1200021',
          description: 'BBK_ALDER_BASS_2Pc C/L_2.54 or less_22x15x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1200019',
          description: 'BBK_ALDER_STRAT_1Pc_2.54 or less_19x14x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200021',
          description: 'BBK_ALDER_BASS_2Pc C/L_2.54 or less_22x15x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1200019',
          description: 'BBK_ALDER_STRAT_1Pc_2.54 or less_19x14x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1100019',
          description: 'ALDER_8/4_SORTED HEAVY=>2.74',
          baseUnit: 6,
        },
        onePiece: {
          part: '1200034',
          description: 'BBK_ALDER_STRAT_1Pc_2.60 or less_19x14x1.84"',
          baseUnit: 1,
        },
      },
    },
    EWPine: {
      XLite: {
        twoPiece: {
          part: '1200067',
          description: 'BBK_EWPINE_BASS_2Pc C/L_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200067',
          description: 'BBK_EWPINE_BASS_2Pc C/L_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200067',
          description: 'BBK_EWPINE_BASS_2Pc C/L_22x15x1.84"',
          baseUnit: 1,
        },
      },
    },
    'Rst EWPine': {
      XLite: {
        twoPiece: {
          part: '1200063',
          description: 'BBK_EWPINE_ROASTED_BASS_2Pc C/L_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200063',
          description: 'BBK_EWPINE_ROASTED_BASS_2Pc C/L_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200063',
          description: 'BBK_EWPINE_ROASTED_BASS_2Pc C/L_22x15x1.84"',
          baseUnit: 1,
        },
      },
    },
    KhyMah: {
      XLite: {
        twoPiece: {
          part: '1200035',
          description: 'BBK_KHY MAH_ BASS_2.8 or less_22x15x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1100014',
          description: 'KHAYA (AFRICAN) MAHOGANY_8/4_7"+WIDE',
          baseUnit: 6,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200035',
          description: 'BBK_KHY MAH_ BASS_2.8 or less_22x15x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1100014',
          description: 'KHAYA (AFRICAN) MAHOGANY_8/4_7"+WIDE',
          baseUnit: 6,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200035',
          description: 'BBK_KHY MAH_ BASS_2.8 or less_22x15x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1100014',
          description: 'KHAYA (AFRICAN) MAHOGANY_8/4_7"+WIDE',
          baseUnit: 6,
        },
      },
    },
    Basswood: {
      XLite: {
        twoPiece: {
          part: '1200053',
          description: 'BBK_BASSWOOD_BASS_1Pc/2Pc C/L_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200053',
          description: 'BBK_BASSWOOD_BASS_1Pc/2Pc C/L_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200053',
          description: 'BBK_BASSWOOD_BASS_1Pc/2Pc C/L_22x15x1.84"',
          baseUnit: 1,
        },
      },
    },
    RstSwAsh: {
      XLite: {
        twoPiece: {
          part: '1200059',
          description: 'BBK_ASH-ROASTED_BASS_2Pc C/L_2.54 or less_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200059',
          description: 'BBK_ASH-ROASTED_BASS_2Pc C/L_2.54 or less_22x15x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200059',
          description: 'BBK_ASH-ROASTED_BASS_2Pc C/L_2.54 or less_22x15x1.84"',
          baseUnit: 1,
        },
      },
    },
  },
  GB: {
    SwAsh: {
      XXLite: {
        twoPiece: {
          part: '1200016',
          description: 'BBK_ASH_STRAT_2Pc C/L_2.30 or less_20x14x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1200015',
          description: 'BBK_ASH_STRAT_1Pc_2.30 or less_20x14x1.84"',
          baseUnit: 1,
        },
      },
      XLite: {
        twoPiece: {
          part: '1200016',
          description: 'BBK_ASH_STRAT_2Pc C/L_2.30 or less_20x14x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1200015',
          description: 'BBK_ASH_STRAT_1Pc_2.30 or less_20x14x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200014',
          description: 'BBK_ASH_STRAT_2Pc C/L_2.54 or less_20x14x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1200038',
          description: 'BBK_ASH_STRAT_1Pc_2.53 or less_20x14x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1100072',
          description: 'SWAMP ASH_8/4_SORTED_HEAVY_>2.5 lb',
          baseUnit: 6,
        },
        onePiece: {
          part: '1100072',
          description: 'SWAMP ASH_8/4_SORTED_HEAVY_>2.5 lb',
          baseUnit: 6,
        },
      },
    },
    Alder: {
      XLite: {
        twoPiece: {
          part: '1200022',
          description: 'BBK_ALDER_STRAT_2Pc C/L_2.54 or less_19x13.5x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1100017',
          description: 'ALDER_8/4_SORTED LIGHT <2.59',
          baseUnit: 6,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200022',
          description: 'BBK_ALDER_STRAT_2Pc C/L_2.54 or less_19x13.5x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1100017',
          description: 'ALDER_8/4_SORTED LIGHT <2.59',
          baseUnit: 6,
        },
      },
      Standard: {
        twoPiece: {
          part: '1100034',
          description: 'BBK_ALDER_STRAT_1Pc_2.60 or less_19x14x1.84"',
          baseUnit: 6,
        },
        onePiece: {
          part: '1100018',
          description: 'ALDER_8/4_SORTED MEDIUM=>2.6',
          baseUnit: 6,
        },
      },
    },
    EWPine: {
      XLite: {
        twoPiece: {
          part: '1200064',
          description: 'BBK_EWPINE_STRAT_2Pc C/L_2.30 or Less_20x14x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200064',
          description: 'BBK_EWPINE_STRAT_2Pc C/L_2.30 or Less_20x14x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200064',
          description: 'BBK_EWPINE_STRAT_2Pc C/L_2.30 or Less_20x14x1.84"',
          baseUnit: 1,
        },
      },
    },
    'Rst EWPine': {
      XLite: {
        twoPiece: {
          part: '1200061',
          description: '',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200061',
          description: 'BBK_EWPINE_ROASTED_STRAT_2Pc C/L_20x14x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200061',
          description: 'BBK_EWPINE_ROASTED_STRAT_2Pc C/L_20x14x1.84"',
          baseUnit: 1,
        },
      },
    },
    KhyMah: {
      XLite: {
        twoPiece: {
          part: '1200012',
          description: 'BBK_KHY MAH_2.8 Lb or less_20x14x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1100014',
          description: 'KHAYA (AFRICAN) MAHOGANY_8/4_7"+WIDE',
          baseUnit: 6,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200012',
          description: 'BBK_KHY MAH_2.8 Lb or less_20x14x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1100014',
          description: 'KHAYA (AFRICAN) MAHOGANY_8/4_7"+WIDE',
          baseUnit: 6,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200012',
          description: 'BBK_KHY MAH_2.8 Lb or less_20x14x1.84"',
          baseUnit: 1,
        },
        onePiece: {
          part: '1100014',
          description: 'KHAYA (AFRICAN) MAHOGANY_8/4_7"+WIDE',
          baseUnit: 6,
        },
      },
    },
    Basswood: {
      XLite: {
        twoPiece: {
          part: '1200052',
          description: 'BBK_BASSWOOD_STRAT_1Pc/2Pc C/L_20x14x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200052',
          description: 'BBK_BASSWOOD_STRAT_1Pc/2Pc C/L_20x14x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200052',
          description: 'BBK_BASSWOOD_STRAT_1Pc/2Pc C/L_20x14x1.84"',
          baseUnit: 1,
        },
      },
    },
    RstSwAsh: {
      XLite: {
        twoPiece: {
          part: '1200069',
          description: 'BBK_ASH-ROASTED_RECT_2Pc C/L_2.54 or less_20x20x1.84"',
          baseUnit: 1,
        },
      },
      Lite: {
        twoPiece: {
          part: '1200069',
          description: 'BBK_ASH-ROASTED_RECT_2Pc C/L_2.54 or less_20x20x1.84"',
          baseUnit: 1,
        },
      },
      Standard: {
        twoPiece: {
          part: '1200069',
          description: 'BBK_ASH-ROASTED_RECT_2Pc C/L_2.54 or less_20x20x1.84"',
          baseUnit: 1,
        },
      },
    },
  },
};

export default (wood: string, weight: string, onePiece: boolean, type: 'GB'|'BB'): { part: string, baseUnit: number } => {
  const DEFAULT = { part: '', baseUnit: 1 };
  const construction = onePiece ? 'onePiece' : 'twoPiece';
  if (!PART_MAP[type] || !PART_MAP[type][wood] || !PART_MAP[type][wood][weight]) return DEFAULT;
  return PART_MAP[type][wood][weight][construction];
};
