import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';
import { IShopOrder } from 'pages/Orders/types';
import Loader from 'shared/components/Utility/Loader';
import { orderScheduleDataAtom } from 'shared/state/orderState';
import useFirebase from 'vendor/Firebase';

interface IRecordData {
  salesOrder: string;
  customer: string;
  releaseDate: string;
  type: 'body'|'neck';
}

export default () => {
  const { firestore } = useFirebase();
  const setOrderScheduleData = useSetRecoilState(orderScheduleDataAtom);
  const [orders, setOrders] = useState<IRecordData[]>([]);

  useEffect(() => {
    firestore.collection('orders')
      .where('completed', '==', false)
      .get()
      .then((snapshot) => {
        const data = [];
        snapshot.forEach((record) => {
          const r = record.data() as IShopOrder;

          // if (r.runners) data.push(null);

          const parsedOrderNumber = parseInt(r.salesOrder, 10);
          let orderNumber = 'PH';
          if (Number.isNaN(parsedOrderNumber)) orderNumber = 'PH';
          else if (parsedOrderNumber < 10000) orderNumber = `${parsedOrderNumber + 10000}`;
          else orderNumber = `${parsedOrderNumber}`;

          data.push({
            salesOrder: orderNumber,
            customer: r.customer.id,
            released: !!r.runners?.length,
            releaseDate: r.releaseDate.toDate().toLocaleDateString(),
            type: r.type,
          });
        });

        const recordData = data.filter((r) => r)
          .map((r: IRecordData) => `${r.salesOrder},${r.customer},${r.released},${r.releaseDate},${r.type}`);

        setOrderScheduleData(`Sales Order,Customer,Released,Release Date,Type\r${recordData.join('\r')}`);
      });
  }, []);

  return (
    <Loader />
  );
};
