import React from 'react';
import styled from 'styled-components';
import { Body } from 'shared/typography';
import theme from 'shared/theme';
import { numberWithCommas } from 'shared/text';
import { Tooltip } from 'antd';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { formatPrice } from 'shared/data';
import { CellCopy } from 'shared/styledComponents/typographicElements';
import { IEndPart } from '../types';
import {
  CopyRecordIcon,
  RouterTableCopyButton,
} from '../../ProductionSchedule/styledComponents';
import CopyPartDataButton from './PriceList/PricedItem/Buttons/CopyPartDataButton';

const shortid = require('shortid');

const InactiveLabel = styled.div`
  border-radius: 4px;
  background-color: rgba(0,0,0,0.1);
  color: black;
  padding: 0 8px;
  width: 70%;
  text-align: center;
  font-size: 12px;
`;

export const ITEM_NUMBER_COLUMN = {
  title: 'Item #',
  dataIndex: 'partNumber',
  render: (text: string, record: IEndPart) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <FlexColumn justify="flex-start" align="flex-start">
    <CellCopy key={shortid.generate()}>{record.partNumber}</CellCopy>
    {!record.active && (
    <InactiveLabel>Inactive</InactiveLabel>
    )}
  </FlexColumn>,
  }),
  sorter: {
    compare: (a: IEndPart, b: IEndPart) => {
      if (a.item > b.item) return 1;
      if (b.item > a.item) return -1;
      return 0;
    },
  },
  defaultSortOrder: 'ascend',
  width: '8%',
};

export const ITEM_DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: 'description',
  render: (text: string, record: IEndPart) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.description}</CellCopy>,
  }),
  sorter: {
    compare: (a: IEndPart, b: IEndPart) => {
      if (a.description > b.description) return 1;
      if (b.description > a.description) return -1;
      return 0;
    },
    multiple: 3,
  },
  filtered: true,
  // width: '75%',
};

const PRICE_COLUMN = {
  title: 'Price',
  dataIndex: 'price',
  render: (text: string, record: IEndPart) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{`${formatPrice(record.price)}`}</CellCopy>,
  }),
  width: '8%',
};

export const ACTIONS_COLUMN = {
  title: '',
  key: 'actions',
  render: (text: string, record: any) => (
    <FlexRow style={{ width: '100%', padding: 0 }}>
      <CopyPartDataButton record={record} />
    </FlexRow>
  ),
  width: '4%',
};

export const columns = [
  ACTIONS_COLUMN,
  ITEM_NUMBER_COLUMN,
  ITEM_DESCRIPTION_COLUMN,
  PRICE_COLUMN,
];
