import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { FlexRow } from '../../containers/FlexContainer';

const ComponentInput = styled(ItemDetailsColumnWrapper)`
    width: auto;
    min-width: 200px;
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

const LabelValue = styled.p`
  margin: 0;
`;

interface IComponent {
  id: string;
  label: string;
  value: string;
  LabelExtra?: any
}
const DetailLabel = ({
  value, id, label, LabelExtra = <div />,
}: IComponent) => {
  const [fieldValue, setFieldValue] = useState<string|undefined>(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <ComponentInput key={`${id}-item-details-wrapper`}>
      <LabelWrapper>
        <ItemDetailsLabel key={`${id}-item-details-wrapper`}>{label}</ItemDetailsLabel>
        {LabelExtra}
      </LabelWrapper>
      <LabelValue
        key={`${id}-label-field`}
        id={`${id}-label-field`}
      >
        {fieldValue}
      </LabelValue>
    </ComponentInput>
  );
};

export default DetailLabel;
