import React, { useEffect, useState } from 'react';
import {
  Select,
} from 'antd';
import styled from 'styled-components';
import {
  RecoilState, useRecoilState, useRecoilValue,
} from 'recoil';
import { currentNcRecordAtom } from 'shared/state/utilState';
import { routerStepsAtom } from 'shared/state/routingState';
import { currentShopOrderAtom } from 'shared/state/orderState';
import { IRouterStep } from '../../ProductionSchedule/types';

interface IMenuComponent {
  callback: (e: any) => void;
  dataList: RecoilState<{label: string, id: string}[]>
  listTitle: string;
}

interface IActionOption {
  departments: string[];
  id: string;
  label: string;
  type: 'any'|'body'|'neck';
}
interface IComponent {
  listPrompt: string;
  listTitle: string;
  onChange?: (e: any) => void;
  defaultValue?: string;
}

const ReasonSelect = styled(Select)`
  width: 100%;
  margin-bottom: 8px;
  
  & .ant-select-selector {
    height: 48px;
    line-height: 48px;
  }
`;

export default ({
  listTitle, listPrompt, onChange = null, defaultValue = null,
}: IComponent) => {
  const [ncRecord, setNcRecord] = useRecoilState(currentNcRecordAtom);
  const shopOrder = useRecoilValue(currentShopOrderAtom);
  const [selectedOption, setSelectedOption] = useState(shopOrder.type === 'body' ? 110 : 152);
  const [optionsList, setOptionsList] = useState<number[]>([]);
  const [label, setLabel] = useState<string|null>(null);
  const routerSteps = useRecoilValue(routerStepsAtom);

  const onMenuClick = (e: number) => {
    setSelectedOption(e);
    setNcRecord({
      ...ncRecord,
      maxPartClass: e,
    });
    if (onChange) onChange(e);
  };

  useEffect(() => {
    let routerStep = _.find(routerSteps, (r: IRouterStep) => r.type === ncRecord.type && r.name.match(ncRecord.name));
    if (ncRecord.department === 'Milling') routerStep = _.find(routerSteps, (r: IRouterStep) => r.type === ncRecord.type && r.name.match(/materials/i));
    setOptionsList(routerStep.validPartClasses);
    if (ncRecord.maxPartClass) setSelectedOption(ncRecord.maxPartClass);
  }, [ncRecord.department]);

  return (
    <>
      { optionsList && (
      <ReasonSelect value={selectedOption} onChange={onMenuClick}>
        {optionsList.map((o) =>
          <Select.Option value={o}>{o}</Select.Option>)}
      </ReasonSelect>
      )}
    </>
  );
};
