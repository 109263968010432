import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { FlexRow } from 'shared/containers/FlexContainer';
import { RouterTDCopy } from '../../../styledComponents';
import ViewOrderButton from './Buttons/ViewOrderButton';
import theme from '../../../../../shared/theme';

const SalesOrderLink = styled.a`
    
`;
const ReleasedTag = styled.p`
    margin: 0;
    padding: 2px 4px;
    background-color: ${theme.palette.success[400]};
    border-radius: 4px;
`;
export const SALES_ORDER_COLUMN = {
  title: 'Sales Order',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => (
    <FlexRow id={`${record.salesOrder}-salesOrder`} key={shortid.generate()} align="center" justify="flex-start">
      <ViewOrderButton key={shortid.generate()} record={record.salesOrder} />
    </FlexRow>
  ),
  width: 100,
};
export const CUSTOMER_COLUMN = {
  title: 'Customer',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => (
    <FlexRow id={`${record.salesOrder.salesOrder}-customer`} key={shortid.generate()} align="center" justify="flex-start">
      <RouterTDCopy key={shortid.generate()}>{record.salesOrder.customer.id}</RouterTDCopy>
    </FlexRow>
  ),
  width: 100,
};
export const RELEASE_DATE_COLUMN = {
  title: 'Release',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => (
    <FlexRow id={`${record.salesOrder}-releaseDate`} key={shortid.generate()} align="center" justify="center">
      {record.released ? (
        <ReleasedTag>RELEASED</ReleasedTag>
      ) : (
        <RouterTDCopy key={shortid.generate()}>{record.releaseDate}</RouterTDCopy>
      )}
    </FlexRow>
  ),
  width: 100,
};
export const QUANTITY_REQUIRED_COLUMN = {
  title: 'Quantity',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => (
    <FlexRow id={`${record.salesOrder}-releaseDate`} key={shortid.generate()} align="center" justify="center">
      <RouterTDCopy key={shortid.generate()}>{record.quantityRequired}</RouterTDCopy>
    </FlexRow>
  ),
  width: 50,
};
export const DESCRIPTION_COLUMN = {
  title: 'Part Name',
  dataIndex: shortid.generate(),
  render: (text: string, record: any) => (
    <FlexRow id={`${record.salesOrder}-description`} key={shortid.generate()} align="center" justify="flex-start">
      <RouterTDCopy key={shortid.generate()}>{record.partDescription}</RouterTDCopy>
    </FlexRow>
  ),
};
