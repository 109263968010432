import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button, Dropdown, MenuProps, Modal, Tooltip,
} from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import theme from 'shared/theme';
import { runnerWorkOrdersAtom } from 'shared/state/routingState';
import { generateRunners } from 'shared/router/utils';
import useFirebase from 'vendor/Firebase';
import {
  currentShopOrderAtom, invoicePDFDrawerAtom,
  orderConfirmationPDFDrawerAtom, orderItemsAtom,
  ORDERS_DB_COLLECTION, orderShipmentsAtom, packSlipPDFDrawerAtom,
  workOrderPDFDrawerAtom,
} from 'shared/state/orderState';
import { Simulate } from 'react-dom/test-utils';
import { noop } from 'lodash';
import { IRunner } from '../../../types';
import { selectedCustomerShippingAddressAtom } from '../../../../../shared/state/customerState';
import { FlexRow } from '../../../../../shared/containers/FlexContainer';

const PrintButton = styled(Button)`
  border-radius: 8px;
  background-color: ${theme.palette.primary.hue};
  border-color: ${theme.palette.primary.hue};
  color: ${theme.palette.neutral.white};
  &:hover {
    background-color: ${theme.palette.primary.D100};
    border-color: ${theme.palette.primary.D100};
    color: ${theme.palette.neutral.white};
  }
  &:focus {
    background-color: ${theme.palette.primary.hue};
    border-color: ${theme.palette.primary.hue};
    color: ${theme.palette.neutral.white};
  }
`;

const DisabledPrintButton = styled(PrintButton)`
  background-color: lightgrey;
  border-color: darkgrey;
  color: darkgrey;
  cursor: not-allowed;
 &:hover {
    border-color: darkgrey;
    color: darkgrey;
    background-color: lightgrey;
  }
  &:focus {
    border-color: darkgrey;
    color: darkgrey;
    background-color: lightgrey;
  }
`;

const PrintModalButton = styled(Button)`
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  width: 50%;
  background-color: unset;
`;

interface IComponent {
  orderId: string;
}
const PrintDocumentButton = ({ orderId }: IComponent) => {
  const { firestore } = useFirebase();
  const [menuProps, setMenuProps] = useState({ items: [], onClick: null });
  const setWorkOrderPDFOpen = useSetRecoilState(workOrderPDFDrawerAtom);
  const setInvoicePDFOpen = useSetRecoilState(invoicePDFDrawerAtom);
  const setPackSlipPDFOpen = useSetRecoilState(packSlipPDFDrawerAtom);
  const setOrderConfirmationPDFOpen = useSetRecoilState(orderConfirmationPDFDrawerAtom);
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);
  const workOrders = useRecoilValue(runnerWorkOrdersAtom);
  const orderShipments = useRecoilValue(orderShipmentsAtom);
  const orderItems = useRecoilValue(orderItemsAtom);
  const currentShopOrder = useRecoilValue(currentShopOrderAtom);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const runners = generateRunners(currentShopOrder) as unknown as IRunner[];
    // @ts-ignore - the missing props from the type validation are contained in ...currentShopOrder
    const updatedRecord = { ...currentShopOrder, runners };
    firestore.collection(ordersDbCollectionString).doc(orderId).set(updatedRecord).then(() => {
      if (e.key === 'order-confirmation') setOrderConfirmationPDFOpen(true);
      if (e.key === 'work-order') setWorkOrderPDFOpen(true);
      if (e.key === 'packing-slip') setPackSlipPDFOpen(true);
      if (e.key === 'invoice') setInvoicePDFOpen(true);
    });
  };

  // only show the "work order option if work orders exist
  useEffect(() => {
    const menuItems = [
      {
        label: 'Order Confirmation',
        key: 'order-confirmation',
      },
    ];

    if (currentShopOrder.runners?.length) {
      menuItems.push({
        label: 'Work Order',
        key: 'work-order',
      });
    }
    if (orderShipments.length) {
      menuItems.push(
        {
          label: '',
          key: 'blank-space',
        },
        {
          label: 'Packing slip',
          key: 'packing-slip',
        },
        {
          label: 'Invoice',
          key: 'invoice',
        },
      );
    }
    setMenuProps({
      items: menuItems,
      onClick: handleMenuClick,
    });
  }, [workOrders, currentShopOrder, orderShipments]);

  return (
    <>
      {orderItems.filter((i) => i).length === 0 ? (
        <Tooltip title="Add items to this order to print documents" placement="top">
          <DisabledPrintButton type="default" onClick={noop}>
            Print
          </DisabledPrintButton>
        </Tooltip>
      ) : (
        <Dropdown disabled={menuProps?.items?.length === 0} trigger={['click']} menu={menuProps}>
          <PrintButton type="primary">
            Print
          </PrintButton>
        </Dropdown>
      )}
    </>
  );
};

export default PrintDocumentButton;
