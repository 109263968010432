import React from 'react';
import { sortBy } from 'lodash';
import { IConsumptionOrder } from 'shared/types/order';
import { SalesOrderTable } from '../../../styledComponents';
import {
  SALES_ORDER_COLUMN, RELEASE_DATE_COLUMN, DESCRIPTION_COLUMN, QUANTITY_REQUIRED_COLUMN, CUSTOMER_COLUMN,
} from './UsageColumns';

interface IComponent {
  records: IConsumptionOrder[];
  monthName: string;
}

const MonthUsageTable = ({ monthName, records }: IComponent) => {
  const columns = [
    SALES_ORDER_COLUMN,
    CUSTOMER_COLUMN,
    RELEASE_DATE_COLUMN,
    QUANTITY_REQUIRED_COLUMN,
    DESCRIPTION_COLUMN,
  ];
  const recordData = sortBy(records, (r) => r.salesOrder.releaseDate).map((r) => {
    const releaseDate = r.salesOrder.releaseDate;
    // @ts-ignore - firestore Timestamp already converted to Date
    const releaseMonth = (releaseDate.getMonth() + 1).toString();
    // @ts-ignore - firestore Timestamp already converted to Date
    const releaseDay = releaseDate.getDate().toString().padStart(2, '0');
    return {
      salesOrder: r.salesOrder,
      releaseDate: `${releaseMonth}/${releaseDay}`,
      quantityRequired: r.quantityNeeded,
      partDescription: r.forPart,
      released: r.salesOrder.runners && r.salesOrder.runners.length > 0,
    };
  });

  return (
    <SalesOrderTable
      key={`${monthName}-requirements-table`}
      rowKey="requirement"
      size="small"
      // @ts-ignore
      columns={columns}
      dataSource={recordData}
      pagination={false}
    />
  );
};

export default MonthUsageTable;
