import React from 'react';
import { useRecoilValue } from 'recoil';
import { find, includes } from 'lodash';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { IRunner, IShopOrder } from 'pages/Orders/types';
import { openOrdersDataAtom, ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { IBomItem, IInventoryPart, IOrderItem } from 'shared/types/dbRecords';
import useFirebase from 'vendor/Firebase';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from '../../../shared/state/inventoryState';

const ComponentButton = styled(Button)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const FlushMaterialConsumptionButton = () => {
  const { firestore } = useFirebase();
  const openOrders = useRecoilValue(openOrdersDataAtom);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const inventory = useRecoilValue(inventoryItemsAtom);
  const inventoryItemsDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const onClick = (e: any) => {
    const productionOrders = openOrders.filter((o: IShopOrder) => o.runners);
    const flushParts = {};

    let processed = 0;
    productionOrders.forEach((order) => {
      const newRunners = order.runners.map((r: IRunner) => ({
        ...r,
        parts: r.parts.map((p: IOrderItem) => ({
          ...p,
          bom: p.bom.map((b: IBomItem) => {
            if (includes(Object.keys(flushParts), b.partNumber)) {
              flushParts[b.partNumber] += b.totalQuantity;
            } else {
              flushParts[b.partNumber] = b.totalQuantity;
            }
            return {
              ...b,
              quantityConsumed: 0,
            };
          }),
        })),
      }));
      firestore.collection(ordersDbString).doc(order.id).update({ runners: newRunners }).then(() => {
        processed += 1;
        if (processed === productionOrders.length) {
          processed = 0;
          Object.entries(flushParts).forEach((pair) => {
            const [partNumber, quantityToRestore] = pair;
            const inventoryItem = find(inventory, (i: IInventoryPart) => i.Sku === partNumber);
            if (!inventoryItem) {
              processed += 1;
            } else {
              // @ts-ignore because we know quantityToRestore is a number
              firestore.collection(inventoryItemsDbString).doc(inventoryItem.uniqueID).update({ quantityOnHand: inventoryItem.quantityOnHand + quantityToRestore }).then(() => {
                processed += 1;
                if (processed === Object.entries(flushParts).length) {
                  Modal.info({
                    title: 'Operation complete',
                    content: 'BOM consumption has been flushed!',
                  });
                }
              });
            }
          });
        }
      });
    });
  };

  return (
    <ComponentButton type="default" onClick={onClick}>Flush BOM Consumption</ComponentButton>
  );
};

export default FlushMaterialConsumptionButton;
