import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  definedParts,
  PART_DATA_ATOM,
} from 'shared/data';
import { ConfigurationCol, ConfigurationRow } from 'shared/pageElements/styledComponents';
import {
  hideUnselectedOptionsAtom, partEditModeAtom, customerPartAtom,
} from 'shared/state/pricingState';
import { IPartWood } from 'shared/types/pricingTool';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import {
  find, findIndex, includes, sortBy,
} from 'lodash';
import { MenuSelect } from '../../styledComponents';
import { inventoryItemsAtom } from '../../../../shared/state/inventoryState';
import { IBomItem, IInventoryPart } from '../../../../shared/types/dbRecords';
import { partBomItemsAtom } from '../../../../shared/state/partViewState';

const _ = require('lodash');

const SelectOption = styled(Select.Option)`
    height: 48px;
`;
const SelectOptionContent = styled(FlexColumn)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 48px;
  padding-right: 8px;
  border-bottom: 1px solid lightgrey;
  gap: 2px;
  & p {
    display: inline-block;
    margin: 0;
      &:first-child {
        font-weight: bold;
      }
      &:last-child {
        line-height: 8px;
      }
  }
`;

const Selector = styled(MenuSelect)`
    & .ant-select-selector {
        height: 48px !important;
    }
`;

interface ComponentInterface {
  partType: 'body'|'neck';
  prompt: string;
  woodCollection: { types: string[], items: IPartWood[], key: string, label: string };
  woodType: 'bodyWood'|'topWood'|'neckWood'|'fretboardWood';
}

interface IMenuItem {
  id: string;
  label: string;
  partNumber: { L: number, M: number };
}

export default ({
  partType, woodCollection, woodType, prompt,
}: ComponentInterface) => {
  // const newPartAtom = partType === 'body' ? newBodyAtom : newNeckAtom;
  // @ts-ignore
  const [newPartState, setNewPartState] = useRecoilState(customerPartAtom);
  const editMode = useRecoilValue(partEditModeAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const [woodGroups, setWoodGroups] = useState({});
  const [currentWoodTypeId, setCurrentWoodTypeId] = useState('');
  const [bomItems, setBomItems] = useRecoilState(partBomItemsAtom);

  useEffect(() => {
    const groups = _.groupBy(woodCollection.items.filter((i: IPartWood) => i), (i: IPartWood) => i.type);
    setWoodGroups(groups);
    setCurrentWoodTypeId(newPartState[woodType].id);
  }, [newPartState.archetype]);

  // @ts-ignore
  const woodSet = useRecoilValue(PART_DATA_ATOM[partType])[woodType];

  const onClickHandler = (e: any): void => {
    const wood = _.find(definedParts(woodSet.items), (w: { id: string, label: string }) => w.id === e) || {} as IPartWood;
    setNewPartState({
      ...newPartState,
      [woodType]: wood,
    });
    setCurrentWoodTypeId(wood.id);
    /*
      The next section will look into the part's defined BOM—if there is a part in the BOM that matches the part type selected, e.g., BBK,
      HELM will try to replace it with the current, selected material. If the part doesn't exist in the BOM, HELM will try to add it.
     */
    // sometimes we use part numbers that are specified for M parts in the case of S parts
    // as defined in the database, if there is no S part, the part number will be 0 -- if that is the case, use the 'M' part number
    let partNumber = wood.partNumber ? wood.partNumber[newPartState.archetype.instrument] : null;
    if (partNumber === 0 && newPartState.archetype.instrument === 'S') partNumber = wood.partNumber.M;

    const inventoryPart = find(inventoryItems, (i: IInventoryPart) => i.Sku === partNumber.toString()) as IInventoryPart;
    if (partNumber && inventoryPart) {
      const _partType = inventoryPart.Description.split('_')[0];
      const bomPartIndex = findIndex(bomItems, (b: IBomItem) => b.Description.split('_')[0] === _partType);
      const newBomParts = [...bomItems];
      if (bomPartIndex > -1) {
        const bomPart = bomItems[bomPartIndex];
        newBomParts[bomPartIndex] = {
          ...bomPart,
          Sku: inventoryPart.Sku,
          Description: inventoryPart.Description,
          unitCost: inventoryPart.PurchaseCost,
          totalCost: inventoryPart.PurchaseCost * bomPart.quantity,
          unitPrice: inventoryPart.UnitPrice,
          totalPrice: inventoryPart.UnitPrice * bomPart.quantity,
          uniqueID: inventoryPart.Id,
        };
        setBomItems(newBomParts);
      } else { // if the index of the bom item is -1, e.g., not in the BOM, add it to the BOM
        const stepId = includes(['GNB', 'BNB', 'GFB', 'BFB'], inventoryPart.Description.split('_')[0]) ? 'Q9ANbIKcC' : 't0FS3ARf0';
        const newBomPart = {
          isPurchased: true,
          totalQuantity: 1,
          Sku: inventoryPart.Sku,
          stepId,
          quantityConsumed: 0,
          vendor: inventoryPart.PrefVendorRef?.name || '',
          Description: inventoryPart.Description,
          totalCost: inventoryPart.PurchaseCost,
          lastModDate: inventoryPart.MetaData.LastUpdatedTime,
          unit: 'EA',
          uniqueID: inventoryPart.Id,
          unitCost: inventoryPart.PurchaseCost,
          totalPrice: inventoryPart.UnitPrice,
          quantity: 1,
          unitPrice: inventoryPart.UnitPrice,
        };
        newBomParts[newBomParts.length] = newBomPart;
        setBomItems(sortBy(newBomParts, (b: IBomItem) => b.Sku));
      }
    }
  };

  return (
    <ConfigurationCol style={{ marginLeft: 0, marginTop: 0 }}>
      <ConfigurationRow style={{ marginLeft: 0, marginTop: 0, justifyContent: 'space-between' }}>
        <Selector disabled={!editMode} value={currentWoodTypeId || prompt} onChange={onClickHandler}>
          {Object.keys(woodGroups).map((key) => {
            const items = _.sortBy(woodGroups[key], (w: IPartWood) => w.label);
            return (
              <Select.OptGroup key={key} label={key}>
                {items.map((item: IPartWood) => {
                  let partSize = newPartState.archetype.instrument;
                  let label = item.label;
                  // allow us to elect to not buy every blank in small, but still price parts
                  if (partSize === 'S' && item.partNumber && !item.partNumber[partSize]) partSize = 'M';
                  const partNumber = item.partNumber ? item.partNumber[partSize] : 0;
                  const inventoryItem = find(inventoryItems, (i: IInventoryPart) => i.Sku === partNumber.toString());
                  if (inventoryItem) label = inventoryItem.Description;
                  return (
                    <SelectOption
                      key={item.id}
                      value={item.id}
                    >
                      <SelectOptionContent>
                        <p>{partNumber}</p>
                        <p>{label}</p>
                      </SelectOptionContent>
                    </SelectOption>
                  );
                })}
              </Select.OptGroup>
            );
          })}
        </Selector>
      </ConfigurationRow>
    </ConfigurationCol>
  );
};
