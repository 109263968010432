import React, { useState } from 'react';
import { Button } from 'antd';
import { useRecoilValue } from 'recoil';
import useFirebase from 'vendor/Firebase';
import { useLocation } from 'react-router-dom';

import {
  NoOrdersText,
  RunnerCustomer,
  RunnerButtonWrapper, RunnerManualSearchInput, RunnerManualSearchWrapper,
  RunnerOrder,
  RunnerOrderColumn, SearchInputLabel,
  RunnerSalesOrder, RunnerWrapper, ScannerName, ToppedBoundIndicator, RunnerCustomerOrderWrapper,
} from './styledComponents';
import { ORDERS_DB_COLLECTION } from '../../shared/state/orderState';
import { IRunner, IShopOrder } from '../Orders/types';

const _ = require('lodash');
const shortid = require('shortid');
const qs = require('qs');

export default () => {
  const location = useLocation();
  const { firestore } = useFirebase();
  const vh = window.innerHeight * 0.01;
  const { inverted } = qs.parse(location.search.replace('?', '')) || { inverted: false };

  const [currentOrder, setCurrentOrder] = useState<IShopOrder|null>(null);
  const [orderNumber, setOrderNumber] = useState('');
  const [noOrderFound, setNoOrderFound] = useState(false);
  const ordersDbCollection = useRecoilValue(ORDERS_DB_COLLECTION);

  const onOrderNumberChange = (e: any) => {
    setNoOrderFound(false);
    setOrderNumber(e.target.value);
  };

  const onSearch = (e: any) => {
    setCurrentOrder(null);
    firestore.collection(ordersDbCollection)
      .where('salesOrder', '==', orderNumber)
      .get()
      .then((snap: any) => {
        if (snap.empty) {
          setNoOrderFound(true);
        } else {
          let doc = null;
          snap.forEach((d: any) => { if (d) doc = d.data(); });
          if (doc) setCurrentOrder(doc as IShopOrder);
          else setNoOrderFound(true);
          // setCurrentOrder(doc.data() as IShopOrder);
        }
      });
  };

  const onRunnerClick = (runnerId: string, orderId: string) => (e: any) => {
    window.location.href = `https://admin.wildwoodmfg.com/router/edit?shopOrderId=${orderId}&runnerId=${runnerId}&scanner=true&inverted=${inverted}`;
  };

  return (
    <RunnerManualSearchWrapper>
      <ScannerName>Router Manual Entry</ScannerName>
      <SearchInputLabel>Sales order</SearchInputLabel>
      <RunnerManualSearchInput
        autoFocus
        onChange={onOrderNumberChange}
        onPressEnter={onSearch}
        value={orderNumber}
        placeholder="Sales order number, e.g., 100"
        size="large"
        inputMode="numeric"
      />
      <Button onClick={onSearch} type="primary">Search</Button>
      <RunnerButtonWrapper>
        {_.sortBy(currentOrder?.runners, (r: IRunner) => r.runnerNumber).map((runner: IRunner) => (
          <RunnerWrapper
            key={shortid.generate()}
            disabled={!!currentOrder?.completedDate}
            onClick={onRunnerClick(runner.id, currentOrder?.id || '')}
          >
            <RunnerOrderColumn topped={runner.toppedOrBound}>
              <RunnerCustomerOrderWrapper>
                <RunnerCustomer>{`${currentOrder?.customer.id}`}</RunnerCustomer>
                <RunnerSalesOrder>{currentOrder?.salesOrder}</RunnerSalesOrder>
              </RunnerCustomerOrderWrapper>
              <RunnerOrder>{`${runner.runnerNumber + 1}/${currentOrder?.runners.length}`}</RunnerOrder>
            </RunnerOrderColumn>
            {runner.toppedOrBound
                && <ToppedBoundIndicator><p>T</p></ToppedBoundIndicator>}
          </RunnerWrapper>
        ))}
        {noOrderFound
          && <NoOrdersText>{`No order found with SO #${orderNumber}`}</NoOrdersText>}
      </RunnerButtonWrapper>
    </RunnerManualSearchWrapper>
  );
};
