import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { find } from 'lodash';
import theme from 'shared/theme';
import {
  contactEditModeAtom,
  currentCustomerAtom,
  currentCustomerShippingAddressesAtom,
  CUSTOMER_SHIPPING_DB_COLLECTION, CUSTOMERS_DB_COLLECTION,
  selectedCustomerShippingAddressAtom,
} from 'shared/state/customerState';
import QBOAuth from 'shared/data/QBO/auth';
import QBOCustomer from 'shared/data/QBO/customer';
import { devLog } from 'shared/util/logging';
import useFirebase from 'vendor/Firebase';

const DeleteButton = styled(Button)`
  margin: 0 0 6px 0;
  padding: 2px 8px;
  background-color: ${theme.palette.error.D500} !important;
  border-color: ${theme.palette.error.D500} !important;
  border-radius: 8px;
  border: none;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${theme.palette.error.hue};
    border-color: transparent;
  }
`;

const DeleteShippingAddressButton = () => {
  const { firestore, database } = useFirebase();
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const [currentShippingAddresses, setCurrentShippingAddresses] = useRecoilState(currentCustomerShippingAddressesAtom);
  const [selectedAddress, setSelectedAddress] = useRecoilState(selectedCustomerShippingAddressAtom);
  const customerShippingDbCollection = useRecoilValue(CUSTOMER_SHIPPING_DB_COLLECTION);
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const setEditMode = useSetRecoilState(contactEditModeAtom);

  const onClick = (e: any) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Delete address?',
      content: 'Click OK to permanently delete this address. If this is the default address, the first address in the list will be assigned as default.',
      onOk: async () => {
        const newShippingAddresses = [...currentShippingAddresses.filter((c) => c.Id !== selectedAddress.Id)];
        if (selectedAddress.default) {
          newShippingAddresses[0] = { ...newShippingAddresses[0], default: true };
          devLog('DeleteContactButton', 54, 'Deleting contact and updating default');
          // await QBOAuth.refreshToken(database);
          const updatedCustomer = await QBOCustomer.updateDefaultShippingAddress(currentCustomer, newShippingAddresses[0]);
          devLog('DeleteContactButton', 54, 'Contact updated in QBO');
          await firestore.collection(customerDbString).doc(currentCustomer.id).update({ ...updatedCustomer });
          devLog('DeleteContactButton', 54, 'Contact updated in Firestore');
          setCurrentCustomer({ ...currentCustomer, ...updatedCustomer });
        }
        await firestore.collection(customerShippingDbCollection).doc(currentCustomer.id).update({ shippingAddresses: newShippingAddresses });
        setEditMode(false);
        setCurrentShippingAddresses(newShippingAddresses);
        setSelectedAddress(find(newShippingAddresses, (c) => c.default) ?? newShippingAddresses[0]);
      },
    });
  };

  return (
    <DeleteButton onClick={onClick}>Delete</DeleteButton>
  );
};

export default DeleteShippingAddressButton;
