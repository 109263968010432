import React, { useState, useEffect } from 'react';
import {
  Document, Font, Page, PDFViewer, StyleSheet, View, Text,
} from '@react-pdf/renderer';
import { useRecoilValue } from 'recoil';
import { IShipment } from 'shared/types/dbRecords';
import {
  currentShopOrderAtom,
  orderShipmentsAtom,
} from 'shared/state/orderState';
import useFirebase from 'vendor/Firebase';
import InvoiceContinuation from './Components/Invoice/InvoiceContinuation';
import PackingSlipLogisticsDetail from './Components/PackingSlip/PackingSlipLogisticsDetail';
import PackingSlipHeader from './Components/PackingSlip/PackingSlipHeader';
import ShippingBillingInfo from './Components/Invoice/BillingShippingInfo';
import { PackingSlipItem, PackingSlipItemHeader } from './Components/PackingSlip/PackingSlipItem';
import PackingSlipNotes from './Components/PackingSlip/PackingSlipNotes';

Font.register({
  family: 'Red Hat Display-300',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg.ttf',
});

Font.register({
  family: 'Red Hat Display',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-700',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-600',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

const HORIZONTAL_PADDING = 18;
const VERTICAL_PADDING = 36;

const style = StyleSheet.create({
  viewer: {
    width: '75vw',
    height: '100vh',
  },
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: VERTICAL_PADDING * 1.5,
    paddingLeft: HORIZONTAL_PADDING,
    paddingRight: HORIZONTAL_PADDING * 2,
    paddingBottom: VERTICAL_PADDING,
  },
  confirmationBody: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: -4,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 7,
    bottom: 24,
    left: 0,
    right: 24,
    textAlign: 'right',
    color: 'grey',
  },

});

interface IComponent {
  shipment: IShipment;
}

const PackSlipPDF = ({ shipment }: IComponent) => {
  const orderShipments = useRecoilValue(orderShipmentsAtom);
  const [_shipment, setShipment] = useState<IShipment>({} as IShipment);
  const currentShopOrder = useRecoilValue(currentShopOrderAtom);
  const { firestore } = useFirebase();

  useEffect(() => {
    if (shipment) setShipment(shipment);
    else {
      setShipment(orderShipments[0]);
      // firestore.collection('orders').doc(orderShipment.orderId).get().then((orderDoc) => {
      //   const custData = orderDoc.data().customer;
      //   setShipment({ ..._shipment, customer: { ..._shipment.customer, ShipAddr: custData.ShipAddr } });
      // });
    }
  }, [orderShipments]);

  return (
    <>
      {(!!_shipment.shippedItems && (
        <>
          <PDFViewer showToolbar style={style.viewer}>
            <Document title={`Invoice - ${_shipment.customer.id} ${_shipment.shipmentNumber}`}>
              <Page size="LETTER" style={style.page}>
                <PackingSlipHeader shipment={_shipment} />
                <View
                  fixed
                  render={({ pageNumber, subPageNumber }) => (
                    pageNumber > 1 && (
                      <InvoiceContinuation pageNumber={pageNumber} />
                    ))}
                />
                <View style={style.confirmationBody}>
                  <ShippingBillingInfo shipment={_shipment} isPackSlip />
                  <PackingSlipLogisticsDetail
                    orderNumber={currentShopOrder.salesOrder}
                    shipsVia={!_shipment.trackingNumber ? 'Customer arranged' : (_shipment.trackingNumber.match(/1Z/) ? 'UPS' : 'FedEx')}
                    poNumber={_shipment.purchaseOrder}
                  />
                  <PackingSlipNotes notes={_shipment.notes} />
                  <PackingSlipItemHeader />
                  {_shipment.shippedItems.filter((shipItem) => shipItem.quantityShipped > 0 || shipItem.quantityOpen > 0)
                    .map((shipItem, shipItemIndex) => (
                      <PackingSlipItem
                        first={shipItemIndex === 0}
                        last={shipItemIndex === _shipment.shippedItems.length - 1}
                        quantityOrdered={shipItem.quantityOrdered}
                        quantityShipped={shipItem.quantityShipped}
                        quantityCanceled={shipItem.quantityCanceled}
                        partNumber={shipItem.Sku}
                        description={shipItem.Description}
                        notes={shipItem.notes}
                      />
                    ))}
                </View>
                <Text
                  style={style.pageNumber}
                  render={({ pageNumber, totalPages }) => (
                    totalPages > 1 ? `Page ${pageNumber} of ${totalPages}` : ''
                  )}
                  fixed
                />
              </Page>
            </Document>
          </PDFViewer>
        </>
      ))}
    </>
  );
};

export default PackSlipPDF;
