import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { every, find } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FlexRow } from 'shared/containers/FlexContainer';
import { ItemDetailsColumnWrapper, ItemDetailsLabel, SearchSelect } from 'shared/styledComponents/inputs';
import { ICustomerRecord } from 'shared/types/dbRecords';
import { currentCustomerAtom, customersAtom } from '../../../shared/state/customerState';

interface IValueOption {
  label?: string;
  keycode: string;
  shade: boolean;
}
const PartDetailDropdownItemRow = styled(FlexRow)`
  width: 600px;
  height: 100%;
  justify-content: flex-start;
  padding: 0 12px;
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.03)' : 'transparent')};
  border-bottom: 1px solid lightgrey;
  & p {
    color: black;
    margin: 0;
    &:first-of-type {
      font-weight: bold;
      width: 120px;
    }
  }
  & span {
    width: 1px;
    height: 100%;
    margin: 0 8px;
    background-color: lightgrey;
  }
`;

const PricingCustomerSelect = styled(SearchSelect)`
    min-width: 144px;
`;

const SearchColumnWrapper = styled(ItemDetailsColumnWrapper)`
    height: 100%;
`;
interface IComponent {
  showLabel: boolean;
  style: any;
}
const OrderCustomerSearchSelect = ({ showLabel = true, style = {} }: IComponent) => {
  const [currentOptions, setCurrentOptions] = useState<{ label: Element, value: string}[]>([]);
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const customers = useRecoilValue(customersAtom);

  const updateCustomer = (customer: ICustomerRecord) => {
    localStorage.setItem('currentCustomerId', customer.id);
    setCurrentCustomer(customer);
  };
  const onChangeSearch = (value: unknown) => {
    const customer = (value as string).toString();
    const customerId = customer.slice(0, 5);
    const cust = find(customers, (c: ICustomerRecord) => c.id === customerId) as ICustomerRecord;
    updateCustomer(cust);
  };

  const onBlurSearch = (e: any) => {
    const customer = e.target.value;
    const cust = find(customers, (c: ICustomerRecord) => c.id.toLowerCase() === customer.toLowerCase()) as ICustomerRecord;
    if (cust) {
      updateCustomer(cust);
    }
  };
  const onSelect = (value: unknown, option: IValueOption) => {
    const customer = (value as string).toString();
    // customerId is a mashup of customer id and shortname
    // the first five characters are the partNumber.
    const [_id, _customerCode, _customerName] = customer.split('.');
    const cust = find(customers, (c: ICustomerRecord) => c.DisplayName === _customerCode || c.CompanyName === _customerName) as ICustomerRecord;
    updateCustomer(cust);
  };
  const PartDetailDropdownMenuItem = ({ keycode, shade, label = undefined }: IValueOption) => (
    <PartDetailDropdownItemRow key={`${keycode}-value-option`} id={`${keycode}-value-option`} shade={shade}>
      <p>{keycode}</p>
      <span />
      <p>{label}</p>
    </PartDetailDropdownItemRow>
  );
  useEffect(() => {
    // @ts-ignore
    const items = customers
      .filter((c) => c.Active)
      .map((c, index) => ({
        label: <PartDetailDropdownMenuItem label={c.CompanyName} keycode={c.id} shade={index % 2 === 1} />,
        value: `${c.id}.${c.DisplayName}.${c.CompanyName}`,
      }));
    // @ts-ignore
    setCurrentOptions(items);
    return () => { };
  }, []);
  return (
    <SearchColumnWrapper id="customer-search-select-item-details-wrapper" key="customer-search-select-item-details-wrapper" noMargin>
      {showLabel && (
        <ItemDetailsLabel key="customer-search-select-item-details-label">Customer</ItemDetailsLabel>
      )}
      <PricingCustomerSelect
        transparent={style.transparent || false}
        showSearch
        dropdownStyle={{ width: 600, minWidth: 600 }}
        // @ts-ignore
        onChange={onChangeSearch}
        onBlur={onBlurSearch}
        disabled={!!window.location.href.match(/edit/)}
        id="order-customer-search-dropdown"
        key="order-customer-item-search-dropdown"
        placeholder="Select a customer"
        // @ts-ignore
        onSelect={onSelect}
        options={currentOptions}
        value={currentCustomer.DisplayName}
        filterOption={(input, option) => {
          const terms = input.split(',').map((s) => s.trim());
          const matchString = option?.value;
          const matches = terms.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
          return every(matches, Boolean);
        }}
        filterSort={(optionA, optionB) =>
          (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())}
      />
    </SearchColumnWrapper>
  );
};

export default OrderCustomerSearchSelect;
