import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Button, Divider, Select,
} from 'antd';
import ReactHtmlParser from 'react-html-parser';
import {
  CloseCircleFilled, CloseCircleOutlined, CloseCircleTwoTone, CloseOutlined, DownOutlined,
} from '@ant-design/icons';
import { TimesCircle, Times } from '@styled-icons/fa-solid';
import { partViewerStateAtom, partViewerTypeAtom } from 'shared/state/partViewState';
import styled from 'styled-components';
import { FlexRow } from 'shared/containers/FlexContainer';
import theme from '../../../shared/theme';
import { rgba } from '../../../shared/palette/utils';

const shortid = require('shortid');

interface IPartAttributeSelector {
  stateKey: string;
  label: string;
  attributeValues: string[];
}

const AttributeSelectorDivider = styled(Divider)`
  & span {
    padding: 0;
  }
  margin-bottom: 0;
`;

const AttributeSelectorRow = styled(FlexRow)`
  width: 100%;
  height: 44px;
  padding-right: 12px;
  padding-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const AttributeSelect = styled(Select)`
  //min-width: 200px;
  flex-grow: 2;
  border-radius: 16px;
`;
const AttributeClearButton = styled(Button)`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background-color: ${theme.palette.neutral.transparent};
  
  &:active {
    background-color: ${theme.palette.neutral.transparent};

    & svg {
      color: ${theme.palette.primary.hue};
    }
  }
`;
const AttributeCloseIcon = styled(Times)`
  width: 12px;
  position: relative;
  top: -2px;
`;

export default ({ stateKey, label, attributeValues }: IPartAttributeSelector) => {
  const partViewerType = useRecoilValue(partViewerTypeAtom);
  const [partViewerState, setPartViewerState] = useRecoilState(partViewerStateAtom);

  const onChangeAttribute = (e: any) => {
    setPartViewerState({
      ...partViewerState,
      [partViewerType]: {
        ...partViewerState[partViewerType],
        [stateKey]: e,
      },
    });
  };
  const onClearAttribute = (e: any) => {
    setPartViewerState({
      ...partViewerState,
      [partViewerType]: {
        ...partViewerState[partViewerType],
        [stateKey]: '.*',
      },
    });
  };

  useEffect(() => { }, []);

  return (
    <>
      {(attributeValues.length > 1 || partViewerState[partViewerType][stateKey] !== '.*') && (
      <>
        <AttributeSelectorDivider orientation="left">{label}</AttributeSelectorDivider>
        <AttributeSelectorRow key={shortid.generate()}>
          <AttributeSelect placeholder={partViewerState[partViewerType][stateKey] === '.*' ? `Select ${label}` : partViewerState[partViewerType][stateKey]} key={`${label}-attr-select`} onChange={onChangeAttribute}>
            {attributeValues.map((attribute: string) => (
              <Select.Option id={attribute} value={attribute} />
            ))}
          </AttributeSelect>

          <AttributeClearButton
            disabled={partViewerState[partViewerType][stateKey] === '.*'}
            type="primary"
            key={shortid.generate()}
            onClick={onClearAttribute}
            icon={<AttributeCloseIcon />}
          />
        </AttributeSelectorRow>
      </>
      )}
    </>
  );
};
