import React from 'react';
import styled from 'styled-components';
import { Collapse, Divider, Drawer } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentRequirementRecordAtom, showPartReqDrawerAtom } from 'shared/state/jobRequirementsState';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { parseYearMonthTag } from 'shared/data/calendar';
import { sortBy } from 'lodash';
import UsagePanelHeader from './PartRequirementsDrawer/UsagePanelHeader';
import MonthUsageTable from './PartRequirementsDrawer/MonthUsageTable';

const { Panel } = Collapse;
const PartDrawer = styled(Drawer)`
  width: calc(100vw - 90px);
    left: 90px;
`;
const ComponentDataWrapper = styled(FlexColumn)`
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 80px);
    margin-left: 80px;
`;
const PartNumber = styled.h1`
    margin: 0;
`;
const PartDescription = styled.h3`
    margin: 0;
`;
const UsageWrapper = styled(FlexColumn)`
    width: calc(100% - 32px);
    align-items: flex-start;
    padding: 16px;
`;
const UsageHeader = styled.h2`
    margin: 16px 0;
`;
const UsageCollapse = styled(Collapse)`
    width: 100%;
`;
const UsagePanel = styled(Panel)`
    width: 100%;
`;

const PartRequirementDrawer = () => {
  const currentRequirementRecord = useRecoilValue(currentRequirementRecordAtom);
  const {
    category,
    consumption,
    currentQuantity,
    description,
    partNumber,
    quantityNeeded,
    vendor,
  } = currentRequirementRecord;
  const [showPartReqDrawer, setShowPartReqDrawer] = useRecoilState(showPartReqDrawerAtom);
  const onClose = (e: any) => {
    setShowPartReqDrawer(false);
  };

  return (
    <PartDrawer
      height="100%"
      open={showPartReqDrawer}
      onClose={onClose}
      placement="top"
      closable
    >
      <ComponentDataWrapper>
        <Divider style={{ alignItems: 'flex-end', textAlign: 'left' }} orientation="left">
          <PartNumber>{partNumber}</PartNumber>
          <PartDescription>{description}</PartDescription>
        </Divider>
        <UsageWrapper>
          <UsageHeader>Usage by Month</UsageHeader>
          <UsageCollapse accordion>
            {sortBy(Object.entries(consumption), (e) => e[0]).map((e) => {
              const [tag, consumptionData] = e;
              const monthName = parseYearMonthTag(tag).month;
              return (
                <UsagePanel key={`month-${monthName}`} header={<UsagePanelHeader name={monthName} totalQuantity={consumptionData.quantityConsumed} />}>
                  <MonthUsageTable records={consumptionData.salesOrders} />
                </UsagePanel>
              );
            })}
          </UsageCollapse>
        </UsageWrapper>
      </ComponentDataWrapper>
      <Collapse />
    </PartDrawer>
  );
};

export default PartRequirementDrawer;
