import React, { useState } from 'react';
import shortid from 'shortid';
import { Button, Progress } from 'antd';
import { compress } from 'image-conversion';
import { ncImageDataItemsAtom, ncReviewImageAtom } from 'shared/state/utilState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { Camera as CameraIcon } from '@styled-icons/fa-solid';
import 'react-html5-camera-photo/build/css/index.css';
import styled from 'styled-components';
import theme from 'shared/theme';
import { ProgressSpinner } from 'shared/pageElements/styledComponents';
import NCImageReviewer from 'pages/QualityAssurance/NonCompliance/Components/NCImageReviewer';
import { useRecoilState } from 'recoil';
import { devLog } from '../../../../shared/util/logging';
import useFirebase from '../../../../vendor/Firebase';
import { qaTicketImageEditModeAtom } from '../../../../shared/state/qualityAssuranceState';

const PhotoActionRow = styled(FlexRow)`
  justify-content: flex-start;
  margin: 8px 0 16px 0;
  gap: 16px;
`;

const ComponentButton = styled(Button)`
    border-radius: 8px;
  `;

const MiniProgressSpinner = styled(ProgressSpinner)`
  width: 24px;
  height: 24px;
  position: relative;
  border: 4px solid ${theme.palette.neutral[100]};
  border-top: 4px solid ${theme.palette.primary.hue};
  animation-duration: 0.75s;
`;

const CameraButtonIcon = styled(CameraIcon)`
  width: 20px;
  margin: 0 8px 4px 0;
`;

const ImageUploadButton = styled(Button)`
  border-radius: 8px;
`;
const ImageUploadInput = styled.input`
  width: 0;
    visibility: hidden;
`;

interface IComponent {
  callback: (imageData: any[]) => void;
}
const QATicketImageUploader = ({ callback }: IComponent) => {
  const { storage } = useFirebase();
  const [imageDataItems, setImageDataItems] = useState<any[]>([]);
  const [reviewPictures, setReviewPictures] = useState<boolean>(false);
  const [imageProcessing, setImageProcessing] = useState<boolean>(false);
  const [imageEditMode, setImageEditMode] = useRecoilState(qaTicketImageEditModeAtom);
  const [uploadPercentComplete, setUploadPercentComplete] = useState(0);

  const onReviewImages = (e: any) => {
    setReviewPictures(true);
  };

  const onEndReviewImages = (e: any) => {
    setReviewPictures(false);
  };

  const compressImage = async (image: any) => compress(image, 0.8);

  const uploadThenCallback = async (compressedImages: Blob[], imageUrls: string[] = []) => {
    try {
      const totalBytes = compressedImages.map((i) => i.size).reduce((a, b) => a + b, 0);
      let bytesProcessed = 0;
      const imagePercent = 50 / compressedImages.length;

      const uploadsPromises = compressedImages.map((image) => {
        const path = `/qa_images/${shortid.generate()}`;
        const imageRef = storage.ref(path);
        const upload = imageRef.put(image);

        return new Promise<string>((resolve, reject) => {
          upload.on('state_changed',
            (s) => {
              bytesProcessed += s.bytesTransferred;
              const uploadPercent = ((bytesProcessed * 0.95) / totalBytes) * 100;
              setUploadPercentComplete(Math.round(uploadPercent));
            },
            (error) => {
              console.log(error.message);
              reject(error);
            },
            () => {
              upload.snapshot.ref.getDownloadURL().then((urlPath) => resolve(urlPath));
            });
        });
      });

      // Use Promise.all to wait for all uploads to finish
      const urls = await Promise.all(uploadsPromises);

      setImageDataItems([]);
      callback([...imageUrls, ...urls]);
    } catch (error) {
      console.error(`Error in image upload: ${error}`);
    }
  };

  //   const uploadThenCallback = (compressedImages: Blob[], imageUrls: string[] = []) => {
  //   if (compressedImages.length === imageUrls.length) {
  //     setImageDataItems([]);
  //     return callback(imageUrls);
  //   }
  //
  //   const path = `/qa_images/${shortid.generate()}`;
  //   const imageRef = storage.ref(path);
  //   const upload = imageRef.put(compressedImages[0]);
  //   const totalBytes = compressedImages.map((i) => i.size).reduce((a, b) => a + b, 0);
  //   let bytesProcessed = 0;
  //   const imagePercent = 50 / compressedImages.length;
  //   upload.on('state_changed',
  //     (s) => {
  //       bytesProcessed += s.bytesTransferred;
  //       const uploadPercent = ((bytesProcessed * 0.95) / totalBytes) * 100;
  //       setUploadPercentComplete(Math.round(uploadPercent));
  //     }, (error) => console.log(error.message),
  //     () => {
  //       upload.snapshot.ref.getDownloadURL().then((urlPath) =>
  //         uploadThenCallback(compressedImages, [...imageUrls, urlPath]));
  //     });
  // };
  const onUploadImage = async (e: any) => {
    setImageProcessing(true);
    const images = Object.keys(e.target.files).map((i) => (e.target.files[i]));
    const compressed = await Promise.all(images.map((i) => compressImage(i)));
    const complete = 0;
    setImageProcessing(false);
    setImageDataItems(compressed);
    uploadThenCallback(compressed);
  };

  const onUploadButtonClick = (e: any) => {
    e.preventDefault();
    document.getElementById('file-uploader')?.click();
  };

  const onToggleImageEditMode = (e: any) => {
    e.preventDefault();
    setImageEditMode(!imageEditMode);
  };

  return (
    <PhotoActionRow>
      <ImageUploadButton type="primary" onClick={onUploadButtonClick} icon={<CameraButtonIcon />}>Attach Image</ImageUploadButton>
      {imageProcessing && (
        <Progress percent={uploadPercentComplete} steps={imageDataItems.length * 4} />
      )}
      <ComponentButton onClick={onToggleImageEditMode}>{`${imageEditMode ? 'Done' : 'Edit'}`}</ComponentButton>
      <ImageUploadInput id="file-uploader" type="file" name="myImage" multiple onChange={onUploadImage} />
      {imageProcessing && (<MiniProgressSpinner />)}
    </PhotoActionRow>
  );
};

export default QATicketImageUploader;
