import React from 'react';
import {
  View, Text, StyleSheet,
} from '@react-pdf/renderer';

const style = StyleSheet.create({
  componentWrapper: {
    width: 150,
    height: 36,
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 80,
    right: 48,
  },
  timeLogSection: {
    height: '100%',
    width: '40%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: '1px solid black',
  },
  timeLogLabel: {
    fontFamily: 'Red Hat Display',
    fontSize: 10,
  },
});
const WorkOrderTimeLog = () => (
  <View style={style.componentWrapper}>
    <View style={style.timeLogSection}>
      <Text style={style.timeLogLabel}>Glue-up</Text>
    </View>
    <View style={style.timeLogSection}>
      <Text style={style.timeLogLabel}>Carve</Text>
    </View>
  </View>
);

export default WorkOrderTimeLog;
