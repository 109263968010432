import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { useRecoilValue } from 'recoil';
import { CSVLink } from 'react-csv';
import theme from 'shared/theme';
import {
  IPurchaseOrder,
} from 'shared/types/dbRecords';
import { purchaseOrdersAtom } from 'shared/state/purchaseOrderState';
import { dateFromMemo } from '../../../../../shared/data/QBO/purchaseOrder';

const DownloadButton = styled(CSVLink)`
  flexGrow: 2;
    height: ${theme.spacing(4)};
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
    
    border: 1px solid ${theme.palette.success.hue};
    border-radius: ${theme.spacing(1)};
    background-color: ${theme.palette.success.hue};
    color: ${theme.palette.neutral.white};
    font-size: 14px;
    
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  
    
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover {
        color: ${theme.palette.neutral.white};
        background-color: ${theme.palette.success[900]};
        border: 1px solid ${theme.palette.success[900]};
    }
  
`;
/**
 * Button component that is responsible for exporting inventory data as a csv file.
 */
const ExportPurchaseOrdersButton = () => {
  const purchaseOrders = useRecoilValue(purchaseOrdersAtom);
  // @ts-ignore
  const [csvData, setCsvData] = useState<any>([]);
  const csvHeaders = ['Status', 'PO Number', 'Description', 'VendorCode', 'Order Date', 'Due Date', 'Total'];

  useEffect(() => {
    const rowData = purchaseOrders.filter((p) => p).map((item: IPurchaseOrder) => {
      const dueDate = dateFromMemo(item.Memo);
      return (
        [
          item.POStatus,
          item.DocNumber,
          (item.PrivateNote || '').replace(/[",]/g, ' '),
          item.VendorRef.name,
          new Date(item.MetaData.CreateTime).toLocaleDateString(),
          new Date(dueDate).toLocaleString(),
          item.TotalAmt,
        ]);
    });

    setCsvData([csvHeaders, ...rowData]);
  }, []);

  return (
    <DownloadButton
      disabled={csvData.length === 0}
      key={shortid.generate()}
      filename={`Wildwood Purchase Order Export ${new Date().toLocaleString()}.csv`}
      data={csvData}
      onClick={() => {}}
    >
      Download
    </DownloadButton>
  );
};

export default ExportPurchaseOrdersButton;
