import shortid from 'shortid';
import React from 'react';
import styled from 'styled-components';
import { formatMargin, formatPrice } from 'shared/data';
import { SubdirectoryArrowRight } from '@styled-icons/material';
import { IInventoryPart, IQATicket } from 'shared/types/dbRecords';
import { CellCopy, ParentCellCopy } from 'shared/styledComponents/typographicElements';
import { FlexRow } from 'shared/containers/FlexContainer/styledComponents';
import { InactiveIndicator, SkuWrapper } from 'shared/styledComponents/utility';
import theme from 'shared/theme';
import { Tooltip } from 'antd';
import { toTitleCase } from '../../../../shared/text';
import ViewReportButton from './Buttons/ViewReportButton';

const ChildWrapper = styled(FlexRow)`
    justify-content: flex-start;
    margin-left: 8px;
`;
const ChildArrow = styled(SubdirectoryArrowRight)`
    width: 24px;
    margin-bottom: 6px;
    color: ${theme.palette.primary.hue};
`;
export const AverageCostCopy = styled(CellCopy)`
    color: ${theme.palette.primary.hue};
    font-weight: bold;
`;

export const mapOpenedBy = (email: string) => ({
  keithh: 'Keith',
  darrans: 'Darran',
  bruceh: 'Bruce',
  blaker: 'Blake',
  stevej: 'Steve',
  brianr: 'Brian',
}[email]);

const REPORT_NUMBER_COLUMN = {
  title: 'Ticket',
  dataIndex: 'TicketNumber',
  render: (text: string, record: IQATicket) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.id}</CellCopy>,
  }),
  width: '5%',
};

const TYPE_COLUMN = {
  title: 'Type',
  dataIndex: 'Type',
  render: (text: string, record: IQATicket) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{toTitleCase(record.type)}</CellCopy>,
  }),
  width: '5%',
};

const DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: 'Description',
  render: (text: string, record: IQATicket) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.title || record.nonConformanceDescription || record.nonConformanceExceptionCode}</CellCopy>,
  }),
  width: '60%',
};
const EXCEPTION_CODE = {
  title: 'Original Exception',
  dataIndex: 'ExceptionCode',
  render: (text: string, record: IQATicket) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.nonConformanceExceptionCode}</CellCopy>,
  }),
  width: '10%',
};
const DATE_OPENED = {
  title: 'Opened',
  dataIndex: 'DateOpened',
  render: (text: string, record: IQATicket) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.createdDate.toDate().toLocaleDateString()}</CellCopy>,
  }),
  width: '8%',
};
const OPENED_BY = {
  title: 'Opened by',
  dataIndex: 'OpenedBy',
  render: (text: string, record: IQATicket) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.createdBy}</CellCopy>,
  }),
  width: '8%',
};

const ACTION_COLUMN = {
  title: '',
  dataIndex: 'actionsColumn',
  render: (text: string, record: IQATicket) => ({
    children:
  <ViewReportButton qaTicket={record} />,
  }),
  width: '4%',
};
export {
  REPORT_NUMBER_COLUMN,
  TYPE_COLUMN,
  DATE_OPENED,
  OPENED_BY,
  DESCRIPTION_COLUMN,
  EXCEPTION_CODE,
  ACTION_COLUMN,
};
