import React from 'react';
import styled from 'styled-components';
import { Drawer } from 'antd';
import { useRecoilState } from 'recoil';
import { showDensityCalculatorAtom } from 'shared/state/pricingState';
import UniversalDensityCalculatorByPart from '../UniversalDensityCalculatorByPart';

const CalcDrawer = styled(Drawer)`
`;
interface IComponent {
  part?: string;
}
const DensityCalculatorDrawer = ({ part = undefined }: IComponent) => {
  const [showDensityCalculator, setShowDensityCalculator] = useRecoilState(showDensityCalculatorAtom);
  const closeCalc = (e: any) => {
    setShowDensityCalculator(false);
  };

  return (
    <CalcDrawer
      placement="right"
      width="95vw"
      open={showDensityCalculator}
      title="Density Calculator"
      onClose={closeCalc}
    >
      <UniversalDensityCalculatorByPart part={part} />
    </CalcDrawer>
  );
};

export default DensityCalculatorDrawer;
