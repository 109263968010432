import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useFirebase from 'vendor/Firebase';
import Loader from '../../../shared/components/Utility/Loader';
import {
  BODY_DATA_PATH,
  NECK_DATA_PATH,
  pricingExportDataAtom,
  useTestDataAtom,
} from '../../../shared/state/pricingState';

interface IRecordData {
  id: string;
  itemCategory: string;
  jbPartNumber: string;
  description: string;
  price: number;
  instrumentType: 'body'|'neck'|'GB'|'BB'|'GN'|'BN';
}

const TERMS = {
  accessoryOptions: 'Accessory Option',
  archetype: 'Model',
  bodyBlankModifications: 'Body Blank Modifier',
  bodyWood: 'Body Wood',
  constructionOptions: 'Construction Option',
  customerSpecificOptions: 'Customer-specific Option',
  dotOptions: 'Dot Options',
  finishingOptions: 'Finishing Option',
  fretboardWood: 'Fretboard Wood',
  frettingOptions: 'Fretting Option',
  handlingOptions: 'Handling Option',
  inlayOptions: 'Inlay Option',
  laborOptions: 'Labor Options (hourly rate)',
  neckWood: 'Neck Wood',
  salesOptions: 'Sales Option',
  topWood: 'Top Wood',
  trussRodOptions: 'Truss Rod Option',
  weightReductionOptions: 'Weight Reduction Options',
};

const keys = {
  body: {
    archetype: 'archetype',
    top: 'topWood',
    core: 'bodyWood',
    options: 'options',
  },
  neck: {
    archetype: 'archetype',
    top: 'fretboardWood',
    core: 'neckWood',
    options: 'options',
  },
};

export default () => {
  const { database } = useFirebase();
  const setPricingExportData = useSetRecoilState(pricingExportDataAtom);
  const bodyDataPath = useRecoilValue(BODY_DATA_PATH);
  const neckDataPath = useRecoilValue(NECK_DATA_PATH);
  const useTestData = useRecoilValue(useTestDataAtom);

  const resolvePartType = (term, type) => {
    if (type === 'body') {
      return term.match(/M/i) ? 'GB' : term.match(/L/i) ? 'BB' : 'GB/BB';
    } if (type === 'neck') {
      return term.match(/M/i) ? 'GN' : term.match(/L/i) ? 'BN' : 'GN/BN';
    }
  };

  useEffect(() => {
    database.ref(bodyDataPath).once('value').then((bodyData) => {
      database.ref(neckDataPath).once('value').then((neckData) => {
        const bodyPricingData = bodyData.val();
        const neckPricingData = neckData.val();

        const [bodyOut, neckOut] = [[bodyPricingData, 'body'], [neckPricingData, 'neck']].map((a) => {
          const [dataSet, partType] = a;
          const dataItems = [];
          dataSet[keys[partType].archetype].items.forEach((item) => {
            const {
              price, id, instrument, type,
            } = item;
            dataItems.push({
              jbPartNumber: '',
              id,
              itemCategory: TERMS.archetype,
              description: type.replace(/"/g, '""'),
              price,
              instrumentType: resolvePartType(instrument, partType),
            });
          });

          // core == neck core for necks, body core for bodies
          const core = keys[partType].core;
          // top == fb for necks, top for bodies
          const top = keys[partType].top;

          [core, top].forEach((key) => {
            dataSet[key].items.forEach((item) => {
              // if (!item.price) console.log(item);
              const { L, M } = item.partNumber;
              if (L) {
                dataItems.push({
                  jbPartNumber: L,
                  id: item.id,
                  itemCategory: TERMS[key],
                  description: item.label.replace(/"/g, '""'),
                  price: item.price.L,
                  instrumentType: resolvePartType('L', partType),
                });
              }
              if (M) {
                dataItems.push({
                  jbPartNumber: M,
                  id: item.id,
                  itemCategory: TERMS[key],
                  description: item.label.replace(/"/g, '""'),
                  price: item.price.M,
                  instrumentType: resolvePartType('M', partType),
                });
              }
            });
          });

          Object.keys(dataSet.options).forEach((key) => {
            dataSet.options[key].items.forEach((item) => {
              dataItems.push({
                jbPartNumber: '',
                id: item.id,
                itemCategory: TERMS[item.type],
                description: item.name,
                price: item.price,
                instrumentType: resolvePartType(item.name.match(/bass/i) ? 'bass' : 'any', partType),
              });
            });
          });
          return dataItems;
        });
        setPricingExportData([...bodyOut, ...neckOut]);
        // console.log(neckOut);
      });
    });
  }, [useTestData]);

  return (
    <Loader />
  );
};
