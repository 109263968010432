import React, { useEffect, useState } from 'react';
import { find, includes } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { Button } from 'antd';
import { Refresh, CheckCircle } from '@styled-icons/material-twotone';
import { FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import { PART_BOM_COLLECTION, PART_PRICING_COLLECTION, PART_VIEWER_COLLECTION, partConfigTermsAtom } from 'shared/state/partViewState';
import { bodyTotal, fetchPricing, formatPrice, neckTotal, updatePricing } from 'shared/data';
import { IOrderItem } from 'shared/types/dbRecords';
import { BODY_DATA_PATH, customerPartsAtom, NECK_DATA_PATH } from 'shared/state/pricingState';
import { currentCustomerAtom, customersAtom } from 'shared/state/customerState';
import { stripPartNotes } from 'shared/text';
import { configToDescription } from 'shared/partParser/util';

const ComponentWrapper = styled(FlexRow)`
  justify-content: flex-start;
`;

const RefreshIcon = styled(Refresh)`
  width: 24px;
  height: 24px;
  color: ${theme.palette.success.hue};
  transition: color 0.2s ease-in-out;
`;
const DoneIcon = styled(CheckCircle)`
  width: 22px;
  height: 22px;
  margin: 0 0 2px 6px;
  color: ${theme.palette.primary.hue};
`;
const ButtonText = styled.p`
  margin: 0 0 0 4px;
  font-size: 14px;
  line-height: 22px;
  transition: color 0.2s ease-in-out;
`;
const ComponentButton = styled(Button)`
  display: flex;
  border-radius: 8px;
  padding-right: 22px;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${theme.palette.primary.hue};
    & ${RefreshIcon} {
      color: ${theme.palette.neutral.white};
    }
    & ${ButtonText} {
      color: ${theme.palette.neutral.white};
    }
  }
`;

interface IComponent {
  customerId: string;
  orderItems: IOrderItem[];
  callback: (items: IOrderItem[]) => void;
  orderType: 'neck' | 'body';
}
const RefreshOrderItemsButton = ({ callback, customerId, orderType, orderItems }: IComponent) => {
  const { database, firestore } = useFirebase();
  const customers = useRecoilValue(customersAtom);
  const [_orderItems, setOrderItems] = useState<IOrderItem[]>(orderItems);
  const partBomCollectionDbString = useRecoilValue(PART_BOM_COLLECTION);
  const partViewerCollectionDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const partPricingCollectionDbString = useRecoilValue(PART_PRICING_COLLECTION);
  const bodyDataString = useRecoilValue(BODY_DATA_PATH);
  const neckDataString = useRecoilValue(NECK_DATA_PATH);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const [showWork, setShowWork] = useState(false);
  const partTypes = orderType === 'body' ? ['GB', 'BB'] : ['GN', 'BN'];

  const onClick = async (e: any) => {
    setShowWork(true);
    const [bodyData, neckData] = await Promise.all([bodyDataString, neckDataString].map((path) => database.ref(path).once('value').then((data) => data.val())));
    const pricingData = await fetchPricing(
      firestore,
      partViewerCollectionDbString,
      partPricingCollectionDbString,
      currentCustomer,
      configTerms,
      [bodyData, neckData],
      'sku'
    );

    const updatedItems: IOrderItem[] = [];
    const newItems: IOrderItem[] = [];
    _orderItems.forEach((o: IOrderItem, index) => {
      const part = find(pricingData.filter((p) => p), (p: any) => p.Sku === o.Sku);
      if (!part) newItems.push(o);
      else {
        firestore.collection(partBomCollectionDbString).doc(part.Sku).get().then((doc) => {
          const bom = doc.exists ? doc.data().bom : [];
          // const data = doc.data() as any;
          // const totalFunc = includes(['GB', 'BB'], part.type) ? bodyTotal : neckTotal;
          // const total = totalFunc(part);
          updatedItems[index] = {
            ...o,
            Sku: part.Sku,
            Description: configToDescription(part.config),
            unitPrice: Math.round(part.price || part.unitPrice),
            bom,
            cncLoad: part.cncLoad || 'mama',
            volume: part.volume || '',
          };
          if (part.showNotesOnOrder) {
            const partNotes = part.showNotesOnOrder && part.notes ? part.notes : '';
            const newOrderItemNotes = `${partNotes}${part.oneTimeNote || ''}`.trim();
            const existingCustomNotes = stripPartNotes(o.notes, part?.notes || '');
            const notes = `${newOrderItemNotes}${existingCustomNotes ? `\n${existingCustomNotes}` : ''}`.trim();
            updatedItems[index].notes = notes;
          }
          if ((updatedItems.length + newItems.length) === orderItems.length) {
            setShowWork(false);
            callback([...updatedItems, ...newItems]);
          }
        });
      }
    });
  };

  useEffect(() => {
    setOrderItems(orderItems);
  }, [orderItems]);
  return (
    <ComponentWrapper key="refresh-jb-items-button-wrapper">
      <ComponentButton type="default" onClick={onClick} key="refresh-jb-items-button">
        <RefreshIcon key="refresh-jb-items-icon" />
        { showWork ? (
          <ButtonText key="refresh-jb-items-button-text-work">Working...</ButtonText>
        ) : (
          <ButtonText key="refresh-jb-items-button-text-refresh">Refresh</ButtonText>
        )}
      </ComponentButton>
    </ComponentWrapper>
  );
};

export default RefreshOrderItemsButton;
