import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { every, find, sortBy } from 'lodash';
import { useRecoilValue } from 'recoil';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import { Select } from 'antd';
import { FlexRow } from 'shared/containers/FlexContainer';
import { customerPartsAtom } from 'shared/state/pricingState';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { IInventoryPart } from 'shared/types/dbRecords';
import { configToDescription } from 'shared/partParser/util';

const SearchSelect = styled(Select)`
  width: 200px;
  margin-bottom: 0;
`;
interface IComponent {
  open: boolean;
  partNumber: string|undefined;
  changeCallback: (e: any) => void;
  inventoryItems?: IInventoryPart[];
  customerParts?: any[];
  showLabel?: boolean;
}
interface IValueOption {
  label?: string;
  keycode: string;
  shade: boolean;
}
const PartDetailDropdownItemRow = styled(FlexRow)`
  width: 600px;
  height: 100%;
  justify-content: flex-start;
  padding: 0 12px;
  background-color: ${(props: any) => (props.shade ? 'rgba(0, 0, 0, 0.03)' : 'transparent')};
  border-bottom: 1px solid lightgrey;
  & p {
    color: black;
    margin: 0;
    &:first-of-type {
      font-weight: bold;
      width: 120px;
    }
  }
  & span {
    width: 1px;
    height: 100%;
    margin: 0 8px;
    background-color: lightgrey;
  }
`;
const PartNumberSearchSelect = ({
  changeCallback, open, inventoryItems = undefined, customerParts = undefined, partNumber = undefined, showLabel = true,
}: IComponent) => {
  const [currentOptions, setCurrentOptions] = useState<{ label: Element, value: string}[]>([]);
  const [currentPartNumber, setCurrentPartNumber] = useState(partNumber?.length === 0 ? undefined : partNumber);
  const _customerParts = customerParts || useRecoilValue(customerPartsAtom);
  const _inventoryItems = inventoryItems || useRecoilValue(inventoryItemsAtom);

  const onChangeSearch = (part: unknown) => {
    const _part = (part as string).toString();
    let _partNumber = _part.slice(0, 11);
    if (_part.match(/^[0-9]{7}/)) _partNumber = _part.slice(0, 7);
    if (changeCallback) changeCallback(_partNumber);
  };

  const onBlurSearch = (e: any) => {
    const _partNumber = e.target.value;
    if (changeCallback) changeCallback(_partNumber);
  };
  const onSelect = (part: unknown, option: IValueOption) => {
    const _part = (part as string).toString();
    // partId is a mashup of partNumber, description, and productCode - this aids in searching
    // the first eleven characters are the partNumber.
    let _partNumber = _part.slice(0, 11);
    if (_part.match(/^[0-9]{7}/)) _partNumber = _part.slice(0, 7);
    setCurrentPartNumber(_partNumber);
    if (changeCallback) changeCallback(_partNumber);
  };
  const PartDetailDropdownMenuItem = ({ keycode, shade, label = undefined }: IValueOption) => (
    <PartDetailDropdownItemRow key={`${keycode}-value-option`} id={`${keycode}-value-option`} shade={shade}>
      <p>{keycode}</p>
      <span />
      <p>{label}</p>
    </PartDetailDropdownItemRow>
  );
  useEffect(() => {
    const custParts = sortBy(_customerParts.filter((p: any) => p.active), (i: any) => i.Sku);
    const inventoryParts = sortBy(_inventoryItems.filter((i) => i.Active), (i: any) => i.Sku);
    // @ts-ignore
    const items = [...custParts, ...inventoryParts]
      .map((i, index) => {
        const description = i.customer ? configToDescription(i.config) : i.Description;
        return {
          label: <PartDetailDropdownMenuItem label={description} keycode={i.Sku} shade={index % 2 === 1} />,
          value: `${i.Sku}${description}`,
        };
      });
    setCurrentOptions(items);
    setCurrentPartNumber(partNumber);
  }, [partNumber]);

  const optionFilter = (input: string, option: any) => {
    const terms = input.split(',').map((s) => s.trim());
    const matchString = option?.value;
    const matches = terms.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
    return every(matches, Boolean);
  };

  return (
    <ItemDetailsColumnWrapper key="part-description-item-details-wrapper">
      { showLabel && (
        <ItemDetailsLabel key="part-description-item-details-label">Part Number</ItemDetailsLabel>
      )}
      <SearchSelect
        showSearch
        dropdownStyle={{ width: 600, minWidth: 600 }}
      // @ts-ignore
        onChange={onChangeSearch}
        onBlur={onBlurSearch}
        disabled={!open}
        id="order-item-search-dropdown"
        placeholder="Find a part #"
      // @ts-ignore
        onSelect={onSelect}
        options={currentOptions}
        value={currentPartNumber}
        filterOption={optionFilter}
        filterSort={(optionA, optionB) =>
          (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default PartNumberSearchSelect;
