import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatPrice } from '../../../../shared/data';

interface IComponent {
  quantityOrdered: number;
  quantityShipped: number;
  quantityCanceled: number;
  partNumber: string;
  description: string;
  notes: string;
  first: boolean;
  last: boolean;
}

const HEADER_FONT_SIZE = 10;
const BODY_FONT_SIZE = 8;
const HORIZONTAL_PADDING = 24;

const style = StyleSheet.create({
  componentWrapper: {
    width: '95%',
    height: 'auto',
    minHeight: 28,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderBottom: '1px solid lightgrey',
    paddingBottom: 4,
    marginLeft: HORIZONTAL_PADDING,
    // gap: 4,
  },
  firstRow: {
    borderTop: '1px solid black',
  },
  lastRow: {
    borderBottom: '1px solid black',
  },
  headerComponentWrapper: {
    marginTop: 24,
    paddingBottom: 0,
  },
  quantityHeader: {
    width: 52,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  quantityWrapper: {
    height: '100%',
    alignItems: 'flex-start',
    paddingTop: 2,
    // borderLeft: '1px solid lightgrey',
  },
  firstQuantity: {
    // borderLeft: '1px solid black',
  },
  lastQuantity: {
    // borderRight: '1px solid black',
  },
  partNumberWrapper: {
    width: 96,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 4,
    marginRight: 6,
  },
  unitPriceWrapper: {
    width: 64,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  headerText: {
    fontFamily: 'Red Hat Display-700',
    fontSize: HEADER_FONT_SIZE,
  },
  bodyText: {
    // marginTop: 2,
    fontFamily: 'Red Hat Display',
    fontSize: BODY_FONT_SIZE,
  },
  partNumberText: {
    marginTop: 2,
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'bold',
  },
  // Description Column
  descriptionCol: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 480,
  },
  description: {
    margin: 0,
    marginTop: 2,
    fontFamily: 'Red Hat Display',
    fontWeight: 500,
    fontSize: BODY_FONT_SIZE,
  },
  notes: {
    fontFamily: 'Red Hat Display',
    fontWeight: 300,
    fontSize: BODY_FONT_SIZE,
    margin: 0,
    marginLeft: 4,
    marginTop: 2,
    color: '#666666',
  },
});

const PackingSlipItemHeader = () => (
  <View fixed style={[style.componentWrapper, style.headerComponentWrapper]}>
    <View style={style.partNumberWrapper}>
      <Text style={style.headerText}>Model</Text>
    </View>
    <View style={style.descriptionCol} />
    <View style={style.quantityHeader}>
      <Text style={style.headerText}>Qty</Text>
    </View>
    <View style={style.quantityHeader}>
      <Text style={style.headerText}>Ship</Text>
    </View>
    <View style={style.quantityHeader}>
      <Text style={style.headerText}>B.O.</Text>
    </View>
  </View>
);
const PackingSlipItem = ({
  first, last, quantityOrdered, quantityShipped, quantityCanceled, partNumber, description, notes,
}: IComponent) => {
  const rowStyle = [style.componentWrapper];
  if (first) rowStyle.push(style.firstRow);
  if (last) rowStyle.push(style.lastRow);
  return (
    <View wrap={false} style={rowStyle}>
      <View style={style.partNumberWrapper}>
        <Text style={[style.bodyText, style.partNumberText]}>{partNumber}</Text>
      </View>
      <View style={style.descriptionCol}>
        <Text wordBreak="break-all" style={style.description}>{description}</Text>
        <Text style={style.notes}>{notes}</Text>
      </View>
      <View style={[style.quantityHeader, style.quantityWrapper, style.firstQuantity]}>
        <Text style={style.bodyText}>{quantityOrdered}</Text>
      </View>
      <View style={[style.quantityHeader, style.quantityWrapper]}>
        <Text style={style.bodyText}>{quantityShipped}</Text>
      </View>
      <View style={[style.quantityHeader, style.quantityWrapper, style.lastQuantity]}>
        <Text style={style.bodyText}>{quantityOrdered - quantityShipped - quantityCanceled}</Text>
      </View>
    </View>
  );
};

export {
  PackingSlipItemHeader,
  PackingSlipItem,
};
