import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { rgba } from 'shared/palette/utils';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import theme from 'shared/theme';
import { currentCustomerAtom, customerEditedAtom } from 'shared/state/customerState';
import { Input } from 'antd';

const CustomerIdInput = styled(Input)`
  width: 100%;
  border: 1px solid lightgrey;
  
  &:focus {
    outline: none !important;
    border: 1px solid ${theme.palette.primary.hue} !important;
    box-shadow: 0 0 4px ${rgba(theme.palette.primary.hue, 0.6)};
  }
}
`;

const CustomerID = () => {
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const setEdited = useSetRecoilState(customerEditedAtom);

  const onIdChange = (e: any) => {
    e.preventDefault();
    const newId = e.target.value.length > 5 ? e.target.value.slice(0, 5) : e.target.value;
    setEdited(true);
    // @ts-ignore
    setCurrentCustomer({ ...currentCustomer, id: newId });
  };

  return (
    <ItemDetailsColumnWrapper key="cust-id-item-details-wrapper">
      <ItemDetailsLabel key="cust-id-item-details-label">Customer ID</ItemDetailsLabel>
      <CustomerIdInput
        key="customer-id-input"
        id="customer-id-input"
        placeholder="Five-character ID, e.g., NASHG"
        // @ts-ignore
        value={currentCustomer.id}
        onChange={onIdChange}
      />
    </ItemDetailsColumnWrapper>

  );
};
export default CustomerID;
