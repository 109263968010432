import shortid from 'shortid';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { findIndex, sortBy } from 'lodash';
import theme from 'shared/theme';
import styled from 'styled-components';
import { IClientRunner } from '../../../Orders/types';
import { FlexRow } from '../../../../shared/containers/FlexContainer';
import { RouterNote, RouterTDCopy } from '../../styledComponents';
import { monthByNumber } from '../../../../shared/data/calendar';
import { stageRedirect } from '../../../../shared/util';

const PartNumberRow = styled(FlexRow)`
    scroll-margin-top: 100px;
    justify-content: space-between;
`;
const DataRow = styled(FlexRow)`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const PartRecordButton = styled(Button)`
    border: none;
`;
interface IJobRequirement {
  partNumber: string;
  consumption: { [month: string]: { quantityConsumed: number } };
  description: string;
  currentQuantity: number;
  quantityNeeded: number;
}
export const PART_NUMBER_COLUMN = {
  title: 'Part #',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IJobRequirement, b: IJobRequirement) => {
      if (a.partNumber > b.partNumber) return 1;
      if (b.partNumber > a.partNumber) return -1;
      return 0;
    },
    multiple: 3,
  },
  render: (text: string, record: IJobRequirement) => {
    const onClick = (e: any) => {
      e.stopPropagation();
      stageRedirect(`/schedule/week#${record.partNumber}`);
      window.location.href = `/inventory/edit?partId=${record.partNumber}`;
    };
    return (
      <PartNumberRow id={record.partNumber} key={shortid.generate()}>
        <PartRecordButton type="ghost" key={shortid.generate()} onClick={onClick}>{record.partNumber}</PartRecordButton>
      </PartNumberRow>
    );
  },
  width: 100,
};

export const DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: shortid.generate(),
  render: (text: string, record: IJobRequirement) => (
    <FlexRow id={record.partNumber} key={shortid.generate()} align="center" justify="space-between">
      <RouterTDCopy key={shortid.generate()}>{record.description}</RouterTDCopy>
    </FlexRow>
  ),
  width: 400,
};

export const VENDOR_COLUMN = {
  title: 'Vendor',
  dataIndex: shortid.generate(),
  render: (text: string, record: IJobRequirement) => (
    <DataRow id={`${record.partNumber}-vendor`} key={shortid.generate()}>
      <RouterTDCopy>{record.vendor}</RouterTDCopy>
    </DataRow>
  ),
  width: 100,
};

export const QUANTITY_NEEDED_COLUMN = {
  title: 'Needed',
  dataIndex: shortid.generate(),
  render: (text: string, record: IJobRequirement) => (
    <DataRow id={record.partNumber} key={shortid.generate()}>
      <RouterTDCopy key={shortid.generate()}>{parseFloat(record.quantityNeeded.toFixed(2))}</RouterTDCopy>
    </DataRow>
  ),
  width: 50,
};

export const CURRENT_QUANTITY_COLUMN = {
  title: 'On hand',
  dataIndex: shortid.generate(),
  render: (text: string, record: IJobRequirement) => (
    <DataRow id={record.partNumber} key={shortid.generate()}>
      <RouterTDCopy key={shortid.generate()}>{record.currentQuantity}</RouterTDCopy>
    </DataRow>
  ),
  width: 50,
};

interface IRequirementDateObject {
  tag: string;
  year: string;
  month: string;
}
export const mapConsumptionColumns = (monthNames: IRequirementDateObject[]) => monthNames.map((month: IRequirementDateObject) => ({
  title: month.month,
  dataIndex: shortid.generate(),
  render: (text: string, record: IJobRequirement) => {
    let background = 'transparent';

    const consumptionData = sortBy(Object.entries(record.consumption), (e) => e[0]);
    let index = findIndex(consumptionData, (e) => e[0] === month.tag);
    if (index === -1) index = consumptionData.length - 1;
    const throughThisMonth = consumptionData.map((e) => e[1]).slice(0, index + 1);
    const quantityConsumed = throughThisMonth.map((m) => m.quantityConsumed).reduce((a, b) => a + b, 0);
    const quantityRemaining = record.currentQuantity - quantityConsumed;
    if (quantityRemaining <= 0) background = theme.palette.error[300];
    return (
      <FlexRow id={shortid.generate()} key={shortid.generate()} align="center" style={{ backgroundColor: background }}>
        <Tooltip title={`${parseFloat(quantityConsumed.toFixed(2))} needed in ${month.month}`}>
          <RouterTDCopy key={shortid.generate()}>{parseFloat(quantityRemaining.toFixed(2))}</RouterTDCopy>
        </Tooltip>
      </FlexRow>
    );
  },
  width: 50,
}));

export const CONSUMPTION_COLUMNS = (children: any[]) => {
  const childWidth = children[0]?.width || 50;
  return {
    title: 'Consumption',
    children,
    width: childWidth * children.length,
  };
};

const JobRequirementColumns = [
  PART_NUMBER_COLUMN,
  DESCRIPTION_COLUMN,
  CURRENT_QUANTITY_COLUMN,
];

export default JobRequirementColumns;
