import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Upload as UploadIcon } from '@styled-icons/fa-solid';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { materialScrapDataAtom } from 'shared/state/utilState';
import useFirebase from 'vendor/Firebase';
import moment from 'moment';
import { groupBy, uniq } from 'lodash';
import { OrderRangePicker } from '../../ProductionSchedule/styledComponents';
import { FlexColumn } from '../../../shared/containers/FlexContainer';
import {
  IRouterStep,
} from '../../ProductionSchedule/types';
import { routerStepsAtom } from '../../../shared/state/routingState';
import { INCRecord } from '../../../shared/types/dbRecords';
import { IRunner, IRunnerHistory, IShopOrder } from '../../Orders/types';

const UploadButton = styled(Button)`
  //margin: 48px;
  min-width: 240px;
  border-radius: 8px;
`;

interface InProcessRecord {
  order: string;
  inProcess: boolean;
}

const CloneMapUploader = () => {
  const { firestore } = useFirebase();

  const onUpload = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result
        .split('\r')
        .slice(1)
        .filter((d: string) => d.match(/KALAB/))
        .map((d: string) => d.trim().split(','));

      const parentGroups = groupBy(data, (d) => d[1].split('.')[0]);

      data.forEach((d) => {
        const [child, childSku] = d;

        firestore.collection('part-viewer-data').doc(child).get().then((doc) => {
          if (doc.exists) {
            firestore.collection('part-viewer-data').doc(doc.id).update({ childSku, parent: childSku.split('.')[0] }).then(() => {
              console.log(`Added ${doc.id} to ${childSku.split('.')[0]} as offspring`);
            });
          }
        });
      });

      Object.keys(parentGroups).forEach((k) => {
        const children = uniq(parentGroups[k].map((a) => a[0]));
        firestore.collection('part-viewer-data').doc(k).get().then((doc) => {
          if (doc.exists) {
            firestore.collection('part-viewer-data').doc(k).update({ childParts: children }).then(() => {
              console.log(`Updated ${k} with children`);
            });
          }
        });
      });
    };

    reader.readAsText(file);

    return false;
  };

  return (
    <FlexColumn align="center" style={{ gap: 8 }}>
      <Upload
        accept=".csv"
        showUploadList={false}
        beforeUpload={onUpload}
      >
        <UploadButton
          type="default"
          icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}
        >
          Upload Part Clone Data
        </UploadButton>
      </Upload>
    </FlexColumn>
  );
};

export default CloneMapUploader;
