import React from 'react';
import { includes, some } from 'lodash';
import styled from 'styled-components';
import { Input } from 'antd';
import { useRecoilState } from 'recoil';
import { Search } from '@styled-icons/material-twotone';
import theme from 'shared/theme';
import { FlexRow } from 'shared/containers/FlexContainer';
import { messageSearchQueryAtom } from 'shared/state/messageState';

const ComponentWrapper = styled(FlexRow)`
    width: calc(70% - 16px);
`;

const SearchIcon = styled(Search)`
  width: 32px;
  color: ${theme.palette.primary.hue};
`;

const MessageFilterInput = styled(Input)`
  & * {
    border-radius: 6px;
  }
  & input {
    width: 100%;
    height: 48px;
    flex-grow: 2;
    font-size: 16px;
    @media ${theme.device.laptopM} {
      width: calc(100% - 40px);
    }
  }
`;

const MessageFilter = () => {
  const [messageSearchQuery, setMessageSearchQuery] = useRecoilState(messageSearchQueryAtom);

  const onChangeSearch = (e: any) => {
    if (some('!@#$%^&*()+=`~[]{};:><?\\'.split(''), (c: string) => includes(e.target.value.split(''), c))) return;

    setMessageSearchQuery(e.target.value);
    sessionStorage.setItem('message.query.string', e.target.value);
  };

  return (
    <ComponentWrapper>
      {/* <SearchIcon /> */}
      <MessageFilterInput
        addonBefore={<SearchIcon />}
        key="message-search-query-field"
        value={messageSearchQuery}
        placeholder="Filter messages with comma-separated terms, e.g., non-conformance, bruce, neck"
        onChange={onChangeSearch}
      />
    </ComponentWrapper>
  );
};

export default MessageFilter;
