import { Modal } from 'antd';

export const QBO_ERROR_TYPES = {
  SERVER_ERROR: 'QBO_SERVER_ERROR',
  CREATE_ERROR: 'QBO_CREATE_ERROR',
  UPDATE_ERROR: 'QBO_UPDATE_ERROR',
  FETCH_ERROR: 'QBO_FETCH_ERROR',
  AUTH_ERROR: 'QBO_AUTH_ERROR',
  GENERIC_ERROR: 'ERR_BAD_RESPONSE',
} as const;

export type QBOErrorType = typeof QBO_ERROR_TYPES[keyof typeof QBO_ERROR_TYPES];

export class QBOError extends Error {
  constructor(
    public code: QBOErrorType,
    public message: string,
    // public originalError?: Error
  ) {
    super(type);
    this.name = 'QBOError';
  }
}

const ERROR_MESSAGES = {
  [QBO_ERROR_TYPES.SERVER_ERROR]: 'QuickBooks Online is experiencing technical difficulties',
  [QBO_ERROR_TYPES.CREATE_ERROR]: 'Unable to create record in QuickBooks Online',
  [QBO_ERROR_TYPES.UPDATE_ERROR]: 'Unable to update record in QuickBooks Online',
  [QBO_ERROR_TYPES.FETCH_ERROR]: 'Unable to fetch record from QuickBooks Online',
  [QBO_ERROR_TYPES.AUTH_ERROR]: 'Authentication error with QuickBooks Online',
  [QBO_ERROR_TYPES.GENERIC_ERROR]: 'Something went awry with this action. Try again?',
} as const;

export const handleQBOError = (error: QBOError, retryPossible: boolean = false): Promise<boolean> => {
  if (retryPossible) {
    return new Promise((resolve) => 
      Modal.confirm({
        title: 'QuickBooks Online Error',
        content: `${ERROR_MESSAGES[error.type] || 'An unknown error occurred'}. Would you like to try again?`,
        okText: 'Try Again',
        cancelText: 'Skip QBO',
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      }));
  }
  return new Promise((resolve) => {
    Modal.error({
      title: 'Error posting to Quickbooks',
      content: 'Something went wrong while posting to Quickbooks. Please try again. If the issue persists, please tell Keith.',
    });
  });
};
