import React from 'react';
import styled from 'styled-components';
import { Button, notification, Tooltip } from 'antd';
import { Info } from '@styled-icons/material-twotone';
import { stageRedirect } from '../../../../../shared/util';

const JumpButton = styled(Button)`
   border: ${(props: { border: boolean }) => (props.border ? '1px solid lightgrey' : 'none')};
`;

const JumpIcon = styled(Info)`
    width: 20px;
    margin-bottom: 4px;
`;

interface IComponent {
  orderId: string;
  border: boolean;
}
const OrderJumpButton = ({ orderId, border = false }: IComponent) => {
  const onClick = (e: any) => {
    e.stopPropagation();
    stageRedirect();
    window.location.href = `/orders/create?edit=true&orderId=${orderId}`;
  };
  return (
    <Tooltip placement="top" title="Jump to order">
      <JumpButton border={border} shape="circle" type="default" icon={<JumpIcon />} onClick={onClick} />
    </Tooltip>
  );
};

export default OrderJumpButton;
