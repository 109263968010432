import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { LeftOutlined } from '@ant-design/icons';
import { ListPriceLabel } from 'shared/pageElements/styledComponents';
import { formatPercent, formatPrice } from 'shared/data';
import {
  currentInventoryPartAtom,
  INVENTORY_ITEMS_COLLECTION,
  inventoryPartEditedAtom, showQuickEditDrawerAtom,
} from 'shared/state/inventoryState';
import { ItemDetailHeader } from 'shared/styledComponents/containers';
import { ItemDetailBackButton } from 'shared/styledComponents/inputs';
import { TotalPrice, PriceDiscount } from 'shared/styledComponents/typographicElements';
import useFirebase from 'vendor/Firebase';
import SaveInventoryRecordButton from './Buttons/SaveInventoryRecordButton';
import SimpleModal from '../../../shared/components/Utility/SimpleModal';
import { popRedirect } from '../../../shared/util';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from '../../../shared/state/routingState';
import ScopedComponent from '../../../shared/components/Utility/ScopedComponent';

export default () => {
  const partState = useRecoilValue(currentInventoryPartAtom);
  const quickEditMode = useRecoilValue(showQuickEditDrawerAtom);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const inventoryPartEdited = useRecoilValue(inventoryPartEditedAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const { firestore } = useFirebase();
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const redirect = () => {
    const redirectUrl = popRedirect();
    if (redirectUrl) window.location.href = redirectUrl;
    else window.location.href = `/inventory?partId=${partState.Sku}`;
  };
  const onBack = (e: any) => {
    if (inventoryPartEdited) {
      setShowModal(true);
    } else {
      redirect();
    }
    e.preventDefault();
  };

  const onSave = (e: any) => {
    firestore.collection(inventoryDbString).doc(partState.Sku).set(partState).then(() => {
      redirect();
    });
  };

  const onCancel = (e: any) => {
    setShowModal(false);
  };

  const onDiscard = (e: any) => {
    redirect();
  };

  useEffect(() => {
    setPrice(partState.UnitPrice);
    const margin = ((partState.UnitPrice || 0) - (partState.PurchaseCost || 0)) / (partState.UnitPrice || 1);
    setDiscount(margin);
  }, [partState.PurchaseCost, partState.UnitPrice]);

  return (
    <>
      <SimpleModal
        title="Save changes before leaving?"
        modalOpen={showModal}
        onCancel={onCancel}
        buttons={[
          {
            label: 'Discard', onClick: onDiscard, key: 'discard', type: 'default',
          },
          {
            label: 'Save', onClick: onSave, key: 'save', type: 'primary',
          },
        ]}
      />
      <ItemDetailHeader quickEditMode={quickEditMode}>
        <ItemDetailBackButton
          shape="circle"
          type="primary"
        // @ts-ignore
          icon={<LeftOutlined />}
          onClick={onBack}
        />
        <ScopedComponent whitelist={[...superAdminUsers.emails, ...adminUsers.emails]}>
          <SaveInventoryRecordButton />
        </ScopedComponent>
        <ListPriceLabel>Sell price</ListPriceLabel>
        <TotalPrice>{formatPrice(price)}</TotalPrice>
        <PriceDiscount>{`(${formatPercent(discount)} margin)`}</PriceDiscount>
      </ItemDetailHeader>
    </>
  );
};
