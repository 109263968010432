import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Document, Font, PDFViewer, StyleSheet,
} from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { QRCodeCanvas } from 'qrcode.react';
import { find, flatten, some } from 'lodash';
import WorkOrderSupportDocsPage from './Pages/WorkOrderSupportDocsPage';
import ShippingPage from './Pages/ShippingPage';
import WorkOrderPage from './Pages/WorkOrderPage';
import { orderItemsAtom } from '../../shared/state/orderState';
import {
  IRunner, IShopOrder, IWorkOrderItem,
} from '../Orders/types';
import { IBomItem, IOrderItem } from '../../shared/types/dbRecords';

const QRCODE = styled(QRCodeCanvas)`
  visibility: hidden;
  width: 0;
  height: 0;
  position: absolute;
  top: -50px;
`;

const style = StyleSheet.create({
  viewer: {
    width: '75vw',
    height: '100vh',
  },
});

Font.register({
  family: 'Red Hat Display',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-300',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg.ttf',
});

Font.register({
  family: 'Red Hat Display-700',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-600',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

interface IComponent {
  currentShopOrder: IShopOrder;
}
const WorkOrderPDF = ({ currentShopOrder }: IComponent) => {
  const [shopOrder, setShopOrder] = useState<IShopOrder>(currentShopOrder);
  const orderItems = useRecoilValue(orderItemsAtom);
  // @ts-ignore
  const orderDisplayItems = orderItems.filter((i: IOrderItem) => (!i.Sku.match(/^99/) && i.open));

  // @ts-ignore
  const allWorkOrderItems = flatten(shopOrder.runners.map((r: IRunner) => r.parts.map((p: IOrderItem) => ({ ...p, runnerId: r.id })))).filter((i: IOrderItem) => (!i.Sku.match(/^99/) && i.open)) as IWorkOrderItem[];

  useEffect(() => {
    setShopOrder(currentShopOrder);
  }, [currentShopOrder]);
  return (
    <>
      {(shopOrder && orderItems) && (
        <>
          {shopOrder.runners.map((r) => <QRCODE id={`qr-${r.id}`} value={`/router/edit?shopOrderId=${shopOrder.id}&runnerId=${r.id}`} />)}
          <PDFViewer showToolbar style={style.viewer}>
            <Document title={`Work Order - ${shopOrder.customer.id} 1${shopOrder.salesOrder.split('-')[0]}`}>
              {shopOrder.runners.map((r, index) => {
                const thisOrderItems = r.parts.map((p: IOrderItem) => ({ ...p, runnerId: r.id }));

                const orderBomItems = flatten(thisOrderItems
                  .map((i: IWorkOrderItem) => i.bom.map((b: IBomItem) => ({
                    Sku: b.Sku,
                    Description: b.Description,
                    pickQty: b.quantity * i.quantityAssigned,
                    forParts: [{ PartSku: i.Sku, PartCount: i.quantityAssigned * b.quantity }],
                  }))))
                  .reduce((accumulator, item) => {
                    // @ts-ignore
                    const existingItem = accumulator.find((a) => a.Sku === item.Sku);
                    if (existingItem) {
                      // @ts-ignore
                      existingItem.pickQty += Math.round(item.pickQty * 4) / 4;
                      // @ts-ignore
                      existingItem.forParts.push(...item.forParts);
                    } else {
                      accumulator.push({ ...item, pickQty: Math.round(item.pickQty * 4) / 4 });
                    }
                    return accumulator;
                  }, []);
                const orderContainsCS = some(thisOrderItems, (i: IWorkOrderItem) => i.Description.match(/CS/));

                return (
                  <>
                    <WorkOrderPage key={r.id} allOrderItems={allWorkOrderItems} thisOrderItems={thisOrderItems} orderDisplayItems={orderDisplayItems} shopOrder={shopOrder} runnerId={r.id} />
                    <WorkOrderSupportDocsPage shopOrder={shopOrder} runnerIndex={index} pickItems={orderBomItems} csParts={orderContainsCS} />
                  </>
                );
              })}
              <ShippingPage order={shopOrder} items={orderDisplayItems} />
            </Document>
          </PDFViewer>
        </>
      )}
    </>
  );
};

export default WorkOrderPDF;
