import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  currentSpreadLengthAtom, currentSpreadThickAtom,
  currentSpreadWidthAtom,
  currentWeightChartRowAtom,
  mapleTopAdjustedWeightAtom,
} from 'shared/state/utilState';
import { userSettingsAtom } from '../../../shared/state/siteState';

interface IComponent {
  isTargetWeight: boolean;
  density: string;
  ounceWeight: string;
  decimalWeight: number;
  onClick: (id: string) => (e: any) => void;
}

const TargetWeightWrapper = styled(FlexColumn)`
  height: 'auto';
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
`;

const TargetWeightText = styled.h2`
    display: none;
    @media ${theme.device.laptopL} {
        display: block;
    }
  //position: absolute;
  //z-index: 100;
  //top: -18px;
`;

const DensityAdjustmentText = styled.p`
  margin-top: -4px !important;
`;

const DensityChartWrapper = styled(FlexRow)`
  width: 90%;
  height: 80px;
  background-color: ${theme.palette.neutral.white};
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  border-bottom: 2px solid black;
  position: sticky;
  top: 0;
  z-index: 1000;
 
  & * {
    margin: 0;
    font-size: 20px;
  }
  
  @media ${theme.device.laptop} {
    width: 90%;
  }
`;
const OunceWeightColumn = styled(FlexColumn)`
  width: 120px;
  align-items: flex-start;
  & p {
    //font-family: "Red Hat Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
  }
  @media ${theme.device.tablet} {
    width: 90px;
  }
`;

const WeightRow = styled(FlexRow)`
    justify-content: flex-start;
    gap: 12px;
    
    & p {
        &:nth-of-type(2) {
            font-weight: 300;
        } 
    }
`;
const SpreadWeightColumnHeader = styled(OunceWeightColumn)`
  width: 120px;
  @media ${theme.device.tablet} {
    width: 120px;
  }
`;
const SpreadWeightColumn = styled(OunceWeightColumn)`
  align-items: flex-end;
`;
const DensityColumn = styled(SpreadWeightColumn)``;

export const DensityChartRow = ({
  isTargetWeight, density, ounceWeight, decimalWeight, onClick,
}: IComponent) => {
  const id = `${ounceWeight}-row`;
  const currentWeightChartRow = useRecoilValue(currentWeightChartRowAtom);
  const mapleTopAdjustedWeight = useRecoilValue(mapleTopAdjustedWeightAtom);
  const currentSpreadWidth = useRecoilValue(currentSpreadWidthAtom);
  const currentSpreadLength = useRecoilValue(currentSpreadLengthAtom);
  const currentSpreadThick = useRecoilValue(currentSpreadThickAtom);
  const userSettings = useRecoilValue(userSettingsAtom);

  const spreadWeight = (): string => (((currentSpreadThick * currentSpreadWidth * currentSpreadLength) / 144) * density).toFixed(2);

  const RowWrapper = styled(Button)`
      width: 90%;
      height: 48px;
      
      color: ${theme.palette.neutral.black};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      border: none;
      border-bottom: 1px solid lightgrey;
      background-color: ${currentWeightChartRow === id ? 'yellow' : 'unset'};
      padding: 0 12px;
      margin: 0;
    
      &:hover {
        color: ${theme.palette.neutral.black};
        background-color: ${currentWeightChartRow === id ? 'yellow' : 'unset'}; 
        border-bottom: 1px solid lightgrey;
      }

      &:nth-child(2n) {
        background-color: ${currentWeightChartRow === id ? 'yellow' : 'rgba(0, 0, 0, 0.03)'};
      }


    & * {
        margin: 0;
      }
    
    @media ${theme.device.tablet} {
        height: 60px;
      }
  `;

  return (
    <RowWrapper key={id} id={id} onClick={onClick(id)}>
      <OunceWeightColumn>
        <WeightRow>
          <p>{ounceWeight}</p>
          {userSettings.boolean.showDecimalWeights && (
            <p>{`(${decimalWeight.toFixed(3)})`}</p>
          )}
        </WeightRow>
      </OunceWeightColumn>
      {isTargetWeight && (
      <TargetWeightWrapper>
        <TargetWeightText>NOT TO EXCEED</TargetWeightText>
        {mapleTopAdjustedWeight && (
        <DensityAdjustmentText>density adjusted to account for thick maple top</DensityAdjustmentText>
        )}
      </TargetWeightWrapper>
      )}
      <FlexRow>
        <SpreadWeightColumn>
          <p>{spreadWeight()}</p>
        </SpreadWeightColumn>
        <DensityColumn>
          <p>{density}</p>
        </DensityColumn>
      </FlexRow>
    </RowWrapper>
  );
};

export const DensityChartHeader = ({ showSpreadWeight }: { showSpreadWeight: boolean }) => (
  <DensityChartWrapper>
    <OunceWeightColumn>
      <h4>Weight</h4>
    </OunceWeightColumn>
    <FlexRow>
      <SpreadWeightColumnHeader>
        <h4>Spread (lbs)</h4>
      </SpreadWeightColumnHeader>
      <DensityColumn>
        <h4>Density</h4>
      </DensityColumn>
    </FlexRow>
  </DensityChartWrapper>
);
