import {
  IRouterStep,
} from 'pages/ProductionSchedule/types';
import { includes } from 'lodash';
import { numberWithCommas, toHash } from '../text';
import { DRY_ROOM_STEP, FINISHING_STEPS } from '../data';
import {
  dateString, daysAtStep, shipDelta, expectedShipDate, workingDaysBefore, isSameDay, yearWeeks, findJobWeek,
} from '../data/calendar';
import {
  IClientRunner, IRunner, IRunnerHistory, IShopOrder,
} from '../../pages/Orders/types';
import { IOrderItem } from '../types/dbRecords';

const _ = require('lodash');

// const YEAR_WEEKS = yearWeeks();

export const WIP_TYPE = {
  FLAT: 'flat',
  FACTOR: 'factor',
};

const rewindRunner = (r: IRunner, rewindDate: Date) => {
  const history = r.history.filter((h) => h.dateEntered.toDate() <= rewindDate);
  if (!history.length) return null;

  return {
    ...r,
    history,
    step: history[history.length - 1].step,
  };
};

export const basicClientRunner = (routerSteps: IRouterStep[], o: IShopOrder, r: IRunner, time: Date) => {
  const today = new Date();
  const _r = (!isSameDay(time, today) && time < today) ? rewindRunner(r, time) : r;
  if (!_r) return null;

  const step = _.find(routerSteps, (rs: IRouterStep) => rs.id === _r.step);
  const stepName = step.subName ? `${step.name} - ${step.subName}` : step.name;
  const toppedOrBound = !!r.parts.filter((p: IOrderItem) => p.Description.match(/bind/i)).length;
  const finishing = !r.parts.filter((p: IOrderItem) => p.Description.match(/CNC/)).length;

  // if (!o.shipDateHistory[0]) console.log(o);
  const clientRunner = {
    ..._r,
    finishing,
    orderId: o.id,
    completedDate: o.completedDate?.toDate() as Date,
    customer: o.customer.shortName,
    description: _r.description || o.description || '',
    shipDate: o.shipDate,
    originalShipDate: (o.shipDateHistory[0] || o.shipDate).toDate(),
    outsideFinishRequired: o.outsideFinishRequired,
    salesOrder: o.salesOrder,
    runnersOnOrder: o.runners.length,
    stepHash: toHash(stepName),
    toppedOrBound,
    type: o.type,
    weightReduction: o.weightReduction,
  };

  return clientRunner;
};

export const generalRunner = (routerSteps: IRouterStep[], clientRunner: IClientRunner, time: Date) => {
  const step = _.find(routerSteps, (rs: IRouterStep) => rs.id === clientRunner.step);

  const [, expectedShip, shippingDelta] = expectedShipDate(routerSteps, clientRunner);
  const releaseDays = clientRunner.type.match(/body/i) ? 5 : 11;
  const releaseDate = workingDaysBefore(clientRunner.shipDate.toDate(), releaseDays, clientRunner.type, clientRunner.toppedOrBound, clientRunner.finishing, clientRunner.ihs, clientRunner.outsideFinishRequired || false);
  releaseDate.setHours(0, 0, 0, 0);
  const shipDate = clientRunner.shipDate.toDate();
  shipDate.setHours(0, 0, 0, 0);
  const subName = step.subName ? ` - ${step.subName}` : '';

  return {
    ...clientRunner,
    releaseDate,
    shipDelta: shippingDelta,
    runnerValue: `$${numberWithCommas(clientRunner.value || 0)}`,
    // @ts-ignore
    shipDate: `${shipDate.getMonth() + 1}/${shipDate.getDate()}`,
    shipDateValue: shipDate,
    actualShip: `${expectedShip.getMonth() + 1}/${expectedShip.getDate()}`,
    runnerNumber: `${clientRunner.runnerNumber + 1}/${clientRunner.runnersOnOrder}`,
    step: `${step.name}${subName}`,
    daysAtStep: daysAtStep(_.last(clientRunner.history), time, !includes(['7kN0pSmXBw3', 'aaKxatWdehe'], step.id)), // exclude weekends when counting days in between, unless it's a step that requires a two-day wait
  };
};

export const neckRunner = (routerSteps: IRouterStep[], clientRunner: IClientRunner, time: Date) => {
  const data = generalRunner(routerSteps, clientRunner, time);

  let daysRemaining = 2;
  const inDryRoom = clientRunner.step === DRY_ROOM_STEP;
  const [, expectedShip] = expectedShipDate(routerSteps, clientRunner);

  if (inDryRoom) {
    // is in dry room
    const dateEntered = data.history[data.history.length - 1].dateEntered.toDate();
    dateEntered.setHours(0, 0, 0);
    const today = new Date(time);
    today.setHours(0, 0, 0);

    // @ts-ignore
    // get the difference between when the neck went in to today in whole days
    // divide milliseconds by number of milliseconds in day
    const day = Math.round((today - dateEntered) / (1000 * 3600 * 24));
    daysRemaining = daysRemaining - day < 0 ? 0 : daysRemaining - day;

    const actualShip = daysRemaining === 0 ? new Date(expectedShip.setDate(expectedShip.getDate() - 1)) : expectedShip;
    const dryRoomData = {
      dryRoomDay: daysRemaining,
      // @ts-ignore
      actualShip: `${actualShip.getMonth() + 1}/${actualShip.getDate()}`,
    };

    return {
      ...data, ...dryRoomData,
    };
  }
  return data;
};

export const stepRunners = (type: 'body'|'neck', routerSteps: IRouterStep[], clientRunners: IClientRunner[]) => routerSteps.filter((r: IRouterStep) => r.type.match(new RegExp(type, 'i')))
  .filter((r: IRouterStep) => !r.name.match(/complete/i))
  .map((s) => {
    const stepName = s.subName ? `${s.name} - ${s.subName}` : s.name;
    return ({
      type: s.type,
      step: stepName,
      stepId: s.id,
      wipCalc: s.wipValue,
      runners: clientRunners.filter((r: any) => r.step === stepName) as IClientRunner[],
    });
  });

const getMaxDateForStep = (runners: IRunner[], step: string) => {
  if (!runners) return null;

  const stepHistory = runners.map((r: IRunner) => {
    const historyStep = _.find(r.history, (d: IRunnerHistory) => d.step === step) as IRunnerHistory;
    return historyStep ? historyStep.dateEntered.toDate() : null;
  }).filter((i: any) => i);

  if (!!stepHistory || (stepHistory.length > 0 && stepHistory.length < runners.length)) return new Date(3030, 0, 0, -8, 0, 0, 0);

  return (stepHistory && stepHistory.length) ? _.max(stepHistory) : null;
};

export const salesRecord = (routerSteps: IRouterStep[], order: IShopOrder, id: string) => {
  let releaseDaysAhead = 5;
  if (order.type.match(/neck/i)) releaseDaysAhead += 6;

  let shipDeltaStatement = '';
  if (order.completedDate) {
    const shipDate = order.shipDateHistory[0] || order.shipDate;
    const shipDeltaFromPlanned = order.completed ? Math.round((shipDate.toMillis() - order.completedDate.toMillis()) / 86400000) : 0;
    if (order.completed) shipDeltaStatement = shipDeltaFromPlanned === 0 ? 'On schedule' : `${shipDeltaFromPlanned} day${Math.abs(shipDeltaFromPlanned) === 1 ? '' : 's'} ${shipDeltaFromPlanned > 0 ? 'ahead of' : 'behind'} initial scheduled date`;
  }
  const orderDate = order.orderDate ? order.orderDate.toDate() : new Date(2020, 11, 21);

  const record = {
    ...order,
    recordId: id,
    customer: order.customer,
    customerId: order.customer.id,
    releaseDate: order.releaseDate?.toDate(),
    orderDate,
    nonConformanceHistory: order.nonConformanceHistory,
    dates: {
      orderDate: dateString(orderDate, false, true),
      shipDate: dateString(order.shipDate.toDate(), true),
      releaseDate: dateString((order.releaseDate?.toDate() || new Date()), true),
      dryRoomDate: getMaxDateForStep(order.runners, DRY_ROOM_STEP),
      finishingReceivedDate: getMaxDateForStep(order.runners, FINISHING_STEPS[3]),
    },
    shipDelta: shipDeltaStatement,
    neckCount: order.type.match(/neck/i) ? order.partCount : 0,
    bodyCount: order.type.match(/body/i) ? order.partCount : 0,
    onTime: true,
  };

  if (order.runners) {
    const runners = order.runners.map((r: IRunner) => {
      // @ts-ignore
      const shipsInDays = shipDelta(routerSteps, order.shipDate.toDate(), r);
      return {
        ...r,
        shipsInDays,
        shipDate: order.shipDate,
      };
    });

    record.runners = runners;
  }

  return record;
};
