import React, { useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import { FlexColumn } from 'shared/containers/FlexContainer';
import {
  currentRunnerOpenAtom, inProcessValueAtom, productionItemsAtom, queueItemsAtom, routerMachineTypeAtom,
  routerStepsAtom, timeMachineAtom,
} from 'shared/state/routingState';
import { numberWithCommas, toHash } from 'shared/text';
import ReactHtmlParser from 'react-html-parser';
import { scrollOptions, scrollToHash } from 'shared/scroll';
import {
  basicClientRunner, neckRunner, stepRunners,
} from 'shared/router/clientRunner';
import { runnerValue, stepRunnerData } from 'shared/router/utils';
import { useTestDataAtom } from 'shared/state/pricingState';
import { dateBefore } from 'shared/data/calendar';
import {
  RouterTableHeader,
  RouterTableHeaderDetail,
  RunnerTable, RouterTableSectionWrapper, PartCount, WholesaleValue, WIPValue, RouterTableHeaderRow,
} from './styledComponents';
import {
  baseNeckColumns, completedNeckColumns, dryRoomColumns, onHoldNeckColumns, readyNeckColumns,
} from './Components/DataColumns';
import Loader from '../../shared/components/Utility/Loader';
import { ORDERS_DB_COLLECTION } from '../../shared/state/orderState';
import {
  IClientRunner, IRunner, IShopOrder, IStepRunner,
} from '../Orders/types';
import { IBomItem, IOrderItem } from '../../shared/types/dbRecords';

const _ = require('lodash');
const shortid = require('shortid');
const qs = require('qs');

export default () => {
  const { firestore } = useFirebase();
  const location = useLocation();
  const { shopOrderId } = qs.parse(location.search.replace('?', ''));
  const routerSteps = useRecoilValue(routerStepsAtom);
  const [, setShopOrders] = useState<IShopOrder[]>([]);
  const setCurrentOpen = useSetRecoilState(currentRunnerOpenAtom);
  const [runners, setRunners] = useState<IStepRunner[]>([]);
  const setQueueItems = useSetRecoilState(queueItemsAtom);
  const setProductionItems = useSetRecoilState(productionItemsAtom);
  const setInProcessValue = useSetRecoilState(inProcessValueAtom);
  const routerMachineType = useRecoilValue(routerMachineTypeAtom);
  const timeMachine = useRecoilValue(timeMachineAtom);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const useTestData = useRecoilValue(useTestDataAtom);

  useEffect(() => {
    firestore.collection(ordersDbString)
      .where('type', '==', 'neck')
      .where('completed', '==', false)
      .get()
      .then((snap) => {
        const orders: IShopOrder[] = [];
        const today = new Date();
        snap.docs.forEach((doc) => {
          const d = doc.data();
          if (d.runners && (!d.completed || dateBefore(timeMachine, today))) {
            orders.push({
              ...d as IShopOrder,
              id: doc.id,
            });
          }
        });
        const clientRunners = _.flatten(orders.map((o: IShopOrder) => o.runners.map((r: IRunner) => {
          const clientRunner = basicClientRunner(routerSteps, o, r, timeMachine);
          if (r.completed || !clientRunner) return null;
          return neckRunner(routerSteps, clientRunner, timeMachine);
        }))).filter((r: IClientRunner) => r && (routerMachineType === 'All' || r.machine.match(new RegExp(routerMachineType, 'i'))));

        setQueueItems(clientRunners
          .filter((r: IRunner) => r.step.match(/(ready|release)/i))
          // @ts-ignore
          .map((r: IRunner) => r.partCount)
          .reduce((a: number, b: number) => a + b, 0));

        const prodNecks = clientRunners
          .filter((r: IRunner) => !r.step.match(/(complete|hold)/i));

        setProductionItems(prodNecks
          // @ts-ignore
          .map((r: IRunner) => r.partCount)
          .reduce((a: number, b: number) => a + b, 0));
        const _runners = stepRunners('neck', routerSteps, clientRunners);
        setRunners(_runners);
        setShopOrders(_.reverse(_.sortBy(orders, (o: IShopOrder) => o.dateCreated)));
        setInProcessValue(runnerValue(_runners));

        if (shopOrderId) {
          setCurrentOpen([shopOrderId]);
        }

        if (window.location.hash.length) {
          const options = {
            ...scrollOptions,
            align: {
              top: 0,
              topOffset: 364,
            },
          };
          scrollToHash(window.location.hash, options);
        }
      });
  }, [timeMachine, useTestData]);

  // @ts-ignore
  return (
    <>
      {runners.length === 0
          && (
          <>
            <FlexColumn style={{ width: '100%', height: '50vw' }}>
              <Loader />
            </FlexColumn>
          </>
          )}
      {/* eslint-disable-next-line no-shadow */}
      {runners.map((item: IStepRunner) => {
        const { partCount, value, wipValue } = stepRunnerData(item);

        let columns: any[] = baseNeckColumns;
        if (item.step.match(/hold/i)) columns = onHoldNeckColumns;
        if (item.step.match(/^ready/i)) columns = readyNeckColumns;
        if (item.step.match(/dry room/i)) columns = dryRoomColumns;
        if (item.step.match(/complete/i)) columns = completedNeckColumns;

        return (
          <>
            <RouterTableSectionWrapper>
              <RouterTableHeader
                id={toHash(item.step)}
                key={shortid.generate()}
              >
                {item.step}
              </RouterTableHeader>
              <RouterTableHeaderRow>
                <PartCount>
                  <span>{partCount}</span>
                  <span>necks </span>
                </PartCount>
                <WholesaleValue>
                  <span>{`$${numberWithCommas(value)}`}</span>
                  <span>Wholesale</span>
                </WholesaleValue>
                {!Number.isNaN(wipValue) && (
                  <WIPValue>
                    <span>{`$${numberWithCommas(Math.round(wipValue))}`}</span>
                    <span>WIP</span>
                  </WIPValue>
                )}
              </RouterTableHeaderRow>
            </RouterTableSectionWrapper>
            <RunnerTable
              size="small"
              columns={columns}
              dataSource={item.runners}
              pagination={false}
              rowClassName={(record: any) => {
                if (!item.step.match(/hold/i)) {
                  if (record.shipDelta <= -5 && !item.step.match(/complete/i)) return 'router-ship-schedule-behind';
                  return 'router-ship-schedule-ontime';
                }
                return 'router-on-hold';
              }}
            />
          </>
        );
      })}
    </>
  );
};
