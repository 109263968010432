import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Divider, Dropdown, Menu, Modal,
} from 'antd';
import styled from 'styled-components';
import { CaretDown } from '@styled-icons/fa-solid';
import {
  every, omit, some,
} from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  clearRedirect, redirect, stageRedirect,
} from 'shared/util';
import shortid from 'shortid';
import firebase from 'firebase';
import {
  currentShipOrderAtom,
  currentShopOrderAtom, NEXT_ORDER_NUMBER_PATH,
  ORDER_ITEMS_DB_COLLECTION,
  orderItemsAtom,
  ORDERS_DB_COLLECTION, showShipOrderDrawerAtom,
} from 'shared/state/orderState';
import {
  currentCustomerAtom,
  CUSTOMERS_DB_COLLECTION,
  selectedCustomerShippingAddressAtom,
} from 'shared/state/customerState';
import { checkProdScheduleDates, releaseDate } from 'shared/util/order';
import { ICustomerRecord, IOrderItem } from 'shared/types/dbRecords';
import { runnerWorkOrdersAtom } from 'shared/state/routingState';
import { generateRunners } from 'shared/router/utils';
import { noFinishing, orderType } from 'shared/data/order';
import { isSameDay } from 'shared/data/calendar';
import { devLog } from 'shared/util/logging';
import useFirebase from 'vendor/Firebase';
import { IRunner, IShopOrder } from '../../../types';
import { MESSAGE_DB_PATH_ATOM, orderConfirmationNotificationUsersAtom } from '../../../../../shared/state/messageState';
import { AuthContext } from '../../../../../vendor/Firebase/AuthProvider';

interface IComponent {
  edit: boolean;
  returnView: string;
  currentOrder: IShopOrder;
}

const MenuDivider = styled(Divider)`
    margin: 8px 0;
`;
const SaveDocumentButton = ({ edit, returnView, currentOrder }: IComponent) => {
  const { currentUser } = useContext(AuthContext);
  const { firestore, database } = useFirebase();
  const [_order, setOrder] = useState(currentOrder);
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbCollection = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const customersDbCollection = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const workOrders = useRecoilValue(runnerWorkOrdersAtom);
  const customer = useRecoilValue(currentCustomerAtom);
  const nextOrderNumberPath = useRecoilValue(NEXT_ORDER_NUMBER_PATH);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const confirmationNotificationUsers = useRecoilValue(orderConfirmationNotificationUsersAtom);
  const orderItems = useRecoilValue(orderItemsAtom);
  const selectedShippingAddress = useRecoilValue(selectedCustomerShippingAddressAtom);
  const setShowShipDrawer = useSetRecoilState(showShipOrderDrawerAtom);
  const setCurrentShipOrder = useSetRecoilState(currentShipOrderAtom);

  interface IReturnType {
    order: IShopOrder;
    runners: IRunner[]|null;
  }
  const orderPreflight = async (includeRunners: boolean): Promise<IReturnType> => {
    const order = omit(_order, ['runners']);
    checkProdScheduleDates(order.shipDate);

    if (orderItems.filter((o: IOrderItem) => o).length > 0) {
      order.type = orderType(orderItems) === 0 ? 'body' : 'neck';
      // only calculate the order value and part count if the order is not a placeholder
      if (_order.salesOrder !== 'PH') {
        // order.orderValue = orderItems.filter((o: IOrderItem) => (_order.completed ? !o.open : o.open))
        order.orderValue = orderItems
          .filter((o: IOrderItem) => (_order.completed ? o.quantityShipped : o.quantityOpen) > 0)
          .map((o: IOrderItem) => (_order.completed ? o.quantityShipped : o.quantityOpen) * o.unitPrice)
          .reduce((a, b) => a + b, 0);
        // order.partCount = orderItems.filter((o: IOrderItem) => (_order.completed ? !o.open : o.open) && o.Sku.match(/[A-Z]{5}/))
        order.partCount = orderItems
          .filter((o: IOrderItem) => o.Sku.match(/[A-Z]{5}/) && (_order.completed ? o.quantityShipped : o.quantityOpen) > 0)
          .map((o: IOrderItem) => (_order.completed ? o.quantityShipped : o.quantityOpen))
          .reduce((a, b) => a + b, 0);
      } else {
        order.orderValue = _order.orderValue;
        order.partCount = _order.partCount;
      }
    }
    // auto-confirm order if all items on order are confirmed
    if (every((orderItems || []).filter((o) => o && o.Sku.match(/[A-Z]{5}/)).map((o) => o.materialsConfirmed), (t) => t)) {
      order.materialsConfirmed = firebase.firestore.Timestamp.fromDate(new Date());
      order.materialsConfirmedBy = currentUser.email;
    }
    order.ihs = some((orderItems || []).filter((o) => o).map((o) => o.houseSample));
    order.finishing = !noFinishing(orderItems);

    // set the appropriate release date for the order and its items
    order.releaseDate = releaseDate(order, orderItems);
    order.orderDate = _order.orderDate || firebase.firestore.Timestamp.fromDate(new Date());
    // if the order date has changed, update the ship date history list
    const orderHistoryDates = order.shipDateHistory.map((t: firebase.firestore.Timestamp) => t.toDate());
    if (every(orderHistoryDates, (t: Date) => !isSameDay(order.shipDate.toDate(), t))) order.shipDateHistory = [...order.shipDateHistory, order.shipDate];

    const orderCustomer = await firestore.collection(customersDbCollection).doc(_order.customer.DisplayName).get();
    order.customer = { ...orderCustomer.data() as ICustomerRecord, ShipAddr: _order.customer.ShipAddr };

    // if we are "splitting" the order, i.e., creating a copy with the intent to remove one or more work orders,
    // process the runners and return with the order.
    const runners = includeRunners ? generateRunners(_order, orderItems) as unknown as IRunner[] : null;

    order.outsideFinishRequired = currentOrder.outsideFinishRequired || false;

    // @ts-ignore
    return { order, runners };
  };

  // increments the order number in our realtime database if the order is being saved
  // and the value in the database is equal to the current order number.
  const incrementRealtimeDbOrderNumber = async (order: IShopOrder) => {
    const nextOrderNumberData = await database.ref(nextOrderNumberPath).once('value');
    const orderNumber = nextOrderNumberData.val();
    if (order.salesOrder.split('-')[0] === orderNumber?.toString()) {
      return database.ref(nextOrderNumberPath).set(parseInt(orderNumber, 10) + 1);
    }
  };

  const onSave = async (e: any) => {
    e.preventDefault();
    devLog('SaveDocumentButton', 38, 'Saving document');
    const orderPreflightData = await orderPreflight(true);
    const { order, runners } = orderPreflightData;
    devLog('SaveDocumentButton', 41, order);
    await firestore.collection(ordersDbCollectionString).doc(_order.id).set({ ...order, runners });
    if (order.materialsConfirmed) {
      const orderItemDoc = await firestore.collection(orderItemsDbCollection).doc(order.id).get();
      if (orderItemDoc.exists) {
        const orderItemsData = orderItemDoc.data()?.orderItems as IOrderItem[];
        const updated = orderItemsData.map((o) => ({
          ...o,
          materialsConfirmed: o.materialsConfirmed || order.materialsConfirmed,
          materialsConfirmedBy: o.materialsConfirmedBy || currentUser.email,
        }));
        await firestore.collection(orderItemsDbCollection).doc(order.id).set({ orderItems: updated });
      }
    }
    // increment the order number in the realtime database
    await incrementRealtimeDbOrderNumber(order);
    redirect();
  };

  const onShip = () => {
    orderPreflight(true).then((data) => {
      const { order, runners } = data;
      firestore.collection(ordersDbCollectionString).doc(order.id).set({ ...order, runners }).then(() => {
        firestore.collection(orderItemsDbCollection).doc(order.id).set({ orderItems: orderItems.length ? orderItems : [] }).then(() => {
          setCurrentShipOrder({ ...order, packingSlipNotes: order.description || '' });
          setShowShipDrawer(true);
        });
      });
    });
  };

  // Checks to see if work orders should be retained on the order before making a copy
  const splitPreflight = () => Modal.confirm({
    title: 'Keep work orders?',
    content: 'If you are shipping one work order, and need to retain the second, select Yes. Otherwise, select No',
    okText: 'Yes',
    onOk: () => { onCopy(true, true); },
    cancelText: 'No',
    onCancel: () => { onCopy(true, false); },
  });
  const onCopy = async (split: boolean, keepWorkOrders: boolean = false) => {
    const data = await orderPreflight(split);
    const { order, runners } = data;

    // save the current order before moving on, using the updated/preflight order with the current order object's runners
    await firestore.collection(ordersDbCollectionString).doc(order.id).set({ ...order, runners: (runners || []) });

    const id = shortid.generate();
    const [orderNumber, splitNumber] = order.salesOrder.split('-');
    let salesOrderNumber = orderNumber;
    if (!split && salesOrderNumber !== 'PH') salesOrderNumber = `${parseInt(orderNumber, 10) + 1}`;
    else if (!splitNumber && salesOrderNumber !== 'PH') {
      salesOrderNumber = `${orderNumber}-2`;
    } else if (salesOrderNumber !== 'PH') {
      const splitInt = parseInt(splitNumber, 10);
      if (!Number.isNaN(splitInt)) salesOrderNumber = `${orderNumber}-${splitInt + 1}`;
      else salesOrderNumber = `${orderNumber}-BO`;
    }
    const ihs = some(orderItems.filter((o) => o).map((o) => o.houseSample));

    const newRecord = {
      ...order,
      runners: keepWorkOrders ? (runners || []) : [],
      completed: false,
      dateCreated: firebase.firestore.Timestamp.fromDate(new Date()),
      id,
      ihs,
      notes: order.notes || '',
      orderDate: order.orderDate || firebase.firestore.Timestamp.fromDate(new Date()),
      released: false,
      releaseDate: order.releaseDate || new Date(),
      materialsConfirmed: null,
      materialsConfirmedBy: null,
      // do not persist release confirmation on copy -- this should be reset for secondary confirmation
      releaseConfirmed: null,
      releaseConfirmedBy: null,
      salesOrder: salesOrderNumber,
      shipDateHistory: [],
      weightReduction: {
        none: 0,
        cavity: 0,
        carveTop: 0,
        slot: 0,
        honeycomb: 0,
      },
    };

    await firestore.collection(ordersDbCollectionString).doc(id).set(newRecord);
    // if we are not splitting, update the sales order number in the realtime database in case we are copying to create a new/different order
    if (!split) await incrementRealtimeDbOrderNumber(order);

    // TODO: if we are not splitting, make sure that the order items have been 'reset'
    // this means setting the orderItem property `quantityAssigned` to 0,
    // and resetting all BOMs for each orderItem so that the total quantity is what it should be by default.
    let newOrderItems = orderItems.length ? orderItems : [];
    if (!split) {
      newOrderItems = orderItems.filter((o) => o).map((o) => {
        const bom = o.bom ? o.bom.map((b) => ({ ...b, quantityConsumed: 0 })) : null;
        if (bom) return { ...o, quantityAssigned: 0, bom };
        return { ...o, quantityAssigned: 0 };
      });
    }
    await firestore.collection(orderItemsDbCollection).doc(id).set({
      id,
      customerId: newRecord.customer.DisplayName,
      completed: false,
      orderItems: newOrderItems.length ? newOrderItems : [],
    });
    /*
      FINALLY notify any users who should be notified that an order was copied, and that it needs to be confirmed again.
     */
    if (order.releaseConfirmed) {
      const notificationUsers = confirmationNotificationUsers.filter((_user: string) => !currentUser.email.match(new RegExp(_user, 'i')));
      if (notificationUsers.length) {
        const messageId = shortid.generate();
        await database.ref(`${messageDbString}/unread/${messageId}`).set({
          users: notificationUsers,
          id: messageId,
          subject: 'A new order was just confirmed',
          message: `${currentUser.email.split('@')[0]} just copied an order: ${order.customer.id} order #${order.salesOrder}. It needs to be confirmed again before releasing.`,
          clickUrl: `/orders/create?edit=true&orderId=${id}&returnView=week`,
          archived: false,
          sent: new Date().getTime(),
        });
      }
    }
    clearRedirect();
    stageRedirect(`/schedule/${localStorage.getItem('salesOrderViewType') || 'week'}#${id}`);
    window.location.href = `/orders/create?edit=true&orderId=${id}`;
  };

  const onSaveContext = (e: any) => {
    if (e.key.match('copy')) onCopy(false);
    if (e.key.match('split')) splitPreflight();
    if (e.key.match('ship')) onShip();
  };

  const onCreate = (e: any) => {
    e.preventDefault();
    if (edit) return onSave(e);
    const id = shortid.generate();
    let errorText = null;

    if (_order.description.length === 0) {
      errorText = 'Please add a description';
    }
    if (!_order.salesOrder) {
      errorText = 'Please add a sales order number';
    }
    if (!customer.id) {
      errorText = 'Please select a customer';
    }
    if (errorText) {
      return Modal.error({
        content: errorText,
        okText: 'OK',
      });
    }
    const salesOrder = {
      ..._order,
      id,
      customer,
      releaseDate: releaseDate(_order, []),
      orderDate: _order.orderDate || firebase.firestore.Timestamp.fromDate(new Date()),
      shipDateHistory: [_order.shipDate],
    };

    const runners = generateRunners(workOrders, _order, orderItems);
    if (runners.length > 0) {
      // @ts-ignore
      salesOrder.runners = runners;
    } else {
      delete salesOrder.runners;
    }

    firestore.collection(ordersDbCollectionString)
      .doc(id)
      .set(salesOrder)
      .then(() => {
        firestore.collection(orderItemsDbCollection).doc(id).set({ orderItems: orderItems.length ? orderItems : [] }).then(() => {
          // stageRedirect(`/schedule#${id}`);
          database.ref(nextOrderNumberPath).once('value').then((data) => {
            const orderNumber = data.val();
            if (salesOrder.salesOrder.split('-')[0] === orderNumber.toString()) {
              database.ref(nextOrderNumberPath).set(parseInt(orderNumber, 10) + 1).then(() => {
                clearRedirect();
                window.location.href = `/orders/create?edit=true&orderId=${id}`;
              });
            } else {
              clearRedirect();
              window.location.href = `/orders/create?edit=true&orderId=${id}`;
            }
          });
        });
      });
  };
  // @ts-ignore
  const saveMenu = (
    <Menu onClick={onSaveContext}>
      <Menu.Item key="copy">
        and Copy
      </Menu.Item>
      <Menu.Item key="split">
        and Split
      </Menu.Item>
      <MenuDivider />
      <Menu.Item key="ship">
        and Ship
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setOrder(currentOrder);
  }, [currentOrder]);

  return (
    <>
      { edit ? (
        <Dropdown.Button
          trigger={['click']}
          icon={<CaretDown style={{ width: 10, marginBottom: 4 }} />}
          onClick={onSave}
          overlay={saveMenu}
          type="primary"
        >
          Save
        </Dropdown.Button>
      ) : (
        <Button type="primary" shape="round" onClick={onCreate}>Create</Button>
      )}
    </>
  );
};

export default SaveDocumentButton;
