// @ts-ignore
import axios from 'axios';
import { Modal } from 'antd';
// @ts-ignore
import { devLog } from 'shared/util/logging';
import { IRouterStep } from 'pages/ProductionSchedule/types';
import { IRunner, IShopOrder } from 'pages/Orders/types';

// eslint-disable-next-line import/no-extraneous-dependencies
const { IncomingWebhook } = require('@slack/webhook');
const _ = require('lodash');
const qs = require('qs');

const QA_CHANNEL = 'https://hooks.slack.com/services/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';

const postMessage = (messageText: string, channel: string = 'https://hooks.slack.com/services/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', attachment: null|string = null) => {
  const attachmentText = attachment || '';
  const messageData = {
    // eslint-disable-next-line max-len
    text: messageText,
    attachments: [{ text: attachmentText }],
  };
  axios.post(
    channel,
    JSON.stringify(messageData),
    {
      withCredentials: false,
      // transformRequest: [(d, headers) => {
      //   delete headers.post['Content-Type'];
      //   return d;
      // }],
    },
  );
};

const postBlockMessage = (subject: string, blockObject: any, channel: string, callback: () => void = () => {}) => {
  if (!channel) return;
  const webhook = new IncomingWebhook(channel);

  const messageData = {
  // const messageData = JSON.stringify({
    blocks: blockObject.blocks,
  };
  // });

  webhook.send(messageData).then(() => {
    callback();
  }).catch((err) => {
    console.log(err);
    Modal.error({
      content: 'Oops! The record was saved, but something went wrong sending the message to Slack. Please let Keith know about this error.',
      onOk: () => {
        devLog('shared/slack', 56, err);
      },
    });
  });
  // console.log(messageData);
  // console.log(channel);
  //
  // axios.post(
  //   channel,
  //   messageData,
  //   {
  //     headers: {
  //       post: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  //     },
  //     withCredentials: false,
  //   },
  // ).then(() => {
  //   callback();
  // })
  //   .catch((err) => {
  //     Modal.error({
  //       content: 'Oops! The record was saved, but something went wrong sending the message to Slack. Please let Keith know about this error.',
  //       onOk: () => {
  //         devLog('shared/slack', 56, err);
  //       },
  //     });
  //   });
};

const slackRouterStepUpdate = (routerSteps: IRouterStep[], order: IShopOrder, step: string, r: IRunner, updateAll: boolean = false) =>
  null;
  // U014A0R0RKM - cathy
  // U014NAY3X6V - bruce
  // U014THYLP9P - ed
  // U013TFGRPMM - keith

// const routerStep = _.find(routerSteps, (s: IRouterStep) => s.id === step);
// const messageText = updateAll ? `All runners for ${order.customer.shortName} order ${order.salesOrder}, have arrived at ${routerStep?.name}`
//   : `*${order.customer.shortName} ${order.salesOrder}, ${r.runnerNumber + 1} of ${order.runners.length}*`;
// const attachment = updateAll ? '' : `${order.type.toUpperCase()}  released :arrow_right:  ${routerStep?.name}`;
// postMessage(messageText, attachment);

const slackNeckReleaseConfirmed = (order: IShopOrder) =>
  null;
  // postMessage(`*${order.customer.shortName}* NECK ORDER _${order.salesOrder}`, 'CONFIRMED');

// @ts-ignore
export {
  QA_CHANNEL,
  postMessage,
  postBlockMessage,
  slackRouterStepUpdate,
  slackNeckReleaseConfirmed,
};
