import React from 'react';
import {
  Font, Text, View, StyleSheet,
} from '@react-pdf/renderer';
import { IOrderItem } from '../../../shared/types/dbRecords';

const PAGE_WIDTH = 11 * 72;
const HORIZONTAL_MARGINS = 18;
const VERTICAL_MARGINS = 36;
const CELL_W = 30;
const CELL_H = 24;

Font.register({
  family: 'Red Hat Display-300',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg.ttf',
});

Font.register({
  family: 'Red Hat Display',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-700',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg.ttf',
});

Font.register({
  family: 'Red Hat Display-600',
  src: 'https://fonts.gstatic.com/s/redhatdisplay/v14/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg.ttf',
});

const style = StyleSheet.create({
  itemWrapper: {
    width: '100%',
    minHeight: CELL_H,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    borderBottom: '1px solid lightgrey',
    borderRight: '1px solid black',
  },
  partDetailWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: PAGE_WIDTH - (2 * HORIZONTAL_MARGINS) - (11 * CELL_W) - 10,
    paddingTop: 4,
    paddingBottom: 5,
    paddingLeft: 4,
  },
  partDetailWrapperBorder: {
    flexDirection: 'column',
    flexGrow: 2,
    position: 'absolute',
    zIndex: 100000,
    top: 0,
    right: -1,
    width: 1,
    height: 100,
    backgroundColor: 'black',
  },
  partDescriptionWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    // marginBottom: 4,
  },
  partNumberWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  partNumber: {
    fontSize: 9,
    fontFamily: 'Red Hat Display-700',
    fontWeight: 'semibold',
    margin: 0,
    marginRight: 8,
  },
  partDescription: {
    fontSize: 9,
    fontFamily: 'Red Hat Display-300',
    fontWeight: 'light',
    margin: 0,
  },
  descriptionRow: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  partNotes: {
    fontSize: 8,
    fontFamily: 'Red Hat Display-300',
    fontWeight: 'light',
    marginLeft: 4,
  },
  itemPrice: {
    fontSize: 8,
    fontFamily: 'Red Hat Display',
    margin: 0,
    marginRight: 8,
    color: 'grey',
  },
  itemDivider: {
    width: 1,
    backgroundColor: 'black',
    marginLeft: 1,
  },
  qaWrapper: {
    width: (11 * CELL_W) - 10,
    flexDirection: 'row',
    flexGrow: 2,
    marginLeft: -1,
  },
  qaCell: {
    width: CELL_W,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  qaCellNoBorder: {
    width: CELL_W,
  },
  qaCellDivider: {
    width: 1,
    height: '100%',
    backgroundColor: 'lightgrey',
    margin: 0,
  },
  quantityToMake: {
    width: CELL_W,
    height: 'auto',
    fontFamily: 'Red Hat Display',
    fontSize: 9,
    margin: 0,
    textAlign: 'center',
  },
  shipWrapper: {
    flexDirection: 'row',
    width: (CELL_W * 8),
    backgroundColor: 'rgba(255,0,0,0.2)',
  },
  shipCell: {
    width: CELL_W,
    height: CELL_H,
    backgroundColor: 'rgba(0, 255, 128, 0.3)',
  },
});

interface IComponent {
  item: IOrderItem;
}

const ShippingLineItem = ({ item }: IComponent) => (
  <View style={style.itemWrapper} wrap={false}>
    <View style={style.partDetailWrapper}>
      <View style={style.partDescriptionWrapper}>
        <View style={style.partNumberWrapper}>
          <Text style={style.partNumber}>{item.Sku}</Text>
          <Text style={style.itemPrice}>{item.unitPrice}</Text>
        </View>
        <View style={style.descriptionRow}>
          <Text style={style.partDescription}>{item.Description}</Text>
          <Text style={style.partNotes}>{item.notes}</Text>
        </View>
      </View>
      <View style={{
        flexDirection: 'column', flexGrow: 2, position: 'absolute', zIndex: 100000, top: 0, right: -1, width: 1, height: 48, backgroundColor: 'black',
      }}
      />
    </View>
    <View style={style.qaWrapper}>
      <View style={style.qaCell}>
        <Text style={style.quantityToMake}>{item.quantityAssigned}</Text>
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
      <View style={style.qaCell}>
        <View style={style.qaCellDivider} />
      </View>
    </View>
  </View>
);

export default ShippingLineItem;
