import React from 'react';
import styled from 'styled-components';
import { Button, Popconfirm, Tooltip } from 'antd';
import { Delete, Help } from '@styled-icons/material-twotone';
import theme from 'shared/theme';
import QBOAuth from 'shared/data/QBO/auth';
import QBOCustomer from 'shared/data/QBO/customer';
import { ConfirmDeleteIcon } from '../../styledComponents';
import useFirebase from '../../../../../vendor/Firebase';

interface IComponent {
  Id: string;
}
const ConfirmHelpIcon = styled(Help)`
    width: 18px;
    color: ${theme.palette.error.hue};
    margin-right: 4px;
`;
const ConfirmDeactivateIcon = styled(Delete)`
  width: 20px;
  color: ${theme.palette.error.hue};
  margin-bottom: 2px;
`;
const CustomerDeactivateButton = ({ Id }: IComponent) => {
  const { database } = useFirebase();
  const onClick = async (e: any) => {
    e.stopPropagation();
    // await QBOAuth.refreshToken(database);
    await QBOCustomer.update(Id, { Active: false });
  };

  return (
    <Tooltip title="Deactivate">
      <Popconfirm
        title="This will deactivate the selected customer. Continue?"
        onConfirm={onClick}
        okText="Yes"
        cancelText="No"
        placement="left"
        // @ts-ignore
        icon={<ConfirmDeleteIcon />}
      >
        <Button
          shape="circle"
          // @ts-ignore
          icon={<ConfirmDeactivateIcon />}
        />
      </Popconfirm>
    </Tooltip>
  );
};

export default CustomerDeactivateButton;
