import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { find, reverse, sortBy } from 'lodash';
import { useRecoilValue } from 'recoil';
import { IShopOrder } from 'pages/Orders/types';
import { Spinner } from 'shared/components/Utility/Loader';
import { ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { IOrderItem, IShipment, IShipmentItem } from 'shared/types/dbRecords';
import QBOItem from 'shared/data/QBO/item';
import useFirebase from 'vendor/Firebase';
import QuickViewOrdersModal from './Components/OrderQuickView/QuickViewOrdersModal';
import QuickViewShipmentsModal from './Components/ShipQuickView/QuickViewShipmentsModal';

interface IComponent {
  partId: string;
  qboId: string;
  onQuickViewOpenCallback: () => void;
}
interface IQuickViewOrder extends IShopOrder {
  orderItems: IOrderItem[];
}

const QuickViewOptionsColumn = styled(FlexColumn)`
    width: 120px;
    align-items: flex-start;
    justify-content: center;
    padding: 8px;
    gap: 4px
`;

const QuickViewOptionWrapper = styled(FlexRow)`
    width: 100%;
`;

const QuickViewOptionsButton = styled(Button)`
    width: 100%;
    height: 28px;
    margin: 0;
    border-radius: 4px;
    line-height: 18px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
`;

const QuickViewOptions = ({ partId, qboId, onQuickViewOpenCallback }: IComponent) => {
  const customerId = partId.substr(0, 5);
  const { firestore } = useFirebase();
  const [_orders, setOrders] = useState<IQuickViewOrder[]>([]);
  const [onOrdersWorking, setOnOrdersWorking] = useState<boolean>(false);
  const [showOrdersModal, setShowOrdersModal] = useState<boolean>(false);
  const [shipments, setShipments] = useState<IShipment[]>([]);
  const [onShipmentsWorking, setOnShipmentsWorking] = useState<boolean>(false);
  const [showShipmentsModal, setShowShipmentsModal] = useState<boolean>(false);
  const ordersDbString = useRecoilValue(ORDERS_DB_COLLECTION);

  const onOrders = async (e: any) => {
    setOnOrdersWorking(true);
    const orderDocs = await firestore.collection(ordersDbString).where('customer.DisplayName', '==', customerId).where('completed', '==', false).get();
    const orders = orderDocs.docs.map((o) => o.data() as IShopOrder);

    const orderItemsDocs = await Promise.all(orders.map((o: IShopOrder) => firestore.collection('order-items').doc(o.id).get()));
    const orderItemsData = orderItemsDocs.map((d) => ({ id: d.id, data: d.data() }));

    const partOrders = orderItemsData.map((o: { id: string, data: { orderItems: IOrderItem[] }}) => {
      const partItem = (o.data?.orderItems || []).filter((i: IOrderItem) => i?.Sku === partId);
      if (partItem.length > 0) {
        const order = find(orders, (i: IShopOrder) => i.id === o.id);
        if (!order) return null;
        return { ...order, orderItems: partItem };
      }
      return null;
    }).filter((i: any) => i);
    setOnOrdersWorking(false);
    if (partOrders.length > 0) {
      // @ts-ignore
      setOrders(reverse(sortBy(partOrders, (o: IQuickViewOrder) => o.shipDate.toDate())));
      setShowOrdersModal(true);
      onQuickViewOpenCallback();
    } else {
      Modal.info({
        title: 'No orders found!',
        content: "We couldn't find any open orders with this item on them. If you are sure there is an order, let Keith know.",
        onOk: () => {
          setOnOrdersWorking(false);
        },
      });
    }
  };
  const onShipments = async (e: any) => {
    setOnShipmentsWorking(true);
    const shipmentDocs = await firestore.collection('order-shipments').where('customer.DisplayName', '==', customerId).get();
    const partShipments = shipmentDocs.docs.map((d) => {
      const shipment = d.data() as IShipment;
      const itemsShipped = shipment.shippedItems.filter((i: IShipmentItem) => i.Sku === partId);
      if (itemsShipped.length > 0) return { ...shipment, quantityShipped: itemsShipped.map((i: IShipmentItem) => i.quantityShipped).reduce((a, b) => a + b, 0) };
      return null;
    }).filter((i: IShipment|null) => i !== null) as IShipment[];

    setOnShipmentsWorking(false);
    if (partShipments.length > 0) {
      setShipments(reverse(sortBy(partShipments, (s: IShipment) => s.shipDate)));
      setShowShipmentsModal(true);
      onQuickViewOpenCallback();
    } else {
      Modal.confirm({
        title: 'No shipments found!',
        content: "We couldn't find any shipments with this item on them. It is possible this was shipped prior to HELM having shipping data. Click below to run a QBO report, or cancel to stay in HELM.",
        okText: 'Run QBO report',
        onOk: () => {
          QBOItem.fetchByName(partId).then((item) => {
            if (!item) {
              return Modal.error({
                title: 'Item not found!',
                content: 'Unable to find that item in Quickbooks. It may have been renamed or removed. Please check manually and report to Keith if still having issues.',
              });
            }
            const ninetyDaysAgo = new Date();
            ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
            const ninetyDaysAgoString = `${(ninetyDaysAgo.getMonth() + 1).toString().padStart(2, '0')}/${ninetyDaysAgo.getDate().toString().padStart(2, '0')}/${ninetyDaysAgo.getFullYear()}`;
            setOnShipmentsWorking(false);
            window.open(`https://app.qbo.intuit.com/app/reportv2?token=ITEML_QUICKREPORT&show_logo=false&date_macro=since90daysago&low_date=${ninetyDaysAgoString}&high_date=&item=${item.Id}&item_incl_subs=true&edited_sections=false&divideby1000=false&hidecents=false&negativenums=1&negativered=false&show_header_title=true&show_header_range=true&show_footer_custom_message=true&show_footer_date=true&show_footer_time=true&header_alignment=Center&footer_alignment=Center&show_header_company=true&company_name=Wildwood%20Manufacturing&footer_custom_message=`);
          });
          // firestore.collection('part-viewer-data').doc(partId).get().then((doc) => {
          //   const data = doc.data();
          // });
        },
      });
    }
  };

  const onCloseOrdersModal = () => {
    setShowOrdersModal(false);
  };
  const onCloseShipmentsModal = () => {
    setShowShipmentsModal(false);
  };
  return (
    <QuickViewOptionsColumn>
      <QuickViewOptionWrapper>
        {onOrdersWorking ? (
          <Spinner radius={28} />
        ) : (
          <QuickViewOptionsButton onClick={onOrders}>Orders</QuickViewOptionsButton>
        )}
      </QuickViewOptionWrapper>
      <QuickViewOptionWrapper>
        {onShipmentsWorking ? (
          <Spinner radius={28} />
        ) : (
          <QuickViewOptionsButton onClick={onShipments}>Shipments</QuickViewOptionsButton>
        )}
      </QuickViewOptionWrapper>
      <QuickViewOrdersModal partId={partId} orders={_orders} open={showOrdersModal} closeCallback={onCloseOrdersModal} />
      <QuickViewShipmentsModal partId={partId} shipments={shipments} open={showShipmentsModal} closeCallback={onCloseShipmentsModal} />
    </QuickViewOptionsColumn>
  );
};

export default QuickViewOptions;
