import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Radio } from 'antd';
import { useRecoilValue } from 'recoil';
import { ItemDetailsColumnWrapper, ItemDetailsLabel, ItemDetail } from 'shared/styledComponents/inputs';
import { houseSample } from 'shared/data/order';
import { orderItemsAtom } from 'shared/state/orderState';

const OrderDynamicRadioButton = styled(Radio.Button)`
  cursor: not-allowed;
`;
const OrderHouseSample = () => {
  const orderItems = useRecoilValue(orderItemsAtom);
  const [ihs, setIhs] = useState<boolean>(houseSample(orderItems));

  useEffect(() => {
    setIhs(houseSample(orderItems));
  }, [orderItems]);

  return (
    <ItemDetailsColumnWrapper key="order-topped-or-bound-details-wrapper" width="164px">
      <ItemDetailsLabel key="order-topped-or-bound-details-label">House sample?</ItemDetailsLabel>
      <ItemDetail>{ ihs ? 'Yes' : 'No'}</ItemDetail>
    </ItemDetailsColumnWrapper>
  );
};

export default OrderHouseSample;
