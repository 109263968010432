import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useTestData, isDevSite } from 'shared/util';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from 'shared/state/inventoryState';
import useFirebase from 'vendor/Firebase';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { MESSAGE_DB_PATH_ATOM, reorderPointNotificationUsersAtom } from 'shared/state/messageState';
import QBOInventoryAdjustment from 'shared/data/QBO/inventoryAdjustment';

const ComponentButton = styled(Button)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const ConsumeMaterialsButton = () => {
  const { database } = useFirebase();
  const [locked, setLocked] = useState<boolean>(true);
  const [progressLabel, setProgressLabel] = useState<string>('');

  const onClick = async (e: any) => {
    const adjustmentDataDoc = await database.ref('/util/adjustmentData').once('value');
    const adjustmentData = adjustmentDataDoc.val();

    const sortedInventoryAdjustments = sortBy(Object.keys(adjustmentData), (k) => new Date(parseInt(k, 10)));
    const BATCH_SIZE = 5;
    
    // Create array of batches
    const batches = sortedInventoryAdjustments.reduce((acc: string[][], key, index) => {
      const batchIndex = Math.floor(index / BATCH_SIZE);
      if (!acc[batchIndex]) {
        acc[batchIndex] = [];
      }
      acc[batchIndex].push(key);
      return acc;
    }, []);

    // Process batches sequentially
    await batches.reduce(async (batchPromise, batch, batchIndex) => {
      await batchPromise;
      setProgressLabel(`Processing batch ${batchIndex + 1} of ${batches.length}...`);
      
      // Process each adjustment in the batch sequentially
      return batch.reduce(async (adjustmentPromise, k) => {
        await adjustmentPromise;
        const adjustment = adjustmentData[k];
        if (adjustment.Line?.length > 0) {
          return QBOInventoryAdjustment.create(adjustment);
        }
        return Promise.resolve();
      }, Promise.resolve());
    }, Promise.resolve());

    Modal.success({
      title: 'Complete',
      content: 'All locked inventory adjustments have been processed.',
      onOk: () => {
        setProgressLabel('');
      },
    });
  };

  useEffect(() => {
    database.ref('/util/adjustmentData').once('value').then((data) => {
      const lockedData = data.val();
      if (!lockedData) return;
      setLocked(lockedData.lock || false);
      if (Object.keys(lockedData.data || {}).length > 0) {
        const itemsToConsume = Object.keys(lockedData.data).length;
        setProgressLabel(`${itemsToConsume} item${itemsToConsume > 1 ? 's' : ''} to consume`);
      }
    });
  }, []);

  return (
    <FlexColumn align="flex-end" justify="flex-start">
      <ComponentButton type="default" onClick={onClick} disabled={!locked || useTestData}>Unlock Consumption & Consume Material</ComponentButton>
      {/* <ComponentButton type="default" onClick={onClick} disabled={!locked || useTestData || isDevSite()}>Unlock Consumption & Consume Material</ComponentButton> */}
      {!!progressLabel.length && (
        <p>{progressLabel}</p>
      )}
    </FlexColumn>
  );
};

export default ConsumeMaterialsButton;
