import { compact, flatMap, includes, zip, uniq } from 'lodash';
import { dateString } from 'shared/data/calendar';

export const validateField = (fieldValue: any, validator: string): boolean => !!fieldValue.match(new RegExp(validator));
export const MAX_PRICE_ITEM_LENGTH = 64;

export const formValidators = {
  // eslint-disable-next-line no-useless-escape,max-len
  email: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
  text: '.+',
  // eslint-disable-next-line no-useless-escape
  url: '[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)',
};

export const toTitleCase = (str: string = '') => {
  const parts = str.split(' ');

  if (parts.length === 0) return;
  if (parts.length === 1 && parts[0] === '') return;
  return parts.map((part) => `${part[0].toUpperCase()}${part.slice(1)}`).join(' ');
};

export const trimHead = (str: string) => str.replace(/^\s/, '');
export const trimTail = (str: string) => str.replace(/\s$/, '');

export const truncateString = (str: string, charCount: number, truncate: 'head'|'middle'|'tail' = 'tail') => {
  if (str.length <= charCount) return str;

  if (truncate === 'tail') return `${str.slice(0, charCount)}...`;
  if (truncate === 'head') return `...${str.slice(str.length - charCount)}`;
  if (truncate === 'middle') {
    const segmentLength = charCount / 2;
    // eslint-disable-next-line max-len
    return `${trimTail(str.slice(0, segmentLength))}...${trimHead(str.slice(segmentLength + (str.length - charCount)))}`;
  }
};

export const numberWithCommas = (x: number) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const toHash = (s: string) => s.replace(/\s/g, '_');
export const fromHash = (s: string) => s.replace(/_/g, ' ');

export const camelToTitleCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const numberText = (n: number): string|number => {
  if (n < 10) {
    return [
      'zero',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
    ][n];
  }
  return n;
};

export const uniqueChildPartNameSegments = (parent: string, childList: string[], includeParent: boolean): { original: string, difference: { term: string, unique: boolean }[]}[] => {
  if (childList.length === 0) {
    throw new Error('Array is empty.');
  }

  const matcher = /[_]+/;

  const processString = (str: string) => {
    const parts = str.split(matcher);
    const prefix = parts.slice(0, 2).join('_');
    const remainingParts = parts.slice(2);
    return { prefix, remainingParts };
  };

  const { prefix: parentPrefix, remainingParts: parentRemaining } = processString(parent);
  let commonSegments = uniq(parentRemaining);

  // Identify common segments
  [parent, ...childList].forEach((name) => {
    const { remainingParts } = processString(name);
    const segments = uniq(remainingParts.map(cleanMeta));
    commonSegments = commonSegments.map(cleanMeta).filter((x) => segments.includes(x));
  });

  const returnList = includeParent ? [parent, ...childList] : childList;
  // Return array of objects with original string and unique segments
  return returnList.map((str) => {
    const { prefix, remainingParts } = processString(str);
    const segments = uniq(remainingParts);
    const uniqueSegments = Array.from(segments).map((x) => ({ term: x, unique: !includes(commonSegments, x) }));
    return {
      original: str,
      difference: [
        { term: prefix, unique: false },
        ...uniqueSegments
      ],
    };
  });
};

export const cleanMeta = (s: string) => s.replace(/[\*]/g, '');
export const isMeta = (s: string) => !!s.match(/^\*/) && !!s.match(/\*$/);

/*
  splitAtMeta
  Function that splits a string into discrete terms based on presence of meta-tagged elements in the string;
  Example: 'Foo*bar*baz' => ['Foo', '*bar*', 'baz'];
  If no meta is present, the input string is returned as the sole element in an array;
 */
export const splitAtMeta = (s: string) => {
  const meta = s.match(/\*[^\*]+\*/g);
  // if there is no meta in the string, simply return the string as an array;
  if (!meta) return [s];

  const nonMeta = s.split(/\*[^\*]+\*/g).filter((t) => t.length > 0);

  let splitTerms = [];
  if (s.indexOf(nonMeta[0]) < s.indexOf(meta[0])) {
    splitTerms = flatMap(zip(nonMeta, meta), (pair) => compact(pair));
  } else {
    splitTerms = flatMap(zip(meta, nonMeta), (pair) => compact(pair));
  }

  return splitTerms;
};
export const addMeta = (description: string) => {
  let descriptionWithMeta = description;
  const matchers = [
    /_NFS_/,
    /_CNC_/,
    /_PHCap_/,
    /_(No|Pocket)[^\/_]+\/(No|Pocket)[^_]+_/g, // match pocket and or no inlay declarations
    /_[0-9]{5}(SS)_/, // match stainless frets
    /_(Loose\s?)((Frets|Dots)|[0-9]{5}|Lined\s?Fretless)_/, // match loose fret declarations
  ];
  matchers.forEach((matcher) => {
    const match = descriptionWithMeta.match(matcher);
    if (match) {
      const terms = match[0].replace(/_/g, '').split('/');
      const metaTerms = `_${terms.map((t) => `*${t}*`).join('/')}_`;
      descriptionWithMeta = descriptionWithMeta.replace(match[0], metaTerms);
    }
  });

  return descriptionWithMeta;
};

export const escapeMeta = (description: string) => description.replace(/[\*]/g, '\*');

export const stripPartNotes = (notes: string, partNotes: string): string => {
  const noteLines = notes.split('\n');
  const partNoteLines = partNotes.split('\n');
  
  const customNotes = noteLines.filter((line: string) => !partNoteLines.includes(line.trim()));
  
  return customNotes.join('\n').trim();
};

export const userFirstName = (userEmail: string) => {
  const firstName = userEmail.split('@')[0].slice(0, -1);
  return firstName.charAt(0).toUpperCase() + firstName.slice(1);
};

export const attributeNote = (currentUser: { email: string }, note: string) => {
  const userName = userFirstName(currentUser?.email || '');
  const currentTime = new Date();
  return `[${userName} - ${dateString(currentTime, true)}]: ${note}\n`;
};
