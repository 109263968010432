import React from 'react';
import Container from './styledComponents';
import { IContentContainerProps } from './types';

const ContentContainer = ({
  children, className, fixedWidth,
}: IContentContainerProps) => (
  <Container className={className} fixedWidth={fixedWidth}>
    {children}
  </Container>
);

export default ContentContainer;
