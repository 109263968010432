import React from 'react';
import { Button, Upload } from 'antd';
import shortid from 'shortid';
import firebase from 'firebase';
import { RcFile } from 'antd/lib/upload';
import { Upload as UploadIcon } from '@styled-icons/fa-solid';
import styled from 'styled-components';
import { accessToken, fetchPart } from 'shared/data/jb_api';
import useFirebase from 'vendor/Firebase';

const UploadButton = styled(Button)`
  min-width: 240px;
  border-radius: 8px;
`;

const PartNumberUploader = () => {
  const { firestore } = useFirebase();

  const onUpload = (file: RcFile) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result
        .split('\r')
        .slice(1)
        .map((s) => s.trim().split(','));

      data.forEach((d) => {
        const [Sku, thickness] = d;
        if (Sku.length === 11) {
          const _thickness = parseFloat(thickness).toFixed(3);
          firestore.collection('part-viewer-data').doc(Sku).get().then((doc) => {
            if (doc.exists) {
              firestore.collection('part-viewer-data').doc(Sku).update({ thickness: _thickness }).then(() => {
                console.log(Sku, _thickness);
              });
            }
          });
        }
      });
    };

    reader.readAsText(file);

    return false;
  };

  return (
    <Upload
      accept=".txt, .csv"
      showUploadList={false}
      beforeUpload={onUpload}
    >
      <UploadButton type="default" icon={<UploadIcon size={16} style={{ marginRight: 8, position: 'relative', bottom: 2 }} />}>Upload Body Thicknesses</UploadButton>
    </Upload>
  );
};

export default PartNumberUploader;
