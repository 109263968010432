import React from 'react';
import { useRecoilValue } from 'recoil';
import { ItemDetail, ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { orderType, toppedOrBound } from 'shared/data/order';
import { orderItemsAtom } from 'shared/state/orderState';

const OrderToppedOrBound = () => {
  const orderItems = useRecoilValue(orderItemsAtom);

  return (
    <ItemDetailsColumnWrapper key="order-topped-or-bound-details-wrapper" width="164px">
      <ItemDetailsLabel key="order-topped-or-bound-details-label">{orderType(orderItems) === 0 ? 'Topped?' : 'Bound?'}</ItemDetailsLabel>
      <ItemDetail>{toppedOrBound(orderItems) ? 'Yes' : 'No'}</ItemDetail>
    </ItemDetailsColumnWrapper>
  );
};

export default OrderToppedOrBound;
