import React from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { EscalatorWarning } from '@styled-icons/material';
import { useSetRecoilState } from 'recoil';
import { priceSearchQueryAtom } from 'shared/state/pricingState';

interface IComponent {
  parentSku: string;
}

const ParentIcon = styled(EscalatorWarning)`
  width: 24px;
  margin-bottom: 2px;
`;
const SeeParentPartButton = ({ parentSku }: IComponent) => {
  const setPricingSearchQuery = useSetRecoilState(priceSearchQueryAtom);

  const onClick = (e: any) => {
    e.stopPropagation();
    setPricingSearchQuery(parentSku);
    if (localStorage.getItem('part.viewer.sortOrder') !== 'like') {
      const el = document.getElementById('view-type-radio-like');
      if (el) el.click();
    }
  };

  return (
    <Tooltip placement="top" title="See parent and related parts">
      <Button
        shape="circle"
      // @ts-ignore
        icon={<ParentIcon />}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default SeeParentPartButton;
