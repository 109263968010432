import React from 'react';
import {
  StyleSheet, View, Text,
} from '@react-pdf/renderer';
import { formatPrice } from '../../../../shared/data';

const style = StyleSheet.create({
  componentWrapper: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  totalLine: {
    width: 100,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
    marginRight: 4,
  },
  totalCopy: {
    fontFamily: 'Red Hat Display-700',
    fontSize: 11,
  },
  disclaimer: {
    fontFamily: 'Red Hat Display-300',
    fontSize: 9,
  },
});

interface IComponent {
  total: number;
}

const OrderConfirmationFooter = ({ total }: IComponent) => (
  <View style={style.componentWrapper}>
    <View style={style.totalLine}>
      <Text style={style.totalCopy}>Total: </Text>
      <Text style={style.totalCopy}>{formatPrice(total)}</Text>
    </View>
    <Text style={style.disclaimer}>This is not an invoice. Please do not pay from this order confirmation.</Text>
  </View>
);

export default OrderConfirmationFooter;
