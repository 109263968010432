import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Add, AddCircle } from '@styled-icons/material';
import { stageRedirect } from 'shared/util';

const NewPartButton = styled(Button)`
  border-radius: 8px;
  margin: ${(props: { margin: string }) => props.margin};
`;

const NewIcon = styled(AddCircle)`
    width: 20px;
    position: relative;
    left: -4px;
    margin: 0 4px 3px 0px;
`;

interface IComponent {
  callback: (e: any) => void;
  label: string;
  margin: string;
}

const NewElementButton = ({ callback, label = 'New', margin = '0 0' }: IComponent) => {
  const onClick = (e: any) => {
    e.preventDefault();
    callback(e);
  };
  return (
    <NewPartButton key="new-part-inventory-button" margin={margin} type="primary" onClick={onClick} icon={<NewIcon />}>{label}</NewPartButton>
  );
};

export default NewElementButton;
