import React, { useState, useEffect, useContext } from 'react';
import {
  Button, Modal,
} from 'antd';
// @ts-ignore
import QrReader from 'react-qr-reader';
import styled from 'styled-components';
import firebase from 'firebase';
import {
  NON_CONFORMANCE_COLLECTION, currentShopOperatorAtom, shopOperatorsObjectAtom,
} from 'shared/state/siteState';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  routerStepsAtom, scannerUserEmailsAtom,
} from 'shared/state/routingState';
import useFirebase from 'vendor/Firebase';
import { FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  ncAdditionalNotesAtom,
  ncCorrectiveActionAtom,
  ncCurrentReasonAtom, ncImageDataItemsAtom,
  ncPartNumberAtom,
  ncPartNumberQuantityAtom,
  ncScanDepartmentAtom,
} from 'shared/state/utilState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { QA_CHANNEL, postBlockMessage as postSlackMessage } from 'shared/messaging/slack';
import ncSlackMessage from 'shared/nonConformance';
import { INCRecord } from 'shared/types/dbRecords';
import { PART_VIEWER_COLLECTION } from 'shared/state/partViewState';
import { devLog } from 'shared/util/logging';
import { currentShopOrderAtom, ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import PartNonComplianceForm from './Components/PartNonComplianceForm';
import { IShopOrder } from '../Orders/types';

const qs = require('qs');
const _ = require('lodash');
const shortid = require('shortid');

const SCAN_BUTTON_TEXT = {
  cancel: 'Cancel',
  scan: 'Scan Job',
  submit: 'Submit',
};

const PageWrapper = styled(FlexColumn)`
    flex-grow: 1;
    height: 100%;
    width: 100vw;
    justify-content: flex-start;
  @media ${theme.device.laptopL} {
    align-items: flex-start;
  }
  `;
const ScannerWrapper = styled(FlexColumn)`
    height: 100%;
    width: 100%;
    justify-content: flex-start;
     background-color: ${(props) => (props.scanFound ? theme.palette.success.hue : theme.palette.neutral.black)};
  `;
const ScanButton = styled(Button)`
    height: 86px;
    width: 85%;
    border-radius: 12px;
    margin: 24px auto;

  @media ${theme.device.laptopL} {
    width: 460px;
    margin: 0 auto;
  } 
  `;

const ScannerHeader = styled.h2`
    width: 100%;
    height: 48px;
    text-align: center;
    margin: 0;
  `;

export default () => {
  const { firestore, storage } = useFirebase();
  const { currentUser } = useContext(AuthContext);
  const [scanMode, setScanMode] = useState(true);
  const [scanFound, setScanFound] = useState(false);
  const [scanSubmitted, setScanSubmitted] = useState(false);
  const [workOrderNumber, setWorkOrderNumber] = useState(1);
  const [salesOrderNumber, setSalesOrderNumber] = useState('');
  const [scanButtonText, setScanButtonText] = useState(SCAN_BUTTON_TEXT.cancel);
  const [shopOrder, setShopOrder] = useRecoilState<IShopOrder>(currentShopOrderAtom);
  const ncImageDataItems = useRecoilValue(ncImageDataItemsAtom);
  const routerSteps = useRecoilValue(routerStepsAtom);
  const [department, setDepartment] = useRecoilState(ncScanDepartmentAtom);

  // Form values for submission
  const partNumber = useRecoilValue(ncPartNumberAtom);
  const partQty = useRecoilValue(ncPartNumberQuantityAtom);
  const reasonCode = useRecoilValue(ncCurrentReasonAtom);
  const actionCode = useRecoilValue(ncCorrectiveActionAtom);
  const partNotes = useRecoilValue(ncAdditionalNotesAtom);
  const shopOperator = useRecoilValue(currentShopOperatorAtom);
  const shopOperators = useRecoilValue(shopOperatorsObjectAtom);
  const scannerStationEmails = useRecoilValue(scannerUserEmailsAtom);
  const imageDataItems = useRecoilValue(ncImageDataItemsAtom);

  const orderDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const nonConformanceDbString = useRecoilValue(NON_CONFORMANCE_COLLECTION);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);

  const onScan = (data: any) => {
    if (!data || scanFound) return;

    if (data.match(/runnerId/i)) {
      setScanFound(true);
      setScanButtonText(SCAN_BUTTON_TEXT.submit);

      const { shopOrderId, runnerId } = qs.parse(data.split('?')[1]);
      window.location.href = `/qa/ticket/new?orderId=${shopOrderId}&runnerId=${runnerId}`;

      // firestore.collection(orderDbString).doc(shopOrderId).get().then((doc) => {
      //   const docData = doc.data() as IShopOrder;
      //   setShopOrder(docData);
      //
      //   setWorkOrderNumber(_.findIndex(docData.runners, (r: IRunner) => r.id === runnerId) + 1);
      //   setSalesOrderNumber(docData.salesOrder.split('-')[0]);
      //   const currentRunner = _.find(docData.runners, (r: IRunner) => r.id === runnerId);
      //   const routerStep = _.find(routerSteps, (rs: IRouterStep) => rs.id === currentRunner.step);
      //   setDepartment(routerStep.department);
      //   setScanButtonText(SCAN_BUTTON_TEXT.submit);
      // });
    }
  };
  const onScanError = (e: any) => {
    Modal.error({
      title: 'Uh-oh. That scan didn\'t work. If this persists, let Keith know.',
    });
  };

  const preflightForm = () => department.length
          && shopOperator.length
          && partNumber?.length
          && partQty > 0
          && (((actionCode.match(/other/i) || reasonCode.match(/other/i)) && partNotes?.length) || (reasonCode.length && actionCode.length));

  const onMessagePosted = () => {
    if (_.includes(scannerStationEmails.emails, currentUser.email)) {
      window.location.href = '/scanner?ncRedirect=true';
    } else {
      Modal.confirm({
        title: 'Success!',
        okText: 'See Report',
        cancelText: 'Scan another',
        onOk: () => {
          if (_.includes(scannerStationEmails.emails, currentUser.email)) window.location.href = '/scanner?ncRedirect=true';
          else window.location.href = '/nc/report';
        },
        onCancel: () => {
          window.location.reload();
        },
      });
    }
  };

  const onSubmit = (e: any) => {
    if (!scanMode) {
      // SCAN MODE ENABLED -----------------------------
      setScanMode(true);
      setScanButtonText(SCAN_BUTTON_TEXT.cancel);
    } else if (scanMode && !scanFound) {
      // CANCELED SCAN ---------------------------------
      if (_.includes(scannerStationEmails.emails, currentUser.email)) window.location.href = '/scanner?ncRedirect=true';
      else window.location.href = '/nc/report';
    } else if (preflightForm()) {
      // SCAN SUBMITTED ---------------------------------
      setScanSubmitted(true);
      const id = shortid.generate();

      const shopOperatorId = _.find(shopOperators, (o: any) => o.label === shopOperator)?.id;
      firestore.collection(partViewerDbString).doc(partNumber).get().then((doc) => {
        const partData = doc.data();
        const dateRecorded = firebase.firestore.Timestamp.fromDate(new Date());

        const ncRecord = {
          id,
          salesOrder: { id: shopOrder.id, orderNumber: salesOrderNumber },
          customerId: shopOrder.customer.id,
          workOrder: `${workOrderNumber}/${shopOrder.runners.length}`,
          department,
          shopOperator: shopOperatorId || shopOperator,
          partNumber,
          partDescription: partData ? partData.description : 'N/A',
          quantity: partQty,
          reasonCode,
          correctiveAction: actionCode,
          notes: partNotes || '',
          type: shopOrder.type,
          dateRecorded,
          imageUrls: imageDataItems || [],
        } as INCRecord;

        firestore.collection(nonConformanceDbString).doc(id).set(ncRecord).then(() => {
          firestore.collection(orderDbString).doc(shopOrder.id).update({
            nonConformanceHistory: [
              ...shopOrder.nonConformanceHistory || [],
              { id, dateRecorded, reasonCode },
            ],
          }).then(() => {
            devLog('NonComplianceScanner', 208, 'Posting slack message...');
            const today = new Date();
            let month: string|number = today.getMonth() + 1;
            if (month < 10) month = `0${month}`;

            let date: string|number = today.getDate();
            if (date < 10) date = `0${date}`;

            const imageUrls = [];

            if (ncImageDataItems.length) {
              devLog('NonComplianceScanner', 219, `Uploading ${ncImageDataItems.length} image(s) to google cloud`);
              const messageSent = false;

              ncImageDataItems.forEach((item: any, index) => {
                const path = `/qa_images/${shopOrder.customer.id}-${shopOrder.salesOrder}-${index}-${today.getFullYear()}${month}${date}-${today.getTime()}.jpg`;
                devLog('NonComplianceScanner', 224, `Uploading ${path}`);
                const upload = storage.ref(path).putString(item, 'data_url', { contentType: 'image/jpg' });
                upload.on('state_changed', (s) => {
                  devLog('NonComplianceScanner', 227, s.state);
                  const progress = (s.bytesTransferred / s.totalBytes) * 100;
                  devLog('NonComplianceScanner', 229, `${progress}% finished`);
                  if (progress === 100) {
                    setTimeout(() => {
                      storage.ref(path).getDownloadURL().then((urlPath) => {
                        devLog('NonComplianceScanner', 233, urlPath);
                        imageUrls.push(urlPath);
                        devLog('NonComplianceScanner', 235, 'complete!');

                        devLog('NonComplianceScanner', 237, `Processed ${imageUrls.length} out of ${ncImageDataItems.length}`);

                        if (imageUrls.length === ncImageDataItems.length) {
                          // if (imageUrls.length === ncImageDataItems.length && !messageSent) {
                          // messageSent = true;
                          postSlackMessage('New Non-Conformance Reported', ncSlackMessage(ncRecord, imageUrls), QA_CHANNEL, onMessagePosted);
                        }
                      }).catch((error) => devLog('NonComplianceScanner', 244, error));
                    }, 200);
                  }
                }, (error) => devLog('NonComplianceScanner', 247, error.message));
              });
            } else {
              postSlackMessage('New Non-Conformance Reported', ncSlackMessage(ncRecord), QA_CHANNEL, onMessagePosted);
            }
          });
        });
      });
    } else {
      Modal.error({
        title: 'Please fill out all fields (Notes optional).',
      });
    }
  };

  useEffect(() => {
  }, []);

  return (
    <PageWrapper key="non-conformance-wrapper">
      <ScannerHeader>Non-conformance scanner</ScannerHeader>
      {(scanMode && !shopOrder.id) && (
        <ScannerWrapper scanFound={scanFound}>
          <QrReader
            delay={200}
            onError={onScanError}
            onScan={onScan}
            facingMode="environment"
            style={{ width: '100vw' }}
          />
        </ScannerWrapper>
      )}
      <>
        {(scanMode && shopOrder.id) && (
          <PartNonComplianceForm />
        )}
      </>
      <ScanButton
        type={`${(scanFound) ? 'primary' : 'default'}`}
        onClick={onSubmit}
        disabled={((!preflightForm() && scanFound) || scanSubmitted)}
      >
        {scanSubmitted ? 'Processing...' : scanButtonText}
      </ScanButton>
    </PageWrapper>
  );
};
