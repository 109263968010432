import React, { useState } from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  dataLoadingAtom,
} from 'shared/state/pricingState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import {
  adminUserEmailsAtom,
  superAdminUserEmailAtom,
} from 'shared/state/routingState';
import Loader from 'shared/components/Utility/Loader';
import { AdminToolsFilterWrapper, PageAdminToolsWrapper } from 'shared/styledComponents/containers';
import { every, sortBy } from 'lodash';
import { PricingCol, PageHeaderWrapper } from '../styledComponents';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';
import ActiveSelector from './Components/PriceListInputs/ActiveSelector';
import CustomerFilter from './Components/CustomerList/CustomerFilter';
import CustomerList from './Components/CustomerList/CustomerList';
import { activeCustomerDisplayAtom, customersAtom } from '../../shared/state/customerState';
import { PageTitle } from '../../shared/styledComponents/typographicElements';
import { ICustomerRecord } from '../../shared/types/dbRecords';
import ItemListFilter from '../../shared/components/Input/ItemListFilter';
import { listFilterQuery } from '../../shared/util';

const ToolsWrapper = styled(AdminToolsFilterWrapper)`
  justify-content: flex-start;
  width: calc(100% - 72px);
  margin: 2px 0 0 72px;
  gap: 12px;
    @media ${theme.device.laptopL} {
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-left: unset;
    }    
`;

const FilterWrapper = styled(AdminToolsFilterWrapper)`
    justify-content: flex-start;
    gap: 12px;
    margin-left: 12px;
    @media ${theme.device.laptopL} {
      margin-left: 0;
    }
`;
/**
 *   Display component that lists and filters all customer records,
 */
const CustomerBrowser = () => {
  // @ts-ignore
  const [loading, setLoading] = useRecoilState(dataLoadingAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const customers = useRecoilValue(customersAtom);

  const sessionKey = 'customer.query.string';
  const sessionActiveKey = 'customer.activeDisplay';
  const sessionPageKey = 'customer.currentPage';
  const [activeDisplayType, setActiveDisplayType] = useState<string>(sessionStorage.getItem(sessionActiveKey) || '');
  const [renderItems, setRenderItems] = useState<ICustomerRecord[]>(customers);
  const [query, setQuery] = useState<string>(sessionStorage.getItem(sessionKey) || '');
  const [currentPage, setCurrentPage] = useState<number>(sessionStorage.getItem(sessionPageKey) || 1);

  const updatePageData = (_query: string = query, _customers: ICustomerRecord[] = customers, activeType: 'all'|'active'|'inactive' = activeDisplayType): void => {
    // @ts-ignore
    setRenderItems(sortBy(customers, (c: ICustomerRecord) => c.id)
      .filter((c: ICustomerRecord) => {
        if (activeType === 'active') return c.Active;
        if (activeType === 'inactive') return !c.Active;
        return c;
      })
      .filter((c: ICustomerRecord) => {
        const terms = listFilterQuery(_query);
        const matchString = `${c.DisplayName}${c.CompanyName}${c.id}`;
        const matches = terms.map((t: string) => matchString.match(new RegExp(t.trim(), 'i')));
        return every(matches, Boolean);
      }));
  };

  const onChangeFilter = (newValue: string) => {
    setQuery(newValue);
    updatePageData(newValue, customers, activeDisplayType);
  };
  const onChangeActiveType = (newType: string) => {
    setActiveDisplayType(newType);
    updatePageData(query, customers, newType);
  };

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
    sessionStorage.setItem(sessionPageKey, newPage.toString());
  };

  return (
    <PageHeaderWrapper>
      <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]}>
        <PageAdminToolsWrapper id="customer-admin-inputs">
          <ToolsWrapper>
            <PageTitle>Customers</PageTitle>
            <FilterWrapper justify="flex-start">
              {/* <CustomerFilter items={renderItems} /> */}
              <ItemListFilter
                id="inventory-part-list-filter"
                callback={onChangeFilter}
                currentValue={query}
                prompt="Filter customers by name or code"
              />
              <ActiveSelector activeType={activeDisplayType} callback={onChangeActiveType} />
            </FilterWrapper>
          </ToolsWrapper>
        </PageAdminToolsWrapper>
      </ScopedComponent>
      <PricingCol>
        {loading
          ? (
            <>
              <FlexColumn style={{ width: '100%', height: '50vw' }}>
                <Loader />
              </FlexColumn>
            </>
          ) : (
            <>
              <CustomerList renderItems={renderItems} currentPage={currentPage} onPageChange={onPageChange} />
            </>
          )}
      </PricingCol>
    </PageHeaderWrapper>
  );
};

export default CustomerBrowser;
