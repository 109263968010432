import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  NoLeftMarginViewTypeRadioGroup,
  ViewTypeRadioButton, ViewTypeSelect, ViewTypeSelectLabel,
  ViewTypeSelectOption,
} from 'shared/styledComponents/inputs';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { ItemDetailsLabel } from '../../../../PartDetail/Components/PartDetailFields/styledComponents';
import { PartViewerFilterWrapper } from '../../../../../shared/styledComponents/containers';

const ComponentWrapper = styled(FlexColumn)`
    @media ${theme.device.laptopL} {
        height: inherit;
        align-items: center;
    }
`;
const ViewTypeSelectWrapper = styled(FlexColumn)`
    position: relative;
    bottom: 10px;
`;
interface IComponent {
  callback: (value: 'all'|'priced'|'unpriced') => void;
  currentValue: 'all'|'priced'|'unpriced';
}

export default ({ callback, currentValue }: IComponent) => {
  const [status, setStatus] = useState<'all'|'priced'|'unpriced'>(currentValue);
  const onChangeView = (e: any) => {
    const value = e.target?.value || e;
    callback(value);
  };

  useEffect(() => {
    setStatus(currentValue);
  }, [currentValue]);

  return (
    <PartViewerFilterWrapper>
      <ItemDetailsLabel key="part-sort-type-item-details-label">Price status</ItemDetailsLabel>
      <ViewTypeSelectWrapper>
        <ViewTypeSelectLabel>Price Status</ViewTypeSelectLabel>
        <ViewTypeSelect
          label="Price Status"
          size="default"
          value={status}
          onChange={onChangeView}
        >
          <ViewTypeSelectOption value="all">All parts</ViewTypeSelectOption>
          <ViewTypeSelectOption value="priced">Priced parts</ViewTypeSelectOption>
          <ViewTypeSelectOption value="unpriced">Unpriced parts</ViewTypeSelectOption>
        </ViewTypeSelect>
      </ViewTypeSelectWrapper>
      <NoLeftMarginViewTypeRadioGroup value={status} buttonStyle="solid" onChange={onChangeView}>
        <ViewTypeRadioButton
          value="all"
        >
          All
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="priced"
        >
          Priced
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="unpriced"
        >
          Unpriced
        </ViewTypeRadioButton>
      </NoLeftMarginViewTypeRadioGroup>
    </PartViewerFilterWrapper>
  );
};
