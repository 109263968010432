import React from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { showMessageCenterAtom } from 'shared/state/messageState';
import MessageCenter from './MessageCenter';

const MessageDrawer = styled(Drawer)`
  height: 100vh;
`;
const MessageCenterDrawer = () => {
  const [showMessageCenter, setShowMessageCenter] = useRecoilState(showMessageCenterAtom);
  const onClose = (e: any) => {
    setShowMessageCenter(false);
  };

  return (
    <MessageDrawer
      open={showMessageCenter}
      placement="right"
      width="50vw"
      height="100vh"
      onClose={onClose}
    >
      <MessageCenter />
    </MessageDrawer>
  );
};

export default MessageCenterDrawer;
