import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { Mail } from '@styled-icons/material';
import { Button } from 'antd';
import { selectedCustomerContactAtom } from 'shared/state/customerState';

const CustomerEmailIcon = styled(Mail)`
  width: 20px;
  margin-bottom: 3px;
`;
const CustomerEmailButton = styled(Button)`
  position: relative;
  top: 6px;
`;

const EmailContactButton = () => {
  const selectedCustomerContact = useRecoilValue(selectedCustomerContactAtom);
  const onClick = (e: any) => {
    window.location.href = `mailto:${selectedCustomerContact.email}`;
  };

  return (
    <CustomerEmailButton
      shape="circle"
      icon={<CustomerEmailIcon />}
      onClick={onClick}
    />
  );
};

export default EmailContactButton;
