import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { FlexColumn } from 'shared/containers/FlexContainer';
import {
  NoLeftMarginViewTypeRadioGroup,
  ViewTypeSelect,
  ViewTypeSelectOption,
  ViewTypeRadioButton, ViewTypeSelectLabel,
} from 'shared/styledComponents/inputs';
import { ViewTypeSelectWrapper, PartViewerFilterWrapper } from 'shared/styledComponents/containers';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { customerUserEmailsAtom } from 'shared/state/routingState';
import theme from 'shared/theme';
import { PartViewerFilterLabel } from 'shared/styledComponents/typographicElements';

const ComponentWrapper = styled(FlexColumn)`
    @media ${theme.device.laptopL} {
        //flex-direction: ;
        margin-top: -12px;
        height: inherit;
        justify-content: center;
    }
`;

interface IComponent {
  sortCallback: (type: 'sku'|'description'|'like') => void;
  sortOrder: 'sku'|'description'|'like';
}
const PriceSortSelector = ({ sortCallback, sortOrder }: IComponent) => {
  const [_sortOrder, setSortOrder] = useState<'sku'|'description'|'like'>(sortOrder);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const onChangeView = (e: any) => {
    const value = e.target?.value || e;
    setSortOrder(value);
    sortCallback(value);
  };

  useEffect(() => {
    setSortOrder(sortOrder);
  }, [sortOrder]);

  return (
    <PartViewerFilterWrapper>
      <PartViewerFilterLabel key="part-sort-type-item-details-label">Sort by</PartViewerFilterLabel>
      <ViewTypeSelectWrapper>
        <ViewTypeSelectLabel>Sort by</ViewTypeSelectLabel>
        <ViewTypeSelect
          label="View type"
          size="default"
          value={_sortOrder}
          onChange={onChangeView}
        >
          <ViewTypeSelectOption value="sku">Sku</ViewTypeSelectOption>
          <ScopedComponent whitelist={customerEmails.emails}>
            <ViewTypeSelectOption value="description">Desc.</ViewTypeSelectOption>
          </ScopedComponent>
          <ViewTypeSelectOption id="view-type-radio-like" value="like">Similar</ViewTypeSelectOption>
        </ViewTypeSelect>
      </ViewTypeSelectWrapper>
      <NoLeftMarginViewTypeRadioGroup value={_sortOrder} buttonStyle="solid" onChange={onChangeView}>
        <ViewTypeRadioButton
          value="sku"
        >
          Sku
        </ViewTypeRadioButton>
        <ScopedComponent whitelist={customerEmails.emails}>
          <ViewTypeRadioButton
            value="description"
          >
            Description
          </ViewTypeRadioButton>
        </ScopedComponent>
        <ViewTypeRadioButton
          id="view-type-radio-like"
          value="like"
        >
          Similar
        </ViewTypeRadioButton>
      </NoLeftMarginViewTypeRadioGroup>
    </PartViewerFilterWrapper>
  );
};

export default PriceSortSelector;
