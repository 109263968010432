import React from 'react';
import { findIndex } from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { DeleteForever } from '@styled-icons/material';
import styled from 'styled-components';
import { Button } from 'antd';
import { bomEditedAtom, partBomItemsAtom } from 'shared/state/partViewState';
import theme from 'shared/theme';

interface IComponent {
  bomItemId: string;
}
const ComponentButton = styled(Button)`
  border-radius: 8px;
  background-color: ${theme.palette.error.hue};
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${theme.palette.error.D100};
  }
`;
const DeleteIcon = styled(DeleteForever)`
  color: white;
  width: 20px;
  height: 20px;
`;

const DeleteBomItemButton = ({ bomItemId }: IComponent) => {
  const [bomItems, setBomItems] = useRecoilState(partBomItemsAtom);
  const setBomEdited = useSetRecoilState(bomEditedAtom);

  const onClick = (e: any) => {
    setBomEdited(true);
    const index = findIndex(bomItems, (b) => b.uniqueID === bomItemId);
    setBomItems([...bomItems.slice(0, index), ...bomItems.slice(index + 1)]);
  };

  return (<ComponentButton onClick={onClick} shape="circle" type="default" icon={<DeleteIcon />} />);
};

export default DeleteBomItemButton;
