import React from 'react';
import styled from 'styled-components';
import { FlexRow } from '../../../shared/containers/FlexContainer';

const ItemHeader = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  padding: 12px 8px 0 8px;
  gap: 8px;
`;

const HeaderText = styled.h3`
  margin: 0;
  text-align: left;
`;
const VendorCode = styled(HeaderText)`
  width: 10%;
`;
const VendorName = styled(HeaderText)`
  width: 30%;
`;
const VendorType = styled(HeaderText)`
  width: 15%;
`;
const VendorEmail = styled(HeaderText)`
  width: 50%;
`;
const VendorContactPhone = styled(HeaderText)`
  width: 5%;
`;
const VendorURL = styled(HeaderText)`
  width: 20%;
`;
const InventoryHeader = () => (
  <ItemHeader>
    <VendorCode>Code</VendorCode>
    <VendorName>Name</VendorName>
    <VendorType>Phone Number</VendorType>
    <VendorEmail>Email</VendorEmail>
    {/* <VendorURL>URL</VendorURL> */}
  </ItemHeader>
);

export default InventoryHeader;
