import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { selectedCustomerShippingAddressAtom } from 'shared/state/customerState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';

const ComponentWrapper = styled(FlexColumn)`
  align-items: flex-start;
  margin-top: 4px;
  padding-left: 16px;
  gap: 4px;
  & * {
    margin: 0;
  }
`;

const CityStateZipRow = styled(FlexRow)`
  justify-content: flex-start;
  & * {
    margin: 0;
  }
  gap: 2px;
`;

const OrderShippingAddress = () => {
  const selectedShippingAddress = useRecoilValue(selectedCustomerShippingAddressAtom);

  return (
    <>
      {selectedShippingAddress && (
      <ComponentWrapper>
        <p>{selectedShippingAddress.Line1}</p>
        <p>{selectedShippingAddress.Line2}</p>
        {!!selectedShippingAddress?.Line3?.length && (
        <p>{selectedShippingAddress.Line3}</p>
        )}
        {!!selectedShippingAddress?.Line4?.length && (
        <p>{selectedShippingAddress.Line4}</p>
        )}
        <CityStateZipRow>
          <p>{`${selectedShippingAddress.City},`}</p>
          <p>{selectedShippingAddress.CountrySubDivisionCode}</p>
          <p>{selectedShippingAddress.PostalCode}</p>
        </CityStateZipRow>
        <p>{selectedShippingAddress.Country}</p>
      </ComponentWrapper>
      )}
    </>
  );
};

export default OrderShippingAddress;
