import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { find, includes, sortBy } from 'lodash';
import { IRouterStep } from 'pages/ProductionSchedule/types';
import { routerStepsAtom } from 'shared/state/routingState';
import HelpButton from 'shared/components/Utility/HelpButton';
import DetailDropdownWithCallback from 'shared/components/Input/DetailDropdownWithCallback';
import { IActionOption } from 'shared/types';
import styled from 'styled-components';
import { ncCorrectiveActionsObjectAtom, ncReasonsObjectAtom } from '../../../../shared/state/utilState';
import { DetailRow } from '../../../../shared/styledComponents/containers';
import theme from '../../../../shared/theme';

interface IComponent {
  department: string;
  reasonCodeCallback: (value: string) => void;
  correctiveActionCallback: (value: string) => void;
  exceptionCode: string;
  correctiveActionCode: string;
}

const QADetailRow = styled(DetailRow)`
    flex-wrap: wrap;
    @media ${theme.device.laptop} {
        flex-wrap: unset;
    }
`;
const QATicketReasonActionDropdowns = ({
  department, reasonCodeCallback, correctiveActionCallback, exceptionCode, correctiveActionCode,
}: IComponent) => {
  const [selectedReason, setSelectedReason] = useState<string>(exceptionCode);
  const [selectedAction, setSelectedAction] = useState<string>(correctiveActionCode);
  const nonConformanceReasonCodes = useRecoilValue(ncReasonsObjectAtom);
  const correctiveActions = useRecoilValue(ncCorrectiveActionsObjectAtom);
  const [reasonsOptions, setReasonsOptions] = useState<IActionOption[]>([]);
  const [actionsOptions, setActionsOptions] = useState<IActionOption[]>([]);

  const onChangeReasonCode = (value: string) => {
    setSelectedReason(value);
    reasonCodeCallback(value);
  };

  const onChangeCorrectiveAction = (value: string) => {
    setSelectedAction(value);
    correctiveActionCallback(value);
  };

  useEffect(() => {
    const reasons = Object.values(nonConformanceReasonCodes).filter((r: any) => includes(r.departments, 'any') || includes(r.departments, department));
    const _optionsList = sortBy(reasons.map((r: any) => ({
      value: r.label,
      label: r.label,
    })), (o: IActionOption) => o.value);
    setReasonsOptions(_optionsList);

    const actions = Object.values(correctiveActions).filter((r: any) => includes(r.departments, 'any') || includes(r.departments, department));
    const _actionOptionsList = sortBy(actions.map((r: any) => ({
      value: r.label,
      label: r.label,
    })), (o: IActionOption) => o.value);
    setActionsOptions(_actionOptionsList);
  }, [department]);

  useEffect(() => {
    setSelectedReason(exceptionCode);
  }, [exceptionCode]);

  useEffect(() => {
    setSelectedAction(correctiveActionCode);
  }, [correctiveActionCode]);

  return (
    <QADetailRow gap="20px">
      <DetailDropdownWithCallback
        width="248px"
        id="qa-ticket-reason-code-list"
        listPrompt="Select Non-conformance Reason"
        label="Reason Code"
        optionsList={reasonsOptions}
        selectedOption={selectedReason}
        LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="Select the best-fit option for how the non-conformance occurred." />}
        changeCallback={onChangeReasonCode}
      />
      <DetailDropdownWithCallback
        width="248px"
        id="qa-ticket-corrective-action-list"
        listPrompt="Select Corrective Action"
        label="Corrective Action"
        optionsList={actionsOptions}
        selectedOption={selectedAction}
        LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="Select the action taken in response to this non-conformance." />}
        changeCallback={onChangeCorrectiveAction}
      />
    </QADetailRow>
  );
};

export default QATicketReasonActionDropdowns;
