import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { ArrowForward } from '@styled-icons/material';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MESSAGE_DB_PATH_ATOM, showMessageCenterAtom } from '../../../../shared/state/messageState';
import { stageRedirect } from '../../../../shared/util';

const ComponentButton = styled(Button)`
  width: 32px;
  height: 32px;
  border: none;
`;

const JumpIcon = styled(ArrowForward)`
  width: 28px;
  height: 28px;
  color: ${theme.palette.primary.hue};
`;

interface IComponent {
  id: string;
  url: string;
}
const JumpButton = ({ id, url }: IComponent) => {
  const setShowMessageCenterDrawer = useSetRecoilState(showMessageCenterAtom);
  // @ts-ignore
  const onClick = (e: any) => {
    setShowMessageCenterDrawer(false);
    stageRedirect(window.location.pathname);
    window.location.href = url;
  };

  return (
    <Tooltip
      placement="left"
      title="Jump to item"
    >
      <ComponentButton key={`message-jump-${id}`} shape="circle" onClick={onClick} type="ghost" icon={<JumpIcon />} />
    </Tooltip>
  );
};

export default JumpButton;
