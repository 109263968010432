import React, { useContext, useEffect, useState } from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import firebase from 'firebase';
import { some } from 'lodash';
import theme from 'shared/theme';
import {
  currentShopOrderAtom,
  ORDERS_DB_COLLECTION,
} from 'shared/state/orderState';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { MESSAGE_DB_PATH_ATOM, orderConfirmationNotificationUsersAtom } from 'shared/state/messageState';
import { IOrderItem } from 'shared/types/dbRecords';
import { isCustomerPart } from 'shared/data/order';

export const ConfirmationButton = styled(Button)`
  background-color: ${theme.palette.primary.hue};
  border-radius: 8px;
  color: white;
  font-weight: 500;
`;

export const ConfirmedButton = styled(Button)`
  background-color: ${theme.palette.success.hue};
  border-radius: 8px;
  color: white;
  pointer: default;
  &:hover {
    pointer: default;
    background-color: ${theme.palette.success.hue};
    color: white;
  }
`;
interface IComponent {
  orderItems: IOrderItem[];
}

const ConfirmOrderButton = ({ orderItems }: IComponent): JSX.Element => {
  const { currentUser } = useContext(AuthContext);
  const { database, firestore } = useFirebase();
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const confirmationNotificationUsers = useRecoilValue(orderConfirmationNotificationUsersAtom);
  const [_orderItems, setOrderItems] = useState<IOrderItem[]>(orderItems);
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const onConfirm = async (e: any) => {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());

    await firestore.collection(ordersDbCollectionString).doc(currentShopOrder.id).update({
      releaseConfirmed: timestamp,
      releaseConfirmedBy: currentUser.email,
    });
    // we only need to send an order confirmation if there are either no order items, or if some of the parts on order are not confirmed
    const sendMaterialConfirmationNotice = some(_orderItems?.filter((o: IOrderItem) => isCustomerPart(o)), (o: IOrderItem) => o.materialsConfirmed === null);
    if (sendMaterialConfirmationNotice) {
      const messageId = shortid.generate();
      // const notificationUsers = confirmationNotificationUsers;
      const notificationUsers = confirmationNotificationUsers.filter((_user: string) => !currentUser.email.match(new RegExp(_user, 'i')));

      if (notificationUsers.length === 0) {
        setCurrentShopOrder({
          ...currentShopOrder,
          releaseConfirmed: timestamp,
          releaseConfirmedBy: currentUser.email,
        });
      } else {
        await database.ref(`${messageDbString}/unread/${messageId}`).set({
          users: notificationUsers,
          id: messageId,
          subject: 'A new order was just confirmed',
          message: `${currentUser.email.split('@')[0]} just confirmed ${currentShopOrder.customer.id} order #${currentShopOrder.salesOrder}. Time to verify the BOM.`,
          clickUrl: `/orders/create?edit=true&orderId=${currentShopOrder.id}&returnView=week`,
          archived: false,
          sent: new Date().getTime(),
        });
        setCurrentShopOrder({
          ...currentShopOrder,
          releaseConfirmed: timestamp,
          releaseConfirmedBy: currentUser.email,
        });
      }
    } else {
      setCurrentShopOrder({
        ...currentShopOrder,
        releaseConfirmed: timestamp,
        releaseConfirmedBy: currentUser.email,
      });
    }
  };
  const onUnconfirm = (e: any) => {
    Modal.confirm({
      content: 'Mark order "not confirmed"?',
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => {
        firestore.collection(ordersDbCollectionString).doc(currentShopOrder.id).update({
          releaseConfirmed: null,
          releaseConfirmedBy: null,
        }).then(() => {
          setCurrentShopOrder({
            ...currentShopOrder,
            releaseConfirmed: null,
            releaseConfirmedBy: null,
          });
        });
      },
    });
  };

  useEffect(() => {
    setOrderItems(orderItems);
  }, [orderItems]);

  return (
    <>
      { currentShopOrder && (
      <>
        { !currentShopOrder.releaseConfirmed ? (
          <ConfirmationButton disabled={_orderItems?.length === 0} key={shortid.generate()} onClick={onConfirm}>Confirm order</ConfirmationButton>
        ) : (
          <ConfirmedButton key={shortid.generate()} onClick={onUnconfirm}>Order confirmed</ConfirmedButton>
        )}
      </>
      )}
    </>
  );
};

export default ConfirmOrderButton;
