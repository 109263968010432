import shortid from 'shortid';
import { IInventoryPart } from '../types/dbRecords';

export const reorderPointNotification = async (
  database: any,
  messageDbString: string,
  notificationUsers: string[],
  notificationPart: IInventoryPart,
) => {
  const messageId = shortid.generate();
  await database.ref(`${messageDbString}/unread/${messageId}`).set({
    users: notificationUsers,
    id: messageId,
    subject: 'Inventory alert: low quantity on part',
    message: `Part ${notificationPart.Sku} (${notificationPart.Description}) is at its reorder quantity threshold. Check quantities on-hand and re-order if low.`,
    clickUrl: `/inventory/edit?partId=${notificationPart.Sku}`,
    archived: false,
    sent: new Date().getTime(),
  });
};

const sendPersonalMessage = async (
  database: any,
  messageDbString: string,
  notificationUsers: string[],
  message: {
    id: string,
    subject: string,
    message: string,
    clickUrl: string,
    archived: boolean,
    sent: number
  },
) => {
  await Promise.all(notificationUsers.map((user) => database.ref(`${messageDbString}/${user}/unread/${message.id}`).set(message))).then(() => {

  });
};
export const nonConformanceNotification = async (
  database: any,
  messageDbString: string,
  message: { subject: string, message: string, clickUrl: string },
  notificationUsers: string[],
) => {
  const messageId = shortid.generate();
  const _message = {
    id: messageId,
    ...message,
    archived: false,
    sent: new Date().getTime(),
  };

  await sendPersonalMessage(database, messageDbString, notificationUsers, _message);
};
