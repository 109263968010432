import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { every, find, includes } from 'lodash';
import { SwapVert } from '@styled-icons/material';
import { IOrderItem } from '../../../../../shared/types/dbRecords';
import { checkGeometricDifferences } from '../../../../../shared/partParser/util';
import neckParser from '../../../../../shared/partParser/neckParser';
import theme from '../../../../../shared/theme';
import { orderType } from '../../../../../shared/data/order';
import bodyParser from '../../../../../shared/partParser/bodyParser';

const ComponentButton = styled(Button)`
    border-radius: 8px;
`;
const ReorderIcon = styled(SwapVert)`
  width: 24px;
  height: 24px;
  margin-left: -4px;
  color: ${theme.palette.success.hue};
  transition: color 0.2s ease-in-out;
`;

interface IComponent {
  orderItems: IOrderItem[];
  callback: (orderItems: IOrderItem[]) => Promise<void>;
}

const OrderItemsByGeometryButton = ({ orderItems, callback }: IComponent) => {
  const [_orderItems, setOrderItems] = useState<IOrderItem[]>(orderItems);

  const processItems = (items: any[], partData: any[], position: number = 0) => {
    if (position >= items.length - 1) {
      return items;
    }

    const current = items[position];
    const currentData = find(partData, (p) => p.Sku === current.Sku);
    const rest = items.slice(position + 1);
    const geometricMatches = rest.filter((part: any) => {
      const data = find(partData, (p) => p.Sku === part.Sku);
      const sameGeometry = every(checkGeometricDifferences(data, currentData));
      return sameGeometry;
    });

    if (geometricMatches.length > 0) {
      console.log(current.Sku, geometricMatches.map((g) => g.Sku));
      const updatedItems = [...items].filter((i: any) => !includes(geometricMatches.map((g) => g.Sku), i.Sku));
      geometricMatches.forEach((m, i) => {
        updatedItems.splice(position + i + 1, 0, m);
      });
      return processItems(updatedItems, partData, position + 1);
    }

    // Recursive call
    return processItems(items, partData, position + 1);
  };
  const onClick = async (e: any) => {
    let partData;
    const orderItemsType = orderType(_orderItems) === 1 ? 'neck' : 'body';
    if (orderItemsType === 'neck') {
      partData = neckParser(orderItems.map((i: IOrderItem) => ['Y', i.Sku, i.Description, i.unitPrice.toString()]));
    } else {
      partData = bodyParser(orderItems.map((i: IOrderItem) => ['Y', i.Sku, i.Description, '0', '', '', '0']));
    }
    const updatedItems = processItems(_orderItems, partData);
    await callback(updatedItems as IOrderItem[]);
  };

  useEffect(() => {
    setOrderItems(orderItems);
  }, [orderItems]);

  return (
    <ComponentButton type="default" onClick={onClick} icon={<ReorderIcon />}>Reorder by Geometry</ComponentButton>
  );
};

export default OrderItemsByGeometryButton;
