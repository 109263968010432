import React from 'react';
import styled from 'styled-components';
import { ItemDetailsLabel } from 'pages/PartDetail/Components/PartDetailFields/styledComponents';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import CustomerSelector from './CustomerSelector';
import CustomerSearchSelect from './CustomerSearchSelect';

const ComponentWrapper = styled(FlexColumn)`
  height: inherit;
  margin-right: 12px;
  align-items: flex-start;
`;
export const CustomerSelectWrapper = styled(FlexRow)`
  justify-content: space-between;
  width: 100%;
`;

interface IComponent {
  showLabel: boolean;
  style: any;
}

export default ({ showLabel = true, style = {} }: IComponent) => (
  <ComponentWrapper>
    <CustomerSearchSelect showLabel={showLabel} style={style} />
  </ComponentWrapper>
);
