import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import theme from 'shared/theme';

interface IComponentInterface {
  record: any;
}

const CustomerLinkButton = styled(Button)`
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin: 0;
  
  @media ${theme.device.laptopL} {
    font-size: ${theme.typography.body.size.small};
  }
`;

export default ({ record }: IComponentInterface) => {
  const onClick = (e: any) => {
    e.preventDefault();
    localStorage.setItem('salesOrderViewType', 'week');
    window.location.hash = record.salesOrder.id;
    window.location.pathname = '/orders';
  };

  return (
    <CustomerLinkButton onClick={onClick}>{record.salesOrder.orderNumber}</CustomerLinkButton>
  );
};
