import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import { Button, Modal } from 'antd';
import { ArrowDropDown } from '@styled-icons/material';
import theme from 'shared/theme';
import { CUSTOMERS_DB_COLLECTION, currentCustomerAtom, customerEditedAtom } from 'shared/state/customerState';
import { useTestData } from 'shared/util';
import useFirebase from 'vendor/Firebase';
/**
 * Button component that is responsible for saving inventory records
 */

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const SaveSpinner = styled.div`
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-left: 32px;

  border-radius: 50%;
  border: 4px solid ${theme.palette.neutral[100]};
  border-top: 4px solid ${theme.palette.success.hue};

  animation: ${rotation} 1s linear infinite;
`;
const DropdownIcon = styled(ArrowDropDown)`
  width: 24px;
  margin-bottom: 2px;
`;

const SaveButton = styled(Button)`
  margin-left: ${theme.spacing(3)};
  background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.success[900])} !important;
  border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.neutral.white)};
  border-radius: ${theme.spacing(1)};
  border: none;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.success.D100)} !important;
    border-color: transparent;
  }
`;
const SaveCustomerRecordButton = () => {
  const { firestore } = useFirebase();
  const customer = useRecoilValue(currentCustomerAtom);
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const setCustomerEdited = useSetRecoilState(customerEditedAtom);
  // @ts-ignore
  const [isSaving, setIsSaving] = useState(false);

  const finishSave = () => {
    setIsSaving(false);
    setCustomerEdited(false);
  };
  // const existingPart = !!partId || !!paste;
  // devLog('SaveRecordButton', 104, `part exists? ${existingPart}`);
  const onSave = (e: any) => {
    // Validation: part cannot be saved unless passing these checks
    if (customer.id === '') {
      Modal.error({
        title: 'No ID!',
        content: 'All customers must have an id to be saved',
      });
      return;
    }
    if (customer.name === '') {
      Modal.error({
        title: 'No Name!',
        content: 'All customers must have a name to be saved',
      });
      return;
    }
    if (customer.shortName === '') {
      Modal.error({
        title: 'No short name!',
        content: 'All customers must have a short name to be saved',
      });
      return;
    }
    // END VALIDATION

    setIsSaving(true);
    firestore.collection(customerDbString).doc(customer.id).set(customer)
      .then(() => {
        if (!useTestData) {
          firestore.collection(`${customerDbString}-test`).doc(customer.id).set(customer).then(() => {
            finishSave();
          })
            .catch((error) => {
              console.error('Error writing document: ', error);
            });
        } else {
          finishSave();
          // window.location.href = `/inventory?partId=${partState.partNumber}`;
        }
      })
      .catch((saveErr) => console.log(saveErr));
  };

  return (
    <>
      {isSaving ? (
        <SaveSpinner />
      ) : (
        <SaveButton id="inventory-record-save-button" type="primary" onClick={onSave} test={useTestData}>Save</SaveButton>
      )}
    </>
  );
};

export default SaveCustomerRecordButton;
