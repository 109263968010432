import styled from 'styled-components';
import { Input } from 'antd';
import { ConfigurationCol, ConfigurationRow } from 'shared/pageElements/styledComponents';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { IArchetypeDetails } from './types';

export const AddPriceGroup = styled(ConfigurationRow)`
    width: 100%;
    
    // align-items: flex-start;
    
    margin: ${theme.spacing(2)} 0 ${theme.spacing(1.5)} 0;
    
    @media ${theme.device.laptop} {
      width: 100%;
    } 
`;

export const AddWoodPriceGroup = styled(ConfigurationCol)`
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   margin: ${theme.spacing(2)} 0 ${theme.spacing(1.5)} 0;
    
    @media ${theme.device.laptop} {
      width: 100%;
    }  
`;

export const AddButtonRow = styled(FlexRow)`
    display: ${(props: { visible: boolean }) => (props.visible ? 'flex' : 'none')};
    margin-bottom: ${theme.spacing(3)};
`;

export const AddArchetype = styled(FlexRow)`
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    
    display: ${(props: { visible: boolean }) => (props.visible ? 'flex' : 'none')};
    margin: 0 ${theme.spacing(1.5)};
    gap: ${theme.spacing(1)}; 
`;

export const AddWoodType = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    display: ${(props: { visible: boolean }) => (props.visible ? 'flex' : 'none')};
    margin: 0 ${theme.spacing(1.5)};
    gap: ${theme.spacing(1)}; 
`;

export const ArchetypeDetails = styled(FlexRow)`
    width: 100%;
    justify-content: ${(props: IArchetypeDetails) => props.justify || 'flex-start'};
    align-items: ${(props: IArchetypeDetails) => props.align || 'center'};
    flex-wrap: ${(props: IArchetypeDetails) => props.wrap || 'no-wrap'};
    // @ts-ignore
    margin-bottom: ${(props: IArchetypeDetails) => props.marginBottom || 0};
    gap: ${theme.spacing(3)};
    flex-grow: 2;
`;

export const ArchetypeActions = styled(FlexRow)`
    justify-content: flex-end;
    gap: ${theme.spacing(0.5)};
    
    margin-top: ${theme.spacing(2)};
`;

export const NewPriceNameInput = styled(Input)`
    font-size: 18px;
    max-width: 60%;
`;
