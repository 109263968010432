import React, { useEffect, useState } from 'react';
import {
  Button, Image, Popover, Tooltip,
} from 'antd';
import { Delete } from '@styled-icons/material';
import { Image as ImageIcon } from '@styled-icons/fa-solid';
import styled from 'styled-components';
import theme from 'shared/theme';
import { rgba } from 'shared/palette/utils';
import { useRecoilValue } from 'recoil';
import { FlexRow } from 'shared/containers/FlexContainer';
import { qaTicketImageEditModeAtom } from 'shared/state/qualityAssuranceState';
import useFirebase from '../../../../vendor/Firebase';
import { SaveSpinner } from '../../../../shared/styledComponents/utility';
import { FlexColumn } from '../../../../shared/containers/FlexContainer/styledComponents';

const shortid = require('shortid');

interface IComponent {
  imageUrl: string;
  deleteCallback: (imageUrl: string) => Promise<boolean>;
}

const PreviewIcon = styled(ImageIcon)`
  width: 18px;
  margin-left: 1px;
  color: ${theme.palette.primary.hue};
`;

const PreviewButton = styled.div`
  width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.neutral.white};
    border-radius: 50%; 
    box-shadow: 1px 1px 2px ${rgba(theme.palette.neutral.black, 0.08)}, -2px -2px 2px ${rgba(theme.palette.neutral.white, 0.08)}, 
      inset 1px 1px 4px ${rgba(theme.palette.neutral.black, 0.02)}; 
    cursor: pointer;
    margin: 2px 0;
    
    transition: all 0.3s ease-in-out;
    
    &:hover ${PreviewIcon} {
        color: ${theme.palette.neutral.white};
    }
     
      @media ${theme.device.laptop} {
        &:hover {
            background-color: ${theme.palette.primary.hue};
        } 
    }
`;

const ImagePreviewDot = styled.div`
  background-color: ${theme.palette.primary.hue};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: calc(50% - 8px);
  border-radius: 4px;
  
  padding: 4px;
  padding-bottom: 0;
  margin-top: 2px;
`;

const ThumbnailWrapper = styled(FlexRow)`
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
`;

const ImageDeleteButton = styled(Button)`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 100;
    background-color: ${theme.palette.error.hue};
    border: none;
    transition: background-color 0.2s ease;
    &:hover {
        background-color: ${theme.palette.error.D100};
        border: none;
    }
`;

const DeletingContainer = styled(FlexColumn)`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    backdrop-filter: blur(8px);
`;
const DeleteSpinner = styled(SaveSpinner)`
    margin-left: 0;
`;
const DeleteCopy = styled.h4`
    margin: 8px 4px;
    color: ${theme.palette.neutral.white};
`;
const DeleteIcon = styled(Delete)`
    width: 24px;
    margin-bottom: 2px;
    color: ${theme.palette.neutral.white};
`;
const QAImageThumbnailPreview = ({ imageUrl, deleteCallback }: IComponent) => {
  const { storage } = useFirebase();
  const [isDeleting, setIsDeleting] = useState(false);
  const editMode = useRecoilValue(qaTicketImageEditModeAtom);

  const onImageDelete = (e: any) => {
    e.stopPropagation();
    setIsDeleting(true);
    storage.refFromURL(imageUrl).delete().then(() => {
      deleteCallback(imageUrl).then(() => {
        setIsDeleting(false);
      });
    });
  };

  return (
    <ImageWrapper>
      {editMode && (
        <ImageDeleteButton onClick={onImageDelete} icon={<DeleteIcon />} />
      )}
      {isDeleting && (
        <DeletingContainer>
          <DeleteSpinner hue={theme.palette.error.hue} />
          <DeleteCopy>Deleting...</DeleteCopy>
        </DeletingContainer>
      )}
      <Image
        key={shortid.generate()}
        src={imageUrl}
      />
    </ImageWrapper>
  );
};

export default QAImageThumbnailPreview;
