import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { Summarize } from '@styled-icons/material';
import { useSetRecoilState } from 'recoil';
import theme from 'shared/theme';
import { currentQaTicketAtom, showNCReportAtom } from 'shared/state/qualityAssuranceState';
import { IQATicket } from 'shared/types/dbRecords';

const ReportButton = styled(Button)``;
const ReportIcon = styled(Summarize)`
  width: 22px;
  margin-bottom: 3px;
  color: ${theme.palette.primary.hue} !important;
`;

interface IComponent {
  qaTicket: IQATicket
}
const CopyItemButton = ({ qaTicket }: IComponent) => {
  const setCurrentQATicket = useSetRecoilState(currentQaTicketAtom);
  const setShowReportPDF = useSetRecoilState(showNCReportAtom);
  const onClick = (e: any) => {
    e.stopPropagation();
    setCurrentQATicket(qaTicket);
    setShowReportPDF(true);
  };

  return (
    <Tooltip placement="top" title="View report PDF">
      <ReportButton
        shape="circle"
        // @ts-ignore
        icon={<ReportIcon />}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default CopyItemButton;
