import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, DatePicker, DatePickerProps, Divider, Modal,
} from 'antd';
import {
  find, findIndex, includes, uniq,
} from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';

const ModalContentWrapper = styled(FlexColumn)`
    justify-content: flex-start;
    align-items: flex-start;
`;

const ModalSyncButton = styled(Button)`
    border-radius: 8px;
`;
const ModalCancelButton = styled(ModalSyncButton)``;

const ProductTypeCheckbox = styled(Checkbox)`
    margin-left: 0 !important;
    margin-bottom: ${(props: any) => (props.marginBottom ? '8px' : '0')};
`;

interface IComponent {
  showModal: boolean;
  closeCallback: (e: any) => void;
  syncCallback: (inactiveParts: boolean, nonCountedParts: boolean, nonInventoryParts: boolean, productCodes: string[]) => void;
}
const InventorySyncModal = ({ closeCallback, showModal, syncCallback }: IComponent) => {
  const [syncDate, setSyncDate] = useState<moment.Moment|null>(null);
  const [syncButtonText, setSyncButtonText] = useState<string>('Sync Now');
  const [synchronizing, setSynchronizing] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(showModal);
  const inactivePartsCode = 'Inactive Parts';
  const nonCountedPartsCode = 'Non-Counted Parts';
  const nonInventoryPartsCode = 'Non-Inventory Parts';

  const productCodes = [inactivePartsCode, nonCountedPartsCode, nonInventoryPartsCode, 'BODYBLANK', 'BODYTOP', 'LUMBER', 'NECKWOOD', 'NECKPARTS', 'SHIPMATLS'];
  const [productCodesExportList, setProductCodesExportList] = useState<string[]>(productCodes.filter((c) => (!includes([inactivePartsCode, nonCountedPartsCode, nonInventoryPartsCode], c))));

  const onSync = async (e: any) => {
    const inactiveParts = !!find(productCodesExportList, (c) => c === inactivePartsCode);
    const nonCountedParts = !!find(productCodesExportList, (c) => c === nonCountedPartsCode);
    const nonInventoryParts = !!find(productCodesExportList, (c) => c === nonInventoryPartsCode);
    syncCallback(inactiveParts, nonCountedParts, nonInventoryParts, productCodesExportList.filter((c) => !includes([inactivePartsCode, nonCountedPartsCode], c)));
  };

  const onCheckProductCode = (code: string) => (e: any) => {
    const newList = e.target.checked ? uniq([...productCodesExportList, code]) : productCodesExportList.filter((s) => s !== code);
    setProductCodesExportList(newList);
  };

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  return (
    <Modal
      onCancel={closeCallback}
      title="Export inventory detail"
      open={modalOpen}
      footer={[
        <ModalCancelButton key="cancel" onClick={closeCallback}>
          Close
        </ModalCancelButton>,
        <ModalSyncButton key="sync" type="primary" loading={synchronizing} onClick={onSync}>
          Export .csv
        </ModalSyncButton>,
      ]}
    >
      <ModalContentWrapper>
        <>
          <h4>Select product types to export</h4>
          <>
            {productCodes.map((p: string) => (
              <>
                <ProductTypeCheckbox marginBottom={p === nonInventoryPartsCode} onChange={onCheckProductCode(p)} checked={includes(productCodesExportList, p)}>{p}</ProductTypeCheckbox>
              </>
            ))}
          </>
          <Divider />
        </>
      </ModalContentWrapper>
    </Modal>
  );
};

export default InventorySyncModal;
