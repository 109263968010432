import React, { useEffect, useState } from 'react';
import {
  Select,
} from 'antd';
import styled from 'styled-components';
import {
  RecoilState, useRecoilState, useRecoilValue, useSetRecoilState,
} from 'recoil';
import { currentNcRecordAtom } from 'shared/state/utilState';
import { currentShopOrderAtom } from 'shared/state/orderState';
import { ItemDetailsLabel } from '../../styledComponents/inputs';
import HelpButton from '../Utility/HelpButton';
import { FlexColumn, FlexRow } from '../../containers/FlexContainer';
import { IActionOption } from '../../types';

interface IComponent {
  id: string;
  listPrompt: string;
  label: string;
  optionsList: IActionOption[];
  selectedOption?: string;
  changeCallback: (value: string) => void;
  LabelExtra?: any;
  width?: string;
}

const ComponentWrapper = styled(FlexColumn)`
    width: ${(props: { width: string }) => props.width || 'auto'};
    justify-content: flex-start;
    align-items: flex-start;
`;
const ComponentSelect = styled(Select)`
  width: 100%;
  margin-bottom: 8px;
  
  & .ant-select-selector {
    height: 48px;
    line-height: 48px;
  }
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;
const DetailDropdownWithCallback = ({
  id, label, listPrompt, optionsList, changeCallback, width = '200px', selectedOption = undefined, LabelExtra = <div />,
}: IComponent) => {
  const [_optionsList, setOptionsList] = useState<IActionOption[]>(optionsList);
  const [currentOption, setCurrentOption] = useState<string|null>(null);

  const onMenuClick = (value: string) => {
    setCurrentOption(value);
    changeCallback(value);
  };

  useEffect(() => {
    setOptionsList(optionsList);
  }, [optionsList]);

  useEffect(() => {
    if (selectedOption) setCurrentOption(selectedOption);
  }, [selectedOption]);
  return (
    <ComponentWrapper width={width}>
      <LabelWrapper>
        <ItemDetailsLabel key={`${id}-item-details-wrapper`}>{label}</ItemDetailsLabel>
        {LabelExtra}
      </LabelWrapper>
      { optionsList && (
      <ComponentSelect value={currentOption || listPrompt} onChange={onMenuClick}>
        {_optionsList.map((o) =>
          <Select.Option key={`${o.label}-select-option`} value={o.label}>{o.label}</Select.Option>)}
      </ComponentSelect>
      )}
    </ComponentWrapper>
  );
};

export default DetailDropdownWithCallback;
