import React, { useContext, useState, useEffect } from 'react';
import {
  Menu, Radio, Modal,
} from 'antd';
import styled from 'styled-components';
import { Search } from '@styled-icons/fa-solid';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  adminUserEmailsAtom,
  inProcessValueAtom, productionItemsAtom, queueItemsAtom, routerMachineTypeAtom,
  searchModalAtom, timeMachineAtom,
} from 'shared/state/routingState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { numberWithCommas } from 'shared/text';
import { Body } from 'shared/typography';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';

import { isSameDay } from 'shared/data/calendar';
import { ButtonIsland } from 'shared/styledComponents/containers';
import {
  RouterTableHeader,
  RouterTableHeaderWrapper,
  RunnerColumn,
  RouterSectionHeaderWrapper,
  RunnerDesktopButton,
  RunnerHeaderRow,
  TableSectionHeaderWrapper,
  RouterTableHeaderDates,
  RouterTableHeaderWhiteDivider,
  RouterStatHeaderWrapper,
  RouterTableSubHeader, RouterTablePartsPerDay, RouterTableHeaderDivider,
} from './styledComponents';
import SearchModal from './Components/SearchModal';
import BodyRouter from './BodyRouter';
import NeckRouter from './NeckRouter';
import useFirebase from '../../vendor/Firebase';

import {
  NoLeftMarginViewTypeRadioGroup,
  ViewTypeRadioButton,
  ViewTypeSelect,
  ViewTypeSelectLabel,
  ViewTypeSelectOption,
} from '../../shared/styledComponents/inputs';
import { IShopOrder } from '../Orders/types';
import theme from '../../shared/theme';
import { PageTitle } from '../../shared/styledComponents/typographicElements';

const _ = require('lodash');
const shortid = require('shortid');

const HeaderRow = styled(RunnerHeaderRow)`
    align-items: center;
`;
const ViewTypeSelectWrapper = styled(FlexColumn)`
    position: relative;
    bottom: 10px;
`;
export default () => {
  const { functions } = useFirebase();
  const { currentUser } = useContext(AuthContext) as { currentUser: any };
  const adminEmails = useRecoilValue(adminUserEmailsAtom);

  const [, setShopOrders] = useState<IShopOrder[]>([]);
  const [, setShowSearchModal] = useRecoilState(searchModalAtom);
  const inProcessValue = useRecoilValue(inProcessValueAtom);
  const [routerMachineType, setRouterMachineType] = useRecoilState(routerMachineTypeAtom);
  const [queueItems, setQueueItems] = useRecoilState(queueItemsAtom);
  const [productionItems, setProductionItems] = useRecoilState(productionItemsAtom);
  const defaultViewType = localStorage.getItem('routerViewType');
  const defaultViewLabel = defaultViewType && defaultViewType.match(/body/i) ? 'Body' : 'Neck';
  const defaultMachineType = localStorage.getItem('routerMachineType') || 'All';
  const [viewType, setViewType] = useState(defaultViewLabel);
  const [timeMachine, setTimeMachine] = useRecoilState(timeMachineAtom);
  const [atCurrentDate, setAtCurrentDate] = useState(false);

  const onChangeView = (e: any) => {
    setShopOrders([]);
    setProductionItems(0);
    setQueueItems(0);

    const value = e.target?.value || e;
    localStorage.setItem('routerViewType', value);
    setViewType(value.match(/body/i) ? 'Body' : 'Neck');
  };

  const onChangeMachine = (e: any) => {
    localStorage.setItem('routerMachineType', e.target.value);
    setRouterMachineType(e.target.value);
  };

  const menu = (
    <Menu onClick={onChangeView}>
      <Menu.Item key="Body">
        Body Router
      </Menu.Item>
      <Menu.Item key="Neck">
        Neck Router
      </Menu.Item>
    </Menu>
  );

  const onRequestWIPReport = (e: any) => {
    functions.httpsCallable('endOfMonthWIPCallable')({ foobar: 'snafu' }).then((result) => {
      Modal.info({
        content: 'Proccesing WIP Report—it will arrive in your inbox in a few minutes.',
        okText: 'OK',
      });
    });
  };

  const onStepSummaryReport = (e: any) => {
    functions.httpsCallable('routerStepSummaryCallable')({ foobar: 'snafu' }).then((result) => {
      Modal.info({
        content: 'Proccesing Step Summary Report—it will arrive in your inbox in a few minutes.',
        okText: 'OK',
      });
    });
  };

  const adjustTimeMachine = (amount: number) => (e: any) => {
    e.preventDefault();
    const newDate = new Date(timeMachine);

    if (newDate.getDay() === 1) {
      if (amount < 0) newDate.setDate(newDate.getDate() - 2);
    } else if (newDate.getDay() === 5) {
      if (amount > 0) newDate.setDate(newDate.getDate() + 2);
    }
    newDate.setDate(newDate.getDate() + amount);
    setTimeMachine(newDate);
  };

  const setTMDate = (date: any) => {
    setTimeMachine(new Date(date.year(), date.month(), date.date()));
  };

  const onClickSearch = (e: any) => {
    setShowSearchModal(true);
  };

  useEffect(() => {
    setAtCurrentDate(isSameDay(timeMachine, new Date()));
  }, [timeMachine]);

  // @ts-ignore
  return (
    <>
      <SearchModal />
      <RunnerColumn key={shortid.generate()}>
        <HeaderRow key={shortid.generate()}>
          <PageTitle key={shortid.generate()}>Router</PageTitle>
          <ButtonIsland noMinWidth flexGrow={0} padding="8px 16px 8px 8px">
            <ViewTypeSelectWrapper>
              <ViewTypeSelectLabel>Part view</ViewTypeSelectLabel>
              <ViewTypeSelect
                label="Part view"
                size="default"
                value={viewType}
                onChange={onChangeView}
              >
                <ViewTypeSelectOption value="Body">Body Router</ViewTypeSelectOption>
                <ViewTypeSelectOption value="Neck">Neck Router</ViewTypeSelectOption>
              </ViewTypeSelect>
            </ViewTypeSelectWrapper>
            <NoLeftMarginViewTypeRadioGroup value={viewType} buttonStyle="solid" onChange={onChangeView}>
              <ViewTypeRadioButton
                value="Body"
              >
                Body Router
              </ViewTypeRadioButton>
              <ViewTypeRadioButton
                value="Neck"
              >
                Neck Router
              </ViewTypeRadioButton>
            </NoLeftMarginViewTypeRadioGroup>
            <RunnerDesktopButton
              key={shortid.generate()}
              type="default"
              shape="round"
              onClick={onClickSearch}
              icon={<Search style={{ width: 12, marginRight: 8 }} />}
            >
              Search
            </RunnerDesktopButton>
            {_.includes(['bruceh@wildwoodmfg.com', 'keithh@wildwoodmfg.com', 'cathyh@wildwoodmfg.com'], currentUser.email)
              && (
              <>
                <RunnerDesktopButton
                  key="WIP"
                  type="default"
                  onClick={onRequestWIPReport}
                >
                  Get WIP Report
                </RunnerDesktopButton>
              </>
              )}
            {_.includes(['keithh@wildwoodmfg.com'], currentUser.email)
                && (
                <RunnerDesktopButton
                  key="StepSummary"
                  type="default"
                  onClick={onStepSummaryReport}
                >
                  Get Router Step Summary
                </RunnerDesktopButton>
                )}
          </ButtonIsland>
        </HeaderRow>
        <RouterSectionHeaderWrapper>
          <TableSectionHeaderWrapper>
            <RouterTableHeaderWrapper id={shortid.generate()} key={shortid.generate()}>
              <RouterTableHeader>{`${viewType} Router`}</RouterTableHeader>
              <RouterTableHeaderWhiteDivider />
              <RouterTableHeaderDates>{`${productionItems + queueItems} total ${viewType.match(/body/i) ? 'bodies' : 'necks'}`}</RouterTableHeaderDates>
            </RouterTableHeaderWrapper>
          </TableSectionHeaderWrapper>
          <RouterStatHeaderWrapper key={shortid.generate()}>
            <RouterTableSubHeader>{`${productionItems} in process`}</RouterTableSubHeader>
            <RouterTablePartsPerDay>{`$${numberWithCommas(inProcessValue)} Order value`}</RouterTablePartsPerDay>
            <RouterTableHeaderDivider />
            <RouterTableSubHeader>{`${queueItems} in queue`}</RouterTableSubHeader>
          </RouterStatHeaderWrapper>
        </RouterSectionHeaderWrapper>
        <>
          {viewType.match(/body/i)
            && <BodyRouter />}
          {viewType.match(/neck/i)
            && <NeckRouter />}
        </>
      </RunnerColumn>
    </>
  );
};
