import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import { noop } from 'lodash';
import { useRecoilValue } from 'recoil';
import { currentShopOrderAtom, orderItemsAtom } from 'shared/state/orderState';
import theme from 'shared/theme';
import { ConfirmationButton } from './ConfirmOrderButton';

const MaterialConfimButton = styled(ConfirmationButton)`
  border-radius: 0;
  opacity: ${(props: { confirmed: boolean }) => (props.confirmed ? 1 : 0.2)};
  background-color: ${(props: { color: string }) => props.color};
  color: ${theme.palette.neutral.white};
  &:hover {
    &:disabled {
      background-color: ${(props: { color: string }) => props.color};
      color: ${theme.palette.neutral.white};
      cursor: default;
    }
  }
  &:disabled {
    background-color: ${(props: { color: string }) => props.color};
    color: ${theme.palette.neutral.white};
    cursor: default;
  }
  cursor: default;
`;

const ConfirmMaterialsButton = (): JSX.Element => {
  const orderItems = useRecoilValue(orderItemsAtom);
  const currentShopOrder = useRecoilValue(currentShopOrderAtom);

  return (
    <>
      { (currentShopOrder && orderItems.filter((i) => i).length > 0) && (
        <MaterialConfimButton 
          disabled={orderItems.filter((i) => i?.Sku.match(/[A-Z]{5}/)).every((i) => i?.materialsConfirmed)}
          key={shortid.generate()}
          onClick={noop}
          color={orderItems.filter((i) => i?.Sku.match(/[A-Z]{5}/)).every((i) => i?.materialsConfirmed) ? theme.palette.success.hue : theme.palette.primary.hue}
          confirmed={orderItems.filter((i) => i?.Sku.match(/[A-Z]{5}/)).every((i) => i?.materialsConfirmed)}
        >
          Materials confirmed
        </MaterialConfimButton>
      )}
    </>
  );
};

export default ConfirmMaterialsButton;
