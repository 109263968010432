import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { find, findIndex } from 'lodash';
import styled from 'styled-components';
import {
  currentCustomerAtom,
  currentCustomerShippingAddressesAtom,
  customerShippingAddressesEditedAtom,
  selectedCustomerShippingAddressAtom,
} from 'shared/state/customerState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import NotFound from 'shared/components/Utility/NotFound';
import { IShippingAddress } from 'shared/types/dbRecords';
import DetailInputWithCallback from '../../../../shared/components/Input/DetailInputWithCallback';
import SaveBillingAddressButton from './Buttons/SaveBillingAddressButton';

const ComponentWrapper = styled(FlexColumn)`
  align-items: flex-start;
  width: 100%;
  margin-top: 34px;
`;

const AddressSelectorRow = styled(FlexRow)`
  width: calc(100% - 12px);
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
`;

const AddressFieldWrapper = styled.div`
    width: 40%;
`;

const DetailRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 32px;
`;
const DetailRowCity = styled.div`
    width: 40%;
`;
const DetailRowStateZip = styled.div`
    width: 15%;
`;

const SearchDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: lightgrey;
  margin: 36px 0 16px 0;
`;
const BillingAddressDetail = () => {
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const [edited, setEdited] = useState<boolean>(false);
  // @ts-ignore

  const onChangeLine1 = (newValue: string) => {
    setEdited(true);
    setCurrentCustomer({
      ...currentCustomer,
      BillAddr: {
        ...currentCustomer.BillAddr,
        Line1: newValue,
      },
    });
  };
  const onChangeLine2 = (newValue: string) => {
    setEdited(true);
    setCurrentCustomer({
      ...currentCustomer,
      BillAddr: {
        ...currentCustomer.BillAddr,
        Line2: newValue,
      },
    });
  };
  const onChangeLine3 = (newValue: string) => {
    setEdited(true);
    setCurrentCustomer({
      ...currentCustomer,
      BillAddr: {
        ...currentCustomer.BillAddr,
        Line3: newValue,
      },
    });
  };
  const onChangeLine4 = (newValue: string) => {
    setEdited(true);
    setCurrentCustomer({
      ...currentCustomer,
      BillAddr: {
        ...currentCustomer.BillAddr,
        Line4: newValue,
      },
    });
  };

  const onChangeCity = (newValue: string) => {
    setEdited(true);
    setCurrentCustomer({
      ...currentCustomer,
      BillAddr: {
        ...currentCustomer.BillAddr,
        City: newValue,
      },
    });
  };
  const onChangeState = (newValue: string) => {
    setEdited(true);
    setCurrentCustomer({
      ...currentCustomer,
      BillAddr: {
        ...currentCustomer.BillAddr,
        CountrySubDivisionCode: newValue,
      },
    });
  };
  const onChangeZip = (newValue: string) => {
    setEdited(true);
    setCurrentCustomer({
      ...currentCustomer,
      BillAddr: {
        ...currentCustomer.BillAddr,
        PostalCode: newValue,
      },
    });
  };
  return (
    <>
      {currentCustomer.BillAddr ? (
        <ComponentWrapper>
          <AddressSelectorRow>
            <SaveBillingAddressButton disabled={!edited} />
          </AddressSelectorRow>
          <SearchDivider />
          <AddressFieldWrapper>
            <DetailInputWithCallback
              id="billing-address-line-1-input"
              callback={onChangeLine1}
              extend
              isLabel={false}
              label="Billing Contact"
              placeholder="e.g., John Smith"
              value={currentCustomer.BillAddr.Line1}
              width="100%"
            />
          </AddressFieldWrapper>
          <AddressFieldWrapper>
            <DetailInputWithCallback
              id="billing-address-line-2-input"
              callback={onChangeLine2}
              extend
              isLabel={false}
              label="Address Line 1"
              placeholder="e.g., 1234 Anystreet"
              value={currentCustomer.BillAddr.Line2}
              width="100%"
            />
          </AddressFieldWrapper>
          <AddressFieldWrapper>
            <DetailInputWithCallback
              id="billing-address-line-2-input"
              callback={onChangeLine3}
              extend
              isLabel={false}
              label="Address Line 2"
              placeholder="e.g., Building A"
              value={currentCustomer.BillAddr.Line3}
              width="100%"
            />
          </AddressFieldWrapper>
          <AddressFieldWrapper>
            <DetailInputWithCallback
              id="billing-address-line-4-input"
              callback={onChangeLine4}
              extend
              isLabel={false}
              label="Address Line 3"
              placeholder="e.g., Suite 4"
              value={currentCustomer.BillAddr.Line4}
              width="100%"
            />
          </AddressFieldWrapper>

          <DetailRow key="address-city-state-zip-row">
            <DetailRowCity>
              <DetailInputWithCallback
                id="billing-address-city-input"
                callback={onChangeCity}
                extend
                isLabel={false}
                label="City"
                placeholder="Anytown"
                value={currentCustomer.BillAddr.City}
                width="40%"
              />
            </DetailRowCity>
            <DetailRowStateZip>
              <DetailInputWithCallback
                id="billing-address-state-input"
                callback={onChangeState}
                extend
                isLabel={false}
                label="State"
                placeholder="CA"
                value={currentCustomer.BillAddr.CountrySubDivisionCode}
                width="15%"
              />
            </DetailRowStateZip>
            <DetailRowStateZip>
              <DetailInputWithCallback
                id="billing-address-zip-input"
                callback={onChangeZip}
                extend
                isLabel={false}
                label="Postal Code"
                placeholder="12345"
                value={currentCustomer.BillAddr.PostalCode}
                width="15%%"
              />
            </DetailRowStateZip>
            {/* <ShippingState key="address-state-input" /> */}
            {/* <ShippingZip key="address-zip-input" /> */}
          </DetailRow>
          {/* <ShippingCountry key="address-country-input" /> */}
        </ComponentWrapper>
      ) : (
        <NotFound compact title="No addresses found" key="addresses-not-found-placeholder" />
      )}
    </>
  );
};

export default BillingAddressDetail;
