import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { findIndex } from 'lodash';
import theme from 'shared/theme';
import useFirebase from 'vendor/Firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  contactEditModeAtom,
  currentCustomerAtom,
  currentCustomerContactsAtom,
  CUSTOMER_CONTACT_DB_COLLECTION,
  selectedCustomerContactAtom,
} from 'shared/state/customerState';
import { IContact } from 'shared/types/jb';
import { qboDateString } from '../../../../../shared/data/calendar';

const SaveButton = styled(Button)`
  margin: 0 0 6px 0;
  padding: 2px 8px;
  background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.success[900])} !important;
  border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.neutral.white)};
  border-radius: ${theme.spacing(1)};
  border: none;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.success.D100)} !important;
    border-color: transparent;
  }
`;

interface IComponent {
  callback: (newContacts: IContact[]) => void;
}
const SaveContactButton = ({ callback }: IComponent) => {
  const { firestore } = useFirebase();
  const customerContactDbString = useRecoilValue(CUSTOMER_CONTACT_DB_COLLECTION);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const [currentCustomerContacts, setCurrentCustomerContacts] = useRecoilState(currentCustomerContactsAtom);
  const selectedContact = useRecoilValue(selectedCustomerContactAtom);
  const setEditMode = useSetRecoilState(contactEditModeAtom);
  const onClick = (e: any) => {
    const selected = findIndex(currentCustomerContacts, (c: IContact) => c.uniqueID === selectedContact.uniqueID);
    const newContacts = [...(currentCustomerContacts || [])];
    const updatedContact = { ...selectedContact, lastModDate: qboDateString(new Date(), true) };
    if (selected === -1) newContacts.push(updatedContact);
    else newContacts[selected] = updatedContact;
    firestore.collection(customerContactDbString).doc(currentCustomer.id).set({ contacts: newContacts }).then(() => {
      callback(newContacts);
      // setCurrentCustomerContacts(newContacts);
      // setEditMode(false);
    });
  };

  return (
    <SaveButton onClick={onClick}>Save</SaveButton>
  );
};

export default SaveContactButton;
