import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button, Dropdown, MenuProps, Modal,
} from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import theme from 'shared/theme';
import { runnerWorkOrdersAtom } from 'shared/state/routingState';
import { generateRunners } from 'shared/router/utils';
import useFirebase from 'vendor/Firebase';
import {
  currentShopOrderAtom, invoicePDFDrawerAtom,
  orderConfirmationPDFDrawerAtom,
  ORDERS_DB_COLLECTION, orderShipmentsAtom, packSlipPDFDrawerAtom,
  workOrderPDFDrawerAtom,
} from 'shared/state/orderState';
import { Simulate } from 'react-dom/test-utils';
import { IRunner } from '../../../types';
import { selectedCustomerShippingAddressAtom } from '../../../../../shared/state/customerState';

const PrintButton = styled(Button)`
  border-radius: 8px;
  //margin-left: 24px;
  //background-color: ${theme.palette.primary.hue};
  //border-color: ${theme.palette.primary.hue};
  //color: ${theme.palette.neutral.white};
  //&:hover {
  //  background-color: ${theme.palette.primary.D100};
  //  border-color: ${theme.palette.primary.D100};
  //  color: ${theme.palette.neutral.white};
  //}
  //&:focus {
  //  background-color: ${theme.palette.primary.hue};
  //  border-color: ${theme.palette.primary.hue};
  //  color: ${theme.palette.neutral.white};
  //}
`;

const PrintModalButton = styled(Button)`
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  width: 50%;
  background-color: unset;
`;

interface IComponent {
  orderId: string;
  shipmentId: string;
}
const PrintDocumentButton = ({ orderId, shipmentId }: IComponent) => {
  const [menuProps, setMenuProps] = useState({ items: [], onClick: null });
  const setInvoicePDFOpen = useSetRecoilState(invoicePDFDrawerAtom);
  const setPackSlipPDFOpen = useSetRecoilState(packSlipPDFDrawerAtom);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    // @ts-ignore - the missing props from the type validation are contained in ...currentShopOrder
    if (e.key === 'packing-slip') setPackSlipPDFOpen(true);
    if (e.key === 'invoice') setInvoicePDFOpen(true);
  };

  // only show the "work order option if work orders exist
  useEffect(() => {
    const menuItems = [
      {
        label: 'Packing slip',
        key: 'packing-slip',
      },
      {
        label: 'Invoice',
        key: 'invoice',
      },
    ];

    setMenuProps({
      items: menuItems,
      onClick: handleMenuClick,
    });
  }, [orderId, shipmentId]);

  return (
    <Dropdown disabled={menuProps?.items?.length === 0} trigger={['click']} menu={menuProps}>
      <PrintButton type="default">
        Print
      </PrintButton>
    </Dropdown>
  );
};

export default PrintDocumentButton;
