import { INCRecord } from '../../shared/types/dbRecords';

// eslint-disable-next-line import/prefer-default-export
export const partDescription = (ncList: INCRecord[], priceCatalogData: any[]) => ncList
  .map((r: INCRecord) => {
    const partNumberInt = parseInt(r.partNumber || '0', 10);
    let fullPartNumber = '';
    if (partNumberInt < 10000) fullPartNumber = `0${partNumberInt}`;
    if (partNumberInt < 1000) fullPartNumber = `00${partNumberInt}`;
    if (partNumberInt < 100) fullPartNumber = `000${partNumberInt}`;
    if (partNumberInt < 10) fullPartNumber = `0000${partNumberInt}`;
    const jbPartNumber = `${r.customerId}_${fullPartNumber}`;
    const description = _.find(priceCatalogData[r.type], (p: any) => p.partNumber === jbPartNumber)?.description;
    return {
      ...r,
      partDescription: description,
    };
  });

export const setRowClass = (record: any) => {
  if (!window.location.hash) return 'nc-standard-row';
  const [orderNumber, ncId] = window.location.hash?.replace('#', '').split('@');
  const isHashed = ncId === record.id;
  const isSameOrder = orderNumber === record.salesOrder;
  return (isHashed || isSameOrder) ? 'nc-highlight-row' : 'nc-standard-row';
};
