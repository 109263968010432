import styled, { keyframes } from 'styled-components';
import theme from '../theme';
import { FlexColumn, FlexRow } from '../containers/FlexContainer';

export const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 8px;
`;

export const SaveSpinner = styled.div`
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-left: 32px;

  border-radius: 50%;
  border: 4px solid ${theme.palette.neutral[100]};
  border-top: ${(props: { hue: string }) => `4px solid ${props.hue ? props.hue : theme.palette.success.hue}`};
  animation: ${rotation} 1s linear infinite;
`;

export const SaveText = styled.p`
    margin: 0;
    color: ${theme.palette.neutral.white};
`;

// used in Inventory / Pricing lists

export const SkuWrapper = styled(FlexColumn)`
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
`;
export const InactiveIndicator = styled.p`
    padding: 0 14px 0 18px;
    margin: 0;
    background-color: lightgrey;
    color: ${theme.palette.neutral[600]};
    font-weight: bold;
    border-radius: 43px;
    font-size: 11px;
    letter-spacing: 4.5px;
`;
