import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IOrderItem } from 'shared/types/dbRecords';
import { currentShopOrderAtom, orderItemsAtom } from 'shared/state/orderState';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWithCallback';

const PartCount = () => {
  const orderItems = useRecoilValue(orderItemsAtom);
  const [currentShopOrder, setCurrentShopOrder] = useRecoilState(currentShopOrderAtom);
  const [value, setValue] = useState<number>(currentShopOrder.partCount);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };
  const onBlur = (newValue: number) => {
    // if we have a sales order and the order items field is being calculated just return
    if (currentShopOrder.salesOrder !== 'PH' && orderItems.filter((o) => o).length > 0) return;

    if (Number.isInteger(newValue)) {
      setValue(newValue);
      setCurrentShopOrder({ ...currentShopOrder, partCount: newValue });
    }
  };

  useEffect(() => {
    if (currentShopOrder.salesOrder === 'PH') setValue(currentShopOrder.partCount);
    else if (orderItems.filter((o: IOrderItem) => o).length === 0) setValue(currentShopOrder.partCount);
    else {
      const newValue = orderItems
        .filter((o: IOrderItem) => !(o?.Sku || '').match(/^9/))
        .filter((o: IOrderItem) => {
          if (currentShopOrder.completed) return o.quantityShipped > 0;
          return o.quantityOpen > 0;
        })
        .map((o: IOrderItem) => (currentShopOrder.completed ? o?.quantityShipped : o?.quantityOpen) || 0)
        .reduce((a: number, b: number) => a + b, 0);
      setValue(newValue);
      setCurrentShopOrder({ ...currentShopOrder, partCount: newValue });
    }
  }, [currentShopOrder.salesOrder, orderItems]);

  return (
    <DetailNumberInputWithCallback
      key="part-count-input"
      label="Part Count"
      placeholder="0"
      value={value}
      callback={onBlur}
      disabled={currentShopOrder.salesOrder !== 'PH' && orderItems.filter((o) => o).length > 0}
    />
  );
};

export default PartCount;
