import React from 'react';
import { useRecoilState } from 'recoil';
import { Drawer } from 'antd';
import OrderConfirmationPDF from '../OrderConfirmationPDF';
import { orderConfirmationPDFDrawerAtom } from '../../../shared/state/orderState';

const OrderConfirmationPDFDrawer = () => {
  const [open, setOpen] = useRecoilState(orderConfirmationPDFDrawerAtom);

  const closeDrawer = (e: any) => {
    setOpen(false);
  };

  return (
    <Drawer
      placement="right"
      width="78vw"
      open={open}
      onClose={closeDrawer}
    >
      <OrderConfirmationPDF />
    </Drawer>
  );
};

export default OrderConfirmationPDFDrawer;
