import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import * as shortid from 'shortid';
import { FlexRow } from 'shared/containers/FlexContainer';
import { adminUserEmailsAtom, superAdminUserEmailAtom, supervisorEmailsAtom } from 'shared/state/routingState';
import { INCRecord } from 'shared/types/dbRecords';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import SalesOrderInfoButton from '../Buttons/SalesOrderInfoButton';
import CompleteOrderButton from '../Buttons/CompleteOrderButton';
import { RouterNcIcon, RouterTableQrButton } from '../../styledComponents';
import ScopedComponent from '../../../../shared/components/Utility/ScopedComponent';
import OrderBumpButton from '../Buttons/OrderBumpButton';

interface ComponentInterface {
  shopOrder: any;
}

const ActionRow = styled(FlexRow)`
  justify-content: flex-end;
  padding-right: 24px;
  gap: 12px;

  @media print {
    display: none;
  }
`;

export default ({ shopOrder }: ComponentInterface) => {
  const { currentUser } = useContext(AuthContext);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const supervisorEmails = useRecoilValue(supervisorEmailsAtom);
  const salesOrderContext = localStorage.getItem('salesOrderViewType');
  const isWeekContext = salesOrderContext && salesOrderContext.match(/week/i);

  const onNcClick = (record: any) => (e: any) => {
    const ncHistory = _.sortBy(record.nonConformanceHistory, (nc: INCRecord) => nc.dateRecorded);
    window.location.href = `/nc/report#${record.salesOrder}@${ncHistory[0].id}`;
  };

  return (
    <>
      {_.includes([...superAdminEmails.emails, ...adminEmails.emails, ...supervisorEmails.emails], currentUser.email)
        && (
        <ActionRow key={shortid.generate()}>
          <>
            {shopOrder.nonConformanceHistory?.length && (
            <Tooltip placement="left" title={`${shopOrder.nonConformanceHistory.length} non-conformance${shopOrder.nonConformanceHistory.length > 1 ? 's' : ''} on this order. Click to view.`} maxWidth={500}>
              <RouterNcIcon style={{ right: 8 }} onClick={onNcClick(shopOrder)} />
            </Tooltip>
            )}
          </>
          <>
            {!shopOrder.completed && (
            <ScopedComponent whitelist={['keithh@wildwoodmfg.com']}>
              <OrderBumpButton order={shopOrder} />
              {/* <OrderBumpButton order={shopOrder} reverse={false} /> */}
            </ScopedComponent>
            )}
            {!!shopOrder.runners?.length && !shopOrder.completed ? (
              <CompleteOrderButton shopOrder={shopOrder} />
            ) : (
              <CompleteOrderButton disabled shopOrder={shopOrder} />
            )}
          </>
          <SalesOrderInfoButton key={shortid.generate()} recordId={shopOrder.recordId} customerId={shopOrder.customer.DisplayName} salesOrder={shopOrder.salesOrder} />
        </ActionRow>
        )}
    </>
  );
};
