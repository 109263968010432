import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import { FlexRow } from '../../../shared/containers/FlexContainer';
import { ItemDetailsLabel } from '../../../shared/styledComponents/inputs';
import ActiveSelector from '../../Customer/Components/PriceListInputs/ActiveSelector';
import HelpButton from '../../../shared/components/Utility/HelpButton';

const SettingRow = styled(FlexRow)`
    width: 100%;
    justify-content: space-between;
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

interface IComponent {
  label: string;
  helpMessage: string;
  options: { label: string, value: string }[];
  value: string;
  storageKey: string;
  reloadRequired: boolean;
  callback: (key: string, value: string, reloadRequired: boolean) => void;
}

const ToggleSetting = ({
  label, options, value, storageKey, reloadRequired, callback, helpMessage = '',
}: IComponent) => {
  const [_value, setValue] = useState<string>(value);

  const onToggle = (e: any) => {
    const selected = find(options, (o: { value: string, label: string }) => o.label.toLowerCase() === e);
    setValue(selected?.label.toLowerCase() || _value);
    callback(storageKey, selected?.label.toLowerCase() || value, reloadRequired);
  };

  useEffect(() => {
    const selected = find(options, (o: { value: string, label: string }) => o.value.toLowerCase() === value);
    setValue(selected?.label.toLowerCase() || 'current week');
  }, []);

  return (
    <SettingRow>
      <LabelWrapper>
        <ItemDetailsLabel key={`setting-${label}-label`}>{label}</ItemDetailsLabel>
        <HelpButton
          style={{ marginBottom: 2 }}
          helpMessage={helpMessage}
        />
      </LabelWrapper>
      <ActiveSelector allValue={options[1].label} activeValue={options[0].label} activeType={_value} callback={onToggle} showLabel={false} margin="0px" />
    </SettingRow>
  );
};

export default ToggleSetting;
