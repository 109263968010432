import React from 'react';
import { Button } from 'antd';
import { Add } from '@styled-icons/material';
import shortid from 'shortid';
import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';
import {
  contactEditModeAtom,
  selectedCustomerContactAtom,
} from 'shared/state/customerState';
import { IContact } from 'shared/types/jb';

const ComponentButton = styled(Button)`
  border-radius: 8px;
  margin: 0 0 6px 0;
  padding: 2px 8px;
`;

const AddIcon = styled(Add)`
  width: 20px;
  margin: 0 4px 2px 0;
`;

const NewContactButton = () => {
  const setSelectedContact = useSetRecoilState(selectedCustomerContactAtom);
  const setEditMode = useSetRecoilState(contactEditModeAtom);

  const onClick = (e: any) => {
    e.preventDefault();
    setEditMode(true);
    const newContact = {
      active: true,
      cellPhone: '',
      comments: '',
      contact: '',
      contactCode: '',
      default: false,
      email: '',
      extension: '',
      lastModDate: '',
      mobileEmail: '',
      phone: '',
      title: '',
      uniqueID: shortid.generate(),
    } as IContact;

    setSelectedContact(newContact);
  };

  return (
    <ComponentButton type="primary" onClick={onClick} icon={<AddIcon />}>New</ComponentButton>
  );
};

export default NewContactButton;
