import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { Button, Menu, Modal } from 'antd';
import { find, flatten, sortBy } from 'lodash';
import shortid from 'shortid';
import useFirebase from 'vendor/Firebase';
import {
  bodyDataAtom,
  neckDataAtom,
} from 'shared/state/pricingState';
import { MiniProgressSpinner } from 'shared/pageElements/styledComponents';
import { fetchPricing, formatPrice } from 'shared/data';
import { FlexRow } from 'shared/containers/FlexContainer';
import { PART_PRICING_COLLECTION, PART_VIEWER_COLLECTION, partConfigTermsAtom, uploadProgressAtom } from 'shared/state/partViewState';
import { currentCustomerAtom, customersAtom } from 'shared/state/customerState';
import theme from 'shared/theme';
import { cleanMeta } from 'shared/text';
import { CustomerDownloadButton } from '../styledComponents';

interface IComponent {
  isCustomer: boolean;
}

const ExportModal = styled(Modal)`
  width: 352px !important;
  text-align: center;
  border-radius: 12px;
  
  & .ant-modal-header {
    border-bottom: none;
  }
  
  & .ant-modal-body { 
    display: none;
  }
  
  & .ant-modal-footer {
    display: flex;
    justify-content: center;
    gap: 8px;
    border-top: none;
  }
  
  & .ant-modal-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 28px;
  }
`;
const ExportButton = styled(Button)`
  width: ${(props: any) => (props.isCustomer ? '152px' : 'auto')};
  border-radius: 8px;
  flex-grow: ${(props: any) => (props.isCustomer ? 0 : 2)};
  background-color: ${theme.palette.success.hue};
  border-color: ${theme.palette.success.hue};
  color: ${theme.palette.neutral.white};
  &:hover {
    background-color: ${theme.palette.success.D100};
    border-color: ${theme.palette.success.D100};
    color: ${theme.palette.neutral.white};
  }
  &:focus {
    background-color: ${theme.palette.success.hue};
    border-color: ${theme.palette.success.hue};
    color: ${theme.palette.neutral.white};
  }
`;
const ExportModalButton = styled(Button)`
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  width: 50%;
  background-color: unset;
`;
/**
 * Button component that is responsible for exporting pricing data as a csv file.
 *   By virtue of the way excel4node works, this has been designed as a two-step button,
 *   where the first click prepares the data, the second click downloads.
 */
export default ({ isCustomer = false }: IComponent) => {
  const { firestore } = useFirebase();
  const customers = useRecoilValue(customersAtom);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const bodyData = useRecoilValue(bodyDataAtom);
  const neckData = useRecoilValue(neckDataAtom);
  const partViewerDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const partPricingCollectionDbString = useRecoilValue(PART_PRICING_COLLECTION);
  const configTerms = useRecoilValue(partConfigTermsAtom);
  const uploadInProgress = useRecoilValue(uploadProgressAtom);
  const csvHeaders = ['Active', 'Part Number', 'Part Name', 'Price'];
  const adminHeaders = ['Product Category', 'GL Code', 'Volume', 'Parent', 'Rev?'];
  // @ts-ignore
  const [csvData, setCsvData] = useState<any>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onExport = async () => {
    setModalOpen(false);
    setIsExporting(true);
    const customerComplete = 0;
    const priceRecords = await fetchPricing(firestore, partViewerDbString, partPricingCollectionDbString, null, configTerms, [bodyData, neckData], 'sku');
    // const partData = partDocs.docs.map((d: any) => {
    // const data = d.data();
    // const customer = find(customers, (c: ICustomerRecord) => c.DisplayName === data.customer) as ICustomerRecord;
    // return { ...data, customer };
    // });
    // const priceRecords = processPricingData(partData, [bodyData, neckData], 'sku');
    const newRecords = sortBy(priceRecords, (p: any) => p.Sku).map((price: any) => ([
      price.active ? 'Y' : 'N',
      price.Sku,
      cleanMeta(price.Description || ''),
      formatPrice(Math.round(price.price)),
      'FINISHGOOD',
      '15900',
      price.volume || '',
      `${price.parent || ''}${price.parent ? '.' : ''}${price.Sku}`,
    ]));
    setCsvData([[...csvHeaders, ...adminHeaders], ...newRecords]);
  };
  
  const onCustomerExport = async () => {
    setModalOpen(false);
    setIsExporting(true);
    const prices: any[] = [];
    fetchPricing(firestore, partViewerDbString, partPricingCollectionDbString, currentCustomer, configTerms, [bodyData, neckData]).then((priceRecords) => {
      const newRecords = sortBy(priceRecords, (p: any) => p.Sku).map((price: any) => {
        const defaultData = [
          price.active ? 'Y' : 'N',
          price.Sku,
          cleanMeta(price.Description || ''),
          formatPrice(Math.round(price.price)),
        ];
        if (!isCustomer) {
          defaultData.push('FINISHGOOD');
          defaultData.push('15900');
          defaultData.push(price.volume || '');
          defaultData.push(`${price.parent || ''}${price.parent ? '.' : ''}${price.Sku}`);
          defaultData.push(price.revisionNeeded ? 'Y' : 'N');
        }
        return defaultData;
        // return [
        //   price.active ? 'Y' : 'N',
        //   price.partNumber,
        //   price.description,
        //   isPriced ? discountPrice(price, currentCustomer, 1) : formatPrice(price.price),
        //   'FINISHGOOD',
        //   '15900',
        //   price.volume || '',
        // ];
      });
      let headers = csvHeaders;
      if (!isCustomer) headers = [...csvHeaders, ...adminHeaders];
      prices.push(...newRecords);
      prices.push(new Array(headers.length).fill(''));
      setCsvData([headers, ...prices]);
    });
  };

  const onCancel = (e: any) => {
    setModalOpen(false);
  };

  const onExportClick = (e: any) => {
    if (!isCustomer) setModalOpen(true);
    else onCustomerExport();
    // setModalOpen(true);
  };

  useEffect(() => {
    setIsExporting(false);
    setCsvData([]);
  }, [currentCustomer]);

  return (
    <>
      <ExportModal
        open={modalOpen}
        closeIcon={<span />}
        title="Export which pricing data?"
        onCancel={onCancel}
        footer={[
          <ExportModalButton key="customer" type="primary" onClick={onCustomerExport}>
            {currentCustomer.shortName}
          </ExportModalButton>,
          <ExportModalButton
            key="all"
            type="default"
            onClick={onExport}
          >
            All Customers
          </ExportModalButton>,
        ]}
      />
      {csvData.length > 0
        ? (
          <CustomerDownloadButton
            key={shortid.generate()}
            filename={`Wildwood Pricing Export ${new Date().toLocaleString()}.csv`}
            data={csvData}
            onClick={() => {
              setIsExporting(false);
              setTimeout(() => {
                setCsvData([]);
              }, 1000);
            }}
          >
            Download Pricing
          </CustomerDownloadButton>
        ) : (
          <>
            { isExporting ? (
              <FlexRow style={{ width: 88 }}>
                <MiniProgressSpinner />
              </FlexRow>
            ) : (
              <ExportButton isCustomer={isCustomer} onClick={onExportClick}>Export</ExportButton>
            )}
          </>
        )}
    </>
  );
};
