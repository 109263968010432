import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { find } from 'lodash';
import theme from 'shared/theme';
import {
  contactEditModeAtom,
  currentCustomerAtom,
  currentCustomerContactsAtom,
  CUSTOMER_CONTACT_DB_COLLECTION, CUSTOMERS_DB_COLLECTION,
  selectedCustomerContactAtom,
} from 'shared/state/customerState';
import QBOAuth from 'shared/data/QBO/auth';
import QBOCustomer from 'shared/data/QBO/customer';
import useFirebase from 'vendor/Firebase';

const DeleteButton = styled(Button)`
  margin: 0 0 6px 0;
  padding: 2px 8px;
  background-color: ${theme.palette.error.D500} !important;
  border-color: ${theme.palette.error.D500} !important;
  border-radius: 8px;
  border: none;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${theme.palette.error.hue};
    border-color: transparent;
  }
`;

const DeleteContactButton = () => {
  const { firestore, database } = useFirebase();
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const [currentCustomerContacts, setCurrentCustomerContacts] = useRecoilState(currentCustomerContactsAtom);
  const [selectedCustomerContact, setSelectedCustomerContact] = useRecoilState(selectedCustomerContactAtom);
  const customerContactDbString = useRecoilValue(CUSTOMER_CONTACT_DB_COLLECTION);
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const setEditMode = useSetRecoilState(contactEditModeAtom);

  const onClick = (e: any) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Delete contact?',
      content: 'Click OK to permanently delete this contact. If this is the default contact, the next remaining contact will be assigned as default.',
      onOk: async () => {
        const newContacts = [...currentCustomerContacts.filter((c) => c.uniqueID !== selectedCustomerContact.uniqueID)];
        if (selectedCustomerContact.default) {
          newContacts[0] = { ...newContacts[0], default: true };
          QBOAuth.refreshToken(database).then(() => {
            QBOCustomer.updateDefaultContact(currentCustomer, newContacts[0]).then((response: { Id: string, SyncToken: string }) => {
              firestore.collection(customerDbString).doc(currentCustomer.id).update({ SyncToken: response.SyncToken }).then(() => {
                setCurrentCustomer({ ...currentCustomer, SyncToken: response.SyncToken });
              });
            });
          });
        }
        await firestore.collection(customerContactDbString).doc(currentCustomer.id).update({ contacts: newContacts });
        setEditMode(false);
        setCurrentCustomerContacts(newContacts);
        setSelectedCustomerContact(find(newContacts, (c) => c.default) ?? newContacts[0]);
      },
    });
  };

  return (
    <DeleteButton onClick={onClick}>Delete</DeleteButton>
  );
};

export default DeleteContactButton;
