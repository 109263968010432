import { Modal } from 'antd';

// a maximum lifespan for local data expressed in milliseconds
const MAX_LIFE = 60 * 30 * 1000;

export const expireDate = () => new Date().getTime();
export const cacheExpired = (timeSinceEpoch: number, maxLife: number = MAX_LIFE) => (new Date().getTime() - timeSinceEpoch) > maxLife;

export const refreshPartDataCache = (callback?: (e: any) => void) => (e: any) => {
  const partViewerKeys = Object.keys(localStorage).filter((k: string) => k.match(/partViewerData$/));
  partViewerKeys.forEach((key: string) => {
    localStorage.removeItem(key);
  });
  if (callback) callback(e);

  Modal.info({
    content: 'Part data refreshed!',
    okText: 'OK',
  });
};
