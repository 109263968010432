import React, { useContext } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { CopyTwoTone, DeleteOutlined } from '@ant-design/icons';
import theme from 'shared/theme';
import { VENDOR_COLLECTION } from 'shared/state/vendorState';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import {
  PriceItem,
  PricingActionRow,
  PriceItemClickWrapper, ConfirmDeleteIcon,
} from 'pages/Customer/Components/styledComponents';
import { IVendor } from 'shared/types/vendor';
import ScopedComponent from '../../../shared/components/Utility/ScopedComponent';

interface IComponent{
  record: IVendor;
  shade: boolean;
}

const VendorCode = styled.p`
  width: 10%;
  text-align: left;
`;
const VendorName = styled.p`
  width: 30%;
  text-align: left;
`;
const VendorCategory = styled.p`
  width: 10%;
  text-align: left;
`;
const VendorEmail = styled.p`
  width: 30%;
  text-align: left;
`;

const VendorPhone = styled.p`
  width: 15%;
  text-align: left;
`;
const VendorURL = styled.p`
  width: 20%;
  text-align: left;
`;

const PartCopyButton = styled(Button)`
  opacity: ${(props: any) => (!props.copyDataLoaded ? 1 : 0.2)};
`;
const PartCopyIcon = styled(CopyTwoTone)`
  color: ${theme.palette.primary.hue} !important;
`;

const VendorRecord = ({ record, shade }: IComponent) => {
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const { firestore } = useFirebase();
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);
  const vendorDbCollectionString = useRecoilValue(VENDOR_COLLECTION);

  const onRowClick = (e: any) => {
    const url = `https://app.qbo.intuit.com/app/vendordetail?nameId=${record.Id}`;
    window.open(url);
  };

  const onDelete = () => {
    firestore.collection(vendorDbCollectionString).doc(record.vendorCode).delete()
      .then(() => {
        window.location.href = '/vendors';
      });
  };
  const onCopy = () => {
    console.log(record.vendorCode);
    // const newRecord = {
    //   ...record,
    //   vendorCode: `${record.vendorCode}-COPY`,
    // };
    // firestore.collection(vendorDbCollectionString).doc().set(newRecord).then(() => {
    //   window.location.href = `/inventory/${newRecord.partNumber}`;
    // });
  };

  return (
    <PriceItem shade={shade}>
      <PriceItemClickWrapper onClick={onRowClick}>
        <VendorCode>{record.DisplayName}</VendorCode>
        <VendorName>{record.CompanyName}</VendorName>
        {/* <VendorCategory>{record.vendorType}</VendorCategory> */}
        {/* <VendorEmail>{record.ARContact}</VendorEmail> */}
        <VendorPhone>{record.PrimaryPhone?.FreeFormNumber}</VendorPhone>
        <VendorEmail>{record.PrimaryEmailAddr?.Address}</VendorEmail>
        {/* <VendorURL>{record.website}</VendorURL> */}
      </PriceItemClickWrapper>
      <PricingActionRow>
        <ScopedComponent whitelist={[...adminUsers.emails, ...superAdminUsers.emails]}>
          <PartCopyButton
            shape="circle"
            icon={<PartCopyIcon />}
            onClick={onCopy}
          />
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete this item?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
              placement="left"
              // @ts-ignore
              icon={<ConfirmDeleteIcon />}
            >
              <Button
                shape="circle"
                // @ts-ignore
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        </ScopedComponent>
      </PricingActionRow>
    </PriceItem>
  );
};

export default VendorRecord;
