import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Modal,
} from 'antd';
import styled from 'styled-components';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { configToDescription } from 'shared/partParser/util';

const ModalContentWrapper = styled(FlexColumn)`
    justify-content: flex-start;
    align-items: flex-start;
`;

const ModalSyncButton = styled(Button)`
    border-radius: 8px;
`;

const ModalCancelButton = styled(ModalSyncButton)``;

const PartHeader = styled.p`
    margin: 0;
`;
const PartDescription = styled.h4`
    margin-bottom: 12px;
`;

interface IComponent {
  showModal: boolean;
  currentPart: any;
  duplicatedPart: any;
  closeCallback: (show: boolean) => void;
}
const DuplicatePartModal = ({
  showModal, currentPart, duplicatedPart, closeCallback,
}: IComponent) => {
  const [_currentPart, setCurrentPart] = useState<any>(currentPart);
  const [_duplicatedPart, setDuplicatedPart] = useState<any>(duplicatedPart);
  const [_showModal, setShowModal] = useState<boolean>(showModal);

  const onClose = (e: any) => {
    setShowModal(false);
    closeCallback(false);
  };

  const onClickExisting = (e: any) => {
    window.location.href = window.location.href.replace(_currentPart.Sku, _duplicatedPart.Sku);
  };

  useEffect(() => {
    setCurrentPart(currentPart);
    setDuplicatedPart(duplicatedPart);
    setShowModal(showModal);
  }, [showModal]);

  return (
    <Modal
      onCancel={onClose}
      title="Duplicate part found!"
      open={_showModal}
      width="50vw"
      footer={[
        <ModalCancelButton key="cancel" onClick={onClose}>
          Keep editing
        </ModalCancelButton>,
        <ModalSyncButton key="existing" type="primary" onClick={onClickExisting}>
          View existing
        </ModalSyncButton>,
      ]}
    >
      <ModalContentWrapper>
        <h3>{`This part description already exists for part ${_duplicatedPart.Sku}.`}</h3>
        <Divider />
        <PartHeader>{`This part (${_currentPart.Sku}):`}</PartHeader>
        <PartDescription>{configToDescription(_currentPart.config)}</PartDescription>
        <PartHeader>{`Existing part (${_duplicatedPart.Sku}):`}</PartHeader>
        <PartDescription>{configToDescription(_duplicatedPart.config)}</PartDescription>
        <Divider />
        <p>
          To view the existing part duplicated by this part, click &ldquo;View existing.&rdquo; To continue editing,
          click &ldquo;Keep editing.&rdquo;
        </p>
      </ModalContentWrapper>
    </Modal>
  );
};

export default DuplicatePartModal;
