import React, { useState } from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import useFirebase from 'vendor/Firebase';
import { currentCustomerAtom, CUSTOMERS_DB_COLLECTION } from 'shared/state/customerState';

const ActiveSwitch = styled(Switch)`
  margin: 4px 0 6px 0;
`;

const CustomerActiveSelector = () => {
  const [customer, setCustomer] = useRecoilState(currentCustomerAtom);
  const { firestore } = useFirebase();
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  const isDev = localStorage.getItem('useTestData') === 'true';
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (toggleState: boolean) => {
    setLoading(true);
    firestore.collection(customerDbString).doc(customer.id).update({ active: toggleState }).then(() => {
      if (!isDev) {
        firestore.collection(`${customerDbString}-test`).doc(customer.id).update({ active: toggleState }).then(() => {
          setLoading(false);
          setCustomer({ ...customer, active: toggleState });
        });
      } else {
        setLoading(false);
        setCustomer({ ...customer, active: toggleState });
      }
    });
  };

  return (
    <ItemDetailsColumnWrapper key="part-number-item-details-wrapper">
      <ItemDetailsLabel key="part-number-item-details-label">Status</ItemDetailsLabel>
      <ActiveSwitch
        loading={loading}
        checked={customer.active}
        checkedChildren="ACTIVE"
        unCheckedChildren="INACTIVE"
        onChange={onChange}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default CustomerActiveSelector;
