import React from 'react';
import styled from 'styled-components';
import { Button, notification, Tooltip } from 'antd';
import { ContentCopy } from '@styled-icons/material-twotone';
import { cleanMeta } from '../../../../../shared/text';

const CopyButton = styled(Button)`
   border: ${(props: { border: boolean }) => (props.border ? '1px solid lightgrey' : 'none')};
`;

const CopyIcon = styled(ContentCopy)`
    width: 20px;
    margin-bottom: 4px;
`;

interface IComponent {
  description: string;
  border: boolean;
}
const CopyDescriptionButton = ({ description, border = false }: IComponent) => {
  const onClick = (e: any) => {
    e.stopPropagation();
    navigator.clipboard.writeText(cleanMeta(description))
      .then(() => {
        notification.open({
          message: 'Description copied!',
          description:
            'The part description has been copied to your clipboard.',
          placement: 'bottomRight',
          className: 'data-copy-notification',
        });
        navigator.clipboard.readText();
      });
  };
  return (
    <Tooltip placement="top" title="Copy part description">
      <CopyButton border={border} shape="circle" type="default" icon={<CopyIcon />} onClick={onClick} />
    </Tooltip>
  );
};

export default CopyDescriptionButton;
