import { atom } from 'recoil';
import { resolveFirestorePath } from 'shared/util';
import { ICustomerRecord, IShippingAddress } from '../types/dbRecords';
import { IContact, IShippingDetail } from '../types/jb';

export const customerSearchQueryAtom = atom({
  key: 'customerSearchQueryAtom',
  default: sessionStorage.getItem('vendor.query.string') || '',
});

export const currentCustomerPageAtom = atom({
  key: 'currentCustomerPageAtom',
  default: 1,
});
export const CUSTOMERS_DB_COLLECTION = atom<string>({
  key: 'CUSTOMERS_DB_COLLECTION',
  default: resolveFirestorePath('customers'),
});
export const CUSTOMER_CONTACT_DB_COLLECTION = atom<string>({
  key: 'CUSTOMER_CONTACT_DB_COLLECTION',
  default: resolveFirestorePath('customer-contacts'),
});
export const CUSTOMER_SHIPPING_DB_COLLECTION = atom<string>({
  key: 'CUSTOMER_SHIPPING_DB_COLLECTION',
  default: resolveFirestorePath('customer-shipping-addresses'),
});
export const customersAtom = atom<ICustomerRecord[]>({
  key: 'customersAtom',
  default: [],
});

export const customerContactsAtom = atom<IContact[]>({
  key: 'customerContactsAtom',
  default: [],
});

export const currentCustomerContactsAtom = atom<IContact[]>({
  key: 'currentCustomerContactsAtom',
  default: [],
});

export const selectedCustomerContactAtom = atom<IContact>({
  key: 'selectedCustomerContactAtom',
  default: {} as IContact,
});
export const customerContactsEditedAtom = atom<boolean>({
  key: 'customerContactsEditedAtom',
  default: false,
});

export const contactEditModeAtom = atom<boolean>({
  key: 'customerContactsEditAtom',
  default: false,
});

export const currentCustomerShippingAddressesAtom = atom<IShippingAddress[]>({
  key: 'currentCustomerShippingAddressesAtom',
  default: [],
});

export const selectedCustomerShippingAddressAtom = atom<IShippingAddress>({
  key: 'selectedCustomerShippingAddressAtom',
  default: {} as IShippingAddress,
});

export const customerShippingAddressesEditModeAtom = atom<boolean>({
  key: 'customerShippingAddressesEditModeAtom',
  default: false,
});
export const customerShippingAddressesEditedAtom = atom<boolean>({
  key: 'customerShippingAddressesEditedAtom',
  default: false,
});

export const customerShippingAddressesAtom = atom<IShippingDetail[]>({
  key: 'customerShippingAddressesAtom',
  default: [],
});
export const showNewCustomerModalAtom = atom({
  key: 'showNewCustomerModalAtom',
  default: false,
});
export const createOrEditCustomerAtom = atom({
  key: 'createOrEditCustomerAtom',
  default: 'create',
});

export const showCustomerEmailsAtom = atom<boolean>({
  key: 'showCustomerEmailsAtom',
  default: false,
});

export const customerEmailButtonsAtom = atom<{ label: string, onClick:(e: any) => void, type: string, key: string}[]>({
  key: 'customerEmailButtonsAtom',
  default: [],
});

export const customerEditedAtom = atom<boolean>({
  key: 'customerEditedAtom',
  default: false,
});
export const currentCustomerAtom = atom<ICustomerRecord>({
  key: 'customerAtom',
  default: {
    id: '', name: 'Select a customer', shortName: '', quickbooksId: '', active: true, pricedItems: 0, neckDiscount: 0.00, bodyDiscount: 0.00, adjustLock: false, login: '', termsCode: '', APContact: '', phone: '', website: '',
  },
});

export const activeCustomerDisplayAtom = atom<'active'|'inactive'|'all'>({
  key: 'ActiveCustomerDisplayAtom',
  default: sessionStorage.getItem('activeCustomerDisplayType') as 'active'|'inactive'|'all' || 'all',
});
