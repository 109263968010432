import shortid from 'shortid';
import React from 'react';
import { formatPrice } from 'shared/data';
import { SubdirectoryArrowRight } from '@styled-icons/material';
import { ICustomerRecord, IPurchaseOrder } from 'shared/types/dbRecords';
import { CellCopy } from 'shared/styledComponents/typographicElements';
import styled from 'styled-components';
import theme from 'shared/theme';
import { Popover } from 'antd';
import PurchaseOrderItemsPopover from './PurchaseOrderItemsPopover';
import ActionButtons from './Buttons/ActionButtons';
import { dateFromMemo } from '../../../../shared/data/QBO/purchaseOrder';

const ChildArrow = styled(SubdirectoryArrowRight)`
    width: 24px;
    margin-bottom: 6px;
    color: ${theme.palette.primary.hue};
`;
export const PurchaseOrderNumberCopy = styled(CellCopy)`
    font-weight: bold;
`;

export const DescriptionCopy = styled(CellCopy)`
    width: 100%;
    color: ${(props: { transparent: boolean }) => (props.transparent ? 'transparent' : theme.palette.neutral.black)};
`;

const ORDER_NUMBER_COLUMN = {
  title: 'PO #',
  dataIndex: 'DocNumber',
  render: (text: string, record: IPurchaseOrder) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <>
    <PurchaseOrderNumberCopy key={shortid.generate()}>{record.DocNumber}</PurchaseOrderNumberCopy>
  </>,
  }),
  width: '5%',
};

const PO_DATE_COLUMN = {
  title: 'PO Date',
  dataIndex: 'orderDate',
  render: (text: string, record: IPurchaseOrder) => {
    const date = new Date(`${record.TxnDate}T00:00`);
    return {
      props: {
        style: {
          textAlign: 'center',
        },
      },
      children:
  <CellCopy key={shortid.generate()}>{date.toLocaleDateString()}</CellCopy>,
    };
  },
  width: '5%',
};
const DUE_DATE_COLUMN = {
  title: 'Due Date',
  dataIndex: 'dueDate',
  render: (text: string, record: IPurchaseOrder) => {
    const dueDateText = dateFromMemo(record.Memo);
    return {
      props: {
        style: {
          textAlign: 'center',
        },
      },
      children:
  <CellCopy key={shortid.generate()}>{dueDateText}</CellCopy>,
    };
  },
  width: '5%',
};
const VENDOR_COLUMN = {
  title: 'Vendor',
  dataIndex: 'Vendor',
  render: (text: string, record: IPurchaseOrder) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.VendorRef.name}</CellCopy>,
  }),
  width: '5%',
};
const DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: 'Description',
  render: (text: string, record: IPurchaseOrder) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <Popover content={<PurchaseOrderItemsPopover items={record.Line} />}>
    <DescriptionCopy transparent={!record.PrivateNote} key={shortid.generate()}>{record.PrivateNote || '--'}</DescriptionCopy>
  </Popover>,
  }),
  width: '30%',
};

const AMOUNT_COLUMN = {
  title: 'Amount',
  dataIndex: 'TotalAmt',
  render: (text: string, record: IPurchaseOrder) => ({
    props: {
      style: {
        textAlign: 'right',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{formatPrice(record.TotalAmt)}</CellCopy>,
  }),
  width: '5%',
};

export {
  ORDER_NUMBER_COLUMN,
  VENDOR_COLUMN,
  DESCRIPTION_COLUMN,
  AMOUNT_COLUMN,
  PO_DATE_COLUMN,
  DUE_DATE_COLUMN,
};
