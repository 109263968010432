import React from 'react';
import styled from 'styled-components';
import { Button, Popover } from 'antd';
import { MoreHoriz } from '@styled-icons/material';

interface IComponent {
  MoreButtons: any;
  placement?: string;
  iconColor?: string;
  width?: string;
  noBorder?: boolean;
}

const ButtonPopover = styled(Popover)`
  & .ant-popover-inner {
    border-radius: 12px !important;
  }
`;
const MoreIcon = styled(MoreHoriz)`
  width: 20px;
  margin-bottom: 2px;
  color: ${(props: { color: string }) => props.color};
`;

const ComponentButton = styled(Button)`
  border: ${(props: { noBorder: boolean }) => (props.noBorder ? 'none' : '1px solid lightgrey')};
  width: ${(props: { width: string }) => props.width};
`;

const OtherActionsButton = ({
  MoreButtons, placement = 'top', iconColor = '#000', width = '24px', noBorder = false,
}: IComponent) => (
  <ButtonPopover content={MoreButtons} placement={placement}>
    <ComponentButton
      noBorder={noBorder}
      width={width}
      shape="circle"
      // @ts-ignore
      icon={<MoreIcon color={iconColor} />}
      onClick={(e: any) => { e.stopPropagation(); }}
    />
  </ButtonPopover>
);

export default OtherActionsButton;
