import { Tooltip } from 'antd';
import React from 'react';
import { FlexRow } from 'shared/containers/FlexContainer';
import {
  RouterInfoIcon,
  RouterTableInfoButton,
  RouterTDCopy,
} from 'pages/ProductionSchedule/styledComponents';
import { IArchetype } from '../../types';
import { IPartWood } from '../../../shared/types/pricingTool';

const shortid = require('shortid');
const _ = require('lodash');

const onEditClick = (archetype: IArchetype) => (e: any) => {
  e.preventDefault();
  const partType = localStorage.getItem('pricingEditCategory');
  window.location.href = `/pricing/archetype/edit?partType=${partType}&partId=${archetype.id}`;
};

export const ARCHETYPE_COLUMN = {
  title: 'Archetype',
  dataIndex: shortid.generate(),
  align: 'left',
  sorter: {
    compare: (a: IPartWood, b: IPartWood) => {
      if (a.type > b.type) return 1;
      if (b.type > a.type) return -1;
      return 0;
    },
  },
  render: (text: string, record: any) => (
    <FlexRow id={record.id} key={shortid.generate()} align="center" justify="space-between">
      <RouterTDCopy key={shortid.generate()}>{record.type}</RouterTDCopy>
    </FlexRow>
  ),
  width: 450,
};

export const INSTRUMENT_COLUMN = {
  title: 'Instrumemnt Type',
  dataIndex: shortid.generate(),
  align: 'left',
  sorter: {
    compare: (a: IPartWood, b: IPartWood) => {
      if (a.instrument > b.instrument) return 1;
      if (b.instrument > a.instrument) return -1;
      return 0;
    },
  },
  render: (text: string, record: any) => {
    const options = {
      body: {
        S: 'Small',
        M: 'Medium',
        L: 'Large',
      },
      neck: {
        M: 'Guitar',
        L: 'Bass',
      },
    };
    const partType = record.type.match(/flat|tilt/i) ? 'neck' : 'body';
    const category = options[partType][record.instrument];
    return ({
      props: {
        style: {
          textAlign: 'left',
        },
      },
      children:
  <FlexRow justify="space-between">
    <RouterTDCopy key={shortid.generate()}>{category}</RouterTDCopy>
  </FlexRow>,
    });
  },
  width: 300,
};

export const PRICE_COLUMN = {
  title: 'Price',
  dataIndex: shortid.generate(),
  sorter: {
    compare: (a: IPartWood, b: IPartWood) => {
      if (a.price > b.price) return 1;
      if (b.price > a.price) return -1;
      return 0;
    },
  },
  render: (text: string, record: IPartWood) => (
    <RouterTDCopy key={shortid.generate()}>{`$${record.price}`}</RouterTDCopy>
  ),
  width: 100,
};

export const ACTIONS_COLUMN = {
  title: '',
  key: 'actions',
  render: (text: string, record: any) => (
    <FlexRow justify="flex-start" style={{ paddingRight: 24 }} key={shortid.generate()}>
      <Tooltip placement="top" title="View/Edit">
        <RouterTableInfoButton key={shortid.generate()} onClick={onEditClick(record)}><RouterInfoIcon /></RouterTableInfoButton>
      </Tooltip>
    </FlexRow>
  ),
};

export const columns = [
  ARCHETYPE_COLUMN,
  INSTRUMENT_COLUMN,
  PRICE_COLUMN,
  ACTIONS_COLUMN,
];
