import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Table } from 'antd';
import styled from 'styled-components';
import { Body } from 'shared/typography';
import { EmptyPriceItem } from 'pages/Customer/Components/styledComponents';
import { rgba as hexRgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import { IInventoryPart, IQATicket } from 'shared/types/dbRecords';
import { CustomerPricingWrapper } from '../../../styledComponents';
import {
  REPORT_NUMBER_COLUMN, DATE_OPENED, OPENED_BY, DESCRIPTION_COLUMN, ACTION_COLUMN, TYPE_COLUMN,
} from './QATicketColumns';
import { cleanCurrentUrl, clearRedirect, stageRedirect } from '../../../../shared/util';

const ItemTable = styled((props) => <Table {...props} />)`
    width: 100%;
    && tbody > tr:hover > td {
        background: ${hexRgba(theme.palette.primary.hue, 0.2)};
    }
`;

interface IComponent {
  renderItems: IQATicket[];
  currentPage: number;
  onPageChange: (newPage: number) => void;
}

const TicketList = ({ renderItems, currentPage, onPageChange }: IComponent) => {
  const currentPageString = sessionStorage.getItem('qaTicket.currentPage');
  const [_currentPage, setCurrentPage] = useState(currentPageString ? parseInt(currentPageString, 10) : 1);
  const [_renderItems, setRenderItems] = useState<IQATicket[]>([]);
  const { partId } = qs.parse(window.location.search.replace('?', ''));
  const tableColumns = [REPORT_NUMBER_COLUMN, TYPE_COLUMN, DATE_OPENED, OPENED_BY, DESCRIPTION_COLUMN, ACTION_COLUMN];

  useEffect(() => {
    setRenderItems(renderItems);
    setTimeout(() => {
      const el = document.getElementById(`${partId}-inventory-item`);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });
  }, [renderItems]);

  const onRowClick = (record: any) => (e: any) => {
    // clearRedirect();
    stageRedirect(`${cleanCurrentUrl()}#${record.id}`);
    window.location.href = `/qa/ticket/edit?qaTicketId=${record.id}`;
  };

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage]);

  return (
    <>
      {(_renderItems.length === 0)
        ? (
          <EmptyPriceItem>
            <Body>No matches available.</Body>
          </EmptyPriceItem>
        ) : (
          <CustomerPricingWrapper>
            <ItemTable
              dataSource={_renderItems}
              rowKey="Id"
              size="large"
              rowClassName="sales-order-row clickable-row"
              columns={tableColumns}
              pagination={{ pageSize: 100, current: _currentPage, onChange: onPageChange }}
              scroll={{ y: window.innerHeight * 0.8 }}
              onRow={(record: IInventoryPart) => ({
                onClick: onRowClick(record),
              })}
            />
          </CustomerPricingWrapper>
        )}
    </>
  );
};

export default TicketList;
