import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Mail } from '@styled-icons/material-twotone';

interface IComponent {
  email: string;
}

const EmailButton = styled(Button)``;
const EmailIcon = styled(Mail)`
    width: 20px;
    margin-bottom: 2px;
`;
const CustomerEmailButton = ({ email }: IComponent) => {
  const onClick = (e: any) => {
    e.stopPropagation();
    window.location.href = `mailto:${email}`;
  };

  return (
    <EmailButton
      shape="circle"
      icon={<EmailIcon />}
      onClick={onClick}
    />
  );
};

export default CustomerEmailButton;
