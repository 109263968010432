import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  find, findIndex,
} from 'lodash';
import { Button } from 'antd';
import { DeleteForever, UnfoldMore, UnfoldLess } from '@styled-icons/material';
import { useRecoilValue } from 'recoil';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import LabelCounter from 'shared/buttons/LabelCounter';
import theme from 'shared/theme';
import PartDetailInput from 'shared/components/Input/PartDetailInput';
import { ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { IBomItem, IOrderItem } from 'shared/types/dbRecords';
import { customerPartsAtom } from 'shared/state/pricingState';
import { configToDescription } from 'shared/partParser/util';
import { IRunner, IWorkOrder } from '../../types';

const ComponentWrapper = styled(FlexColumn)`
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 24px 0;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  background-color: ${(props: { complete: boolean }) => (props.complete ? 'rgba(0, 0, 0, 0.05)' : 'white')};
  & p {
    margin-bottom: 0;
  }
  @media ${theme.device.laptopL} {
    width: 75%;
  }
`;

const ComponentInnerWrapper = styled(FlexColumn)`
  width: 100%;
  // height: ${(props: any) => props.height};
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: 0 16px;
  overflow: hidden;
`;

const PartDetailRow = styled(FlexRow)`
  width: calc(100% - 16px);
  justify-content: flex-start;
  padding: 0 16px;
  gap: 8px;
`;

const OrderLabel = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border: none;
  background-color: ${(props: { complete: boolean }) => (props.complete ? theme.palette.neutral[500] : theme.palette.primary.hue)};
  color: #FFFFFF;
  border-radius: 4px 4px 0 0;
  padding: 0 4px 0 16px;
  margin-bottom: 16px;
  & h3 {
    margin: 0;
    font-size: 22px;
  }
  & p {
    color: white;
  }
`;

const PartsColumn = styled(FlexColumn)`
  width: 100%;
  align-items: flex-start;
  margin: 0 auto;
  gap: 8px;
`;

const WorkOrderDeleteIcon = styled(DeleteForever)`
  color: ${theme.palette.neutral.white};
  width: 32px;
`;
const WorkOrderDeleteButton = styled(Button)`
  width: 32px !important;
  height: 32px !important;
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  align-self: flex-end;
  margin: 0 0 4px auto;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${theme.palette.error.hue};
  }
`;

const ExpandLessIcon = styled(UnfoldLess)`
  width: 32px;
  cursor: pointer;
`;
const ExpandMoreIcon = styled(UnfoldMore)`
  width: 32px;
  cursor: pointer;
`;
interface IComponent {
  workOrders: IRunner[];
  workOrder: IRunner;
  orderItems: IOrderItem[];
  salesOrderId: string;
  deleteCallback: (workOrder: IRunner) => void;
  editCallback: (workOrder: IRunner) => void;
  updateWorkOrderItemsCallback: (orderItems: IOrderItem[]) => void;
  incrementPartCountCallback: (partId: string, workOrder: IRunner, suggestedValue: number, longPress: boolean) => [IOrderItem, IOrderItem[]];
  index: number;
}
const WorkOrder = ({
  deleteCallback, editCallback, updateWorkOrderItemsCallback, incrementPartCountCallback, orderItems, workOrder, index, workOrders,
}: IComponent) => {
  const { id } = workOrder;
  const [_workOrder, setWorkOrder] = useState<IRunner>({ ...workOrder });
  const [_description, setDescription] = useState<string>(workOrder.description || '');
  const customerParts = useRecoilValue(customerPartsAtom);

  const filterDisplayItems = (items: IOrderItem[]) => items.filter((orderItem: IOrderItem) => {
    const isOnWorkOrder = !!find(_workOrder?.parts, (p: IOrderItem) => p.id === orderItem?.id);
    return isOnWorkOrder || (((orderItem?.quantityOpen || 0) - (orderItem?.quantityAssigned || 0)) > 0);
  });

  const [orderDisplayItems, setOrderDisplayItems] = useState<IOrderItem[]>(filterDisplayItems(orderItems));

  const onAddWorkOrderPart = (_id: string) => (i: number, longPress: boolean) => {
    const [updatedWorkOrderItem, newOrderItems] = incrementPartCountCallback(_id, workOrder, i, longPress);
    const newWorkOrderParts = [...workOrder.parts];
    let workOrderItemIndex = findIndex(workOrder.parts, (o: IOrderItem) => o.id === updatedWorkOrderItem.id);
    // it is possible that the workOrderItemIndex is -1, since we may have just added this item to the work order
    // if that is the case, we will just append the item onto the end of the workOrder.parts array
    if (workOrderItemIndex === -1) workOrderItemIndex = newWorkOrderParts.length;
    newWorkOrderParts[workOrderItemIndex] = updatedWorkOrderItem;

    const toppedOrBound = newWorkOrderParts.map((p: IOrderItem) => {
      const customerPart = find(customerParts, (part: any) => part.partNumber === p.Sku);
      if (!customerPart) return false;
      if (configToDescription(customerPart.config).match(/^[G|B]B_/) && customerPart.top) return true;
      return !!(configToDescription(customerPart.config).match(/^[G|B]N_/) && customerPart.bound);
    }).filter((t: boolean) => t);

    const updatedWorkOrder = { ...workOrder, parts: newWorkOrderParts.filter((p: IOrderItem) => p.quantityAssigned > 0), toppedOrBound: toppedOrBound.length > 0 };
    const workOrderIndex = findIndex(workOrders, (w: IWorkOrder) => w.id === workOrder.id);
    const newWorkOrders = [...workOrders];
    newWorkOrders[workOrderIndex] = updatedWorkOrder;
    setWorkOrder(updatedWorkOrder);
    editCallback(updatedWorkOrder);

    // finally, update the order items for the whole order via callback, so all work orders get updated wit
    // the parts they can show or should not show based on availability
    updateWorkOrderItemsCallback(newOrderItems);
  };

  const onDescChange = (e: any) => {
    setDescription(e.target.value);
  };

  const onDescBlur = (e: any) => {
    editCallback({ ..._workOrder, description: _description });
  };

  const onDelete = (e: any) => {
    e.preventDefault();
    deleteCallback(_workOrder);
  };

  useEffect(() => {
    setWorkOrder(workOrder);
    setOrderDisplayItems(filterDisplayItems(orderItems));
  }, [orderItems, workOrder]);

  return (
    <>
      <ComponentWrapper key={`${id}-component-wrapper}`} complete={workOrder.completed}>
        <OrderLabel key={`${id}-component-label`} complete={workOrder.completed}>
          <h3 key={`${id}-component-label-h3`}>{`Work Order ${index + 1}`}</h3>
          {!workOrder.completed && (

          <WorkOrderDeleteButton
            key={`${id}-component-delete-button`}
            icon={<WorkOrderDeleteIcon />}
            onClick={onDelete}
          />
          )}
        </OrderLabel>
        <ComponentInnerWrapper key={`${id}-component-inner-wrapper`}>
          <PartDetailRow key={`${id}-component-part-detail-row`}>
            <PartDetailInput
              id={`${id}-description`}
              blurCallback={onDescBlur}
              changeCallback={onDescChange}
              disabled={workOrder.completed}
              label="Description"
              placeholder="Description for work order"
              value={_description}
              width="50%"
              noMargin={false}
            />
            <PartDetailInput
              id={`${id}-component-part-count-input`}
              disabled
              changeCallback={() => {}}
              placeholder="0"
              label="Part count"
              value={workOrder.parts ? workOrder.parts.filter((p: IOrderItem) => !p.Sku.match(/^[0-9]/))
                .map((p: IOrderItem) => p.quantityAssigned)
                .reduce((a, b) => a + b, 0) : workOrder.partCount}
              width="144px"
              noMargin={false}
            />
            <PartDetailInput
              id={`${id}-component-value-input`}
              disabled
              changeCallback={() => {}}
              placeholder="0"
              label="Value"
              value={workOrder.parts ? workOrder.parts
                .map((p: IOrderItem) => p.quantityAssigned * p.unitPrice)
                .reduce((a, b) => a + b, 0) : workOrder.value}
              width="144px"
              noMargin={false}
            />
            <PartDetailInput
              id={`${id}-component-bom-value-input`}
              disabled
              changeCallback={() => {}}
              placeholder="0"
              label="BOM Value"
              value={workOrder.parts ? Math.round(workOrder.parts
                .map((p: IOrderItem) => p.bom.map((b: IBomItem) => b.unitCost * p.quantityAssigned).reduce((a, b) => a + b, 0))
                .reduce((a, b) => a + b, 0)) : 0}
              width="144px"
              noMargin={false}
            />
          </PartDetailRow>
          <PartsColumn key={`${id}-component-parts-column`}>
            <ItemDetailsLabel key={`${id}-component-item-details-label`}>Parts</ItemDetailsLabel>
            {orderDisplayItems
              .map((orderItem: IOrderItem) => {
                const workOrderItem = find(_workOrder.parts, (p: IOrderItem) => p.id === orderItem.id) as IOrderItem;
                const totalQuantityAssignedElsewhere = workOrders
                  .filter((w: IRunner) => w.id !== workOrder.id)
                  .map((w) => w.parts
                    .filter((p: IOrderItem) => p.id === orderItem.id)
                    .map((p: IOrderItem) => p.quantityAssigned)
                    .reduce((a, b) => a + b, 0))
                  .reduce((a, b) => a + b, 0);
                const totalQuantityAvailable = orderItem.quantityOpen - totalQuantityAssignedElsewhere;
                return (
                  <>
                    { (totalQuantityAvailable > 0 || (workOrderItem?.quantityAssigned || 0) !== 0) && (
                    <LabelCounter
                      key={`${orderItem.id}-${workOrder.id}-component-item-label-counter`}
                      width="100%"
                      label={(
                        <FlexRow key={`${orderItem.id}-component-part-detail-label`} justify="flex-start" style={{ gap: 8 }}>
                          <p style={{ fontWeight: 'bold', margin: 0 }}>{orderItem.Sku}</p>
                          <p style={{ margin: 0 }}>{orderItem.Description}</p>
                        </FlexRow>
                    )}
                      initialValue={workOrderItem?.quantityAssigned ?? 0}
                      minValue={0}
                      maxValue={totalQuantityAvailable}
                    // maxValue={orderItem.quantityOpen - orderItem.quantityAssigned}
                      callback={onAddWorkOrderPart(orderItem.id)}
                    // useIncrementValue
                      disabled={workOrder.completed}
                    />
                    )}
                  </>
                );
              })}
          </PartsColumn>
        </ComponentInnerWrapper>
      </ComponentWrapper>
    </>
  );
};

export default WorkOrder;
