import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { findIndex, sortBy } from 'lodash';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { formatPercent, formatPrice } from 'shared/data';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { bomEditedAtom, PART_BOM_COLLECTION, partBomItemsAtom } from 'shared/state/partViewState';
import { hideUnselectedOptionsAtom, partEditModeAtom } from 'shared/state/pricingState';
import BomItem from './BomItem';
import AddBomItemButton from '../Buttons/AddBomItemButton';
import CopyBomButton from '../Buttons/CopyBomButton';
import RefreshBomDetailsButton from '../Buttons/RefreshBomDetailsButton';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from '../PartDetailFields/styledComponents';
import { IBomItem } from '../../../../shared/types/dbRecords';
import useFirebase from '../../../../vendor/Firebase';

interface IComponent {
  open: boolean;
  partType: string;
  partId: string;
  partDescription: string;
  partPrice: number;
}

const ComponentWrapper = styled(ItemDetailsColumnWrapper)`
  width: 90%;
  margin-top: 16px;
  padding-top: 16px;
`;
const BomHeaderRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;

const RowHeader = styled(FlexRow)`
    justify-content: flex-start;
    gap: 12px;
`;
const CostWrapper = styled(FlexRow)`
    justify-content: flex-end;
`;

const BomCostLabel = styled(ItemDetailsLabel)`
  font-weight: normal;
`;
const BomListWrapper = styled(FlexColumn)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  margin: 8px 0 8px 0;
`;
const BomPlaceholder = styled.p`
  height: 100%;
  color: grey;
`;
const BomActionButtonRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 8px;
`;

const PartBomList = ({
  open, partType, partId, partDescription, partPrice,
}: IComponent) => {
  const { firestore } = useFirebase();
  const [totalCost, setTotalCost] = useState<number>(0);
  const [totalSell, setTotalSell] = useState<number>(0);
  const [description, setDescription] = useState<string>(partDescription);
  const [bomItems, setBomItems] = useRecoilState(partBomItemsAtom);
  const partBomDbCollection = useRecoilValue(PART_BOM_COLLECTION);
  const partEditMode = useRecoilValue(partEditModeAtom);
  const setBomEdited = useSetRecoilState(bomEditedAtom);

  const onRefreshParts = (parts: IBomItem[]) => {
    setBomItems(parts);
  };

  const onChangeBomItem = (bomItem: IBomItem, oldSku: string) => {
    setBomEdited(true);
    const updatedItems = [...bomItems];
    // use oldSku to find the current index if the new sku is not in the bom list
    // this would indicate that we've replaced one bom item with another.
    const index = findIndex(bomItems, (i: IBomItem) => (i.Sku === bomItem.Sku || i.Sku === oldSku));
    if (index > -1) {
      updatedItems[index] = bomItem;
      firestore.collection(partBomDbCollection).doc(partId).get().then((partDoc) => {
        if (partDoc.exists) {
          firestore.collection(partBomDbCollection).doc(partId).update({ bom: updatedItems }).then(() => {
            setBomItems(updatedItems);
          });
        } else {
          setBomItems(updatedItems);
        }
      });
    } else {
      updatedItems[updatedItems.length - 1] = bomItem;
    }
  };

  useEffect(() => {
    const cost = bomItems.map((item) => item.unitCost)
      .reduce((a, b) => a + b, 0);
    setTotalCost(cost);
    const sell = bomItems.map((item) => item.unitPrice)
      .reduce((a, b) => a + b, 0);
    setTotalSell(sell);
  }, [bomItems]);

  useEffect(() => {
    setDescription(partDescription);
  }, [partEditMode, partDescription]);

  return (
    <ComponentWrapper>
      <BomHeaderRow>
        <RowHeader>
          <ItemDetailsLabel style={{ margin: 0 }}>Part BOM</ItemDetailsLabel>
          <RefreshBomDetailsButton partType={partType} partId={partId} callback={onRefreshParts} />
        </RowHeader>
        <CostWrapper>
          <BomCostLabel>{`Cost: ${formatPrice(totalCost)} (${formatPercent(totalCost / partPrice, 2)})`}</BomCostLabel>
          {/* <BomCostLabel>{`Cost / Sell: ${formatPrice(totalCost)} / ${formatPrice(totalSell)}`}</BomCostLabel> */}
        </CostWrapper>
      </BomHeaderRow>

      <BomListWrapper>
        {bomItems.length > 0 ? (
          <>
            {sortBy(bomItems, (item) => item.Sku).map((item) => (
              <BomItem
                key={`bom-item-${item.Sku}`}
                open={open}
                partType={partType as 'body'|'neck'}
                bomItem={item}
                changeCallback={onChangeBomItem}
              />
            ))}
          </>
        ) : (
          <BomPlaceholder>No BOM items to display</BomPlaceholder>
        )}
      </BomListWrapper>
      {(partEditMode && open) && (
        <BomActionButtonRow>
          <AddBomItemButton />
          <CopyBomButton partDescription={description} />
        </BomActionButtonRow>
      )}
    </ComponentWrapper>
  );
};

export default PartBomList;
