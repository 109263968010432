import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Switch } from 'antd';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { hidePositiveQuantityAtom } from 'shared/state/jobRequirementsState';
import { inventoryRequirementsAtom } from 'shared/state/inventoryState';
import { ItemDetailsLabel } from 'shared/styledComponents/inputs';
import theme from 'shared/theme';

const ComponentSwitch = styled(Switch)`
    background-color: ${(props: any) => (!props.checked ? theme.palette.neutral[800] : theme.palette.primary.hue)} !important;
`;
const ComponentWrapper = styled(FlexColumn)`
  justify-content: flex-start;
    gap: 8px;
`;

const HidePositiveQuantitiesFilter = () => {
  const [hidePositiveQuantities, setHidePositiveQuantities] = useRecoilState(hidePositiveQuantityAtom);
  const requirements = useRecoilValue(inventoryRequirementsAtom);
  const toggleHidePositiveQuantities = (e: any) => {
    console.log(requirements);
    setHidePositiveQuantities(!hidePositiveQuantities);
  };

  return (
    <ComponentWrapper>
      <ComponentSwitch
        checked={hidePositiveQuantities}
        checkedChildren="NEEDED"
        unCheckedChildren="ALL"
        onChange={toggleHidePositiveQuantities}
      />
    </ComponentWrapper>
  );
};

export default HidePositiveQuantitiesFilter;
