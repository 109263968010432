import { find, includes } from 'lodash';
import { IModel, IWood, IBinding, INeckComposition, ISimpleConfig, INeckShape, IFretwire, IInlay, INeckFeatures, ICustomerPart, IModelScale } from 'shared/types/parts';
import { cleanMeta } from 'shared/text';
import { orderTermsByRank } from './util';
import { BODY_PICKUP_MODIFIERS, BODY_PICKUPS } from './parsingTerms';

interface Term {
  terms: string[];
  type: string;
  rank: number;
}
// Universal resolvers
export const resolveArchetype = (partConfig: any): { text: string } => {
  const archetype = find(partConfig, (t) => t.type === 'Archetype');
  return archetype ? { text: archetype.terms[0] } : { text: '' };
};

export const resolveModel = (partConfig: any): IModel => {
  const superModel = find(partConfig, (t) => t.type === 'Model_Supermodel');
  const _model = find(partConfig, (t) => t.type === 'Model');
  const subModel = find(partConfig, (t) => t.type === 'Model_Submodel');
  const modifiers = find(partConfig, (t) => t.type === 'Model_Modifier');
  const scale = find(partConfig, (t) => t.type === 'Model_Scale');
  return {
    superModel: superModel ? superModel.terms[0] : '',
    model: _model ? _model.terms[0] : '',
    subModel: subModel ? subModel.terms.join('_') : '',
    modifiers: modifiers ? modifiers.terms : [],
    scale: scale ? scale.terms[0] : '',
    text: [(_model ? _model.terms[0] : ''), (subModel ? subModel.terms.join('_') : ''), (modifiers ? modifiers.terms.join('_') : ''), (scale ? scale.terms[0] : '')].filter((t) => t !== '').join('_'),
  };
};

export const resolveModelScale = (partConfig: any): IModelScale => {
  const scale = find(partConfig, (t) => t.type === 'Model_Scale');
  if (!scale) return { stringCount: 0, fretCount: 0, scaleLength: 0 };
  let [stringCount, fretCount, scaleLength] = [0, 0, 0];
  if (scale.terms.length === 1) {
    const split = scale.terms[0].split('/');
    stringCount = parseInt(split[0], 10);
    fretCount = parseInt(split[1], 10);
    scaleLength = parseInt(split[2], 10);
  } else {
    stringCount = parseInt(scale.terms[0], 10);
    fretCount = parseInt(scale.terms[1], 10);
    scaleLength = parseInt(scale.terms[2], 10);
  }
  return { stringCount: stringCount || 0, fretCount: fretCount || 0, scaleLength: scaleLength || 0 };
};

export const resolveBodyFeatures = (partConfig: any): { terms: string[] } => {
  const bodyFeatures = find(partConfig, (t) => t.type === 'Body_Feature');
  return bodyFeatures ? { terms: bodyFeatures.terms } : { terms: [] };
};

export const resolveBodyConstructionModifiers = (partConfig: any): { terms: string[] } => {
  const bodyConstruction = find(partConfig, (t) => t.type === 'Body_Construction_Modifier');
  return bodyConstruction ? { terms: bodyConstruction.terms } : { terms: [] };
};

export const resolveDescription = (aggregateTerms: Term[]): { text: string } => {
  const sortedTerms = orderTermsByRank(aggregateTerms);
  return { text: sortedTerms.join('_') };
};

export const resolveWood = (partConfig: any): IWood => {
  const wood = find(partConfig, (t) => t.type === 'Wood');
  if (!wood) return { core: '', top: '', veneer: '' };

  const parts = wood.terms[0].split('/').filter(Boolean);
  const archetype = resolveArchetype(partConfig);
  
  // Handle body wood (GB/BB)
  if (['GB', 'BB'].includes(archetype.text)) {
    if (parts.length === 3) {
      const [core, veneer, top] = parts;
      return { core, top, veneer };
    }
    const [core, top] = parts;
    return { core, top, veneer: '' };
  }
  
  // Handle neck wood
  if (parts.length === 3) {
    const [core, top, veneer] = parts;
    return { core, top, veneer };
  }
  const [core, top] = parts;
  return { core, top, veneer: '' };
};

// Body resolvers
export const resolveBodyThickness = (partConfig: any): { text: string, value: number } => {
  const thickness = find(partConfig, (t) => t.type === 'Body_Thickness');
  return thickness ? { text: thickness.terms[0], value: parseFloat(thickness.terms[0]) } : { text: '', value: 0 };
};
export const resolvePickups = (partConfig: any): { neck: string; middle: string; bridge: string; text: string } => {
  const pickups = find(partConfig, (t) => t.type === 'Pickups');
  const pickupTypes = Object.values(BODY_PICKUPS).flat();
  const pickupModifiers = Object.values(BODY_PICKUP_MODIFIERS).flat();
  
  // Special case for Nashville pickup config, which is S/S/H
  if (pickups?.terms[0]?.match('Nashville')) {
    return {
      neck: 'S',
      bridge: 'H',
      middle: 'S',
      text: 'Nashville S/S/H',
    };
  }

  const pickupPattern = new RegExp(`((${pickupModifiers.join('|')})?(${pickupTypes.join('|')}\/?)){1,3}`, 'gi');
  const match = pickups ? pickups.terms[0].match(pickupPattern) : null;
  if (!match) return { neck: 'None', middle: 'None', bridge: 'None', text: '' };
  
  const [neck = 'None', middle = 'None', bridge = 'None'] = match;
  
  const cleanedPickups = {
    neck: neck.replace(/[_]|\/$/g, ''),
    middle: middle.replace(/[_]|\/$/g, ''),
    bridge: bridge.replace(/[_]|\/$/g, ''),
  };

  const text = Object.values(cleanedPickups)
    .filter((pickup) => pickup !== 'None')
    .join('/');

  return { ...cleanedPickups, text };
};
export const resolveWeight = (partConfig: any): { text: string, value: number } => {
  const weight = find(partConfig, (t) => t.type === 'Weight');
  const weightRegex = /(\d+)\s*Lb[s]?\s*(\d*)/i;
  const weightMatch = weight ? weight.terms[0].match(weightRegex) : null;
  if (!weightMatch) {
    return {
      text: weight.terms[0],
      value: 0,
    };
  }

  const [fullText, pounds, ounces] = weightMatch;
  return {
    text: fullText,
    value: (parseInt(pounds, 10) || 0) + ((parseInt(ounces, 10) || 0) / 16),
  };
};

export const resolveWeightReductionType = (partConfig: any): { text: string } => {
  const modelModifier = find(partConfig, (t) => t.type === 'Model_Modifier');
  if (!modelModifier) return { text: 'None' };
  const weightReductionType = find(modelModifier.terms, (t) => t.match(/^WR/i));
  return weightReductionType ? { text: weightReductionType } : { text: 'None' };
};
// Neck resolvers
export const resolveBinding = (partConfig: any): IBinding => {
  const binding = find(partConfig, (t) => t.type === 'Binding');
  if (!binding) return { material: 'None', type: 'None', text: 'None' };

  const material = binding.terms[0].replace(/bind(ing)?/i, '');
  return {
    material: material === 'Shelf' ? 'None' : material,
    type: binding.terms[0].match(/shelf/i) ? 'Shelf' : 'Binding',
    text: binding.terms[0],
  };
};

export const resolveNeckComposition = (partConfig: any): INeckComposition => {
  const neckComposition = find(partConfig, (t) => t.type === 'Neck_Composition');
  return neckComposition ? { terms: neckComposition.terms } : { terms: [] };
};

export const resolveTrussRod = (partConfig: any): ISimpleConfig => {
  const trussRod = find(partConfig, (t) => t.type === 'Truss_Rod');
  return trussRod ? { text: trussRod.terms[0] } : { text: '' };
};

export const resolveNeckShape = (partConfig: any): INeckShape => {
  const neckShape = find(partConfig, (t) => includes(['Neck_Shape_Nut', 'Neck_Shape_Carve', 'Neck_Shape_Radius'], t.type));
  if (!neckShape) return { nut: '', carve: '', radius: '' }; 
  
  return {
    nut: find(neckShape, (s) => s.type === 'Neck_Shape_Nut')?.terms[0].split('_')[0] || '',
    carve: find(neckShape, (s) => s.type === 'Neck_Shape_Carve')?.terms[0].split('_')[0] || '',
    radius: find(neckShape, (s) => s.type === 'Neck_Shape_Radius')?.terms[0].split('_')[0] || '',
  };
};

export const resolveFretwire = (partConfig: any): IFretwire => {
  const fretwire = find(partConfig, (t) => includes(['Fretwire_Prefix', 'Fretwire', 'Fretwire_Suffix'], t.type));
  if (!fretwire) return { prefix: '', wire: '', suffix: '' };
  return {
    prefix: find(fretwire, (s) => s.type === 'Fretwire_Prefix')?.terms[0].split('_')[0] || '',
    wire: find(fretwire, (s) => s.type === 'Fretwire')?.terms[0].split('_')[0] || '',
    suffix: find(fretwire, (s) => s.type === 'Fretwire_Suffix')?.terms[0].split('_')[0] || '',
  };
};

export const resolveInlay = (partConfig: any): IInlay => {
  const inlay = find(partConfig, (t) => t.type === 'Inlay');
  if (!inlay) return { front: { material: 'None', shape: 'None' }, side: { material: 'None', shape: 'None' }, text: 'None' };
  const [front, side] = inlay?.terms[0].split('/').map((t: string) => cleanMeta(t));
  const inlayShapeMatcher = /Block[s]?|Frame[s]?|Crown[s]?|Leaves|Oval[s]?|F$|S$/i;
  const frontShape = front?.match(inlayShapeMatcher) || '';
  const frontMaterial = front?.replace(frontShape, '');
  const sideShape = side?.match(inlayShapeMatcher) || '';
  const sideMaterial = side?.replace(sideShape, '');
  return {
    front: { material: frontMaterial, shape: frontShape[0] },
    side: { material: sideMaterial, shape: sideShape[0] },
    text: inlay.terms[0],
  };
};

export const resolveNeckFeatures = (partConfig: any): INeckFeatures => {
  const neckFeatures = find(partConfig, (t) => t.type === 'Neck_Features');
  return neckFeatures ? { terms: neckFeatures.terms } : { terms: [] };
};
