import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { find } from 'lodash';
import { ICustomerRecord } from 'shared/types/dbRecords';
import {
  RouterTDButton,
} from '../../styledComponents';
import { currentCustomerAtom, customersAtom } from '../../../../shared/state/customerState';

interface IComponentInterface {
  record: any;
}

export default ({ record }: IComponentInterface) => {
  const customers = useRecoilValue(customersAtom);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const onClick = (e: any) => {
    e.preventDefault();
    localStorage.setItem('salesOrderViewType', 'customer');
    localStorage.setItem('currentCustomerId', record.customer.id);
    // setCurrentCustomer(find(customers, (c: ICustomerRecord) => c.id === record.customer.id) as ICustomerRecord);
    window.location.href = '/schedule/customer';
  };

  return (
    <RouterTDButton onClick={onClick}>{record.customer.id}</RouterTDButton>
  );
};
