import { atom } from 'recoil';
import firebase from 'firebase';
import { DEFAULT_SPREAD_LENGTH, DEFAULT_SPREAD_THICK, DEFAULT_SPREAD_WIDTH } from '../util';
import { INCRecord, IGlCode, IProductCode } from '../types/dbRecords';

export const materialScrapDataAtom = atom({
  key: 'materialScrapDataAtom',
  default: '',
});

export const currentWeightChartRowAtom = atom({
  key: 'currentWeightChartRowAtom',
  default: '',
});

export const currentSpreadWidthAtom = atom({
  key: 'currentSpreadWidthAtom',
  default: DEFAULT_SPREAD_WIDTH,
});
export const currentSpreadLengthAtom = atom({
  key: 'currentSpreadLengthAtom',
  default: DEFAULT_SPREAD_LENGTH,
});
export const currentSpreadThickAtom = atom({
  key: 'currentSpreadThickAtom',
  default: DEFAULT_SPREAD_THICK,
});
export const mapleTopAdjustedWeightAtom = atom({
  key: 'mapleTopAdjustedWeightAtom',
  default: false,
});

export const parsedBodyPartDataAtom = atom({
  key: 'parsedBodyPartDataAtom',
  default: '',
});

export const parsedNeckPartDataAtom = atom({
  key: 'parsedNeckPartDataAtom',
  default: '',
});

export const explodeAndUpdateAtom = atom({
  key: 'explodeAndUpdateAtom',
  default: false,
});

export const glCodesAtom = atom<IGlCode[]>({
  key: 'glCodesAtom',
  default: [],
});
export const productCodesAtom = atom<IProductCode[]>({
  key: 'productCodesAtom',
  default: [],
});

export const ncScanDepartmentAtom = atom({
  key: 'ncScanDepartmentAtom',
  default: '',
});

export const ncRecordsAtom = atom<any[]>({
  key: 'ncRecordsAtom',
  default: [],
});

export const ncPartNumberAtom = atom<string>({
  key: 'ncPartNumberAtom',
  default: '',
});

export const ncPartNumberQuantityAtom = atom({
  key: 'ncPartNumberQuantity',
  default: 1,
});

export const ncAdditionalNotesAtom = atom<null|string>({
  key: 'ncAdditionalNotesAtom',
  default: null,
});

export const ncReasonsObjectAtom = atom({
  key: 'ncReasonsAtom',
  default: {},
});

export const ncCurrentReasonAtom = atom({
  key: 'ncCurrentReason',
  default: '',
});

export const ncCurrentReasonsListAtom = atom<{label: string, id: string}[]>({
  key: 'ncCurrentReasonsList',
  default: [],
});

export const ncCorrectiveActionsObjectAtom = atom({
  key: 'ncCorrectiveActionsList',
  default: {},
});

export const ncCorrectiveActionAtom = atom({
  key: 'ncCorrectiveActionAtom',
  default: '',
});

export const ncCurrentCorrectiveActionsListAtom = atom({
  key: 'ncCurrenctCorrectiveActionsListAtom',
  default: [],
});

export const ncDepartmentObjectAtom = atom({
  key: 'ncDepartmentObjectAtom',
  default: [
    {
      departments: ['any'], label: 'QA/Shipping', id: 'az844sIxe', type: 'any',
    },
    {
      departments: ['any'], label: 'Milling', id: 'OaIOaIxe', type: 'any',
    },
    {
      departments: ['any'], label: 'Construction', id: 'MSuoo0q9LDS', type: 'any',
    },
    {
      departments: ['any'], label: 'CNC', id: '-AQR54tNx', type: 'any',
    },
    {
      departments: ['any'], label: 'Finishing', id: 'q9LDSuoo0', type: 'any',
    },
    {
      departments: ['any'], label: 'Office', id: '4tNx-AQR5', type: 'any',
    },
  ],
});

export const ncCurrentDepartmentListAtom = atom({
  key: 'ncDepartmentListAtom',
  default: [],
});

export const ncCurrentDepartmentAtom = atom({
  key: 'ncCurrentDepartmentAtom',
  default: '',
});

export const ncCaptureImageAtom = atom({
  key: 'ncCaptureImageAtom',
  default: false,
});

export const ncImageDataItemsAtom = atom({
  key: 'ncImageDataItems',
  default: [],
});

export const ncReviewImageAtom = atom({
  key: 'ncReviewImageAtom',
  default: false,
});

export const currentNcRecordAtom = atom<INCRecord>({
  key: 'currentNcRecordAtom',
  default: {
    id: '',
    salesOrder: { id: '', orderNumber: '0' },
    customerId: '',
    shopOperator: '',
    workOrder: '1',
    department: '',
    partNumber: '',
    partDescription: '',
    quantity: 1,
    reasonCode: '',
    correctiveAction: '',
    notes: '',
    type: 'body',
    dateRecorded: firebase.firestore.Timestamp.fromDate(new Date()),
    imageUrls: [],
    maxPartClass: 0,
  },
});

export const showRealtimeDataDrawerAtom = atom<boolean>({
  key: 'showRealtimeDataDrawerAtom',
  default: false,
});
