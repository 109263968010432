import React from 'react';
import styled from 'styled-components';
import { IShipmentItem } from 'shared/types/dbRecords';
import { FlexRow, FlexColumn } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { formatPrice } from 'shared/data';
import { rgba } from 'shared/palette/utils';

const ComponentWrapper = styled(FlexColumn)`
    display: none;
    align-items: flex-start;
    gap: 2px;
    width: 70vw;
    @media ${theme.device.laptopL} {
      display: flex;
    }
`;

const ItemWrapper = styled(FlexRow)`
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    width: 100%;
    min-height: 48px;
    background-color: ${(props: any) => (props.shade ? rgba(theme.palette.neutral[100], 0.5) : 'transparent')};
`;

const HeaderWrapper = styled(ItemWrapper)`
    border-bottom: 1px solid lightgrey;
    height: 60px;
`;

const ItemCopy = styled.p`
    margin: 0;
`;
const ItemNumber = styled(ItemCopy)`
  font-weight: bold;
    width: 8%;
`;

const ItemDescription = styled(FlexColumn)`
    padding-left: 8px;
    align-items: flex-start;
    //gap: 4px;
    width: calc(60% - 8px);
`;
const ItemDescriptionCopy = styled(ItemCopy)`
`;
const ItemNotesCopy = styled(ItemCopy)`
    margin-left: 8px;
    font-size: 14px;
    color: ${theme.palette.neutral[500]};
`;
const ItemQuantity = styled(ItemCopy)`
    text-align: center;
    width: 10%;
`;
const ItemUnitAmount = styled(ItemCopy)`
    width: 10%;
`;
const ItemTotalAmount = styled(ItemCopy)`
    width: 10%;
`;

interface IComponent {
  items: IShipmentItem[];
}
const ShipmentItemsPopover = ({ items }: IComponent) => {
  const foo = 'bar';
  return (
    <ComponentWrapper>
      <HeaderWrapper shade={false}>
        <ItemNumber>Sku</ItemNumber>
        <ItemDescription>Description</ItemDescription>
        <ItemQuantity>Shipped</ItemQuantity>
        <ItemUnitAmount>Each</ItemUnitAmount>
        <ItemTotalAmount>Total</ItemTotalAmount>
      </HeaderWrapper>
      {items.map((i: IShipmentItem, index) => (
        <ItemWrapper shade={index % 2 === 1}>
          <ItemNumber>{i.Sku}</ItemNumber>
          <ItemDescription>
            <ItemDescriptionCopy>{i.Description}</ItemDescriptionCopy>
            {i.notes?.length > 0 && (
              <ItemNotesCopy>{i.notes}</ItemNotesCopy>
            )}
          </ItemDescription>
          <ItemQuantity>{i.quantityShipped}</ItemQuantity>
          <ItemUnitAmount>{formatPrice(i.unitPrice)}</ItemUnitAmount>
          <ItemTotalAmount>{formatPrice(i.quantityShipped * i.unitPrice)}</ItemTotalAmount>
        </ItemWrapper>
      ))}
    </ComponentWrapper>
  );
};

export default ShipmentItemsPopover;
