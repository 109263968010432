import React from 'react';
import { Button } from 'antd';
import { find } from 'lodash';
import { Add } from '@styled-icons/material';
import styled from 'styled-components';
import shortid from 'shortid';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentCustomerShippingAddressesAtom, customerShippingAddressesEditedAtom,
  selectedCustomerShippingAddressAtom,
} from 'shared/state/customerState';
import { IShippingAddress } from 'shared/types/dbRecords';

const ComponentButton = styled(Button)`
  border-radius: 8px;
  margin: 0 0 6px 0;
  padding: 2px 8px;
`;

const AddIcon = styled(Add)`
  width: 20px;
  margin: 0 4px 2px 0;
`;

const NewShippingAddressButton = () => {
  const currentCustomerShippingAddresses = useRecoilValue(currentCustomerShippingAddressesAtom);
  const setSelectedAddress = useSetRecoilState(selectedCustomerShippingAddressAtom);
  const setEditMode = useSetRecoilState(customerShippingAddressesEditedAtom);

  const onClick = (e: any) => {
    e.preventDefault();
    setEditMode(true);
    const newAddress = {
      Id: shortid.generate(),
      City: '',
      Country: '',
      CountrySubDivisionCode: '',
      PostalCode: '',
      Line1: '',
      Line2: '',
      Line3: '',
      Line4: '',
      name: `Shipping Address ${currentCustomerShippingAddresses.length}`,
      default: false,
    } as IShippingAddress;

    setSelectedAddress(newAddress);
  };

  return (
    <ComponentButton type="primary" onClick={onClick} icon={<AddIcon />}>New</ComponentButton>
  );
};

export default NewShippingAddressButton;
