import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { History, CheckCircle } from '@styled-icons/material-twotone';
import { FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { IOrderItem } from 'shared/types/dbRecords';

const ComponentWrapper = styled(FlexRow)`
  justify-content: flex-start;
`;

const RefreshIcon = styled(History)`
  width: 24px;
  height: 24px;
  color: ${theme.palette.success.hue};
  transition: color 0.2s ease-in-out;
`;
const DoneIcon = styled(CheckCircle)`
  width: 22px;
  height: 22px;
  margin: 0 0 2px 6px;
  color: ${theme.palette.primary.hue};
`;
const ButtonText = styled.p`
  margin: 0 0 0 4px;
  font-size: 14px;
  line-height: 22px;
  transition: color 0.2s ease-in-out;
`;
const ComponentButton = styled(Button)`
  display: flex;
  border-radius: 8px;
  padding-right: 22px;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${theme.palette.primary.hue};
    & ${RefreshIcon} {
      color: ${theme.palette.neutral.white};
    }
    & ${ButtonText} {
      color: ${theme.palette.neutral.white};
    }
  }
`;

interface IComponent {
  orderItems: IOrderItem[];
  callback: (items: IOrderItem[]) => Promise<void>;
}
const ResetAllocationButton = ({ callback, orderItems }: IComponent) => {
  const [_orderItems, setOrderItems] = useState<IOrderItem[]>(orderItems);
  const [showWork, setShowWork] = useState<boolean>(false);

  const onClick = async (e: any) => {
    setShowWork(true);
    const updatedItems: IOrderItem[] = _orderItems.map((o: IOrderItem) => ({
      ...o,
      quantityAssigned: 0,
    }));
    await callback(updatedItems);
    setShowWork(false);
  };

  useEffect(() => {
    setOrderItems(orderItems);
  }, [orderItems]);
  return (
    <ComponentWrapper key="reset-item-allocation-button-wrapper">
      <ComponentButton type="default" onClick={onClick} key="refresh-jb-items-button">
        <RefreshIcon key="refresh-jb-items-icon" />
        { showWork ? (
          <ButtonText key="refresh-jb-items-button-text-work">Working...</ButtonText>
        ) : (
          <ButtonText key="refresh-jb-items-button-text-refresh">Reset Allocation</ButtonText>
        )}
      </ComponentButton>
    </ComponentWrapper>
  );
};

export default ResetAllocationButton;
