import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Button } from 'antd';

import bodyParser from 'shared/partParser/bodyParser';
import neckParser from 'shared/partParser/neckParser';
import { parsedBodyPartDataAtom, parsedNeckPartDataAtom } from 'shared/state/utilState';
import { FlexColumn } from 'shared/containers/FlexContainer';
import useFirebase from 'vendor/Firebase';
import { CustomerDownloadButton } from '../../Customer/Components/styledComponents';
import { PART_VIEWER_COLLECTION } from '../../../shared/state/partViewState';

const ParseButton = styled(Button)`
  min-width: 240px;
  text-align: center;
  margin-bottom: unset;
  margin-bottom: 4px;
  border-radius: 8px;
`;

const DownloadButton = styled(CustomerDownloadButton)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

interface IComponent {
  label: string;
}

export default ({ label }: IComponent) => {
  const { firestore } = useFirebase();
  const [parsedBodyPartData, setParsedBodyPartData] = useRecoilState(parsedBodyPartDataAtom);
  const [parsedNeckPartData, setParsedNeckPartData] = useRecoilState(parsedNeckPartDataAtom);
  const partViewerDatabaseString = useRecoilValue(PART_VIEWER_COLLECTION);

  const onNeckDownload = (e: any) => {
    const neckParts: string[][] = [];
    firestore.collection(partViewerDatabaseString).where('type', 'in', ['BN', 'GN']).get()
      .then((snap) => {
        snap.forEach((doc) => {
          const record = doc.data();
          if (record.active) {
            const data: string[] = [record.active ? 'Y' : 'N', record.Sku, record.Description, record.price].map((t: any) => t.toString());
            neckParts.push(data);
          }
        });
        const parsedNeckData = neckParser(neckParts).filter((i) => i);
        setParsedNeckPartData([
        // @ts-ignore -- results are filtered so only defined parts come back
          Object.keys(parsedNeckData[0]).join(','),
          ...parsedNeckData.map((pd: any) => Object.values(pd).join(',')),
        ].join('\r'));
      });
  };
  const onBodyDownload = (e: any) => {
    const bodyParts: string[][] = [];
    firestore.collection(partViewerDatabaseString).where('type', 'in', ['BB', 'GB']).get()
      .then((snap) => {
        snap.forEach((doc) => {
          const record = doc.data();
          if (record.active) {
            const data: string[] = [record.active ? 'Y' : 'N', record.Sku, record.Description, record.price, '', '', record.volume || ''].map((t: any) => t.toString());
            bodyParts.push(data);
          }
        });
        const parsedBodyData = bodyParser(bodyParts).filter((i) => i);
        setParsedBodyPartData([
        // @ts-ignore -- results are filtered so only defined parts come back
          [...Object.keys(parsedBodyData[0]), 'density'].join(','),
          ...parsedBodyData.map((pd: any) => {
            const values: string[] = [];
            const keys = Object.keys(pd);
            keys.forEach((k) => {
              if (k === 'weight') values.push(pd[k].value);
              else values.push(pd[k]);
            });
            values.push((parseFloat(pd.weight.value) / parseFloat(pd.volume)).toString());
            return values.join(',');
          }),
        ].join('\r'));
      });
  };

  return (
    <FlexColumn align="center" justify="flex-start">
      {parsedBodyPartData.length === 0 ? (
        <ParseButton
          key="body-part-data-download-button"
          onClick={onBodyDownload}
        >
          Download Body Part Data
        </ParseButton>
      ) : (
        <DownloadButton
          key="body-part-data-download-button"
          disabled={parsedBodyPartData === ''}
          filename={`Parsed_Body_Data_${new Date().toLocaleString()}.csv`}
          data={parsedBodyPartData}
          onClick={() => {
            setTimeout(() => {
              setParsedBodyPartData('');
            }, 200);
          }}
        >
          Download Body Part Data
        </DownloadButton>
      )}
      {parsedNeckPartData.length === 0 ? (
        <ParseButton
          key="neck-part-data-download-button"
          onClick={onNeckDownload}
        >
          Download Neck Part Data
        </ParseButton>
      ) : (
        <DownloadButton
          key="neck-part-data-download-button"
          filename={`Parsed_Neck_Data_${new Date().toLocaleString()}.csv`}
          data={parsedNeckPartData}
          onClick={() => {
            setTimeout(() => {
              setParsedNeckPartData('');
            }, 200);
          }}
        >
          Download Neck Part Data
        </DownloadButton>
      )}
    </FlexColumn>
  );
};
