import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import { useRecoilValue } from 'recoil';
import { NON_CONFORMANCE_COLLECTION } from 'shared/state/siteState';
import { currentShopOrderAtom, ORDER_ITEMS_DB_COLLECTION, ORDERS_DB_COLLECTION } from 'shared/state/orderState';
import { clearRedirect, popRedirect, redirect } from 'shared/util';
import theme from 'shared/theme';

const ComponentButton = styled(Button)`
  background-color: ${theme.palette.error.hue};
  border-color: ${theme.palette.error.hue};
  
  &:hover {
    background-color: ${theme.palette.error[900]};
    border-color: ${theme.palette.error[900]};
  }
`;
interface IComponent {
  returnView: string;
}
const DeleteOrderButton = ({ returnView }: IComponent) => {
  const { firestore } = useFirebase();
  const ncDbCollectionString = useRecoilValue(NON_CONFORMANCE_COLLECTION);
  const ordersDbCollectionString = useRecoilValue(ORDERS_DB_COLLECTION);
  const orderItemsDbCollectionString = useRecoilValue(ORDER_ITEMS_DB_COLLECTION);
  const currentShopOrder = useRecoilValue(currentShopOrderAtom);
  const redirectScheduleView = localStorage.getItem('salesOrderViewType') || 'week';
  const onDelete = (e: any) => {
    e.preventDefault();
    Modal.confirm({
      content: 'Delete this sales order, any associated router jobs, and related non-conformance items?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        firestore.collection(ncDbCollectionString).where('salesOrder.id', '==', currentShopOrder.id).get().then((snap) => {
          const docs: any[] = [];
          snap.forEach((doc) => {
            docs.push(doc.data());
          });
          const docCount = docs.length;
          let processed = 0;
          if (docCount !== 0) {
            docs.forEach((doc) => {
              firestore.collection(ncDbCollectionString).doc(doc.id).delete().then(() => {
                processed += 1;
                if (processed === docCount) {
                  firestore.collection(ordersDbCollectionString).doc(currentShopOrder.id).delete().then(() => {
                    firestore.collection(orderItemsDbCollectionString).doc(currentShopOrder.id).delete().then(() => {
                      const redirectUrl = popRedirect();
                      window.location.href = redirectUrl || `/schedule/${redirectScheduleView}`;
                    });
                  });
                }
              });
            });
          } else {
            firestore.collection(ordersDbCollectionString).doc(currentShopOrder.id).delete().then(() => {
              firestore.collection(orderItemsDbCollectionString).doc(currentShopOrder.id).delete().then(() => {
                clearRedirect();
                window.location.href = `/schedule/${redirectScheduleView}`;
              });
            });
          }
        });
      },
    });
  };

  return (
    <ComponentButton style={{ marginLeft: 'auto' }} type="danger" onClick={onDelete}>Delete</ComponentButton>
  );
};

export default DeleteOrderButton;
