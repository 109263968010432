import { addMeta, cleanMeta } from '../text';

import { resolveActive } from './helpers';
import models from './models';
import { sanitizePartDescription, descriptionToConfig } from './util';
import { NECK_WOOD_MAP, INLAY_MAP } from './parsingTerms';

const _ = require('lodash');
const {
  extractPattern,
  regexMaker,
  normalizeTerm,
  partType,
  simpleTest,
} = require('./helpers');

const extractNut = (partDescription: string) => {
  const match = extractPattern(partDescription, '(1.[0-9]+N)');
  return match || '';
};

const extractWood = (partDescription: string) => {
  const m = regexMaker(_.flatMapDeep(Object.entries(NECK_WOOD_MAP)), [], false, true, 'end');
  const wood = partDescription.match(m) || ['//'];
  const self = simpleTest(partDescription, 'self');
  const [core, fretboard, ph] = wood[0].split('/').map((s) => s.replace(/_/g, '').trim());
  const _ph = ph ? ph.replace(/(\s?PH([\scap]+)?)/i, '') : 'None';
  return [
    core && core.match(/self/i) && self ? 'CS' : core,
    fretboard ? fretboard.match(/cs/i) ? 'CS' : self ? 'SELF' : fretboard : '',
    _ph,
  ].map((w) => (w ? normalizeTerm(NECK_WOOD_MAP, w) : ''));
};

const extractInlay = (partDescription: string) => {
  const inlayMatcher = /([a-z\s]+)(Frame[s]?|Crown[s]?|Block[s]?|Leaves|Oval[s]?|F)(-[^\/]+)?\/(([0-9\.]{1,3}mm)?[a-z]+)(S)/i;
  const inlayMatch = partDescription.match(inlayMatcher);

  if (!inlayMatch) {
    return { frontInlay: { material: '', shape: '' }, sideInlay: { material: '', shape: '' } };
  }

  const [match, _frontMaterial, _frontType, _frontMisc, _sideMaterial, materialSize, _sideType, ...rest] = inlayMatch;
  let frontMaterial = _frontMaterial;
  let frontType = _frontType;
  let sideMaterial = _sideMaterial;
  let sideType = _sideType;

  /*
  * if the front or side "material" is "No" then it is actually geometric in nature,
  * for this reason, it should be concatenated with the type value as opposed to material.
  */
  if (_frontMaterial === 'No') {
    frontMaterial = '';
    frontType = `${_frontMaterial}${_frontType}`;
  }

  if (_sideMaterial === 'No') {
    sideMaterial = '';
    sideType = `${_sideMaterial}${_sideType}`;
  } else if (_sideMaterial.match(/lumi/i)) {
  /*
 * if the "material" is a type of luminlay then we need to indicate the size of the side dot
 * so we know if it is the same geometric shape as another.
 */
    const size = _sideMaterial.match(/[0-9]mm/);
    if (size) {
      sideMaterial = _sideMaterial.replace(size[0], '');
      sideType = `${size[0]}${_sideType}`;
    }
  }

  return { frontInlay: { material: frontMaterial, shape: frontType }, sideInlay: { material: sideMaterial, shape: sideType } };
};

const extractFretCount = (partDescription: string) => {
  let match = extractPattern(partDescription, '[0-9]{2}F');
  if (match) return match.replace('F', '');

  match = extractPattern(partDescription, '[0-9]{1}-[0-9]{2}-[0-9]{2}');
  return match ? parseInt(match.split('-')[1], 10) : '';
};

const extractFretProfile = (partDescription: string) => {
  let fretwireProfile = 'any';
  let fretwireMaterial = 'NoFrets';

  let match = partDescription.match(/_(\*{2})?(Loose)?[0-9]{5}(SS)?(EVO)?(EG)?(NoFrets)?(\*{2})?_/i);
  if (match) {
    const profileMatch = match[0].match(/[0-9]{5}/);
    if (profileMatch) fretwireProfile = profileMatch[0];
    const materialMatch = match[0].match(/(ss|evo|eg|nofrets)/i);
    fretwireMaterial = materialMatch ? materialMatch[0] : 'NS';
  }

  match = extractPattern(partDescription, '_(lined)?(frets|fretless)_');
  if (match) {
    // @ts-ignore
    fretwireProfile = match.replace(/_/g, '');
    fretwireMaterial = fretwireProfile.match(/lined/i) ? 'Plastic' : 'None';
  }

  return { profile: fretwireProfile, material: fretwireMaterial };
};

const model = (partDescription: string) => {
  const terms = partDescription.split('_');
  const rootModel = terms[0];
  
  // Create a regex pattern that matches whole words from the models array
  const modelPattern = new RegExp(`^(${models.join('|')})$`, 'i');
  
  const matchedModels = terms.filter((term) => modelPattern.test(term));
  
  if (matchedModels.length === 0) return 'NO MATCH';
  
  // Use Set to remove duplicates, then convert back to array
  const uniqueMatches = Array.from(new Set(matchedModels));
  
  return [rootModel, ...uniqueMatches].join('_');
};

const extractBinding = (partDescription: string) => {
  const r = /(Map|White|Cream|Black|Pearloid)?(bind){1}(shelf)?_/i;
  const match = partDescription.match(r);
  if (match) {
    if (match[1]) return match[1];
    return 'Binding Shelf';
  }
  return 'None';
};

const extractStrings = (partDescription: string) => {
  if (simpleTest(partDescription, '^BN')) {
    let match = extractPattern(partDescription, '[456]ST');
    if (match) return match.replace(/st/i, '');
    match = extractPattern(partDescription, '[0-9]{1}-[0-9]{2}-[0-9]{2}');
    return match ? match.split('-')[0] : '';
  }
  return simpleTest(partDescription, '12ST') ? '12' : '6';
};

const graphite = (partDescription: string) => {
  const match = partDescription.match(/(cs|ww|dbl|pi)?\s?gr[a]?ph/i);
  if (match && match[0] === ' Graph') console.log(partDescription);
  return match ? match[0] : '';
};

const cncLoad = (partNumber: string, description: string) => {
  if (description.match(/1Pc/) || partNumber.match(/NASHG/)) {
    return 'baby';
  } if (description.match(/tilt/i)) {
    return 'papa';
  }
  return 'mama';
};

export const parseNecksFromPartData = (partData: any[]) => {
  const partDetails = partData.map((p) => [p.active ? 'Y' : 'N', p.Sku, p.Description, p.price.toString()]);
  return neckParser(partDetails);
};
const neckParser = (neckData: string[][]) => neckData
  .map((l: string[]) => {
    try {
      const [active, partNumber, _description, price, productCode, glCode, volume, parent, revisonNeeded, ...rest] = l;
      const description = cleanMeta(_description);
      const customer = partNumber.slice(0, 5);
      const type = partType(description);
      const [core, fretboard, ph] = extractWood(description);
      const { frontInlay, sideInlay } = extractInlay(description);
      const partPrice = price ? parseInt(price.replace('$', ''), 10) : 0;
      return {
        active: resolveActive(active),
        // common part attributes
        customer: customer || '',
        cncLoad: cncLoad(partNumber, description),
        Sku: partNumber,
        Description: addMeta(descriptionToConfig(sanitizePartDescription(description)) as string),
        type: type || '',
        price: partPrice,
        model: model(description),
        neckCore: core[0] || '',
        fretboard: fretboard[0] || '',
        peghead: (ph && ph[0]) ? ph[0] : 'None',
        lefty: simpleTest(description, 'lefty|lft|left', 'Y', 'N'),
        scale: extractPattern(description, '[0-9]{1}/[0-9]{2}(/[0-9]{2})?'),

        // neck-specific
        // bound: extractPattern(description, '_([a-z]+\\s)?(bound|bind)_', 'N binding').replace(/_/g, ''),
        bound: extractBinding(description),
        carve: extractPattern(description, '([0-9]+(-[0-9]+)?(A|BT|C|D|SRV|SV|U))'),
        cnc: simpleTest(description, 'cnc', 'Y', 'N'),
        fbs: simpleTest(description, 'nofbs', 'N', 'Y'),
        fretCount: extractFretCount(description),
        fretwire: extractFretProfile(description),
        frontInlay,
        graphite: graphite(description),
        looseFrets: simpleTest(description, 'loose', 'Y', 'N'),
        nfs: simpleTest(description, 'nfs', 'N', 'Y'),
        nut: extractNut(description),
        nutType: simpleTest(description, 'floyd', 'Floyd', 'Standard'),
        radius: extractPattern(description, '[-0-9/.]+?C?R'),
        revisonNeeded: revisonNeeded === 'Y',
        revPh: simpleTest(description, 'revph', 'Y', 'N'),
        skunk: extractPattern(description, 'sk(?:unk|&b)'),
        sideInlay,
        stringCount: extractStrings(description),
        tilt: simpleTest(description, 'tilt', 'Y', 'N'),
        trAccess: simpleTest(description, '[12]WP') ? 'Peghead' : simpleTest(description, '[12]WS', 'Spoke', 'Heel'),
        trType: simpleTest(description, '2W', '2-way', '1-way'),
        vintage: simpleTest(description, 'Vin', 'Y', 'N'),
        volume: '',
      };
    } catch (e) {
      return undefined;
    }
  });

export default neckParser;
