import React from 'react';
import { useRecoilValue } from 'recoil';
import { flatten, flattenDeep } from 'lodash';
import { closestMonday, dateString, findJobWeek } from 'shared/data/calendar';
import { orderWeeksAtom } from 'shared/state/routingState';
import { RouterTDButton } from '../../styledComponents';

import { IShopOrder } from '../../../Orders/types';

interface ComponentInterface {
  record: IShopOrder;
}

export default ({ record }: ComponentInterface) => {
  const orderWeeks = useRecoilValue(orderWeeksAtom);
  const onClick = (e: any) => {
    e.preventDefault();
    const shipDate = new Date(record.shipDate.toDate());
    shipDate.setHours(0, 0, 0, 0);
    const shipDateString = dateString(shipDate);
    const orderWeekDates = flatten(flatten(orderWeeks)
      .map((week: Date[]) => week.map((d: Date) => dateString(d)))
      .filter((week: string[]) => week.indexOf(shipDateString) > -1))
      .map((d: string) => new Date(d));

    // localStorage.setItem('salesOrderViewType', 'week');
    const firstDayOfWeek = orderWeekDates[0];
    window.location.href = `/schedule/week#${firstDayOfWeek.getTime().toString()}`;
    // localStorage.setItem('customerViewCustomer', record.customer.id || '');

    return null;
  };
  return (
    <RouterTDButton
      onClick={onClick}
    >
      {dateString(record.shipDate.toDate(), true)}
    </RouterTDButton>
  );
};
