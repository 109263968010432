import React, { useEffect, useState } from 'react';
import {
  Select,
} from 'antd';
import styled from 'styled-components';
import {
  RecoilState, useRecoilState, useRecoilValue, useSetRecoilState,
} from 'recoil';
import { currentNcRecordAtom } from 'shared/state/utilState';
import { currentShopOrderAtom } from 'shared/state/orderState';

interface IMenuComponent {
  callback: (e: any) => void;
  dataList: RecoilState<{label: string, id: string}[]>
  listTitle: string;
}

interface IActionOption {
  departments: string[];
  id: string;
  label: string;
  type: 'any'|'body'|'neck';
}
interface IComponent {
  ncField: string;
  ncOptionsAtom: RecoilState<any>;
  ncSelectedOptionAtom: RecoilState<string>;
  listPrompt: string;
  listTitle: string;
  onChange?: (e: any) => void;
  defaultValue?: string;
}

const ReasonSelect = styled(Select)`
  width: 100%;
  margin-bottom: 8px;
  
  & .ant-select-selector {
    height: 48px;
    line-height: 48px;
  }
`;

export default ({
  listTitle, listPrompt, ncOptionsAtom, ncSelectedOptionAtom, ncField, onChange = null, defaultValue = null,
}: IComponent) => {
  const [ncRecord, setNcRecord] = useRecoilState(currentNcRecordAtom);
  const ncOptions = useRecoilValue(ncOptionsAtom);
  const shopOrder = useRecoilValue(currentShopOrderAtom);
  const setSelectedOption = useSetRecoilState<string>(ncSelectedOptionAtom);
  const [optionsList, setOptionsList] = useState<IActionOption[]>([]);
  const [label, setLabel] = useState<string|null>(null);

  const onMenuClick = (e: string) => {
    setSelectedOption(e);
    const action = _.find(optionsList, (c) => c.label === e);
    if (action) setLabel(action.label);
    setNcRecord({
      ...ncRecord,
      [ncField]: action.label,
    });
    if (onChange) onChange(e);
  };

  useEffect(() => {
    const departmentOptions = ncOptions.filter((o: IActionOption) => (_.includes(o.departments, 'any') || _.includes(o.departments, ncRecord.department)) && _.includes([shopOrder.type, 'any'], o.type)) as IActionOption[];

    if (departmentOptions) setOptionsList(_.sortBy(departmentOptions, (d: IActionOption) => d.label));
    const currentLabelValid = _.includes(departmentOptions.map((o: IActionOption) => o.label), label);
    if (defaultValue && (listTitle.match(/department/i) || currentLabelValid)) setLabel(defaultValue);
    else setLabel('');
  }, [ncRecord.department]);

  return (
    <>
      { optionsList && (
      <ReasonSelect value={ncRecord[ncField]?.length ? ncRecord[ncField] : listPrompt} onChange={onMenuClick}>
        {optionsList.map((o) =>
          <Select.Option value={o.label}>{o.label}</Select.Option>)}
      </ReasonSelect>
      )}
    </>
  );
};
