import React, { useState } from 'react';
import { find } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Button } from 'antd';
import { Refresh } from '@styled-icons/material-twotone';
import theme from 'shared/theme';
import { FlexRow } from 'shared/containers/FlexContainer';
import { PART_BOM_COLLECTION, partBomItemsAtom } from 'shared/state/partViewState';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import { IBomItem, IInventoryPart } from 'shared/types/dbRecords';
import useFirebase from 'vendor/Firebase';
import PurchaseOrder from '../../../../shared/data/QBO/purchaseOrder';

const ComponentWrapper = styled(FlexRow)`
  justify-content: flex-start;
`;

const RefreshIcon = styled(Refresh)`
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
  color: ${theme.palette.success.hue};
  transition: color 0.2s ease-in-out;
`;
const ButtonText = styled.p`
  margin: 0 0 0 4px;
  font-size: 14px;
  line-height: 22px;
  transition: color 0.2s ease-in-out;
`;
const ComponentButton = styled(Button)`
  border: 1px solid lightgrey;
  margin-bottom: 4px;
  //display: flex;
  //border-radius: 8px;
  //padding-right: 22px;
  //transition: background-color 0.2s ease-in-out;
  &:hover {
   background-color: ${theme.palette.primary.hue};
     & ${RefreshIcon} {
       color: ${theme.palette.neutral.white};
    }
  //   & ${ButtonText} {
  //    color: ${theme.palette.neutral.white};
    }
  //}
 `;

interface IComponent {
  partId: string;
  callback: (parts: IBomItem[]) => void;
}

const RefreshBomDetailsButton = ({ callback, partId }: IComponent) => {
  const { firestore } = useFirebase();
  const [bomItems, setBomItems] = useRecoilState(partBomItemsAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const partBomDbCollection = useRecoilValue(PART_BOM_COLLECTION);
  const [showWork, setShowWork] = useState(false);

  const onClick = (e: any) => {
    setShowWork(true);
    const newBomItems = bomItems.map((b: IBomItem) => {
      const inventoryItem = find(inventoryItems, (i: IInventoryPart) => i.Sku === b.Sku) as IInventoryPart;
      if (!inventoryItem) return b;
      const newPricing = {
        Description: inventoryItem.Description,
        unit: inventoryItem.PurchasingUnit || 'EA',
        unitCost: inventoryItem.PurchaseCost,
        unitPrice: parseFloat((inventoryItem.UnitPrice.toFixed(3))),
        totalCost: parseFloat((inventoryItem.PurchaseCost * b.quantity).toFixed(2)),
        totalPrice: parseFloat((inventoryItem.UnitPrice * b.quantity).toFixed(3)),
      };
      return {
        ...b,
        ...newPricing,
      };
    });
    firestore.collection(partBomDbCollection).doc(partId).get().then((doc) => {
      const data = doc.data();
      firestore.collection(partBomDbCollection).doc(partId).set({ ...data, bom: newBomItems }).then(() => {
        setShowWork(false);
        setBomItems(newBomItems);
        callback(newBomItems);
      });
    });
  };

  return (
  // <ComponentWrapper key="refresh-jb-items-button-wrapper">
    <ComponentButton
      id="refresh-jb-items-button"
      shape="circle"
      type="default"
      onClick={onClick}
      key="refresh-jb-items-button"
    >
      <RefreshIcon key="refresh-jb-items-icon" />
      {/* { showWork ? ( */}
      {/*  <ButtonText key="refresh-jb-items-button-text-work">Working...</ButtonText> */}
      {/* ) : ( */}
      {/*  <ButtonText key="refresh-jb-items-button-text-refresh">Refresh</ButtonText> */}
      {/* )} */}
    </ComponentButton>
  // </ComponentWrapper>
  );
};

export default RefreshBomDetailsButton;
