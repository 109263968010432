import React from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { useRecoilValue } from 'recoil';
import QBOAuth from 'shared/data/QBO/auth';
import QBOCustomer from 'shared/data/QBO/customer';
import { ICustomerRecord } from 'shared/types/dbRecords';
import { CUSTOMERS_DB_COLLECTION } from 'shared/state/customerState';
import { devLog } from 'shared/util/logging';
import useFirebase from 'vendor/Firebase';

const SyncButton = styled(Button)`
  margin-left: 0;
  margin-bottom: 4px;
  min-width: 240px;
  text-align: center;
  border-radius: 8px;
`;

const SyncCustomers = () => {
  const { firestore, database } = useFirebase();
  const customerDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);

  const onClick = (e: any) => {
    const fetchCustomerData = (customer: ICustomerRecord, customers: ICustomerRecord[]) => QBOCustomer.fetchOne(customer.quickbooksId).then((qboData) => {
      // @ts-ignore - Customer is a valid data key for the returned data from the QBO API
      firestore.collection(customerDbString).doc(customer.id).update({ ...qboData.Customer })
      // @ts-ignore
        .then(() => {
          devLog('SyncContacts', 27, `Finished ${customer.id}`);
          if (customers.length === 0) {
            return Modal.info({
              title: 'Operation Complete',
              content: 'All finished synchronizing customers from QBO!',
            });
          }
          return fetchCustomerData(customers[0], customers.slice(1));
        });
    });
    firestore.collection(customerDbString).get().then((snap) => {
      const customers: ICustomerRecord[] = [];
      snap.forEach((doc) => {
        const data = doc.data();
        if (data.Id) customers.push(data as ICustomerRecord);
      });
      QBOAuth.refreshToken(database).then(() => {
        fetchCustomerData(customers[0], customers);
      });
    });
  };

  return (
    <SyncButton type="default" onClick={onClick}>Sync QBO Contact Data</SyncButton>
  );
};

export default SyncCustomers;
