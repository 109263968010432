import React from 'react';
import styled from 'styled-components';
import { rgba } from 'shared/palette/utils';
import theme from 'shared/theme';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { Button } from 'antd';
import { H3 } from 'shared/typography';
import { IRouterStep } from '../types';

const _ = require('lodash');
const shortid = require('shortid');

interface IComponent {
  stations: IRouterStep[];
  label?: string|null;
  callback: (stationId: string, stationName: string) => (e: any) => void;
}

export default ({ stations, label, callback }: IComponent) => {
  const buttonHeight = `${100 / stations.length}vw`;

  const Overlay = styled(FlexColumn)`
    width: 100vw;
    
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    z-index: 1000;
    
    background-color: ${rgba(theme.palette.neutral.black, 0.8)};
    backdrop-filter: blur(4px);
    
    color: ${theme.palette.neutral.white};
    padding: ${theme.spacing(3)};
  `;

  const SubStepButton = styled(Button)`
    width: calc(100vw - ${theme.spacing(4)});
    height: calc(${buttonHeight} - ${theme.spacing(4)}); 
    text-align: center;
    border-radius: 12px;
    border: rgba(255, 255, 255, 0.2);
    
    whitespace: normal;
    
    background-color: ${theme.palette.neutral.white}; 
    
    color: ${theme.palette.primary.hue};
    
    margin: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
    
    box-shadow: 4px 4px 8px ${rgba(theme.palette.neutral.black, 0.08)}, 
      -4px -4px 8px ${rgba(theme.palette.neutral.black, 0.1)}, 
      inset 1px 1px 8px ${rgba(theme.palette.neutral.black, 0.02)};
    
    & p {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }
    
    @media ${theme.device.laptop} {
        width: 200px;
        height: 200px;
        font-size: 20px;
        
        &:last-of-type {
          margin-bottom: ${theme.spacing(2)};
        }
    }
  `;

  const Header = styled(H3)`
    color: ${theme.palette.neutral.white};
    margin: 0;
    margin-bottom: ${theme.spacing(6)};
  `;

  return (
    <Overlay>
      <Header>{label || `Which ${stations[0].name} step is this work order at?`}</Header>
      { stations.map((s: IRouterStep) => (
        <SubStepButton
          key={shortid.generate()}
          onClick={callback(s.id, s.name)}
          value={s.id}
        >
          <p>{s.subName ? s.subName : s.name}</p>
        </SubStepButton>
      ))}
    </Overlay>
  );
};
