import styled from 'styled-components';
import { BREAKPOINTS } from 'shared/grid/constants';
import theme from 'shared/theme';
import { IContentContainerProps } from './types';

const Container = styled.div<IContentContainerProps>`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.neutral.transparent};
  max-width: ${(props) => (props.fixedWidth ? `${BREAKPOINTS.lg}px` : '100vw')};
  margin: 0 auto;
  
  @media ${theme.device.laptop} {
    height: unset;
  }
`;
Container.displayName = 'Container';

export default Container;
