import React, { useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import { Button } from 'antd';
import { useRecoilValue } from 'recoil';
import { BODY_DATA_PATH, NECK_DATA_PATH, useTestDataAtom } from 'shared/state/pricingState';
import { unitOptionColumns, marginUnitOptionColumns, rateOptionColumns } from './OptionColumns';
import { SalesOrderTable } from '../../ProductionSchedule/styledComponents';
import { IPartWood, IUnitOption } from '../../../shared/types/pricingTool';

const _ = require('lodash');

interface ComponentInterface {
  listType: 'unitOption'|'rateOption';
  optionType: 'accessoryOptions'
  | 'bodyBlankModifications'
  | 'constructionOptions'
  | 'dotOptions'
  | 'finishingOptions'
  | 'frettingOptions'
  | 'handlingOptions'
  | 'inlayOptions'
  | 'laborOptions'
  | 'trussRodOptions'
  | 'customerSpecificOptions'
  | 'weightReductionOptions';
}

export default ({ listType, optionType }: ComponentInterface) => {
  const { database } = useFirebase();
  const useTestData = useRecoilValue(useTestDataAtom);
  const bodyDataPath = useRecoilValue(BODY_DATA_PATH);
  const neckDataPath = useRecoilValue(NECK_DATA_PATH);
  const partType = localStorage.getItem('pricingEditCategory')?.match(/neck/i) ? neckDataPath : bodyDataPath;

  const [listItems, setListItems] = useState<IPartWood[]>([]);

  const onNewClick = (e: any) => {
    window.location.href = `/pricing/option/edit?partType=${partType}&optionType=${optionType}`;
  };

  const marginBasedUnit = _.includes(['trussRodOptions', 'inlayOptions'], optionType);
  let columns: any[] = unitOptionColumns;
  if (!listType.match(/unitOption/)) columns = rateOptionColumns;
  if (marginBasedUnit) columns = marginUnitOptionColumns;

  useEffect(() => {
    database.ref(`${partType}/options/${optionType}/items`).once('value', (snapshot) => {
      setListItems([]);
      const data = snapshot.val();
      setTimeout(() => {
        // @ts-ignore
        setListItems(Object.values(data).sort((a: IUnitOption, b: IUnitOption) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        }));
      }, 0);
    });
  }, [useTestData]);

  return (
    <>
      <Button type="primary" shape="round" onClick={onNewClick}>Add new</Button>
      <SalesOrderTable
        rowKey="recordId"
        size="small"
        // @ts-ignore
        columns={columns}
        dataSource={listItems}
        pagination={false}
        rowClassName="sales-order-row"
      />
    </>
  );
};
