import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  NoLeftMarginViewTypeRadioGroup,
  ViewTypeRadioButton, ViewTypeSelect, ViewTypeSelectLabel,
  ViewTypeSelectOption,
} from 'shared/styledComponents/inputs';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { PartViewerFilterWrapper } from 'shared/styledComponents/containers';
import { ItemDetailsLabel } from '../../PartDetail/Components/PartDetailFields/styledComponents';

const ViewTypeSelectWrapper = styled(FlexColumn)`
    position: relative;
    bottom: 10px;
`;
const RadioSelectLabel = styled(ItemDetailsLabel)`
  display: none;
  @media ${theme.device.laptopL} {
    display: unset;
  }
`;
interface IComponent {
  callback: (value: 'all'|'onOrder') => void;
  currentValue: 'all'|'onOrder';
}

export default ({ callback, currentValue }: IComponent) => {
  const [status, setStatus] = useState<'all'|'onOrder'>(currentValue);
  const onChangeView = (e: any) => {
    const value = e.target?.value || e;
    callback(value);
  };

  useEffect(() => {
    setStatus(currentValue);
  }, [currentValue]);

  return (
    <PartViewerFilterWrapper>
      <RadioSelectLabel key="part-sort-type-item-details-label">Release Status</RadioSelectLabel>
      <ViewTypeSelectWrapper>
        <ViewTypeSelectLabel>Release Status</ViewTypeSelectLabel>
        <ViewTypeSelect
          label="Release Date"
          size="default"
          value={status}
          onChange={onChangeView}
        >
          <ViewTypeSelectOption value="all">All</ViewTypeSelectOption>
          <ViewTypeSelectOption value="onOrder">On Order</ViewTypeSelectOption>
        </ViewTypeSelect>
      </ViewTypeSelectWrapper>
      <NoLeftMarginViewTypeRadioGroup value={status} buttonStyle="solid" onChange={onChangeView}>
        <ViewTypeRadioButton
          value="all"
        >
          All
        </ViewTypeRadioButton>
        <ViewTypeRadioButton
          value="onOrder"
        >
          On Order
        </ViewTypeRadioButton>
      </NoLeftMarginViewTypeRadioGroup>
    </PartViewerFilterWrapper>
  );
};
