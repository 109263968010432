import React, { useCallback, useContext, useState } from 'react';
import { includes } from 'lodash';
import { withRouter, Redirect } from 'react-router-dom';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { Button, Form, Input } from 'antd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentUserEmailAtom,
  customerUserEmailsAtom,
  scannerUserEmailsAtom, shopUserEmailsAtom,
} from 'shared/state/routingState';
import Logo from 'shared/components/Logo/Logo';
import axios from 'axios';

import { QBO_AUTH_PATH, QBO_USERS_ATOM, qboAuthUriAtom } from 'shared/state/qboState';
import QBOAuth from 'shared/data/QBO/auth';
import { devLog } from 'shared/util/logging';
import {
  PageContentWrapper,
  FormComponent,
  FormButtonWrapper,
  LogoWrapper,
  AuthFormInput,
  InputCaption,
  CopyrightWrapper, LoginLink, OuterWrapper, HelperWrapper, LoginLinkWrapper,
} from './styledComponents';

/**
 * Simple login form for the Admin tool website.
 */
const Login = ({ history }: { history: any }) => {
  const { auth, database } = useFirebase();
  const setCurrentUserEmail = useSetRecoilState(currentUserEmailAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const shopUserEmails = useRecoilValue(shopUserEmailsAtom);
  const scannerStationEmails = useRecoilValue(scannerUserEmailsAtom);
  const qboUsers = useRecoilValue(QBO_USERS_ATOM);
  const qboAuthUri = useRecoilValue(qboAuthUriAtom);
  const qboAuthPath = useRecoilValue(QBO_AUTH_PATH);
  const [loginValue, setLoginValue] = useState<string>('');

  const handleLogin = useCallback(
    async (values: any) => {
      const { username, password } = values;
      const user = username.match(/@wildwoodmfg\.com$/) ? username : `${username}@wildwoodmfg.com`;
      try {
        await auth().signInWithEmailAndPassword(user, password);
      } catch (error) {
        alert(error);
      }
    },
    [history],
  );

  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  /**
   * Once the user is logged in, choose an appropriate redirect based on the user's role.
   */
  if (currentUser) {
    setCurrentUserEmail(currentUser.email);
    if (includes(qboUsers, currentUser.email)) {
      database.ref(qboAuthPath).once('value').then((data) => {
        const authData = data.val();
        devLog('Login.tsx', 64, authData?.refresh_token);
        if (authData?.refresh_token) {
          devLog('Login.tsx', 66, `found a refresh token: ${authData.refresh_token}`);
          localStorage.setItem('qbo.refreshToken', authData.refresh_token);
          window.location.href = '/';
        } else {
          QBOAuth.authUri().then((auth_data) => {
            window.location.href = auth_data;
          }).catch((e: any) => {
            console.log(e);
          });
        }
      });
    } else {
      if (includes(customerEmails.emails, currentUser.email)) return <Redirect to="/pricing" />;
      if (includes(scannerStationEmails.emails, currentUser.email)) {
        if (window.location.pathname.match(/nc/)) return <Redirect to="/nc/scanner" />;
        return <Redirect to="/scanner" />;
      }
      if (includes(shopUserEmails.emails, currentUser.email)) {
        return <Redirect to="/scanner" />;
      }
      // default redirect
      return <Redirect to="/" />;
    }
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <>
      <OuterWrapper id="outer-wrapper">
        <PageContentWrapper>
          <LogoWrapper
            siteImage={`/images/${window.location.origin.match(/customer/i) ? 'customer_logo' : 'helm_logo'}.png`}
          />
          <FormComponent
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={handleLogin}
          >
            <InputCaption>Username</InputCaption>
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Use your Wildwood email!' }]}
            >
              <AuthFormInput />
            </Form.Item>

            <InputCaption>Password</InputCaption>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <AuthFormInput.Password />
            </Form.Item>

            <FormButtonWrapper>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </FormButtonWrapper>
            <LoginLinkWrapper>
              <LoginLink href="mailto:keithh@wildwoodmfg.com?subject=I'm having trouble logging in to HELM">Having trouble logging in?</LoginLink>
            </LoginLinkWrapper>
          </FormComponent>
        </PageContentWrapper>
        <HelperWrapper>
          <CopyrightWrapper>
            <LoginLink href="https://wildwoodmfg.com">Visit the Wildwood marketing site</LoginLink>
            <p>©2024 Wildwood Manufacturing. All rights reserved.</p>
          </CopyrightWrapper>
        </HelperWrapper>
      </OuterWrapper>
    </>
  );
};

export default withRouter(Login);
