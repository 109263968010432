import React from 'react';
import firebase from 'firebase';
import shortid from 'shortid';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Add } from '@styled-icons/material';
import styled from 'styled-components';
import { Button } from 'antd';
import { bomEditedAtom, partBomItemsAtom, showBomEditorAtom } from 'shared/state/partViewState';
import { IBomItem } from 'shared/types/dbRecords';

const ComponentButton = styled(Button)`
  border-radius: 8px;
`;
const AddIcon = styled(Add)`
  color: white;
  width: 20px;
  height: 20px;
`;

const AddBomItemButton = () => {
  const [bomItems, setBomItems] = useRecoilState(partBomItemsAtom);
  const setEditBom = useSetRecoilState(showBomEditorAtom);
  const setBomEdited = useSetRecoilState(bomEditedAtom);

  const onClick = (e: any) => {
    setBomEdited(true);
    setEditBom(true);
    const newItem = {
      Description: 'Select a part using the search field to the left.',
      Sku: '',
      isPurchased: true,
      lastModDate: firebase.firestore.Timestamp.fromDate(new Date()),
      lastModUser: '',
      purchaseFactor: 1,
      quantity: 1,
      stepId: '',
      totalCost: 0,
      totalPrice: 0,
      totalQuantity: 1,
      uniqueID: shortid.generate(),
      unit: 'EA',
      unitCost: 0,
      unitPrice: 0,
      vendor: '',
    } as IBomItem;
    setBomItems([...bomItems, newItem]);
  };

  return (<ComponentButton onClick={onClick} type="primary" icon={<AddIcon />}>Add part</ComponentButton>);
};

export default AddBomItemButton;
