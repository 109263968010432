import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from 'shared/theme';
import { H6 } from 'shared/typography';

interface IComponent {
  radius?: number;
  borderSize?: number;
  label?: string;
  fontSize?: string;
}

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.div`
  width: ${(props: { radius: number }) => `${props.radius}px`};
  height: ${(props: { radius: number }) => `${props.radius}px`};
  display: inline-block;

  border-radius: 50%;

  border: ${(props: { radius: number }) => `${props.radius / 4}px solid ${theme.palette.neutral[100]}`};
  border-top: ${(props: { radius: number }) => `${props.radius / 4}px solid ${theme.palette.primary.hue}`};

  animation: ${rotation} 1s linear infinite;
`;

const SpinnerText = styled(H6)`
  margin-top: 12px;
  font-size: ${(props: { fontSize: string }) => props.fontSize};
`;

const Loader = ({ radius = 48, label = 'Loading', fontSize = '16px' }: IComponent) => (
  <>
    <Spinner radius={radius} />
    <SpinnerText fontSize={fontSize}>{label}</SpinnerText>
  </>
);

export default Loader;
