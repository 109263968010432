import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useSetRecoilState } from 'recoil';
import { showRealtimeDataDrawerAtom } from 'shared/state/utilState';

const ComponentButton = styled(Button)`
  border-radius: 8px;
  min-width: 240px;
  text-align: center;
  margin-bottom: unset;
  margin-right: 8px;
`;

const ShowRealtimeDataButton = () => {
  const setShowRealtimeDataDrawer = useSetRecoilState(showRealtimeDataDrawerAtom);
  const onClick = (e: any) => {
    e.preventDefault();
    setShowRealtimeDataDrawer(true);
  };
  return (
    <ComponentButton type="default" onClick={onClick}>Edit Realtime Data</ComponentButton>
  );
};

export default ShowRealtimeDataButton;
