import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { findIndex } from 'lodash';
import { useRecoilValue } from 'recoil';
import { Button, message, Modal } from 'antd';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { PageTitle } from 'shared/styledComponents/typographicElements';
import { PART_CONFIG_TERMS_COLLECTION, partConfigTermsAtom } from 'shared/state/partViewState';
import { IConfigTerm } from 'shared/types/parts';
import useFirebase from 'vendor/Firebase';
import Confetti from 'shared/components/Utility/Confetti';

const pulseSuccess = keyframes`
  0% { background-color: transparent; }
  20% { background-color: rgba(82, 196, 26, 0.3); }
  100% { background-color: transparent; }
`;

const pulseError = keyframes`
  0% { background-color: transparent; }
  20% { background-color: rgba(255, 77, 79, 0.3); }
  100% { background-color: transparent; }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const bounceIn = keyframes`
  0% { transform: scale(0.1); opacity: 0; }
  60% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-10px); }
`;

const pulseAnimation = css<{ status: 'success' | 'error' | 'none' }>`
  animation: ${(props) => {
    switch (props.status) {
      case 'success':
        return css`${pulseSuccess} 1.5s ease-out`;
      case 'error':
        return css`${pulseError} 1.5s ease-out`;
      default:
        return 'none';
    }
  }};
`;

const SLANG_SUCCESS_MESSAGES = [
  'Totally tubular!', 'Radical!', 'Gnarly!', 'Far out!', 'Groovy!', "That's sick!",
  "You're crushing it!", 'Epic win!', "You're on fire!", 'Absolutely stellar!',
  'Way cool!', "That's the bomb!", 'Righteous!', 'Awesome sauce!', "You're killing it!",
  "That's lit!", 'Pure gold!', "You're a legend!", 'Crushing it!', 'Mind-blowing!',
  'Out of sight!', 'Wicked awesome!', 'Too cool for school!', "You're a rock star!",
  "That's the way!", 'Fantastic!', "You're crushing it!", 'Phenomenal!', 'Outstanding!',
  "You're a genius!", 'Brilliant!', 'Spectacular!', 'Magnificent!', 'Excellent!',
  'Top notch!', 'First rate!', 'Superb!', 'Marvelous!', 'Terrific!', 'Incredible!',
  'Unreal!', 'Amazing!', 'Astounding!', 'Remarkable!', 'Extraordinary!', 'Sensational!',
  'Exceptional!', 'Wonderful!', 'Splendid!', 'Fabulous!', 'Fantastic!', 'Super duper!',
  'Far out!', 'Right on!', 'Dynamite!', 'Sweet!', 'Awesome possum!', 'Excellent!',
  'Bodacious!', 'Rad to the max!', 'Too cool!', 'Way to go!', "You're on a roll!",
  'Keep it up!', "You're unstoppable!", "You're on fire!", "You're a natural!",
  "You've got skills!", "You're a pro!", "You're a champion!", "You're a superstar!",
  "You're amazing!", "You're brilliant!", "You're incredible!", "You're phenomenal!",
  "You're outstanding!", "You're remarkable!", "You're spectacular!", "You're wonderful!",
  "You're fantastic!", "You're magnificent!", "You're marvelous!", "You're terrific!",
  "You're unbelievable!", "You're extraordinary!", "You're sensational!", "You're exceptional!",
  "You're splendid!", "You're fabulous!", "You're super!", "You're excellent!",
  "You're awesome!", "You're incredible!", "You're phenomenal!", "You're outstanding!",
  "You're remarkable!", "You're spectacular!", "You're wonderful!", "You're fantastic!",
  "You're magnificent!", "You're marvelous!", "You're terrific!", "You're unbelievable!",
  "You're extraordinary!", "You're sensational!", "You're exceptional!", "You're splendid!",
  "You're fabulous!", "You're super!", "You're excellent!", "You're awesome!"
];

const GameWrapper = styled(FlexColumn)<{ status: 'success' | 'error' | 'none' }>`
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
  gap: 20px;
  align-items: center;
  min-height: 100vh;
  ${pulseAnimation}
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  position: relative;
  overflow: hidden;
`;

const FixedWidthContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TermWrapper = styled.div`
  width: 100%;
  margin: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  overflow: visible;
`;

const Term = styled.div`
  font-size: clamp(32px, 8vw, 128px);
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  width: auto;
  min-width: min-content;
  animation: ${floatAnimation} 3s ease-in-out infinite;
  color: #2c3e50;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
  padding: 0 20px;

  &:hover {
    transform: scale(1.05) rotate(2deg);
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input<{ isDisabled?: boolean }>`
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 50px;
  margin-bottom: 20px;
  background-color: ${(props) => (props.isDisabled ? '#f5f5f5' : 'white')};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
  }
`;

const EditButton = styled(Button)`
  position: absolute;
  right: -80px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50px;

  @media (max-width: 768px) {
    right: 10px;
    top: auto;
    bottom: -50px;
    transform: none;
  }
`;

const Stats = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
`;

const StatItem = styled.div`
  text-align: center;
  flex: 1;
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const StatValue = styled.div<{ color?: string }>`
  font-size: 32px;
  font-weight: bold;
  color: ${(props) => props.color || 'inherit'};
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
`;

const StyledButton = styled(Button)`
  &.ant-btn {
    border-radius: 50px;
    padding: 0 30px;
    height: 50px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    background: linear-gradient(45deg, #2196F3, #21CBF3);
    border: none;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 232px;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1);
      background: linear-gradient(45deg, #21CBF3, #2196F3);
    }

    &:active {
      transform: translateY(1px);
    }
  }
`;

const StatsContainer = styled.div`
  position: relative;
  width: 50%;
`;

const MessageContainer = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
`;

const AnimatedTitle = styled(PageTitle)<{ show: boolean }>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  animation: ${(props) => (props.show ? css`${bounceIn} 1s forwards` : 'none')};
  font-size: 3rem;
  color: #2c3e50;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

const ContentWrapper = styled.div<{ show: boolean }>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: translateY(${(props) => (props.show ? '0' : '20px')});
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  width: 100%;
  // max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessMessage = styled.div<{ show: boolean }>`
  font-size: 40px;
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
  margin-top: 40px;
  animation: ${(props) => (props.show ? css`${fadeIn} 0.5s forwards` : css`${fadeOut} 0.5s forwards`)};
`;

const NameGame = () => {
  const { firestore } = useFirebase();
  const partConfigTerms = useRecoilValue(partConfigTermsAtom);
  const [currentTerm, setCurrentTerm] = useState<IConfigTerm | null>(null);
  const [configTerms, setConfigTerms] = useState<IConfigTerm[]>(partConfigTerms);
  const [input, setInput] = useState('');
  const [skipped, setSkipped] = useState(0);
  const [submitted, setSubmitted] = useState(0);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [validTerms, setValidTerms] = useState<IConfigTerm[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [status, setStatus] = useState<'success' | 'error' | 'none'>('none');
  const [showFireworks, setShowFireworks] = useState(false);
  const partConfigTermsDbPath = useRecoilValue(PART_CONFIG_TERMS_COLLECTION);

  // New state for controlling animations
  const [showTitle, setShowTitle] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const getRandomSuccessMessage = () => {
    const index = Math.floor(Math.random() * SLANG_SUCCESS_MESSAGES.length);
    return SLANG_SUCCESS_MESSAGES[index];
  };

  const nextTerm = () => {
    if (validTerms.length === 0) return;
    
    setInput('');
    setIsEditing(false);
    const nextIndex = Math.floor(Math.random() * validTerms.length);
    const next = validTerms[nextIndex];
    setCurrentTerm(next);
    if (next.english) {
      setInput(next.english);
    }
  };

  useEffect(() => {
    setConfigTerms(partConfigTerms);
    // Filter out terms that have coercion
    const filtered = Object.values(partConfigTerms).filter((term) => !term.coercion);
    setValidTerms(filtered);
    
    setShowTitle(true);
    setTimeout(() => setShowContent(true), 1000);
    if (filtered.length > 0) {
      const initialIndex = Math.floor(Math.random() * filtered.length);
      const initial = filtered[initialIndex];
      setCurrentTerm(initial);
      if (initial.english) {
        setInput(initial.english);
      }
    }
  }, [partConfigTerms]);

  const showStatus = (newStatus: 'success' | 'error') => {
    setStatus('none');
    // Force a reflow to ensure animation triggers
    requestAnimationFrame(() => {
      setStatus(newStatus);
      setTimeout(() => setStatus('none'), 1500);
    });
  };

  const showGameMessage = (type: 'success' | 'error', content: string) => {
    if (type === 'success') {
      setSuccessMessage(content);
      setShowSuccessMessage(true);
      setShowFireworks(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setShowFireworks(false);
      }, 3000);
    }
  };

  // const showGameMessage = (type: 'success' | 'error', content: string) => {
  //   message.config({
  //     getContainer: () => document.body,
  //     duration: 2,
  //   });

  //   if (type === 'success') {
  //     message.success(content);
  //     setShowFireworks(true);
  //     setTimeout(() => setShowFireworks(false), 3000);
  //   } else {
  //     message.error(content);
  //   }
  // };

  const updateTermDefinition = async (term: IConfigTerm, definition: string) => {
    try {
      // Get the collection path
      const keys = Object.keys(partConfigTerms);
      const vals = Object.values(partConfigTerms);
      const _termIndex = findIndex(vals, (value) => value.term === term.term);
      
      if (_termIndex === -1) return;
      const termId = keys[_termIndex];
      const newTerm = vals[_termIndex];

      console.log('Updating term:', {
        term: newTerm.term,
        definition,
        partConfigTermsDbPath,
      });

      // Update Firestore
      await firestore.collection(partConfigTermsDbPath).doc(termId).update({
        english: definition,
      });

      // Update local state
      const updatedTerms = { ...partConfigTerms };
      // @ts-ignore
      updatedTerms[termId] = { ...newTerm, english: definition };
      setConfigTerms(updatedTerms);
      
      // Show success feedback
      showStatus('success');
      showGameMessage('success', getRandomSuccessMessage());
      return true;
    } catch (error) {
      console.error('Failed to update term:', error);
      showStatus('error');
      showGameMessage('error', 'Failed to save definition');
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!currentTerm) return;

    try {
      if (currentTerm.english && !isEditing) {
        // Verifying an existing definition
        showStatus('success');
        showGameMessage('success', getRandomSuccessMessage());
        const _submitted = submitted + 1;
        setSubmitted(_submitted);
        nextTerm();
      } else if (input.trim()) {
        // Submitting a new definition
        const success = await updateTermDefinition(currentTerm, input.trim());
        const _submitted = submitted + 1;
        console.log('Submitted:', _submitted);
        setSubmitted(_submitted);
        if (success) {
          nextTerm();
        }
      } else {
        handleSkip();
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      showStatus('error');
    }
  };

  const handleSkip = () => {
    setSkipped(skipped + 1);
    nextTerm();
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && (!currentTerm?.english || isEditing)) {
      handleSubmit();
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <GameWrapper status={status}>
      <AnimatedTitle show={showTitle}>The Name Game</AnimatedTitle>
      <ContentWrapper show={showContent}>
        <StyledButton onClick={() => setShowHowToPlay(true)}>How to Play</StyledButton>
        <Modal
          title="How to Play"
          open={showHowToPlay}
          onOk={() => setShowHowToPlay(false)}
          onCancel={() => setShowHowToPlay(false)}
          footer={[
            <Button key="ok" type="primary" onClick={() => setShowHowToPlay(false)}>
              Got it!
            </Button>,
          ]}
        >
          <p>Welcome to the Name Game! Here&apos;s how to play:</p>
          <ul>
            <li>You&apos;ll be shown a term from our internal vocabulary</li>
            <li>Type in what you think this term means in plain English</li>
            <li>For terms that already have definitions:</li>
            <ul>
              <li>Review the existing definition</li>
              <li>Click &quot;Verify&quot; if it looks good, or &quot;Edit&quot; to improve it</li>
            </ul>
            <li>Hit Enter or click Next/Verify to submit</li>
            <li>If you don&apos;t know a term, just leave it blank and click Skip</li>
            <li>Try to define as many terms as you can!</li>
          </ul>
        </Modal>
        {currentTerm && (
          <>
            <TermWrapper>
              <Term>{currentTerm.term}</Term>
            </TermWrapper>
            <FixedWidthContainer>
              <InputWrapper>
                <Input
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your definition..."
                  disabled={currentTerm.english && !isEditing}
                  isDisabled={currentTerm.english && !isEditing}
                />
                {currentTerm.english && (
                  <EditButton
                    type="default"
                    onClick={toggleEdit}
                  >
                    {isEditing ? 'Cancel' : 'Edit'}
                  </EditButton>
                )}
              </InputWrapper>
              <StyledButton
                type="primary"
                onClick={handleSubmit}
                size="large"
              >
                {currentTerm.english && !isEditing ? 'Verify' : input.trim() ? 'Next' : 'Skip'}
              </StyledButton>
              <StatsContainer className="stats-container">
                <MessageContainer />
                <Stats>
                  <StatItem>
                    <StatLabel>Skipped</StatLabel>
                    <StatValue color="#ff4d4f">{skipped}</StatValue>
                  </StatItem>
                  <StatItem>
                    <StatLabel>Submitted</StatLabel>
                    <StatValue color="#52c41a">{submitted}</StatValue>
                  </StatItem>
                </Stats>
              </StatsContainer>
              <SuccessMessage show={showSuccessMessage}>
                {successMessage}
              </SuccessMessage>
            </FixedWidthContainer>
          </>
        )}
      </ContentWrapper>
      <Confetti show={showFireworks} />
    </GameWrapper>
  );
};

export default NameGame;
