import React, {
  useEffect, useState,
} from 'react';
import { Modal } from 'antd';
// @ts-ignore
import QrReader from 'react-qr-reader';
import Logo from 'shared/components/Logo/Logo';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { H6 } from 'shared/typography';
import {
  ScreenSaverLogoWrapper, ScreenSaverWrapper,
} from './styledComponents';

const _ = require('lodash');

interface ComponentInterface {
  callback: () => void;
}
export default ({ callback }: ComponentInterface) => {
  const [leftPos, setLeftPos] = useState('25vw');
  const [topPos, setTopPos] = useState('45vh');

  const [moveHorizontal, setMoveHorizontal] = useState(false);
  const [moveStart, setMoveStart] = useState(true);
  const HORIZ_MIN = 0;
  const HORIZ_MAX = window.innerWidth / 4;
  const VERT_MIN = 0;
  const VERT_MAX = window.innerHeight / 4;

  const onClick = (e: any) => {
    e.preventDefault();
    // window.location.href = '/scanner';
    callback();
  };

  useEffect(() => {
    const LogoElement = document.getElementById('logo');
    const minPos = 0;
    // @ts-ignore
    const maxX = window.innerWidth - LogoElement.offsetWidth;
    // @ts-ignore
    const maxY = window.innerHeight - LogoElement.offsetHeight;
    const setPosition = () => {
      const left = `${_.random(minPos, maxX)}px`;
      const top = `${_.random(minPos, maxY)}px`;

      setLeftPos(left);
      setTopPos(top);
    };

    setInterval(() => {
      setPosition();
    }, 5000);
    setPosition();
  }, []);

  const vh = window.innerHeight * 0.01;

  return (
    <ScreenSaverWrapper style={{ height: 100 * vh }} onClick={onClick}>
      <ScreenSaverLogoWrapper id="logo" left={leftPos} top={topPos}>
        <Logo />
        <H6 style={{ color: 'white', marginTop: 12 }}>Tap to scan</H6>
      </ScreenSaverLogoWrapper>
    </ScreenSaverWrapper>
  );
};
