import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { CopyTwoTone, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  last, omit, sortBy,
} from 'lodash';
import { useRecoilValue } from 'recoil';
import { adminUserEmailsAtom, superAdminUserEmailAtom } from 'shared/state/routingState';
import { IInventoryPart } from 'shared/types/dbRecords';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from 'shared/state/inventoryState';
import { stageRedirect } from 'shared/util';
import theme from 'shared/theme';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import QBOAuth from 'shared/data/QBO/auth';
import QBOItem from 'shared/data/QBO/item';
import useFirebase from 'vendor/Firebase';
import { ConfirmDeleteIcon } from '../../../Customer/Components/styledComponents';

const PartCopyButton = styled(Button)`
  opacity: ${(props: any) => (!props.copyDataLoaded ? 1 : 0.2)};
    margin-right: 8px;
`;
const PartCopyIcon = styled(CopyTwoTone)`
  color: ${theme.palette.primary.hue} !important;
`;

interface IComponent {
  inventoryPart: IInventoryPart;
}
const ActionButtons = ({ inventoryPart }: IComponent) => {
  const { firestore, database } = useFirebase();
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const adminUsers = useRecoilValue(adminUserEmailsAtom);
  const superAdminUsers = useRecoilValue(superAdminUserEmailAtom);

  const nextChildNumberFromChild = () => {
    const [sku, stub] = inventoryPart.Sku.split('.');
    return `${sku}.${(parseInt(stub, 10) + 1).toString().padStart(2, '0')}`;
  };
  const nextPartNumber = () => {
    if (inventoryPart.Parent) return nextChildNumberFromChild();
    const inventorySkuStub = inventoryPart.Sku.slice(0, 3);
    const likeParts = inventoryItems
      .filter((i: IInventoryPart) => {
        const partSku = i.Sku?.substring(0, 3) || '';
        return partSku === inventorySkuStub;
      })
      .map((i: IInventoryPart) => parseInt(i.Sku, 10));
    const sorted = sortBy(likeParts, (i) => i);
    // @ts-ignore
    const newPartNumber = last(sorted) + 1;
    return newPartNumber.toString();
  };

  const nextChildPartNumber = () => {
    const nextChild = ((inventoryPart.Children || []).length + 1).toString().padStart(2, '0');
    return `${inventoryPart.Sku}.${nextChild}`;
  };

  const onCopy = (e: any) => {
    e.stopPropagation();
    const newPartNumber = nextPartNumber();
    const updatedPart = { ...inventoryPart, Sku: newPartNumber, Name: newPartNumber };
    localStorage.setItem('inventory.temp.part', JSON.stringify(omit(updatedPart, ['Id', 'Children'])));
    stageRedirect(`/inventory?partId=${newPartNumber}`);
    window.location.href = '/inventory/edit?copy=true';
  };

  const onCreateChild = (e: any) => {
    e.stopPropagation();
    const newPartNumber = nextChildPartNumber();
    const updatedPart = {
      ...inventoryPart,
      Sku: newPartNumber,
      Name: newPartNumber,
      Parent: {
        Id: inventoryPart.Id,
        Sku: inventoryPart.Sku,
        Description:
        inventoryPart.Description,
        ParentPerChild: 1,
      },
    };
    localStorage.setItem('inventory.temp.part', JSON.stringify(omit(updatedPart, ['Id', 'Children'])));
    stageRedirect(`/inventory?partId=${newPartNumber}`);
    window.location.href = '/inventory/edit?copy=true';
  };

  const onCopyClick = (e: any) => {
    e.stopPropagation();
    if (inventoryPart.Parent) return onCopy(e);
  };
  const onDeleteClick = (e: any) => {
    e.stopPropagation(); // stop propagation so popover can be triggered to confirm whether we should delete.
  };
  const onDelete = async (e: any) => {
    e.stopPropagation();
    // await QBOAuth.refreshToken(database);
    await QBOItem.update({ Id: inventoryPart.Id, Active: false });
    await firestore.collection(inventoryDbString).doc(inventoryPart.Sku).delete();
    window.location.reload();
  };

  return (
    <ScopedComponent whitelist={[...adminUsers.emails, ...superAdminUsers.emails]}>
      <Tooltip title="Copy Part">
        <Popconfirm
          title="Copy or create subitem of this part?"
          onConfirm={onCreateChild}
          onCancel={onCopy}
          okText="Subitem"
          cancelText="Copy"
          placement="left"
        >
          <PartCopyButton
            shape="circle"
            icon={<PartCopyIcon />}
            onClick={onCopyClick}
          />
        </Popconfirm>
      </Tooltip>
      <Tooltip title="Delete">
        <Popconfirm
          title="Are you sure you want to delete this item?"
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
          placement="left"
          // @ts-ignore
          icon={<ConfirmDeleteIcon />}
        >
          <Button
            shape="circle"
            // @ts-ignore
            icon={<DeleteOutlined />}
            onClick={onDeleteClick}
          />
        </Popconfirm>
      </Tooltip>
    </ScopedComponent>
  );
};

export default ActionButtons;
