import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from 'antd';
import { compress } from 'image-conversion';
import { ncImageDataItemsAtom, ncReviewImageAtom } from 'shared/state/utilState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import { Camera as CameraIcon } from '@styled-icons/fa-solid';
import 'react-html5-camera-photo/build/css/index.css';
import styled from 'styled-components';
import theme from 'shared/theme';
import { ProgressSpinner } from 'shared/pageElements/styledComponents';
import NCImageReviewer from './NCImageReviewer';

const PhotoActionRow = styled(FlexRow)`
  justify-content: flex-start;
  margin: 8px 0 16px 0;
  gap: 16px;
`;

const ComponentButton = styled(Button)`
    border-radius: 8px;
  `;

const MiniProgressSpinner = styled(ProgressSpinner)`
  width: 24px;
  height: 24px;
  position: relative;
  border: 4px solid ${theme.palette.neutral[100]};
  border-top: 4px solid ${theme.palette.primary.hue};
  animation-duration: 0.75s;
`;

const CameraButtonIcon = styled(CameraIcon)`
  width: 20px;
  margin: 0 8px 4px 0;
`;

const ImageUploadButton = styled(Button)`
  border-radius: 8px;
`;
const ImageUploadInput = styled.input`
  width: 0;
    visibility: hidden;
`;

export default () => {
  const [imageDataItems, setImageDataItems] = useRecoilState(ncImageDataItemsAtom);
  const [reviewPictures, setReviewPictures] = useRecoilState(ncReviewImageAtom);
  const [imageProcessing, setImageProcessing] = useState(false);

  const onReviewImages = (e: any) => {
    setReviewPictures(true);
  };

  const onEndReviewImages = (e: any) => {
    setReviewPictures(false);
  };

  const onUploadImage = (e: any) => {
    setImageProcessing(true);
    const images = Object.keys(e.target.files).map((i) => (e.target.files[i]));
    const compressed = [];

    images.forEach((i) => {
      compress(i, 0.8).then((res) => {
        compressed.push(res);
        if (compressed.length === images.length) {
          setImageProcessing(false);
          setImageDataItems([
            ...imageDataItems,
            ...compressed,
          ]);
        }
      });
    });
  };

  const onUploadButtonClick = (e: any) => {
    e.preventDefault();
    document.getElementById('file-uploader')?.click();
  };

  return (
    <>
      {imageDataItems.length ? (
        <PhotoActionRow>
          <ComponentButton type="primary" onClick={onReviewImages}>{`Review ${imageDataItems.length} photo${imageDataItems.length === 1 ? '' : 's'}`}</ComponentButton>
          {/* <ImageUploadComponent label="Attach another Image" /> */}
          <ImageUploadButton type="primary" onClick={onUploadButtonClick} icon={<CameraButtonIcon />}>Attach another Image</ImageUploadButton>
          <ImageUploadInput id="file-uploader" type="file" name="myImage" multiple onChange={onUploadImage} />
          {imageProcessing && (<MiniProgressSpinner />)}
        </PhotoActionRow>
      ) : (
        <PhotoActionRow>
          {/* <ImageUploadComponent label="Attach Image" /> */}
          <ImageUploadButton type="primary" onClick={onUploadButtonClick} icon={<CameraButtonIcon />}>Attach Image</ImageUploadButton>
          <ImageUploadInput id="file-uploader" type="file" name="myImage" multiple onChange={onUploadImage} />
          {imageProcessing && (<MiniProgressSpinner />)}
        </PhotoActionRow>
      )}
      { reviewPictures && (
      <NCImageReviewer view={reviewPictures} endCallback={onEndReviewImages} />
      )}
    </>
  );
};
